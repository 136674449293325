import React, { useMemo } from 'react'
import { Box, Typography, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { randomId } from '@mui/x-data-grid-generator'

// Shared number formatting helper function for currency values
const formatCurrency = (value) => {
  if (value === undefined || value === null) return ' '
  return value < 0 ? `-$${Math.abs(Number(value)).toLocaleString()}` : `$${Number(value).toLocaleString()}`
}

// Define columns for current allocation table
const getCurrentColumns = () => [
  {
    field: 'accountName',
    headerName: 'Name',
    flex: 1,
    headerAlign: 'left',
    align: 'left'
  },
  {
    field: 'strategyName',
    headerName: 'Strategy',
    flex: 1,
    headerAlign: 'left',
    align: 'left'
  },
  {
    field: 'weight',
    headerName: 'Weight',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) =>
      params?.row?.weight !== undefined ? `${params.row.weight.toFixed(2)}%` : 'NA'
  },
  {
    field: 'totalMarketValue',
    headerName: 'Market Value',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) => params?.value?.toFixed(0),
    renderCell: (params) => formatCurrency(params?.value)
  },
  {
    field: 'totalInitUrgl',
    headerName: 'Unrealized Gain/Loss',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) => params?.value?.toFixed(0),
    renderCell: (params) => (
      <span style={{ color: params.value < 0 ? '#A6333F' : '#33A68D' }}>
        {formatCurrency(params.value)}
      </span>
    )
  },
  {
    field: 'buyValue',
    headerName: 'Buy Value',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) => params?.value?.toFixed(0),
    renderCell: (params) => formatCurrency(params.value)
  },
  {
    field: 'proposedCash',
    headerName: 'Cash',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) => params?.value?.toFixed(0),
    renderCell: (params) => formatCurrency(params.value)
  },
  {
    field: 'transferAmount',
    headerName: 'Transfer Value',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) => params?.value?.toFixed(0),
    renderCell: (params) => formatCurrency(params.value)
  }
]

// Define columns for original allocation table
const getOriginalColumns = () => [
  {
    field: 'accountName',
    headerName: 'Name',
    flex: 1,
    headerAlign: 'left',
    align: 'left'
  },
  {
    field: 'weight',
    headerName: 'Weight',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) =>
      params?.row?.weight !== undefined ? `${params.row.weight.toFixed(2)}%` : 'NA'
  },
  {
    field: 'totalMarketValue',
    headerName: 'Market Value',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) => params?.value?.toFixed(0),
    renderCell: (params) => formatCurrency(params.value)
  },
  {
    field: 'totalRgl',
    headerName: 'Realized Gain/Loss',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) => params?.value?.toFixed(0),
    renderCell: (params) => (
      <span style={{ color: params.value < 0 ? '#A6333F' : '#33A68D' }}>
        {formatCurrency(params.value)}
      </span>
    )
  },
  {
    field: 'totalInitUrgl',
    headerName: 'Unrealized Gain/Loss',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) => params?.value?.toFixed(0),
    renderCell: (params) => (
      <span style={{ color: params.value < 0 ? '#A6333F' : '#33A68D' }}>
        {formatCurrency(params.value)}
      </span>
    )
  },
  {
    field: 'sellValue',
    headerName: 'Sell Value',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) => params?.value?.toFixed(0),
    renderCell: (params) => formatCurrency(params.value)
  },
  {
    field: 'initialCash',
    headerName: 'Cash',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) => params?.value?.toFixed(0),
    renderCell: (params) => formatCurrency(params.value)
  },
  {
    field: 'transferAmount',
    headerName: 'Transfer Value',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) => params?.value?.toFixed(0),
    renderCell: (params) => formatCurrency(params.value)
  }
]

// Column definitions specifically for TRANSITION case
const getTransitionColumns = () => [
  {
    field: 'accountName',
    headerName: 'Name',
    flex: 1,
    headerAlign: 'left',
    align: 'left'
  },
  {
    field: 'weight',
    headerName: 'Weight',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) =>
      params?.row?.weight !== undefined ? `${params.row.weight.toFixed(2)}%` : 'NA'
  },
  {
    field: 'totalMarketValue',
    headerName: 'Market Value',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) => params?.value?.toFixed(0),
    renderCell: (params) => formatCurrency(params.value)
  },
  {
    field: 'transferAmount',
    headerName: 'Investment',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) => params?.value?.toFixed(0),
    renderCell: (params) => formatCurrency(params.value)
  },
  {
    field: 'totalInitUrgl',
    headerName: 'Unrealized Gain/Loss',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) => params?.value?.toFixed(0),
    renderCell: (params) => (
      <span style={{ color: params.value < 0 ? '#A6333F' : '#33A68D' }}>
        {formatCurrency(params.value)}
      </span>
    )
  }
]

const LoadingTable = ({ columns }) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          {columns.map((col, index) => (
            <TableCell key={index}>{col.headerName}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.from({ length: 5 }).map((_, i) => (
          <TableRow key={i}>
            {columns.map((_, j) => (
              <TableCell key={j}>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)

const EnhancedAllocation = ({ data = null, loading = false, umaType }) => {
  const effectiveData = useMemo(() => data, [data])

  const currentColumns = useMemo(() =>
    umaType === 'TRANSITION' ? getTransitionColumns() : getCurrentColumns(),
    [umaType])

  const originalColumns = useMemo(() =>
    umaType === 'SCENARIO_TRANSITION' ? getOriginalColumns() : [],
    [umaType])

  const currentTableData = useMemo(() => (
    (umaType === 'SCENARIO_TRANSITION'
      ? effectiveData?.targetUMA?.allocationData
      : effectiveData?.originalUMA?.allocationData) || []
  ).map(row => ({ ...row, id: randomId() })), [effectiveData, umaType])

  const originalTableData = useMemo(() => (
    (umaType === 'SCENARIO_TRANSITION'
      ? effectiveData?.originalUMA?.allocationData
      : []) || []
  ).map(row => ({ ...row, id: randomId() })), [effectiveData, umaType])

  const calculateAggregates = (data) => {
    return data.reduce((sum, row) => ({
      weight: (sum.weight || 0) + (row.weight || 0),
      totalMarketValue: (sum.totalMarketValue || 0) + (row.totalMarketValue || 0),
      totalInitUrgl: (sum.totalInitUrgl || 0) + (row.totalInitUrgl || 0),
      totalRgl: (sum.totalRgl || 0) + (row.totalRgl || 0),
      buyValue: (sum.buyValue || 0) + (row.buyValue || 0),
      sellValue: (sum.sellValue || 0) + (row.sellValue || 0),
      proposedCash: (sum.proposedCash || 0) + (row.proposedCash || 0),
      initialCash: (sum.initialCash || 0) + (row.initialCash || 0),
      transferAmount: (sum.transferAmount || 0) + (row.transferAmount || 0)
    }), {})
  }

  const currentTotalRow = useMemo(() => ({
    id: 'current-total',
    accountName: 'Total',
    ...calculateAggregates(currentTableData)
  }), [currentTableData])

  const originalTotalRow = useMemo(() => ({
    id: 'original-total',
    accountName: 'Total',
    ...calculateAggregates(originalTableData)
  }), [originalTableData])

  return (
    <Box sx={{ p: 2, borderRadius: 2, border: 1, borderColor: '#E6ECF3' }}>
      <Box sx={{ width: '100%' }}>
        <Typography sx={{
          fontSize: '15px',
          fontWeight: 600,
          color: '#2f2f2f',
          mt: '10px'
        }}
        >
          {umaType === 'SCENARIO_TRANSITION' ? 'Proposed Allocation' : 'Portfolio Allocation'}
        </Typography>
        {loading
          ? (
            <LoadingTable columns={currentColumns} />
          )
          : (
            <Box sx={{ mt: '20px' }}>
              <DataGridPro
                autoHeight
                density='compact'
                rows={currentTableData}
                columns={currentColumns}
                pinnedRows={{ bottom: [currentTotalRow] }}
                getRowId={(row) => row.id}
                disableRowSelectionOnClick
                hideFooter
                localeText={{ noRowsLabel: 'No Portfolio Found' }}
                sx={{
                  [`.${gridClasses.pinnedRows}`]: { boxShadow: 'none', borderBlock: '1px solid black', background: '#FBFBFD' }
                }}
              />
            </Box>
          )}
      </Box>

      {umaType === 'SCENARIO_TRANSITION' && (
        <>
          <Box sx={{ my: 3 }} />
          <Box sx={{ width: '100%' }}>
            <Typography sx={{
              fontSize: '15px',
              fontWeight: 600,
              color: '#2f2f2f',
              mb: '20px'
            }}
            >
              Original Allocation
            </Typography>
            {loading
              ? (
                <LoadingTable columns={originalColumns} />
              )
              : (
                <Box>
                  <DataGridPro
                    autoHeight
                    density='compact'
                    rows={originalTableData}
                    columns={originalColumns}
                    pinnedRows={{ bottom: [originalTotalRow] }}
                    getRowId={(row) => row.id}
                    disableRowSelectionOnClick
                    hideFooter
                    localeText={{ noRowsLabel: 'No Portfolio Found' }}
                    sx={{
                      [`.${gridClasses.pinnedRows}`]: { boxShadow: 'none', borderBlock: '1px solid black', background: '#FBFBFD' }
                    }}
                  />
                </Box>
              )}
          </Box>
        </>
      )}
    </Box>
  )
}

export default EnhancedAllocation
