import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { API } from 'aws-amplify'
import { AppBar, Box, IconButton, Toolbar, Tooltip } from '@mui/material'
import 'rc-slider/assets/index.css'
import { LuRefreshCw } from 'react-icons/lu'
import menu from '../assets/images/menu.svg'
import '../assets/styles/topbar.scss'
import LegacyViewButton from '../components/LegacyViewButton'
import { useAuth } from '../contexts/AuthContext'
import { useLogo } from '../contexts/SponsorLogoContext'
import { useErrorToast } from '../hooks/useErrorToast'
import { clearStore } from '../store/dashboard-reducer/dashboard-reducer'
import { setSidebarVisibility } from '../store/layout-reducer/layout-reducer'
import PageSearch from './PageSearch'
import PolicyCheckTop from './PolicyCheckTop'
import ProfileMenu from './ProfileMenu'
import SwitchUserTop from './SwitchUserTop'

const Topbar = ({ topMenuToggleRef }) => {
  const { user, refreshACL, setIsAclFetching } = useAuth()
  const { showLegacyView } = useSelector((state) => state.trade)
  const { isSidebarOpen } = useSelector(state => state.layout)
  const [currentZIndex, setCurrentZIndex] = useState(1)
  const { storeSponsorLogo, handleSetLogoInnerHTML } = useLogo()
  const { showError } = useErrorToast()
  const dispatch = useDispatch()

  const handleAClRefresh = () => {
    setIsAclFetching(true)
    dispatch(clearStore('RESET'))
    localStorage.removeItem('object')
    refreshACL(user?.sub, true)
    fetchSponsorData()
  }

  const fetchSponsorData = (retries = 2) => {
    let hasError = false
    API.get('baseSponserURL', `data-maintenance/v1/logo-details/${user?.sub}`)
      .then(response => {
        if (response?.data) {
          if (Array.isArray(response.data) && response?.data?.length) {
            storeSponsorLogo(response.data[0])
            const favicon = document.getElementById('favicon')
            if (favicon) {
              favicon.href = response.data[0].favIconUrl ? response.data[0].favIconUrl : './ArisFavicon.svg'
            }
            const title = document.getElementById('title')
            if (title && response.data[0].sponsorName) {
              title.innerText = response.data[0].sponsorName ? response.data[0].sponsorName : 'Aris Investing'
            }
          } else {
            storeSponsorLogo(null)
            handleSetLogoInnerHTML()
          }
        }
      })
      .catch(error => {
        hasError = true
        showError(error, false, {}, 'Failed to load sponsor data.')
      }).finally(() => {
        // retry api call 2 more times if first api call fails
        if (hasError && retries > 0) {
          setTimeout(() => {
            fetchSponsorData(retries - 1)
          }, 1000)
        } else if (hasError && retries === 0) {
          storeSponsorLogo(null)
          handleSetLogoInnerHTML()
        }
      })
  }

  return (
    <>
      <AppBar
        position='sticky'
        className='app-bar'
        sx={{
          backgroundColor: 'white',
          borderBottom: 'none',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
          height: '48px',
          zIndex: currentZIndex,
          marginRight: '0px',
          width: 'auto',
          color: 'black',
          ...{
            ...showLegacyView
              ? {
                marginLeft: '50px'
              }
              : {}
          },
          '@media screen and (max-width:600px)': {
            // marginLeft: '80px'
          },

          '& .MuiToolbar-root': {
            paddingLeft: '30px',
            paddingRight: '30px',
            minHeight: '48px',
            // '@media screen and (min-width:1024px)': {
            //   paddingLeft: '30px',
            //   paddingRight: '40px'
            // },
            // '@media screen and (max-width:800px)': {
            //   paddingLeft: '73px',
            //   paddingRight: '40px'
            // },
            '@media screen and (max-width:600px)': {
              paddingLeft: '20px',
              paddingRight: '20px'
            }

            // '@media screen and (min-width:1024px) and (max-width:1400px)': {
            //   paddingLeft: '30px',
            //   paddingRight: '40px'
            // },

            // '@media screen and (min-width:601px) and (max-width:1024px)': {
            //   paddingLeft: '30px',
            //   paddingRight: '40px'
            // }
          }
        }}
      >
        <Toolbar sx={{
          display: 'inline-flex',
          justifyContent: 'space-between',
          width: '100%'
        }}
        >
          <IconButton
            className='menu-toggle'
            sx={{
              display: 'none',
              '@media screen and (max-width:600px)': {
                display: 'inline-block'
              }
            }}
            onClick={() => dispatch(setSidebarVisibility({ isSidebarOpen: !isSidebarOpen }))}
            ref={topMenuToggleRef}
          >
            <img src={menu} alt='' height={21} width={22} />
          </IconButton>
          {user?.userGroup !== 'admin' && <PageSearch setCurrentZIndex={setCurrentZIndex} />}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <LegacyViewButton />
            <Tooltip title='Refresh Access'>
              <Box>
                <LuRefreshCw style={{ height: '18px', cursor: 'pointer', marginLeft: '10px', color: '#0000008a', marginTop: '5px' }} onClick={handleAClRefresh} />
              </Box>
            </Tooltip>
            {['pm', 'adv-classic'].includes(user?.userGroup) ? <PolicyCheckTop /> : ''}
            <SwitchUserTop />
            <ProfileMenu />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Topbar
