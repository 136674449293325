import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import dayjs from 'dayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { Alert, Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, Menu, MenuItem, OutlinedInput, Select, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GridActionsCellItem, gridClasses, GridFooterContainer, GridPagination, GridRowModes } from '@mui/x-data-grid-pro'
import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { isNumberWithSign, isWholeNumber, numberWithSignPasteHandler, wholeNumberPasteHandler } from '../../../utils/NumberUtils'
import AddPropertyRecordPopup from '../components/AddPropertyRecordPopup'
import { HtmlTooltip } from '../../../components/CustomTooltip'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import { useAccountService } from '../../../hooks/useAccountService'
import { AccountCodeSelect, PassiveScenarioSelect } from '../components/InputComponent'

const UpdatePropertiesModal = (props) => {
  const { selectedItem, index, allScenarioList, isUPShown, handlePopupClose, diTrading = false } = props
  const { scenarioId, propertyCode, propertyValue, accountId } = selectedItem
  const { getGeneralAccountListUtil } = useAccountService()
  const [data] = useState({
    scenarioId,
    propertyCode,
    propertyValue,
    accountId
  })
  const [approvalProperties, setApprovalProperties] = useState([])
  const [hasDuplicatePropertyCode, setHasDuplicatePropertyCode] = useState(false)
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [isOptPropertiesLoading, setIsOptPropertiesLoading] = useState(true)
  const [isPropertyCodesLoading, setIsPropertyCodesLoading] = useState(true)

  const [rowModesModel, setRowModesModel] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [selectedScenarioCopy, setSelectedScenarioCopy] = useState(diTrading ? [((index?.length > 1 && index[1] && index[1].optDetails?.find(optObj => !optObj.isDILink)?.scenarioId) || 1)] : (index?.[1]?.optDetails?.map(obj => obj.scenarioId) || [1, 2, 3]))
  const [propertiesList, setPropertiesList] = useState([])
  const [addPropertyPopupOpen, setAddPropertyPopupOpen] = useState(false)
  const [newRowCount, setNewRowCount] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedScenario, setSelectedScenario] = useState(selectedScenarioCopy || [])
  const [isScenarioGroupChanged, setIsScenarioGroupChanged] = useState(false)
  const [accountList, setAccountList] = useState({ loading: false, data: [] })

  const handleOpenScenarioMenu = (event) => {
    setSelectedScenario(selectedScenarioCopy || [])
    setAnchorEl(event.currentTarget)
  }

  const handleCloseScenarioMenu = () => setAnchorEl(null)

  const handleScenarioApply = () => {
    if (selectedScenario?.length <= 3) {
      setSelectedScenarioCopy(selectedScenario)
      setIsOptPropertiesLoading(true)
      setRowModesModel({})
      setNewRowCount(0)
      setHasDuplicatePropertyCode(false)
      setIsScenarioGroupChanged(true)
      handleCloseScenarioMenu()
    }
  }

  const handleScenarioCancel = () => {
    setSelectedScenario(selectedScenarioCopy ?? [])
    handleCloseScenarioMenu()
  }

  const handleScenarioCheckboxChange = (scenarioId) => {
    setSelectedScenario((prev) => {
      const isSelected = prev.includes(scenarioId)
      if (isSelected) {
        // Prevent unselecting the last item
        if (prev.length === 1) return prev
        return prev.filter((id) => id !== scenarioId)
      } else {
        // Limit to 3 selections
        if (prev.length >= 3) return prev
        return [...prev, scenarioId]
      }
    })
  }

  const editModeRowsLength = Object.values(rowModesModel).filter((row) => row.mode === 'edit').length

  const handleCancelClose = () => {
    setAddPropertyPopupOpen(false)
  }

  const getApprovalProperties = async () => {
    const accountId = index[0]
    const currentScenarioId = diTrading
      ? isScenarioGroupChanged
        ? selectedScenarioCopy.join(',') || ''
        : (index?.length > 1 && index[1] && index[1].optDetails?.find(optObj => !optObj.isDILink)?.scenarioId)
      : selectedScenarioCopy.join(',') || ''
    API.get(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/opt-properties/accounts/${accountId}/scenarios/${currentScenarioId}`
    )
      .then((response) => {
        if (response.data.length > 0) {
          const proData = []
          response.data.forEach((item) => {
            item.data.forEach((property) => {
              if (property.propertyCode !== 'SCENARIO_GROUP_ID') {
                proData.push({
                  scenarioId: item.scenarioId,
                  scenarioDesc: item.scenarioDesc,
                  propertyCode: property.propertyCode,
                  propertyValue: property.propertyValue,
                  propertySource: property?.propertySource,
                  propertyValueCopy: property?.propertyValue,
                  propertySourceCopy: property?.propertySource,
                  id: randomId()
                })
              }
            })
          })
          setApprovalProperties(proData)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load trade approval properties.', true)
      })
      .finally(() => {
        setIsOptPropertiesLoading(false)
      })
  }

  const getAdhocPropertiesList = () => {
    setIsPropertyCodesLoading(true)
    API.get(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/adhoc-property-list`
    )
      .then((response) => {
        if (response?.data?.length) {
          setPropertiesList(response?.data)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load adhoc properties list.')
      })
      .finally(() => {
        setIsPropertyCodesLoading(false)
      })
  }

  const fetchAccountList = () => {
    setAccountList({ loading: true, data: [] })
    getGeneralAccountListUtil({ serviceId: 'trade', resourceId: 'trade-approvals' })
      .then((res) => {
        const passiveTradingAccs = res?.data?.accountsSummary?.filter(acc => acc?.accountType === 'TRADING' && acc?.strTypeCode !== 'MANAGED') || []
        setAccountList({ loading: false, data: passiveTradingAccs })
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load account list.')
      })
      .finally(() => {
        setAccountList(prev => ({ ...prev, loading: false }))
      })
  }

  useEffect(() => {
    if (user) {
      getAdhocPropertiesList()
      fetchAccountList()
    }
  }, [user])

  useEffect(() => {
    if (user) {
      getApprovalProperties(data)
    }
  }, [user, selectedScenarioCopy])

  const runOptimization = () => {
    const payload = approvalProperties.map((item) => {
      return {
        scenarioId: item.scenarioId,
        propertyCode: item.propertyCode,
        propertyValue: item.propertyValue,
        propertySource: item?.propertySource
      }
    })
    const accId = index[0]
    setIsSubmitting(true)
    API.patch(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/accounts/${accId}`,
      { body: payload }
    )
      .then((response) => {
        showSuccess(response.message)
        handlePopupClose()
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to update adhoc optimization.', true)
      })
      .finally(() => setIsSubmitting(false))
  }

  if (approvalProperties === null) {
    return null
  }

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = (id) => () => {
    const updatedProperties = approvalProperties.filter((row) => row.id !== id)
    const updatedPropertiesWithErrors = updatedProperties.map((row) => ({
      ...row,
      hasError: updatedProperties?.some(
        (otherRow) =>
          otherRow.id !== row.id &&
          otherRow.propertyCode === row.propertyCode &&
          Number(otherRow.scenarioId) === Number(row.scenarioId) && row.propertySource === 'ADHOC'
      )
    }))
    setApprovalProperties(updatedPropertiesWithErrors)
    setNewRowCount(updatedProperties?.filter(row => row.isNew).length)
    setHasDuplicatePropertyCode(updatedPropertiesWithErrors?.some((currentProperty) => currentProperty.hasError))
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleCancelClick = (id, row) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })
  }

  const processRowUpdate = (newRow) => {
    let updatedRow = { ...newRow }
    if (updatedRow.scenarioDesc === null || updatedRow.propertyCode === null || updatedRow.propertyValue === null || updatedRow.propertyValue === '') {
      setRowModesModel({ ...rowModesModel, [updatedRow.id]: { mode: GridRowModes.Edit } })
      return approvalProperties
    }
    // update source and value copy variables on saving new row
    if (newRow?.propertyValue !== newRow?.propertyValueCopy) {
      updatedRow = { ...newRow, propertySource: 'ADHOC' }
    } else {
      updatedRow = { ...newRow, propertySource: newRow?.propertySourceCopy }
    }
    const updatedProperties = approvalProperties.map((row) => (row.id === newRow.id ? updatedRow : row))
    setApprovalProperties(updatedProperties)
    // check if any rows have same property code
    setHasDuplicatePropertyCode(updatedProperties.some((currentProperty, index) => {
      return updatedProperties.find((otherProperty, otherIndex) =>
        otherIndex !== index && otherProperty.propertyCode === currentProperty.propertyCode && Number(otherProperty.scenarioId) === Number(currentProperty.scenarioId)
      )
    }))
    return updatedRow
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const handleAddRecordClick = (values) => {
    const id = randomId()
    const newRow = {
      id,
      isNew: true,
      ...values,
      propertyValueCopy: null,
      propertySourceCopy: null,
      hasError: false
    }
    const hasError = approvalProperties.some(
      (otherProperty) =>
        otherProperty.propertyCode === newRow.propertyCode &&
        Number(otherProperty.scenarioId) === Number(newRow.scenarioId)
    )
    const updatedNewRow = { ...newRow, hasError }
    const updatedApprovalProperties = [updatedNewRow, ...approvalProperties]
    setApprovalProperties(updatedApprovalProperties)

    const hasDuplicates = updatedApprovalProperties.some((currentProperty, index) => {
      return updatedApprovalProperties.find((otherProperty, otherIndex) =>
        otherIndex !== index &&
        otherProperty.propertyCode === currentProperty.propertyCode &&
        Number(otherProperty.scenarioId) === Number(currentProperty.scenarioId)
      )
    })

    setHasDuplicatePropertyCode(hasDuplicates)
    setNewRowCount((prevCount) => prevCount + 1)
    setAddPropertyPopupOpen(false)
  }

  const getEditCellFromType = (params, propertyType) => {
    switch (propertyType) {
      case 'INT':
        return (
          <TextField
            fullWidth
            value={params?.value}
            onChange={(e) => {
              params?.api?.setEditCellValue({ id: params?.row?.id, field: params?.field, value: e.target.value })
            }}
            onKeyDown={isWholeNumber}
            onPaste={wholeNumberPasteHandler}
          />
        )
      case 'DECIMAL':
        return (
          <TextField
            fullWidth
            value={params?.value}
            onChange={(e) => {
              params?.api?.setEditCellValue({ id: params?.row?.id, field: params?.field, value: e.target.value })
            }}
            onKeyDown={isNumberWithSign}
            onPaste={numberWithSignPasteHandler}
          />
        )
      case 'STRING':
      case 'BOOLEAN':
        return (
          <TextField
            fullWidth
            value={params?.value}
            onChange={(e) => {
              params?.api?.setEditCellValue({ id: params?.row?.id, field: params?.field, value: e.target.value })
            }}
          />
        )
      case 'DATE':
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={dayjs(params?.value)}
              onChange={(value) => {
                params?.api?.setEditCellValue({ id: params?.row?.id, field: params?.field, value: dayjs(value).format('YYYY-MM-DD') })
              }}
              format='YYYY-MM-DD'
              fullWidth
            />
          </LocalizationProvider>
        )
      default:
        return ''
    }
  }

  const CustomFooterComponent = ({ hasDuplicatePropertyCode, isOptPropertiesLoading }) => {
    return (
      <GridFooterContainer sx={{ justifyContent: hasDuplicatePropertyCode ? 'space-between' : 'flex-end' }}>
        {
          hasDuplicatePropertyCode && !isOptPropertiesLoading
            ? <Box sx={{ display: 'flex' }}>
              <Alert
                icon={false} severity='error' sx={{
                  border: '1px solid rgb(243, 47, 47)',
                  margin: '5px 8px',
                  padding: '1px 8px',
                  color: 'rgb(243, 47, 47)',
                  backgroundColor: '#ffe7ea',
                  '& .MuiAlert-message': {
                    padding: '3px 0'
                  }
                }}
              >
                {
                  hasDuplicatePropertyCode
                    ? 'Duplicate property code is not allowed for the same scenario.'
                    : ''
                }
              </Alert>
            </Box>
            : ''
        }
        <GridPagination />
      </GridFooterContainer>
    )
  }

  const columns = [
    {
      field: 'scenarioDesc',
      headerName: 'Scenario Description',
      flex: 1
    },
    {
      field: 'propertyCode',
      headerName: 'Property Code',
      flex: 1
    },
    {
      field: 'propertyValue',
      headerName: 'Property Value',
      editable: true,
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => {
        if (params?.row?.propertyCode === 'DI_ASSIST_ACC_LINK') {
          if (!accountList?.loading) {
            const account = accountList?.data?.find(acc => acc?.accountCode === params?.row?.propertyValue)
            return account ? `${account?.accountName} (${account?.accountCode})` : params?.value
          }
          return params?.value
        }
        else if (params?.row?.propertyCode === 'DI_PASSIVE_SCENARIO') {
          const scenarioObj = allScenarioList?.find(scenario => scenario?.scenarioId === Number(params?.row?.propertyValue))
          return scenarioObj?.scenarioCode
        }
      },
      renderEditCell: (params) => {
        const propertyIndex = propertiesList?.findIndex(obj => obj?.propertyCode === params?.row?.propertyCode)
        if (propertyIndex > -1) {
          if (params?.row?.propertyCode === 'DI_ASSIST_ACC_LINK') {
            const handleChange = (e, value) => params?.api?.setEditCellValue({ id: params?.id, field: params?.field, value: value?.accountCode || '' })
            return <AccountCodeSelect accountList={accountList} onChange={handleChange} value={params?.row?.propertyValue || ''} />
          } else if (params?.row?.propertyCode === 'DI_PASSIVE_SCENARIO') {
            const handleChange = (e, value) => params?.api?.setEditCellValue({ id: params?.row?.id, field: params?.field, value: value?.scenarioId || '' })
            return <PassiveScenarioSelect allScenarioList={allScenarioList} onChange={handleChange} value={params?.value || ''} />
          }
          return getEditCellFromType(params, propertiesList[propertyIndex]?.dataType)
        }
        return null
      }
    },
    {
      field: 'propertySource',
      headerName: 'Property Source',
      flex: 1,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key='save'
              icon={<SaveIcon />}
              label='Save'
              onClick={handleSaveClick(id, row)}
            />,
            <GridActionsCellItem
              key='cancel'
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id, row)}
              color='inherit'
            />
          ]
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            disabled={isPropertyCodesLoading}
            key='edit'
            label='Edit'
            className='textPrimary'
            onClick={handleEditClick(id)}
            color='inherit'
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            disabled={isPropertyCodesLoading}
            key='delete'
            label='Delete'
            className='textPrimary'
            onClick={handleDeleteClick(id)}
            color='inherit'
          />
        ]
      }
    }
  ]

  const getRowId = (row) => row.id
  const tableHeaderHTML = columns.map(({ headerName }, index) => <TableCell key={index} sx={{ padding: '8px' }}>{headerName}</TableCell>)
  const rowSkeleton = columns.map(({ headerName }, index) => <TableCell key={index} sx={{ padding: '8px' }}><Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} /></TableCell>)

  return (
    <>
      <Dialog open={isUPShown} onClose={handlePopupClose} fullScreen>
        <DialogTitle>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex' }}>
              <Typography style={{ fontSize: '22px', fontWeight: 400 }}>Update Properties</Typography>
              {editModeRowsLength || newRowCount
                ? (
                  <Alert
                    severity='info'
                    icon={false}
                    sx={{
                      margin: '3px 20px',
                      border: '1px solid #5087C6',
                      padding: '1px 8px',
                      color: '#5087C6',
                      '& .MuiAlert-message': {
                        padding: '3px 0'
                      }
                    }}
                  >{`Unsaved Rows: ${editModeRowsLength}${newRowCount ? `  |  New Rows: ${newRowCount}` : ''}`}
                  </Alert>
                )
                : (
                  ''
                )}
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button variant='text' size='small' startIcon={<AddIcon />} onClick={() => setAddPropertyPopupOpen(true)} sx={{ mr: 1 }}>Add Property</Button>
              {
                !approvalProperties
                  ? ''
                  : isSubmitting
                    ? (
                      <LoadingButton
                        loading
                        loadingPosition='start'
                        startIcon={<SaveIcon />}
                        size='small'
                        variant='outlined'
                      >
                        Run Optimization
                      </LoadingButton>
                    )
                    : (
                      <Button
                        variant='contained'
                        size='small'
                        disabled={Object.keys(rowModesModel).some(rowId => rowModesModel[rowId].mode === GridRowModes.Edit) || hasDuplicatePropertyCode}
                        onClick={() => runOptimization()}
                      >Run Optimization
                      </Button>
                    )
              }
              <FormControl sx={{ width: 200 }}>
                <InputLabel id='scenario-select-label'>Scenario</InputLabel>
                <Select
                  labelId='scenario-select-label'
                  id='scenario-select'
                  multiple
                  value={selectedScenarioCopy ?? []}
                  disabled={isOptPropertiesLoading}
                  size='small'
                  label='Scenario'
                  input={<OutlinedInput label='Scenario' />}
                  onClick={(event) => {
                    if (!isOptPropertiesLoading) handleOpenScenarioMenu(event)
                  }}
                  readOnly
                  displayEmpty
                  renderValue={selected => {
                    if (!selected?.length) return 'Select Scenarios'
                    return selected
                      .map((id) => {
                        const scenario = allScenarioList?.find(s => s.scenarioId === id)
                        return scenario ? `${scenario?.scenarioDescription}` : id
                      })
                      .join(', ')
                  }}
                  endAdornment={
                    <HtmlTooltip
                      title={
                        <>
                          <Typography color='inherit' fontFamily='Open Sans' fontWeight={600}>Selected Scenario</Typography>
                          <Divider sx={{ backgroundColor: '#000000' }} />
                          <Typography sx={{ fontSize: '14px' }} my={1}>
                            {(selectedScenarioCopy ?? [])
                              .map(id => {
                                const scenario = allScenarioList?.find(s => s?.scenarioId === id)
                                return scenario ? `${scenario?.scenarioDescription}` : id
                              })
                              .join(', ') || 'No scenarios selected'}
                          </Typography>
                        </>
                      }
                    >
                      <IconButton sx={{ display: selectedScenarioCopy?.length > 0 ? '' : 'none', mx: 1 }}>
                        <InfoOutlinedIcon fontSize='small' />
                      </IconButton>
                    </HtmlTooltip>
                  }
                />
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleScenarioCancel}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  slotProps={{
                    paper: {
                      sx: { mt: 1.5 }
                    },
                  }}
                >
                  {allScenarioList?.map((scenario) => {
                    const isSelected = selectedScenario?.includes(scenario?.scenarioId)
                    const disableUnselect = isSelected && selectedScenario?.length === 1 // Prevent last item unselect
                    const disableSelect = !isSelected && selectedScenario?.length >= 3 // Max 3 selections
                    return (
                      <MenuItem
                        key={scenario.scenarioId}
                        onClick={() => !(disableUnselect || disableSelect) && handleScenarioCheckboxChange(scenario?.scenarioId)}
                        sx={{ paddingY: '2px', paddingX: 1 }}
                      >
                        <Checkbox
                          checked={isSelected}
                          sx={{ padding: 0.5, marginRight: 1 }}
                          disabled={disableUnselect || disableSelect}
                          size='small'
                        />
                        <Typography sx={{ fontSize: '16px' }}>
                          {scenario?.scenarioDescription}
                        </Typography>
                      </MenuItem>
                    )
                  })}

                  <Divider />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '8px' }}>
                    <Button onClick={handleScenarioCancel} size='small'>Cancel</Button>
                    <Button onClick={handleScenarioApply} variant='contained' size='small'>Apply</Button>
                  </Box>
                </Menu>
              </FormControl>
              <IconButton
                onClick={handlePopupClose}
              >
                <CancelIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ padding: '0px 24px' }}>
          {isOptPropertiesLoading
            ? (
              <Table className='security-holdings-table'>
                <TableHead>
                  <TableRow style={{ position: 'sticky', top: '0', background: 'white' }}>
                    {tableHeaderHTML}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {rowSkeleton}
                  </TableRow>
                  <TableRow>
                    {rowSkeleton}
                  </TableRow>
                  <TableRow>
                    {rowSkeleton}
                  </TableRow>
                </TableBody>
              </Table>
            )
            : (
              <>
                {!approvalProperties
                  ? (
                    <>
                      <h6 style={{ color: 'black', height: '10px', textAlign: 'center' }}>No Data found</h6>
                    </>
                  )
                  : (
                    <>
                      <DataGridPro
                        density='compact'
                        autoHeight
                        rows={approvalProperties}
                        columns={columns}
                        loading={isOptPropertiesLoading}
                        getRowId={getRowId}
                        pagination
                        pageSizeOptions={[10, 15, 25, 50, 100]}
                        editMode='row'
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStart={handleRowEditStart}
                        onRowEditStop={handleRowEditStop}
                        onCellEditStart={handleCellEditStart}
                        onCellEditStop={handleCellEditStop}
                        rowSelection={false}
                        getCellClassName={(params) => params?.row?.hasError === true ? 'error-row' : ''}
                        processRowUpdate={processRowUpdate}
                        initialState={{
                          ...approvalProperties?.initialState,
                          pagination: { paginationModel: { pageSize: 10 } }
                        }}
                        slots={{
                          footer: CustomFooterComponent
                        }}
                        slotProps={{
                          footer: { hasDuplicatePropertyCode, isOptPropertiesLoading }
                        }}
                        sx={{
                          '.error-row': {
                            backgroundColor: '#f05f5f1a'
                          },
                          [`.${gridClasses.cell}:focus`]: {
                            outline: 'none'
                          },
                          [`.${gridClasses['cell--editing']}`]: {
                            outline: 'none'
                          },
                          [`.${gridClasses['cell--editing']}:focus`]: {
                            outline: 'none'
                          },
                          '.MuiOutlinedInput-notchedOutline': {
                            border: 'none'
                          },
                          [`.${gridClasses.main}`]: {
                            height: 'calc(100vh - 72px - 39px - 14px)'
                          },
                          [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                            color: '#74788d',
                            fontWeight: 600
                          },
                          [`.${gridClasses.virtualScroller}`]: {
                            overflowY: 'auto !important',
                            scrollbarGutter: 'stable',
                            scrollbarWidth: 'none'
                          }
                        }}
                      />
                    </>
                  )}
              </>
            )}
        </DialogContent>
      </Dialog>
      {
        addPropertyPopupOpen && (
          <Dialog open={addPropertyPopupOpen} onClose={handleCancelClose} fullWidth maxWidth='sm'>
            <AddPropertyRecordPopup
              handleCancelClick={handleCancelClose}
              allScenarioList={allScenarioList}
              propertiesList={propertiesList}
              savedData={handleAddRecordClick}
              selectedScenarios={selectedScenarioCopy}
              accountList={accountList}
            />
          </Dialog>
        )
      }
    </>
  )
}
export default UpdatePropertiesModal
