import { useContext } from 'react'
import Chart from 'react-apexcharts'
import { Box, Skeleton, Typography } from '@mui/material'
import { formatCurrency } from '../../../../utils/FormateCurrenyInMilion'
import { ScenarioContext, SolutionContext } from '..'

const PerformanceLineChart = ({ isScenarioScreen }) => {
  const solutionData = useContext(SolutionContext)
  const scenarioData = useContext(ScenarioContext)

  const colorArray = ['#31AD80', '#4575AC', '#E06464']

  const formateToUsdCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const isLoading = isScenarioScreen ? scenarioData?.scenarioPerformanceData[scenarioData?.aggId]?.loading : solutionData?.isSolutionPerformanceDataLoading

  const categories = isScenarioScreen ? (scenarioData?.yearCategories || []) : (solutionData?.yearCategories || [])

  const data = isScenarioScreen ? (scenarioData?.scenarioPerformanceData[scenarioData?.aggId]?.data?.performanceData || []) : (solutionData?.solutionPerformanceData || [])

  const options = {
    xaxis: {
      categories,
      tooltip: {
        enabled: true
      }
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return (value < 0 ? '-' : '') + '$' + formatCurrency(value?.toFixed(2))
        }
      },
      tickAmount: 3
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0
    },
    chart: {
      zoom: {
        enabled: false
      },
      height: 300,
      toolbar: {
        show: false
      },
      redrawOnWindowResize: true
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const values = series?.map((s, i) => {
          const value = s.length ? Number((s[dataPointIndex])?.toFixed(2)) : undefined
          return value !== undefined
            ? `
                <div>
                    <span style="display: inline-block; width: 10px; height: 10px; background-color: ${w.config.colors[i]}; border-radius: 50%; margin-right: 5px;"></span>
                    <span>${value === -0 ? '0.00' : formateToUsdCurrency.format(value)}</span>
                </div>`
            : ''
        }).join('')

        return `<div style="padding: 8px; border-radius: 4px; color: #000; background-color: #fff;">
            ${values}
        </div>`
      }
    },
    legend: {
      show: false
    },
    stroke: {
      curve: 'straight',
      width: 1.8
    },
    fill: {
      type: 'solid',
      opacity: 0
    },
    colors: colorArray,
    noData: {
      text: 'No performance data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '18px',
        fontFamily: undefined
      }
    }
  }

  return (
    <>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: {
          xs: 'column',
          sm: 'row'
        },
        gap: {
          xs: '10px',
          sm: '0px'
        }
      }}
      >
        <Typography sx={{ fontWeight: 600, fontSize: '16px', color: '#2f2f2f' }}>{isScenarioScreen ? 'Transition Alpha' : 'Performance'}</Typography>
        <Box sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: {
            xs: 'center'
          }
        }}
        >
          {
            data?.map((x, i) => (
              <Box key={i} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ height: '14px', width: '14px', borderRadius: '2px', background: colorArray[i] }} />
                <Typography sx={{ fontSize: '10px', ml: '5px', color: '#000', fontWeight: 600 }}>{x.name}</Typography>
              </Box>
            ))
          }
        </Box>
      </Box>
      {
        isLoading
          ? <Skeleton variant='rectangular' height={isScenarioScreen ? 270 : 200} />
          : <Chart options={options} series={data || []} type='area' height={isScenarioScreen ? 270 : 200} />
      }
    </>
  )
}

export default PerformanceLineChart
