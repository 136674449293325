import { useContext, useEffect } from 'react'
import Chart from 'react-apexcharts'
import { Box, Fade, IconButton, MenuItem, Select, Skeleton, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { TbInfoSquare } from 'react-icons/tb'
import { HtmlTooltip } from '../../../../components/CustomTooltip'
import { SummaryContext, UmaContext } from '..'

const ReturnsOverTime = () => {
  const summaryData = useContext(SummaryContext)
  const umaContextData = useContext(UmaContext)

  const options = {
    grid: {
      show: true,
      borderColor: '#74788D4D',
      strokeDashArray: 12,
      position: 'back',
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: true
      }
    },
    yaxis: [
      {
        title: {
          text: 'Returns (%)',
          offsetX: 0,
          style: {
            fontSize: '12px',
            color: '#74788D',
            fontWeight: 500
          }
        },
        labels: {
          formatter: function (value) {
            return value?.toFixed(2)
          }
        },
        tickAmount: 7
      }
    ],
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0
    },
    chart: {
      zoom: {
        enabled: false
      },
      height: 300,
      toolbar: {
        show: false
      },
      redrawOnWindowResize: true
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const values = series.map((s, i) => {
          const value = s.length ? Number((s[dataPointIndex])?.toFixed(2)) : undefined
          const date = w.config.series?.length ? w.config.series[0]?.data[dataPointIndex]?.x ?? '' : ''
          return value !== undefined ? `
              <div style="font-size: 12px; color: #656F70;">
              <div>
                <span>Return: </span>
                <span>${value === -0 ? '0.00%' : value + '%'}</span>
              </div>
              <div>
                  <span>Time: </span>
                  <span>${date}</span>
                  </div>
              </div>`
            : ''
        }).join('')

        return `<div style="padding: 8px; border-radius: 4px; color: #000; background-color: #fff;">
          ${values}
      </div>`
      },
    },
    legend: {
      show: false
    },
    markers: {
      size: 4,
      colors: ['#ffffff'],
      strokeColors: ['#4575AC'],
      strokeWidth: 4,
      hover: {
        size: 5,
        strokeWidth: 5
      }
    },
    stroke: {
      curve: 'straight',
      width: 4
    },
    fill: {
      type: 'solid',
      opacity: 0
    },
    colors: ['#2A96CE', '#009445', '#1C75BC', '#002A59', '#6A86A6', '#F7941E', '#F15A2B', '#D7F422', '#7B5231', '#2962FF'],
    noData: {
      text: 'No performance data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '18px',
        fontFamily: undefined
      }
    }
  }

  const scenarioList = umaContextData?.umaDetailsData?.data?.scenarioUMA || []

  useEffect(() => {
    summaryData?.setSelectedReturnOverTimeScenario(scenarioList && scenarioList?.length ? scenarioList[0]?.aggId : '')
  }, [scenarioList])

  const handleScenarioChange = (e) => {
    summaryData?.setSelectedReturnOverTimeScenario(e.target.value)
  }

  const isLoading = summaryData?.returnOverTimeData?.loading || false
  const returnOverTimeData = !isLoading && summaryData?.returnOverTimeData?.data && summaryData?.returnOverTimeData?.data?.length ?
    summaryData?.returnOverTimeData?.data?.find((data) => data.aggId === summaryData?.selectedReturnOverTimeScenario) : []

  const data = [{
    name: returnOverTimeData?.aggName || '',
    data: returnOverTimeData?.preTaxDailyReturns?.map(obj => ({
      x: obj?.dataDate, y: obj?.cumulatedPreTaxDailyReturns * 100
    })) || []
  }]

  const handleOpenScenarioMenu = () => {
    document.body.classList.add('model-open')
  }

  const handleCloseScenarioMenu = () => {
    document.body.classList.remove('model-open')
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 600, fontSize: '16px', color: '#2f2f2f' }}>Returns Over Time</Typography>
          <HtmlTooltip
            title='Returns Over Time'
            tooltipStyle={{ backgroundColor: '#000', color: '#fff', fontSize: '11px', align: 'center' }}
          >
            <IconButton size='small'>
              <TbInfoSquare style={{ height: '20px', width: '20px', color: '#2F2F2F' }} />
            </IconButton>
          </HtmlTooltip>
        </Box>
        <Box>
          <Select
            value={summaryData?.selectedReturnOverTimeScenario}
            onChange={handleScenarioChange}
            onOpen={handleOpenScenarioMenu}
            onClose={handleCloseScenarioMenu}
            MenuProps={{
              TransitionComponent: Fade,
            }}
            IconComponent={ExpandMoreIcon}
            sx={{
              '.MuiSelect-select': {
                width: '120px',
                padding: '5px 10px',
                borderRadius: '8px',
                fontSize: '12px',
                fontWeight: 600
              }
            }}
          >
            {
              scenarioList.map((scenario, index) => (
                <MenuItem key={index} value={scenario.aggId}
                  sx={{
                    fontSize: '12px',
                    py: '4px',
                    fontWeight: 600
                  }}
                >
                  {scenario.aggName}
                </MenuItem>
              ))
            }
          </Select>
        </Box>
      </Box>
      {
        isLoading ? (
          <Skeleton variant='rectangular' height='360px' sx={{ mt: '20px' }} />
        ) : (
          <Chart options={options} series={data} type='area' height={380} />
        )
      }
    </>
  )
}

export default ReturnsOverTime