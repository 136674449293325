import React from 'react'
import {
  getBezierPath,
  EdgeLabelRenderer,
  BaseEdge
} from '@xyflow/react'
import { formatCurrencyWithSymbol } from '../../../../utils/FormateCurrenyInMilion'

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  style
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  })
  return (
    <>
      <BaseEdge id={id} path={edgePath} style={style} />
      {data.showLabel
        ? (
          <EdgeLabelRenderer>
            <div
              style={{
                position: 'absolute',
                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                background: '#3BBFA3',
                padding: 8,
                color: 'white',
                borderRadius: 5,
                fontSize: 14,
                fontWeight: 700
              }}
              className='nodrag nopan'
            >
              {formatCurrencyWithSymbol(data?.value, 2, '$')}
            </div>
          </EdgeLabelRenderer>
        )
        : ''
      }
    </>
  )
}

export default CustomEdge
