import React, { useState } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import refreshIcon from '../../../../assets/images/refreshIcon.svg'
import UpdatePropertiesModal from '../UpdatePropertiesModal'

const CustomUpdatePropertiesCell = (params) => {
  const [editIndex, setEditIndex] = useState()
  const [isUPShown, setIsUPShown] = useState(false)

  const viewUpdatePropertiesModalShow = (index, itemAP) => {
    const approvalPropertiesParameter = [index, itemAP]
    setEditIndex(approvalPropertiesParameter)
    setIsUPShown(true)
  }

  const handlePopupClose = () => {
    setIsUPShown(false)
  }

  return (
    <>
      <Tooltip title='Adhoc Optimization & Update Properties'>
        <IconButton
          style={{
            marginLeft: '1.7rem'
          }}
          className='action-clock-btn'
          onClick={() => {
            viewUpdatePropertiesModalShow(
              params.row.account.accountId,
              params.row.account
            )
          }}
        >
          <img
            src={refreshIcon}
            alt=''
          />
        </IconButton>
      </Tooltip>
      {
          isUPShown && (
            <UpdatePropertiesModal
              index={editIndex}
              isUPShown={isUPShown}
              selectedItem={params?.data}
              handlePopupClose={handlePopupClose}
              allScenarioList={params?.allScenarioList}
              diTrading={params?.diTrading || false}
            />
          )
         }
    </>
  )
}

export default CustomUpdatePropertiesCell
