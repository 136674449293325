import Consideration from '../components/Consideration'
import AccountTransactionAndTransfer from './AccountTransactionAndTransfer'
import TransfersSellChart from './TransfersSellChart'
import { Card } from '../components'
import AccountSankeyTransitionChart from './AccountSankeyTransitionChart'
import TargetAccountTable from './TargetAccountTable'

const ScenarioSummary = ({ aggId, toggleSummaryChartExpandView }) => {
  return (
    <>
      <Card>
        <TransfersSellChart aggId={aggId} />
      </Card>
      <Card style={{
        mt: '16px'
      }}>
        <TargetAccountTable aggId={aggId} />
      </Card>
      <Card style={{
        '& .react-flow__pane': {
          background: '#FBFBFD'
        },
        mt: '16px'
      }}>
        <AccountSankeyTransitionChart toggleSummaryChartExpandView={toggleSummaryChartExpandView} aggId={aggId} />
      </Card>
      <AccountTransactionAndTransfer aggId={aggId} />
      <Consideration umaType='SCENARIO_TRANSITION' aggId={aggId} />
    </>
  )
}

export default ScenarioSummary