import { useCallback, useLayoutEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { addEdge, ReactFlow, ReactFlowProvider, useEdgesState, useNodesState, useReactFlow } from '@xyflow/react'
import ELK from 'elkjs/lib/elk.bundled.js'
import '@xyflow/react/dist/style.css'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import AccountNode from './AccountNode'
import CustomEdge from './CustomEdge'
import MidNode from './MidNode'

const nodeTypes = {
  midNode: MidNode,
  account: AccountNode
}

const edgeTypes = {
  custom: CustomEdge
}

const midNodeDefaultStyle = {
  borderRadius: '12px',
  border: '1px solid #E6ECF3'
}

const elk = new ELK()

const getLayoutedElements = async (nodes, edges, options = {}) => {
  const isHorizontal = options?.['elk.direction'] === 'RIGHT'
  const graph = {
    id: 'root',
    layoutOptions: options,
    children: nodes.map((node) => ({
      ...node,
      targetPosition: isHorizontal ? 'left' : 'top',
      sourcePosition: isHorizontal ? 'right' : 'bottom',
    })),
    edges
  }

  return elk
    .layout(graph)
    .then((layoutedGraph) => ({
      nodes: layoutedGraph.children.map((node) => ({
        ...node,
        position: { x: node.x, y: node.y },
      })),
      edges: layoutedGraph.edges,
    }))
    .catch(console.error)
}

const LayoutFlow = ({ data, transferDetails = [], width }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const { fitView } = useReactFlow()
  const [clickedNodes, setClickedNodes] = useState([])
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  )

  const elkOptions = {
    'elk.algorithm': 'layered',
    'elk.direction': 'RIGHT',
    'elk.layered.nodePlacement.strategy': 'NETWORK_SIMPLEX',
    'elk.edgeRouting': 'SPLINES',
    'elk.layered.crossingMinimization.semiInteractive': true,
    'elk.partitioning.activate': false,
    'elk.considerModelOrder': 'NODES_AND_EDGES',
    'elk.separateConnectedComponents': false,
    'elk.layered.nodePlacement.bk.fixedAlignment': 'BALANCED',
    'elk.layered.considerModelOrder.strategy': 'PREFER_EDGES',
    'elk.layered.spacing.nodeNodeBetweenLayers': '400',
    'elk.spacing.nodeNode': '40'
  }

  const centerNodes = {
    cash: {
      label: 'Cash',
      key: 'cashMv',
    },
    sell: {
      label: 'Sell',
      key: 'sellMv',
    },
    transfer: {
      label: 'Transfer',
      key: 'transferMv',
    }
  }

  const onLayout = useCallback(({ direction, useInitialNodes = false }) => {
    const opts = { 'elk.direction': direction, ...elkOptions }
    const ns = useInitialNodes ? data?.flowChartNodes || [] : nodes
    const es = useInitialNodes ? data?.flowChartEdges || [] : edges

    getLayoutedElements(ns, es, opts).then(
      ({ nodes: layoutedNodes, edges: layoutedEdges }) => {
        setNodes(layoutedNodes)
        setEdges(layoutedEdges)
        window.requestAnimationFrame(() => fitView())
      },
    )
  }, [nodes, edges, data, width])

  useLayoutEffect(() => {
    onLayout({ direction: 'RIGHT', useInitialNodes: true })
  }, [data, width])

  const handleNodeClick = (node) => {
    let updatedClickedNodes = []
    if (clickedNodes.includes(node.id)) {
      updatedClickedNodes = clickedNodes.filter(nodeId => nodeId !== node.id)
    } else {
      updatedClickedNodes = [...clickedNodes, node.id]
    }

    const centerNodeIds = Object.keys(centerNodes).map(key => (data?.midNodeData?.[key]?.id || ''))

    setClickedNodes(updatedClickedNodes)
    if (!updatedClickedNodes.length) {
      setEdges(data?.flowChartEdges)
      setNodes(prev => prev.map(node => ({
        ...node,
        style: (centerNodeIds.includes(node.id)
          ? midNodeDefaultStyle
          : {}
        )
      })))
      return
    }

    let transfersWithSellOrTransfer = [], filteredTransfers = [], finalTransfers = []
    const isAnyCenterNodeSelected = Object.keys(centerNodes).some(key => {
      return updatedClickedNodes.includes(data.midNodeData?.[key].id)
    })
    if (isAnyCenterNodeSelected) {
      // filter valid transfers details if sell or transfer is select
      transfersWithSellOrTransfer = transferDetails.filter(transfer => {
        let isValid = false
        Object.keys(centerNodes).map(key => {
          if (updatedClickedNodes.includes(data.midNodeData[key].id)) {
            isValid = isValid || (!!transfer?.[centerNodes[key]?.key])
          }
        })
        return isValid
      })
    }

    // add that to finalTransfers
    transfersWithSellOrTransfer.forEach(transfer => {
      finalTransfers.push(transfer)
    })

    // again filter transferDetails based on selected nodes
    filteredTransfers = transferDetails.filter(transfer => (
      updatedClickedNodes.includes(transfer.srcAccount) || updatedClickedNodes.includes(transfer.dstAccount)
    ))

    // combine above filtered data and keep transfer detail only once
    filteredTransfers.forEach(transfer => {
      if (!finalTransfers.find(edge => edge?.srcAccount === transfer?.srcAccount && edge?.dstAccount === transfer?.dstAccount)) {
        finalTransfers.push(transfer)
      }
    })

    const finalEdges = []
    finalTransfers.forEach(transfer => {
      Object.keys(centerNodes).map(key => {
        const id = data.midNodeData[key].id || ''
        if (transfer?.[centerNodes[key].key] && (updatedClickedNodes.includes(id) || updatedClickedNodes.includes(transfer?.srcAccount) || updatedClickedNodes.includes(transfer?.dstAccount))) {
          const sourceIndex = finalEdges.findIndex(edge => edge?.source === transfer?.srcAccount && edge?.target === id)
          if (sourceIndex > -1) {
            finalEdges[sourceIndex].data.value += transfer?.[centerNodes[key].key]
          } else {
            finalEdges.push({
              source: transfer?.srcAccount,
              target: id,
              data: {
                value: transfer?.[centerNodes[key]?.key]
              }
            })
          }
          const targetIndex = finalEdges.findIndex(edge => edge?.source === id && edge?.target === transfer?.dstAccount)
          if (targetIndex > -1) {
            finalEdges[targetIndex].data.value += transfer?.[centerNodes[key]?.key]
          } else {
            finalEdges.push({
              source: id,
              target: transfer?.dstAccount,
              data: {
                value: transfer?.[centerNodes[key]?.key]
              }
            })
          }
        }
      })
    })

    const newEdges = edges.map(edge => {
      const edgeObj = finalEdges.find(newEdge => newEdge?.source === edge.source && newEdge?.target === edge?.target)
      if (!edgeObj) {
        return {
          ...edge,
          data: {
            ...edge?.data,
            showLabel: false
          },
          animated: false,
          style: {
            ...edge.style,
            opacity: 0.2
          }
        }
      } else {
        return {
          ...edge,
          data: {
            ...edgeObj?.data,
            showLabel: true
          },
          animated: true,
          style: {
            ...edge?.style,
            stroke: '#3BBFA3',
            opacity: 1
          }
        }
      }
    })

    const nodesToHightlight = []
    finalEdges.forEach(edge => {
      if (!nodesToHightlight.includes(edge?.source)) {
        nodesToHightlight.push(edge?.source)
      }
      if (!nodesToHightlight.includes(edge?.target)) {
        nodesToHightlight.push(edge?.target)
      }
    })

    setEdges(newEdges)

    setNodes(prev => prev.map(node => {
      if (updatedClickedNodes.includes(node?.id)) {
        return {
          ...node,
          style: {
            opacity: 1,
            border: '3px solid #3BBFA3',
            borderRadius: '8px'
          }
        }
      } else if (nodesToHightlight.includes(node.id)) {
        return {
          ...node,
          style: {
            opacity: 1,
            ...(centerNodeIds.includes(node.id)
              ? midNodeDefaultStyle
              : {}
            )
          }
        }
      } else {
        return {
          ...node,
          style: {
            opacity: 0.2,
            ...(centerNodeIds.includes(node.id)
              ? midNodeDefaultStyle
              : {}
            )
          }
        }
      }
    }))
  }

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      proOptions={{ hideAttribution: true, account: 'paid-pro' }}
      nodesConnectable={false}
      nodesDraggable={false}
      fitView
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onNodeClick={(e, node) => handleNodeClick(node)}
      onClick={(e) => {
        document.body.dispatchEvent(new Event('mousedown'))
      }}
    >
      <Box sx={{ position: 'absolute', top: 0, right: 0, left: 0, alignItems: 'center', display: 'flex', justifyContent: 'center', zIndex: 4, gap: '16px' }}>
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <Box sx={{ height: '14px', width: '14px', background: '#CFD53A', borderRadius: '2px' }}></Box>
          <Typography sx={{ fontSize: '10px', marginRight: '6px' }}>Active Funds</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <Box sx={{ height: '14px', width: '14px', background: '#1454A6', borderRadius: '2px' }}></Box>
          <Typography sx={{ fontSize: '10px' }}>Passive Funds</Typography>
        </Box>
      </Box>
    </ReactFlow>
  )
}

const ExpandedGroupView = (props) => {
  const { width } = useWindowDimensions()
  return (
    <ReactFlowProvider>
      <LayoutFlow {...props} width={width} />
    </ReactFlowProvider>
  )
}

export default ExpandedGroupView
