import { useRef } from 'react'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/react'

export function useErrorToast () {
  const toastId = useRef(null)
  const options = {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: false,
    theme: 'colored',
    whiteSpace: 'pre-line',
    pauseOnFocusLoss: false
  }

  const showError = (error, sessionExpiredToast = false, extraOptions = {}, customMessage = null, overrideStatusCheck = false) => {
    let errorMessage
    if (typeof error === 'string') {
      // If the error is a string, use it as the message directly
      errorMessage = error
    } else {
      // Handle error object with response data
      const userMessage = error?.response?.data?.errorInfo?.userMessage
      errorMessage = userMessage || customMessage || 'Something went wrong.'
      Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error?.message || customMessage)
    }
    // Show only one toast for session expired message
    if (sessionExpiredToast) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(errorMessage, { ...options, ...extraOptions })
      }
    } else {
      if (!overrideStatusCheck && typeof error !== 'string' && (!error?.response?.status || error?.response?.status < 400 || error?.response?.status >= 500)) {
        return
      }
      toast.error(errorMessage, { ...options, ...extraOptions })
    }
  }

  const sendSentryError = (error) => {
    const userMessage = error?.response?.data?.errorInfo?.userMessage || error?.message
    Sentry.captureException(userMessage)
  }

  return { showError, sendSentryError }
}
