import { useState } from 'react'
import { InfoOutlined } from '@mui/icons-material'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import {
  Box,
  Card,
  IconButton,
  Typography,
  Tooltip,
  tooltipClasses
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { right } from '@popperjs/core'
import DisableEditIcon from '../../../assets/images/disableEdit.svg'
import EditIcon from '../../../assets/images/edit.svg'
import { useArisPMDataContext } from '../../../contexts/IPSPolicyContext'
import '../IPSPage.css'
import { EditDialog } from './DialogEditPolicy'
import { IOSSwitch } from './IOSSwitch'
import { useIpsAccess } from '../../../hooks/useIpsAccess'
import { useAuth } from '../../../contexts/AuthContext'

export function DataCard ({ onDataChange, onDataRemove, pendingSubmitIds, isUnderApproval }) {
  const arisPMData = useArisPMDataContext()
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [editData, setEditData] = useState({})
  const { user } = useAuth()
  const policyEditAccess = useIpsAccess('Policy')

  const handleSwitchChange = (event, groupIndex, dataIndex) => {
    const cardToUpdate = { ...arisPMData[groupIndex].policies[dataIndex] }
    cardToUpdate.checked = event?.target?.checked
    cardToUpdate.policyGroup = arisPMData[groupIndex]?.policyGroup
    if (event?.target?.checked) {
      cardToUpdate.parameters.forEach((param) => { param.value = param.value || param.options.find((option) => option.isDefault)?.optionValue; param.currentValue = param.value || param.options.find((option) => option.isDefault)?.optionValue })
      if (cardToUpdate.status === 'UNDER_APPROVAL' || cardToUpdate.status === 'APPROVED') {
        cardToUpdate.remove = true
      }
      onDataChange(cardToUpdate)
    } else {
      onDataRemove(cardToUpdate)
    }
  }

  const handleEditClick = (data, groupIndex) => {
    setEditData(data)
    setEditDialogOpen(true)
  }

  const handleSaveClick = (editData) => {
    if (editData) {
      const editGroup = arisPMData.find(obj => obj.policies.some(policy => policy.sponsorPolicyMapId === editData.sponsorPolicyMapId))
      if (editGroup) {
        if (!editData.policyGroup) {
          editData.policyGroup = editGroup.policyGroup
        }
        let str = editGroup.policies[editData.policyIndex].policyText
        editGroup.policies[editData.policyIndex].parameters.map((param, index) => {
          const oldParam = `${param.uom === '$' ? `${param.uom}` : ''}${param.value}${param.uom === '%' ? param.uom : (param.uom !== 'NA' && param.uom !== '$') ? ` ${param.uom}` : ''}`
          const strikedParam = `${param.uom === '$' ? `${param.uom}` : ''}${param.currentValue}${param.uom === '%' ? param.uom : (param.uom !== 'NA' && param.uom !== '$') ? ` ${param.uom}` : ''}`
          const newParam = `${editData.parameters[index].uom === '$' ? `${editData.parameters[index].uom}` : ''}${editData.parameters[index].value}${editData.parameters[index].uom === '%' ? editData.parameters[index].uom : (editData.parameters[index].uom !== 'NA' && editData.parameters[index].uom !== '$') ? ` ${editData.parameters[index].uom}` : ''}`
          //  replace old selected value with new selected value if it is not changing first time
          if (editData.parameters[index].value !== editData.parameters[index].currentValue && param.value !== editData.parameters[index].currentValue) {
            str = str.replace(`<strike>${strikedParam}</strike> ${oldParam}`, `<strike>${strikedParam}</strike> ${newParam}`)
          } else {
            //  replace default value with new selected value if it is changing first time
            if (editData.parameters[index].value !== editData.parameters[index].currentValue) {
              str = str.replace(/<strike>(.*?)<\/strike>/g, (match, matchedString) => {
                if (param.uom !== 'NA' && !matchedString.includes(editData.parameters[index].uom)) {
                  return `<strike>${matchedString}</strike>`
                } else {
                  return ''
                }
              })
              if (strikedParam === '5 days' && editGroup.groupIndex === 0 && editData.policyIndex === 0) {
                let count = 0
                const regex = new RegExp(strikedParam, 'g')

                str = str.replace(regex, (match) => {
                  count++
                  if (count === 2) {
                    return `<strike>${match}</strike> ${newParam}`
                  } else {
                    return match
                  }
                })
              } else {
                str = str.replace(strikedParam, `<strike>${strikedParam}</strike> ${newParam}`)
              }
            } else {
              //  replace striked value with default value if it is set to default value
              str = str.replace(`<strike>${strikedParam}</strike> ${oldParam}`, strikedParam)
            }
          }
        })
        if (editData.parameters.every((param) => param.value === param.currentValue)) {
          if (editData.status === 'UNDER_APPROVAL' || editData.status === 'APPROVED') {
            editData.remove = true
          }
        }
        editData.policyText = str
      }

      setEditDialogOpen(false)
      onDataChange(editData)
    }
  }

  const tooltipContent = (infoData) => (
    <div>
      <Typography sx={{ padding: '8px', textAlign: 'left' }}>
        {infoData}
      </Typography>
    </div>
  )

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      background: '#FFFFFF',
      boxShadow: '0px 1.26482px 12.6482px rgba(0, 0, 0, 0.15)',
      borderRadius: '12.6482px',
      color: '#34475A'
    }
  }))

  return (
    <>
        {arisPMData?.map((group, groupIndex) => (
          <Box key={group.groupIndex}>
            <Typography
              variant='h6'
              sx={{
                fontSize: '18px',
                my: groupIndex === 0 ? 1 : 0,
                color: '#34475A',
                fontWeight: 600
              }}
            >
              {group.policyGroup}
            </Typography>
            {group?.policies?.map((data, dataIndex) => (
              <Box
                display='flex'
                flexDirection='column'
                borderRadius={10}
                key={data.policyIndex}
              >
                <Card
                  className='PolicyIPSCard'
                  height='54px'
                  sx={{
                    bgcolor: (data.status !== 'INACTIVE' || data.checked) ? '#FFFFFF' : '#F7F7F7',
                    marginBottom: '10px',
                    boxShadow: '0px 1px 8px rgba(60, 60, 60, 0.15)'
                  }}
                >
                  <Box display='flex' alignItems='center' px={2}>
                    <Typography
                      variant='body1'
                      flexGrow={1}
                      className='PolicyIPSCardDesc'
                      display='flex'
                      alignItems='center'
                    >
                      {data.policyTitle}&nbsp;
                      {data?.policyInfo
                        ? (
                          <LightTooltip
                            title={tooltipContent(data?.policyInfo)}
                            placement={right}
                          >
                            <span style={{ marginLeft: '10px', marginTop: '8px' }}>
                              <InfoOutlined fontSize='small' />
                            </span>
                          </LightTooltip>
                          )
                        : (
                            ''
                          )}
                    </Typography>
                    <Tooltip
                      title={
                        (data.status === 'UNDER_APPROVAL' || data.status === 'APPROVED' || data.status === 'DEACTIVATING') &&
                         (user?.userGroup !== 'pm' && policyEditAccess ? isUnderApproval : true)
                          ? 'View'
                          : 'Edit'
                      }
                    >
                      <span>
                        {(data.status === 'UNDER_APPROVAL' || data.status === 'APPROVED' || data.status === 'DEACTIVATING') &&
                         (user?.userGroup !== 'pm' && policyEditAccess ? isUnderApproval : true)
                          ? <IconButton
                              onClick={() => handleEditClick(data, groupIndex)}
                            >
                            <VisibilityOutlinedIcon />
                          </IconButton>
                          : <IconButton
                              onClick={() => handleEditClick(data, groupIndex)}
                              disabled={!data.checked || isUnderApproval || user?.userGroup === 'pm'}
                              style={{ opacity: !data.checked || isUnderApproval || user?.userGroup === 'pm' ? 0.5 : 1 }}
                            >
                            <img
                              src={(data.checked || data.status !== 'APPROVED') ? EditIcon : DisableEditIcon}
                              alt=''
                            />
                          </IconButton>}
                      </span>
                    </Tooltip>
                    <IOSSwitch
                      checked={data.checked || (data.status === 'UNDER_APPROVAL' && !pendingSubmitIds.includes(data.policyId)) || (data.status === 'APPROVED' && !pendingSubmitIds.includes(data.policyId))}
                      onChange={(event) =>
                        handleSwitchChange(event, groupIndex, dataIndex)}
                      backgroundColor={
                        data.status === 'UNDER_APPROVAL'
                          ? '#EFAE3A'
                          : data.status === 'DEACTIVATING'
                            ? '#F05F5F'
                            : (data.checked && (data.status === 'INACTIVE' || (data.status === 'APPROVED' && pendingSubmitIds.includes(data.policyId)))) ? '#34C38F' : undefined
                      }
                      disabled={
                        user?.userGroup === 'pm' || (policyEditAccess ? isUnderApproval : true)
                      }
                    />
                  </Box>
                </Card>
              </Box>
            ))}
          </Box>
        ))}
      <EditDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        onSave={handleSaveClick}
        data={editData}
        isUnderApproval={isUnderApproval}
      />
    </>
  )
}
