import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import { yupResolver } from '@hookform/resolvers/yup'
import AddIcon from '@mui/icons-material/Add'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SaveIcon from '@mui/icons-material/Save'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  ThemeProvider,
  Typography
} from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GridActionsCellItem, gridClasses, GridRowEditStopReasons, GridRowModes, GridToolbarContainer, useGridApiRef } from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // Import the UTC plugin
import filesize from 'filesize'
import Papa from 'papaparse'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'
import addIcon from '../../../assets/images/icon-add-plus-circle.svg'
import fileIcon from '../../../assets/images/icon-file.svg'
import CustomCountryCodeField from '../../../components/CustomCountryCodeField'
import CustomDateComponent from '../../../components/CustomDateComponent'
import CustomPurchaseCostField from '../../../components/CustomPurchaseCostField'
import CustomSecurityNameField from '../../../components/CustomSecurityNameField'
import CustomSharesField from '../../../components/CustomSharesField'
import InvalidRowEntriesPopup from '../../../components/InvalidRowEntriesPopup'
import SchwabDataPopup from '../../../components/SchwabDataPopup'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { isNumberWithSign, numberWithSignPasteHandler } from '../../../utils/NumberUtils'
import { checkInstrumentSearchQuery } from '../../../utils/searchQueryUtils'
import { InputAdornmentTextField } from '../../AccountOnboarding/components/inputAdornmentTextfield'
import Loader from '../../Loader'
import '../import-portfolio.scss'
import { SuccessUploadModal } from './SuccessUploadModal'
import { SampleTaxlotTable } from './sampleTaxlotTable'
import AddPortfolioEntryPopup from '../../../components/AddPortfolioEntryPopup'

// Extend dayjs with the utc plugin
dayjs.extend(utc)

const UploadForm = ({ submitRef, getTaxlot, selectedAccount }) => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const params = useParams()
  const validationSchema = Yup.object({
    uploadType: Yup.string(),
    totalPortfolioMarketValue: Yup.string(),
    totalCashValue: Yup.string(),
    securityTaxLotsFile: Yup.mixed().required('A file is required')
  })
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [showSecondMsg, setShowSecondMsg] = useState(true)
  const [modalShow, setModalShow] = useState(false)
  const [successMessage, setSuccessMessage] = useState('Validating')
  const [isSecurityOptionsLoading, setIsSecurityOrCountryOptionsLoading] = useState(false)
  const [file, setFile] = useState(null)
  const [fileId, setFileId] = useState('')
  const [stagedData, setStagedData] = useState([])
  const copyStagedData = useRef([])
  const [newRowCount, setNewRowCount] = useState(0)
  const [totalCashValue, setTotalCashValue] = useState()
  const [options, setOptions] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showThirdMsg, setShowThirdMsg] = useState(false)
  const datasecure = localStorage.getItem('object')
  const [invalidStagedData, setInvalidStagedData] = useState([])
  const invalidCopyStagedData = useRef([])
  const [showInvalid, setShowInvalid] = useState(false)
  const [countryOptions, setCountryOptions] = useState([])
  const [portfolioDate, setPortfolioDate] = useState()
  const [cashFieldError, setCashFieldError] = useState(false)
  const [cashFieldErrorMessage, setCashFieldErrorMessage] = useState('')
  const scrollToTopRef = useRef(null)
  const [isErrorModelOpen, setIsErrorModelOpen] = useState(false)
  const [errorModalContent, setErrorModalContent] = useState([])
  const [portfolioDataFromSchwab, setPortfolioDataFromSchwab] = useState({})
  const [validatedPortfolioData, setValidatedPortfolioData] = useState({})
  const [isSchwabDataModelOpen, setIsSchwabDataModelOpen] = useState(false)
  const [rowModesModel, setRowModesModel] = useState({})
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const apiRef = useGridApiRef()
  const [addPortfolioEntryPopUpOpen, setAddPortfolioEntryPopUpOpen] = useState(false)

  const getStoreData = JSON.parse(datasecure)

  const accountTypeCode = selectedAccount?.accountType
  const CheckAccountType = () => {
    if (accountTypeCode === 'TRANSITION') {
      setShowThirdMsg(false)
    } else {
      setShowThirdMsg(true)
    }
  }

  const formateToUsdCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  useEffect(() => {
    if (modalShow) {
      getTaxlot()
    }
  }, [modalShow])

  useEffect(() => {
    if (user) {
      fetchISOCountryCodeList()
    }
  }, [user])

  const handleCancelClose = () => {
    setAddPortfolioEntryPopUpOpen(false)
  }

  const {
    register,
    formState: { errors },
    setValue,
    control,
    watch
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      uploadType: 'manual'
    }
  })

  const uploadTypeWatch = watch('uploadType')

  const columns = [
    {
      field: 'name',
      headerName: 'Security Name',
      flex: 2,
      editable: true,
      renderEditCell: (params, index) => {
        return (
          <CustomSecurityNameField
            {...params}
            onSecurityNameChange={onSecurityNameChange}
            isSecurityOptionsLoading={isSecurityOptionsLoading}
            options={options}
          />
        )
      }
    },
    {
      field: 'symbol',
      headerName: 'Symbol',
      flex: 1,
      editable: true,
      valueGetter: (params) =>
        params?.value === '__USD' ? '__CASH' : params?.value,
      renderEditCell: (params) => {
        return params?.row?.symbol
      }
    },
    {
      field: 'shares',
      headerName: 'Shares',
      flex: 1,
      editable: true,
      type: 'number',
      valueGetter: (params) => {
        return params?.value !== null && params?.value !== undefined && !isNaN(params?.value)
          ? Number(params?.value)
          : params?.value
      },
      renderCell: (params) => {
        return params.value !== null && params.value !== undefined && !isNaN(params.value)
          ? Number(params.value)
          : ''
      },
      renderEditCell: (params) => {
        return (
          <CustomSharesField
            {...params}
          />
        )
      }
    },
    {
      field: 'purchaseCost',
      headerName: 'Total Purchase Cost',
      flex: 1,
      editable: true,
      type: 'number',
      valueGetter: (params) => {
        const sharesValue = Number(params?.row?.shares).toFixed(2)
        if (params?.row?.symbol === '__USD' || params?.row?.symbol === '__CASH') {
          return !isNaN(sharesValue) ? sharesValue : null
        }
        return Number(params?.value).toFixed(2) || null
      },
      renderCell: (params) => {
        return params.value !== null && params.value !== undefined
          ? formateToUsdCurrency.format(params?.value)
          : ''
      },
      renderEditCell: (params) => {
        if (params?.row?.symbol === '__USD' || params?.row?.symbol === '__CASH') {
          const sharesValue = Number(params?.row?.shares)
          return formateToUsdCurrency.format(!isNaN(sharesValue) ? sharesValue : 0)
        }
        return (
          <CustomPurchaseCostField
            {...params}
          />
        )
      }
    },
    {
      field: 'purchaseDate',
      headerName: 'Purchase Date',
      type: 'date',
      flex: 1,
      editable: true,
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      valueFormatter: (params) => dayjs(params?.value).format('YYYY-MM-DD'),
      renderEditCell: (params) => {
        return (
          <CustomDateComponent {...params} />
        )
      }
    },
    {
      field: 'isoCountryCode',
      headerName: 'ISO Country Code',
      flex: 1,
      editable: true,
      renderEditCell: (params, index) => {
        return (
          <CustomCountryCodeField
            {...params}
            isSecurityOptionsLoading={isSecurityOptionsLoading}
            options={countryOptions}
          />
        )
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit
        const isSaveDisabled = params?.row?.hasSecurityError || params?.row?.hasSharesError || params?.row?.hasPurchaseCostError || params?.row?.hasDateError
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              disabled={isSaveDisabled}
              key={params.id}
              label='Save'
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(params.id)}
            />,
            <GridActionsCellItem
              icon={<CloseIcon />}
              key={params.id}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(params.id)}
              color='inherit'
            />
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            key={params.id}
            className='textPrimary'
            onClick={handleEditClick(params.id)}
            color='inherit'
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key={params.id}
            label='Delete'
            onClick={handleDeleteClick(params.id)}
            color='inherit'
          />
        ]
      }
    }
  ]

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleDeleteClick = (id) => () => {
    if (!showInvalid) {
      setStagedData((prevStagedData) => {
        const rowToDelete = prevStagedData.find((row) => row.id === id)

        if (rowToDelete?.newRow) {
          setNewRowCount((prevCount) => prevCount - 1)
        }

        return prevStagedData.filter((row) => row.id !== id)
      })

      copyStagedData.current = copyStagedData.current.filter((data) => data?.id !== id)
    } else {
      setInvalidStagedData((prevInvalidStagedData) => prevInvalidStagedData?.filter((row) => row.id !== id))
      invalidCopyStagedData.current = invalidCopyStagedData.current.filter((data) => data?.id !== id)
    }
  }

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    if (!showInvalid) {
      const editedRow = copyStagedData?.current?.find((row) => row.id === id)
      apiRef.current.updateRows([{ id: editedRow?.id, ...editedRow }])
    } else {
      const editedRow = invalidCopyStagedData?.current?.find((row) => row.id === id)
      apiRef.current.updateRows([{ id: editedRow?.id, ...editedRow }])
    }
  }

  // filtered by the search endpoint, so no need to do it again.
  const onSecurityNameChange = (query) => {
    setIsSecurityOrCountryOptionsLoading(true)
    if (checkInstrumentSearchQuery(query)) {
      API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/instruments`, {
        queryStringParameters: { search: query }
      })
        .then((response) => {
          if (response?.data) {
            setOptions([...response?.data])
          }
        })
        .catch((error) => {
          showError(error, false, {}, 'Failed to load security name.')
        })
        .finally(() => {
          setIsSecurityOrCountryOptionsLoading(false)
        })
    } else {
      setIsSecurityOrCountryOptionsLoading(false)
    }
  }

  const fetchISOCountryCodeList = () => {
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/iso-country-codes`)
      .then((response) => {
        if (response?.data) {
          setCountryOptions(response?.data)
          setIsSecurityOrCountryOptionsLoading(false)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load country code.')
      })
  }

  const openModal = () => {
    document.body.classList.add('modal-open', 'pe-0')
    setShowModal(true)
  }

  const closeModal = () => {
    if (document.querySelectorAll('.invalid-entry')?.length > 0) {
      showError('Please correct data')
    } else {
      setShowModal(false)
      setTimeout(() => {
        document.body.classList.remove('modal-open', 'pe-0')
      }, 200)
    }
  }

  const csvConfig = {
    headers: [
      {
        name: 'Symbol',
        inputName: 'Symbol',
        required: true
      },
      {
        name: 'Purchase Date',
        inputName: 'purchaseDate',
        required: true
      },
      {
        name: 'Total Purchase Cost',
        inputName: 'purchaseCost',
        required: true
      },
      {
        name: 'Quantity / Shares',
        inputName: 'Quantity / Shares',
        required: true
      },
      {
        name: 'ISO Country Code',
        inputName: 'isoCountryCode',
        required: false
      }
    ]
  }

  const handleUpload = async () => {
    let updatedCsvData
    const fileSize = (file.size / 1024).toFixed(2) // KB
    if (file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel') {
      showError('Please upload csv file')
      setFile('')
    } else if (fileSize > 1024) {
      showError('Please upload file size less than 1 MB')
      setFile('')
    } else {
      // Passing file data to parse using Papa.parse
      Papa.parse(file, {
        skipEmptyLines: true,
        complete: (results) => {
          const errors = {
            headerErrors: [],
            rowErrors: []
          }
          const portfolioJsonData = []
          if (results?.data?.length) {
            const headersArray = results.data[0]
            const originalFileContent = results.data

            // find mandatory columns are present or not and store error message for missing header
            const mandatoryColumns = csvConfig?.headers?.filter(column => column.required)
            const validationOfColumns = mandatoryColumns?.every(ele => headersArray.includes(ele.name))
            if (!validationOfColumns) {
              const headerErrors = mandatoryColumns?.filter(column => !headersArray.includes(column.name)).map(column => `Header name ${column.name} is not correct or missing`)
              errors.headerErrors = [...headerErrors]
            }

            // store index of column position in file data
            const positionOfColumnInFileJson = {}
            for (const column of csvConfig?.headers) {
              // ignore column without target field
              if (column?.inputName) {
                positionOfColumnInFileJson[column?.inputName] = headersArray.findIndex(name => name === column?.name)
              }
            }

            for (let rowIndex = 1; rowIndex < originalFileContent.length; rowIndex++) {
              const columns = originalFileContent[rowIndex]
              const rowJson = {}
              csvConfig?.headers?.forEach(header => {
                // ignore column without target field
                if (header?.inputName) {
                  rowJson[header?.inputName] = (positionOfColumnInFileJson && columns[positionOfColumnInFileJson[header?.inputName]]) || null
                }
              })
              // if all mandatory column are present, store row
              if (mandatoryColumns.every(header => rowJson[header?.inputName])) {
                portfolioJsonData.push({ ...rowJson })
              } else {
                errors.rowErrors = [...errors?.rowErrors, `Mandatory field ${mandatoryColumns.map(column => column?.name || '').join(' or ')} is missing in the row ${rowIndex}`]
              }
            }
            if ((errors?.rowErrors?.length > 0) || (errors?.headerErrors?.length > 0)) {
              setErrorModalContent(errors)
              setIsErrorModelOpen(true)
            } else {
              setErrorModalContent([])
              setIsErrorModelOpen(false)
              if (Array.isArray(portfolioJsonData) && portfolioJsonData?.length > 0) {
                setIsFileUploading(true)
                updatedCsvData = portfolioJsonData?.map((item) => {
                  if (item?.isoCountryCode === null || item?.isoCountryCode === '') {
                    item.isoCountryCode = 'US'
                  }
                  return item
                })
                if (totalCashValue) {
                  if (totalCashValue === '') {
                    getSecurityDetails(updatedCsvData || portfolioJsonData)
                  } else {
                    const formattedDate = dayjs().format('YYYYMMDD')
                    updatedCsvData = [
                      {
                        Symbol: '__USD',
                        purchaseDate: formattedDate,
                        purchaseCost: 1,
                        'Quantity / Shares': totalCashValue,
                        isoCountryCode: 'US'
                      },
                      ...(updatedCsvData || portfolioJsonData)
                    ]
                  }
                  getSecurityDetails(updatedCsvData)
                  // }
                } else {
                  setIsFileUploading(true)
                  getSecurityDetails(updatedCsvData || portfolioJsonData)
                }
              } else {
                showError('No rows available in the uploaded file. Please re-upload file with rows')
              }
            }
          }
        }
      })
    }
  }

  const getSecurityDetails = (csvDataProcessed) => {
    if (!Array.isArray(csvDataProcessed)) {
      return
    }
    API.post(
      'baseURL',
      `account-master/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/account-imports/preprocessed-data`,
      { body: [...csvDataProcessed] }
    )
      .then((response) => {
        if (response?.data) {
          const template = response?.data?.map((data, index) => {
            return { ...data, id: uuidv4(), hasSecurityError: true, hasPurchaseCostError: false, hasDateError: false, hasSharesError: false, isInvalid: true }
          })
          const invalidEntries = template?.filter((item) => item?.name === null)
          setInvalidStagedData(invalidEntries)
          invalidCopyStagedData.current = invalidEntries
          const validEntries = template?.filter((item) => item?.name !== null)
          const fileId = template[0]?.fileId || 'no file ID'
          setFileId(fileId)
          if (validEntries?.length === 0 && invalidEntries?.length > 0) {
            setShowInvalid(true)
          }
          const formattedData = validEntries.map((e, index) => {
            return {
              id: uuidv4(),
              symbol: e?.symbol,
              purchaseDate: e?.purchaseDate,
              purchaseCost: (e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : e?.purchaseCost,
              shares: e?.shares,
              isoCountryCode: e?.isoCountryCode,
              originalIsoCountryCode: e?.isoCountryCode,
              hasSecurityError: false,
              hasPurchaseCostError: false,
              hasDateError: false,
              hasSharesError: false,
              instrId: e?.instrId,
              name: e?.name,
              isInvalid: false
            }
          })
          setStagedData(formattedData)
          copyStagedData.current = formattedData
          openModal()
          setIsFileUploading(false)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load security taxlot.')
        setIsFileUploading(false)
      })
  }

  const checkStagingData = () => {
    const emptyFields = stagedData?.filter(
      (item) => item?.symbol === '' || item?.name === null || item?.name === ''
    )?.length
    const zeroValue = stagedData
      .slice()
      .filter(
        (item) =>
          !(item?.symbol === '__USD' || item?.symbol === '__CASH') &&
          (item?.shares <= 0 || item?.shares === '')
      )?.length
    const zeroPurchasevalue = stagedData
      .slice()
      .filter(
        (item) => item.purchaseCost < 0 || item.purchaseCost === ''
      )?.length
    if (emptyFields && emptyFields > 0) {
      showError('Please check for errors and enter valid values')
    } else if (zeroValue && zeroValue > 0) {
      showError('Shares must be greater than 0')
    } else if (zeroPurchasevalue && zeroPurchasevalue > 0) {
      showError('Total Purchase Cost must be greater than 0')
    } else {
      setIsFileUploading(true)
      const formattedData = stagedData?.map((e) => {
        return {
          symbol: e?.symbol === '__CASH' ? '__USD' : e?.symbol,
          purchase_date: dayjs(e?.purchaseDate).format('YYYY-MM-DD'),
          purchase_cost: (e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : e?.purchaseCost,
          shares: e?.shares,
          instrId: e?.instrId,
          ...{
            ...uploadTypeWatch === 'urlupload'
              ? {
                org_purchase_price: e?.orgPurchasePrice,
                schwabLotMv: e?.schwabLotMv,
                schwabLastClosePrice: e?.schwabLastClosePrice,
                org_purchase_price_unadj: e?.orgPurchasePriceUnadj ?? (e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : (e?.unstoredRow ? (e?.purchaseCost / e?.shares) : e?.purchaseCost),
                purchase_cost_unadj: e?.purchaseCostUnadj ?? ((e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : e?.purchaseCost),
                shares_unadj: e?.sharesUnadj ?? e?.shares,
                taxlotId: e?.taxlotId
              }
              : {}
          }
        }
      })
      const requestBody = {
        securities: formattedData,
        ...(uploadTypeWatch === 'urlupload'
          ? {
              schwabPortfolioMarketValue: portfolioDataFromSchwab?.schwabPortfolioMarketValue,
              schwabTotalLots: portfolioDataFromSchwab?.schwabTotalLots,
              secSwappedData: portfolioDataFromSchwab?.secSwappedData
            }
          : {})
      }
      API.post(
        'baseURL',
        `account-master/v1/${user?.userGroup}/account-imports/submit/${fileId}?validate-only=true&account-id=${params?.accountId}`,
        { body: requestBody }
      )
        .then((response) => {
          if (response && response?.data) {
            setValidatedPortfolioData({
              ...response?.data,
              portfolio: response?.data?.portfolio?.map(item => ({
                ...item,
                id: randomId()
              }))
            })
            setIsSchwabDataModelOpen(true)
          }
        })
        .catch((error) => {
          if (Array.isArray(error.response?.data?.errorInfo?.userMessage)) {
            error.response?.data?.errorInfo?.userMessage?.forEach((element) => {
              showError({ message: element.message }, false, {}, 'Failed to upload validate taxlot.')
            })
          } else {
            showError(error, false, {}, 'Failed to upload validate taxlot.')
          }
        })
        .finally(() => setIsFileUploading(false))
    }
  }

  const submitTaxlotData = () => {
    setIsFileUploading(true)
    const formattedData = stagedData?.map((e) => {
      return {
        symbol: e?.symbol === '__CASH' ? '__USD' : e?.symbol,
        purchase_date: dayjs(e?.purchaseDate).format('YYYY-MM-DD'),
        purchase_cost: (e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : e?.purchaseCost,
        shares: e?.shares,
        instrId: e?.instrId,
        ...{
          ...uploadTypeWatch === 'urlupload'
            ? {
              org_purchase_price: e?.orgPurchasePrice,
              schwabLotMv: e?.schwabLotMv,
              schwabLastClosePrice: e?.schwabLastClosePrice,
              org_purchase_price_unadj: e?.orgPurchasePriceUnadj ?? (e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : (e?.unstoredRow ? (e?.purchaseCost / e?.shares) : e?.purchaseCost),
              purchase_cost_unadj: e?.purchaseCostUnadj ?? ((e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : e?.purchaseCost),
              shares_unadj: e?.sharesUnadj ?? e?.shares,
              taxlotId: e?.taxlotId
            }
            : {}
        }
      }
    })
    const requestBody = {
      securities: formattedData,
      dbPortfolioMarketValue: validatedPortfolioData?.dbPortfolioMarketValue,
      ...(uploadTypeWatch === 'urlupload'
        ? {
            schwabPortfolioMarketValue: portfolioDataFromSchwab?.schwabPortfolioMarketValue,
            schwabTotalLots: portfolioDataFromSchwab?.schwabTotalLots,
            cashSwappedData: portfolioDataFromSchwab?.cashSwappedData,
            secSwappedData: portfolioDataFromSchwab?.secSwappedData
          }
        : {})
    }
    API.post(
      'baseURL',
      `account-master/v1/${user?.userGroup}/account-imports/submit/${fileId}?account-id=${params?.accountId}`,
      { body: requestBody }
    )
      .then((response) => {
        if (response && response?.data) {
          setModalShow(true)
          setShowSecondMsg(true)
          setSuccessMessage(response?.message)
          setValue('totalPortfolioMarketValue', response?.data?.dbPortfolioMarketValue || 0)
          if (stagedData?.some((item) => item?.symbol === '__USD')) {
            const totalCashValue = stagedData
              .filter((item) => item?.symbol === '__USD' || item?.symbol === '__CASH')
              .reduce((init, item) => init + (1 * parseFloat(item?.shares)), 0)
            setTotalCashValue(totalCashValue.toString())
          }
          setTimeout(() => {
            closeModal()
            setIsSchwabDataModelOpen(false)
          }, 2000)
        }
      })
      .catch((error) => {
        if (Array.isArray(error.response?.data?.errorInfo?.userMessage)) {
          error.response?.data?.errorInfo?.userMessage?.forEach((element) => {
            showError({ message: element.message }, false, {}, 'Failed to load schwab taxlot.')
          })
        } else {
          showError(error, false, {}, 'Failed to load schwab taxlot.')
        }
      })
      .finally(() => setIsFileUploading(false))
  }

  const handleCancel = () => {
    setFile('')
    setStagedData([])
    copyStagedData.current = []
    setNewRowCount(0)
    setValue('totalPortfolioMarketValue', '')
    setValue('securityTaxLotsFile', '')
  }

  const filterInvalid = () => {
    setShowInvalid(!showInvalid)
    setOptions([])
    apiRef.current.setPage(0)
  }

  if (showInvalid) {
    if (invalidStagedData?.length === 0) {
      setShowInvalid(false)
    }
  }
  const closePopupDialog = () => {
    setShowInvalid(false)
    setShowModal(false)
    handleCancel()
    setRowModesModel({})
  }

  const editModeRowsLength = Object.values(rowModesModel).filter((row) => row.mode === 'edit').length

  // Handle delete all button click
  const handleDeleteAllClick = () => {
    const isDeleteAll = rowSelectionModel.length === invalidStagedData.length
    if (isDeleteAll) {
      setInvalidStagedData([])
      invalidCopyStagedData.current = []
    } else {
      const currentEntries = invalidStagedData.filter((data) =>
        rowSelectionModel.includes(data.id)
      )

      const updatedData = {}
      invalidStagedData.forEach((d) => {
        updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
      })

      const remainingData = invalidStagedData
        .filter((data) => !rowSelectionModel.includes(data.id))
        .map((data) => (
          {
            ...data,
            symbol: updatedData[data?.id]?.symbol,
            instrId: updatedData[data?.id]?.instrId,
            hasSecurityError: updatedData[data?.id]?.hasSecurityError,
            hasSharesError: updatedData[data?.id]?.hasSharesError,
            hasDateError: updatedData[data?.id]?.hasDateError,
            hasPurchaseCostError: updatedData[data?.id]?.hasPurchaseCostError
          }
        ))

      setInvalidStagedData(remainingData)

      const updatedRowModesModel = { ...rowModesModel }

      currentEntries.forEach((entry) => {
        updatedRowModesModel[entry.id] = { ignoreModifications: true }
      })

      setRowModesModel(updatedRowModesModel)
    }

    setRowSelectionModel([])
  }

  const fetchPortfolioFromSchwab = async () => {
    try {
      setIsFileUploading(true)
      const queryParams = {
        accountId: params?.accountId
      }
      API.get('baseURL', `account-master/v1/${user?.userGroup}/schwab-portfolio/import`, { queryStringParameters: queryParams })
        .then((response) => {
          if (response && response?.data) {
            setPortfolioDataFromSchwab(response?.data)
            if (response?.data.portfolioDate) {
              setPortfolioDate(response?.data?.portfolioDate)
            }
            if (response?.data?.securities) {
              const template = response?.data?.securities?.map((data, index) => {
                return { ...data, id: uuidv4(), hasSecurityError: true, hasPurchaseCostError: false, hasDateError: false, hasSharesError: false, isInvalid: true }
              })
              const invalidEntries = template?.filter((item) => item?.name === null)
              setInvalidStagedData(invalidEntries)
              invalidCopyStagedData.current = invalidEntries
              const validEntries = template?.filter((item) => item?.name !== null)
              if (template && template?.length) {
                setFileId(template[0]?.fileId)
              }
              setIsFileUploading(false)
              const formattedData = validEntries?.map((e, index) => {
                return {
                  id: uuidv4(),
                  symbol: e.symbol,
                  purchaseDate: e.purchaseDate,
                  purchaseCost: (e.symbol === '__CASH' || e.symbol === '__USD') ? 1 : e?.purchaseCost,
                  shares: e?.shares,
                  orgPurchasePrice: e.orgPurchasePrice,
                  name: e.name,
                  hasSecurityError: false,
                  hasPurchaseCostError: false,
                  hasDateError: false,
                  hasSharesError: false,
                  isoCountryCode: 'US',
                  originalIsoCountryCode: e?.isoCountryCode || 'US',
                  instrId: e?.instrId,
                  schwabLotMv: e?.schwabLotMv,
                  schwabLastClosePrice: e?.schwabLastClosePrice,
                  orgPurchasePriceUnadj: e?.orgPurchasePriceUnadj,
                  purchaseCostUnadj: e?.purchaseCostUnadj,
                  sharesUnadj: e?.sharesUnadj,
                  isInvalid: false,
                  taxlotId: e?.taxlotId
                }
              })
              setStagedData(formattedData)
              copyStagedData.current = formattedData
              if (response?.data?.fundingSource === 'CASH' && response?.data?.securities?.some((item) => item?.symbol === '__USD')) {
                const purchaseCost = response?.data?.securities
                  .filter((item) => item?.symbol === '__USD')
                  .reduce((init, item) => init + (item?.shares), 0)
                setTotalCashValue(purchaseCost?.toString())
              }
              openModal()
            }
          }
        })
        .catch((error) => {
          setIsFileUploading(false)
          showError(error, false, {}, 'Failed to fetch schwab portfolio.')
        })
    } catch (error) {
      showError(error, false, {}, 'Failed to fetch schwab portfolio.')
    }
  }

  const handleAddNewRow = (values) => {
    const id = uuidv4()
    setStagedData((prevData) => {
      // add the new row at the beginning
      const newRow = {
        id,
        ...values,
        ...values.name,
        newRow: true,
        ...(uploadTypeWatch == 'urlupload' ? {
          unstoredRow: true
        } : {})
      }

      const updatedData = {}
      stagedData.forEach((d) => {
        updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
      })

      // Update existing rows with updated value
      const updatedRows = prevData.map((row) => ({
        ...row,
        symbol: updatedData[row?.id]?.symbol,
        instrId: updatedData[row?.id]?.instrId,
        hasSecurityError: updatedData[row?.id]?.hasSecurityError,
        hasSharesError: updatedData[row?.id]?.hasSharesError,
        hasDateError: updatedData[row?.id]?.hasDateError,
        hasPurchaseCostError: updatedData[row?.id]?.hasPurchaseCostError

      }))

      setNewRowCount((prevCount) => prevCount + 1)

      return [newRow, ...updatedRows]
    })

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View }
    }))

    setAddPortfolioEntryPopUpOpen(false)

    apiRef.current.scrollToIndexes({ rowIndex: 0 })
    apiRef.current.setPage(0)
  }

  const EditToolbar = () => {
    return (
      <GridToolbarContainer sx={{ position: 'absolute', right: 0, bottom: '-50px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          {editModeRowsLength || (newRowCount && !showInvalid)
            ? (
              <Alert
                severity='info'
                sx={{
                  padding: '1px 10px',
                  '& .MuiAlert-icon': {
                    padding: '2px 0'
                  },
                  '& .MuiAlert-message': {
                    padding: '3px 0'
                  }
                }}
              >{`Unsaved Rows: ${editModeRowsLength}${newRowCount && !showInvalid ? `  |  New Rows: ${newRowCount}` : ''}`}
              </Alert>
              )
            : (
                ''
              )}
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {showInvalid
            ? (
              <Button
                style={{
                  marginRight: '5px',
                  marginBottom: '10px'
                }}
                variant='contained'
                onClick={filterInvalid}
                className='PortfolioEndBtn'
                disabled={editModeRowsLength}
              >
                Valid Entries
              </Button>
              )
            : (
              <>
                <Button
                  color='primary'
                  variant='contained'
                  startIcon={<AddIcon />}
                  onClick={() => setAddPortfolioEntryPopUpOpen(true)}
                  style={{ margin: '8px' }}
                >
                  Add
                </Button>
                <Button
                  style={{
                    backgroundColor: editModeRowsLength || (!invalidStagedData?.length)
                      ? ''
                      : '#EA3D2F',
                    margin: '8px'
                  }}
                  variant='contained'
                  endIcon={<ErrorOutlineIcon />}
                  onClick={filterInvalid}
                  className='PortfolioEndBtn'
                  disabled={editModeRowsLength || (!invalidStagedData?.length)}
                >
                  Invalid Entries
                </Button>
                <Button
                  variant='contained'
                  onClick={() => checkStagingData() || CheckAccountType()}
                  className='PortfolioEndBtn'
                  style={{ margin: '8px' }}
                  disabled={editModeRowsLength || (invalidStagedData && invalidStagedData?.length)}
                >
                  Validate
                </Button>
              </>
              )}
          {rowSelectionModel?.length > 0 && showInvalid && (
            <Button
              style={{
                backgroundColor: '#EA3D2F',
                color: 'white',
                textTransform: 'capitalize',
                border: '1px solid transparent !important',
                borderRadius: '10px',
                fontFamily: 'Open Sans',
                fontSize: '15px',
                marginRight: '5px',
                marginBottom: '10px'
              }}
              variant='contained'
              endIcon={<DeleteIcon />}
              onClick={handleDeleteAllClick}
            >
              {rowSelectionModel?.length === invalidStagedData?.length ? 'Delete All' : 'Delete Selected'}
            </Button>
          )}
        </Box>
      </GridToolbarContainer>
    )
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut || params.reason === GridRowEditStopReasons.escapeKeyDown || params.reason === GridRowEditStopReasons.enterKeyDown || params.reason === GridRowEditStopReasons.tabKeyDown) {
      event.defaultMuiPrevented = true
    }
  }

  const handleCellEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isInvalid: false }
    if (!showInvalid) {
      const updatedData = {}
      stagedData.forEach((d) => {
        updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
      })

      // Update the staged data
      setStagedData((prevData) =>
        prevData.map((row) => (row.id === newRow.id
          ? updatedRow
          : {
              ...row,
              symbol: updatedData[row?.id]?.symbol,
              instrId: updatedData[row?.id]?.instrId,
              hasSecurityError: updatedData[row?.id]?.hasSecurityError,
              hasSharesError: updatedData[row?.id]?.hasSharesError,
              hasDateError: updatedData[row?.id]?.hasDateError,
              hasPurchaseCostError: updatedData[row?.id]?.hasPurchaseCostError
            }))
      )

      copyStagedData.current = copyStagedData.current.map((data) => (
        data.id === newRow.id
          ? updatedRow
          : {
              ...data
            }
      ))
    } else {
      // Add the updated row to stagedData
      setStagedData((prevData) => [updatedRow, ...prevData])

      copyStagedData.current = [updatedRow, ...copyStagedData.current]

      const updatedData = {}
      invalidStagedData.forEach((d) => {
        updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
      })

      // Remove the row from invalidStagedData
      setInvalidStagedData((prevData) =>
        prevData
          .filter((row) => row.id !== newRow?.id) // Remove the row with matching id
          .map((row) => ({
            ...row,
            symbol: updatedData[row?.id]?.symbol,
            instrId: updatedData[row?.id]?.instrId,
            hasSecurityError: updatedData[row?.id]?.hasSecurityError,
            hasSharesError: updatedData[row?.id]?.hasSharesError,
            hasDateError: updatedData[row?.id]?.hasDateError,
            hasPurchaseCostError: updatedData[row?.id]?.hasPurchaseCostError
          }))
      )

      invalidCopyStagedData.current = invalidCopyStagedData.current.filter(
        (row) => row.id !== newRow?.id
      )
    }
    return updatedRow
  }

  return (
    <Box className='uploadImportDiv'>
      {isFileUploading ? <Loader /> : ''}
      <Box
        component='form' noValidate onSubmit={(event) => {
          event.preventDefault() // Prevents page reload on form submission
        }}
      >
        <Button ref={submitRef} type='submit' style={{ display: 'none' }} />
        <Grid container>
          <Grid item xs={12}>
            <Box width='100%' my={1}>
              {
                  accountTypeCode === 'TRADING'
                    ? <Controller
                        name='uploadType'
                        control={control}
                        render={({ field }) => (
                          <RadioGroup
                            row
                            aria-labelledby='demo-row-radio-buttons-group-label'
                            value={field.value || 'manual'}
                            {...field}
                            onChange={(e) => {
                              field.onChange(e)
                              setValue('userType', e.target.value)
                              setFile('')
                              setFileId('')
                              setStagedData([])
                              copyStagedData.current = []
                              setNewRowCount(0)
                              setValue('totalPortfolioMarketValue', '')
                              setTotalCashValue('')
                              if (e.target.value === 'urlupload') {
                                fetchPortfolioFromSchwab()
                              }
                            }}
                          >
                            <FormControlLabel
                              value='manual'
                              control={<Radio size='small' />}
                              label='Manually Upload Portfolio'
                            />
                            <FormControlLabel
                              value='urlupload'
                              control={<Radio size='small' onClick={() => uploadTypeWatch === 'urlupload' && !stagedData?.length ? fetchPortfolioFromSchwab() : ''} />}
                              label='Import from API'
                            />
                          </RadioGroup>
                        )}
                      />
                    : ''
                }
            </Box>
          </Grid>
        </Grid>
        {(uploadTypeWatch === 'manual' || (uploadTypeWatch === 'urlupload' && stagedData?.length))
          ? <>
            <Grid container rowGap={1} columnGap={2}>
              <Grid item md={6} lg={3}>
                <FormControl fullWidth>
                  <Controller
                    name='totalPortfolioMarketValue'
                    control={control}
                    render={({ field }) => (
                      <InputAdornmentTextField
                        id='total-portfolio-market-value'
                        type='text'
                        label='Total Portfolio Market Value'
                        name='totalPortfolioMarketValue'
                        readOnly
                        disabled
                        {...register('totalPortfolioMarketValue')}
                        onChange={field.onChange}
                        value={(field?.value && !isNaN(field?.value)) ? Number(field?.value).toFixed(2) : ''}
                        aria-describedby='total-portfolio-market-value'
                        sx={{
                          width: '100%'
                        }}
                        adornment={<AttachMoneyOutlinedIcon />}
                      />
                    )}
                  />
                  {errors.totalPortfolioMarketValue && (
                    <FormHelperText error>{errors.totalPortfolioMarketValue.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6} lg={3}>
                <FormControl fullWidth>
                  <InputAdornmentTextField
                    id='total-cash-value'
                    name='totalCashValue'
                    type='text'
                    label='Total Cash Value *'
                    value={totalCashValue}
                    onKeyDown={(event) => isNumberWithSign(event)}
                    onPaste={numberWithSignPasteHandler}
                    onChange={(event) => {
                      if (Number(event.target.value) === 0) {
                        setCashFieldError(true)
                        setCashFieldErrorMessage('Total Cash Value Should be greater than 0')
                      } else {
                        setCashFieldError(false)
                        setCashFieldErrorMessage('')
                      }
                      setTotalCashValue(event.target.value)
                    }}
                    sx={{
                      width: '100%'
                    }}
                    adornment={<AttachMoneyOutlinedIcon />}
                    error={Boolean(errors.totalCashValue) || cashFieldError}
                    helperText={(errors.totalCashValue && errors.totalCashValue.message) || cashFieldErrorMessage}
                  />
                  {errors.totalCashValue && (
                    <FormHelperText error>{errors.totalCashValue.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={12} lg={12}>
                <FormControl fullWidth sx={{ marginTop: '16px', marginBottom: '24px' }}>
                  <Typography sx={{ color: '#74788d', fontSize: '16px', fontWeight: 600 }} mb={1}>Upload Security Tax Lots</Typography>
                  <Box
                    className='import-portfolio file-upload-block'
                    onDragEnter={(e) => e.preventDefault()}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                      e.preventDefault()
                      const droppedFile = e?.dataTransfer?.files[0]
                      setFile(droppedFile)
                    }}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                  >
                    <Box>
                      <input
                        type='file'
                        style={{ position: 'absolute', width: '100%', height: '100%', cursor: 'pointer' }}
                        disabled={cashFieldError}
                        {...register('securityTaxLotsFile')}
                        onChange={(e) => {
                          setFile(e?.target?.files[0])
                          e.target.value = ''
                        }}
                        accept='.csv'
                      />
                      <Box sx={{ display: 'flex' }}>
                        <img
                          style={{ cursor: 'pointer' }}
                          src={addIcon}
                          alt='add'
                          height={30}
                          width={30}
                        />
                        <Typography
                          pl={1}
                          sx={{ color: '#34475a', fontWeight: 500 }}
                        >
                          Drop your files here or browse
                        </Typography>
                      </Box>
                    </Box>
                    {file && (
                      <Box className='uploaded-file' sx={{ width: '420px', maxWidth: '100%', height: '125px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box className='btn-soft-blue' sx={{ backgroundColor: 'rgba(23, 92, 194, 0.5)', padding: '7px', color: 'white', width: '24px', height: '24px', display: 'flex', justifyContent: 'center' }} align='center' mr={1}>
                            <img
                              src={fileIcon}
                              alt=''
                              height={10}
                              width={10}
                            />
                          </Box>
                          <Box>
                            <Typography>Name: {file?.name}</Typography>
                            <Typography>Size: {filesize(file?.size)}</Typography>
                            <Typography>Last Modified: {file?.lastModifiedDate?.toLocaleDateString()}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex' }} p={1}>
                            <Button
                              className=''
                              type='button'
                              onClick={handleUpload}
                              size='small'
                              variant='contained'
                              disabled={cashFieldError}
                              sx={{ fontSize: '12px !important' }}
                            >
                              Upload
                            </Button>
                            <Button
                              className='btnRemove btn-soft-red rounded-pill'
                              type='button'
                              onClick={handleCancel}
                              size='small'
                              sx={{ fontSize: '12px !important', marginLeft: '10px' }}
                            >
                              Cancel
                            </Button>
                          </Box>
                          {isFileUploading ? <Loader /> : null}
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {errors?.totalPortfolioMarketValue && (
                    <FormHelperText error>{errors.totalPortfolioMarketValue.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </>
          : ''}
      </Box>
      {uploadTypeWatch === 'manual' ? <SampleTaxlotTable /> : ''}
      <Dialog open={showModal} onClose={closePopupDialog} fullScreen>
        <DialogTitle>
          <IconButton
            onClick={closePopupDialog}
            style={{ position: 'absolute', top: 0, right: 0 }}
          >
            <CloseIcon />
          </IconButton>
          {uploadTypeWatch === 'urlupload' && stagedData?.length && portfolioDate ? <h6 style={{ margin: 0, fontSize: '16px' }}>Latest Portfolio Date: {portfolioDate}</h6> : ''}
          {portfolioDataFromSchwab?.cashSwapReasonMessage
            ? <Alert
                severity='warning'
                icon={<InfoOutlinedIcon fontSize='small' />}
                sx={{
                  position: 'absolute',
                  marginBottom: '10px',
                  padding: '1px 8px',
                  right: '34px',
                  top: '6px',
                  zIndex: 98,
                  '& .MuiAlert-icon': {
                    padding: '3px 0'
                  },
                  '& .MuiAlert-message': {
                    padding: '3px 0'
                  }
                }}
              >
              {portfolioDataFromSchwab?.cashSwapReasonMessage}
            </Alert>
            : ''}
        </DialogTitle>
        <DialogContent ref={scrollToTopRef}>
          <DataGridPro
            apiRef={apiRef}
            rows={showInvalid
              ? invalidStagedData
              : stagedData}
            columns={columns}
            disableRowSelectionOnClick
            editMode='row'
            autoHeight
            getRowId={(row) => row?.id}
            getRowHeight={() => 40}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            checkboxSelection={!!showInvalid}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel)
            }}
            onRowEditStop={handleRowEditStop}
            onCellEditStart={handleCellEditStart}
            onCellEditStop={handleCellEditStop}
            processRowUpdate={processRowUpdate}
            pagination
            pageSizeOptions={[15, 25, 50, 100]}
            getRowClassName={(params) => {
              return params?.row?.isInvalid ? 'error-row' : params?.row?.newRow ? 'new-row' : ''
            }}
            initialState={{
              ...stagedData?.initialState,
              pagination: { paginationModel: { pageSize: 15 } }
            }}
            slots={{ toolbar: EditToolbar }}
            sx={{
              '& .error-row': {
                backgroundColor: '#f05f5f1a'
              },
              // '& .new-row': {
              //   backgroundColor: '#52d21d38'
              // },
              '& .MuiDataGrid-cell': {
                cursor: 'pointer'
              },
              '& .MuiDataGrid-cell:focus-within': {
                outline: 'none !important'
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none !important'
              },
              [`.${gridClasses.main}`]: {
                height: 'calc(100vh - 96px - 40px)'
              },
              [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                color: '#74788d',
                fontWeight: 600
              },
              [`.${gridClasses.virtualScroller}`]: {
                overflowY: 'auto !important',
                scrollbarGutter: 'stable',
                scrollbarWidth: 'none'
              }
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={modalShow} onClose={closeModal} centered>
        <DialogContent className='oboardSuccess' align='center'>
          <SuccessUploadModal successMessage={successMessage} showSecondMsg={showSecondMsg} showThirdMsg={showThirdMsg} />
        </DialogContent>
      </Dialog>
      {
        isErrorModelOpen ? <InvalidRowEntriesPopup isErrorModelOpen={isErrorModelOpen} errorModalContent={errorModalContent} setIsErrorModelOpen={setIsErrorModelOpen} /> : ''
      }
      {
        isSchwabDataModelOpen
          ? <SchwabDataPopup validatedPortfolioData={validatedPortfolioData} isSchwabDataModelOpen={isSchwabDataModelOpen} setIsSchwabDataModelOpen={setIsSchwabDataModelOpen} showSubmitButton submitPortfolio={submitTaxlotData} isUploadPortfolioScreen />
          : ''
      }
      {
        addPortfolioEntryPopUpOpen && (
          <Dialog open={addPortfolioEntryPopUpOpen} onClose={handleCancelClose} fullWidth maxWidth='sm'>
            <AddPortfolioEntryPopup
              handleCancelClick={handleCancelClose}
              securityOptions={options}
              countryOptions={countryOptions}
              savedData={handleAddNewRow}
              isSecurityOptionsLoading={isSecurityOptionsLoading}
              onSecurityNameChange={onSecurityNameChange}
            />
          </Dialog>
        )
      }
    </Box>
  )
}
export default UploadForm
