import { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  Collapse,
  Typography
} from '@mui/material'
import DoneIcon from '../../../assets/images/Check.svg'
import arrowIcon from '../../../assets/images/arrow.svg'
import Success from '../../../assets/images/success.svg'
import '../import-portfolio.scss'

export const SuccessUploadModal = ({ successMessage, showSecondMsg, showThirdMsg }) => {
  const [showCurrent, setShowCurrent] = useState(false)

  return (
    <Box className='uploadImportDiv'>
        <img src={Success} alt='' height={100} width={100} />
        <Typography sx={{ fontSize: '24px', fontWeight: 500, fontFamily: 'Open Sans' }} gutterBottom>
          {successMessage}
        </Typography>
        {showSecondMsg === true && (
          <>
            <Box className='apimsgbox'>
              <ul className='apimsg'>
                <li className='msglist' style={{ marginBottom: '0px' }}>
                  <Box style={{ width: '10%' }}>
                    <img
                      src={DoneIcon}
                      alt=''
                      height={25}
                      width={25}
                    />
                  </Box>{' '}
                  <Typography>Update portfolio details</Typography>
                </li>
                <li className='msglist' style={{ marginBottom: '0px' }}>
                  <Box style={{ width: '10%' }}>
                    <img
                      src={DoneIcon}
                      alt=''
                      height={25}
                      width={25}
                    />
                  </Box>{' '}
                  <div>Generate account summary</div>
                </li>
                {showThirdMsg === true && (
                  <li className='msglist' style={{ marginBottom: '0px' }}>
                    <Box style={{ width: '10%' }}>
                      <img
                        src={DoneIcon}
                        alt=''
                        height={25}
                        width={25}
                      />
                    </Box>{' '}
                    <Typography>Calculate Risk Data</Typography>
                  </li>
                )}
                <li className='msglist' style={{ marginBottom: '0px' }}>
                  <Box style={{ width: '10%' }}>
                    <img
                      src={DoneIcon}
                      alt=''
                      height={25}
                      width={25}
                    />
                  </Box>{' '}
                  <Typography>
                    Check policy breaks on portfolio
                  </Typography>
                </li>
              </ul>
            </Box>
            {showThirdMsg === true && (
              <>
                <ul className='apimsg'>
                  <li className='msglist' style={{ marginBottom: '0px' }}>
                    <Box style={{ width: '10%' }}>
                      <img
                        src={DoneIcon}
                        alt=''
                        height={25}
                        width={25}
                      />
                    </Box>{' '}
                    <Box>
                      Reconcile trades{' '}
                      <Button
                        className='collapse-btn'
                        sx={{ border: 'none', marginLeft: '20px' }}
                        onClick={() => setShowCurrent(!showCurrent)}
                      >
                        <img
                          src={arrowIcon}
                          alt=''
                          height={15}
                          width={15}
                          className={
                            showCurrent ? 'rotate-0' : 'rotate-90degn'
                          }
                        />
                      </Button>
                    </Box>
                  </li>
                </ul>
                <Collapse in={showCurrent}>
                  <Box className='collaspe-margin'>
                    <ul className='apimsg'>
                      <li className='msglist2' style={{ marginBottom: '0px', textAlign: 'left' }}>
                        <Box style={{ width: '10%' }}>
                          <img
                            src={DoneIcon}
                            alt=''
                            height={25}
                            width={25}
                          />
                        </Box>
                        <Typography>
                          Enable account for optimization
                        </Typography>
                      </li>
                      <li className='msglist2' style={{ marginBottom: '0px', textAlign: 'left' }}>
                        <Box style={{ width: '10%' }}>
                          <img
                            src={DoneIcon}
                            alt=''
                            height={25}
                            width={25}
                          />
                        </Box>
                        <Typography>Update status of trades</Typography>
                      </li>
                    </ul>
                    <ul className='apimsg'>
                      <li className='msglist2' style={{ marginBottom: '0px', textAlign: 'left' }}>
                        <Box style={{ width: '10%' }}>
                          <img
                            src={DoneIcon}
                            alt=''
                            height={25}
                            width={25}
                          />
                        </Box>
                        <Typography>Add trade restrictions</Typography>
                      </li>
                      <li className='msglist2'>
                        <Box style={{ width: '10%' }}>
                          <img
                            src={DoneIcon}
                            alt=''
                            height={25}
                            width={25}
                          />
                        </Box>
                        <Typography style={{ marginBottom: '0px', textAlign: 'left' }}>
                          Update realized gain/loss
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                </Collapse>
              </>
            )}
          </>
        )}
        <Button
          variant='contained'
          component={Link}
          to='/book-of-business'
          sx={{ margin: '12px' }}
        >
          Go to Dashboard
        </Button>
    </Box>
  )
}
