import { TextField } from '@mui/material'

const CustomTextField = (props) => {
  const { id, name, label, formik, placeholder, disabled, onChange, value, error, helperText, defaultAccountDetails, InputLabelProps, ...otherProps } = props
  return (
    <TextField
      id={id}
      name={name}
      type='text'
      size='small'
      label={label}
      disabled={disabled}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      InputLabelProps={InputLabelProps}
      sx={{ width: '100%', marginTop: '0px' }}
      {...otherProps}
    />
  )
}

export default CustomTextField
