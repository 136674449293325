import { useContext } from 'react'
import { Box, Divider, IconButton, ListItem, Skeleton, Typography } from '@mui/material'
import { TbInfoSquare } from 'react-icons/tb'
import CircleIcon from '@mui/icons-material/Circle'
import { HtmlTooltip } from '../../../../components/CustomTooltip'
import { SummaryContext } from '..'

const Insights = () => {
  const { summaryInsightApiLoading, summaryInsightsData, selectedReturnOverTimeScenario } = useContext(SummaryContext)

  const summaryInsightsDataOfScenario = !summaryInsightApiLoading && summaryInsightsData && summaryInsightsData?.length ?
    summaryInsightsData?.find((data) => data?.aggId === selectedReturnOverTimeScenario) : {}

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
        <Typography sx={{ fontWeight: 600, fontSize: '16px', color: '#2f2f2f' }}>Insights</Typography>
        <HtmlTooltip
          title='Insights'
          tooltipStyle={{ backgroundColor: '#000', color: '#fff', fontSize: '11px', align: 'center' }}
        >
          <IconButton size='small'>
            <TbInfoSquare style={{ height: '20px', width: '20px', color: '#2F2F2F' }} />
          </IconButton>
        </HtmlTooltip>
      </Box>
      <Divider sx={{ my: '7px' }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '250px', overflow: 'scroll', scrollbarWidth: 'none' }}>
        {summaryInsightApiLoading ? (
          Array.from({ length: 5 }).map((_, index) => (
            <ListItem key={index} sx={{ px: '0px' }}>
              <Skeleton variant="text" width="80%" sx={{ ml: '10px', fontSize: '14px' }} />
            </ListItem>
          ))
        ) : (
          (summaryInsightsDataOfScenario?.insights || [])?.map((insight, index) => (
            <ListItem key={index} sx={{ px: '0px' }}>
              <CircleIcon
                sx={{
                  color: '#1A4B83',
                  width: '12px',
                  height: '12px',
                  border: '2px solid #CCE4FF',
                  borderRadius: '50%'
                }}
              />
              <Typography sx={{ color: '#2F2F2F', fontSize: '14px', ml: '10px' }}>
                {insight}
              </Typography>
            </ListItem>
          ))
        )}
      </Box>
    </>
  )
}

export default Insights
