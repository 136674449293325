import { getAssetClassMapData, getInstrumentIdData, getInstrumentMasterData, getLastMarketData, getSplitsCalData , getDelistingCalData } from './Instrument/GetInstrumentData'

export const tabNameData = {
  account: [
    { id: 'account-master', name: 'Account master', actionCds: { get: ['get-all-account-with-status', 'get-account-master'], create: [], update: ['update-account-master'], delete: [] } },
    { id: 'external-gl', name: 'External GL', actionCds: { get: [], create: [], update: [], delete: [] } },
    { id: 'internal-gl', name: 'Internal GL', actionCds: { get: [], create: [], update: [], delete: [] } },
    { id: 'parameters', name: 'Parameters', actionCds: { get: [], create: [], update: [], delete: [] } },
    { id: 'portfolio-specification', name: 'Portfolio specification', actionCds: { get: [], create: [], update: [], delete: [] } },
    { id: 'portfolio-specification-properties', name: 'Portfolio specification properties', actionCds: { get: [], create: [], update: [], delete: [] } },
    { id: 'substitution', name: 'Substitution', actionCds: { get: [], create: [], update: [], delete: [] } },
    { id: 'restriction', name: 'Restriction', actionCds: { get: [], create: [], update: [], delete: [] } },
    { id: 'tax-rate', name: 'Tax rate', actionCds: { get: [], create: [], update: [], delete: [] } },
    { id: 'ignore-tax-lots', name: 'Ignore TaxLots', actionCds: { get: [], create: [], update: [], delete: [] } },
    { id: 'properties', name: 'Properties', actionCds: { get: [], create: [], update: [], delete: [] } },
    { id: 'account-optimization-status', name: 'Account optimization status', actionCds: { get: [], create: [], update: [], delete: [] } },
  ],
  request: [
    { id: 'type-code', name: 'Type code' },
    { id: 'status-code', name: 'Status code' }
  ],
  strategy: [
    { id: 'strategy-master', name: 'Strategy Master' },
    { id: 'strategy-parameter', name: 'Strategy Parameter' },
    { id: 'strategy-benchmark-map', name: 'Strategy Benchmark Map' },
    { id: 'strategy-instrument-restriction', name: 'Strategy Instrument Restriction' },
    { id: 'strategy-properties-master', name: 'Strategy Properties Master' },
    { id: 'strategy-benchmark-master', name: 'Strategy Bench Master' }
  ],
  sponsor: [
    { id: 'sponsor', name: 'Sponsor' },
    { id: 'strategy-owner', name: 'Strategy Owner' },
    { id: 'sponsor-financial-advisors', name: 'Sponsor Financial Advisors' }
  ],
  report: [
    { id: 'stats-level-code', name: 'Stats Level Code' },
    { id: 'stats-master', name: 'Stats Master' }
  ],
  instrument: [
    { id: 'instr-master', name: 'Instrument Master', searchable: true, searchFunc: getInstrumentMasterData, searchKey: 'instr' },
    { id: 'asset-class-map', name: 'Asset Class Map', searchable: true, searchFunc: getAssetClassMapData, searchKey: 'instrId' },
    { id: 'last-market-data', name: 'Last Market Data', searchable: true, searchFunc: getLastMarketData, searchKey: 'instrId' },
    { id: 'instr-id', name: 'Instrument Id', searchable: true, searchFunc: getInstrumentIdData, searchKey: 'instrId' },
    { id: 'instrument-split', name: 'Instrument Split Cal', searchable: true, dateSearchFunc: getSplitsCalData, searchKey: 'instrId' },
    { id: 'instrument-delist', name: 'Instrument Delist Cal', searchable: true, dateSearchFunc: getDelistingCalData, searchKey: 'instrId' }
  ],
  trade: [
    { id: 'trade-status', name: 'Trade Status' },
    { id: 'trade-status-master', name: 'Trade Status Master' }
  ],
  strategyAllocation: [
    { id: 'type-code', name: 'Type Code' },
    { id: 'dynamic-strategy', name: 'Dynamic Strategy' }
  ],
  optimization: [
    { id: 'opt-status-master', name: 'Optimization Status Master' },
    { id: 'opt-scenario-list', name: 'Optimization Scenario List' },
    { id: 'opt-scenario-properties', name: 'Optimization Scenario Properties' }
  ],
  master: [
    { id: 'account-status-codes', name: 'Account Status Code' },
    { id: 'restriction-codes', name: 'Restriction Code' },
    { id: 'sell-logic-codes', name: 'Sell Logic Code' },
    { id: 'tax-states', name: 'Tax States' },
    { id: 'property-codes', name: 'Property Code' }
  ],
  aggregate: [
    { id: 'aggregate-master', name: 'Aggregate Master' },
    { id: 'properties', name: 'Properties' },
    { id: 'external-gl', name: 'External GL' },
    { id: 'parameters', name: 'Parameters' },
    { id: 'tax-rate', name: 'Tax Rate' },
    { id: 'model-alloc', name: 'Model Allocation' },
    { id: 'account-map', name: 'Account Map' },
  ]
}
