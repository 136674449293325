import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react'
import { Box, Typography, Skeleton } from '@mui/material'
import Chart from 'react-apexcharts'
import { SolutionContext } from '..'

const TaxCostChart = () => {
  const solutionData = useContext(SolutionContext)
  const loading = solutionData?.isScenarioCardLoading || false;

  const series = useMemo(() => [
    {
      name: 'Tax Saving',
      data: (solutionData?.scenarioCardData || [])
        ?.map((scenarioObj) => ({
          x: scenarioObj?.taxCost || 0,
          y: parseFloat(((scenarioObj?.propTe || 0) * 100).toFixed(2)),
          active: (scenarioObj?.activeAlloc || 0) * 100,
          passive: (scenarioObj?.passiveAlloc || 0) * 100,
          name: scenarioObj?.aggName
        }))
        .sort((a, b) => b.y - a.y)
    }
  ], [solutionData?.scenarioCardData]);

  const formateToUsdCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const generatePieImage = useCallback((active, passive, size) => {
    const canvas = document.createElement('canvas')
    canvas.width = canvas.height = size
    const ctx = canvas.getContext('2d')
    const total = active + passive

    const drawSlice = (startAngle, endAngle, color) => {
      ctx.fillStyle = color
      ctx.beginPath()
      ctx.moveTo(size / 2, size / 2)
      ctx.arc(size / 2, size / 2, size / 2, startAngle, endAngle)
      ctx.closePath()
      ctx.fill()
    }

    drawSlice(0, (active / total) * Math.PI * 2, '#1454A6') // Active funds
    drawSlice((active / total) * Math.PI * 2, Math.PI * 2, '#CFD53A') // Passive funds

    return canvas.toDataURL()
  }, [])

  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: 'taxChart',
      type: 'line',
      toolbar: { show: false, zoom: false },
      zoom: { enabled: false },
      animations: { enabled: false }
    },
    xaxis: {
      title: { text: 'Tax Cost ($)', style: { fontWeight: 500, fontSize: '12px', color: '#8F9190' } },
      formatter: (value) => value?.toFixed(2),
      crosshairs: {
        show: false
      },
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      title: { text: 'Tracking Error (%)', style: { fontWeight: 500, fontSize: '12px', color: '#8F9190' } },
      formatter: (value) => value?.toFixed(2),
      min: Math.min(...series[0].data.map(d => d.y)) * 0.8,
      max: Math.max(...series[0].data.map(d => d.y)) * 1.1
    },
    stroke: { curve: 'smooth', width: 1, colors: ['#4a90e2'] },
    markers: { size: 5 },
    tooltip: {
      enabled: true,
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const point = w.config.series[seriesIndex].data[dataPointIndex]
        if (!point) {
          return ''
        }
        const active = point.active || 0
        const passive = point.passive || 0
        const scenario = point.name || 0
        const xValue = point.x || 0
        const yValue = point.y || 0
        return `
          <div style="padding: 12px; font-size: 13px; font-family: 'Open Sans', sans-serif; background: white; border-radius: 8px; box-shadow: 0 4px 6px rgba(0,0,0,0.1);">
          <div style="font-weight: 400; color: #8F9190;">Scenario:  ${scenario}</div> 
          <div style="font-weight: 400; color: #8F9190;">Active Allocation:  ${active}%</div>  
          <div style="font-weight: 400; color: #8F9190;">Passive Allocation:  ${passive}%</div>  
          <div style="font-weight: 400; color: #8F9190;">Tax Cost: ${formateToUsdCurrency.format(xValue)}</div>
            <div style="font-weight: 400; color: #8F9190;">Tracking Error: ${yValue}%</div>
          </div>
        `
        // return `
        //   <div style="padding: 12px; font-size: 13px; font-family: 'Open Sans', sans-serif; background: white; border-radius: 8px; box-shadow: 0 4px 6px rgba(0,0,0,0.1);">
        //     <div style="font-weight: 500; font-size: 14px; color: #34475A;">Active Funds</div>
        //     <div style="font-weight: 400; color: #8F9190;">Allocation:  ${active}%</div>
        //     <div style="font-weight: 400; color: #8F9190;">Tax Cost:  ${formateToUsdCurrency.format(xValue)}</div>
        //     <div style="font-weight: 400; color: #8F9190;">Tracking Error: ${yValue}%</div>
        //     <hr style="border: none; border-top: 1px solid #ddd; margin: 8px 0;" />
        //     <div style="font-weight: 500; font-size: 14px; color: #34475A;">Passive Funds</div>
        //     <div style="font-weight: 400; color: #8F9190;">Allocation: ${passive}%</div>
        //     <div style="font-weight: 400; color: #8F9190;">Tax Cost: ${formateToUsdCurrency.format(xValue)}</div>
        //     <div style="font-weight: 400; color: #8F9190;">Tracking Error: ${yValue}%</div>
        //     <hr style="border: none; border-top: 1px solid #ddd; margin: 8px 0;" />
        //     <div style="font-weight: 500; font-size: 14px; color: #34475A;">Aggregate</div>
        //     <div style="font-weight: 400; color: #8F9190;">Tax Cost: ${formateToUsdCurrency.format(xValue)}</div>
        //     <div style="font-weight: 400; color: #8F9190;">Tracking Error: ${yValue}%</div>
        //   </div>
        // `
      }
    },
    dataLabels: { enabled: false },
    annotations: { points: [] }
  })

  const updateChartAnnotations = useCallback(() => {
    const newAnnotations = series[0].data.map((point, index) => ({
      x: point.x,
      y: point.y,
      className: `annotation-point-${index}`,
      image: {
        path: generatePieImage(point.active, point.passive, 40),
        width: 40,
        height: 40
      }
    }))
    setChartOptions((prev) => ({ ...prev, annotations: { points: newAnnotations } }))
  }, [series, generatePieImage])

  const attachHoverEffects = useCallback(() => {
    const images = document.querySelectorAll('.apexcharts-point-annotations image')

    images.forEach((img, index) => {
      img.style.cursor = 'pointer'
      img.style.transition = 'width 0.2s ease, height 0.2s ease'

      const handleHover = () => {
        img.style.width = '50px'
        img.style.height = '50px'
        window.ApexCharts.exec('taxChart', 'showTooltip', [0, index])
      }

      const handleLeave = () => {
        img.style.width = '40px'
        img.style.height = '40px'
        window.ApexCharts.exec('taxChart', 'hideTooltip', [0, index])
      }

      img.addEventListener('mouseenter', handleHover)
      img.addEventListener('mouseleave', handleLeave)
    })
  }, [])

  useEffect(() => {
    updateChartAnnotations()
  }, [updateChartAnnotations])

  useEffect(() => {
    if (!loading) {
      setTimeout(() => attachHoverEffects(), 500)
    }
  }, [loading, chartOptions.annotations, attachHoverEffects])

  // Chart skeleton loader
  const ChartSkeleton = () => (
    <Box sx={{ width: '100%', height: 400 }}>
      <Skeleton variant="rectangular" width="100%" height={50} sx={{ mb: 2 }} />
      <Skeleton variant="rectangular" width="100%" height={300} sx={{ mb: 2 }} />
      <Box display="flex" justifyContent="space-between">
        <Skeleton variant="rectangular" width="20%" height={20} />
        <Skeleton variant="rectangular" width="20%" height={20} />
      </Box>
    </Box>
  )

  return (
    <>
      <style>
        {`
          .apexcharts-point-annotations image {
            cursor: pointer;
            transition: width 0.2s ease, height 0.2s ease;
          }
        `}
      </style>
      {loading ? (
        // Show skeleton while loading
        <>
          <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
            <Skeleton variant="text" width={150} height={30} />
            <Box display='flex' gap={2}>
              <Skeleton variant="rectangular" width={80} height={20} />
              <Skeleton variant="rectangular" width={80} height={20} />
            </Box>
          </Box>
          <ChartSkeleton />
        </>
      ) : (
        // Show actual chart when loaded
        <>
          <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
            <Typography sx={{ fontWeight: 600, fontSize: '16px', color: '#2f2f2f' }}>Scenario Analysis</Typography>
            <Box display='flex' gap={1} alignItems='center'>
              <Box display='flex' alignItems='center'>
                <Box width={14} height={14} borderRadius='2px' bgcolor='#1454A6' />
                <Typography sx={{ fontSize: '10px', color: '#2f2f2f', ml: '5px', fontWeight: 600 }}>Active Funds</Typography>
              </Box>
              <Box display='flex' alignItems='center'>
                <Box width={14} height={14} borderRadius='2px' bgcolor='#CFD53A' />
                <Typography sx={{ fontSize: '10px', color: '#2f2f2f', ml: '5px', fontWeight: 600 }}>Passive Funds</Typography>
              </Box>
            </Box>
          </Box>
          <Chart options={chartOptions} series={series} type='line' height={400} />
        </>
      )}
    </>
  )
}

export default TaxCostChart
