import { Fragment, useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Box, Grid, FormControl, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { ACLLoadingTable } from './ACLLoadingTable'
import { closeOnSaveExcludeResourceList } from './PermissionTable'
import { useAuth } from '../../contexts/AuthContext'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import { useErrorToast } from '../../hooks/useErrorToast'

const ManageStrategyTable = (props) => {
  const {
    allStrategiesList, strategyListLoading, setSelectionModel,
    selectionModel, closeModal, modelProps, allStrIdsFlag, defaultCheckBoxApiLoading, setAllStrIdsFlag, allActiveStrIdsFlag, setAllActiveStrIdsFlag
  } = props
  const { user } = useAuth()
  const [currentPage, setCurrentPage] = useState(0)
  const [row, setRow] = useState([])
  const { showSuccess } = useSuccessToast()
  const { showError } = useErrorToast()
  const [isSortProcessLoading, setIsSortProcessLoading] = useState(true)
  const [currentTab, setCurrentTab] = useState('')
  const [idSaveLoading, setIdSaveLoading] = useState(false)
  const [allStrategies, setAllStrategies] = useState([])
  const [allActiveStrategies, setAllActiveStrategies] = useState([])
  const [isStrategiesSorting, setIsStrategiesSorting] = useState(true)
  const [sortStrategiesFlag, setSortStrategiesFlag] = useState(true)

  const columns = [
    {
      field: 'strategyName',
      headerName: 'Strategy Name',
      flex: 2
    },
    {
      field: 'strategyCd',
      headerName: 'Strategy Code',
      flex: 2
    },
    {
      field: 'strTypeCode',
      headerName: 'Strategy Type',
      flex: 2
    },
    {
      field: 'strategyId',
      headerName: 'StrategyId',
      flex: 2,
      hidden: true
    }
  ]

  useEffect(() => {
    if (!strategyListLoading && !defaultCheckBoxApiLoading) {
      if (allStrIdsFlag) {
        setCurrentTab('All Strategies')
        const selectedStrategies = allStrategiesList?.map((data) => data.strategyId)
        setAllStrategies(selectedStrategies)
      } else if (allActiveStrIdsFlag) {
        setCurrentTab('All Active Strategies')
        const activeStrategies = allStrategiesList?.filter((d) => d.strTypeCode === 'MANAGED')?.map((data) => data.strategyId)
        setAllActiveStrategies(activeStrategies)
      } else {
        setCurrentTab('Select Manually')
      }
    }
  }, [allStrIdsFlag, allActiveStrIdsFlag, strategyListLoading, defaultCheckBoxApiLoading])

  useEffect(() => {
    if (selectionModel?.length > 0 && !allActiveStrIdsFlag && !allStrIdsFlag) {
      if (allStrategiesList && sortStrategiesFlag && selectionModel?.length > 0) {
        const reorderedRows = [...allStrategiesList].sort((a, b) => {
          const isSelectedA = sortStrategiesFlag && selectionModel?.includes(a?.strategyId)
          const isSelectedB = sortStrategiesFlag && selectionModel?.includes(b?.strategyId)
          return isSelectedB - isSelectedA
        })
        if (reorderedRows && reorderedRows.length > 0 && selectionModel?.length > 0) {
          setIsStrategiesSorting(false)
          setRow(reorderedRows)
        }
        setIsSortProcessLoading(false)
      }
    } else {
      if (allActiveStrIdsFlag) {
        const activeStrategies = allStrategiesList?.filter((d) => d.strTypeCode === 'MANAGED')
        setRow(activeStrategies)
        setIsStrategiesSorting(false)
        setIsSortProcessLoading(false)
      } else if (allStrategiesList && !allActiveStrIdsFlag) {
        setRow(allStrategiesList)
        setIsStrategiesSorting(false)
        setIsSortProcessLoading(false)
      }
    }
  }, [allStrategiesList, modelProps, sortStrategiesFlag, selectionModel, allActiveStrIdsFlag, allStrIdsFlag])

  const handleAccountTypeChange = (e) => {
    if (e.target.value === 'Select Manually') {
      setCurrentTab(e.target.value)
      setAllStrIdsFlag(false)
      setAllActiveStrIdsFlag(false)
      setSortStrategiesFlag(true)
    } else if (e.target.value === 'All Active Strategies') {
      setCurrentTab(e.target.value)
      const activeStrategies = allStrategiesList?.filter((d) => d.strTypeCode === 'MANAGED')?.map((data) => data.strategyId)
      setAllActiveStrategies(activeStrategies)
      setAllActiveStrIdsFlag(true)
      setAllStrIdsFlag(false)
    } else {
      setCurrentTab(e.target.value)
      const selectedAccounts = allStrategiesList.map((data) => data.strategyId)
      setAllStrategies(selectedAccounts)
      setAllStrIdsFlag(true)
      setAllActiveStrIdsFlag(false)
    }
  }

  const handleStrategyIdsSave = () => {
    setIdSaveLoading(true)
    API.patch('baseAclURL2', `user-access-control/v1/${user?.userGroup}/user/strategies/${modelProps?.userId}`, {
      body: [{
        serviceId: modelProps?.serviceId,
        resourceId: modelProps?.resourceId,
        allStrategies: currentTab === 'All Strategies',
        allActiveStrategies: currentTab === 'All Active Strategies',
        strategyIds: currentTab === 'Select Manually' ? selectionModel : currentTab === 'All Active Strategies' ? allActiveStrategies : allStrategies
      }]
    }).then((res) => {
      if (res && res?.success) {
        showSuccess(res?.message)
        setIdSaveLoading(false)
      }
    }).catch((error) => {
      setIdSaveLoading(false)
      showError(error, false, {}, 'Failed to update strategy access.')
    }).finally(() => {
      setIdSaveLoading(false)
      if (!closeOnSaveExcludeResourceList.includes(modelProps?.resourceId)) {
        closeModal()
      }
    })
  }

  return (
    <Box sx={{ width: 'auto', height: '600px', minHeight: '600px', maxHeight: '600px' }}>
      <Grid container xs={12} justifyContent='space-between' alignItems='center' mb={1} mt={1}>
        <FormControl style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '5px 0 0 16px' }}>
          <RadioGroup
            name='radio-buttons-group'
            style={{ display: 'flex', flexDirection: 'row', marginLeft: '0px' }}
          >
            <FormControlLabel
              disabled={defaultCheckBoxApiLoading || strategyListLoading || isStrategiesSorting} value='Select Manually'
              onChange={handleAccountTypeChange} control={<Radio />} label='Select Manually'
              defaultChecked={false}
              checked={currentTab === 'Select Manually' && !allStrIdsFlag && !allActiveStrIdsFlag && !isStrategiesSorting && !isSortProcessLoading && !strategyListLoading && !defaultCheckBoxApiLoading}
            />
            <FormControlLabel
              disabled={defaultCheckBoxApiLoading || strategyListLoading || isStrategiesSorting} value='All Strategies'
              onChange={handleAccountTypeChange} control={<Radio />} label='All Strategies'
              defaultChecked={false}
              checked={currentTab === 'All Strategies' && allStrIdsFlag && !isStrategiesSorting && !isSortProcessLoading && !strategyListLoading && !defaultCheckBoxApiLoading}
            />
            <FormControlLabel
              disabled={defaultCheckBoxApiLoading || strategyListLoading || isStrategiesSorting} value='All Active Strategies'
              onChange={handleAccountTypeChange} control={<Radio />} label='All Active Strategies'
              defaultChecked={false}
              checked={currentTab === 'All Active Strategies' && allActiveStrIdsFlag && !isStrategiesSorting && !isSortProcessLoading && !strategyListLoading && !defaultCheckBoxApiLoading}
            />
          </RadioGroup>
        </FormControl>
        {idSaveLoading
          ? (
            <LoadingButton
              loading
              loadingPosition='start'
              variant='outlined'
              sx={{ float: 'right', width: '120px' }}
            >
              Apply
            </LoadingButton>
            )
          : (
            <Button
              disabled={strategyListLoading || defaultCheckBoxApiLoading || row?.length < 1 || isStrategiesSorting}
              variant='contained' color='primary' sx={{ float: 'right', width: '100px' }} onClick={handleStrategyIdsSave}
            >Apply
            </Button>
            )}
      </Grid>
      <Grid container xs={12} justifyContent='space-between' alignItems='center' mb={2}>
        {(isSortProcessLoading || strategyListLoading || isStrategiesSorting || defaultCheckBoxApiLoading || !currentTab)
          ? (
            <>
              <ACLLoadingTable headers={columns?.filter(col => !col.hidden)} />
            </>
            )
          : (
            <DataGridPro
              autoHeight
              rows={row}
              columns={columns}
              rowSelectionModel={currentTab === 'Select Manually' ? selectionModel : currentTab === 'All Active Strategies' ? allActiveStrategies : allStrategies}
              onRowSelectionModelChange={(e) => {
                setSortStrategiesFlag(false)
                setSelectionModel(e)
              }}
              getRowId={(row) => row?.strategyId}
              checkboxSelection
              density='compact'
              sx={{
                '&.MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                  display: (currentTab === 'All Strategies' || currentTab === 'All Active Strategies') ? 'none' : ''
                }
              }}
              pageSizeOptions={[10]}
              isRowSelectable={(params) => currentTab !== 'All Strategies' && currentTab !== 'All Active Strategies'}
              disableMultipleRowSelection={currentTab === 'All Strategies' || currentTab === 'All Active Strategies'}
              onPaginationModelChange={(event) => setCurrentPage(event.page)}
              pagination
              paginationModel={{ pageSize: 10, page: currentPage }}
              initialState={{
                ...row?.initialState,
                columns: {
                  columnVisibilityModel: {
                    strategyId: false
                  }
                }
              }}
            />
            )}
      </Grid>
    </Box>
  )
}

export default ManageStrategyTable
