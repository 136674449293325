import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography
} from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { randomId } from '@mui/x-data-grid-generator'
import '../../assets/styles/account-onboarding.scss'
import { FundingSource } from '../../contstants/constants'
import './AccountOnboarding.scss'

const NewConfirmationTab = ({ accountDetails, taxlotData, strategy, personlization, selectedCustodian, allScenarioList }) => {
  const chipStyles = {
    borderRadius: '5px', // set the border radius to create the rounded corners
    border: '1px solid rgba(0, 0, 0, 0.4)',
    backgroundColor: '#f6f6f6',
    color: '#34475A', // set the text color
    fontWeight: 600, // set the font weight
    fontSize: '14px',
    fontFamily: 'Open Sans',
    padding: '4px', // set the padding to create the size of the chip
    marginRight: '10px',
    marginTop: '12px'
  }

  const formateToUsdCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
    // updating into USD Currency
  })

  // format date to YYYY-MM-DD
  const formatDate = (dateStr) => {
    const date = new Date(dateStr)
    const year = date?.getFullYear()
    const month = String(date?.getMonth() + 1).padStart(2, '0')
    const day = String(date?.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const columns = [
    { field: 'name', headerName: 'Name', flex: 2 },
    {
      field: 'symbol',
      headerName: 'Symbol'
    },
    {
      field: 'shares',
      headerName: 'Shares',
      type: 'number'
    },
    {
      field: 'purchaseCost',
      headerName: 'Purchase Cost',
      type: 'number',
      valueFormatter: (params) => params.value >= 0 ? formateToUsdCurrency.format(params.value) : formateToUsdCurrency?.format(params.value),
      flex: 1
    },
    {
      field: 'purchaseDate',
      headerName: 'Purchase Date',
      valueFormatter: (params) => formatDate(params.value), // purchaseDate to YYYY-MM-DD format
      type: 'number',
      flex: 1
    },
    {
      field: 'isoCountryCode',
      headerName: 'ISO Country Code',
      flex: 1
    }
  ]

  return (
    <Box className='account-details-container'>
      <Typography className='summary-header'>Summary</Typography>
      <hr style={{ borderBottom: '1px solid #dee2e6', borderTop: '0' }} />
        <Grid container spacing={3}>
          <Grid item lg={9}>
            <Card className='card-layout'>
              <CardContent sx={{ marginBottom: '12px' }}>
                <Typography variant='h3' className='sub-title'>Basic Information</Typography>
                <Grid container spacing={3}>
                  <Grid item lg={4}>
                    <Box>
                      <Typography className='sub-labels'>
                        Account Type
                      </Typography>
                      <Typography className='label-value' style={{ textTransform: 'capitalize' }}>
                        {accountDetails?.accountType || ''}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box>
                      <Typography className='sub-labels'>Name</Typography>
                      <Typography className='label-value'>
                        {accountDetails?.name || ''}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box>
                      <Typography className='sub-labels'>
                        Client Email
                      </Typography>
                      <Typography className='label-value'>
                        {accountDetails?.email || ''}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box>
                      <Typography className='sub-labels'>
                        Funding Source
                      </Typography>
                      <Typography className='label-value'>
                        {FundingSource[taxlotData?.fundingSource] || ''}
                      </Typography>
                    </Box>
                  </Grid>
                  {accountDetails?.accountType === 'trading'
                    ? (
                      <>
                        <Grid item lg={4}>
                          <Box>
                            <Typography className='sub-labels'>
                              Custodian
                            </Typography>
                            <Typography className='label-value'>
                              {selectedCustodian?.custodianName || ''}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={4}>
                          <Box>
                            <Typography className='sub-labels'>
                              Custodian Number
                            </Typography>
                            <Typography className='label-value'>
                              {accountDetails?.custodianNumber || ''}
                            </Typography>
                          </Box>
                        </Grid>
                        {
                          selectedCustodian?.custodianName === 'Charles Schwab'
                            ? <Grid item lg={4}>
                              <Box>
                                <Typography className='sub-labels'>
                                  Master Account ID
                                </Typography>
                                <Typography className='label-value'>
                                  {accountDetails?.masterAccountId || ''}
                                </Typography>
                              </Box>
                              </Grid>
                            : ''
                        }
                      </>
                      )
                    : null}
                </Grid>
              </CardContent>
            </Card>
            {taxlotData?.totalCashValue || taxlotData?.totalPortfolioMarketValue
              ? <Card className='card-layout'>
                <CardContent sx={{ marginBottom: '12px' }}>
                  <Typography variant='h3' className='sub-title'>Current Portfolio</Typography>
                  <Grid container spacing={3}>
                    {taxlotData?.totalPortfolioMarketValue &&
                      (FundingSource[taxlotData?.fundingSource] === 'Cash & Security' || FundingSource[taxlotData?.fundingSource] === 'Security only')
                      ? <Grid item lg={4}>
                        <Box>
                          <Typography className='sub-labels'>
                            Total Portfolio Market Value
                          </Typography>
                          <Typography className='label-value' style={{ textTransform: 'capitalize' }}>
                            {(taxlotData?.totalPortfolioMarketValue !== null || taxlotData?.totalPortfolioMarketValue !== '')
                              ? Number(taxlotData?.totalPortfolioMarketValue) < 0
                                ? `-$${Math.abs(taxlotData?.totalPortfolioMarketValue)?.toLocaleString('en')}`
                                : `$${Number(taxlotData?.totalPortfolioMarketValue)?.toLocaleString('en')}`
                              : '-'}
                          </Typography>
                        </Box>
                        </Grid>
                      : ''}
                    {taxlotData?.totalCashValue &&
                      (FundingSource[taxlotData?.fundingSource] === 'Cash & Security' || FundingSource[taxlotData?.fundingSource] === 'Cash only')
                      ? <Grid item lg={4}>
                        <Box>
                          <Typography className='sub-labels'>Total Cash Value</Typography>
                          <Typography className='label-value'>
                            {taxlotData?.totalCashValue
                              ? Number(taxlotData?.totalCashValue) < 0
                                ? `-$${Math.abs(Number(taxlotData?.totalCashValue)).toLocaleString('en')}`
                                : `$${Number(taxlotData?.totalCashValue).toLocaleString('en')}`
                              : ''}
                          </Typography>
                        </Box>
                        </Grid>
                      : ' '}
                  </Grid>
                </CardContent>
                </Card>
              : ''}
            {accountDetails?.taxManaged
              ? <Card className='card-layout'>
                <CardContent sx={{ marginBottom: '12px' }}>
                  <Typography variant='h6' className='sub-title'>
                    Tax Information
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item lg={4}>
                      <Box>
                        <Typography className='sub-labels'>
                          Is tax managed?
                        </Typography>
                        <Typography className='label-value'>
                          {accountDetails?.taxManaged ? 'Yes' : 'No'}
                        </Typography>
                      </Box>
                    </Grid>
                    {accountDetails?.taxManaged
                      ? (
                        <>
                          <Grid item lg={4}>
                            <Box>
                              <Typography className='sub-labels'>
                                Tax State
                              </Typography>
                              <Typography className='label-value'>
                                {accountDetails.taxState ? JSON.parse(accountDetails.taxState).stateName : ''}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item lg={4}>
                            <Box>
                              <Typography className='sub-labels'>
                                Short Term Tax{' '}
                              </Typography>
                              <Typography variant='h3' className='label-value'>
                                {
                                  accountDetails?.shortTermTaxRate || '0'
                                }
                                %
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item lg={4}>
                            <Box>
                              <Typography className='sub-labels'>
                                Long Term Tax
                              </Typography>
                              <Typography variant='h3' className='label-value'>
                                {
                                  accountDetails?.longTermTaxRate || '0'
                                }
                                %
                              </Typography>
                            </Box>
                          </Grid>
                        </>)
                      : ''}
                    {accountDetails?.taxManaged && accountDetails?.showAdvancedTax
                      ? <>
                        <Grid item lg={4}>
                          <Box>
                            <Typography className='sub-labels'>
                              External realized long gain or losses
                            </Typography>
                            <Typography className='label-value'>
                              {formateToUsdCurrency.format(
                                accountDetails?.externalLongGainLoss
                              ) || '0'}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={4}>
                          <Box>
                            <Typography className='sub-labels'>
                              External realized short gain or losses
                            </Typography>
                            <Typography className='label-value'>
                              {formateToUsdCurrency.format(
                                accountDetails?.externalShortGainLoss
                              ) || '0'}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={4}>
                          <Box>
                            <Typography className='sub-labels'>
                              Tax Year
                            </Typography>
                            <Typography className='label-value'>
                              {new Date().getFullYear()}
                            </Typography>
                          </Box>
                        </Grid>
                        </>
                      : ''}
                  </Grid>
                </CardContent>
                </Card>
              : null}
            {!(
              personlization?.factorUtilData?.filter(factor => factor?.value !== '' || factor?.selected !== false)?.length > 0 ||
              personlization?.sectorData?.filter(sector => sector?.min !== '' || sector?.max !== '' || sector?.exclude !== false)?.length > 0 ||
              personlization?.industryData?.filter(industry => industry?.min !== '' || industry?.max !== '' || industry?.exclude !== false)?.length > 0 ||
              personlization?.securityData?.length > 0 ||
              personlization?.factorUtilData?.filter(factor => factor?.value !== '' || factor?.selected !== false)?.length > 0 ||
              personlization?.substitutionData?.length > 0 ||
              personlization?.taxConstraintData?.filter(taxConstraint => taxConstraint?.value !== '')?.length > 0 ||
              personlization?.cashConstraintData?.filter(cashConstraint => cashConstraint?.value !== null && cashConstraint?.value !== undefined && cashConstraint?.value !== '')?.length > 0 ||
              personlization?.otherConstraintData?.filter(otherConstraint => otherConstraint?.value !== null && otherConstraint?.value !== undefined && otherConstraint?.value !== '')?.length > 0
            )
              ? null
              : <Card className='card-layout'>
                <CardContent sx={{ marginBottom: '12px' }}>
                  <Typography variant='h3' className='sub-title'>Personalization</Typography>
                  <Grid container spacing={2} columns={12}>
                    {personlization?.sectorData?.filter(sector => sector?.min !== '' || sector?.max !== '' || sector?.exclude !== false)?.length > 0
                      ? <Grid item xs={12}>
                        <Box>
                          <Typography className='sub-labels-pers' sx={{ color: '#74788d', fontWeight: 600 }}>
                            Sector
                          </Typography>
                          {(personlization.sectorData || [])?.map((sector) => {
                            let valueSel = `${sector.sectorName} | `
                            if (sector.exclude) {
                              valueSel += 'Exclude'
                            } else if (sector.min && sector.max) {
                              valueSel += `${sector.min}-${sector.max}`
                            } else if (sector.min) {
                              valueSel += `${sector.min}`
                            } else if (sector.max) {
                              valueSel += `${sector.max}`
                            }
                            return (sector.min !== '' || sector.max !== '' || sector.exclude !== false) ? <Chip style={chipStyles} label={valueSel} /> : <></>
                          })}
                        </Box>
                        </Grid>
                      : null}
                    {personlization?.industryData?.filter(industry => industry?.min !== '' || industry?.max !== '' || industry?.exclude !== false)?.length > 0
                      ? <Grid item xs={12}>
                        <Box>
                          <Typography className='sub-labels-pers' sx={{ color: '#74788d', fontWeight: 600 }}>
                            Industry
                          </Typography>
                          {(personlization.industryData || [])?.map((industry) => {
                            let valueSel = `${industry.industry} | `
                            if (industry.exclude) {
                              valueSel += 'Exclude'
                            } else if (industry.min && industry.max) {
                              valueSel += `${industry.min}-${industry.max}`
                            } else if (industry.min) {
                              valueSel += `${industry.min}`
                            } else if (industry.max) {
                              valueSel += `${industry.max}`
                            }
                            return (industry.min !== '' || industry.max !== '' || industry.exclude !== false) ? <Chip label={valueSel} style={chipStyles} /> : <></>
                          })}
                        </Box>
                        </Grid>
                      : null}
                    {personlization?.securityData?.length > 0 && (
                      <Grid item xs={12}>
                        <Box>
                          <Typography className='sub-labels-pers' sx={{ color: '#74788d', fontWeight: 600 }}>
                            Security Restrictions
                          </Typography>
                          {(personlization.securityData || [])?.map((security) => (
                            <Chip label={`${security.name} | ${security.action}${security.weight ? ` | ${security.weight}` : ''}`} style={chipStyles} />
                          ))}
                        </Box>
                      </Grid>
                    )}
                    {personlization?.factorUtilData?.filter(factor => factor?.value !== '' || factor?.selected !== false)?.length > 0
                      ? <Grid item xs={12}>
                        <Box>
                          <Typography className='sub-labels-pers' sx={{ color: '#74788d', fontWeight: 600 }}>
                            Factor Tilts
                          </Typography>
                          {(personlization.factorUtilData || []).map((factor) => {
                            return (factor.value !== '' || factor.selected !== false) ? <Chip label={`${factor.attrLabel} | ${factor.value}`} style={chipStyles} /> : <></>
                          })}
                        </Box>
                        </Grid>
                      : null}
                    {personlization?.substitutionData?.length > 0
                      ? <Grid item xs={12}>
                        <Box>
                          <Typography className='sub-labels-pers' sx={{ color: '#74788d', fontWeight: 600 }}>
                            Substitutes
                          </Typography>
                          {(personlization.substitutionData || [])?.map(
                            (security, index) => {
                              return (
                                <Chip key={index} style={chipStyles} label={`${security.source} -> ${security.target}`} />
                              )
                            }
                          )}
                        </Box>
                        </Grid>
                      : null}
                    <Grid item xs={12}>
                      {personlization?.cashConstraintData?.filter(cashConstraint => cashConstraint?.value !== null && cashConstraint?.value !== undefined && cashConstraint?.value !== '')?.length > 0
                        ? (
                          <Box>
                            <Typography className='sub-labels-pers' sx={{ color: '#74788d', fontWeight: 600 }}>
                              Cash Constraints
                            </Typography>
                            {(personlization?.cashConstraintData || []).map((cashConstraint, index) => (
                              cashConstraint.value !== '' && cashConstraint.value !== undefined
                                ? (
                                  <Chip style={chipStyles} label={`${cashConstraint?.propertyDesc} | ${cashConstraint?.value}`} />
                                  )
                                : null
                            ))}
                          </Box>
                          )
                        : null}

                    </Grid>
                    <Grid item xs={12} style={{ display: personlization?.taxConstraintData?.filter(taxConstraint => taxConstraint?.value !== '')?.length > 0 ? 'block' : 'none' }}>
                      {personlization?.taxConstraintData?.filter(taxConstraint => taxConstraint?.value !== '')?.length > 0
                        ? <Box>
                          <Typography className='sub-labels-pers' sx={{ color: '#74788d', fontWeight: 600 }}>
                            Tax Constraints
                          </Typography>
                          {(personlization?.taxConstraintData || [])?.map(
                            (taxConstraint, index) => {
                              return (taxConstraint?.value !== '') ? <Chip style={chipStyles} label={`${taxConstraint?.name} | ${taxConstraint?.value}`} /> : <></>
                            }
                          )}
                          </Box>
                        : null}
                    </Grid>
                    <Grid item xs={12} style={{ display: personlization?.otherConstraintData?.filter(otherConstraint => otherConstraint?.value !== null && otherConstraint?.value !== undefined && otherConstraint?.value !== '')?.length > 0 ? 'block' : 'none' }}>
                      {personlization?.otherConstraintData?.filter(otherConstraint => otherConstraint?.value !== null && otherConstraint?.value !== undefined && otherConstraint?.value !== '')?.length > 0
                        ? (
                          <Box>
                            <Typography className='sub-labels-pers' sx={{ color: '#74788d', fontWeight: 600 }}>
                              Other Constraints
                            </Typography>
                            {(personlization?.otherConstraintData || [])?.map((otherConstraint, index) => {
                              if (otherConstraint?.propertyCode === 'DEFAULT_SCENARIO_CODE') {
                                // Find the scenario description from allScenarioList
                                const scenario = allScenarioList.find(scenario => scenario?.scenarioCode === otherConstraint?.value)
                                const scenarioDescription = scenario ? scenario?.scenarioDescription : 'NA'
                                return (
                                  <Chip
                                    style={chipStyles}
                                    label={`${otherConstraint?.propertyDesc} | ${scenarioDescription}`}
                                  />
                                )
                              } else {
                                return (
                                  otherConstraint?.value !== undefined && otherConstraint?.value !== '' && (
                                    <Chip
                                      style={chipStyles}
                                      label={`${otherConstraint?.propertyDesc} | ${otherConstraint?.value}`}
                                    />
                                  )
                                )
                              }
                            })}
                          </Box>
                          )
                        : null}
                    </Grid>
                  </Grid>
                </CardContent>
                </Card>}
            {taxlotData?.fundingSource !== 'CASH'
              ? <Card className='card-layout'>
                <CardContent sx={{ marginBottom: '12px' }}>
                  <Box>
                    <Typography variant='h3' className='sub-title'>Securities</Typography>
                    <DataGridPro
                      autoHeight
                      rows={taxlotData?.stagedData
                        ? taxlotData?.stagedData?.map((security) => ({
                          ...security,
                          id: randomId(),
                          purchaseCost: (security.symbol === '__USD' || security.symbol === '__CASH') ? security?.shares : security?.purchaseCost
                        }))
                        : []}
                      columns={columns}
                      pagination
                      pageSizeOptions={[5]}
                      initialState={{
                        ...taxlotData?.stagedData?.initialState,
                        pagination: { paginationModel: { pageSize: 5 } }
                      }}
                      getRowId={(row) => row?.id}
                      disableRowSelectionOnClick
                    />
                  </Box>
                </CardContent>
                </Card>
              : ''}
          </Grid>
          <Grid item lg={3}>
            <Card sx={{ minWidth: '100%' }} className='card-layout'>
              <CardContent>
                <Typography
                  variant='h2'
                  className='sub-title mt-0 mb-4'
                  id={strategy && strategy?.strategyId}
                >
                  Model
                </Typography>
                <Typography className='sub-labels'>Portfolio Specification</Typography>
                <Typography className='label-value'>{strategy?.portSpecName}</Typography>
                <Typography className='sub-labels' mt={3}>Strategy</Typography>
                <Typography className='label-value' mt={1}>{strategy?.strategyName}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
    </Box>
  )
}

export default NewConfirmationTab
