import { Box } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'

const SectorTable = ({ rows, columns, minMaxPerColumn }) => {
  const getCellClassName = params => {
    if (params?.field === 'strategy1'
      || params?.field === 'sector'
      || isNaN(params?.value)
      || isNaN(params?.row?.strategy1)
    ) return ''
    if (params?.field === 'originalUMA' && params?.row?.isOrgUMAOverWeighted === true) return 'high-deviation'
    if (params?.field === 'originalUMA' && params?.row?.isOrgUMAUnderWeighted === true) return 'low-deviation'
    if (params?.field === 'scenarioUMA' && params?.row?.isTrgUMAOverWeighted === true) return 'high-deviation'
    if (params?.field === 'scenarioUMA' && params?.row?.isTrgUMAUnderWeighted === true) return 'low-deviation'

    // const difference = Math.abs(params?.row?.[params?.field] - params?.row?.strategy1)
    // const minMax = minMaxPerColumn[params?.field]
    // if (difference >= minMax?.secondHighest) return 'high-deviation'
    // if (difference <= minMax?.secondLowest) return 'low-deviation'
    return ''
  }

  return (
    <Box sx={{ mt: '20px' }}>
      <DataGridPro
        autoHeight
        density='compact'
        rows={rows}
        columns={columns}
        getRowId={(row) => row?.id}
        disableRowSelectionOnClick
        localeText={{ noRowsLabel: 'No Portfolio Found' }}
        getCellClassName={getCellClassName}
        sx={{
          [`.${gridClasses.pinnedRows}`]: { boxShadow: 'none', borderBlock: '1px solid black', background: '#FBFBFD' },
          '.high-deviation': {
            background: '#F7E9EF9F'
          },
          '.low-deviation': {
            background: '#FBEBC26F'
          },
          '.mid-deviation': {
            background: '#FFFFFF'
          }
        }}
      />
    </Box>
  )
}

export default SectorTable
