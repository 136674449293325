import { Grid } from '@mui/material'
import Consideration from '../components/Consideration'
import { Card } from '../components'
import ScenarioCards from './ScenarioCards'
import TaxCostChart from './TaxSavingChart'
import TaxSavingLineChart from './TaxSavingLineChart'
import PerformanceLineChart from './PerformanceLineChart'
import TaxFocusedProgressCards from './TaxFocusedProgressCards'

const SolutionSummary = () => {
  return (
    <Grid container
      direction='column'
      spacing={1} mt={3}
      sx={{
        '.MuiGrid-root': { width: '100%' }
      }}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Card>
              <TaxSavingLineChart />
            </Card>
          </Grid>
          <Grid item md={6} xs={12}>
            <Card>
              <PerformanceLineChart />
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <Card>
              <TaxCostChart />
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <TaxFocusedProgressCards />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ScenarioCards />
      </Grid>
      <Grid item xs={12}>
        <Consideration isSolutionScreen />
      </Grid>
    </Grid>
  )
}

export default SolutionSummary
