import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Fade, List, ListItem, ListItemIcon, ListItemText, Paper, Popover, useTheme } from '@mui/material'
import 'rc-slider/assets/index.css'
import { hexToRgba } from '../utils/HexOperations'
import { useAuth } from '../contexts/AuthContext'
import { useSelector } from 'react-redux'

const NonSubmenuItems = (props) => {
  const { activePath, icon, linkTo, label, id, handleSubmenuToggle, isSidebarOpen, leftSidebarProps, isPreview } = props
  const { user } = useAuth()
  const themeData = useSelector((state) => state.theme)
  const [anchorEl, setAnchorEl] = useState(null)
  const isActive = activePath === linkTo
  const theme = useTheme()
  const [sidebarFullyClosed, setSidebarFullyClosed] = useState(false);

  useEffect(() => {
    if (!isSidebarOpen) {
      const timeout = setTimeout(() => setSidebarFullyClosed(true), 500)
      return () => clearTimeout(timeout)
    } else {
      setSidebarFullyClosed(false)
    }
  }, [isSidebarOpen])

  // set dynamic colors for leftside bar service name text
  const primaryText = user?.userGroup === 'adv-classic' && theme.palette.primaryPalette.contrastText ? theme.palette.primaryPalette.contrastText : user?.userGroup === 'admin' && leftSidebarProps ? leftSidebarProps?.brandTextColor : 'white'
  const secondaryText = user?.userGroup === 'adv-classic' && theme.palette.paletteTextColor.contrastText
    ? hexToRgba(theme.palette.paletteTextColor.contrastText, themeData?.opacity)
    : user?.userGroup === 'admin' && leftSidebarProps ? hexToRgba(leftSidebarProps?.brandTextColor, leftSidebarProps.opacity) : 'rgba(255, 255, 255, 0.5)'

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handlePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget)
  }

  return (
    <ListItem
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      className='menu-item'
      onClick={handleSubmenuToggle}
      component={isPreview ? 'div' : NavLink}
      to={linkTo}
      id={id}
    >
      <ListItemIcon id={id + '-icon'} sx={{ color: isActive ? primaryText : secondaryText }}>
        {
          icon?.type === 'svg'
            ? <icon.inactive sx={{ width: '20px', height: '20px', color: isActive ? primaryText : secondaryText }} />
            : icon?.type === 'component'
              ? <icon.component color={isActive ? primaryText : secondaryText} size='18px' />
              : <img
                src={isActive ? icon?.active : icon?.inactive}
                color={isActive ? primaryText : secondaryText}
                alt='menu-icon'
                className='inline-block'
                style={{ width: '18px', height: '18px' }}
              />
        }
      </ListItemIcon>
      {
        (!isSidebarOpen && sidebarFullyClosed && anchorEl) ? (
          <SubMenuPopover
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            name={label}
            isActive={isActive}
            path={linkTo}
            isPreview={isPreview}
          />
        ) : ''
      }
      <ListItemText sx={{ color: isActive ? primaryText : secondaryText }} primary={label} className='menu-link' />
    </ListItem>
  )
}

const SubMenuPopover = (props) => {
  const {
    name,
    anchorEl,
    onClose,
    isActive,
    path,
    isPreview
  } = props
  return (
    <Popover
      className='nav-popup'
      disableScrollLock
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorEl={anchorEl}
      TransitionComponent={Fade}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      sx={{
        pointerEvents: 'none',
        minWidth: '180px',
        p: '4px'
      }}
      slotProps={{
        paper: {
          sx: {
            borderRadius: '8px',
          }
        }
      }}
    >
      <Paper
        elevation={0}
        sx={{
          minWidth: '180px',
          backgroundColor: 'white',
          padding: '5px 4px',
          pointerEvents: 'all',
        }}>
        <List
          className='sub-menu'
          disablePadding
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            ...(isPreview ? { pointerEvents: 'none' } : {})
          }}
        >
          <ListItem
            component={NavLink}
            to={path}
            onClick={(e) => e.stopPropagation()}
            sx={{
              p: 0,
              m: 0,
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#919eab1F'
              }
            }}
          >
            <ListItemText
              primary={name}
              primaryTypographyProps={{
                margin: 0,
                fontSize: '14px',
                fontWeight: isActive ? 600 : 500,
                lineHeight: '34px',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: isActive ? '#919eab1F' : 'none',
                color: isActive ? '#1c252e' : '#34475A',
                p: '0px 8px'
              }}
              sx={{
                my: 0
              }}
            />
          </ListItem>
        </List>
      </Paper>
    </Popover>
  )
}

export default NonSubmenuItems
