import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import { Box, Grid } from '@mui/material'
import 'rc-slider/assets/index.css'
import { ErrorBoundary } from 'react-error-boundary'
import AccountTitle from '../../components/AccountTitle'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import ErrorFallback from '../ErrorFallback'
import { RiskAttributes } from './RiskAttributes'
import SectorwiseRisk from './SectorwiseRisk'
import SecuritywiseRisk from './SecuritywiseRisk'

const RiskDashboard = () => {
  const { user } = useAuth()
  const { showError } = useErrorToast()

  const [riskAttributes, setRiskAttributes] = useState({})
  const [securityWiseAccounts, setSecurityWiseAccount] = useState([])
  const [sectorWiseAccounts, setSectorWiseAccount] = useState([])
  const [securityRiskLoading, setSecurityRiskLoading] = useState(true) // Security wise table
  const [sectorRiskLoading, setSectorRiskLoading] = useState(true) // Sector wise table
  const params = useParams()

  // const fetchRiskAttributes = async (userGroup, accountId) => {
  //   return await API.get(
  //     'baseURL',
  //     `account-master/v1/${userGroup}/accounts/${accountId}/risks/attributes`
  //   )
  // }

  // const fetchSecurityWiseRisk = async (userGroup, accountId) => {
  //   return await API.get(
  //     'baseURL',
  //     `account-master/v1/${userGroup}/accounts/${accountId}/securities/risk`
  //   )
  // }

  // const fetchSectorWiseRisk = async (userGroup, accountId) => {
  //   return await API.get(
  //     'baseURL',
  //     `account-master/v1/${userGroup}/accounts/${accountId}/asset-class/risks`
  //   )
  // }

  // const [riskAttributesQuery, securityWiseRiskQuery, sectorwiseRiskQuery] = useQueries({
  //   queries: [
  //     {
  //       queryKey: ['risk-attributes', params?.accountId],
  //       queryFn: () =>
  //         fetchRiskAttributes(user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup, params?.accountId),
  //       enabled: user !== null, // dependent condition
  //       retry: false // prevent retry if it fails
  //     },
  //     {
  //       queryKey: ['security-wise-risk', params?.accountId],
  //       queryFn: () =>
  //         fetchSecurityWiseRisk(user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup, params?.accountId),
  //       enabled: user !== null, // dependent condition
  //       retry: false // prevent retry if it fails
  //     },
  //     {
  //       queryKey: ['sector-wise-accounts', params?.accountId],
  //       queryFn: () =>
  //         fetchSectorWiseRisk(user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup, params?.accountId),
  //       enabled: user !== null, // dependent condition
  //       retry: false // prevent retry if it fails
  //     }
  //   ]
  // })

  // risk attributes
  // useEffect(() => {
  //   if (riskAttributesQuery?.status === 'success') {
  //     if (riskAttributesQuery?.data) {
  //       if (riskAttributesQuery?.data?.data) {
  //         setRiskAttributes(riskAttributesQuery?.data?.data)
  //       }
  //     }
  //   } else if (riskAttributesQuery?.status === 'error') {
  //     showError(riskAttributesQuery?.error, false, {}, 'Failed to load risk attributes data')
  //   }
  // }, [riskAttributesQuery?.isFetching])

  // // securitywise risk
  // useEffect(() => {
  //   setSecurityRiskLoading(securityWiseRiskQuery?.isFetching)
  //   if (securityWiseRiskQuery?.status === 'success') {
  //     if (securityWiseRiskQuery?.data) {
  //       if (securityWiseRiskQuery?.data?.data) {
  //         securityWiseRiskQuery?.data?.data?.forEach((item) => {
  //           if (item.localSymbol === '__USD') {
  //             item.localSymbol = 'CASH'
  //           }
  //         })
  //         setSecurityWiseAccount(securityWiseRiskQuery?.data?.data)
  //       }
  //     }
  //   } else if (securityWiseRiskQuery?.status === 'error') {
  //     showError(securityWiseRiskQuery?.error, false, {}, 'Failed to load security wise risk.')
  //   }
  // }, [securityWiseRiskQuery?.isFetching])

  // // sectorwise risk
  // useEffect(() => {
  //   setSectorRiskLoading(sectorwiseRiskQuery?.isFetching)
  //   if (sectorwiseRiskQuery?.status === 'success') {
  //     if (sectorwiseRiskQuery?.data) {
  //       if (sectorwiseRiskQuery?.data?.data) {
  //         setSectorWiseAccount(sectorwiseRiskQuery?.data?.data)
  //       }
  //     }
  //   } else if (sectorwiseRiskQuery?.status === 'error') {
  //     showError(sectorwiseRiskQuery?.error, false, {}, 'Failed to load sector wise data')
  //   }
  // }, [sectorwiseRiskQuery?.isFetching])

  useEffect(() => {
    if (user) {
      getRiskAttributes()
      getSectorWiseAccountsList()
      getSecurityWiseAccountsList()
    }
  }, [user, params?.accountId])

  const getRiskAttributes = async () => {
    try {
      const response = await API.get(
        'baseURL',
        `account-master/v1/${user.userGroup}/accounts/${params?.accountId}/risks/attributes`,
        { queryStringParameters: {} }
      )
      if (response?.data) {
        setRiskAttributes(response?.data)
        // setValue(Number(response?.data?.accountRiskAttributes[0]?.statsValue).toFixed(2))
      }
    } catch (error) {
      showError(error, false, {}, 'Failed to load risk attributes.')
    }
  }

  // accountRiskAttributes Data
  const riskAttri = {}
  if (riskAttributes?.accountRiskAttributes) {
    riskAttributes?.accountRiskAttributes?.forEach((obj) => {
      riskAttri[obj.statsCode] = obj.statsValue
    })
  }

  // strategyRiskAttributes Data
  const riskStrAttri = {}
  if (riskAttributes?.strategyRiskAttributes) {
    riskAttributes?.strategyRiskAttributes?.forEach((obj) => {
      riskStrAttri[obj.statsCode] = obj.statsValue
    })
  }

  const getSecurityWiseAccountsList = async () => {
    setSecurityRiskLoading(true)
    try {
      const response = await API.get(
        'baseURL',
        `account-master/v1/${user.userGroup}/accounts/${params?.accountId}/securities/risk`,
        { queryStringParameters: {} }
      )
      if (response?.data) {
        response?.data?.forEach((item) => {
          if (item.localSymbol === '__USD') {
            item.localSymbol = 'CASH'
          }
        })
        setSecurityWiseAccount(response?.data)
      }
    } catch (error) {
      showError(error, false, {}, 'Failed to load risk by security data.')
    } finally {
      setSecurityRiskLoading(false)
    }
  }

  const getSectorWiseAccountsList = async () => {
    setSectorRiskLoading(true)
    try {
      const response = await API.get(
        'baseURL',
        `account-master/v1/${user.userGroup}/accounts/${params?.accountId}/asset-class/risks`,
        { queryStringParameters: {} }
      )
      if (response?.data) {
        setSectorWiseAccount(response?.data)
      }
    } catch (error) {
      showError(error, false, {}, 'Failed to load risk by sector data.')
    } finally {
      setSectorRiskLoading(false)
    }
  }

  const screen = 'not main'

  return (
    <Box className='risk-page'>
      <ErrorBoundary
        fallbackRender={(props) => <ErrorFallback {...props} screen={screen} />}
      >
        <AccountTitle title='Risk' >
          {
            riskAttributes?.lastUpdatedTime?.updateTime
              ? (
                <div className='text-sm float-end'>
                  Data Date:{' '}
                  {riskAttributes?.lastUpdatedTime?.updateTime ? riskAttributes?.lastUpdatedTime?.updateTime?.split(' ')[0] : '-'}
                </div>
                )
              : (
                <></>
                )
              }
        </AccountTitle>
      </ErrorBoundary>
      <ErrorBoundary
        fallbackRender={(props) => <ErrorFallback {...props} screen={screen} />}
      >
        <RiskAttributes riskAttri={riskAttri} riskStrAttri={riskStrAttri} />
      </ErrorBoundary>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7}>
          <SecuritywiseRisk loading={securityRiskLoading} securityWiseAccounts={securityWiseAccounts} screen={screen} />
        </Grid>
        <Grid item xs={12} lg={5}>
          <SectorwiseRisk loading={sectorRiskLoading} sectorWiseAccounts={sectorWiseAccounts} screen={screen} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default RiskDashboard
