import { useContext, useState } from 'react'
import { Box, Fade, IconButton, MenuItem, Select, Skeleton, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { TbInfoSquare } from 'react-icons/tb'
import { HtmlTooltip } from '../../../../components/CustomTooltip'
import SummaryAccountsTable from '../OriginalSummaryTab/SummaryAccountsTable'
import { Card, MainTabs, TabPanel } from '../components'
import BarChart from './BarChart'
import SectorTable from './SectorTable'
import { ScenarioContext, SolutionContext, SummaryContext, UmaContext } from '..'

const RenderTableSkeleton = () => {
  return (
    <Table>
      <TableBody>
        {Array.from({ length: 5 }).map((_, index) => (
          <TableRow key={index}>
            {Array.from({ length: 4 }).map((_, index) => (
              <TableCell key={index}>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
              </TableCell>))}
          </TableRow>))}
      </TableBody>
    </Table>
  )
}

export const gradientColors = [
  { startColor: '#3F856CD3', endColor: '#8BC05833', color: '#3F856C' },
  { startColor: '#08367261', endColor: '#739ACB05', color: '#4A7EC1' },
  { startColor: '#4A81BA99', endColor: '#BFD3EB05', color: '#0D3B76' },
  { startColor: '#E6F1FE', endColor: '#CCE4FF', color: '#9AC8EB' }
]

const Consideration = ({ isSolutionScreen = false, umaType, aggId }) => {
  const tabsList = isSolutionScreen || umaType === 'SCENARIO_TRANSITION'
    ? [{ id: 'market-cap', label: 'Market-Cap' }, { id: 'factors', label: 'Factors' }, { id: 'sectors', label: 'Sectors' }]
    : [{ id: 'accounts', label: 'Accounts' }, { id: 'sectors', label: 'Sectors' }, { id: 'factors', label: 'Factors' }]
  const [selectedTab, setSelectedTab] = useState(tabsList[0].id)
  const scenarioData = useContext(ScenarioContext)
  const solutionData = useContext(SolutionContext)
  const summaryData = useContext(SummaryContext)
  const umaContextData = useContext(UmaContext)
  const [selectedScenario, setSelectedScenario] = useState(umaContextData?.umaDetailsData?.data?.scenarioUMA?.[0]?.aggId || '')

  const handleScenarioChange = (e) => {
    setSelectedScenario(e.target.value)
    if (!solutionData?.solutionMarketCapAndFactor[e.target.value]) {
      solutionData?.fetchMarketCapFactorData(e.target.value, true)
    }
    if (!solutionData?.solutionSector[e.target.value]) {
      solutionData?.fetchSectorData(e.target.value, true)
    }
  }

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue)
  }

  const marketCapAndFactorData = isSolutionScreen
    ? solutionData?.solutionMarketCapAndFactor[selectedScenario]?.data
    : umaType === 'TRANSITION'
      ? summaryData?.summaryMarketCapAndFactorData
      : scenarioData?.scenarioMarketCapAndFactorData[aggId]?.data

  const sectorDataObject = isSolutionScreen
    ? solutionData?.solutionSector[selectedScenario]?.data
    : umaType === 'TRANSITION'
      ? summaryData?.summarySectorData
      : scenarioData?.scenarioSectorData[aggId]?.data

  const legends = (marketCapAndFactorData?.marketCapData || []).map(obj => obj.label)

  const factorData = {
    labels: marketCapAndFactorData?.factorLabels || [],
    datasets: marketCapAndFactorData?.factorData || []
  }

  const marketCapData = {
    labels: marketCapAndFactorData?.marketCapLabels || [],
    datasets: marketCapAndFactorData?.marketCapData || []
  }

  const scenarioList = umaContextData?.umaDetailsData?.data?.scenarioUMA || []

  const renderTabPanel = (item) => {
    switch (item) {
      case 'market-cap':
        return (
          isSolutionScreen && solutionData?.solutionMarketCapAndFactor[selectedScenario]?.loading ? (
            <Skeleton variant='rectangular' height={480} width='100%' sx={{ mt: '20px' }} />
          ) : umaType === 'TRANSITION' && summaryData?.isSummaryMarketCapAndFactorLoading ? (
            <Skeleton variant='rectangular' height={480} width='100%' sx={{ mt: '20px' }} />
          ) : scenarioData?.scenarioMarketCapAndFactorData?.[aggId]?.loading ? (
            <Skeleton variant='rectangular' height={500} width='100%' sx={{ mt: '20px' }} />
          ) : (
            <Box sx={{ height: '500px' }}>
              <BarChart data={marketCapData} chartType='market-cap' />
            </Box>
          )
        )
      case 'factors':
        return (
          isSolutionScreen && solutionData?.solutionMarketCapAndFactor[selectedScenario]?.loading ? (
            <Skeleton variant='rectangular' height={480} width='100%' sx={{ mt: '20px' }} />
          ) : umaType === 'TRANSITION' && summaryData?.isSummaryMarketCapAndFactorLoading ? (
            <Skeleton variant='rectangular' height={480} width='100%' sx={{ mt: '20px' }} />
          ) : scenarioData?.scenarioMarketCapAndFactorData?.[aggId]?.loading ? (
            <Skeleton variant='rectangular' height={500} width='100%' sx={{ mt: '20px' }} />
          ) : (
            <Box sx={{ height: '500px' }}>
              <BarChart data={factorData} chartType='market-cap' />
            </Box>
          )
        )
      case 'sectors':
        return (
          isSolutionScreen && solutionData?.solutionSector[selectedScenario]?.loading ? (
            <RenderTableSkeleton />
          ) : umaType === 'TRANSITION' && summaryData?.isSummarySectorDataLoading ? (
            <RenderTableSkeleton />
          ) : scenarioData?.scenarioSectorData?.[aggId]?.loading ? (
            <RenderTableSkeleton />
          ) : (
            <SectorTable
              rows={sectorDataObject?.sectorData || []}
              columns={sectorDataObject?.sectorColumns || []}
              minMaxPerColumn={sectorDataObject?.minMaxPerColumn || {}}
            />
          )
        )
      case 'accounts':
        return <SummaryAccountsTable />
      default:
        return ''
    }
  }

  const handleOpenScenarioMenu = () => {
    document.body.classList.add('model-open')
  }

  const handleCloseScenarioMenu = () => {
    document.body.classList.remove('model-open')
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center', my: '12px' }}>
          <Typography sx={{ fontWeight: 600, fontSize: '16px', color: '#2f2f2f' }}>{umaType === 'SCENARIO_TRANSITION' ? 'Consideration' : 'Current Consideration'}</Typography>
          <HtmlTooltip
            title={umaType === 'SCENARIO_TRANSITION' ? 'Consideration' : 'Current Consideration'}
            tooltipStyle={{ backgroundColor: '#000', color: '#fff', fontSize: '11px', align: 'center' }}
          >
            <IconButton size='small'>
              <TbInfoSquare style={{ height: '20px', width: '20px', color: '#2F2F2F' }} />
            </IconButton>
          </HtmlTooltip>
        </Box>
        {
          isSolutionScreen ? (
            <Select
              value={selectedScenario}
              onOpen={handleOpenScenarioMenu}
              onClose={handleCloseScenarioMenu}
              MenuProps={{
                TransitionComponent: Fade,
              }}
              onChange={handleScenarioChange}
              IconComponent={ExpandMoreIcon}
              sx={{
                '.MuiSelect-select': {
                  width: '120px',
                  padding: '5px 10px',
                  borderRadius: '8px',
                  fontSize: '12px',
                  fontWeight: 600
                }
              }}
            >
              {
                scenarioList?.map((scenario, index) => (
                  <MenuItem key={index} value={scenario.aggId}
                    sx={{
                      fontSize: '12px',
                      py: '4px',
                      fontWeight: 600
                    }}
                  >{scenario.aggName}</MenuItem>
                ))
              }
            </Select>
          ) : ''
        }
      </Box>
      <Card>
        <Box sx={{
          display: 'flex',
          alignItems: {
            xs: 'start',
            md: 'center'
          },
          justifyContent: {
            xs: 'start',
            md: 'space-between'
          },
          flexDirection: {
            xs: 'column',
            md: 'row'
          },
          gap: '10px'
        }}>
          <MainTabs
            tabsList={tabsList}
            selectedTab={selectedTab}
            handleTabChange={handleTabChange}
            size='small'
          />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {
              (isSolutionScreen || umaType === 'SCENARIO_TRANSITION') && selectedTab === 'sectors'
                ? (
                  <Box sx={{ display: 'flex', justifyContent: { xs: 'end' }, width: { xs: '100%', md: 'inherit' } }}>
                    <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
                      <Typography sx={{ fontSize: '12px' }}>Deviation From Benchmark</Typography>
                      <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>High</Typography>
                        <Box sx={{ backgroundColor: '#F7E9EF', width: '14px', height: '14px', borderRadius: '4px' }} />
                        <Box sx={{ backgroundColor: '#FFFFFF', width: '14px', height: '14px', borderRadius: '4px', border: '1px solid #F0F0F0' }} />
                        <Box sx={{ backgroundColor: '#FBEBC2', width: '14px', height: '14px', borderRadius: '4px' }} />
                        <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>Low</Typography>
                      </Box>
                    </Box>
                  </Box>
                )
                : (
                  selectedTab === 'market-cap' || selectedTab === 'factors'
                    ? (
                      <Box sx={{
                        display: 'flex', alignItems: 'center', gap: '24px', justifyContent: 'center',
                        width: { sm: '100%', md: 'inherit' }, flexWrap: { xs: 'wrap', md: 'nowrap' }
                      }}>
                        {legends.map((legend, index) => (
                          <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Box sx={{
                              width: '16px',
                              height: '16px',
                              background: gradientColors[index]?.color,
                              // `linear-gradient(180deg, ${gradientColors[index].startColor} 0%, ${gradientColors[index].endColor} 100%)`,
                              borderRadius: '3px'
                            }}
                            />
                            <Typography sx={{ fontWeight: 600, fontSize: '12px' }}>{legend}</Typography>
                          </Box>
                        ))}
                      </Box>
                    )
                    : ''
                )
            }
          </Box>
        </Box>
        <TabPanel
          selectedTab={selectedTab}
          tabList={tabsList}
          getTabContent={renderTabPanel}
        />
      </Card>
    </>
  )
}

export default Consideration
