import StyledTradePopupBox from '../../components/StyledTradePopupBox'
import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

const TradeAdhocLoader = ({ tradeListHeaders }) => {
  const tableHeaderHTML = tradeListHeaders.map(({ headerName }, index) => <TableCell key={index}>{headerName}</TableCell>)
  const rowSkeleton = tradeListHeaders.map(({ headerName }, index) => <TableCell key={index}><Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} /></TableCell>)

  return (
    <StyledTradePopupBox className='table-responsive' sx={{ height: 'calc(100vh - 16px - 16px - 45px - 39px - 53px)' }}>
      <Table>
        <TableHead>
          <TableRow
            style={{ position: 'sticky', top: '0', background: 'white' }}
          >
            {tableHeaderHTML}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>{rowSkeleton}</TableRow>
          <TableRow>{rowSkeleton}</TableRow>
        </TableBody>
      </Table>
    </StyledTradePopupBox>
  )
}

export default TradeAdhocLoader