import { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { MenuItem } from '@mui/material'

const CustomStrategyField = (props) => {
  const { strategies, params, error, loading, disabled } = props
  const [options, setOptions] = useState(strategies || [])

  const handleStrategyChange = (event, newValue) => {
    params?.api.setEditCellValue({ id: params?.id, field: params?.field, value: newValue, strategyId: params?.row?.id })
    params?.api?.updateRows([{ id: params?.id, strategyError: !newValue }])
  }

  const handleInputChange = (event, newInputValue) => {
    if (newInputValue?.trim()?.length >= 3) {
      setOptions(strategies)
    }
  }

  useEffect(() => {
    if (strategies) {
      setOptions(strategies)
    }
  }, [strategies])

  return (
    <Autocomplete
      value={params?.value || null}
      loading={loading}
      onChange={handleStrategyChange}
      onInputChange={handleInputChange}
      loadingText='Loading...'
      size='small'
      disabled={disabled}
      noOptionsText='No strategies found'
      options={options}
      getOptionLabel={(option) => (`${option?.strategyName || ''}`)}
      renderOption={(props, option) => (
        <MenuItem {...props} sx={{ whiteSpace: 'normal' }} key={option?.strategyId}>
          {option?.strategyName}
        </MenuItem>
      )}
      isOptionEqualToValue={(option, value) => (option?.strategyId === value?.strategyId)}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Strategy'
          InputLabelProps={{ shrink: true }}
          error={error}
        />
      )}
      style={params?.style || {}}
      sx={{ width: '100%', mr: 1 }}
    />
  )
}

export default CustomStrategyField
