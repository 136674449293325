import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, IconButton, Modal, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridClasses, gridFilteredSortedRowEntriesSelector } from '@mui/x-data-grid-pro'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import { moduleConfig } from '../../../contexts/data'
import StyledTradePopupBox from '../components/StyledTradePopupBox'
import { tradeModalStyle } from './TradeApprovalIconCells/tradeModalStyle'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { createHierarchy } from '../../../utils/groupData'

const TradeBlotterPopUp = (props) => {
  const { open, setOpen, apiRef } = props
  const { user, checkAccess } = useAuth()
  const [tradeBlotterDataLoading, setTradeBlotterDataLoading] = useState(true)
  const { showError } = useErrorToast()
  const [tradeBlotterData, setTradeBlotterData] = useState([])
  const [uniqueAccountsCnt, setUniqueAccountsCnt] = useState(0)

  useEffect(() => {
    if (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.VIEW_TRADE_BLOTTER })) {
      if (apiRef.current) {
        const model = gridFilteredSortedRowEntriesSelector(apiRef)?.filter(row => row.model?.accOptMapId)
        setUniqueAccountsCnt(new Set(model.map(row => row.model?.accountId)).size)
        getTradeBlotterData(model.map(row => row.model?.accOptMapId))
      }
    }
  }, [apiRef])

  const tradeBlotterColumns = [
    {
      field: 'securityName',
      headerName: 'Security Name',
      flex: 1
    },
    {
      field: 'localSymbol',
      headerName: 'Local Symbol',
      flex: 1
    },
    {
      field: 'buyAccounts',
      headerName: 'Buy Accounts',
      flex: 1,
      type: 'number'
    },
    {
      field: 'sellAccounts',
      headerName: 'Sell Accounts',
      flex: 1,
      type: 'number'
    },
    {
      field: 'totalAccounts',
      headerName: 'Total Accounts',
      flex: 1,
      type: 'number',
      renderCell: (props) => props?.row?.totalAccounts !== undefined && props?.row?.totalAccounts !== null && uniqueAccountsCnt
        ? `${props?.row?.totalAccounts}/${uniqueAccountsCnt}`
        : '0/0',
      align: 'right',
      headerAlign: 'right'
    }
  ]

  const groupDataByLocalSymbol = (data) => {
    const hierarchy = createHierarchy(data, 0, ['instrId'])

    return Object.keys(hierarchy).map(key => {
      const buyAccounts = hierarchy[key].find(trade => trade?.side === 'BUY')?.noOfAccountsInTrade ?? 0,
        sellAccounts = hierarchy[key].find(trade => trade?.side === 'SELL')?.noOfAccountsInTrade ?? 0
      return {
        id: randomId(),
        securityName: hierarchy[key][0].securityName,
        localSymbol: hierarchy[key][0].localSymbol,
        buyAccounts: buyAccounts,
        sellAccounts: sellAccounts,
        totalAccounts: buyAccounts + sellAccounts
      }
    })
  }

  const getTradeBlotterData = (accOptMapIds) => {
    API.post('baseUriTrade', `trade/v1/${user?.userGroup}/trade-approvals/blotter`,
      {
        queryStringParameters: {
          resources: encodeURIComponent(JSON.stringify({ serviceId: 'trade', resourceId: 'trade-approvals' }))
        },
        body: { accountOptMapIds: accOptMapIds }
      })
      .then((res) => {
        setTradeBlotterData(groupDataByLocalSymbol(res?.data || []))
      }).catch((error) => {
        setTradeBlotterDataLoading(false)
        showError(error, false, {}, 'Failed to load trade blotter data.')
      })
      .finally(() => setTradeBlotterDataLoading(false))
  }

  const handleCloseTradeErrorModal = () => {
    setOpen(false)
  }

  const renderTableSkeleton = (header) => {
    return (
      <TableContainer mt={5}>
        <Table className='risk-page-table'>
          <TableHead>
            <TableRow>
              {header.map((item, index) => {
                return (
                  <TableCell key={index}>{item.headerName}</TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({ length: 5 }).map((_, index) => (
              <TableRow key={index}>
                {Array.from({ length: header.length }).map((_, index) => (
                  <TableCell key={index}>
                    <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                  </TableCell>))}
              </TableRow>))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const downloadCSVData = () => {
    const headers = ['Security Name', 'Local Symbol', 'Buy Accounts', 'Sell Accounts', 'Total Accounts']
    const dataCsv = [headers.join(',')]
    tradeBlotterData.forEach(obj => {
      const { securityName, localSymbol, buyAccounts, sellAccounts, totalAccounts } = obj
      dataCsv.push([securityName || '', localSymbol || '', buyAccounts || 0, sellAccounts || 0, `" ${totalAccounts ?? 0}/${uniqueAccountsCnt ?? 0}"`].join(','))
    })

    const csvBlob = new Blob([dataCsv.join('\n')], { type: 'text/csv' })
    const csvUrl = URL.createObjectURL(csvBlob)

    const tempLink = document.createElement('a')
    tempLink.href = csvUrl
    tempLink.setAttribute('download', 'trade-blotter.csv')
    tempLink.click()

    // Clean up the URL object
    URL.revokeObjectURL(csvUrl)
  }

  return (
    <Modal
      open={open}
      onClose={handleCloseTradeErrorModal}
    >
      <Box
        sx={{ ...tradeModalStyle, maxWidth: '900px', py: 2 }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant='h6' component='h2'>
            Trade Blotter
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {
              tradeBlotterData.length ? (
                <Button
                  sx={{ textTransform: 'none' }}
                  onClick={(e) => downloadCSVData()}
                >
                  Export CSV
                </Button>
              ) : ''
            }
            <IconButton aria-label='close' onClick={handleCloseTradeErrorModal}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <StyledTradePopupBox
          sx={{
            height: '650px',
            maxHeight: '700px',
            overflow: 'auto',
            scrollbarGutter: 'stable'
          }}
        >
          {tradeBlotterDataLoading
            ? (
              renderTableSkeleton(tradeBlotterColumns)
            )
            : (
              <DataGridPro
                autoHeight
                rows={tradeBlotterData}
                columns={tradeBlotterColumns}
                density='compact'
                pagination
                pageSizeOptions={[15]}
                disableRowSelectionOnClick
                initialState={{
                  ...tradeBlotterData?.initialState,
                  pagination: { paginationModel: { pageSize: 15 } }
                }}
                sx={(theme) => ({
                  [`.${gridClasses.main}`]: {
                    overflow: 'unset'
                  },
                  [`.${gridClasses.columnHeaders}`]: {
                    position: 'sticky',
                    top: -1,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1
                  },
                  [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                    color: '#74788d',
                    fontWeight: 600
                  },
                  [`.${gridClasses.footerContainer}`]: {
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1
                  },
                  [`.${gridClasses.toolbarContainer}`]: {
                    position: 'sticky',
                    top: 0,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1
                  }
                })}
              />
            )}
        </StyledTradePopupBox>
      </Box>
    </Modal>

  )
}

export default TradeBlotterPopUp
