import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Box, Collapse, Fade, List, ListItem, ListItemIcon, ListItemText, Paper, Popover } from '@mui/material'
import 'rc-slider/assets/index.css'
import { StyledChevronRightIcon, StyledExpandMoreIcon } from './components/ArrowButtons'
import { useAuth } from '../contexts/AuthContext'
import { useTheme } from '@mui/material/styles'
import { ACCESS_LEVEL } from '../contstants/constants'
import { hexToRgba } from '../utils/HexOperations'
import { useSelector } from 'react-redux'

const SubMenuItems = (props) => {
  const { id, menuId, paths, activePath, icon, name, isOpen, handleSubmenuToggle, isSidebarOpen, parentModule, leftSidebarProps, isPreview } = props
  const { user, checkAccess } = useAuth()
  const themeData = useSelector((state) => state.theme)
  const [anchorEl, setAnchorEl] = useState(null)
  const isActive = paths.some(path => activePath?.includes(path.path))

  const theme = useTheme()

  const [sidebarFullyClosed, setSidebarFullyClosed] = useState(false);

  useEffect(() => {
    if (!isSidebarOpen) {
      const timeout = setTimeout(() => setSidebarFullyClosed(true), 500)
      return () => clearTimeout(timeout)
    } else {
      setSidebarFullyClosed(false)
    }
  }, [isSidebarOpen])

  // set dynamic colors for leftside bar service name text
  const primaryText = user?.userGroup === 'adv-classic' && theme.palette.primaryPalette.contrastText ? theme.palette.primaryPalette.contrastText : user?.userGroup === 'admin' && leftSidebarProps ? leftSidebarProps?.brandTextColor : 'white'
  const secondaryText = user?.userGroup === 'adv-classic' && theme.palette.paletteTextColor.contrastText
    ? hexToRgba(theme.palette.paletteTextColor.contrastText, themeData?.opacity)
    : user?.userGroup === 'admin' && leftSidebarProps ? hexToRgba(leftSidebarProps?.brandTextColor, leftSidebarProps.opacity) : 'rgba(255, 255, 255, 0.5)'

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handlePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget)
  }

  return (
    <>
      <ListItem
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className='menu-item'
        component='div'
        onClick={(e) => handleSubmenuToggle(e)}
        id={id}
      >
        <ListItemIcon id={id + '-icon'} sx={{ color: isActive ? primaryText : secondaryText }}>
          {
            icon.type === 'svg'
              ? <icon.inactive sx={{ width: '20px', height: '20px', color: isActive ? primaryText : secondaryText }} />
              : icon.type === 'component'
                ? <icon.component color={isActive ? primaryText : secondaryText} size='18px' />
                : <img
                  src={isActive ? icon.active : icon.inactive}
                  alt='menu-icon'
                  className='inline-block'
                  style={{ width: '18px', height: '18px' }}
                />
          }
        </ListItemIcon>
        <ListItemText
          className='menu-link'
          sx={{ color: paths.some(path => activePath?.includes(path.path)) ? primaryText : secondaryText }}
          primary={name}
        />
        {
          isOpen
            ? <StyledExpandMoreIcon className='arrow-icon' style={{ color: paths.some(path => activePath?.includes(path.path)) ? primaryText : secondaryText }} />
            : <StyledChevronRightIcon style={{ color: paths.some(path => activePath?.includes(path.path)) ? primaryText : secondaryText }} />
        }
        {
          (!isSidebarOpen && sidebarFullyClosed && anchorEl) ? (
            <SubMenuPopover
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              name={name}
              paths={paths}
              activePath={activePath}
              parentModule={parentModule}
              isPreview={isPreview}
            />
          ) : ''
        }
      </ListItem>
      <Collapse in={isOpen}>
        <List
          className='sub-menu'
          id={menuId}
          disablePadding
          sx={
            isPreview ? { pointerEvents: 'none'} : {}
          }
        >
          <>
            {paths?.map((path, index) => (
              (user?.userGroup !== 'admin')
                ? (
                  checkAccess(parentModule, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: path.moduleCd }) &&
                  <ListItem
                    key={index}
                    className='menu-item'
                    component={NavLink}
                    to={path.path}
                    id={path.name}
                  >
                    <ListItemText primary={path.name} sx={{ color: path.path.split('/').every(p => activePath.split('/').includes(p)) ? primaryText : secondaryText }} className='menu-link' />
                  </ListItem>
                )
                : (
                  <ListItem
                    key={index}
                    className='menu-item'
                    component={NavLink}
                    to={path.path}
                    id={path.name}
                    sx={{
                    }}
                  >
                    <ListItemText primary={path.name} sx={{ color: path.path.split('/').every(p => activePath.split('/').includes(p)) ? primaryText : secondaryText }} className='menu-link' />
                  </ListItem>
                )
            ))}
          </>
        </List>
      </Collapse>
    </>
  )
}

const SubMenuPopover = (props) => {
  const {
    name,
    paths,
    parentModule,
    anchorEl,
    onClose,
    activePath,
    isPreview
  } = props
  const { user, checkAccess } = useAuth()

  return (
    <Popover
      open={Boolean(anchorEl)}
      disableScrollLock
      onClose={onClose}
      anchorEl={anchorEl}
      TransitionComponent={Fade}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      sx={{
        pointerEvents: 'none',
        minWidth: '180px',
        p: '4px'
      }}
      slotProps={{
        paper: {
          sx: {
            borderRadius: '8px',
          }
        }
      }}
    >
      <Paper
        elevation={0}
        sx={{
          minWidth: '180px',
          backgroundColor: 'white',
          padding: '4px',
          pointerEvents: 'all'
        }}>
        <List
          className='sub-menu'
          disablePadding
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            ...(isPreview ? { pointerEvents: 'none' } : {})
          }}
        >
          <>
            <ListItem
              onClick={(e) => e.stopPropagation()}
              sx={{
                p: 0,
                m: 0,
                borderRadius: '8px',
                backgroundColor: 'transparent',
              }}
              >
              <ListItemText
                primary={name}
                primaryTypographyProps={{
                  borderBottom: '1px solid #eaeaea',
                  margin: 0,
                  fontSize: '14px',
                  fontWeight: 'bold',
                  lineHeight: '34px',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#34475A',
                  p: '0px 8px'
                }}
                sx={{
                  cursor: 'default',
                  my: 0
                }}
              />
            </ListItem>
            {paths?.map((path, index) => {
              const isActive = path.path.split('/').every(p => activePath.split('/').includes(p))
              return (
                (user?.userGroup !== 'admin')
                  ? (
                    checkAccess(parentModule, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: path.moduleCd }) &&
                    <ListItem
                      key={index}
                      onClick={(e) => e.stopPropagation()}
                      component={NavLink}
                      to={path.path}
                      id={path.name}
                      sx={{
                        p: 0,
                        m: 0,
                        borderRadius: '8px',
                        '&:hover': {
                          backgroundColor: '#919eab1F'
                        }
                      }}
                    >
                      <ListItemText primary={path.name}
                        primaryTypographyProps={{
                          fontSize: '14px',
                          borderRadius: '8px',
                          fontWeight: isActive ? 600 : 500,
                          lineHeight: '34px',
                          display: 'flex',
                          alignItems: 'center',
                          backgroundColor: isActive ? '#919eab1F' : 'none',
                          color: isActive ? '#1c252e' : '#34475A',
                          p: '0px 8px'
                        }}
                        sx={{
                          my: 0
                        }}
                      />
                    </ListItem>
                  )
                  : (
                    <ListItem
                      key={index}
                      component={NavLink}
                      to={path.path}
                      id={path.name}
                      sx={{
                        p: 0,
                        m: 0,
                        borderRadius: '8px',
                        '&:hover': {
                          backgroundColor: '#919eab1F'
                        }
                      }}
                    >
                      <ListItemText primary={path.name}
                        primaryTypographyProps={{
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '34px',
                          borderRadius: '8px',
                          display: 'flex',
                          alignItems: 'center',
                          color: '#34475A',
                          p: '0px 8px'
                        }}
                        sx={{
                          my: 0
                        }}
                      />
                    </ListItem>
                  )
              )
            })}
          </>
        </List>
      </Paper>
    </Popover>
  )
}

export default SubMenuItems
