import { memo } from 'react'
import { Box, Divider, styled, Tooltip, Typography } from '@mui/material'
import { Handle, Position } from '@xyflow/react'
import { formatCurrencyWithSymbol } from '../../../../utils/FormateCurrenyInMilion'

export default memo(({ data, isConnectable }) => {
  const nameParts = data?.accountName?.split(' ')
  const nameInitials = (nameParts[0][0] + (nameParts.length > 1 ? nameParts[1][0] : '')).toUpperCase()
  return (
    <>
      {
        data?.type === 'TRANSITION' ? (
          <Handle
            type='source'
            position={Position.Right}
            isConnectable={isConnectable}
          />
        ) : data?.type === 'SCENARIO_TRANSITION' ? (
          <Handle
            type='target'
            position={Position.Left}
            isConnectable={isConnectable}
          />
        ) : (
          <>
            <Handle
              type='source'
              position={Position.Right}
              isConnectable={isConnectable}
            />
            <Handle
              type='target'
              position={Position.Left}
              isConnectable={isConnectable}
            />
          </>
        )
      }
      <Box sx={{ fontFamily: 'Open Sans', height: '100%' }}>
        <NodeBox strategy={data?.strategyType} nodetype={data?.type}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Box sx={{ width: '45px', height: '45px', borderRadius: '5px', backgroundColor: '#FBFBFD', padding: '7px' }}>
              <Typography sx={{ fontWeight: 600, fontSize: '20px', color: '#75A0D2' }}>{nameInitials}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Tooltip title={data?.accountName}>
                <Typography sx={{ fontSize: '16px', color: '#002a59', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '180px' }}>{data?.accountName}</Typography>
              </Tooltip>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600, fontSize: '16px', color: '#002a59' }}>{formatCurrencyWithSymbol(data?.portMv, 2, '$')}</Typography>
                <VerticalDivider flexItem orientation='vertical' />
                {
                  data?.type === 'SCENARIO_TRANSITION' ? (
                    <Typography sx={{ fontWeight: 600, fontSize: '16px', color: data?.totalUrgl >= 0 ? '#3BBFA3' : '#FF6161' }}>({(data?.totalUrgl >= 0 ? '+' : '') + formatCurrencyWithSymbol(data?.totalUrgl, 0, '$')})</Typography>
                  ) : (
                    <Typography sx={{ fontWeight: 600, fontSize: '16px', color: data?.totalInitUrgl >= 0 ? '#3BBFA3' : '#FF6161' }}>({(data?.totalInitUrgl >= 0 ? '+' : '') + formatCurrencyWithSymbol(data?.totalInitUrgl, 0, '$')})</Typography>
                  )
                }
              </Box>
            </Box>
          </Box>
          <Box sx={{
            display: 'flex',
            backgroundColor: '#E6ECF333',
            border: '0.5px solid #E6ECF3',
            width: '100%',
            paddingBlock: '5px',
            justifyContent: 'space-around',
            mt: '10px'
          }}>
            {
              <Typography sx={{ fontSize: '14px', color: '#002a59' }}>
                {data?.type === 'TRANSITION' ? 'Sell: ' : 'Buy: '}
                <span style={{ fontWeight: 600 }}>
                  {data?.type === 'TRANSITION' ? data?.sellShares : data?.buyShares}
                </span>
              </Typography>
            }
            <Divider flexItem orientation='vertical' />
            <Typography sx={{ fontSize: '14px', color: '#002a59' }}>Transfer: <span style={{ fontWeight: 600 }}>{data?.transferredShares}</span></Typography>
          </Box>
        </NodeBox>
      </Box>
    </>
  )
})

const NodeBox = styled(Box)(({ strategy, nodetype }) => ({
  borderRadius: '8px',
  padding: '5px',
  ...(
    nodetype === 'TRANSITION' ? {
      // borderLeft: `2px solid ${strategy === 'ACTIVE' ? '#CFD53A' : '#4575AC'}`
      borderLeft: `2px solid #4575AC`
    } : {
      borderRight: `2px solid ${strategy === 'ACTIVE' ? '#CFD53A' : '#4575AC'}`
    }
  ),
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  background: strategy === 'ACTIVE' && nodetype !== 'TRANSITION'
    ? `linear-gradient(${nodetype === 'TRANSITION' ? '-90deg' : '90deg'}, #FFFFFF 62.01%, rgba(234, 235, 207, 0.4) 100%)`
    : `linear-gradient(${nodetype === 'TRANSITION' ? '-90deg' : '90deg'}, #FFFFFF 55.71%, rgba(214, 223, 236, 0.4) 100%)`
}))

const VerticalDivider = styled(Divider)({
  background: 'linear-gradient( #FFFFFF, #80A8D6, #FFFFFF)',
  marginInline: '13px',
  width: '2px',
  borderColor: 'transparent'
})