import React, { useContext, useMemo } from 'react'
import { Box, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { SummaryContext } from '..'

const RenderTableSkeleton = ({ header, hiddenColumns = [] }) => {
  if (hiddenColumns) { header = header.filter(col => !hiddenColumns.includes(col.field)) }
  return (
    <TableContainer mt={5}>
      <Table className='risk-page-table'>
        <TableHead>
          <TableRow>
            {header.map((item, index) => {
              return (
                <TableCell key={index}>{item.headerName}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, index) => (
            <TableRow key={index}>
              {Array.from({ length: header.length }).map((_, index) => (
                <TableCell key={index}>
                  <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                </TableCell>))}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const currentUMACols = [
  {
    field: 'accountName',
    headerName: 'Name',
    flex: 1,
    headerAlign: 'left'
  },
  {
    field: 'weight',
    headerName: 'Weight (%)',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.row?.weight !== undefined && params?.row?.weight !== null ? params?.row?.weight?.toFixed(2) : 'NA',
    headerAlign: 'right'
  },
  {
    field: 'totalMarketValue',
    headerName: 'Market Value',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    renderCell: (params) => (
      params?.row?.totalMarketValue !== undefined
        ? params?.row?.totalMarketValue !== null
          ? params?.row?.totalMarketValue < 0
            ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
            : '$' + Number(params?.value)?.toLocaleString()
          : 'NA'
        : ''
    ),
    headerAlign: 'right'
  },
  {
    field: 'purchaseCost',
    headerName: 'Purchase Cost',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    renderCell: (params) => (
      params?.row?.purchaseCost !== undefined
        ? params?.row?.purchaseCost !== null
          ? params?.row?.purchaseCost < 0
            ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
            : '$' + Number(params?.value)?.toLocaleString()
          : 'NA'
        : ''
    ),
    headerAlign: 'right'
  },
  {
    field: 'shortTermUrg',
    headerName: 'Short Term Unrealized Gain',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    renderCell: (params) => (
      <span style={{ color: params?.row?.shortTermUrg < 0 ? '#A6333F' : '#33A68D' }}>
        {params?.row?.shortTermUrg !== undefined
          ? params?.row?.shortTermUrg !== null
            ? params?.row?.shortTermUrg < 0
              ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
              : '$' + Number(params?.value)?.toLocaleString()
            : 'NA'
          : ''}
      </span>
    ),
    headerAlign: 'right'
  },
  {
    field: 'shortTermUrl',
    headerName: 'Short Term Unrealized Loss',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    renderCell: (params) => (
      <span style={{ color: params?.row?.shortTermUrl < 0 ? '#A6333F' : '#33A68D' }}>
        {params?.row?.shortTermUrl !== undefined
          ? params?.row?.shortTermUrl !== null
            ? params?.row?.shortTermUrl < 0
              ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
              : '$' + Number(params?.value)?.toLocaleString()
            : 'NA'
          : ''}
      </span>
    ),
    headerAlign: 'right'
  },
  {
    field: 'longTermUrg',
    headerName: 'Long Term Unrealized Gain',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    renderCell: (params) => (
      <span style={{ color: params?.row?.longTermUrg < 0 ? '#A6333F' : '#33A68D' }}>
        {params?.row?.longTermUrg !== undefined
          ? params?.row?.longTermUrg !== null
            ? params?.row?.longTermUrg < 0
              ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
              : '$' + Number(params?.value)?.toLocaleString()
            : 'NA'
          : ''}
      </span>
    ),
    headerAlign: 'right'
  },
  {
    field: 'longTermUrl',
    headerName: 'Long Term Unrealized Loss',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    renderCell: (params) => (
      <span style={{ color: params?.row?.longTermUrl < 0 ? '#A6333F' : '#33A68D' }}>
        {params?.row?.longTermUrl !== undefined
          ? params?.row?.longTermUrl !== null
            ? params?.row?.longTermUrl < 0
              ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
              : '$' + Number(params?.value)?.toLocaleString()
            : 'NA'
          : ''}
      </span>
    ),
    headerAlign: 'right'
  }
]

const SummaryAccountsTable = () => {
  const summaryData = useContext(SummaryContext)

  const allocationData = (summaryData?.originalSummaryAllocation?.originalUMA?.allocationData || [])

  const currentUMAData = useMemo(() =>
    allocationData.map(data => ({
      ...data,
      id: randomId()
    })), [allocationData])

  const currentAggObj = currentUMAData.reduce((sumObj, obj) => ({
    weight: sumObj.weight + obj.weight,
    totalMarketValue: sumObj.totalMarketValue + obj.totalMarketValue,
    shortTermUrg: sumObj.shortTermUrg + obj.shortTermUrg,
    shortTermUrl: sumObj.shortTermUrl + obj.shortTermUrl,
    longTermUrg: sumObj.longTermUrg + obj.longTermUrg,
    longTermUrl: sumObj.longTermUrl + obj.longTermUrl,
    purchaseCost: sumObj.purchaseCost + obj.purchaseCost
  }), {
    totalMarketValue: 0,
    weight: 0,
    shortTermUrg: 0,
    shortTermUrl: 0,
    longTermUrg: 0,
    longTermUrl: 0,
    purchaseCost: 0
  })

  const totalRowCurrentUMA = {
    id: randomId(),
    accountName: 'Total',
    weight: currentAggObj.weight,
    totalMarketValue: currentAggObj.totalMarketValue,
    shortTermUrg: currentAggObj.shortTermUrg || 0,
    shortTermUrl: currentAggObj.shortTermUrl || 0,
    longTermUrg: currentAggObj.longTermUrg || 0,
    longTermUrl: currentAggObj.longTermUrl || 0,
    purchaseCost: currentAggObj.purchaseCost

  }

  return (
    <Box sx={{ mt: '20px' }}>
      {
        summaryData?.isSummaryAllocationLoading ? (
          <RenderTableSkeleton header={currentUMACols} />
        ) : (
          <DataGridPro
            autoHeight
            density='compact'
            rows={currentUMAData}
            pinnedRows={{ bottom: [totalRowCurrentUMA] }}
            columns={currentUMACols}
            getRowId={(row) => row.id}
            disableRowSelectionOnClick
            hideFooter
            localeText={{ noRowsLabel: 'No Portfolio Found' }}
            sx={{
              [`.${gridClasses.pinnedRows}`]: { boxShadow: 'none', borderBlock: '1px solid black', background: '#FBFBFD' }
            }}
          />
        )
      }
    </Box>
  )
}

export default SummaryAccountsTable
