import { useGridApiContext } from '@mui/x-data-grid-pro'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'

export default function CustomEditDateComponent (props) {
  const { id, value, field, hasFocus } = props
  const apiRef = useGridApiContext()
  const ref = useRef()
  const [selectedDateTime, setSelectedDateTime] = useState('')

  // Store the original value to revert to if the user clears the field.
  const originalValueRef = useRef(value)

  useEffect(() => {
    originalValueRef.current = value
    if (value) {
      const dateObject = new Date(value)
      if (!isNaN(dateObject.getTime())) {
        const day = ('0' + dateObject.getDate()).slice(-2)
        const month = ('0' + (dateObject.getMonth() + 1)).slice(-2)
        const year = dateObject.getFullYear()
        setSelectedDateTime(`${year}-${month}-${day}`)
      } else {
        setSelectedDateTime('')
      }
    } else {
      setSelectedDateTime('')
    }
  }, [value])

  useLayoutEffect(() => {
    if (hasFocus) {
      ref.current.focus()
    }
  }, [hasFocus])

  const handleValueChange = (event) => {
    const inputValue = event.target.value

    if (inputValue !== '') {
      setSelectedDateTime(inputValue)
      // Process the new date and update the grid cell.
      const newValue = inputValue + 'T00:00:00.000Z'
      const dateObject = new Date(newValue)
      const adjustedDate = new Date(
        dateObject.getTime() + dateObject.getTimezoneOffset() * 60 * 1000
      )
      apiRef.current.setEditCellValue({ id, field, value: adjustedDate })
    } else {
      // If the input is cleared, revert to the original date.
      if (originalValueRef.current) {
        const dateObject = new Date(originalValueRef.current)
        const day = ('0' + dateObject.getDate()).slice(-2)
        const month = ('0' + (dateObject.getMonth() + 1)).slice(-2)
        const year = dateObject.getFullYear()
        const formattedDate = `${year}-${month}-${day}`
        // Set the old date in state so the input field shows the original date.
        setSelectedDateTime(formattedDate)
      } else {
        setSelectedDateTime('')
      }
    }
  }

  return (
    <input
      ref={ref}
      type='date'
      value={selectedDateTime}
      onChange={handleValueChange}
      className='date-input'
    />
  )
}
