import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { BsBarChartLine } from 'react-icons/bs'
import CircleIcon from '@mui/icons-material/Circle'
import CloseIcon from '@mui/icons-material/Close'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tab,
  Tooltip,
  Typography
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridClasses, useGridApiRef } from '@mui/x-data-grid-pro'
import closeIcon from '../../../assets/images/close.svg'
import filterIcon from '../../../assets/images/filter.svg'
import showAllAccount from '../../../assets/images/icon-show-all-account.svg'
import PolicyStatusPopup from '../../../components/PolicyStatusPopup'
import { useAuth } from '../../../contexts/AuthContext'
import { moduleConfig } from '../../../contexts/data.js'
import { ACCESS_LEVEL } from '../../../contstants/constants.js'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import { enableLegacyViewReducer } from '../../../store/trade-reducer/trade-reducer.js'
import Loader from '../../Loader'
import { formatCurrencyWithSymbol } from '../../Report/components/dataProcess/DataProcess.js'
// import LivePriceButton from '../components/LivePriceButton'
import CashSwapPopup from '../components/CashSwapPopup.js'
import DownloadTransButton from './DownloadTransButton'
import './Trade.css'
import TradeApprovalFilterMenu from './TradeApprovalFilterMenu'
import TradeApprovalsHeaderMain from './TradeApprovalsHeaderMain'
import TradeBlotterPopUp from './TradeBlotterPopUp.js'
import { TradeErrorTable } from './TradeErrorTable.js'
import TradeLegacyViewTable from './TradeLegacyViewTable'
import DIAssistTrading from './DIAssistTrading'
import RerunPolicyAndOptimization from './RerunPolicyAndOptimization.js'

export let expansionLookup = {}

function getStatusColor (status) {
  if (status === 'green') { return '#3BBFA3' } else if (status === 'red') { return '#FF6161' } else if (status === 'yellow') { return '#D29922' } else if (status === 'gray') { return '#0000004d' }
  return '#c9c9c9'
}

const TradeApprovalsMain = () => {
  const apiRef = useGridApiRef()
  const groupingTableRef = useGridApiRef()
  const { user, checkAccess } = useAuth()
  const dispatch = useDispatch()
  const [optRuns, setOptRuns] = useState([])
  const [optDILinkRuns, setOptDILinkRuns] = useState([])
  const { showError } = useErrorToast()
  const [finalArray, setFinalArray] = useState([])
  const [tradeArray, setTradeArray] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [diAssistLoading, setDiAssistLoading] = useState(true)
  const [optAccountApprovals, setOptAccountApprovals] = useState([])
  const [optDILinkApprovals, setOptDILinkApprovals] = useState([])
  const [chartLabelData, setChartLabelData] = useState([])
  const { showSuccess } = useSuccessToast()
  const [showFilters, setShowFilters] = useState(false)
  const [delistData, setDelistData] = useState([])
  const [tradeDatagridState, setTradeDatagridState] = useState({})
  const [tradeDIDatagridState, setTradeDIDatagridState] = useState({})
  const [scenarioMappingWithLegend, setScenarioMappingWithLegend] = useState({
    balanced: 'Balanced',
    mintax: 'Min Tax',
    model: 'Model'
  })
  const [legendState, setLegendState] = useState({
    balanced: false,
    model: false,
    mintax: false
  })
  const [strategies, setStrategies] = useState([])
  const [tradeStatusFilters, setTradeStatusFilters] = useState([
    {
      id: 1,
      name: 'Failed Scenario',
      colorNumber: 99,
      selected: true
    },
    {
      id: 2,
      name: 'Success Scenario',
      colorNumber: 2,
      selected: true
    },
    {
      id: 3,
      name: 'Inprogress Scenario',
      colorNumber: 1,
      selected: true
    },
    {
      id: 4,
      name: 'Pending Scenario',
      colorNumber: 0,
      selected: true
    }
  ])

  const [allScenarioList, setAllScenarioList] = useState({})
  const location = useLocation()
  const navigate = useNavigate()
  const [selectedFilters, setSelectedFilters] = useState([])
  const [isDefaultChecked, setIsDefaultChecked] = useState(false)
  const [isApprovalDataChanged, setIsApprovalDataChanged] = useState(false)
  const [openTradeErrorModal, setOpenTradeErrorModal] = useState(false)
  const [tradeErrorRows, setTradeErrorRows] = useState([])
  const [tradeBlotterPopUpOpen, setTradeBlotterPopUpOpen] = useState(false)
  const [policyData, setPolicyData] = useState({})
  const [showPolicyPopup, setShowPolicyPopup] = useState(false)
  const [activeTradeTab, setActiveTradeTab] = useState('account')
  const [accountsFilterModel, setAccountsFilterModel] = useState({ items: [] })
  const [diFilterModel, setDiFilterModel] = useState({ items: [] })
  const [cashSwapData, setCashSwapData] = useState([])
  const [isCashSwapPopupOpen, setIsCashSwapPopupOpen] = useState(false)
  const [selectedAccounts, setSelectedAccounts] = useState([])
  const [isApiLoading, setIsApiLoading] = useState(false)
  const [washsaleAccounts, setWashsaleAccounts] = useState([])

  const handleOpenCashSwapPopup = () => setIsCashSwapPopupOpen(true)
  const handleCloseCashSwapPopup = () => setIsCashSwapPopupOpen(false)

  // const canAccessLivePrice = checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS,
  //   { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.VIEW_LIVE_PRICE })

  const handleTabChange = (event, newValue) => {
    setActiveTradeTab(newValue)
    processOptRunData(optRuns)
    processDIOptRunData(optDILinkRuns)
    setIsApprovalDataChanged(true)
  }
  const handleOpenTradeErrorModal = () => setOpenTradeErrorModal(true)
  const handleCloseTradeErrorModal = () => setOpenTradeErrorModal(false)

  const restoreDatagridState = () => {
    if (apiRef?.current?.restoreState && tradeDatagridState) {
      apiRef?.current?.restoreState({
        pinnedColumns: tradeDatagridState.pinnedColumns,
        columns: tradeDatagridState.columns,
        sorting: tradeDatagridState.sorting
      })
    }
    if (groupingTableRef?.current?.restoreState && tradeDIDatagridState) {
      groupingTableRef?.current?.restoreState({
        pinnedColumns: tradeDIDatagridState.pinnedColumns,
        columns: tradeDIDatagridState.columns,
        sorting: tradeDIDatagridState.sorting
      })
    }
  }

  // restore the datagrid state whenever the state variables updates
  // add new state variables in the dependency array to prevent columns from getting resize and reorder
  useEffect(() => {
    restoreDatagridState()
  }, [isLoading, loading, tradeArray, finalArray,
    optRuns, optAccountApprovals, isApprovalDataChanged,
    tradeErrorRows, showFilters, delistData, washsaleAccounts,
    openTradeErrorModal, tradeBlotterPopUpOpen, isCashSwapPopupOpen, cashSwapData,
    optDILinkRuns, optDILinkApprovals, tradeDIDatagridState, diAssistLoading,
    diFilterModel, accountsFilterModel, selectedAccounts, isApiLoading])

  const handleChartLabel = (datapoints, label, clearChartLabel=false) => {
    const findMinMax = (data, property) => {
      const values = data.map(item => item[property])
      return {
        min: Math.min(...values),
        max: Math.max(...values)
      }
    }
    const xRange = findMinMax(datapoints, 'x')
    const yRange = findMinMax(datapoints, 'y')
    const newLabel = `${yRange.min} <= ${label} <= ${yRange.max} / ${xRange.min} <= Tax($) ${xRange.max}`
    const uniqueAccountIds = Array.from(new Set(datapoints.map(item => item.accountId)))
    setChartLabelData([
      {
        xRange,
        yRange,
        label: newLabel,
        accountIds: uniqueAccountIds
      }
    ])
  }

  const handleLegendsClick = (legends) => {
    // Filter out items with a value of false
    const selectedScenario = Object.keys(legends).filter(key => !legends[key])
    // Update state with selected legends
    setLegendState(prevState => ({
      ...legends,
      selectedScenario
    }))
  }

  const updatePendingApprovalIds = (formattedData) => {
    if (activeTradeTab === 'di-assist') {
      setOptDILinkApprovals(formattedData)
    } else {
      setOptAccountApprovals(formattedData)
    }
  }

  const fetchDelistActions = async () => {
    if (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL }) &&
      checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.DELIST_INSTRUMENTS })) {
      API.get(
        'baseOptimizationURL',
        `optimization/v1/${user?.userGroup}/delist-accounts`,
        {
          queryStringParameters: {
            resources: encodeURIComponent(JSON.stringify({ serviceId: 'trade', resourceId: 'trade-approvals' }))
          }
        }
      )
        .then((response) => {
          if (response?.success) {
            setDelistData(response?.data)
          }
        })
        .catch(error => {
          showError(error, false, {}, 'Failed to load delist records.')
        })
    }
  }

  const fetchMarketValuePolicy = () => {
    if (!checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.VIEW_MARKET_VALUE_POLICY_CHECK })) { return }
    API.post('baseDataValidation', `data-validation/v1/${user.userGroup}/policies/market`)
      .then(res => {
        if (res?.data) {
          setPolicyData(res.data)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load market value policy')
      })
  }

  const canAccessDIAssist = checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.DI_ASSIST_TRADING })

  useEffect(() => {
    if (user) {
      getOptRuns()
      getDIOptRuns()
      getAllScenarioList()
      getStrategyList()
      fetchDelistActions()
      fetchMarketValuePolicy()
      getWashsaleAccounts()
    }
  }, [user])

  const resetLegends = () => {
    const originalLegends = {}
    Object.keys(scenarioMappingWithLegend).forEach(key => {
      originalLegends[key] = false
    })
    setLegendState(originalLegends)
  }

  useEffect(() => {
    processOptRunData(optRuns)
  }, [location?.state?.tradeData])

  const handleTradeBlotterClick = () => {
    setTradeBlotterPopUpOpen(true)
  }

  const processDIOptRunData = (updatedOptRun, optApprovalList = optDILinkApprovals) => {
    if (updatedOptRun?.length) {
      // store summary for each opt run
      updatedOptRun.forEach((account) => {
        account.optDetails.forEach((optOptionObj) => {
          // restore selection status of previously selected rows before refreshing data
          if (optOptionObj?.approvalDetails?.length) {
            optOptionObj.approvalDetails = optOptionObj?.approvalDetails?.map(obj => ({
              ...obj,
              isSelected: Boolean(optApprovalList.find(appObj => appObj.approvalId === obj.optApprovalId))
            }))
          }
        })
      })
      setOptDILinkRuns(updatedOptRun)
    }
    setIsLoading(false)
  }

  const processOptRunData = (updatedOptRun, optApprovalList = optAccountApprovals) => {
    if (updatedOptRun?.length) {
      // store summary for each opt run
      let scenarioList = []
      updatedOptRun.forEach((account) => {
        account.optDetails.forEach((optOptionObj) => {
          optOptionObj.summary = optOptionObj.summaryDetails
          scenarioList.push(optOptionObj.scenarioDesc)
          // restore selection status of previously selected rows before refreshing data
          if (optOptionObj?.approvalDetails?.length) {
            optOptionObj.approvalDetails = optOptionObj?.approvalDetails?.map(obj => ({
              ...obj,
              isSelected: Boolean(optApprovalList.find(appObj => appObj.approvalId === obj.optApprovalId))
            }))
          }
        })
      })
      scenarioList = [...new Set(scenarioList)]
      const mappingObj = {}
      const legendStateObj = {}

      for (let i = 0; i < scenarioList.length; i++) {
        const key = scenarioList[i].toLowerCase().replace(/\s+/g, '') // Remove spaces for valid object keys
        legendStateObj[key] = legendState[key] || false
        mappingObj[key] = scenarioList[i]
      }
      // keep old state of legend on refreshing trade list
      if (legendState.isLegendClicked) {
        legendStateObj.isLegendClicked = legendState.isLegendClicked
      }
      setLegendState(legendStateObj)
      setScenarioMappingWithLegend(mappingObj)
      setOptRuns(updatedOptRun)
      setFinalArray(updatedOptRun)
      const accountList = location?.state?.tradeData || []
      if (accountList.length > 0) {
        const filteredAccounts = updatedOptRun.filter((account) => accountList.includes(account.accountCd))
        setFinalArray(filteredAccounts)
        if (filteredAccounts.length > 0) {
          dispatch(enableLegacyViewReducer())
        }
      } else {
        setFinalArray(updatedOptRun)
      }
      setTradeArray(updatedOptRun)
    }
    setIsLoading(false)
  }

  const getOptRuns = async (clearCacheFlag = false, optApprovalList = optAccountApprovals) => {
    if (
      checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL })) {
      try {
        const response = await API.get(
          'baseOptimizationURL',
          `optimization/v1/${user?.userGroup}/opt-runs`,
          {
            queryStringParameters: {
              approval_details: true,
              status_flag: true,
              ...{ ...clearCacheFlag ? { 'cache-override': true } : {} },
               resources: encodeURIComponent(JSON.stringify({ serviceId: 'trade', resourceId: 'trade-approvals' }))
            }
          }
        )
        if (response.data?.length === 0) {
          setLoading(false)
          setIsApiLoading(false)
          setIsLoading(false)
        } else {
          processOptRunData(response.data, optApprovalList)
          setIsApiLoading(false)
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        showError(error, false, {}, 'Failed to load trade approval data.', true)
        setIsLoading(false)
        setLoading(false)
        setIsApiLoading(false)
      }
    }
  }

  const getWashsaleAccounts = async () => {
    if (
      checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL })) {
      try {
        const response = await API.get(
          'baseOptimizationURL',
          `optimization/v1/${user?.userGroup}/washsale-accounts`,
          {
            queryStringParameters: {
              resources: encodeURIComponent(JSON.stringify({ serviceId: 'trade', resourceId: 'trade-approvals' })) 
            }
          }
        )
        if (response?.data) {
          setWashsaleAccounts(response?.data)
        }
      } catch (error) {
        showError(error, false, {}, 'Failed to load washsale accounts.')
      }
    }
  }

  const getDIOptRuns = async (clearCacheFlag = false, optApprovalList = optDILinkApprovals) => {
    if (canAccessDIAssist) {
      API.get(
        'baseOptimizationURL',
        `optimization/v1/${user?.userGroup}/opt-runs/di-assist`,
        {
          queryStringParameters: {
            approval_details: true,
            resources: encodeURIComponent(JSON.stringify({ serviceId: 'trade', resourceId: 'trade-approvals' }))
          }
        }
      )
        .then((response) => {
          if (response.data?.length === 0) {
            setDiAssistLoading(false)
            setIsLoading(false)
            setIsApiLoading(false)
          } else {
            processDIOptRunData(response.data, optApprovalList)
            setDiAssistLoading(false)
            setIsApiLoading(false)
          }
        })
        .catch((error) => {
          setDiAssistLoading(false)
          showError(error, false, {}, 'Failed to load DI assist data.')
          setIsLoading(false)
          setIsApiLoading(false)
        })
    } else {
      setDiAssistLoading(false)
      setIsApiLoading(false)
    }
  }

  const getAllScenarioList = async () => {
    if (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.RUN_ADHOC }) ||
    checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.MANAGE_ADHOC_OPT_PROPERTIES })) {
      API.get('baseUriOptimizationDataMaintenance', `data-maintenance/v1/${user?.userGroup}/optimizations/all-scenario-list`)
        .then((response) => {
          if (response.data?.data?.length === 0) {
            setLoading(false)
            setIsLoading(false)
          } else {
            setAllScenarioList(response?.data?.data)
          }
        }).catch((error) => {
          showError(error, false, {}, 'Failed to load scenario list data.')
        })
    }
  }

  const handleApproveTrade = async (event) => {
    const optApprovals = activeTradeTab === 'di-assist' ? optDILinkApprovals : optAccountApprovals
    if (optApprovals?.length === 0) {
      showError('No account selected for approval')
    } else {
      setIsLoading(true)
      event.preventDefault()
      const data = optApprovals?.map(item => ({
        optApprovalId: item?.approvalId,
        status: 'APPROVED',
        remarks: 'optimization approved'
      }))
      try {
        const response = await API.patch(
          'baseOptimizationURL',
          `optimization/v1/${user?.userGroup}/opt-approvals`,
          {
            body: data,
            ...activeTradeTab === 'di-assist' ? { queryStringParameters: { isDILink: true } } : {}
          }
        )
        showSuccess(response.message)
        if (activeTradeTab === 'di-assist') {
          setOptDILinkApprovals([])
          setIsApiLoading(true)
          getDIOptRuns(false, [])
        } else {
          setOptAccountApprovals([])
          setIsApiLoading(true)
          getOptRuns(false, [])
          setIsApprovalDataChanged(true)
        }
        // expansionLookup = {}
      } catch (error) {
        showError(error, false, {}, 'Failed to approve trade.', true)
      } finally {
        setIsLoading(false)
      }
    }
  }

  // fetch settlement errors API
  const getSettlementData = async () => {
    const optApprovals = activeTradeTab === 'di-assist' ? optDILinkApprovals : optAccountApprovals
    if (optApprovals?.length === 0) {
      return []
    }
    // Pass accountId and accOptMapId from optApprovals as payload
    const payload = optApprovals?.map(item => ({
      accountId: item?.accountId,
      accOptMapId: item?.accOptMapId
    }))
    try {
      const response = await API.post(
        'baseDataValidation',
        `data-validation/v1/${user?.userGroup}/validate/aggregate-settlement`,
        {
          body: payload
        }
      )
      return response?.data || []
    } catch (error) {
      showError(error, false, {}, 'Failed to load settlement data.', true)
    }
  }

  // fetch fractional errors API
  const getFractionalData = async () => {
    const optApprovals = activeTradeTab === 'di-assist' ? optDILinkApprovals : optAccountApprovals
    if (optApprovals?.length === 0) {
      return []
    }
    try {
      // Pass accountId and accOptMapId from optApprovals as payload
      const payload = optApprovals?.map(item => ({
        accountId: item?.accountId,
        accOptMapId: item?.accOptMapId
      }))
      const response = await API.post(
        'baseDataValidation',
        `data-validation/v1/${user?.userGroup}/validate/fractional-trades`,
        {
          body: payload
        }
      )
      return response?.data || []
    } catch (error) {
      showError(error, false, {}, 'Failed to load fractional data.', true)
    }
  }

  // fetch washsale errors API
  const getWashsaleData = async () => {
    const optApprovals = activeTradeTab === 'di-assist' ? optDILinkApprovals : optAccountApprovals
    if (optApprovals?.length === 0) {
      return []
    }
    // Pass accountId and accOptMapId from optApprovals as payload
    const payload = optApprovals?.map(item => ({
      accountId: item?.accountId,
      accOptMapId: item?.accOptMapId
    }))
    try {
      const response = await API.post(
        'baseDataValidation',
        `data-validation/v1/${user?.userGroup}/validate/washsale-settlement`,
        {
          body: payload
        }
      )
      return response?.data || []
    } catch (error) {
      showError(error, false, {}, 'Failed to load washsales data.', true)
    }
  }

  const handleApprove = async (event) => {
    const optApprovals = activeTradeTab === 'di-assist' ? optDILinkApprovals : optAccountApprovals
    try {
      if (optApprovals?.length === 0) {
        showError('No account selected for approval')
      }
      // Check if there are any approvals
      if (optApprovals?.some(id => id !== null)) {
        setIsLoading(true)
        // Execute both API calls concurrently
        const apiCalls = [
          getSettlementData(),
          getFractionalData(),
          getWashsaleData()
        ]
        // Await all promises to settle
        const [settlementResult, fractionalResult, washsaleResult] = await Promise.allSettled(apiCalls)
        // Extract settlement and fractional errors from results
        const settlementErrors = settlementResult?.status === 'fulfilled' ? settlementResult?.value : []
        const fractionalErrors = fractionalResult?.status === 'fulfilled' ? fractionalResult?.value : []
        const washsaleErrors = washsaleResult?.status === 'fulfilled' ? washsaleResult?.value : []
        const combinedErrors = [
          ...(Array.isArray(settlementErrors) ? settlementErrors.map(error => ({ ...error, errorType: 'settlementError' })) : []),
          ...(Array.isArray(fractionalErrors) ? fractionalErrors.map(error => ({ ...error, errorType: 'fractionalError' })) : []),
          ...(Array.isArray(washsaleErrors) ? washsaleErrors.map(error => ({ ...error, errorType: 'washsaleErrors' })) : [])
        ]
        if (settlementResult.status === 'rejected' || fractionalResult.status === 'rejected' || washsaleResult.status === 'rejected') {
          setIsLoading(false)
        }
        setTradeErrorRows(combinedErrors?.map((item) => ({
          id: randomId(),
          ...item
        })))
        // Check if there are errors; open modal if true
        if (combinedErrors?.length > 0) {
          setIsLoading(false)
          handleOpenTradeErrorModal() // Open trade error modal if there are any errors
        } else {
          // call cashswap API only if all policy APIs are successful
          if (settlementErrors && fractionalErrors && washsaleErrors) {
            fetchCashSwapData(event) // If combinedErrors is empty, fetch cash swap API
          } else {
            setIsLoading(false)
          }
        }
      }
    } catch (error) {
      setIsLoading(false)
      showError(error, false, {}, 'Failed to approve trade.', true)
    }
  }

  const fetchCashSwapData = (event) => {
    setIsLoading(true)
    const optApprovals = activeTradeTab === 'di-assist' ? optDILinkApprovals : optAccountApprovals
    const accOptMapIds = optApprovals?.map(item => item.accOptMapId)
    const requestBody = { accOptMapIds }
    API.post(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/cash-swap`,
      { body: requestBody }
    )
      .then((response) => {
        setIsLoading(false)
        if (response?.data?.length > 0) {
          // Show popup if API returns data
          setCashSwapData(
            response?.data?.map(item => ({
              ...item,
              id: randomId()
            }))
          )
          handleOpenCashSwapPopup()
        } else {
          // Directly call handleApproveTrade if no data in response
          handleApproveTrade(event)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        showError(error, false, {}, 'Failed to load cash swap data.', true)
      })
  }

  const confirmApproval = (event) => {
    handleCloseCashSwapPopup()
    handleApproveTrade(event)
  }

  const tradeErrorColumns = [
    { field: 'accountName', headerName: 'Account Name', flex: 1 },
    {
      field: 'errorType',
      headerName: 'Policy',
      flex: 1,
      valueGetter: (params) => {
        if (params.value === 'settlementError') {
          return 'Settlement'
        } else if (params.value === 'fractionalError') {
          return 'Fractional Check'
        } else if (params.value === 'washsaleErrors') {
          return 'Washsale'
        } else {
          return params.value
        }
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ color: '#F46A6A' }}>
          {params.value}
        </Box>
      )
    }]

  const getStrategyList = async () => {
    if (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL })) {
      API
        .get('baseStrategyURL', `strategy/v1/${user?.userGroup}/strategies`, {
          queryStringParameters: {
            page: 1,
            perpage: 100,
            ...{
                  resources: encodeURIComponent(JSON.stringify({
                    serviceId: 'trade',
                    resourceId: 'trade-approvals'
                  }))
            }
          }
        })
        .then(response => {
          if (response.data.strategies) {
            setStrategies(response.data.strategies)
          }
        })
        .catch(error => {
          showError(error, false, {}, 'Failed to load strategies data.')
        })
    }
  }

  useEffect(() => {
    if (isApprovalDataChanged) {
      if (selectedFilters?.length > 0) {
        applyFilter(selectedFilters, legendState.isDefaultScenario, legendState.isLegendClicked, legendState, true)
        setIsApprovalDataChanged(false)
      }
    }
  }, [tradeArray, activeTradeTab])

  const openFilters = () => {
    setShowFilters(true)
  }

  const closeFilters = () => {
    setShowFilters(false)
  }

  const removeAllFilters = () => {
    setFinalArray(tradeArray)
    setShowFilters(false)
    setSelectedFilters([])
    setChartLabelData([])
    const originalLegends = {}
    Object.keys(scenarioMappingWithLegend).forEach(key => {
      originalLegends[key] = false
    })
    setLegendState(originalLegends)
  }

  const getFilteredOptDetailsBasedOnStatus = (optDetails, colorNumber) => {
    if (colorNumber === 0) { // scenario is pending if one approval object is present status is null
      return optDetails?.filter(optObj => optObj?.approvalDetails?.length === 1 && optObj?.approvalDetails[0].approvalStatus === null)
    } else if (colorNumber === 1) { // scenario is inprogress if both approval objects are present and one is approved and other is null
      return optDetails?.filter(optObj => optObj?.approvalDetails?.length === 2 && optObj?.approvalDetails[0].approvalStatus === 'APPROVED' && optObj?.approvalDetails[1].approvalStatus === null)
    } else if (colorNumber === 2) { // scenario is success if both approval objects are present and approved
      return optDetails?.filter(optObj => optObj?.approvalDetails?.length === 2 && optObj?.approvalDetails[0].approvalStatus === 'APPROVED' && optObj?.approvalDetails[1].approvalStatus === 'APPROVED')
    } else if (colorNumber === 99) { // scenario is fail if none of the approval objects is present
      return optDetails?.filter(optObj => !optObj?.approvalDetails?.length)
    }
    return optDetails
  }

  const removeFilter = (filterValue, type) => {
    let selectedFiltersTemp = [...selectedFilters]
    if (type === 'zoomedAccountIds') {
      selectedFiltersTemp = selectedFiltersTemp.filter(filterObj => filterObj.name !== 'zoomedAccountIds')
      // resetLegends()
      // setZoomedAccountIds([])
    } else if (type === 'strategy') {
      selectedFiltersTemp = selectedFiltersTemp.map(filterObj => {
        if (filterObj.name === 'strategy') {
          return {
            ...filterObj,
            value: filterObj.value.filter(strategy => strategy.strategyId !== filterValue.strategyId)
          }
        }
        return filterObj
      })
    } else if (type === 'taxStatus') {
      selectedFiltersTemp = selectedFiltersTemp.map(filterObj => {
        if (filterObj.name === 'taxStatus') {
          return {
            ...filterObj,
            value: filterObj.value.filter(taxStatus => taxStatus !== filterValue)
          }
        }
        return filterObj
      })
    } else if (type === 'tradeStatus') {
      selectedFiltersTemp = selectedFiltersTemp.map(filterObj => {
        if (filterObj.name === 'tradeStatus') {
          return {
            ...filterObj,
            value: filterObj.value.filter(tradeStatus => tradeStatus.id !== filterValue.id)
          }
        }
        return filterObj
      })
    } else if (type === 'trackingError') {
      selectedFiltersTemp = selectedFiltersTemp.map(filterObj => {
        if (filterObj.name === 'trackingError') {
          const teImprovementToRemove = filterValue.teImprovement
          if (filterObj.value && filterObj.value.teImprovement === teImprovementToRemove) {
            filterObj.value = null
          }
        }
        return filterObj
      })
    } else if (type === 'tradingAge') {
      selectedFiltersTemp = selectedFiltersTemp.map(filterObj => {
        if (filterObj.name === 'tradingAge') {
          const ageToRemove = filterValue.tradingAge
          if (filterObj.value && filterObj.value.tradingAge === ageToRemove) {
            filterObj.value = null
          }
        }
        return filterObj
      })
    } else if (type === 'isModelChange') {
      selectedFiltersTemp = selectedFiltersTemp.map(filterObj => {
        if (filterObj.name === 'isModelChange') {
          const modelChangeRemove = filterValue.modelChange
          if (filterObj.value !== null && filterObj.value.isModelChanged === modelChangeRemove) {
            filterObj.value = null
          }
        }
        return filterObj
      })
    }
    if (type === 'delist') {
      const filterIndex = selectedFiltersTemp.findIndex(filterObj => filterObj.name === type)
      if (filterIndex !== -1) {
        selectedFiltersTemp[filterIndex].value = selectedFiltersTemp[filterIndex].value.filter(item => item !== filterValue)
        if (selectedFiltersTemp[filterIndex].value.length === 0) {
          selectedFiltersTemp.splice(filterIndex, 1)
        }
        setSelectedFilters(selectedFiltersTemp)
        }
      } else if (type === 'washsale') {
      const filterIndex = selectedFiltersTemp.findIndex(filterObj => filterObj.name === type)
      if (filterIndex !== -1) {
        selectedFiltersTemp[filterIndex].value = selectedFiltersTemp[filterIndex].value.filter(aggGroupId => aggGroupId !== filterValue)  
        // If no values remain, remove the entire washsale filter
        if (selectedFiltersTemp[filterIndex].value?.length === 0) {
          selectedFiltersTemp.splice(filterIndex, 1)
        }
      }
    }  else if (type === 'taxCostBenefit') {
      selectedFiltersTemp = selectedFiltersTemp.map(filterObj => {
        if (filterObj.name === 'taxCostBenefit') {
          const taxCostBenefitRemove = filterValue.taxCostBenefit
          if (filterObj.value && filterObj.value.taxCostBenefit === taxCostBenefitRemove) {
            filterObj.value = null
          }
        }
        return filterObj
      })
    }
    setSelectedFilters(selectedFiltersTemp)
    // if scenarios are selected, pass scenario data in filters
    if (legendState?.selectedScenario?.length) {
      applyFilter(selectedFiltersTemp, isDefaultChecked, legendState.isLegendClicked, legendState)
    } else { applyFilter(selectedFiltersTemp, isDefaultChecked) }
  }

  const removeLegendFilter = (scenario) => {
    let updatedLegendState = { ...legendState, selectedScenario: legendState.selectedScenario.filter(scenarioObj => scenarioObj !== scenario), [scenario]: !legendState.scenario }
    const updatedSelectedFilters = [...selectedFilters]
    if (Object.keys(updatedLegendState).filter(key => key !== 'selectedScenario' && key !== 'isDefaultScenario' && key !== 'isLegendClicked').every((legendKey) => updatedLegendState[legendKey]) || Object.keys(updatedLegendState).filter(key => key !== 'selectedScenario' && key !== 'isDefaultScenario' && key !== 'isLegendClicked').every((legendKey) => !updatedLegendState[legendKey])) {
      const originalLegends = {}
      Object.keys(scenarioMappingWithLegend).forEach(key => {
        originalLegends[key] = false
      })
      updatedLegendState = originalLegends
      setLegendState(originalLegends)
      // if all legends are removed, and there is no zoomed Ids, remove zoomed ID filter from selected filters list
      const zoomFilterIndex = updatedSelectedFilters.findIndex(filter => filter.name === 'zoomedAccountIds')
      if (zoomFilterIndex > -1 && !updatedSelectedFilters[zoomFilterIndex]?.value?.length) {
        updatedSelectedFilters.splice(zoomFilterIndex, 1)
        setSelectedFilters(updatedSelectedFilters)
      }
    } else {
      setLegendState(prevState => ({ ...prevState, selectedScenario: prevState.selectedScenario.filter(scenarioObj => scenarioObj !== scenario), [scenario]: !prevState.scenario }))
    }
    const selectedScenario = updatedLegendState.selectedScenario ? Object.keys(updatedLegendState).filter(key => key !== 'selectedScenario' && key !== 'isDefaultScenario' && key !== 'isLegendClicked').filter(key => !updatedLegendState[key]) : []
    if (updatedSelectedFilters.length) {
      const chartFilter = updatedSelectedFilters.find(filter => filter.name === 'zoomedAccountIds')
      if (chartFilter?.value?.length) {
        if (selectedScenario && selectedScenario.length) {
          const filterBasedOnScenario = selectedScenario.map(scenario => scenarioMappingWithLegend[scenario])
          setFinalArray(tradeArray.filter((account) => chartFilter?.value?.includes(account.accountId))
            .map((acc) => ({
              ...acc,
              optDetails: acc.optDetails?.filter(
                (opt) =>
                  filterBasedOnScenario.includes(opt.scenarioDesc) &&
                  (isDefaultChecked ? opt.isDefault : true) &&
                  !opt.failedScenario
              )
            }))
            .filter((acc) => acc.optDetails?.length))
        } else {
          setFinalArray(tradeArray.filter((account) => chartFilter?.value?.includes(account.accountId)))
        }
      } else {
        let selectedAccounts = [...tradeArray]
        updatedSelectedFilters.filter(filterObj => filterObj.name === 'isModelChange'
          ? filterObj.value !== undefined && filterObj.value !== null
          : filterObj.value?.length).forEach((filter) => {
          if (filter.name === 'tradeStatus' && filter.value) {
            const selectedTradeFilters = filter.value
              .filter((filter) => filter.selected)
              .map((filter) => filter.colorNumber) || []
            const filteredAccounts = selectedAccounts.filter((account) => selectedTradeFilters.includes(account.colorNumber))
              .map(acc => ({
                ...acc,
                optDetails: getFilteredOptDetailsBasedOnStatus(acc.optDetails, acc.colorNumber)
              }))
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'strategy' && filter.value) {
            const filteredAccounts = selectedAccounts.filter((account) => (filter.value)?.map(str => str.strategyId).includes(account.strategyId))
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'taxStatus' && filter.value) {
            const selectedTaxStatus = []
            if (filter.value.includes('Taxable')) {
              selectedTaxStatus.push(1)
            } if (filter.value.includes('Tax Exempt')) {
              selectedTaxStatus.push(0)
            }
            const filteredAccounts = selectedAccounts.filter((account) => selectedTaxStatus?.includes(account.isTaxable))
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'trackingError' && filter.value) {
            const filteredAccounts = selectedAccounts.filter((account) => account.optDetails.some(opt =>
              parseFloat(opt.summaryDetails?.teImprovement) >= filter?.value))
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'tradingAge' && filter?.value) {
            const filteredAccounts = selectedAccounts = selectedAccounts.filter(account =>
              account?.optDetails?.some(opt =>
                opt?.summaryDetails?.tradeAge === 'NA' || parseFloat(opt?.summaryDetails?.tradeAge) >= filter?.value
              )
            )
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'isModelChange' && filter.value !== null) {
            const filteredAccounts = selectedAccounts.filter(account =>
              account.optDetails.some(opt =>
                opt.summaryDetails?.isModelChanged === filter.value
              )
            )
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'delist' && filter.value) {
            const allAccounts = filter.value?.flatMap(group => {
              const accounts = []
              if (group?.accounts) accounts.push(...group.accounts)
              return accounts
            })
            const delistAccountIds = new Set(allAccounts?.map(account => account.accountId))
            const filteredAccounts = selectedAccounts.filter(account =>
              delistAccountIds?.has(account.accountId)
            )
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'taxCostBenefit' && filter.value) {
            const taxCostBenefitValue = parseFloat(filter.value * -1)
            const filteredAccounts = selectedAccounts.filter(account =>
              account?.optDetails?.some(opt =>
                opt?.summaryDetails?.taxCost === null || parseFloat(opt?.summaryDetails?.taxCost) <= taxCostBenefitValue
              )
            )
            selectedAccounts = [...filteredAccounts]
          }
        })
        if (selectedScenario && selectedScenario.length) {
          const filterBasedOnScenario = selectedScenario.map(scenario => scenarioMappingWithLegend[scenario])
          selectedAccounts = [...updatedSelectedFilters.filter(filterObj => filterObj.name === 'isModelChange'
            ? filterObj.value !== undefined && filterObj.value !== null
            : filterObj.value?.length)?.length
            ? selectedAccounts
            : finalArray]
            .map(acc => ({
              ...acc,
              optDetails: acc.optDetails?.filter(opt => filterBasedOnScenario.includes(opt.scenarioDesc) && (isDefaultChecked ? opt.isDefault : true) && !opt.failedScenario)
            }))
            .filter((acc) => acc.optDetails?.length)
        } else {
          selectedAccounts = [...updatedSelectedFilters.filter(filterObj => filterObj.name === 'isModelChange'
            ? filterObj.value !== undefined && filterObj.value !== null
            : filterObj.value?.length)?.length
            ? selectedAccounts
            : finalArray]
        }
        setFinalArray(selectedAccounts)
      }
    } else {
      if (selectedScenario && selectedScenario.length) {
        const filterBasedOnScenario = selectedScenario.map(scenario => scenarioMappingWithLegend[scenario])
        setFinalArray(tradeArray.map(acc => ({
          ...acc,
          optDetails: acc.optDetails?.filter(opt => filterBasedOnScenario.includes(opt.scenarioDesc) && (isDefaultChecked ? opt.isDefault : true) && !opt.failedScenario)
        }))
          .filter((acc) => acc.optDetails?.length)
        )
      } else {
        setFinalArray(tradeArray)
      }
    }
  }

  const applyFilter = (filters, isDefaultScenario, isLegendClicked = false, legends = null, updateLegends = false) => {
    setIsDefaultChecked(isDefaultScenario)
    let selectedScenario = []
    if (isLegendClicked || filters.find(filter => filter.name === 'zoomedAccountIds')?.activeFilterNames?.length) {
      if (legends) {
        selectedScenario = Object.keys(legends).filter(key => !legends[key] && key !== 'isDefaultScenario')
        // Update state with selected legends
        if (updateLegends) {
          setLegendState({
            ...legends,
            selectedScenario,
            isDefaultScenario,
            isLegendClicked
          })
        }
      }
    } else {
      if (updateLegends) {
        setLegendState(prevState => ({
          ...(legends || prevState),
          isDefaultScenario,
          isLegendClicked: false
        }))
      }
    }
    if (filters.length) {
      setSelectedFilters(filters.filter(filterObj =>
        filterObj.name === 'isModelChange'
          ? filterObj.value !== undefined && filterObj.value !== null
          : (filterObj.value?.length || filterObj?.activeFilterNames?.length)
      ))
      // apply chart filter as first priority
      const chartFilter = filters.find(filter => filter.name === 'zoomedAccountIds')
      let selectedAccounts = [...tradeArray]
      if (chartFilter?.value?.length) {
        selectedAccounts = tradeArray.filter((account) => chartFilter?.value?.includes(account.accountId))
      }
      // apply other filters
      filters.filter(filterObj =>
        filterObj.name === 'isModelChange'
          ? filterObj.value !== undefined && filterObj.value !== null
          : filterObj.value?.length
      )
        .forEach((filter) => {
          if (filter.name === 'tradeStatus' && filter.value) {
            const selectedTradeFilters = filter.value
              .filter((filter) => filter.selected)
              .map((filter) => filter.colorNumber) || []
            const filteredAccounts = selectedAccounts.filter((account) => selectedTradeFilters.includes(account.colorNumber))
              .map(acc => ({
                ...acc,
                optDetails: getFilteredOptDetailsBasedOnStatus(acc.optDetails, acc.colorNumber)
              }))
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'strategy' && filter.value) {
            const filteredAccounts = selectedAccounts.filter((account) => (filter.value)?.map(str => str.strategyId).includes(account.strategyId))
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'delist' && filter.value) {
            const allAccounts = filter.value?.flatMap(group => {
              const accounts = []
              if (group?.accounts) accounts.push(...group.accounts)
              return accounts
            })
            const delistAccountIds = new Set(allAccounts?.map(account => account.accountId))
            const filteredAccounts = selectedAccounts.filter(account =>
              delistAccountIds?.has(account.accountId)
            )
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'washsale' && filter.value?.length) {
            const washSaleAccountIds = new Set(
              washsaleAccounts
                .filter(group => filter.value.includes(group.aggGroupId))
                .flatMap(group => group.accountIds?.map(account => account.accountId) ?? [])
            )          
            selectedAccounts = selectedAccounts.filter(account =>
              washSaleAccountIds.has(account.accountId)
            )                 
          } else if (filter.name === 'taxStatus' && filter.value) {
            const selectedTaxStatus = []
            if (filter.value.includes('Taxable')) {
              selectedTaxStatus.push(1)
            } if (filter.value.includes('Tax Exempt')) {
              selectedTaxStatus.push(0)
            }
            const filteredAccounts = selectedAccounts.filter((account) => selectedTaxStatus?.includes(account.isTaxable))
            selectedAccounts = [...filteredAccounts]
          } else if (filter.name === 'trackingError' && filter.value) {
            const minTeImprovement = parseFloat(filter.value)
            selectedAccounts = selectedAccounts.filter(account =>
              account.optDetails.some(opt =>
                parseFloat(opt.summaryDetails?.teImprovement) >= minTeImprovement
              )
            )
          } else if (filter.name === 'tradingAge' && filter.value) {
            const minAge = parseFloat(filter.value)
            selectedAccounts = selectedAccounts.filter(account =>
              account?.optDetails?.some(opt =>
                opt?.summaryDetails?.tradeAge === 'NA' || parseFloat(opt?.summaryDetails?.tradeAge) >= minAge
              )
            )
          } else if (filter.name === 'isModelChange' && filter.value !== null) {
            const modelChangedValue = filter.value
            selectedAccounts = selectedAccounts.filter(account =>
              account.optDetails.some(opt =>
                opt.summaryDetails?.isModelChanged === modelChangedValue
              )
            )
          } else if (filter.name === 'taxCostBenefit' && filter.value) {
            const taxCostBenefitValue = parseFloat(filter.value * -1)
            selectedAccounts = selectedAccounts.filter(account =>
              account?.optDetails?.some(opt =>
                opt?.summaryDetails?.taxCost === null || parseFloat(opt?.summaryDetails?.taxCost) <= taxCostBenefitValue
              )
            )
          }
        })
      // apply selected scenario filters. show opt entries which has selected scenarios
      if (selectedScenario && selectedScenario.length) {
        const filterBasedOnScenario = selectedScenario.map(scenario => scenarioMappingWithLegend[scenario])
        selectedAccounts = [...filters.filter(filterObj => filterObj.name === 'isModelChange'
          ? filterObj.value !== undefined && filterObj.value !== null
          : filterObj.value?.length)?.length
          ? selectedAccounts
          : tradeArray].map(acc => ({
          ...acc,
          optDetails: acc.optDetails?.filter(opt => filterBasedOnScenario.includes(opt.scenarioDesc) && (isDefaultScenario ? opt.isDefault : true) && !opt.failedScenario)
        }))
          .filter((acc) => acc.optDetails?.length > 0)
      } else {
        selectedAccounts = [...filters.filter(filterObj =>
          filterObj.name === 'isModelChange'
            ? filterObj.value !== undefined && filterObj.value !== null
            : filterObj.value?.length
        )?.length
          ? selectedAccounts
          : tradeArray]
      }
      setFinalArray(selectedAccounts)
    } else if (selectedScenario && selectedScenario.length) {
      const filterBasedOnScenario = selectedScenario.map(scenario => scenarioMappingWithLegend[scenario])
      setFinalArray(tradeArray.map(acc => ({
        ...acc,
        optDetails: acc.optDetails?.filter(opt => filterBasedOnScenario.includes(opt.scenarioDesc) && (isDefaultScenario ? opt.isDefault : true) && !opt.failedScenario)
      }))
        .filter((acc) => acc.optDetails?.length > 0))
    } else {
      setFinalArray([...tradeArray])
    }
    closeFilters()
  }

  const handleRefreshClick = () => {
    setLoading(true)
    setDiAssistLoading(true)
    setIsApprovalDataChanged(true)
    getOptRuns(true)
    getDIOptRuns()
  }

  const getDetailPanelContent = useCallback(
    ({ row }) => <TradeErrorTable row={row} />,
    []
  )
  const getDetailPanelHeight = useCallback(() => 'auto', [])

  const menuOptions = [
    { label: 'Re-run Policies', accessKey: moduleConfig.BULK_RERUN_POLICY },
    { label: 'Re-run Optimization', accessKey: moduleConfig.BULK_RERUN_OPTIMIZATION }
  ]

  const accessibleOptions = menuOptions.filter(option =>
    checkAccess(moduleConfig.ARIS_INTERNAL, ACCESS_LEVEL.COMPONENT_ACCESS, {
      subModuleName: moduleConfig.ARIS_INTERNAL,
      component_name: option.accessKey
    })
  )

  const isTableDataAvailable = activeTradeTab === 'account' ? Boolean(finalArray?.length) : activeTradeTab === 'di-assist' ? Boolean(optDILinkRuns?.length) : false
  const canAccessBlotter = checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.VIEW_TRADE_BLOTTER }) && isTableDataAvailable
  const canAccessDownloadTransaction = checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.DOWNLOAD_TRANSACTION }) && activeTradeTab === 'account' && finalArray.length
  const canAccessApprovalButton = (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.APPROVAL1 }) || checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.APPROVAL2 })) && isTableDataAvailable

  const renderTabPanel = (tabName, isTabsVisible = false) => {
    switch (tabName) {
      case 'account': {
        return (
          <Box className='tradeMainbody' py='4px'>
            {
              loading
                ? (
                  <TradeApprovalsHeaderMain />
                )
                : (
                  <>
                    {/* Display collapsible rows for trade list */}
                    {tradeArray.length && (location?.state?.policyName || location?.state?.tradeData?.length)
                      ? <Chip
                        sx={{
                          mb: 1,
                          borderRadius: '5px',
                          background: 'rgba(116,120,141,.102)',
                          color: '#74788D'
                        }}
                        size='small'
                        label={location?.state?.filterName ? location?.state?.filterName : ''}
                        className='chip'
                      />
                      : ''}
                    {
                      tradeArray.length && selectedFilters.length
                        ? <>
                          {
                            selectedFilters.map((filterObj, index) => {
                              if (filterObj.name === 'zoomedAccountIds') {
                                return (
                                  <div key={index} style={{ display: 'inline-block' }}>
                                    {
                                      chartLabelData?.map((chartLabel, chartIndex) => (
                                        <Chip
                                          key={chartIndex}
                                          sx={{
                                            ml: 1,
                                            mb: 1,
                                            borderRadius: '5px',
                                            background: 'rgba(116,120,141,.102)',
                                            color: '#74788D',
                                            '.MuiChip-icon': {
                                              mx: 0
                                            }
                                          }}
                                          size='small'
                                          icon={
                                            <IconButton
                                              onClick={() => {
                                                removeFilter(chartLabel, 'zoomedAccountIds')
                                              }}
                                              sx={{
                                                borderRadius: '0px',
                                                borderRight: '0.5px solid #74788D40'
                                              }}
                                            >
                                              <img src={closeIcon} alt='' height={8} width={8} />
                                            </IconButton>
                                          }
                                          label={chartLabel.label}
                                          className='chip'
                                        />
                                      ))
                                    }
                                  </div>
                                )
                              } else if (filterObj.name === 'strategy') {
                                return (
                                  <div key={index} style={{ display: 'inline-block' }}>
                                    {filterObj.value?.map((strategy) => (
                                      <Chip
                                        key={strategy.straegyId}
                                        sx={{
                                          ml: 1,
                                          mb: 1,
                                          borderRadius: '5px',
                                          background: 'rgba(116,120,141,.102)',
                                          color: '#74788D',
                                          '.MuiChip-icon': {
                                            mx: 0
                                          }
                                        }}
                                        size='small'
                                        icon={
                                          <IconButton
                                            onClick={() => {
                                              removeFilter(strategy, 'strategy')
                                            }}
                                            sx={{
                                              borderRadius: '0px',
                                              borderRight: '0.5px solid #74788D40'
                                            }}
                                          >
                                            <img src={closeIcon} alt='' height={8} width={8} />
                                          </IconButton>
                                        }
                                        label={`Strategy : ${strategy.strategyName}`}
                                        className='chip'
                                      />
                                    ))}
                                  </div>
                                )
                              } else if (filterObj.name === 'tradeStatus') {
                                return (
                                  <div key={index} style={{ display: 'inline-block' }}>
                                    {filterObj.value?.map((tradeStatus) => (
                                      <Chip
                                        key={tradeStatus.id}
                                        sx={{
                                          ml: 1,
                                          mb: 1,
                                          borderRadius: '5px',
                                          background: 'rgba(116,120,141,.102)',
                                          color: '#74788D',
                                          '.MuiChip-icon': {
                                            mx: 0
                                          }
                                        }}
                                        size='small'
                                        icon={
                                          <IconButton
                                            onClick={() => {
                                              removeFilter(tradeStatus, 'tradeStatus')
                                            }}
                                            sx={{
                                              borderRadius: '0px',
                                              borderRight: '0.5px solid #74788D40'
                                            }}
                                          >
                                            <img src={closeIcon} alt='' height={8} width={8} />
                                          </IconButton>
                                        }
                                        label={`${tradeStatus.name}`}
                                        className='chip'
                                      />
                                    ))}
                                  </div>
                                )
                              } else if (filterObj.name === 'taxStatus') {
                                return (
                                  <div key={index} style={{ display: 'inline-block' }}>
                                    {filterObj.value?.map((taxStatus, taxIndex) => (
                                      <Chip
                                        key={taxIndex}
                                        sx={{
                                          ml: 1,
                                          mb: 1,
                                          borderRadius: '5px',
                                          background: 'rgba(116,120,141,.102)',
                                          color: '#74788D',
                                          '.MuiChip-icon': {
                                            mx: 0
                                          }
                                        }}
                                        size='small'
                                        icon={
                                          <IconButton
                                            onClick={() => {
                                              removeFilter(taxStatus, 'taxStatus')
                                            }}
                                            sx={{
                                              borderRadius: '0px',
                                              borderRight: '0.5px solid #74788D40'
                                            }}
                                          >
                                            <img src={closeIcon} alt='' height={8} width={8} />
                                          </IconButton>
                                        }
                                        label={`${taxStatus}`}
                                        className='chip'
                                      />
                                    ))}
                                  </div>
                                )
                              } else if (filterObj.name === 'trackingError') {
                                return (
                                  <div key={index} style={{ display: 'inline-block' }}>
                                    <Chip
                                      key={index}
                                      sx={{
                                        ml: 1,
                                        mb: 1,
                                        borderRadius: '5px',
                                        background: 'rgba(116,120,141,.102)',
                                        color: '#74788D',
                                        '.MuiChip-icon': {
                                          mx: 0
                                        }
                                      }}
                                      size='small'
                                      icon={
                                        <IconButton
                                          onClick={() => {
                                            removeFilter(filterObj.value, 'trackingError')
                                          }}
                                          sx={{
                                            borderRadius: '0px',
                                            borderRight: '0.5px solid #74788D40'
                                          }}
                                        >
                                          <img src={closeIcon} alt='' height={8} width={8} />
                                        </IconButton>
                                      }
                                      label={`Tracking Error Improvement >= ${filterObj.value} bps`}
                                      className='chip'
                                    />
                                  </div>
                                )
                              } else if (filterObj.name === 'tradingAge') {
                                return (
                                  <div key={index} style={{ display: 'inline-block' }}>
                                    <Chip
                                      key={index}
                                      sx={{
                                        ml: 1,
                                        mb: 1,
                                        borderRadius: '5px',
                                        background: 'rgba(116,120,141,.102)',
                                        color: '#74788D',
                                        '.MuiChip-icon': {
                                          mx: 0
                                        }
                                      }}
                                      size='small'
                                      icon={
                                        <IconButton
                                          onClick={() => {
                                            removeFilter(filterObj.value, 'tradingAge')
                                          }}
                                          sx={{
                                            borderRadius: '0px',
                                            borderRight: '0.5px solid #74788D40'
                                          }}
                                        >
                                          <img src={closeIcon} alt='' height={8} width={8} />
                                        </IconButton>
                                      }
                                      label={`Trading Age >= ${filterObj.value} days`}
                                      className='chip'
                                    />
                                  </div>
                                )
                              } else if (filterObj.name === 'isModelChange') {
                                return (
                                  <div key={index} style={{ display: 'inline-block' }}>
                                    <Chip
                                      key={index}
                                      sx={{
                                        ml: 1,
                                        mb: 1,
                                        borderRadius: '5px',
                                        background: 'rgba(116,120,141,.102)',
                                        color: '#74788D',
                                        '.MuiChip-icon': {
                                          mx: 0
                                        }
                                      }}
                                      size='small'
                                      icon={
                                        <IconButton
                                          onClick={() => {
                                            removeFilter(filterObj.value, 'isModelChange')
                                          }}
                                          sx={{
                                            borderRadius: '0px',
                                            borderRight: '0.5px solid #74788D40'
                                          }}
                                        >
                                          <img src={closeIcon} alt='' height={8} width={8} />
                                        </IconButton>
                                      }
                                      label={`Model Change: ${filterObj.value ? 'Yes' : 'No'}`}
                                      className='chip'
                                    />
                                  </div>
                                )
                              } else if (filterObj.name === 'delist') {
                                return (
                                  <div key={index} style={{ display: 'inline-block' }}>
                                    {filterObj.value?.map((item, chipIndex) => (
                                      <Chip
                                        key={chipIndex}
                                        sx={{
                                          ml: 1,
                                          mb: 1,
                                          borderRadius: '5px',
                                          background: 'rgba(116,120,141,.102)',
                                          color: '#74788D',
                                          '.MuiChip-icon': {
                                            mx: 0
                                          }
                                        }}
                                        size='small'
                                        icon={
                                          <IconButton
                                            onClick={() => {
                                              removeFilter(item, 'delist')
                                            }}
                                            sx={{
                                              borderRadius: '0px',
                                              borderRight: '0.5px solid #74788D40'
                                            }}
                                          >
                                            <img src={closeIcon} alt='' height={8} width={8} />
                                          </IconButton>
                                        }
                                        label={`Delist: ${item.instrId}`}
                                        className='chip'
                                      />
                                    ))}
                                  </div>
                                )
                              } else if (filterObj.name === 'washsale') {
                                return (
                                  <div key={index} style={{ display: 'inline-block' }}>
                                    {filterObj.value?.map((aggGroupId, chipIndex) => {
                                      const matchedGroup = washsaleAccounts.find(group => group.aggGroupId === aggGroupId)
                                      const aggGroupName = matchedGroup?.aggGroupName ?? ''
                                      return (
                                        <Chip
                                          key={chipIndex}
                                          sx={{
                                            ml: 1,
                                            mb: 1,
                                            borderRadius: '5px',
                                            background: 'rgba(116,120,141,.102)',
                                            color: '#74788D',
                                            '.MuiChip-icon': { mx: 0 },
                                          }}
                                          size='small'
                                          icon={
                                            <IconButton
                                              onClick={() => removeFilter(aggGroupId, 'washsale')}
                                              sx={{ borderRadius: 0, borderRight: '0.5px solid #74788D40' }}
                                            >
                                              <img src={closeIcon} alt='' height={8} width={8} />
                                            </IconButton>
                                          }
                                          label={`Washsale: ${aggGroupName}`}
                                        />
                                      )
                                    })}
                                  </div>
                                )
                              } else if (filterObj.name === 'taxCostBenefit') {
                                return (
                                  <div key={index} style={{ display: 'inline-block' }}>
                                    <Chip
                                      key={index}
                                      sx={{
                                        ml: 1,
                                        mb: 1,
                                        borderRadius: '5px',
                                        background: 'rgba(116,120,141,.102)',
                                        color: '#74788D',
                                        '.MuiChip-icon': {
                                          mx: 0
                                        }
                                      }}
                                      size='small'
                                      icon={
                                        <IconButton
                                          onClick={() => {
                                            removeFilter(filterObj.value, 'taxCostBenefit')
                                          }}
                                          sx={{
                                            borderRadius: '0px',
                                            borderRight: '0.5px solid #74788D40'
                                          }}
                                        >
                                          <img src={closeIcon} alt='' height={8} width={8} />
                                        </IconButton>
                                      }
                                      label={`Tax Cost Benefit <= ${formatCurrencyWithSymbol(filterObj.value, 0, '$')}`}
                                      className='chip'
                                    />
                                  </div>
                                )
                              } else return (<Fragment key={index} />)
                            })
                          }
                        </>
                        : null
                    }
                    {
                      tradeArray.length && legendState?.selectedScenario && legendState?.selectedScenario?.length
                        ? legendState.selectedScenario.filter(scenario => scenarioMappingWithLegend[scenario]).map((scenario, index) => (
                          <div key={index} style={{ display: 'inline-block' }}>
                            <Chip
                              sx={{
                                ml: 1,
                                mb: 1,
                                borderRadius: '5px',
                                background: 'rgba(116,120,141,.102)',
                                color: '#74788D',
                                '.MuiChip-icon': {
                                  mx: 0
                                }
                              }}
                              size='small'
                              icon={
                                <IconButton
                                  onClick={() => {
                                    removeLegendFilter(scenario)
                                  }}
                                  sx={{
                                    borderRadius: '0px',
                                    borderRight: '0.5px solid #74788D40'
                                  }}
                                >
                                  <img src={closeIcon} alt='' height={8} width={8} />
                                </IconButton>
                              }
                              label={scenarioMappingWithLegend[scenario]}
                              className='chip'
                            />
                          </div>
                        ))
                        : ''
                    }
                    {finalArray.length > 0
                      ? (
                          <TradeLegacyViewTable
                            apiRef={apiRef}
                            selectedAccounts={selectedAccounts}
                            accessibleOptions={accessibleOptions}
                            setSelectedAccounts={setSelectedAccounts}
                            dataArray={finalArray || []}
                            getOptRuns={getOptRuns}
                            updatePendingApprovalIds={updatePendingApprovalIds}
                            allScenarioList={allScenarioList}
                            legendState={(legendState?.selectedScenario && legendState?.selectedScenario?.length) ? legendState : {}}
                            scenarioMappingWithLegend={scenarioMappingWithLegend}
                            setTradeDatagridState={setTradeDatagridState}
                            tradeDatagridState={tradeDatagridState}
                            filterModel={accountsFilterModel}
                            setFilterModel={setAccountsFilterModel}
                            isTabsVisible={isTabsVisible}
                          />
                      )
                      : (
                        <>
                          {/* No account available for approval case */}
                          <Grid container sx={{ my: '20px' }}>
                            <Grid item xs={12}>
                              <Card>
                                <CardContent>
                                  <Box className='table-responsive'>
                                    No account available for approval.
                                  </Box>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </>
                      )}
                  </>
                )
            }
            <TradeApprovalFilterMenu
              optmRun={optRuns}
              showFilters={showFilters}
              closeFilters={closeFilters}
              removeAll={removeAllFilters}
              tradeStatusFilters={tradeStatusFilters}
              tradeArray={tradeArray}
              getChartLabel={handleChartLabel}
              updateLegends={handleLegendsClick}
              appliedLegend={legendState}
              resetLegends={resetLegends}
              strategies={strategies}
              applyFilter={applyFilter}
              selectedFilters={selectedFilters}
              isDefaultScenarioChecked={isDefaultChecked}
              scenarioMappingWithLegend={scenarioMappingWithLegend}
              delistData={delistData}
              washsaleAccountData={washsaleAccounts}
            />
          </Box>
        )
      }
      case 'di-assist': {
        return (
          <>
            {
              diAssistLoading
                ? (
                  <TradeApprovalsHeaderMain DIheader />
                )
                : (
                  optDILinkRuns?.length > 0
                    ? <DIAssistTrading
                      apiRef={groupingTableRef}
                      dataArray={optDILinkRuns || []}
                      updatePendingApprovalIds={updatePendingApprovalIds}
                      allScenarioList={allScenarioList}
                      setTradeDatagridState={setTradeDIDatagridState}
                      tradeDatagridState={tradeDIDatagridState}
                      filterModel={diFilterModel}
                      setFilterModel={setDiFilterModel}
                    />
                    : (
                      <Grid container sx={{ my: '20px' }}>
                        <Grid item xs={12}>
                          <Card>
                            <CardContent>
                              <Box className='table-responsive'>
                                No account available for approval.
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    )
                )
            }
          </>
        )
      }
      default:
        return ''
    }
  }

  return (
    <>
      {tradeBlotterPopUpOpen && <TradeBlotterPopUp open={tradeBlotterPopUpOpen} setOpen={setTradeBlotterPopUpOpen} apiRef={activeTradeTab === 'di-assist' ? groupingTableRef : apiRef} />}
      <Box className='Account-trade'>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
          className='tradeMainhead'
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Typography component='h3' className='page-title'>Trade Approval</Typography>
            {
              policyData?.overallPolicyColor && checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.VIEW_MARKET_VALUE_POLICY_CHECK })
                ? (
                  <Tooltip title='Market Value Policies'>
                    <IconButton onClick={() => setShowPolicyPopup(true)} sx={{ p: '6px' }}>
                      <CircleIcon sx={{ color: getStatusColor(policyData?.overallPolicyColor), height: '12px', width: '12px' }} />
                    </IconButton>
                  </Tooltip>
                  )
                : ''
            }
            <PolicyStatusPopup data={policyData} title='Market Value' onClose={() => setShowPolicyPopup(false)} showPolicyPopup={showPolicyPopup} canDownloadJson={checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.DOWNLOAD_MARKET_VALUE_POLICY_CHECK })} />
          </Box>
          <Box
            className='tradeApprove-btn'
            sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
          >
            {/* {
              canAccessLivePrice ? (
                <LivePriceButton />
              ) : ''
            } */}
            {tradeArray.length && location?.state?.tradeData && location?.state?.tradeData?.length
              ? <Button
                  variant='text'
                  onClick={() => {
                    setFinalArray(tradeArray)
                    navigate('.', { state: null })
                  }}
                  startIcon={<img src={showAllAccount} alt='' />}
                >Show All Accounts
              </Button>
              : ''}
            {
              canAccessBlotter
                ? (
                  <Button
                    onClick={handleTradeBlotterClick}
                    startIcon={<BsBarChartLine size={16} color='gray' />}
                  >
                    Blotter
                  </Button>
                  )
                : ''
            }
            {
              canAccessDownloadTransaction
                ? <DownloadTransButton />
                : ''
            }
            {
              canAccessApprovalButton
                ? (
                  <Button
                    className='tardeApprove'
                    variant='contained'
                    disabled={isLoading}
                    onClick={(event) => handleApprove(event)}
                  >
                    Approve (<PeopleAltOutlinedIcon color='white' sx={{ mr: '3px', fontSize: '16px' }} /> {activeTradeTab === 'di-assist' ? optDILinkApprovals?.length : optAccountApprovals?.length})
                  </Button>)
                : ''
          }
          {
              accessibleOptions && accessibleOptions?.length && activeTradeTab === 'account' && finalArray?.length
            ? (
                <RerunPolicyAndOptimization
                data={finalArray}
                setLoading={setLoading}
                setBulkRerunLoader={setIsApiLoading}
                selectedAccounts={selectedAccounts}
                setSelectedAccounts={setSelectedAccounts}
                getOptRuns={getOptRuns}
                accessibleOptions={accessibleOptions}
              />
            )
            : ''
          }
          {
            activeTradeTab === 'account' && tradeArray?.length
              ? <IconButton
                onClick={openFilters}
                sx={{
                  padding: 1,
                  borderRadius: '10px',
                  border: '2px solid #dee2e6',
                }}
              >
                <img src={filterIcon} alt='filter' height={20} width={20} />
              </IconButton>
              : ''
          }
          <Button
            variant='outlined'
            onClick={handleRefreshClick}
            sx={{
              border: '2px solid #dee2e6',
              padding: '8px',
              minWidth: 'auto',
              ':hover': {
                background: 'transparent',
                border: '2px solid #dee2e6'
              },
              '&.Mui-disabled': {
                border: '2px solid #dee2e6'
              }
            }}
            disabled={loading}
          >
            <RefreshOutlinedIcon fontSize='small' sx={{ color: '#74788D' }} />
          </Button>
          </Box>
        </Box>
        {(isLoading || isApiLoading) ? <Loader /> : ''}
        {
          canAccessDIAssist
            ? (
              <TabContext value={activeTradeTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={handleTabChange}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: '#34475A'
                      }
                    }}
                    indicatorColor='#34475A'>
                    <Tab label='Account' value='account' />
                    <Tab label='DI Assist' value='di-assist' />
                  </TabList>
                </Box>
                <TabPanel value='account' sx={{ p: 0, pb: 0 }}>
                  {renderTabPanel('account', true)}
                </TabPanel>
                <TabPanel value='di-assist' sx={{ px: 0, pt: '5px', pb: 0 }}>
                  {renderTabPanel('di-assist', true)}
                </TabPanel>
              </TabContext>
              )
            : renderTabPanel('account')
        }

        <Dialog
          open={openTradeErrorModal}
          onClose={handleCloseTradeErrorModal}
          fullWidth
          maxWidth='md'
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant='h6' component='h2'>
                Policy Check
              </Typography>
              <IconButton aria-label='close' onClick={handleCloseTradeErrorModal}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  color: '#74788D'
                },
                '& .MuiDataGrid-footerContainer': {
                  border: 'none'
                },
                '& .MuiDataGrid-root': {
                  border: 'none'
                },
                '& .MuiTablePagination-toolbar': {
                  alignItems: 'baseline'
                }
              }}
            >
              <DataGridPro
                autoHeight
                rows={tradeErrorRows}
                columns={tradeErrorColumns}
                density='compact'
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={getDetailPanelHeight}
                pagination
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
                slots={{
                  detailPanelExpandIcon: ExpandMoreIcon,
                  detailPanelCollapseIcon: ExpandLessIcon
                }}
                initialState={{
                  ...tradeErrorRows?.initialState,
                  pagination: { paginationModel: { pageSize: 10 } }
                }}
                sx={(theme) => ({
                  '&.MuiDataGrid-root': {
                    height: 450,
                    overflowY: 'auto',
                    scrollbarGutter: 'stable',
                    scrollbarWidth: 'none'
                  },
                  [`.${gridClasses.main}`]: {
                    overflow: 'unset'
                  },
                  [`.${gridClasses.columnHeaders}`]: {
                    position: 'sticky',
                    top: 0,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 3
                  },
                  [`.${gridClasses.footerContainer}`]: {
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 2
                  },
                  [`.${gridClasses.toolbarContainer}`]: {
                    position: 'relative',
                    top: 0,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1
                  }
                })}
              />
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
      {cashSwapData?.length > 0 && isCashSwapPopupOpen
        ? <CashSwapPopup
            open={isCashSwapPopupOpen}
            onClose={handleCloseCashSwapPopup}
            onConfirm={(event) => confirmApproval(event)}
            data={cashSwapData}
          />
        : ''}
    </>
  )
}

export default TradeApprovalsMain
