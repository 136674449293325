import { useState, useCallback, useEffect, useContext } from 'react'
import { Box, IconButton, Menu, MenuItem, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { DataGridPro, gridFilteredDescendantCountLookupSelector, gridClasses, useGridApiContext, useGridSelector, useGridApiRef } from '@mui/x-data-grid-pro'
import { ExpandMore, FileDownloadOutlined } from '@mui/icons-material'
import { ScenarioContext } from '..'

// Column definitions with formatting
const columns = [
  {
    field: 'weight',
    headerName: 'Weight (%)',
    type: 'number',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    valueGetter: (params) =>
      params?.row?.weight !== undefined && params?.row?.weight !== null
        ? params?.row?.weight?.toFixed(2)
        : ''
  },
  {
    field: 'modelWeight',
    headerName: 'Model Weight (%)',
    type: 'number',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    valueGetter: (params) =>
      params?.row?.modelWeight !== undefined && params?.row?.modelWeight !== null
        ? params?.row?.modelWeight?.toFixed(2)
        : ''
  },
  {
    field: 'marketValue',
    headerName: 'Market Value',
    type: 'number',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    renderCell: (params) => (
      params?.row?.marketValue !== undefined
        ? params?.row?.marketValue !== null
          ? params?.row?.marketValue < 0
            ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
            : '$' + Number(params?.value)?.toLocaleString()
          : 'NA'
        : ''
    )
  },
  {
    field: 'urgl',
    headerName: 'Unrealized Gain/Loss',
    type: 'number',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    renderCell: (params) => (
      params?.row?.urgl !== undefined
        ? params?.row?.urgl !== null
          ? Number(params?.row?.urgl?.toFixed(0)) < 0
            ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
            : '$' + Math.abs(Number(params?.value))?.toLocaleString()
          : 'NA'
        : ''
    )
  }
]

// Store expansion state between renders
let taxlotExpansionLookup = {}

// Custom tree node component for grouping cells
const CustomGridTreeDataGroupingCell = (props) => {
  const { id, field, rowNode } = props
  const apiRef = useGridApiContext()
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  )
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

  const handleClick = (event) => {
    if (rowNode.type !== 'group') {
      return
    }
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  return (
    <Box sx={{ ml: rowNode.depth * 2 }}>
      <div>
        {filteredDescendantCount > 0 && props?.row?.hierarchy?.length >= 1
          ? (
            <>
              <IconButton
                onClick={handleClick}
                size='small'
                tabIndex={-1}
                aria-label={rowNode.childrenExpanded ? 'Close' : 'Open'}
              >
                <ExpandMore
                  sx={{
                    transform: `rotateZ(${rowNode.childrenExpanded ? 360 : 270}deg)`,
                    transition: (theme) =>
                      theme.transitions.create('transform', {
                        duration: theme.transitions.duration.shortest
                      })
                  }}
                  fontSize='inherit'
                />
              </IconButton>
              <span style={{ marginLeft: rowNode.depth * 2 }}>
                {props?.row?.name}
              </span>
            </>
          )
          : (
            <span style={{ marginLeft: rowNode.depth * 8 }}>{props?.row?.name}</span>
          )}
      </div>
    </Box>
  )
}

const EnhancedTaxlot = ({ aggId }) => {
  const scenarioData = useContext(ScenarioContext)
  const loading = scenarioData?.taxlotData[aggId]?.loading
  const data = scenarioData?.taxlotData[aggId]?.data || {}
  const [downloadCSVAnchorEl, setDownloadCSVAnchorEl] = useState(null)
  const tableData = data?.taxlot || []
  const accountHierarchy = data?.accountHierarchy || {}
  const rawData = data?.rawData || []
  const apiRef = useGridApiRef()

  function downloadCSV(blobParts, filename, type) {
    const csvBlob = new Blob(blobParts, { type })
    const csvUrl = URL.createObjectURL(csvBlob)
    const link = document.createElement('a')
    link.href = csvUrl
    link.setAttribute('download', filename)
    link.click()
    URL.revokeObjectURL(csvUrl)
  }

  const downloadDetailedData = () => {
    const headers = ['Account Name', 'Account Code', 'Local Symbol', 'Shares', 'Trade Code', 'Src Account Name', 'Purchase Date', 'Market Value', 'Weight']
    const csvBody = [headers.join('|')]
    rawData.forEach(data => {
      csvBody.push([
        data?.accountName || '',
        data?.accountCd || '',
        data?.localSymbol || '',
        data?.shares,
        data?.tradeCode || '',
        data?.sourceAccountName || '',
        data?.purchaseDate?.split('T')[0]?.replace(/-/g, '') || '',
        data?.marketValue,
        data?.weight
      ].join('|'))
    })
    downloadCSV([csvBody.join('\n')], 'taxlot-details.csv', 'text/csv')
    setDownloadCSVAnchorEl(null)
  }

  const downloadAccountData = () => {
    const headers = ['Symbol', 'Purchase Date', 'Total Purchase Cost', 'Quantity / Shares', 'ISO Country Code']
    Object.keys(accountHierarchy)?.forEach(accName => {
      const csvBody = [headers.join(',')]
      accountHierarchy[accName]?.forEach(data => {
        csvBody.push([
          data?.localSymbol || '',
          data?.purchaseDate?.split('T')[0]?.replace(/-/g, '') || '',
          data?.purchaseCost,
          data?.shares,
          data?.isoCountryCode || ''
        ].join(','))
      })
      downloadCSV([csvBody.join('\n')], `${accName}_Portfolio.csv`, 'text/csv')
    })
    setDownloadCSVAnchorEl(null)
  }

  useEffect(() => {
    if (apiRef?.current?.subscribeEvent) {
      apiRef?.current?.subscribeEvent('rowExpansionChange', (node) => {
        if (node?.groupingKey) {
          taxlotExpansionLookup[node?.groupingKey] = node?.childrenExpanded
        }
      })
    }
    return () => {
      taxlotExpansionLookup = {}
    }
  }, [apiRef, data])

  const isGroupExpandedByDefault = useCallback(
    (node) => {
      return node?.groupingKey && !!taxlotExpansionLookup?.[node?.groupingKey]
    },
    []
  )

  return (
    <Box sx={{ p: 2, borderRadius: 2, border: 1, borderColor: '#E6ECF3' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        {
          !loading?.rawData ? (
            <Box sx={{ display: 'flex' }}>
              <Tooltip title='Download CSV'>
                <IconButton onClick={(e) => setDownloadCSVAnchorEl(e.currentTarget)}>
                  <FileDownloadOutlined fontSize='small' />
                </IconButton>
              </Tooltip>
              <Menu
                open={Boolean(downloadCSVAnchorEl)}
                onClose={() => setDownloadCSVAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                MenuListProps={{
                  sx: { py: '4px' }
                }}
                anchorEl={downloadCSVAnchorEl}
              >
                <MenuItem
                  sx={{ fontSize: '14px' }}
                  onClick={downloadDetailedData}
                >
                  Export Taxlot
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: '14px' }}
                  onClick={downloadAccountData}
                >
                  Export Account Portfolio
                </MenuItem>
              </Menu>
            </Box>
          ) : ''
        }
      </Box>
      {
        loading?.groupData ? (
          <TableContainer mt={10}>
            <Table className='risk-page-table'>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  {columns.map((item, index) => {
                    return (
                      <TableCell key={index}>{item.headerName}</TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from({ length: 5 }).map((_, i) => (
                  <TableRow key={i}>
                    {
                      Array.from({ length: columns.length + 1 }).map((_, j) => (
                        <TableCell key={j}>
                          <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                        </TableCell>
                      ))
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <DataGridPro
            density='compact'
            rows={tableData}
            columns={columns}
            apiRef={apiRef}
            isGroupExpandedByDefault={isGroupExpandedByDefault}
            treeData
            getTreeDataPath={(row) => row?.hierarchy}
            autoHeight
            getRowId={(row) => row?.id}
            disableRowSelectionOnClick
            groupingColDef={{
              headerName: 'Name',
              field: 'name',
              flex: 1,
              filterable: true,
              sortable: true,
              disableColumnMenu: false,
              valueGetter: (params) => params?.row?.name,
              renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />
            }}
            localeText={{ noRowsLabel: 'No Portfolio Found' }}
            pageSizeOptions={[10, 25, 50, 100]}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } }
            }}
            pagination
            sx={{
              [`.${gridClasses.detailPanel}`]: {
                maxHeight: '500px',
                height: 'auto !important'
              }
            }}
          />
        )
      }
    </Box>
  )
}

export default EnhancedTaxlot
