import ReactApexChart from 'react-apexcharts'
import { gradientColors } from './Consideration'

const BarChart = ({ data, chartType }) => {
  const transformedSeries = data?.datasets?.map((dataset, index) => {
    const validData = dataset?.data?.map((value, dataIndex) => ({
      x: data?.labels[dataIndex],
      y: value?.toFixed(2) ?? 0,
      goals: [
        {
          name: data?.labels[dataIndex],
          value: value?.toFixed(2) ?? 0,
          strokeHeight: 2,
          strokeColor: gradientColors[index]?.color
        }
      ]
    }))
    return {
      name: dataset?.label || `Series ${index + 1}`,
      data: validData || []
    }
  })

  // Base configuration shared between both chart types
  const options = {
    chart: {
      type: 'bar',
      height: 500,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50px',
        borderRadius: 0,
        distributed: false
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        gradientToColors: gradientColors?.map(color => color?.startColor),
        stops: [0, 100]
      }
    },
    colors: gradientColors?.map(color => color?.endColor),
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: data?.labels?.map(label => String(label)),
      axisBorder: {
        show: true,
        color: '#74788D4D'
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          colors: '#000000'
        }
      }
    },
    yaxis: {
      labels: {
        formatter: (value) => Number(value)?.toFixed(2),
        style: {
          colors: '#000000'
        }
      },
      tickAmount: 6,
      lines: {
        show: true
      },
      axisBorder: {
        show: true,
        color: '#74788D4D'
      },
      axisTicks: {
        show: true,
        color: '#74788D4D'
      }
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const xLabel = w.globals.labels[dataPointIndex]

        return `
          <div style="
            background: #000000;
            padding: 12px 16px;
            border-radius: 8px;
            font-family: 'Open Sans', sans-serif;
            min-width: 180px;
          ">
            <div style="
              display: flex;
              align-items: center;
              gap: 6px;
              margin-bottom: 8px;
            ">
              <span style="
                color: white;
                font-size: 14px;
                font-weight: 600;
              ">${xLabel}</span>
            </div>
            ${series.map((s, i) => {
          // Get the gradient colors for this series
          // const startColor = gradientColors[i % gradientColors.length].startColor
          // const endColor = gradientColors[i % gradientColors.length].endColor

          return `
                <div style="
                  color: #B4B4B4;
                  font-size: 12px;
                  margin: 6px 0;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  gap: 8px;
                ">
                  <div style="
                    display: flex;
                    align-items: center;
                    gap: 6px;
                  ">
                    <span style="
                      display: inline-block;
                      width: 12px;
                      height: 12px;
                      border-radius: 2px;
                      background: ${gradientColors[i]?.color}
                    "></span>
                    <span>${w.globals.seriesNames[i]}</span>
                  </div>
                  <span style="color: white; font-weight: 600;">${s[dataPointIndex].toFixed(2) + '%'}</span>
                </div>
              `
        }).join('')}
          </div>
        `
      }
    },
    legend: {
      show: false
    }
  }

  // Add type-specific configurations
  if (chartType === 'market-cap') {
    options.grid = {
      show: true,
      borderColor: '#74788D4D',
      strokeDashArray: 12,
      position: 'back',
      yaxis: {
        lines: {
          show: true
        }
      }
    }
    // options.dataLabels = {
    //   ...options.dataLabels,
    //   enabled: true,
    //   offsetY: -30,
    //   formatter: (val) => val + '%',
    //   style: {
    //     fontFamily: 'Open Sans',
    //     fontWeight: 400,
    //     colors: ['#000']
    //   }
    // }
    // options.plotOptions = {
    //   ...options.plotOptions,
    //   bar: {
    //     ...options.plotOptions.bar,
    //     dataLabels: {
    //       position: 'top'
    //     }
    //   }
    // }
  } else if (chartType === 'factors') {
    options.grid = {
      show: false
    }
    options.annotations = {
      yaxis: [{
        y: 0,
        strokeDashArray: 0,
        borderColor: '#74788D4D',
        borderWidth: 1,
        opacity: 1
      }]
    }
  }

  return (
    <ReactApexChart
      options={options}
      series={transformedSeries}
      type='bar'
      height={500}
    />
  )
}

export default BarChart
