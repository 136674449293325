import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Button, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import AllocationModelCards from './AllocationModelCards'

const AllocationModelTab = (props) => {
  const {
    umaDetails, addAllocation, allocationCardData, setAllocationCardData, personalizationData, setPersonalizationData, setAllStepsVisited
    , strategies, loading, accountData
  } = props

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '5px', marginBottom: '8px' }}>
        <Typography sx={{ fontWeight: 600, color: '#74788D', fontSize: '17px', fontFamily: 'Open Sans' }}>Create Target Allocation Model</Typography>
        {umaDetails?.accountType === 'transition' && (
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Button
              onClick={() => addAllocation()}
              sx={{ alignItems: 'flex-end' }}
              disabled={allocationCardData?.length >= 3}
            >
              Add more Allocation
            </Button>
            <Tooltip
              title='Maximum of 3 allocations allowed.'
              arrow
            >
              <InfoOutlinedIcon sx={{ fontSize: '16px', cursor: 'pointer' }} />
            </Tooltip>
          </Box>
        )}
        </Box>
      <Divider sx={{ mb: '17px' }} />
      {allocationCardData?.map((allocation, index) => (
        <AllocationModelCards
          key={allocation.id}
          accountType={umaDetails?.accountType}
          defaultAllocationTitleId={index + 1}
          allocationId={allocation.id}
          allocation={allocation}
          allocationCardData={allocationCardData}
          setAllStepsVisited={setAllStepsVisited}
          loading={loading}
          setAllocationCardData={setAllocationCardData}
          personalizationData={personalizationData}
          strategies={strategies}
          setPersonalizationData={setPersonalizationData}
          accountData={accountData}
        />
      ))}
    </Box>
  )
}

export default AllocationModelTab
