import React from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { Box, Card, CardContent, Grid, IconButton, Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { DataGridPro, gridClasses, gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // Import the UTC plugin

// Extend dayjs with the utc plugin
dayjs.extend(utc)

const formateToUsdCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

const getPurchaseCost = (value) => {
  return value !== undefined && value !== null ? parseFloat((value || 0).toFixed(2)) : null
}

const calculateTaxlotTotalRow = (data) => {
  return {
    id: 'total',
    instrName: 'Total',
    symbol: '',
    accountLotWeight: data.reduce((sum, row) => sum + (parseFloat(row.accountLotWeight) || 0), 0),
    modelWeight: data.reduce((sum, row) => sum + (parseFloat(row.modelWeight) || 0), 0),
    shares: data.reduce((sum, row) => sum + (parseFloat(row.shares) || 0), 0),
    purchaseCost: data.reduce((sum, row) => sum + (parseFloat(row.purchaseCost) || 0), 0),
    orgPurchasePrice: data.reduce((sum, row) => sum + (parseFloat(row.orgPurchasePrice) || 0), 0),
    urgl: data.reduce((sum, row) => sum + (parseFloat(row.urgl) || 0), 0),
    marketValue: data.reduce((sum, row) => sum + (parseFloat(row.marketValue) || 0), 0),
    modelDrift: data.reduce((sum, row) => sum + (parseFloat(row.modelDrift) || 0), 0),
    mcte: data.reduce((sum, row) => sum + (parseFloat(row.mcte) || 0), 0),
    purchaseDate: '',
    isoCountryName: ''
  }
}

const calculateCompanyTotalRow = (rows) => {
  const totals = {
    id: 'totalRow',
    name: 'Total',
    accountLotWeight: 0,
    modelWeight: 0,
    shares: 0,
    purchaseCost: 0,
    urgl: 0,
    modelDrift: 0,
    mcte: 0,
    marketValue: 0,
    purchaseDate: '',
    isoCountryName: ''
  }
  // Filter rows to get only the company level rows
  const companyLevelRows = rows.filter(row => row?.hierarchy?.length === 1)
  companyLevelRows.forEach(row => {
    if (row?.accountLotWeight !== null && row?.accountLotWeight !== undefined) {
      totals.accountLotWeight += row?.accountLotWeight
    }
    if (row?.modelWeight !== null && row?.modelWeight !== undefined) {
      totals.modelWeight += row?.modelWeight
    }
    if (row?.shares !== null && row?.shares !== undefined) {
      totals.shares += row?.shares
    }
    if (row?.purchaseCost !== null && row?.purchaseCost !== undefined) {
      totals.purchaseCost += row?.purchaseCost
    }
    if (row?.urgl !== null && row?.urgl !== undefined) {
      totals.urgl += row?.urgl
    }
    if (row?.modelDrift !== null && row?.modelDrift !== undefined) {
      totals.modelDrift += row?.modelDrift
    }
    if (row?.mcte !== null && row?.mcte !== undefined) {
      totals.mcte += row?.mcte
    }
    if (row?.marketValue !== null && row?.marketValue !== undefined) {
      totals.marketValue += row?.marketValue
    }
  })
  totals.accountLotWeight = totals.accountLotWeight === 0 ? 0.0000 : parseFloat(totals.accountLotWeight)
  totals.modelWeight = totals.modelWeight === 0 ? 0.0000 : parseFloat(totals.modelWeight)
  totals.mcte = totals.mcte === 0 ? 0.0000 : parseFloat(totals.mcte)
  totals.marketValue = totals.marketValue === 0 ? 0.00 : parseFloat(totals.marketValue)
  totals.purchaseCost = totals.purchaseCost === 0 ? 0.00 : parseFloat(totals.purchaseCost)
  totals.urgl = totals.urgl === 0 ? 0.00 : parseFloat(totals.urgl)
  return totals
}

// Helper function to get the model drift value
const getModelDrift = (params) => {
  const driftVal = params?.value
  if (driftVal === null || driftVal === undefined || isNaN(driftVal)) {
    return null
  }
  return parseFloat(driftVal)
}

const taxlotLevelcolumns = [
  { field: 'instrName', headerName: 'Name', flex: 2 },
  { field: 'symbol', headerName: 'Symbol', flex: 1, valueGetter: (params) => params.row.localSymbol === '__USD' ? '__CASH' : params.row.localSymbol },
  {
    field: 'accountLotWeight',
    headerName: 'Account Weight',
    flex: 1,
    type: 'number',
    valueGetter: (params) => params?.value !== null && params?.value !== undefined && !isNaN(Number(params.value))
      ? parseFloat(Number(params.value).toFixed(4))
      : null,
    renderCell: (params) => params?.value !== null && params?.value !== undefined ? params?.value?.toFixed(4) : ''
  },
  {
    field: 'shares',
    headerName: 'Share',
    flex: 1,
    type: 'number',
    renderCell: (params) => params.value !== undefined && params.value !== null ? params.value : ''
  },
  {
    field: 'purchaseCost',
    headerName: 'Purchase Cost',
    flex: 1,
    type: 'number',
    valueGetter: (params) => (params.row.instrId === '__USD' || params.row.instrId === '__CASH')
      ? getPurchaseCost(params.row.shares)
      : getPurchaseCost(params.row.purchaseCost),
    renderCell: (params) => params.value !== null ? `${formateToUsdCurrency.format(params.value)}` : ''
  },
  {
    field: 'orgPurchasePrice',
    headerName: 'Org Purchase Price',
    flex: 1,
    type: 'number',
    valueGetter: (params) => getPurchaseCost(params?.value),
    renderCell: (params) => params.value !== undefined && params.value !== null ? `${formateToUsdCurrency.format(params.value)}` : ''
  },
  {
    field: 'marketValue',
    headerName: 'Market Value',
    flex: 1,
    type: 'number',
    valueGetter: (params) => getPurchaseCost(params?.value),
    renderCell: (params) => params.value !== undefined && params.value !== null ? `${formateToUsdCurrency.format(params.value)}` : ''
  },
  {
    field: 'urgl',
    headerName: 'Unrealized Gain/Loss',
    flex: 1,
    type: 'number',
    valueGetter: (params) => getPurchaseCost(params?.value),
    renderCell: (params) => params.value !== undefined && params.value !== null ? `${formateToUsdCurrency.format(params.value)}` : ''
  },
  { field: 'purchaseDate', headerName: 'Purchase Date', flex: 1, renderCell: (params) => params.row.purchaseDate ? params.row.purchaseDate.split('T')[0] : '' },
  { field: 'isoCountryName', headerName: 'Country Code', flex: 1 }
]

const companyLevelColumns = [
  { field: 'symbol', headerName: 'Symbol', flex: 1 },
  {
    field: 'accountLotWeight',
    headerName: 'Account Weight',
    flex: 1,
    type: 'number',
    valueGetter: (params) => params?.value !== null && params?.value !== undefined && !isNaN(Number(params.value))
      ? parseFloat(Number(params.value).toFixed(4))
      : null,
    renderCell: (params) => params?.value !== null && params?.value !== undefined ? params?.value?.toFixed(4) : ''
  },
  {
    field: 'modelWeight',
    headerName: 'Model Weight',
    flex: 1,
    type: 'number',
    valueGetter: (params) => {
      if (params?.row?.hierarchy?.length === 1 || params?.row?.id === 'totalRow') {
        return params?.value !== null && params?.value !== undefined && !isNaN(Number(params.value))
          ? parseFloat(Number(params.value).toFixed(4))
          : null
      }
      return null
    },
    renderCell: (params) => {
      if (params?.row?.hierarchy?.length === 1 || params?.row?.id === 'totalRow') {
        return params.value !== undefined && params.value !== null ? params.value.toFixed(4) : ''
      }
      return ''
    }
  },
  { field: 'shares', headerName: 'Share', flex: 1, type: 'number', renderCell: (params) => params.value !== undefined && params.value !== null ? params.value : '' },
  {
    field: 'purchaseCost',
    headerName: 'Purchase Cost',
    flex: 1,
    type: 'number',
    valueGetter: (params) => (params.row.instrId === '__USD' || params.row.instrId === '__CASH')
      ? getPurchaseCost(params.row.shares)
      : getPurchaseCost(params.row.purchaseCost),
    renderCell: (params) => params.value !== null ? `${formateToUsdCurrency.format(params.value)}` : ''
  },
  {
    field: 'urgl',
    headerName: 'Unrealized Gain/Loss',
    flex: 1,
    type: 'number',
    valueGetter: (params) => getPurchaseCost(params?.value),
    renderCell: (params) => params.value !== undefined && params.value !== null ? `${formateToUsdCurrency.format(params.value)}` : ''
  },
  {
    field: 'mcte',
    headerName: 'MCTE',
    flex: 1,
    type: 'number',
    valueGetter: (params) => {
      const value = params?.value
      if ((params?.row?.hierarchy?.length === 1 || params?.row?.id === 'totalRow') && value !== null && value !== undefined) {
        return parseFloat((parseFloat(value) * 100).toFixed(6))
      }
      return null
    },
    renderCell: (params) => {
      const value = params?.value
      if (params?.row?.hierarchy?.length === 1 || params?.row?.id === 'totalRow') {
        return value !== null && value !== undefined ? value.toFixed(6) : ''
      }
      return ''
    }
  },
  {
    field: 'marketValue',
    headerName: 'Market Value',
    flex: 1,
    type: 'number',
    valueGetter: (params) => getPurchaseCost(params?.value),
    renderCell: (params) => params.value !== undefined && params.value !== null ? `${formateToUsdCurrency.format(params.value)}` : ''
  },
  {
    field: 'modelDrift',
    headerName: 'Model Drift',
    flex: 1,
    type: 'number',
    renderCell: (params) => {
      const driftVal = getModelDrift(params)
      const isParentRow = params?.row?.hierarchy?.length === 1
      if (!isParentRow) return ''
      const color = driftVal > 0 ? '#3BBFA3' : driftVal < 0 ? '#FF6161' : ''
      const Icon = driftVal > 0 ? AddCircleOutlineIcon : driftVal < 0 ? RemoveCircleOutlineIcon : null
      return (
        <>
          {Icon && <Icon fontSize='small' sx={{ color, marginRight: '2px' }} />}
          <Typography
            fontSize='14px'
            fontWeight='600'
            color={color}
            sx={{ width: '55px', textAlign: 'right', display: 'inline-block' }}
          >
            {Math.abs(driftVal).toFixed(4)}
          </Typography>
        </>
      )
    },
    valueGetter: (params) => {
      const driftVal = getModelDrift(params)
      const isParentRow = params?.row?.hierarchy?.length === 1
      if (!isParentRow) return null
      const fixedDriftVal = driftVal !== null && driftVal !== undefined ? parseFloat(driftVal.toFixed(4)) : null
      return fixedDriftVal
    }
  },
  { field: 'purchaseDate', headerName: 'Purchase Date', flex: 1, renderCell: (params) => params.row.purchaseDate ? params.row.purchaseDate.split('T')[0] : '' },
  { field: 'isoCountryName', headerName: 'Country Code', flex: 1 }
]

const CustomGridTreeDataGroupingCell = (props) => {
  const { id, field, rowNode } = props
  const apiRef = useGridApiContext()
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  )
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

  const handleClick = (event) => {
    if (rowNode.type !== 'group') {
      return
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  return (
    <Box>
      {filteredDescendantCount > 0
        ? (
          <>
            <IconButton
              size='small'
              onClick={handleClick}
              aria-label={rowNode.childrenExpanded ? 'Close' : 'Open'}
            >
              <ExpandMoreIcon
                sx={{
                  transform: `rotateZ(${rowNode.childrenExpanded ? 360 : 270}deg)`,
                  transition: (theme) =>
                    theme.transitions.create('transform', {
                      duration: theme.transitions.duration.shortest
                    })
                }}
                fontSize='inherit'
              />
            </IconButton>
            <span>
              {props?.row?.name}
            </span>
          </>
          )
        : (
          <span style={{ marginLeft: rowNode.depth * 27 }}>
            {props?.row?.name}
          </span>
          )}
    </Box>
  )
}

const RenderTableSkeleton = ({ headers }) => {
  const excludedColumns = ['mcte', 'marketValue', 'modelDrift']
  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((item, index) => {
            if (excludedColumns?.includes(item.field)) return null
            return (
              <TableCell key={index}>{item.headerName}</TableCell>
            )
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.from({ length: 5 }).map((_, rowIndex) => (
          <TableRow key={rowIndex}>
            {headers?.map((item, colIndex) => {
              if (excludedColumns.includes(item.field)) return null
              return (
                <TableCell key={colIndex}>
                  <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                </TableCell>
              )
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const CurrentImport = ({ taxlotData, portfolioLevel, companyWisePortfolio, isTaxlotLoading, noDataMsg }) => {
  const totalTaxlotRow = calculateTaxlotTotalRow(taxlotData)
  const totalCompanyRow = calculateCompanyTotalRow(companyWisePortfolio)
  const updatedCompanyRows = [...companyWisePortfolio, totalCompanyRow]

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            {isTaxlotLoading
              ? (
                <Box className='table-responsive-import'>
                  <RenderTableSkeleton headers={taxlotLevelcolumns} />
                </Box>
                )
              : taxlotData?.length > 0
                ? portfolioLevel === 'Company'
                  ? (
                    <DataGridPro
                      rows={updatedCompanyRows || []}
                      columns={companyLevelColumns}
                      getRowId={(row) => row.id}
                      disableRowSelectionOnClick
                      pinnedRows={{ bottom: totalCompanyRow ? [totalCompanyRow] : [] }}
                      autoHeight
                      pagination
                      density='compact'
                      treeData
                      getTreeDataPath={(row) => row.hierarchy || []}
                      groupingColDef={{
                        headerName: 'Name',
                        flex: 2,
                        filterable: true,
                        sortable: true,
                        disableColumnMenu: false,
                        valueGetter: (params) => params?.row?.name,
                        renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />
                      }}
                      initialState={{
                        ...companyWisePortfolio?.initialState,
                        pagination: { paginationModel: { pageSize: 15 } },
                        columns: {
                          columnVisibilityModel: {
                            mcte: false,
                            marketValue: false,
                            modelDrift: false
                          }
                        }
                      }}
                      pageSizeOptions={[15, 25, 50, 75, 100]}
                      sx={{
                        '& .MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        [`.${gridClasses.main}`]: {
                          height: 'calc(100vh - 48px - 15px - 20px - 56px - 48px - 54px - 16px - 24px - 39px - 53px)'
                        },
                        [`.${gridClasses.columnHeaders}`]: {
                          position: 'sticky',
                          top: 0,
                          zIndex: 1
                        },
                        [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                          color: '#74788d',
                          fontWeight: 600
                        },
                        [`.${gridClasses.virtualScroller}`]: {
                          overflowY: 'auto !important',
                          scrollbarGutter: 'stable',
                          scrollbarWidth: 'none'
                        }
                      }}
                    />
                    )
                  : (
                    <DataGridPro
                      rows={taxlotData || []}
                      columns={taxlotLevelcolumns}
                      getRowId={(row) => row.id}
                      disableRowSelectionOnClick
                      autoHeight
                      pagination
                      getTreeDataPath={(row) => [row.id]}
                      pinnedRows={{ bottom: taxlotData?.length ? [totalTaxlotRow] : [] }}
                      density='compact'
                      initialState={{
                        ...taxlotData?.initialState,
                        pagination: { paginationModel: { pageSize: 15 } },
                        columns: {
                          columnVisibilityModel: {
                            mcte: false,
                            marketValue: false,
                            modelDrift: false
                          }
                        }
                      }}
                      pageSizeOptions={[15, 25, 50, 75, 100]}
                      sx={{
                        '& .MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        [`.${gridClasses.main}`]: {
                          height: 'calc(100vh - 48px - 15px - 20px - 56px - 48px - 54px - 16px - 24px - 39px - 53px)'
                        },
                        [`.${gridClasses.columnHeaders}`]: {
                          position: 'sticky',
                          top: 0,
                          zIndex: 1
                        },
                        [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                          color: '#74788d',
                          fontWeight: 600
                        },
                        [`.${gridClasses.virtualScroller}`]: {
                          overflowY: 'auto !important',
                          scrollbarGutter: 'stable',
                          scrollbarWidth: 'none'
                        }
                      }}
                    />
                    )
                : (
                  <Stack style={{ minHeight: '30px' }}>
                    {noDataMsg || 'No rows'}
                  </Stack>
                  )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default CurrentImport
