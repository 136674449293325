import { createContext, useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box, Card, CardContent, Button } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { disableLegacyViewReducer } from '../../../store/trade-reducer/trade-reducer'
import SolutionSummary from './SolutionSummaryTab'
import OriginalUMA from './OriginalSummaryTab'
import ScenarioTab from './ScenarioTab'
import { KPICards, MainTabs, TabPanel, UMADetail } from './components'
import { assetAllocationChartDataProcess, generateYearCategories, performanceChartDataProcess, sectorAllocationDataProcess, taxSavingChartDataProcess } from '../AggregateDashboard/aggregateDataProcessing'
import SummaryFlowChart from './ScenarioTab/SummaryFlowChart'

export const SummaryContext = createContext(null)
export const SolutionContext = createContext(null)
export const ScenarioContext = createContext(null)
export const UmaContext = createContext(null)

const UMADashboard = () => {
  const params = useParams()
  const location = useLocation()
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const navigate = useNavigate()
  const [mainTabs, setMainTabs] = useState([])
  const [selectedTab, setSelectedTab] = useState(null)
  const [summaryGraphData, setSummaryGraphData] = useState({})
  const [scenarioMarketCapAndFactorData, setScenarioMarketCapAndFactorData] = useState({})
  const [scenarioSectorData, setScenarioSectorData] = useState({})
  const [scenarioAllocationData, setScenarioAllocationData] = useState({})
  const [scenarioPerformanceData, setScenarioPerformanceData] = useState({})
  const [taxlotData, setTaxlotData] = useState({})
  const [tradelistData, setTradelistData] = useState({})
  const [solutionTaxSavingsData, setSolutionTaxSavingsData] = useState([])
  const [solutionPerformanceData, setSolutionPerformanceData] = useState([])
  const [isSummaryMarketCapAndFactorLoading, setIsSummaryMarketCapAndFactorLoading] = useState(false)
  const [solutionMarketCapAndFactor, setSolutionMarketCapAndFactor] = useState({})
  const [isSummarySectorDataLoading, setIsSummarySectorDataLoading] = useState(true)
  const [isSolutionTaxSavingDataLoading, setIsSolutionTaxSavingDataLoading] = useState(false)
  const [isSolutionPerformanceDataLoading, setIsSolutionPerformanceDataLoading] = useState(false)
  const [summarySectorData, setSummarySectorData] = useState()
  const [solutionSector, setSolutionSector] = useState({})
  const [summaryMarketCapAndFactorData, setSummaryMarketCapAndFactorData] = useState({})
  const [returnOverTimeData, setReturnOverTimeData] = useState({})
  const [isSummaryAllocationLoading, setIsSummaryAllocationLoading] = useState(true)
  const [isTopUrglSecurityLoading, setIsTopUrglSecurityLoading] = useState(false)
  const [topUrglSecurities, setTopUrglSecurities] = useState([])
  const [originalSummaryAllocation, setOriginalSummaryAllocationData] = useState({})
  const [isScenarioCardLoading, setIsScenarioCardLoading] = useState(true)
  const [scenarioCardData, setScenarioCardData] = useState([])
  const [expandSummaryChart, setExpandSummaryChart] = useState(false)
  const [umaDetailsData, setUmaDetailsData] = useState({ loading: true, data: null })
  const [topCard, setTopCard] = useState({ loading: true, data: {} })
  const [summaryInsightApiLoading, setSummaryInsightApiLoading] = useState(false)
  const [summaryInsightsData, setSummaryInsightsData] = useState([])
  const [scenarioInsightsData, setScenarioInsightsData] = useState([])
  const [scenarioInsightApiLoading, setScenarioInsightApiLoading] = useState(false)
  const [selectedReturnOverTimeScenario, setSelectedReturnOverTimeScenario] = useState('')
  const dispatch = useDispatch()

  const yearCategories = generateYearCategories()

  const toggleSummaryChartExpandView = () => {
    setExpandSummaryChart(prevState => !prevState)
  }

  const handleTabChange = (_, newValue) => {
    if (topCard.data.aggType === 'SCENARIO_TRANSITION') {
      navigate(`/aggregate/uma-dashboard/${newValue}`)
    }
    setSelectedTab(newValue)
  }

  const fetchReturnsOverTime = (aggId) => {
    setReturnOverTimeData({ loading: true, data: {} })
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/agg-return-over-time/${aggId}`
    )
      .then(res => {
        setReturnOverTimeData({ loading: false, data: res?.data })
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to returns data.')
      }).finally(() => setReturnOverTimeData(prev => ({ ...prev, loading: false })))
  }

  const fetchSummaryInsightsData = () => {
    setSummaryInsightApiLoading(true)
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/dashboard/agg/summary-insights/${params?.aggGroupId}`
    )
      .then(res => {
        if (res?.data) {
          setSummaryInsightsData(res?.data)
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to fetch summary insights.')
      }).finally(() => {
        setSummaryInsightApiLoading(false)
      })
  }

  const fetchTopCardsData = () => {
    setTopCard({ loading: true, data: {} })
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/dashboard/agg/top-card/${params?.aggGroupId}`
    )
      .then(res => {
        setTopCard(prev => ({ ...prev, loading: false, data: res?.data }))
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to fetch top card data.')
      })
      .finally(() => {
        setTopCard(prev => ({ ...prev, loading: false }))
      })
  }

  const fetchUmaDetails = (aggId) => {
    setUmaDetailsData({ loading: true, data: null })
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/dashboard/uma-detail/${aggId}`
    )
      .then(res => {
        if (res?.data) {
          if (params.aggGroupId === res?.data?.aggId) {
            setMainTabs([{ id: 'summary', label: 'Summary' }, { id: 'solution', label: 'Solution' }])
            setSelectedTab('summary')
            fetchReturnsOverTime(params?.aggGroupId)
            fetchSummaryInsightsData()
            fetchAllocationData()
            fetchTaxSavingData()
            fetchPerformanceData()
            fetchSolutionConsiderationData(res?.data?.scenarioUMA[0]?.aggId)
            fetchSummaryConsiderationData()
            fetchScenarioCards()
            fetchScenarioInsightsData()
            fetchTopUrglSecurities()
            fetchTopCardsData()
          } else {
            setMainTabs(res?.data?.scenarioUMA?.map(obj => ({ id: obj.aggId, label: obj.aggName })))
            setSelectedTab(params?.aggGroupId)
            const dataObject = {}
            res?.data?.scenarioUMA?.forEach(obj => {
              dataObject[obj?.aggId] = { loading: false, data: null }
            })
            setSummaryGraphData(dataObject)
            setScenarioMarketCapAndFactorData(dataObject)
            setScenarioSectorData(dataObject)
            setScenarioPerformanceData(dataObject)
            setTaxlotData(dataObject)
            setTradelistData(dataObject)
            fetchTopCardsData()
          }
          setUmaDetailsData({ loading: false, data: res?.data })
        }
      })
      .catch(error => {
        setTopCard({ loading: false, data: {} })
        setUmaDetailsData({ loading: false, data: null })
        showError(error, false, {}, 'Failed to fetch UMA details.')
      })
      .finally(() => {
        setUmaDetailsData(prev => ({ ...prev, loading: false }))
      })
  }

  const fetchAllocationData = () => {
    setIsSummaryAllocationLoading(true)
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/agg-allocation-data/${params?.aggGroupId}`
    )
      .then(res => {
        if (res?.data) {
          setOriginalSummaryAllocationData(res?.data)
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to fetch allocation data.')
      })
      .finally(() => setIsSummaryAllocationLoading(false))
  }

  const fetchTaxSavingData = () => {
    setIsSolutionTaxSavingDataLoading(true)
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/uma/scenario-tax-savings/${params?.aggGroupId}`
    )
      .then(res => {
        if (res?.data) {
          setSolutionTaxSavingsData(taxSavingChartDataProcess(res?.data))
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to fetch tax saving data.')
      })
      .finally(() => setIsSolutionTaxSavingDataLoading(false))
  }

  const fetchPerformanceData = () => {
    setIsSolutionPerformanceDataLoading(true)
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/uma/scenario-performance/${params?.aggGroupId}`
    )
      .then(res => {
        if (res?.data) {
          setSolutionPerformanceData(performanceChartDataProcess(res?.data || [], 'marketValueProjection'))
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to fetch performance data.')
      })
      .finally(() => setIsSolutionPerformanceDataLoading(false))
  }

  const fetchTopUrglSecurities = () => {
    setIsTopUrglSecurityLoading(true)
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/dashboard/uma-urgl/${params?.aggGroupId}`
    )
      .then(res => {
        if (res?.data) {
          setTopUrglSecurities(res?.data)
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to fetch security data.')
      })
      .finally(() => setIsTopUrglSecurityLoading(false))
  }

  const fetchMarketCapFactorData = (aggId, isSolutionScreen) => {
    if (isSolutionScreen) {
      setSolutionMarketCapAndFactor(prev => ({ ...prev, [aggId]: { loading: true, data: {} } }))
    } else {
      setIsSummaryMarketCapAndFactorLoading(true)
    }
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/dashboard/marketcap-factor/${aggId}`
    ).then((res) => {
      if (res?.data) {
        if (isSolutionScreen) {
          setSolutionMarketCapAndFactor(prev => ({ ...prev, [aggId]: { loading: false, data: assetAllocationChartDataProcess(res, true) } }))
        } else {
          setSummaryMarketCapAndFactorData(assetAllocationChartDataProcess(res))
        }
      }
    }).catch((error) => {
      showError(error, false, {}, 'Failed to fetch market-cap and factor allocation data.')
    }).finally(() => {
      if (isSolutionScreen) {
        setSolutionMarketCapAndFactor(prev => ({ ...prev, [aggId]: { ...prev[aggId], loading: false } }))
      } else {
        setIsSummaryMarketCapAndFactorLoading(false)
      }
    })
  }

  const fetchSectorData = (aggId, isSolutionScreen) => {
    if (isSolutionScreen) {
      setSolutionSector(prev => ({ ...prev, [aggId]: { loading: true, data: {} } }))
    } else {
      setIsSummarySectorDataLoading(true)
    }
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/dashboard/sector/${aggId}`
    ).then((res) => {
      if (res?.data) {
        if (isSolutionScreen) {
          setSolutionSector(prev => ({ ...prev, [aggId]: { loading: false, data: sectorAllocationDataProcess(res, isSolutionScreen) } }))
        } else {
          setSummarySectorData(sectorAllocationDataProcess(res))
        }
      }
    }).catch((error) => {
      showError(error, false, {}, 'Failed to fetch sector allocation data.')
    }).finally(() => {
      if (isSolutionScreen) {
        setSolutionSector(prev => ({ ...prev, [aggId]: { ...prev[aggId], loading: false } }))
      } else {
        setIsSummarySectorDataLoading(false)
      }
    })
  }

  const fetchScenarioInsightsData = () => {
    setScenarioInsightApiLoading(true)
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/dashboard/agg/scenario-insights/${params?.aggGroupId}`
    )
      .then(res => {
        if (res?.data) {
          setScenarioInsightsData(res?.data)
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to fetch scenario insights.')
      }).finally(() => {
        setScenarioInsightApiLoading(false)
      })
  }

  const fetchScenarioCards = () => {
    setIsScenarioCardLoading(true)
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/dashboard/agg/summary/${params?.aggGroupId}`
    )
      .then(res => {
        if (res?.data) {
          setScenarioCardData(res?.data)
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to fetch transition summary.')
      }).finally(() => {
        setIsScenarioCardLoading(false)
      })
  }

  const fetchSolutionConsiderationData = (aggId) => {
    fetchMarketCapFactorData(aggId, true)
    fetchSectorData(aggId, true)
  }

  const fetchSummaryConsiderationData = () => {
    fetchMarketCapFactorData(params?.aggGroupId)
    fetchSectorData(params?.aggGroupId)
  }

  useEffect(() => {
    // on page refresh topCard won't have any data so it will fetch uma details
    // navigating from transition to scenario uma will have top card as transition
    // so it will fetch uma details
    // also if current id is not found in scenario list that means screen is changed
    // so it will fetch uma details
    // when changing tabs in scenario uma topCard will have scenario type 
    // so it won't fetch uma details instead it will only fetch topCard API
    if (location?.state?.umaType === 'TRANSITION') {
      setMainTabs([{ id: 'summary', label: 'Summary' }, { id: 'solution', label: 'Solution' }])
      setSelectedTab('summary')
      setTopCard({ loading: true, data: {} })
      setReturnOverTimeData({ loading: true, data: {} })
      setSummaryInsightApiLoading(true)
      setIsSolutionTaxSavingDataLoading(true)
      setIsSolutionPerformanceDataLoading(true)
      setIsSummaryMarketCapAndFactorLoading(true)
      setIsSummarySectorDataLoading(true)
      fetchUmaDetails(params?.aggGroupId)
    } else if (topCard?.data?.aggType !== 'SCENARIO_TRANSITION' || !umaDetailsData?.data?.scenarioUMA?.find(obj => obj?.aggId === params?.aggGroupId)) {
      setMainTabs([])
      setSelectedTab(null)
      setTopCard({ loading: true, data: {} })
      fetchUmaDetails(params?.aggGroupId)
    } else {
      fetchTopCardsData()
    }
  }, [location])

  const getTabContent = (item) => {
    switch (item) {
      case 'summary':
        return (
          <SummaryContext.Provider value={{
            returnOverTimeData,
            selectedReturnOverTimeScenario, setSelectedReturnOverTimeScenario,
            fetchReturnsOverTime,
            isSummaryAllocationLoading,
            originalSummaryAllocation,
            summaryInsightApiLoading,
            summaryInsightsData,
            isSummaryMarketCapAndFactorLoading,
            summaryMarketCapAndFactorData,
            isSummarySectorDataLoading,
            summarySectorData,
            isTopUrglSecurityLoading,
            topUrglSecurities
          }}>
            <OriginalUMA />
          </SummaryContext.Provider>
        )
      case 'solution':
        return (
          <SolutionContext.Provider value={{
            isSolutionPerformanceDataLoading,
            solutionPerformanceData,
            isSolutionTaxSavingDataLoading,
            solutionTaxSavingsData,
            solutionMarketCapAndFactor, fetchMarketCapFactorData,
            solutionSector, fetchSectorData,
            isScenarioCardLoading,
            scenarioCardData
          }}>
            <SolutionSummary />
          </SolutionContext.Provider>
        )
      default:
        return (
          <ScenarioContext.Provider value={{
            aggId: selectedTab,
            summaryGraphData, setSummaryGraphData,
            scenarioMarketCapAndFactorData, setScenarioMarketCapAndFactorData,
            scenarioSectorData, setScenarioSectorData,
            scenarioInsightApiLoading, scenarioInsightsData,
            scenarioAllocationData, setScenarioAllocationData,
            scenarioPerformanceData, setScenarioPerformanceData,
            taxlotData, setTaxlotData,
            tradelistData, setTradelistData,
            yearCategories, toggleSummaryChartExpandView
          }}>
            <ScenarioTab aggId={selectedTab} />
          </ScenarioContext.Provider>
        )
    }
  }

  const disableLegacyView = () => {
    dispatch(disableLegacyViewReducer())
    toggleSummaryChartExpandView()
  }

  return (
    <>
      {
        expandSummaryChart
          ? (
            <Box sx={{
              background: 'white',
              height: 'calc(100vh - 48px - 15px - 20px)',
              width: '100%',
              position: 'relative'
            }}
            >
              <SummaryFlowChart data={summaryGraphData[selectedTab]?.data} transferDetails={summaryGraphData[selectedTab]?.data?.transferDetails} />
              <Button
                startIcon={<ArrowBackIosNewIcon sx={{ height: '14px', width: '14px' }} />}
                sx={{ position: 'absolute', top: 0, background: 'white', ':hover': { background: 'white' } }}
                onClick={disableLegacyView}
              >
                Dashboard
              </Button>
            </Box>
          )
          : <>
            <UmaContext.Provider value={{
              umaDetailsData, topCard, scenarioCardData
            }}>
              <UMADetail />
              <KPICards />
              <Card sx={{ mt: 2, border: 'none' }}>
                <CardContent>
                  <MainTabs
                    tabsList={mainTabs}
                    handleTabChange={handleTabChange}
                    selectedTab={selectedTab}
                    primary
                  />
                  <TabPanel
                    selectedTab={selectedTab}
                    tabList={mainTabs}
                    getTabContent={getTabContent}
                  />
                </CardContent>
              </Card>
            </UmaContext.Provider>
          </>
      }
    </>
  )
}

export default UMADashboard
