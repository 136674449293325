import { Grid, Paper, Typography } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import TotalAccountsIcon from '../../assets/images/icons/total-accounts.svg'
import ModelsIcon from '../../assets/images/icons/models.svg'
import CashDriftIcon from '../../assets/images/icons/cash-drift.svg'
import PortfolioValueIcon from '../../assets/images/icons/value-of-portfolio.svg'
import YTDLossHarvestedIcon from '../../assets/images/icons/ytd-loss-harvested.svg'
import StatsWidget from '../../components/StatsWidget'
import { formatCurrency } from '../../utils/FormateCurrenyInMilion'
import { formatNumbers, formatNumbersWithSymbol } from '../Report/components/dataProcess/DataProcess'

const Statistics = ({ isLoading, accountSummaryMetrics }) => {
  return (
    <Grid container spacing={{ xs: 2, lg: 3 }}>
      {/* <Grid item xs={6} md={4} lg={3}>
        {isLoading
          ? <Skeleton
              variant='rectangle'
              animation='wave'
              width={270}
              height={100}
            />
          : (
            <StatsWidget title='Total Accounts' stats={accountSummaryMetrics.totalAccount || 0} imgSrc={TotalAccountsIcon} />
            )}
      </Grid> */}
      {/* <Grid item xs={6} md={4} lg={6}>
        {isLoading
          ? <Skeleton
              variant='rectangle'
              animation='wave'
              width={270}
              height={100}
            />
          : (
            <StatsWidget title='Models' stats={accountSummaryMetrics.totalModel || 0} imgSrc={ModelsIcon} />
            )}
      </Grid> */}
      <Grid item xs={6} md={4} lg={3}>
        {isLoading
          ? <Skeleton
              variant='rectangle'
              animation='wave'
              height={100}
            />
          : (
            <Paper
              sx={{
                background: 'rgba(23, 92, 194, 0.08)',
                boxShadow: '0px 4px 4px rgba(116, 120, 141, 0.5)',
                borderRadius: '10px',
                padding: '18px 30px',
                '@media (max-width:600px)': {
                  padding: '15px'
                }
              }}
            >
              <Grid container>
                <Grid item container xs={12} justifyContent='space-between' wrap='nowrap' alignItems='center'>
                  <Grid item>
                    <Typography component='p' className='text-title'>Transition Accounts</Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      component='h1' sx={{
                        fontWeight: 700,
                        fontSize: '26px',
                        lineHeight: '34px',
                        color: '#34475A',
                        '@media (max-width:600px)': {
                          fontSize: '24px',
                          lineHeight: '28px'
                        }
                      }}
                    >{accountSummaryMetrics.transitionAccount || 0}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} justifyContent='space-between' wrap='nowrap' alignItems='center'>
                  <Grid item>
                    <Typography component='p' className='text-title'>Live Accounts</Typography>

                  </Grid>
                  <Grid item>
                    <Typography
                      component='h1' sx={{
                        fontWeight: 700,
                        fontSize: '26px',
                        lineHeight: '34px',
                        color: '#34475A',
                        '@media (max-width:600px)': {
                          fontSize: '24px',
                          lineHeight: '28px'
                        }
                      }}
                    >{accountSummaryMetrics.tradingAccount || 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            )}
      </Grid>
      <Grid item xs={6} md={4} lg={3}>
        {isLoading
          ? <Skeleton
              variant='rectangle'
              animation='wave'
              height={100}
            />
          : (
            <StatsWidget title='Value of AUM' stats={'$' + formatCurrency(accountSummaryMetrics.totalMarketValue || 0)} tooltipValue={formatNumbersWithSymbol(accountSummaryMetrics.totalMarketValue || 0, 2, '$')} imgSrc={PortfolioValueIcon} />
            )}
      </Grid>
      <Grid item xs={6} md={4} lg={3}>
        {isLoading
          ? <Skeleton
              variant='rectangle'
              animation='wave'
              height={100}
            />
          : (
            <StatsWidget title='Cash Drift' stats={`${(Number(accountSummaryMetrics.cashDrift || 0) * 100).toFixed(2)}%`} imgSrc={CashDriftIcon} />
            )}
      </Grid>
      <Grid item xs={6} md={4} lg={3}>
        {isLoading
          ? <Skeleton
              variant='rectangle'
              animation='wave'
              height={100}
            />
          : (
            <StatsWidget title='YTD Loss Harvested' stats={'$' + formatCurrency(accountSummaryMetrics.ytdLostHarvested || 0)} tooltipValue={formatNumbers(accountSummaryMetrics.ytdLostHarvested || 0, 2, '$')} imgSrc={YTDLossHarvestedIcon} />
            )}
      </Grid>

    </Grid>
  )
}

export default Statistics
