import { API } from 'aws-amplify'
import { useContext, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useAuth } from '../../../../contexts/AuthContext'
import { useErrorToast } from '../../../../hooks/useErrorToast'
import { assetAllocationChartDataProcess, scenarioPerformanceTabDataProcess, sectorAllocationDataProcess, taxlotDataProcess } from '../../AggregateDashboard/aggregateDataProcessing'
import { SecondaryTabs, TabPanel } from '../components'
import { summaryTabDataProcess } from './scenarioAggregateProcess'
import EnhancedTaxlot from './EnhancedTaxlot'
import EnhancedTradeList from './EnhancedTradeList'
import EnhancedAllocation from './EnhancedAllocation'
import PerformanceTab from './PerformanceTab'
import ScenarioSummary from './ScenarioSummary'
import { ScenarioContext } from '..'

const ScenarioTab = ({ aggId }) => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const scenarioData = useContext(ScenarioContext)
  const tabs = [
    { id: 'summary', label: 'Summary' },
    { id: 'allocation', label: 'Allocation' },
    { id: 'performance', label: 'Performance' },
    { id: 'trade-list', label: 'Trade List' },
    { id: 'taxlot', label: 'Taxlot' }
  ]
  const [selectedTab, setSelectedTab] = useState(tabs[0].id)

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue)
  }

  const fetchScenarioSummary = (aggId) => {
    scenarioData.setSummaryGraphData(prev => ({ ...prev, [aggId]: { ...prev[aggId], loading: true } }))
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/dashboard/agg/scenario/summary/${aggId}`
    )
      .then(res => {
        if (res?.data) {
          scenarioData.setSummaryGraphData(prev => ({ ...prev, [aggId]: { loading: false, data: summaryTabDataProcess(res?.data) } }))
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to fetch transition summary.')
      })
      .finally(() => {
        scenarioData.setSummaryGraphData(prev => ({ ...prev, [aggId]: { ...prev[aggId], loading: false } }))
      })
  }

  const fetchTaxlotData = (aggId, taxlot) => {
    scenarioData.setTaxlotData(prev => ({
      ...prev, [aggId]: {
        ...prev[aggId],
        loading: {
          ...prev[aggId]?.loading,
          ...(taxlot ? { rawData: true } : { groupData: true })
        }
      }
    }))
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/uma/taxlot/${aggId}`,
      taxlot
        ? {
          queryStringParameters: {
            taxlot
          }
        }
        : {}
    )
      .then((res) => {
        if (res.data) {
          const taxlotObj = taxlotDataProcess(res?.data, taxlot)
          scenarioData.setTaxlotData(prev => ({
            ...prev, [aggId]: {
              ...prev[aggId],
              data: {
                ...prev[aggId]?.data,
                ...(taxlot ? {
                  accountHierarchy: taxlotObj?.accountHierarchy, rawData: taxlotObj?.rawData
                } : { taxlot: taxlotObj?.taxlotData })
              }
            }
          }))
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to fetch taxLot data.')
      })
      .finally(() => {
        scenarioData.setTaxlotData(prev => ({
          ...prev,
          [aggId]: {
            ...prev[aggId],
            loading: {
              ...prev[aggId]?.loading,
              ...(taxlot ? { rawData: false } : { groupData: false })
            }
          }
        }))
      })
  }

  const fetchPerformanceTabData = (aggId) => {
    scenarioData.setScenarioPerformanceData(prev => ({ ...prev, [aggId]: { ...prev[aggId], loading: true } }))
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/dashboard/agg/scenario/transition-alpha/${aggId}`
    )
      .then(res => {
        if (res?.data?.length) {
          scenarioData.setScenarioPerformanceData(prev => ({
            ...prev, [aggId]: {
              loading: false,
              data: scenarioPerformanceTabDataProcess(res?.data)
            }
          }))
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to fetch performance data.')
      })
      .finally(() => {
        scenarioData.setScenarioPerformanceData(prev => ({ ...prev, [aggId]: { ...prev[aggId], loading: false } }))
      })
  }

  const fetchTradeList = (aggId) => {
    scenarioData.setTradelistData(prev => ({ ...prev, [aggId]: { ...prev[aggId], loading: true } }))
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/uma/trade-list/${aggId}`
    )
      .then((res) => {
        if (res.data) {
          scenarioData.setTradelistData(prev => ({ ...prev, [aggId]: { loading: false, data: res.data.map((obj, index) => ({ ...obj, id: index })) } }))
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to fetch trades.')
      })
      .finally(() => {
        scenarioData.setTradelistData(prev => ({ ...prev, [aggId]: { ...prev[aggId], loading: false } }))
      })
  }

  const fetchAllocationData = (aggId) => {
    scenarioData?.setScenarioAllocationData(prev => ({ ...prev, [aggId]: { ...prev[aggId], loading: true } }))
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/agg-allocation-data/${aggId}`
    )
      .then(res => {
        if (res?.data) {
          scenarioData.setScenarioAllocationData(prev => ({ ...prev, [aggId]: { loading: false, data: res.data } }))
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to fetch allocation data.')
      })
      .finally(() => {
        scenarioData?.setScenarioAllocationData(prev => ({ ...prev, [aggId]: { ...prev[aggId], loading: false } }))
      })
  }

  const fetchMarketCapFactorData = (aggId) => {
    scenarioData?.setScenarioMarketCapAndFactorData(prev => ({ ...prev, [aggId]: { ...prev[aggId], loading: true } }))
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/dashboard/marketcap-factor/${aggId}`
    )
      .then(res => {
        if (res?.data) {
          scenarioData.setScenarioMarketCapAndFactorData(prev => ({ ...prev, [aggId]: { loading: false, data: assetAllocationChartDataProcess(res, true) } }))
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to fetch market-cap and factor allocation data.')
      })
      .finally(() => {
        scenarioData?.setScenarioMarketCapAndFactorData(prev => ({ ...prev, [aggId]: { ...prev[aggId], loading: false } }))
      })
  }

  const fetchSectorData = (aggId) => {
    scenarioData?.setScenarioSectorData(prev => ({ ...prev, [aggId]: { ...prev[aggId], loading: true } }))
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/dashboard/sector/${aggId}`
    )
      .then(res => {
        if (res?.data) {
          scenarioData.setScenarioSectorData(prev => ({ ...prev, [aggId]: { loading: false, data: sectorAllocationDataProcess(res, true) } }))
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to sector allocation data.')
      })
      .finally(() => {
        scenarioData?.setScenarioSectorData(prev => ({ ...prev, [aggId]: { ...prev[aggId], loading: false } }))
      })
  }

  useEffect(() => {
    if (!scenarioData?.summaryGraphData[aggId]?.loading && !scenarioData?.summaryGraphData[aggId]?.data) {
      fetchScenarioSummary(aggId)
    }
    if (!scenarioData?.scenarioMarketCapAndFactorData[aggId]?.loading && !scenarioData?.scenarioMarketCapAndFactorData[aggId]?.data) {
      fetchMarketCapFactorData(aggId)
    }
    if (!scenarioData?.scenarioSectorData[aggId]?.loading && !scenarioData?.scenarioSectorData[aggId]?.data) {
      fetchSectorData(aggId)
    }
    if (!scenarioData?.scenarioAllocationData[aggId]?.loading && !scenarioData?.scenarioAllocationData[aggId]?.data) {
      fetchAllocationData(aggId)
    }
    if (!scenarioData?.tradelistData[aggId]?.loading && !scenarioData?.tradelistData[aggId]?.data) {
      fetchTradeList(aggId)
    }
    if (!scenarioData?.scenarioPerformanceData[aggId]?.loading && !scenarioData?.scenarioPerformanceData[aggId]?.data) {
      fetchPerformanceTabData(aggId)
    }
    if (!scenarioData?.taxlotData[aggId]?.loading && !scenarioData?.taxlotData[aggId]?.data) {
      fetchTaxlotData(aggId, true)
      fetchTaxlotData(aggId, false)
    }
  }, [aggId])

  const renderTabPanel = (item) => {
    switch (item) {
      case 'summary':
        return <ScenarioSummary
          aggId={aggId}
          toggleSummaryChartExpandView={scenarioData?.toggleSummaryChartExpandView}
        />
      case 'allocation':
        return <EnhancedAllocation
          umaType='SCENARIO_TRANSITION'
          data={scenarioData?.scenarioAllocationData[aggId]?.data}
          loading={scenarioData?.scenarioAllocationData[aggId]?.loading}
        />
      case 'performance':
        return <PerformanceTab
          data={scenarioData?.scenarioPerformanceData[aggId]?.data}
          loading={scenarioData?.scenarioPerformanceData[aggId]?.loading}
          insightData={scenarioData?.scenarioInsightsData}
          insightLoading={scenarioData?.scenarioInsightApiLoading}
        />
      case 'trade-list':
        return <EnhancedTradeList
          data={scenarioData?.tradelistData[aggId]?.data || []}
          loading={scenarioData?.tradelistData[aggId]?.loading}
        />
      case 'taxlot':
        return <EnhancedTaxlot aggId={aggId} />
      default:
        return <></>
    }
  }

  return (
    <Box sx={{ mt: 3 }}>
      <SecondaryTabs
        tabsList={tabs}
        handleTabChange={handleTabChange}
        selectedTab={selectedTab}
      />
      <TabPanel
        selectedTab={selectedTab}
        tabList={tabs}
        getTabContent={renderTabPanel}
      />
    </Box>
  )
}

export default ScenarioTab
