import { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import CloseIcon from '@mui/icons-material/Close'
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined'
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ErrorIcon from '@mui/icons-material/Error'
import { Box, IconButton, Skeleton, Typography, Tooltip, Menu, MenuItem, Chip } from '@mui/material'
import { LuRefreshCw } from 'react-icons/lu'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'

// Only shows loading for the content area
const LoadingSkeletonContent = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '450px',
        overflowY: 'auto'
      }}
    >
      <Box sx={{ mt: 2 }}>
        <Skeleton variant='rectangular' height={20} animation='wave' sx={{ mb: 2 }} />
        <Skeleton variant='rectangular' height={20} width='90%' animation='wave' sx={{ mb: 2 }} />
        <Skeleton variant='rectangular' height={20} width='95%' animation='wave' sx={{ mb: 2 }} />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Skeleton variant='rectangular' height={20} animation='wave' sx={{ mb: 2 }} />
        <Skeleton variant='rectangular' height={20} width='85%' animation='wave' sx={{ mb: 2 }} />
        <Skeleton variant='rectangular' height={20} width='92%' animation='wave' sx={{ mb: 2 }} />
      </Box>
    </Box>
  )
}

export const ViewSummary = ({ params, onClose }) => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [summaryData, setSummaryData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [summaryInProgress, setSummaryInProgress] = useState(false)
  const [formattedSummary, setFormattedSummary] = useState([])
  const [lineFeedback, setLineFeedback] = useState({})
  const [feedbackCounts, setFeedbackCounts] = useState({})
  const [anchorEl, setAnchorEl] = useState(null)
  const [summaryError, setSummaryError] = useState(false)

  // Fetch summary data on component mount
  useEffect(() => {
    fetchSummaryData()
  }, [])

  // Process summary data and set up feedback state when data changes
  useEffect(() => {
    if (summaryData) {
    // Check if the summary status indicates a failure
      const isErrorStatus = summaryData?.policySummaryRunStatus === 'FAILED'
      setSummaryError(isErrorStatus)

      // If there's summary text, format it
      if (summaryData?.summaryText) {
        const formattedSummaryText = formatSummaryText(summaryData?.summaryText)
        setFormattedSummary(formattedSummaryText)
        setLoading(false)

        // Only set up feedback state if not an error message
        if (!isErrorStatus) {
          const initialFeedback = {}
          const initialCounts = {}
          formattedSummaryText.forEach(line => { // set null as feedback and count as 0 initially
            if (line.content.trim()) {
              initialFeedback[line.lineIndex] = null
              initialCounts[line.lineIndex] = 0
            }
          })
          setLineFeedback(initialFeedback)
          setFeedbackCounts(initialCounts)
        }
      } else {
      // Handle case where there's no summary text
        setFormattedSummary([])
        setLoading(false)
      }
    }
  }, [summaryData])

  const fetchSummaryData = async () => {
    const payload = {
      accountOptMapIds: [params?.row?.accOptMapId]
    }

    setLoading(true)
    API.post('baseDataValidation', `data-validation/v1/${user?.userGroup}/policy-summary`, { body: payload })
      .then((response) => {
        if (!response?.data || response?.data?.length === 0) {
          setSummaryData({ summaryText: '', policySummaryRunStatus: '' })
        } else {
          setSummaryData(response?.data[0])
          // If we received data, we can consider any in-progress summary as completed
          setSummaryInProgress(false)
        }
        setLoading(false)
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load summary data.', true)
        setLoading(false)
      })
  }

  const sendFeedback = async (lineIndex, isPositive, lineContent) => {
    try {
      // Check if we've already reached the maximum feedback limit
      if (feedbackCounts[lineIndex] >= 2) {
        return false
      }

      const payload = {
        accountId: params?.row?.accountId,
        componentId: lineIndex.toString(),
        componentText: lineContent,
        accountOptMapSummaryId: summaryData?.accountOptMapSummaryStatusId,
        vote: isPositive ? '1' : '0' // 1 for thumbs up, 0 for thumbs down
      }

      return API.post(
        'baseDataValidation',
        `data-validation/v1/${user?.userGroup}/policy-summary-feedback`,
        { body: payload }
      )
        .then((response) => {
          // Update feedback count based on the API response
          setFeedbackCounts(prev => {
            // Use API response count if available, otherwise increment locally
            let newCount
            if (response?.data && response?.data?.length > 0) {
              newCount = response?.data[0]?.feedbackCount || (prev[lineIndex] || 0) + 1
            } else {
              newCount = (prev[lineIndex] || 0) + 1
            }

            return {
              ...prev,
              [lineIndex]: newCount
            }
          })

          // Only update UI state if API call was successful
          setLineFeedback(prev => ({
            ...prev,
            [lineIndex]: isPositive
          }))

          return true
        })
        .catch((error) => {
          showError(error, false, {}, 'Failed to submit feedback.', true)
          return false
        })
    } catch (error) {
      showError(error, false, {}, 'Failed to submit feedback.', true)
      return false
    }
  }

  const handleFeedback = async (lineIndex, isPositive) => {
    const line = formattedSummary?.find(line => line?.lineIndex === lineIndex)
    if (!line) return

    // Send feedback to API
    sendFeedback(lineIndex, isPositive, line?.content)
  }

  const summaryMenuOptions = [
    { label: 'Reset Summary Feedbacks' },
    { label: 'Re-run Summarization' }
  ]

  const resetFeedback = () => {
    API.del('baseDataValidation', `data-validation/v1/${user?.userGroup}/policy-summary-feedback`, { body: {
      accountId: params?.row?.accountId,
      scenarioId: params?.row?.scenarioId
    }})
      .finally(() => {
        // Keep the existing summary data but reset the feedback state
        showSuccess('Feedback has been reset successfully.')
        setLineFeedback({})
        setFeedbackCounts({})
      })
  }

  const rerunSummary = () => {
    // Don't set loading to true, as we want to keep showing the existing data
    // setLoading(true)
    
    // Set summary in progress to true to show the chip
    setSummaryInProgress(true)
    
    const payload = {
      accOptMapId: [params?.row?.accOptMapId]
    }

    API.post('baseDataValidation', `data-validation/v1/${user?.userGroup}/policy-summary-rerun`, { body: payload })
      .then((response) => {
        if (response?.success) {
          setSummaryInProgress(true)
        } else {
          showError(null, false, {}, 'Failed to trigger summarization re-run.', true)
          setSummaryInProgress(false)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to re-run summarization.', true)
        setSummaryInProgress(false)
      })
  }

  const handleSummaryMenuItemClick = (option) => {
    if (option === 'Reset Summary Feedbacks') {
      setLoading(true)
      resetFeedback()
      fetchSummaryData()
    }
    if (option === 'Re-run Summarization') {
      rerunSummary() 
    }
    setAnchorEl(null)
  }

  const formatSummaryText = (summaryText) => {
    const lines = summaryText.split('\n')

    return lines.map((line, index) => ({
      lineIndex: index,
      content: line.trim(),
      type: line.includes('**✅')
        ? 'success'
        : line.includes('**�')
          ? 'error'
          : line.startsWith('**') || line.includes('. **')
            ? 'heading'
            : 'normal'
    }))
  }

  const renderMarkdownLine = (line) => {
    if (!line.content.trim()) return null

    let lineStyle = { fontFamily: 'Open Sans' }
    let variant = 'body2'

    if (line.type === 'success') {
      lineStyle = { ...lineStyle, color: '#3BBFA3' }
    } else if (line.type === 'error') {
      lineStyle = { ...lineStyle, color: '#D32F2F' }
    } else if (line.type === 'heading') {
      variant = 'subtitle1'
      lineStyle = { ...lineStyle, fontWeight: 500 }
    }

    // Process markdown-style content
    const processedContent = line.content
      .replace(/\*\*(.*?)\*\*/g, (_, content) => {
        if (content.includes('✅')) {
          return `<strong style="color: #3BBFA3">${content}</strong>`
        } else if (content.includes('�')) {
          return `<strong style="color: #D32F2F">${content.replace('�', '❌')}</strong>`
        }
        return `<strong>${content}</strong>`
      })

    const isNumberedLine = /^\d+\.\s/.test(line.content)
    const maxFeedbackReached = feedbackCounts[line.lineIndex] >= 2

    return (
      <Box
        key={line.lineIndex}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          mt: line.content.startsWith('-') ? 0.5 : (isNumberedLine ? 0.5 : 1),
          position: 'relative',
          '&:hover .feedback-icons': {
            opacity: maxFeedbackReached ? 0.5 : 1
          }
        }}
      >
        <Typography
          variant={variant}
          style={lineStyle}
          dangerouslySetInnerHTML={{ __html: processedContent }}
          data-line-index={line.lineIndex}
          sx={{
            flexGrow: 1,
            ml: line.content.startsWith('-') ? 2 : 0,
            fontSize: '0.85rem',
            wordBreak: 'break-word'
          }}
        />

        {/* Feedback icons on the left - only show if not in error state */}
        {!summaryError && (
          <Box
            className='feedback-icons'
            sx={{
              display: 'flex',
              alignItems: 'center',
              mr: 1,
              opacity: lineFeedback[line.lineIndex] !== null && lineFeedback[line.lineIndex] !== undefined ? 1 : 0,
              transition: 'opacity 0.2s ease',
              pointerEvents: maxFeedbackReached ? 'none' : 'auto'
            }}
          >
            <Tooltip title={maxFeedbackReached ? 'Maximum feedback limit reached' : 'Helpful'}>
              <span> {/* Wrap in span to allow tooltip on disabled button */}
                <IconButton
                  size='small'
                  onClick={() => handleFeedback(line.lineIndex, true)}
                  color={lineFeedback[line.lineIndex] === true ? 'primary' : 'default'}
                  sx={{
                    p: 0.5,
                    opacity: maxFeedbackReached ? (lineFeedback[line.lineIndex] === true ? 1 : 0.5) : 1,
                    cursor: maxFeedbackReached ? 'not-allowed' : 'pointer'
                  }}
                  disabled={maxFeedbackReached}
                >
                  <ThumbUpAltOutlinedIcon fontSize='small' />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title={maxFeedbackReached ? 'Maximum feedback limit reached' : 'Not helpful'}>
              <span> {/* Wrap in span to allow tooltip on disabled button */}
                <IconButton
                  size='small'
                  onClick={() => handleFeedback(line.lineIndex, false)}
                  color={lineFeedback[line.lineIndex] === false ? 'error' : 'default'}
                  sx={{
                    p: 0.5,
                    opacity: maxFeedbackReached ? (lineFeedback[line.lineIndex] === false ? 1 : 0.5) : 1,
                    cursor: maxFeedbackReached ? 'not-allowed' : 'pointer'
                  }}
                  disabled={maxFeedbackReached}
                >
                  <ThumbDownAltOutlinedIcon fontSize='small' />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        )}
      </Box>
    )
  }

  // Render the component with a fixed header
  return (
    <Box sx={{
      height: 'min(100vh, 600px)',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }}>
      {/* Fixed Header - Always visible */}
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'sticky',
        top: 0,
        zIndex: 10
      }}>
        <Typography
          variant='h5'
          style={{ width: '100%', fontWeight: 400, fontFamily: 'Open Sans', color: '#212529' }}
          sx={{
            fontSize: '1.5rem',
            wordBreak: 'break-word'
          }}
        >
          Summary
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {/* Status chip for when summarization is in progress */}
          {summaryInProgress && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Chip
                variant='outlined'
                sx={{
                  backgroundColor: '#F9F4D8',
                  color: '#947330',
                  borderRadius: '5px',
                  border: '1px solid #947330',
                  fontSize: '12px',
                  fontWeight: 600,
                  width: '180px',
                  maxWidth: '500px',
                  height: 'auto',
                  minHeight: '32px',
                  '& .MuiChip-label': { whiteSpace: 'break-spaces' }
                }}
                icon={<ErrorIcon sx={{ color: '#947330 !important', height: '18px', width: '18px' }} />}
                label="Summary is in progress"
              />
              <Tooltip title="Refresh summary">
                <IconButton onClick={fetchSummaryData} size="small">
                  <LuRefreshCw style={{ height: 20, width: 20 }} />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          <IconButton
            sx={{
              padding: 1,
              borderRadius: '10px',
              border: '2px solid #dee2e6',
            }}
            aria-label='more'
            aria-haspopup='true'
            onClick={(event) => {
              setAnchorEl(event.currentTarget)
            }}
          >
            <MoreVertIcon fontSize='small' sx={{ color: '#74788D' }} />
          </IconButton>
          <Menu
            id='long-menu'
            elevation={4}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {summaryMenuOptions?.map((option) => (
              <MenuItem key={option?.label} onClick={() => handleSummaryMenuItemClick(option?.label)}>
                {option?.label}
              </MenuItem>
            ))}
          </Menu>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Scrollable Content Area */}
      <Box sx={{
        flexGrow: 1,
        overflowY: 'auto',
        width: '100%',
        height: '100%'
      }}>
        {loading ? (
          <LoadingSkeletonContent />
        ) : (
          <>
            {summaryData?.summaryText ? (
              <Box sx={{
                width: '100%',
                height: 'auto'
              }}>
                {formattedSummary.map(line => renderMarkdownLine(line))}
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                  p: 4
                }}
              >
                <Typography variant='body1'>
                  No summary data found. Please try again later
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
      
      {/* Fixed Footer - Always visible */}
      <Box sx={{ 
        p: 1, 
        position: 'sticky',
        bottom: 0,
        zIndex: 10
      }}>
        <Typography variant='caption' sx={{ fontStyle: 'italic' }}>*M.A.I.A is an experimental engine by ARIS. It can make mistakes, consider checking important information</Typography>
      </Box>
    </Box>
  )
}