import { Tooltip } from '@mui/material'

const statusMapping = {
  APPROVED: 'green',
  REJECTED: 'red'
}

const ApprovalRadioButton = ({ option, levelIndex, dIndex, handleApprovalRadioClick, enabledFromCheckAccess, isDIRadio = false }) => {
  const id = `approve${levelIndex + 1}`
  if (option?.tradeApprovalVisibility === 0) {
    return (
      <Tooltip title='Maintain bill date for trading'>
        <span>
          <input
            type='radio'
            id={id}
            name={id + dIndex}
            disabled
          />
        </span>
      </Tooltip>
    )
  }
  if (!(option?.approvalDetails || [])[levelIndex]) {
    return (
      <span>
        <input
          type='radio'
          id={id}
          name={id + dIndex}
          disabled
        />
      </span>
    )
  }
  const { approvalStatus, optApprovalId, isSelected, approvalLevel, allowApproval, aclAccessGranted } = option?.approvalDetails ? option?.approvalDetails[levelIndex] : {}
  return (
    <span>
      <input
        type='radio'
        id={id}
        name={id + dIndex + ((isDIRadio && approvalStatus === 'APPROVED') ? option.DILinkType : '')}
        value={optApprovalId}
        style={approvalStatus && { accentColor: statusMapping[approvalStatus] }}
        checked={isSelected || approvalStatus === 'APPROVED'}
        disabled={approvalStatus === 'REJECTED' || (approvalLevel === 2 && approvalStatus === null && !allowApproval) || enabledFromCheckAccess === false || (aclAccessGranted === false && approvalStatus !== 'APPROVED')}
        readOnly
        onClick={(e) =>
          !approvalStatus && handleApprovalRadioClick(
            e,
            optApprovalId,
            dIndex,
            option.scenarioId,
            levelIndex,
            option.DILinkType
          )}
      />
    </span>
  )
}
export default ApprovalRadioButton
