import { Box } from '@mui/material'

const TabPanel = ({ selectedTab, tabList, getTabContent }) => {
  return (
    tabList.map((item, index) => (
      <Box
        key={index}
        hidden={selectedTab !== item.id}
        id={`tabpanel-${item.id}`}
      >
        {
          selectedTab === item.id && (
            getTabContent(selectedTab)
          )
        }
      </Box>
    ))
  )
}

export default TabPanel