import { FormatDateAPI, FormatPayload } from '../components/FormatAPIRequest'
import { UpdateAPI } from '../components/Actions'
import { getUserRole } from '../../../utils/getUserRole'

export const UpdateAggregateData = async (updateRow, tabIndex, oldRow) => {
  switch (tabIndex) {
    case 'aggregate-master':
      return await UpdateAggregateMaster(updateRow)
    case 'properties':
      return await UpdateAggregateProperties(updateRow)
    case 'external-gl':
      return await UpdateAggregateExternalGL(updateRow)
    case 'parameters':
      return await UpdateAggregateParameters(updateRow)
    case 'tax-rate':
      return await UpdateAggregateTaxRate(updateRow, oldRow)
    case 'account-map':
      return await UpdateAggregateAccountMap(updateRow)
    default:
      return {}
  }
}

const UpdateAggregateMaster = async (updateRow) => {
  const userRole = getUserRole()
  const startDateValue = updateRow.startDate ? FormatDateAPI(updateRow.startDate) : null
  const endDateValue = updateRow.endDate ? FormatDateAPI(updateRow.endDate) : null

  let payload = {
    aggName: updateRow?.aggName,
    aggEmail: updateRow?.aggEmail,
    aggTypeId: updateRow?.aggTypeId,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseUriAggregateMaintenance', 
    `data-maintenance/v1/${userRole}/aggregate/${updateRow?.aggId}/master`, 
    payload)
}

const UpdateAggregateProperties = async (updateRow) => {
  const userRole = getUserRole()
  const startDateValue = updateRow.startDate ? FormatDateAPI(updateRow.startDate) : null
  const endDateValue = updateRow.endDate ? FormatDateAPI(updateRow.endDate) : null
  let payload = {
    aggId: updateRow?.aggId,
    propertyCode: updateRow?.propertyCode.toUpperCase(),
    propertyValue: updateRow?.propertyValue,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseUriAggregateMaintenance',
    `data-maintenance/v1/${userRole}/aggregate/properties/${updateRow?.aggPropertiesId}`,
    payload)
}

const UpdateAggregateExternalGL = async (updateRow) => {
  const userRole = getUserRole()
  const startDateValue = updateRow.startDate ? FormatDateAPI(updateRow.startDate) : null
  const endDateValue = updateRow.endDate ? FormatDateAPI(updateRow.endDate) : null
  
  let payload = {
    taxYear: updateRow?.taxYear,
    ytdRealLongGains: updateRow.ytdRealLongGains,
    ytdRealShortGains: updateRow.ytdRealShortGains,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseUriAggregateMaintenance',
    `data-maintenance/v1/${userRole}/aggregate/external-gain-loss/${updateRow.aggExtGlId}`,
    payload
  )
}

const UpdateAggregateParameters = async (updateRow) => {
  const userRole = getUserRole()

  let payload = {
    aggType: updateRow?.aggType?.toUpperCase(),
    sellLogicId: updateRow?.sellLogicId,
    custodianId: updateRow?.custodianId,
    maxShortTermGain: updateRow?.maxShortTermGain,
    maxLongTermGain: updateRow?.maxLongTermGain,
    brokerId: updateRow?.brokerId,
    cashInstrId: updateRow?.cashInstrId,
    pctCashCushion: updateRow?.pctCashCushion
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseUriAggregateMaintenance',
    `data-maintenance/v1/${userRole}/aggregate/${updateRow?.aggId}/parameter`,
    payload
  )
}

const UpdateAggregateTaxRate = async (updateRow, oldRow) => {
  const userRole = getUserRole()
  let payload = {
    currentTaxYear: updateRow?.currentTaxYear,
    taxSensitivity: updateRow?.taxSensitivity.toUpperCase(),
    stateId: updateRow?.stateId,
    fedLongTax: updateRow?.fedLongTax,
    fedMedTax: updateRow?.fedMedTax,
    fedShortTax: updateRow?.fedShortTax,
    stateLongTax: updateRow?.stateLongTax,
    stateMedTax: updateRow?.stateMedTax,
    stateShortTax: updateRow?.stateShortTax
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseUriAggregateMaintenance',
    `data-maintenance/v1/${userRole}/aggregate/${updateRow?.aggId}/tax-rates/${oldRow?.currentTaxYear}`,
    payload
  )
}

const UpdateAggregateAccountMap = async (updateRow) => {
  const userRole = getUserRole()
  const startDateValue = updateRow.startDate ? FormatDateAPI(updateRow.startDate) : null
  const endDateValue = updateRow.endDate ? FormatDateAPI(updateRow.endDate) : null

  let payload = {
    isDefault: updateRow?.isDefault,
    cashAllocWeight: updateRow?.cashAllocWeight,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseUriAggregateMaintenance', 
    `data-maintenance/v1/${userRole}/aggregate/agg-account-map/${updateRow?.aggAccountMapId}`, 
    payload)
}
