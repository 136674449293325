import { useCallback, useEffect, useState } from 'react'
import { Box, IconButton, Menu, MenuItem, Modal, Tooltip, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import MoreVert from '@mui/icons-material/MoreVert'
import ExpandMore from '@mui/icons-material/ExpandMore'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import { randomId } from '@mui/x-data-grid-generator'
import { useGridApiContext, DataGridPro, gridClasses, gridFilteredDescendantCountLookupSelector, useGridApiRef, useGridSelector } from '@mui/x-data-grid-pro'
import { useAuth } from '../../../../contexts/AuthContext'
import { moduleConfig } from '../../../../contexts/data'
import { ACCESS_LEVEL } from '../../../../contstants/constants'
import { formatCurrency } from '../../../../utils/FormateCurrenyInMilion'
import { fixConnectivityStatusColor } from '../../../../utils/getFixConnectivityStatusColor'
import { createHierarchy } from '../../../../utils/groupData'
import DownloadTradeLogsCell from '../DownloadTradeLogsCell'

const detailsPopupStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: '4px',
  outline: 'none'
}

const CustomGridTreeDataGroupingCell = (props) => {
  const { id, field, rowNode } = props
  const apiRef = useGridApiContext()
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  )
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

  const handleClick = (event) => {
    if (rowNode.type !== 'group') {
      return
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  return (
    <>
      {filteredDescendantCount > 0 ? (
        <>
          <IconButton
            onClick={handleClick}
            size='small'
            tabIndex={-1}
            aria-label={rowNode.childrenExpanded ? 'Close' : 'Open'}
          >
            <ExpandMore
              sx={{
                transform: `rotateZ(${rowNode.childrenExpanded ? 360 : 270}deg)`,
                transition: (theme) =>
                  theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                  }),
              }}
              fontSize='inherit'
            />
          </IconButton>
          <span>
            {props?.row?.localSymbol}
          </span>
        </>
      ) : (
          <span style={{ marginLeft: filteredDescendantCount === 0 && rowNode.depth === 0 ? '28px' : (rowNode.depth * 28) + 12 }}>{props?.row?.localSymbol}</span>
        )}
    </>
  )
}

const hierarchyToArray = (hierarchy, blockTradeRows) => {
  Object.keys(hierarchy).forEach((key) => {
      if (hierarchy[key]?.length > 1 && key !== 'null') {
        blockTradeRows.push({ ...hierarchy[key][0], key, hierarchy: [key], parentRow: true })
      }
      hierarchy[key].forEach(object => {
        const id = randomId()
        blockTradeRows.push({ ...object, id, hierarchy: (hierarchy[key]?.length === 1 || key === 'null') ? [object.instrId + id] : [key, object.instrId + id] })
      })
  })
}

const processBlockTradeRows = (rows) => {
  const blockTradeRows = []
  const hierarchy = createHierarchy(rows, 0, ['blockTradeId'])
  hierarchyToArray(hierarchy, blockTradeRows)
  return blockTradeRows
}

let expansionLookupBlockTrade = {}

const StepDetails = ({ showDetailsPopup, closeDetailsPopup, title, stats, rows, refreshTrades, activeStepDetails = null, stepsChanged = false, isBlockTrade = false }) => {
  const apiRef = useGridApiRef()
  const { checkAccess } = useAuth()
  const [openCopyTooltip, setOpenCopyTooltip] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [tradeRows, setTradeRows] = useState([])

  useEffect(() => {
    if (apiRef?.current?.subscribeEvent) {
      apiRef?.current?.subscribeEvent('rowExpansionChange', (node) => {
        if (node?.groupingKey) {
          expansionLookupBlockTrade[node?.groupingKey] = node?.childrenExpanded
        }
      })
    }
  }, [apiRef, tradeRows, rows])

  // clear expansion lookup on popup unmount
  useEffect(() => {
    return () => {
      expansionLookupBlockTrade = {}
    }
  }, [])

  const isGroupExpandedByDefault = useCallback(
    (node) => {
      return node?.groupingKey && !!expansionLookupBlockTrade?.[node?.groupingKey]
    },
    []
  )

  useEffect(() => {
    // combine rows based on block trade IDs for sell trades
    if (isBlockTrade) {
      setTradeRows(processBlockTradeRows(rows))
    } else {
      setTradeRows(rows)
    }
  }, [rows, isBlockTrade, activeStepDetails?.stepCode])

  const exportCsv = () => {
    const fileName = title?.replaceAll(' ', '-')
    let dataCsv = null

    const headers = ['Local Symbol', 'Side', 'Quantities', 'Market Value', 'Pre Trade Cash', 'Post Trade Cash', 'Trade Status', ...(isBlockTrade ? ['Allocation Status'] : [])]
    dataCsv = [headers.join(',')]

    for (let i = 0; i < rows?.length; i++) {
      const { localSymbol, trdCode, trdQty, propMarketValue, preTradeCash, postTradeCash, tradeStatusCode, allocStatusCode } = rows[i]
      dataCsv.push([localSymbol || '', trdCode || '', trdQty, propMarketValue, preTradeCash, postTradeCash, tradeStatusCode || '', ...(isBlockTrade ? [allocStatusCode || ''] : [])].join(','))
    }

    const csvBlob = new Blob([dataCsv.join('\n')], { type: 'text/csv' })
    const csvUrl = URL.createObjectURL(csvBlob)

    // Create a temporary link and trigger the download
    const tempLink = document.createElement('a')
    tempLink.href = csvUrl
    tempLink.setAttribute('download', `${fileName?.toUpperCase()}.csv`)
    tempLink.click()

    // Clean up the URL object
    URL.revokeObjectURL(csvUrl)
    handleMoreOptionClose()
  }

  const handleMoreOptionClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMoreOptionClose = () => {
    setAnchorEl(null)
  }

  const viewResourceIdAccess = checkAccess(
    moduleConfig.TRADE,
    ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.TRADE_HISTORY, component_name: moduleConfig.SHOW_RESOURCE_ID }
  )

  const downloadTradeLogAccess = checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, {
    subModuleName: moduleConfig.TRADE_HISTORY,
    component_name: moduleConfig.VIEW_TRADE_EXECUTION_LOGS
  })

  const stepDetailsColumns = [
    ...((isBlockTrade) ? [] : [{ field: 'localSymbol', headerName: 'Local Symbol', flex: 1 }]),
    { field: 'trdCode', headerName: 'Side', flex: 1 },
    {
      field: 'trdQty',
      headerName: 'Quantities',
      type: 'number',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => {
        const columnValue = params?.row?.parentRow ? params?.row?.blockTradeQty : params?.row?.trdQty
        return columnValue !== null && columnValue !== undefined ? columnValue : null
      },
      renderCell: (params) => {
        return params?.value !== null ? params?.value : ''
      }
    },
    {
      field: 'propMarketValue',
      headerName: 'Market Value',
      type: 'number',
      align: 'right',
      flex: 1,
      valueGetter: (params) => {
        const columnValue = params?.row?.parentRow ? params?.row?.blockMarketValue : params?.row?.propMarketValue
        return columnValue !== null && columnValue !== undefined ? columnValue.toFixed(0) : null
      },
      renderCell: (params) => {
        const columnValue = params?.row?.parentRow ? params?.row?.blockMarketValue : params?.row?.propMarketValue
        return columnValue !== undefined
          ? columnValue !== null
            ? columnValue < 0
              ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
              : '$' + Number(params?.value)?.toLocaleString()
            : ''
          : ''
      }
    },
    {
      field: 'preTradeCash',
      headerName: 'Pre Trade Cash',
      type: 'number',
      align: 'right',
      flex: 1,
      valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
      renderCell: (params) => (
        params?.row?.preTradeCash !== undefined
          ? params?.row?.preTradeCash !== null
            ? params?.row?.preTradeCash < 0
              ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
              : '$' + Number(params?.value)?.toLocaleString()
            : ''
          : ''
      )
    },
    {
      field: 'postTradeCash',
      headerName: 'Post Trade Cash',
      type: 'number',
      align: 'right',
      flex: 1,
      valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
      renderCell: (params) => (
        params?.row?.postTradeCash !== undefined
          ? params?.row?.postTradeCash !== null
            ? params?.row?.postTradeCash < 0
              ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
              : '$' + Number(params?.value)?.toLocaleString()
            : ''
          : ''
      )
    },
    {
      field: 'tradedShares',
      headerName: 'Traded Shares',
      type: 'number',
      align: 'right',
      flex: 1,
      valueGetter: (params) => {
        const columnValue = params?.row?.parentRow ? params?.row?.blockTradedShares : params?.row?.tradedShares
        return columnValue !== null && columnValue !== undefined ? parseFloat(columnValue) : null
      },
      renderCell: (params) => {
        return params?.value !== null ? params?.value : ''
      }
    },
    {
      field: 'tradedPrice',
      headerName: 'Traded Price',
      type: 'number',
      align: 'right',
      flex: 1,
      valueGetter: (params) => {
        const columnValue = params?.row?.parentRow ? params?.row?.blockTradedPrice : params?.row?.tradedPrice
        return columnValue !== null && columnValue !== undefined ? parseFloat(columnValue) : null
      },
      renderCell: (params) => {
        const columnValue = params?.row?.parentRow ? params?.row?.blockTradedPrice : params?.row?.tradedPrice
        return !isNaN(columnValue) && columnValue !== null
          ? columnValue < 0
            ? `-$${formatCurrency(columnValue)}`
            : `$${formatCurrency(columnValue)}`
          : ''
      }
    },
    {
      field: 'tradeStatusCode',
      headerName: 'Trade Status',
      width: 200,
      valueGetter: (params) => {
        return params?.row?.parentRow ? params?.row?.blockTradeStatus : params?.row?.tradeStatusCode
      },
      renderCell: (params) => {
        const data = params?.value
        return (
          <Box sx={{ color: fixConnectivityStatusColor(data) }}>
            {data || ''}
          </Box>
        )
      }
    },
    ...(isBlockTrade ? [{
      field: 'allocStatusCode',
      headerName: 'Allocation Status',
      width: 250,
      valueGetter: (params) => {
        return params?.row?.parentRow ? params?.row?.blockAllocStatus : params?.row?.allocStatusCode
      },
      renderCell: (params) => {
        const data = params?.value
        return (
          <Box sx={{ color: fixConnectivityStatusColor(data) }}>
            {data || ''}
          </Box>
        )
      }
    }] : []),
    ...(downloadTradeLogAccess
      ? [{
          field: 'actions',
          headerName: 'Trade Logs',
          width: 50,
          renderCell: (params) => <DownloadTradeLogsCell {...params} isBlockTrade={isBlockTrade} />
        }]
      : []
    )
  ]

  const handleCopyResourceClick = () => {
    navigator.clipboard.writeText(activeStepDetails?.awsRsrcExecId)
    setOpenCopyTooltip(true)
    setTimeout(() => {
      setOpenCopyTooltip(false)
      handleMoreOptionClose()
    }, 1000)
  }

  return (
    <>
      <Modal
        open={showDetailsPopup}
        onClose={() => closeDetailsPopup(false)}
      >
        <Box sx={detailsPopupStyle}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
            <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
              <Typography variant='h6' sx={{ ml: '5px', mr: '5px', fontWeight: 400, color: '#002A59' }}>{title}</Typography>
              <Typography variant='span' sx={{ fontWeight: 400, color: '#34475A' }}>({stats})</Typography>
            </Box>
            <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
              <Box sx={{ display: 'inline-flex', alignItems: 'center', mr: '16px' }}>
                <Typography color='#74788d'>Pre Trade Cash:</Typography>
                <Tooltip title={activeStepDetails?.preTradeCashSummary !== null && activeStepDetails?.preTradeCashSummary !== undefined ? activeStepDetails?.preTradeCashSummary : ''}>
                  <Typography color={activeStepDetails?.preTradeCashSummary !== null && activeStepDetails?.preTradeCashSummary !== undefined && activeStepDetails?.preTradeCashSummary < 0 ? 'red' : '#34475a'} fontWeight='500' ml={1}>{activeStepDetails?.preTradeCashSummary !== null && activeStepDetails?.preTradeCashSummary !== undefined ? activeStepDetails?.preTradeCashSummary < 0 ? `-$${formatCurrency(Math.abs(activeStepDetails?.preTradeCashSummary))}` : `$${formatCurrency(activeStepDetails?.preTradeCashSummary)}` : '-'}</Typography>
                </Tooltip>
              </Box>
              <Box sx={{ display: 'inline-flex', alignItems: 'center', mr: '10px' }}>
                <Typography color='#74788d'>Post Trade Cash:</Typography>
                <Tooltip title={activeStepDetails?.postTradeCashSummary !== null && activeStepDetails?.postTradeCashSummary !== undefined ? activeStepDetails?.postTradeCashSummary : ''}>
                  <Typography color={activeStepDetails?.postTradeCashSummary !== null && activeStepDetails?.postTradeCashSummary !== undefined && activeStepDetails?.postTradeCashSummary < 0 ? 'red' : '#34475a'} fontWeight='500' ml={1}>{activeStepDetails?.postTradeCashSummary !== null && activeStepDetails?.postTradeCashSummary !== undefined ? activeStepDetails?.postTradeCashSummary < 0 ? `-$${formatCurrency(Math.abs(activeStepDetails?.postTradeCashSummary))}` : `$${formatCurrency(activeStepDetails?.postTradeCashSummary)}` : '-'}</Typography>
                </Tooltip>
              </Box>
              {
                stepsChanged ? (
                  <IconButton
                    aria-label='refresh'
                    size='small'
                    onClick={refreshTrades}
                  >
                    <RefreshOutlinedIcon />
                  </IconButton>
                ) : ''
              }
              <IconButton
                aria-label='more'
                size='small'
                onClick={handleMoreOptionClick}
              >
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMoreOptionClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <MenuItem onClick={() => exportCsv()}>
                  Export CSV
                </MenuItem>
                {
                  viewResourceIdAccess
                    ? (
                      <MenuItem onClick={handleCopyResourceClick}>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true
                          }}
                          onClose={() => setOpenCopyTooltip(false)}
                          open={openCopyTooltip}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title='Execution ID copied'
                        >
                          Copy Execution ID
                        </Tooltip>
                      </MenuItem>
                    )
                    : ''
                }
              </Menu>
              <IconButton onClick={() => closeDetailsPopup(false)} size='small'>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <DataGridPro
            autoHeight
            density='compact'
            apiRef={apiRef}
            rows={tradeRows || []}
            columns={stepDetailsColumns}
            getRowId={(row) => row?.id}
            pagination
            rowSelection={false}
            pageSizeOptions={[15, 25, 50, 75, 100]}
            {
            ...isBlockTrade
              ? {
                  treeData: true,
                  getTreeDataPath: (row) => row.hierarchy,
                  isGroupExpandedByDefault,
                  groupingColDef: {
                    headerName: 'Local Symbol',
                    filterable: true,
                    sortable: true,
                    disableColumnMenu: false,
                    cellClassName: 'name-column-row',
                    headerClassName: 'name-column-header',
                    valueGetter: (params) => params?.row?.localSymbol,
                    renderCell: (params) => (
                      <CustomGridTreeDataGroupingCell
                        {...params}
                      />
                    )
                  }
                }
              : {}
            }
            initialState={{
              pagination: { paginationModel: { pageSize: 15 } }
            }}
            sx={(theme) => ({
              '.name-column-row': {
                paddingLeft: 0
              },
              '.name-column-header': {
                paddingLeft: '28px'
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              [`.${gridClasses.main}`]: {
                height: 'calc(100vh - 16px - 45px - 16px - 39px - 53px)'
              },
              [`.${gridClasses.columnHeaders}`]: {
                position: 'sticky',
                backgroundColor: theme.palette.background.paper,
                top: 0,
                zIndex: 1
              },
              [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                color: '#74788d',
                fontWeight: 600
              },
              [`.${gridClasses.virtualScroller}`]: {
                overflowY: 'auto !important',
                scrollbarGutter: 'stable',
                scrollbarWidth: 'none'
              }
            })}
          />
        </Box>
      </Modal>
    </>
  )
}

// const StepDetailsWithConfirmation = ({ showDetailsPopup, closeDetailsPopup, title, columns }) => {
//   return (
//     <Modal
//       open={showDetailsPopup}
//       onClose={() => closeDetailsPopup(false)}
//     >
//       <Box sx={detailsPopupStyle}>
//         <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
//           <Typography variant='h6' sx={{ ml: '5px', mr: '5px', fontWeight: 400, color: '#002A59' }}>{title}</Typography>
//           <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
//             <Button variant='text'>Export CSV</Button>
//             <IconButton onClick={() => closeDetailsPopup(false)}>
//               <CloseIcon />
//             </IconButton>
//           </Box>
//         </Box>
//         <DataGridPro
//           autoHeight
//           density='compact'
//           rows={[]}
//           columns={columns}
//           getRowId={(row) => row?.id}
//           pagination
//           pageSizeOptions={[10]}
//           initialState={{
//             pagination: { paginationModel: { pageSize: 10 } },
//           }}
//           sx={(theme) => ({
//             '& .MuiDataGrid-cell:focus': {
//               outline: 'none'
//             },
//             [`.${gridClasses.main}`]: {
//               // height: 'min(100vh - 16px - 45px - 16px - 53px, 600px - 16px - 45px - 16px - 40px - 53px)',
//             },
//             [`.${gridClasses.columnHeaders}`]: {
//               position: 'sticky',
//               backgroundColor: theme.palette.background.paper,
//               top: 0,
//               zIndex: 1,
//             },
//             [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
//               color: '#74788d',
//               fontWeight: 600
//             },
//             [`.${gridClasses.virtualScroller}`]: {
//               overflowY: 'auto !important',
//               scrollbarGutter: 'stable',
//               scrollbarWidth: 'none'
//             }
//           })}
//         />
//         <Box textAlign={'right'}>
//           <Button variant='contained'>Confirm</Button>
//         </Box>
//       </Box>
//     </Modal>
//   )
// }

export { StepDetails }
