import { useContext } from "react"
import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { DataGridPro } from "@mui/x-data-grid-pro"
import { ScenarioContext } from ".."

const formatCurrency = (value) => {
  if (value === undefined || value === null) return ' '
  return value < 0 ? `-$${Math.abs(Number(value)).toLocaleString()}` : `$${Number(value).toLocaleString()}`
}

const summaryCols = [
  {
    field: 'accountName',
    headerName: 'Accounts',
    flex: 1
  },
  {
    field: 'propTe',
    headerName: 'Tracking Error',
    type: 'number',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueGetter: (params) => params.value !== null && params.value !== undefined ? parseFloat((params.value * 100).toFixed(2)) : null,
    renderCell: (params) => params.value !== null && params.value !== undefined ? params.value : 'NA'
  },
  {
    field: 'securities',
    headerName: 'No. of Securities',
    type: 'number',
    flex: 1,
    align: 'right'
  },
  {
    field: 'transferredShares',
    headerName: 'Transfers',
    type: 'number',
    flex: 1,
    align: 'right'
  },
  {
    field: 'buyShares',
    headerName: 'Buy',
    type: 'number',
    flex: 1,
    align: 'right'
  },
  {
    field: 'accountWeight',
    headerName: 'Weight (%)',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) => parseFloat((params?.value || 0)?.toFixed(2)),
    renderCell: (params) => (params?.value || 0)?.toFixed(2)
  },
  {
    field: 'portMv',
    headerName: 'Market Value',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) => params?.value?.toFixed(0),
    renderCell: (params) => formatCurrency(params.value)
  },
]

const TargetAccountTable = ({ aggId }) => {
  const scenarioData = useContext(ScenarioContext)
  const isLoading = scenarioData?.summaryGraphData[aggId]?.loading
  const targetTableData = scenarioData?.summaryGraphData[aggId]?.data?.targetUMA

  return (
    <>
      <Typography sx={{ fontWeight: 600, fontSize: '16px', color: '#2f2f2f' }}> Risk Summary</Typography>
      {
        isLoading ? (
          <TableContainer mt={10}>
            <Table className='risk-page-table'>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  {summaryCols.map((item, index) => {
                    return (
                      <TableCell key={index}>{item.headerName}</TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from({ length: 5 }).map((_, i) => (
                  <TableRow key={i}>
                    {
                      Array.from({ length: summaryCols.length + 1 }).map((_, j) => (
                        <TableCell key={j}>
                          <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                        </TableCell>
                      ))
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          targetTableData?.length ? (
            <DataGridPro
              autoHeight
              density='compact'
              rows={targetTableData || []}
              getRowId={(row) => row?.accountId}
              columns={summaryCols}
              hideFooter
            />
          ) : ''
        )
      }
    </>
  )
}

export default TargetAccountTable
