import { TextField } from '@mui/material'

const NumberFieldEditCell = (params) => {
  const { id, field, value } = params

  const handleNumberFieldEditChange = (event) => {
    const newValue = event.target.value
    params?.api?.setEditCellValue({ id, field, value: newValue })
    params?.api?.updateRows([{ tradeId: id, error: (params?.row?.trdCode === 'SELL' && field === 'trdQty' && newValue >= 0) || (params?.row?.trdCode === 'BUY' && field === 'trdQty' && newValue <= 0) }])
  }

  return (
    <TextField
      type='number'
      margin='dense'
      size='small'
      sx={
        (params?.row?.trdCode === 'SELL' && field === 'trdQty' && value >= 0) || (params?.row?.trdCode === 'BUY' && field === 'trdQty' && value <= 0)
          ? {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red !important'
              }
            }
          : {}
      }
      fullWidth
      autoComplete={false}
      id={`number-field-edit-${params?.row?.id}`}
      name={`number-field-edit-${params?.row?.id}`}
      value={value ?? ''}
      onChange={handleNumberFieldEditChange}
    />
  )
}

export default NumberFieldEditCell
