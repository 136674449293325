import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Typography, Autocomplete, TextField, Select, MenuItem, Tooltip, Tabs, Tab, styled } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { TabContext, TabPanel } from '@mui/lab'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ManageAccountsTable from './ManageAccountsTable'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import ManageStrategyTable from './ManageStrategyTable'
import ManageUMAAccountsTable from './ManageUMAAccountsTable'
import ManageAggregateGroupsTable from './ManageAggregateGroupsTable'
import ManageTradeGroupTab from './ManageTradeGroupTab'
import { tradingUmaResourceList } from './PermissionTable'

const RoleAndAccountManagePopUp = (props) => {
  const {
    idSelectionModalData, showRoleModel, closeModal, allSpnAccounts, data,
    allSpnTradingAccounts, spnAccountLoading, strategyListLoading, allStrategiesList,
    aggregateGroupsLoading, allAggregateGroupsList, umaAggregateLoading, allUmaAggregateList,
    allUmaTradingAccounts, allEntityMapGroupsList
  } = props
  const { user } = useAuth()
  const [roleListOptions, setRoleListOptions] = useState([])
  const [selectedRoleValue, setSelectedRoleValue] = useState(null)
  const [resourceIdList, setResourceIdList] = useState([])
  const [showCopyResIdList, setShowCopyResIdList] = useState(false)
  const { showError } = useErrorToast()
  const [selectedValue, setSelectedValue] = useState(null)
  const [accountSelectionModel, setAccountSelectionModel] = useState([])
  const [strategySelectionModel, setStrategySelectionModel] = useState([])
  const [aggregateSelectionModel, setAggregateSelectionModel] = useState([])
  const [aggregateGroupsSelectionModel, setAggregateGroupsSelectionModel] = useState([])
  const [allSpnAccountsFlag, setAllSpnAccountsFlag] = useState(false)
  const [nonGroupTradingAccountsFlag, setNonGroupTradingAccountsFlag] = useState(false)
  const [allStrIdsFlag, setAllStrIdsFlag] = useState(false)
  const [allActiveStrIdsFlag, setAllActiveStrIdsFlag] = useState(false)
  const [spnGroupAccountsFlag, setSpnGroupAccountsFlag] = useState(false)
  const [allSponsorUMAFlag, setAllSponsorUMAFlag] = useState(false)
  const [accountCheckBoxApiLoading, setAccountCheckBoxApiLoading] = useState([])
  const [strategyCheckBoxApiLoading, setStrategyCheckBoxApiLoading] = useState([])
  const [aggregateCheckBoxApiLoading, setAggregateCheckBoxApiLoading] = useState([])
  const [aggregateGroupsCheckBoxApiLoading, setAggregateGroupsCheckBoxApiLoading] = useState([])
  const [tabValue, setTabValue] = useState(idSelectionModalData?.popupTabs && idSelectionModalData?.popupTabs?.length ? idSelectionModalData.popupTabs[0] : null)

  const renderTabPanel = (item) => {
    switch (item) {
      case 'Strategy':
        return (
          <ManageStrategyTable
            allStrategiesList={allStrategiesList}
            strategyListLoading={strategyListLoading}
            selectionModel={strategySelectionModel}
            closeModal={closeModal}
            allStrIdsFlag={allStrIdsFlag}
            setSelectionModel={setStrategySelectionModel}
            setAllStrIdsFlag={setAllStrIdsFlag}
            allActiveStrIdsFlag={allActiveStrIdsFlag}
            setAllActiveStrIdsFlag={setAllActiveStrIdsFlag}
            defaultCheckBoxApiLoading={strategyCheckBoxApiLoading}
            modelProps={idSelectionModalData?.props}
          />
        )
      case 'UMA':
        return (
          <ManageUMAAccountsTable
            allUmaAggregateList={allUmaAggregateList}
            umaAggregateLoading={umaAggregateLoading}
            selectionModel={aggregateSelectionModel}
            setSelectionModel={setAggregateSelectionModel}
            defaultCheckBoxApiLoading={aggregateCheckBoxApiLoading}
            allUmaTradingAccounts={allUmaTradingAccounts}
            modelProps={idSelectionModalData?.props}
            closeModal={closeModal}
            setAllSponsorUMAFlag={setAllSponsorUMAFlag}
            allSponsorUMAFlag={allSponsorUMAFlag}
          />
        )
      case 'Groups':
        return (
          <ManageAggregateGroupsTable
            allAggregateGroupsList={allAggregateGroupsList}
            aggregateGroupsLoading={aggregateGroupsLoading}
            selectionModel={aggregateGroupsSelectionModel}
            setSelectionModel={setAggregateGroupsSelectionModel}
            spnGroupAccountsFlag={spnGroupAccountsFlag}
            setSpnGroupAccountsFlag={setSpnGroupAccountsFlag}
            defaultCheckBoxApiLoading={aggregateGroupsCheckBoxApiLoading}
            modelProps={idSelectionModalData?.props}
            closeModal={closeModal}
            allEntityMapGroupsList={allEntityMapGroupsList}
          />
        )
      case 'Trade-Groups':
        return (
          <ManageTradeGroupTab
            allAggregateGroupsList={allAggregateGroupsList}
            aggregateGroupsLoading={aggregateGroupsLoading}
            selectionModel={aggregateGroupsSelectionModel}
            setSelectionModel={setAggregateGroupsSelectionModel}
            spnGroupAccountsFlag={spnGroupAccountsFlag}
            setSpnGroupAccountsFlag={setSpnGroupAccountsFlag}
            closeModal={closeModal}
            defaultCheckBoxApiLoading={aggregateGroupsCheckBoxApiLoading}
            modelProps={idSelectionModalData?.props}
          />
        )
      case 'Account':
        return (
          <ManageAccountsTable
            modelProps={idSelectionModalData?.props}
            allSpnTradingAccounts={allSpnTradingAccounts}
            spnAccountLoading={spnAccountLoading}
            allSpnAccounts={allSpnAccounts}
            allSpnAccountsFlag={allSpnAccountsFlag}
            selectionModel={accountSelectionModel}
            closeModal={closeModal}
            setAllSpnAccountsFlag={setAllSpnAccountsFlag}
            setNonGroupTradingAccountsFlag={setNonGroupTradingAccountsFlag}
            nonGroupTradingAccountsFlag={nonGroupTradingAccountsFlag}
            defaultCheckBoxApiLoading={accountCheckBoxApiLoading}
            setSelectionModel={setAccountSelectionModel}
          />
        )
      default:
        return <></>
    }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: showRoleModel?.show ? '350px' : '950px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: '4px',
    '.MuiDataGrid-root': {
      border: 'none'
    },
    '.MuiDataGrid-main': {
      overflow: 'unset'
    },
    '.MuiDataGrid-columnHeaders': {
      position: 'sticky',
      top: '-4px',
      zIndex: 99,
      background: 'white'
    },
    '.MuiDataGrid-virtualScroller': {
      marginTop: '-4px!important'
    }
  }

  useEffect(() => {
    if (user && showRoleModel) {
      setRoleListOptions(showRoleModel?.props?.roleList || showRoleModel?.props)
    }
  }, [user, showRoleModel])

  useEffect(() => {
    if (user && idSelectionModalData) {
      const newResourceIdList = data
        ?.map((service) => (
          service?.resources?.map((resource) => ({
            resourceName: resource?.resourceName,
            serviceId: resource?.serviceId,
            resId: resource?.resourceId
          }))
        ))
        .flat()
      setResourceIdList(newResourceIdList)
    }

    if (!idSelectionModalData?.show) {
      setShowCopyResIdList(false)
      setSelectedValue(null)
      setAccountSelectionModel([])
      setStrategySelectionModel([])
      setAggregateSelectionModel([])
      setAggregateGroupsSelectionModel([])
    }
  }, [user, idSelectionModalData, data])

  useEffect(() => {
    if (idSelectionModalData?.props) {
      if (tabValue === 'Strategy' && !strategyListLoading) {
        getUserStrategiesApi(idSelectionModalData?.props?.resId)
      }
    }
  }, [user, idSelectionModalData, strategyListLoading])

  useEffect(() => {
    if (idSelectionModalData?.props) {
      if (tabValue === 'Account' && !spnAccountLoading) {
        getUserAccountAccessApi(idSelectionModalData?.props?.resId)
      }
    }
  }, [user, idSelectionModalData, spnAccountLoading])

  useEffect(() => {
    if (idSelectionModalData?.props) {
      if ((tabValue === 'Groups' || tabValue === 'Trade-Groups') && !aggregateGroupsLoading) {
        getAggregateGroupsApi(idSelectionModalData?.props?.resId)
      }
    }
  }, [user, idSelectionModalData, aggregateGroupsLoading])

  useEffect(() => {
    if (idSelectionModalData?.props) {
      if (tabValue === 'UMA' && !umaAggregateLoading) {
        getUMAAggregateApi(idSelectionModalData?.props?.resId)
      }
    }
  }, [user, idSelectionModalData, umaAggregateLoading])

  const onChangeHandler = (e, value) => {
    setSelectedRoleValue(value)
  }

  const handleResourceChange = (event, newValue) => {
    setSelectedValue(newValue?.props?.value)
    if (tabValue === 'Account') {
      getUserAccountAccessApi(newValue?.props?.className)
    } else if (tabValue === 'Strategy') {
      getUserStrategiesApi(newValue?.props?.className)
    } else if (tabValue === 'Groups') {
      getAggregateGroupsApi(newValue?.props?.className)
    } else if (tabValue === 'UMA') {
      getUMAAggregateApi(newValue?.props?.className)
    }
  }

  const getAggregateGroupsApi = (resId) => {
    setAggregateGroupsCheckBoxApiLoading(true)
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/user/agg-group${idSelectionModalData?.props?.userId ? `/${idSelectionModalData?.props?.userId}` : ''}`)
      .then((res) => {
        if (res && res?.success && res?.data) {
          const aggGroupIds = res?.data?.filter((r) => (r?.resourceId + '-' + r?.serviceId) === resId)?.map((agg) => agg?.aggGroupIds)?.flat()

          const selectedGroupIds = res?.data?.find((r) => (r?.resourceId + '-' + r?.serviceId) === resId)

          // use allSpnGroups flag for advisor and use allAggGroup flag for PM
          let spnAggregateGroupFlag = false
          if (selectedGroupIds) {
            spnAggregateGroupFlag = selectedGroupIds
              ? idSelectionModalData?.props?.sponsor
                ? selectedGroupIds?.allSpnAggGroups
                : selectedGroupIds?.allAggGroups
              : false
          }

          const filteredAggGroupIds = aggGroupIds?.filter(id =>
            (idSelectionModalData?.props?.resourceId === 'entity-mapping' ? allEntityMapGroupsList : allAggregateGroupsList)?.some(agg => agg?.aggGroupId === id))

          // if (filteredAggGroupIds?.length > 0) {
            setAggregateGroupsSelectionModel(filteredAggGroupIds)
            setSpnGroupAccountsFlag(spnAggregateGroupFlag)
          // }

          setAggregateGroupsCheckBoxApiLoading(false)
        }
      }).catch((error) => {
        setAggregateGroupsCheckBoxApiLoading(false)
        showError(error, false, {}, 'Failed to load aggregate group')
      }).finally(() => setAggregateGroupsCheckBoxApiLoading(false))
  }

  const getUMAAggregateApi = (resId) => {
    setAggregateCheckBoxApiLoading(true)
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/user/uma${idSelectionModalData?.props?.userId ? `/${idSelectionModalData?.props?.userId}` : ''}`)
      .then((res) => {
        if (res && res?.success && res?.data) {
          const accIds = res?.data?.filter((r) => (r?.resourceId + '-' + r?.serviceId) === resId)?.map((agg) => agg?.aggIds)?.flat()

          const selectedAggregateIds = res?.data?.find((r) => (r?.resourceId + '-' + r?.serviceId) === resId)

          // use allSpnAggregates flag for advisor and use allAggregates flag for PM
          let spnUmaFlag = false
          if (selectedAggregateIds) {
            spnUmaFlag = selectedAggregateIds
              ? idSelectionModalData?.props?.sponsor
                ? selectedAggregateIds?.allSpnAggregates
                : selectedAggregateIds?.allAggregates
              : false
          }

          const filteredAggIds = accIds?.filter(id =>
            (tradingUmaResourceList?.includes(idSelectionModalData?.props?.resourceId) ? allUmaTradingAccounts : allUmaAggregateList)?.some(agg => agg?.aggId === id))

          // if (filteredAggIds.length > 0) {
            setAggregateSelectionModel(filteredAggIds)
            setAllSponsorUMAFlag(spnUmaFlag)
          // }

          setAggregateCheckBoxApiLoading(false)
        }
      }).catch((error) => {
        setAggregateCheckBoxApiLoading(false)
        showError(error, false, {}, 'Failed to load UMA group')
      }).finally(() => setAggregateCheckBoxApiLoading(false))
  }

  // this api mainly used to show default selection on radio buttons and rows
  // store flags to check if all the accountIds are true then show all rows as default selected
  const getUserAccountAccessApi = (resId) => {
    setAccountCheckBoxApiLoading(true)
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/user/accounts${idSelectionModalData?.props?.userId ? `/${idSelectionModalData?.props?.userId}` : ''}`,
      !idSelectionModalData?.props?.sponsor ? { queryStringParameters: { source: 'pm' } } : {}
    )
      .then((res) => {
        if (res && res?.success && res?.data) {
          const accIds = res?.data?.filter((r) => (r?.resourceId + '-' + r?.serviceId) === resId)?.map((accIds) => accIds?.accountIds)?.flat()

          const selectedAccountIds = res?.data?.find((r) => (r?.resourceId + '-' + r?.serviceId) === resId)

          // use allSpnAccounts flag for advisor and use allAccounts flag for PM
          let spnAccountFlag = false
          if (selectedAccountIds) {
            spnAccountFlag = selectedAccountIds
              ? idSelectionModalData?.props?.sponsor
                ? selectedAccountIds?.allSpnAccounts
                : selectedAccountIds?.allAccounts
              : false
          }

          const isNonGroupAccounts =
          res?.data?.filter((r) => (r?.resourceId + '-' + r?.serviceId) === resId) &&
          res?.data?.filter((r) => (r?.resourceId + '-' + r?.serviceId) === resId)[0]?.allNonTradingGrpAccounts

          const filteredAccIds = accIds?.filter(id =>
            (idSelectionModalData?.props?.serviceId === 'trade' || idSelectionModalData?.props?.resourceId === 'group-onboarding' ? allSpnTradingAccounts : allSpnAccounts)?.some(account => account.accountId === id))
  
          // Always update the selection model and flags, even when there are no accounts
          setAccountSelectionModel(filteredAccIds || [])
          setAllSpnAccountsFlag(spnAccountFlag)
          setNonGroupTradingAccountsFlag(!!isNonGroupAccounts)
  
          setAccountCheckBoxApiLoading(false)
        }
      }).catch((error) => {
        setAccountCheckBoxApiLoading(false)
        showError(error, false, {}, 'Failed to load user account')
      }).finally(() => setAccountCheckBoxApiLoading(false))
  }

  // this api mainly used to show default selection on radio buttons and rows
  // store flags to check if all the strategyIds and all activeStrategyIds are true then show all rows as default selected
  const getUserStrategiesApi = (resId) => {
    setStrategyCheckBoxApiLoading(true)
    API.get('baseAclURL2', `user-access-control/v1/${user?.userGroup}/user/strategies${idSelectionModalData?.props?.userId ? `/${idSelectionModalData?.props?.userId}` : ''}`)
      .then((res) => {
        if (res && res?.success && res?.data) {
          const strategyId = res?.data?.filter((r) => (r?.resourceId + '-' + r?.serviceId) === resId)?.map((res) => res?.strategyIds)?.flat()

          const isAllStrgs =
          res?.data?.filter((r) => (r?.resourceId + '-' + r?.serviceId) === resId) &&
          res?.data?.filter((r) => (r?.resourceId + '-' + r?.serviceId) === resId)[0]?.allStrategies

          const isAllActiveStrgs =
          res?.data?.filter((r) => (r?.resourceId + '-' + r?.serviceId) === resId) &&
          res?.data?.filter((r) => (r?.resourceId + '-' + r?.serviceId) === resId)[0]?.allActiveStrategies

          if (strategyId?.some(id => allStrategiesList?.some(account => account.strategyId === id))) {
            setStrategySelectionModel(strategyId)
            setAllStrIdsFlag(!!isAllStrgs)
            setAllActiveStrIdsFlag(!!isAllActiveStrgs)
          }
          setStrategyCheckBoxApiLoading(false)
        }
      }).catch((error) => {
        setStrategyCheckBoxApiLoading(false)
        showError(error, false, {}, 'Failed to load users strategies')
      }).finally(() => setStrategyCheckBoxApiLoading(false))
  }

  const getPopupTitle = (tabValue) => {
    if (tabValue === 'UMA' || tabValue === 'Groups' || tabValue === 'Trade-Groups') return 'Aggregate'
    else if (tabValue === 'Strategy') return 'Strategy'
    else return 'Accounts'
  }

  const handleAggregateGroupTabChange = (e, newValue) => {
    setShowCopyResIdList(false) // Hide the copy dropdown when changing tabs
    setSelectedValue(null) // resets the resource selection on tab change
    if (newValue === 'Account') {
      setAccountSelectionModel([])
      getUserAccountAccessApi(idSelectionModalData?.props?.resId)
    } else if (newValue === 'UMA') {
      setAggregateSelectionModel([])
      getUMAAggregateApi(idSelectionModalData?.props?.resId)
    } else {
      setAggregateGroupsSelectionModel([])
      getAggregateGroupsApi(idSelectionModalData?.props?.resId)
    }
    setTabValue(newValue)
  }

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={idSelectionModalData?.show || showRoleModel?.show}
      sx={{ outline: 'none' }}
      onClose={() => {
        setAllSpnAccountsFlag(false)
        closeModal()
      }}
    >
      <Box sx={style}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            variant='h6'
            style={{
              fontFamily: 'Open Sans',
              fontWeight: 400,
              color: '#34475A',
              fontSize: '20px',
              margin: 0
            }}
          >
            {`Choose ${getPopupTitle(tabValue)}`}
          </Typography>
          {showCopyResIdList && idSelectionModalData?.popupTabs && idSelectionModalData?.popupTabs?.length && !idSelectionModalData.popupTabs.includes('Strategy') && tabValue !== 'Strategy' && (
            <Select
              labelId='label-id'
              margin='dense'
              size='small'
              id='roleCode'
              name='roleCode'
              sx={{ position: 'absolute', right: '100px', width: '150px', visibility: idSelectionModalData?.show && showCopyResIdList }}
              value={selectedValue || idSelectionModalData?.props?.resourceName}
              fullWidth
              onChange={(event, newValue) => handleResourceChange(event, newValue)}
            >
              {resourceIdList && resourceIdList?.length
                ? (
                    resourceIdList?.map((res, index) => (
                      <MenuItem value={res.resourceName} key={index} className={res?.resId + '-' + res?.serviceId} itemID={res?.serviceId}>
                        {res?.resourceName}
                      </MenuItem>
                    ))
                  )
                : []}
            </Select>
          )}
          {
            (idSelectionModalData?.popupTabs && idSelectionModalData?.popupTabs?.length && idSelectionModalData?.popupTabs?.includes('Account') && tabValue === 'Account') && (
              <Tooltip title='Copy Accounts from other service'>
                <ContentCopyIcon
                  sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: '65px'
                  }} onClick={() => setShowCopyResIdList(true)}
                />
              </Tooltip>
            )
          }
          {
            (idSelectionModalData?.popupTabs && idSelectionModalData?.popupTabs?.length && idSelectionModalData?.popupTabs?.includes('UMA') && tabValue === 'UMA') && (
              <Tooltip title='Copy UMAs from other service'>
                <ContentCopyIcon
                  sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: '65px'
                  }} onClick={() => setShowCopyResIdList(true)}
                />
              </Tooltip>
            )
          }
          {
            (idSelectionModalData?.popupTabs && idSelectionModalData?.popupTabs?.length && idSelectionModalData?.popupTabs?.includes('Groups') && tabValue === 'Groups') && (
              <Tooltip title='Copy Groups from other service'>
                <ContentCopyIcon
                  sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: '65px'
                  }} onClick={() => setShowCopyResIdList(true)}
                />
              </Tooltip>
            )
          }
          <IconButton
            aria-label='close'
            onClick={() => closeModal()}
            sx={{ marginLeft: 'auto' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {
        idSelectionModalData?.show
          ? (
            <>
              {
                idSelectionModalData?.popupTabs && idSelectionModalData?.popupTabs?.length === 1
                  ? (
                      renderTabPanel(tabValue)
                    )
                  : (
                      idSelectionModalData?.popupTabs && idSelectionModalData?.popupTabs?.length
                        ? (
                          <>
                            <TabContext value={tabValue}>
                              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs
                                  value={tabValue}
                                  onChange={handleAggregateGroupTabChange}
                                  variant='standard'
                                  TabIndicatorProps={{
                                    style: {
                                      backgroundColor: '#34475A'
                                    }
                                  }}
                                  indicatorColor='#34475A'
                                  sx={{
                                    p: 0,
                                    height: '35px',
                                    minHeight: '40px'
                                  }}
                                >
                                  {
                              idSelectionModalData?.popupTabs?.map((item) => (
                                <Tab
                                  key={item}
                                  value={item}
                                  label={item === 'Trade-Groups' ? 'Groups' : item}
                                  sx={{
                                    fontSize: '14px',
                                    padding: 0,
                                    minWidth: '65px'
                                  }}
                                />))
                          }
                                </Tabs>
                              </Box>
                              {idSelectionModalData?.popupTabs?.map((item) => (
                                <TabPanel
                                  key={item}
                                  value={item}
                                  sx={{ padding: 0, position: 'relative' }}
                                >
                                  {item === tabValue && renderTabPanel(item)}
                                </TabPanel>
                              ))}
                            </TabContext>
                          </>

                          )
                        : (
                          <></>
                          )
                    )
              }
            </>
            )
          : (
            <>
              <Typography variant='h6' style={{ fontFamily: 'Open Sans', fontWeight: 400, color: '#34475A', marginLeft: '-10px', marginBottom: '20px' }}>Select Role</Typography>
              <Autocomplete
                disablePortal
                id='roles'
                sx={{
                  width: 300,
                  '& .MuiInputBase-input': {
                    height: '10px'
                  },
                  '& label': {
                    margin: '-5px 3px 2px 3px'
                  },
                  marginLeft: '-10px'
                }}
                options={roleListOptions || []}
                value={selectedRoleValue}
                onChange={(event, value) => {
                  onChangeHandler(event, value)
                }}
                isOptionEqualToValue={(option, value) => {
                  return option.roleName === value
                }}
                getOptionLabel={(option) => typeof option === 'string' || option instanceof String ? option : option?.roleName}
                renderInput={(params) => <TextField sx={{ height: '40px' }} {...params} label='Roles' />}
              />
            </>
            )
        }
      </Box>
    </Modal>
  )
}

export default RoleAndAccountManagePopUp
