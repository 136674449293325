import { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { IoIosExpand } from 'react-icons/io'
import ReactECharts from 'echarts-for-react'
import { Box, Grid, IconButton, Skeleton, Tooltip } from '@mui/material'
import { enableLegacyViewReducer } from '../../../../store/trade-reducer/trade-reducer'
import { formatCurrencyWithSymbol } from '../../../../utils/FormateCurrenyInMilion'
import { ScenarioContext } from '..'

const nodeTooltipFormatter = (params) => {
  if (params.data.type === 'TRANSITION') {
    return `<div style='background-color: white;font-family: Open Sans;padding: 10px;'>
    <div style='display: flex; justify-content: space-between;align-items: flex-start;'>
        <div>
            <div style='font-size: 14px; font-weight: 500; color: #74788D;'>Market Value</div>
            <div style='font-size: 18px; font-weight: 600; color: #2F2F2F;'>${formatCurrencyWithSymbol(params?.data?.portMv, 2, '$')}</div>
        </div>
        <div style='display: flex; justify-content: space-between;align-items: center;'>
            <div style='margin-left:50px;text-align: left;'>
                <div style='font-size: 12px; font-weight: 600; color: #3BBFA3;margin-bottom: 2px;'>${params?.data?.transferredShares}</div>
                <div style='width: 50px; height: 5px; border-radius: 5px; background-color: #3BBFA3;'></div>
                <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Transfers</div>
            </div>
            <div style='text-align: right;'>
                <div style='font-size: 12px; font-weight: 600; color: #ff6161;margin-bottom: 2px;'>${params?.data?.sellShares}</div>
                <div style='width: 50px; height: 5px; border-radius: 5px; background-color: #ff6161;'></div>
                <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Sell</div>
            </div>
        </div>
    </div>
    <div style='margin-top:20px;'>
        <div style='display: flex; justify-content: space-between;align-items: center;'>
            <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Tax Cost</div>
            <div style='font-size: 12px; font-weight: 600; color: #2F2F2F;'>${formatCurrencyWithSymbol(params?.data?.taxCost, 2, '$')}</div>
        </div>
        <div style='display: flex; justify-content: space-between;align-items: center;'>
            <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Realized Gain/Loss</div>
            <div style='font-size: 12px; font-weight: 600; color: #2F2F2F;'>${formatCurrencyWithSymbol(params?.data?.rgl, 2, '$')}</div>
        </div>
    </div>

</div>`
  } else if (params.data.type === 'SCENARIO_TRANSITION') {
    return `<div style='background-color: white;font-family: Open Sans;padding: 10px;'>
    <div style='display: flex; justify-content: space-between;align-items: flex-start;'>
        <div>
            <div style='font-size: 14px; font-weight: 500; color: #74788D;'>Market Value</div>
            <div style='font-size: 18px; font-weight: 600; color: #2F2F2F;'>${formatCurrencyWithSymbol(params?.data?.portMv, 2, '$')}</div>
        </div>
        <div style='display: flex; justify-content: space-between;align-items: center;'>
            <div style='margin-left:50px;text-align: left;'>
                <div style='font-size: 12px; font-weight: 600; color: #3BBFA3;margin-bottom: 2px;'>${params?.data?.transferredShares}</div>
                <div style='width: 50px; height: 5px; border-radius: 5px; background-color: #3BBFA3;'></div>
                <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Transfers</div>
            </div>
            <div style='text-align: right;'>
                <div style='font-size: 12px; font-weight: 600; color: #6AA6EA;margin-bottom: 2px;'>${params?.data?.buyShares}</div>
                <div style='width: 50px; height: 5px; border-radius: 5px; background-color: #6AA6EA;'></div>
                <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Buy</div>
            </div>
        </div>
    </div>
    <div style='margin-top:20px;'>
        <div style='display: flex; justify-content: space-between;align-items: center;'>
            <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Tracking Error</div>
            <div style='font-size: 12px; font-weight: 600; color: #2F2F2F;'>${((params?.data?.propTe || 0) * 100)?.toFixed(2)}%</div>
        </div>
        <div style='display: flex; justify-content: space-between;align-items: center;'>
            <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Unrealized Gain/Loss</div>
            <div style='font-size: 12px; font-weight: 600; color: #2F2F2F;'>${formatCurrencyWithSymbol(params?.data?.totalUrgl, 2, '$')}</div>
        </div>
    </div>

</div>`
  }
  return ''
}

const edgeTooltipFormatter = (params) => {
  return `<div style='background-color: white; width: 160px;'>
                    <div style='font-size: 12px; font-weight: 400; color: #74788D;'>Transferred Amount</div>
                    <div style='font-size: 12px; font-weight: 600; color: #34475A;'>${formatCurrencyWithSymbol((params?.data?.value), 2, '$')}</div>
                  </div>`
}

const AccountSankeyTransitionChart = ({ toggleSummaryChartExpandView, aggId }) => {
  const scenarioData = useContext(ScenarioContext)
  const isLoading = scenarioData?.summaryGraphData[aggId]?.loading
  const summaryChartData = scenarioData?.summaryGraphData[aggId]?.data?.sankeyTransitionData
  const dispatch = useDispatch()

  const openExpandedView = () => {
    toggleSummaryChartExpandView()
    dispatch(enableLegacyViewReducer())
  }

  return (
    <Grid item xs={12} lg={10} alignSelf='center'>
      <Box sx={{ width: '100%', textAlign: 'right' }}>
        <Tooltip title='Expand'>
          <IconButton
            id='expand-button'
            sx={{ marginLeft: 'auto' }}
            onClick={() => openExpandedView()}
          >
            <IoIosExpand size='18px' color='#74788D' />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
      {
        isLoading ? (
          <Skeleton variant='rectangular' width='1000px' height='500px' sx={{ mx: 'auto' }} />
        ) : (
          summaryChartData ? (
            <ReactECharts
              option={{
                tooltip: {
                  trigger: 'item',
                  triggerOn: 'mousemove',
                  formatter: (params) => {
                    if (params.dataType === 'node') {
                      return nodeTooltipFormatter(params)
                    } else if (params.dataType === 'edge') {
                      return edgeTooltipFormatter(params)
                    }
                    return ''
                  }
                },
                series: [
                  {
                    type: 'sankey',
                    ...summaryChartData,
                    top: '2%',
                    left: '0%',
                    right: '0%',
                    bottom: '2%',
                    levels: [
                      {
                        depth: 0,
                        label: {
                          show: true,
                          formatter: (params) => {
                            return params?.data?.accountName
                          }
                        }
                      },
                      {
                        depth: 1,
                        label: {
                          show: true,
                          position: 'left',
                          formatter: (params) => {
                            return params?.data?.accountName
                          }
                        }
                      }
                    ],
                    emphasis: {
                      focus: 'adjacency'
                    },

                    lineStyle: {
                      color: 'source',
                      curveness: 0.5
                    },
                    nodeWidth: 10
                  }
                ]
              }}
              style={{ height: '500px', width: '100%', maxWidth: '1000px', margin: 'auto' }}
              opts={{ renderer: 'svg' }}
            />
          ) : ''
        )
      }
      </Box>
    </Grid>
  )
}

export default AccountSankeyTransitionChart
