import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, Divider, Grid, Tab, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DateRangePicker } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useAccountService } from '../../../hooks/useAccountService'
import { formatCurrencyWithSymbol } from '../../Report/components/dataProcess/DataProcess'
import AdhocTradeHistory from './components/AdhocTradeHistory'
import CreateAdhocForm from './components/CreateAdhocForm'
import LivePriceAlert from '../components/LivePriceAlert'
import './trade-adhoc.css'

dayjs.extend(utc)

export default function TradeAdhocMain () {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const { getGeneralAccountListUtil } = useAccountService()
  const [value, setValue] = useState('create-trade')
  const [accountsList, setAccountsList] = useState([])
  const [aggregateFamilyData, setAggregateFamilyData] = useState([])
  const [adhocTradeHistoryData, setAdhocTradeHistoryData] = useState([])
  const [date, setDate] = useState([dayjs.utc().subtract(1, 'month'), dayjs.utc()])
  const [tradeAdhocHistoryLoading, setTradeAdhocHistoryLoading] = useState(true)
  const [isCustodianLoading, setIsCustodianLoading] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [custodianList, setCustodianList] = useState([])
  const [sponsorsList, setSponsorsList] = useState([])
  const [latestLivePriceTime, setLatestLivePriceTime] = useState(null)

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }

  const getAccountsList = async () => {
    try {
      const response = await getGeneralAccountListUtil({ serviceId: 'book-of-business', resourceId: 'book-of-business' })
      if (response?.data && response?.data?.accountsSummary) {
        const filteredAccounts = response.data.accountsSummary.filter(option =>
          option.accountType === 'TRADING' &&
          option.masterAccountId &&
          option.custodianAccountNumber &&
          option.custodianId &&
          option.custodianName?.includes('Schwab')
        )
        setAccountsList([...filteredAccounts])
      }
    } catch (error) {
      showError(error, false, {}, 'Failed to load accounts list.')
    }
  }

  const getAggregateFamilyData = async () => {
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user?.userGroup}/aggregates`,
      {
        queryStringParameters: {
          aggTypeId: '',
          resources: encodeURIComponent(JSON.stringify({
            serviceId: 'aggregate',
            resourceId: 'aggregate-dashboard'
          }))
        }
      }
    )
      .then((response) => {
        if (response?.data?.length > 0) {
          const filteredAggregate = response.data.filter(option =>
            option.masterAccountId &&
            option.custodianAccountNumber &&
            option.custodianId
          )
          setAggregateFamilyData([...filteredAggregate])
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load aggregate family data.')
      })
  }

  // GET API for custodian dropdown data
  const getCustodianType = () => {
    setIsCustodianLoading(true)
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup}/custodians`)
      .then(response => {
        if (response?.data) {
          setCustodianList(response.data)
          setIsCustodianLoading(false)
        }
      }).catch(error => {
        setIsCustodianLoading(false)
        showError(error, false, {}, 'Failed to load custodian.')
      })
  }

  const getSponsorIds = () => {
    API.get('baseSponserURL', `data-maintenance/v1/${user?.userGroup}/sponsorIds`)
      .then(response => {
        if (response?.data) {
          setSponsorsList(response.data.data)
        }
      }).catch(error => {
        showError(error, false, {}, 'Failed to load sponsor list.')
      })
  }

  const getOptimRun = (fromDate, toDate) => {
    const currentDataDate = new Date().toISOString().slice(0, 10)
    fromDate = fromDate || currentDataDate
    toDate = toDate || currentDataDate

    API.get(
      'baseUriTrade',
      `trade/v1/${user?.userGroup}/adhoc/trades`, { queryStringParameters: { startDate: fromDate, endDate: toDate, resources: encodeURIComponent(JSON.stringify({ serviceId: 'book-of-business', resourceId: 'book-of-business' })) } }
    )
      .then((response) => {
        if (response.data.length === 0) {
          setTradeAdhocHistoryLoading(false)
          setAdhocTradeHistoryData([])
        } else {
          const adhocTrades = response?.data?.map((obj, index) => ({
            ...obj,
            id: randomId(),
            vspDate: obj?.additional?.vspDate || 'N/A',
            vspPrice: obj?.additional?.vspPrice !== null && obj?.additional?.vspPrice !== undefined ? formatCurrencyWithSymbol(obj?.additional?.vspPrice, 2, '$') : 'N/A'
          }))
          setAdhocTradeHistoryData(adhocTrades)
        }
        setTradeAdhocHistoryLoading(false)
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load adhoc trade history data.')
        setTradeAdhocHistoryLoading(false)
      })
  }

  const dateFilterHandler = () => {
    setTradeAdhocHistoryLoading(true)
    getOptimRun(date[0].format('YYYY-MM-DD'), date[1].format('YYYY-MM-DD'))
  }

  const handleDateChange = (date) => {
    if (!date[0] || !date[1]) {
      setDisableSubmit(true)
      return
    }
    setDate(date)
    setDisableSubmit(false)
  }
  useEffect(() => {
    if (user) {
      dateFilterHandler()
      getAccountsList()
      getCustodianType()
      if (user?.userGroup === 'pm') {
        getAggregateFamilyData()
        getSponsorIds()
      }
    }
  }, [user])

  const handleTradeHistoryChange = () => {
    dateFilterHandler()
    setValue('adhoc-history')
  }

  return (
    <>
      <Box className='trade-adhoc'>
        <Box
          sx={{
            display: 'flex',
            alignItems: { xs: 'start', md: 'center' },
            justifyContent: 'space-between'
          }}
          className='tradeMainhead'
        >
          <Typography component='h3' className='page-title' sx={{ marginRight: '20px', whiteSpace: 'nowrap' }}>Trade Adhoc</Typography>
          {
            latestLivePriceTime
              ? <LivePriceAlert dateTime={latestLivePriceTime} />
              : ''
          }
          {value === 'adhoc-history'
            ? <Box
                sx={{ display: 'inline-flex', justifyContent: 'flex-end', alignItems: 'end', gap: 2, position: 'absolute', top: '48px', right: '0' }}
              >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  value={date}
                  onChange={handleDateChange}
                  disableFuture
                  format='YYYY-MM-DD'
                  slotProps={{ textField: { size: 'small' } }}
                  sx={{
                    '.MuiMultiInputDateRangeField-separator': {
                      marginLeft: '8px'
                    },
                    '.MuiTextField-root:last-child': {
                      marginLeft: '8px'
                    },
                    '.MuiInputBase-input': {
                      padding: '6px 14px'
                    },
                    width: '17em'
                  }}
                  localeText={{ start: 'From Date', end: 'To Date' }}
                />
              </LocalizationProvider>
              <Grid item>
                <Button
                  variant='contained'
                  disabled={disableSubmit}
                  onClick={() => dateFilterHandler()}
                >
                  Submit
                </Button>
              </Grid>
            </Box>
            : ''}
        </Box>
        <TabContext value={value}>
          <TabList
            onChange={handleTabChange}
            variant='scrollable'
            scrollButtons={false}
            id='adhoc-tabs'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#34475A'
              }
            }}
            indicatorColor='#34475A'
          >
            <Tab
              className='tab-header' value='create-trade' label='Generate Trade'
            />
            <Tab
              className='tab-header' value='adhoc-history' label='Trade History'
            />
          </TabList>
          <Divider sx={{
            bgcolor: 'rgba(116, 120, 141, 0.15)'
          }}
          />
          <TabPanel
            sx={{
              px: 0
            }}
            value='create-trade'
          >
            <CreateAdhocForm
              onConfirm={() => handleTradeHistoryChange()}
              accountsList={accountsList}
              aggregateFamilyData={aggregateFamilyData}
              custodianList={custodianList}
              sponsorsList={sponsorsList}
              isCustodianLoading={isCustodianLoading}
              setLatestLivePriceTime={setLatestLivePriceTime}
            />
          </TabPanel>
          <TabPanel
            sx={{
              px: 0
            }}
            value='adhoc-history'
          >
            <AdhocTradeHistory
              tradeAdhocHistoryLoading={tradeAdhocHistoryLoading}
              adhocTradeHistoryData={adhocTradeHistoryData}
              setAdhocTradeHistoryData={setAdhocTradeHistoryData}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  )
}
