import { API } from 'aws-amplify'
import { ErrorMessage, FieldArray, Formik } from 'formik'
import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { useErrorToast } from '../../../../hooks/useErrorToast'
import { FormikAutocomplete } from '../../../../components/Formik/FormikAutocomplete'
import { FormikDynamicInput } from '../../../../components/Formik/FormikDynamicInput'
import { formatDerivedWeightData } from './GetDirectIndexingData'
import { useAuth } from '../../../../contexts/AuthContext'

export const UpdateDynamicStrategy = ({ open, handleCancelClose, strategyRows, handleDerivedStrategyInsert, currentStrategyId }) => {
  const initialValues = {
    rows: (strategyRows || []).map((row) => ({ strategyId: row.subStrId, weight: row.weight, existing: true, id: randomId() })) || [{ strategyId: '', weight: '', id: randomId() }]
  }
  const { showError } = useErrorToast()
  const { user } = useAuth()

  return (
    <Dialog open={open} onClose={handleCancelClose} fullWidth maxWidth='md'>
      <DialogTitle sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pb: 0
      }}
      >
        Manage sub strategies
        <IconButton onClick={handleCancelClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const errors = {}
            const usedIds = new Set()
            values.rows.forEach((row, index) => {
              // check for empty sub strategy ID validation
              if (!row.strategyId?.trim()) {
                errors.rows = errors.rows || []
                errors.rows[index] = { ...errors.rows[index], strategyId: 'Strategy ID is required' }
              } else if (usedIds.has(row.strategyId)) {
                // check for unique sub strategy validation
                errors.rows = errors.rows || []
                errors.rows[index] = { ...errors.rows[index], strategyId: 'Strategy already used. Can not add it again' }
              } else {
                usedIds.add(row.strategyId)
              }

              // Check for weight validation
              if (!row.weight?.toString().trim()) {
                errors.rows = errors.rows || []
                errors.rows[index] = { ...errors.rows[index], weight: 'Weight is required' }
              } else if (!/^\d+(\.\d{1,4})?$/.test(row.weight)) {
                // Check if weight has more than 4 digits after the decimal point (regex)
                errors.rows = errors.rows || []
                errors.rows[index] = { ...errors.rows[index], weight: 'Weight can have up to 4 digits after the decimal point' }
              } else if (row.weight < 1 || row.weight > 100) {
                errors.rows = errors.rows || []
                errors.rows[index] = { ...errors.rows[index], weight: 'Weight must be between 1 to 100' }
              }
            })
            // check total weight for strategy
            if (!errors.rows) {
              const totalWeight = values.rows.reduce((sum, row) => sum + Number(row.weight), 0)
              if (totalWeight !== 100) {
                errors.rows = 'Total weight must be 100'
              }
            }
            return errors
          }}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            // check total weight of whole batch
            const totalWeight = values.rows.reduce((sum, row) => sum + Number(row.weight), 0)
            if (totalWeight !== 100) {
              setFieldError('rows', 'Total weight must be 100')
            } else {
              const payload = values.rows?.map((row) => (
                {
                  subStrId: row.strategyId,
                  // fixed 6 digits after decimal point if not add 0's
                  weight: row.weight ? (row.weight / 100).toFixed(6) : 0.000000
                }
              ))
              try {
                await API.post(
                  'baseStrategyMaintainURL',
                  `data-maintenance/v1/${user?.userGroup}/strategies/direct-indexing/derived-weight-aloc/${strategyRows && strategyRows?.length && strategyRows[0]?.strategyId ? strategyRows[0]?.strategyId : currentStrategyId}`,
                  { body: [...payload] }
                ).then((res) => {
                  // formatting data
                  handleDerivedStrategyInsert(formatDerivedWeightData(res.data).map((data) => ({ ...data, id: randomId(), startDate: new Date(data.startDate) })))
                })
              } catch (error) {
                showError(error, false, {}, 'Failed to update derived strategies.')
              }
            }
            setSubmitting(false)
          }}
        >
          {props => (
            <form onSubmit={props.handleSubmit} autoComplete='off'>
              <FieldArray name='rows'>
                {({ push, remove }) => (
                  <>
                    {
                      props.values.rows.map((row, index) => {
                        return (
                          <Grid container key={row?.id} spacing={2} sx={{ alignItems: 'flex-start' }}>
                            <Grid item xs={9}>
                              <FormikAutocomplete
                                name={`rows[${index}].strategyId`}
                                label='Sub Strategy Id'
                                fieldValues={row.strategyId}
                                // disabled={row.existing || false}
                                onHandleChange={(event) => {
                                  // Update strategy ID in formik values when strategy is selected
                                  props.setFieldValue(`rows[${index}].strategyId`, event.target.value)
                                }}
                                optionValueKey='strategyName'
                                filterFunction={(data) => {
                                  return data?.filter(item => item.strTypeId !== 3 && item.strTypeId !== null)
                                }}
                                optionKey='strategyId'
                              />
                              <ErrorMessage name={`rows[${index}].strategyId`} component={Typography} sx={{ color: '#f05f5f' }} />
                            </Grid>
                            <Grid item xs={2}>
                              <FormikDynamicInput
                                control='number'
                                name={`rows[${index}].weight`}
                                label='Weight'
                                onHandleChange={props.handleChange}
                                fieldValues={row}
                                fieldKey='weight'
                              />
                              <ErrorMessage name={`rows[${index}].weight`} component={Typography} sx={{ color: '#f05f5f' }} />
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton onClick={() => remove(index)} sx={{ mt: 3 }}>
                                <DeleteOutlineIcon sx={{ color: '#f05f5f' }} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )
                      })
                    }
                    <Button color='primary' startIcon={<AddIcon />} onClick={() => push({ id: randomId(), strategyId: '', weight: '' })}>
                      Add record
                    </Button>
                    {
                      typeof props.errors?.rows === 'string'
                        ? (
                          <ErrorMessage name='rows' component={Typography} sx={{ color: '#f05f5f' }} />
                          )
                        : null
                    }
                  </>
                )}
              </FieldArray>
              <Button color='primary' variant='contained' fullWidth type='submit' sx={{ mt: 2 }} disabled={!props.dirty}>
                Submit
              </Button>
            </form>
          )}
        </Formik>

      </DialogContent>
    </Dialog>
  )
}
