import { useContext } from 'react'
import { Box, Divider, IconButton, Skeleton, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { TbInfoSquare } from 'react-icons/tb'
import { HtmlTooltip } from '../../../../components/CustomTooltip'
import { Card } from '../components'
import { ScenarioContext } from '..'

const RenderTableSkeleton = () => {
  return (
    <Table>
      <TableBody>
        {Array.from({ length: 5 }).map((_, index) => (
          <TableRow key={index}>
            {Array.from({ length: 4 }).map((_, index) => (
              <TableCell key={index}>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
              </TableCell>))}
          </TableRow>))}
      </TableBody>
    </Table>
  )
}

const AccountTransactionAndTransfer = ({ aggId }) => {
  const scenarioData = useContext(ScenarioContext),
    isLoading = scenarioData.summaryGraphData[aggId]?.loading,
    summaryData = scenarioData.summaryGraphData[aggId]?.data || {},
    targetUMA = summaryData?.targetUMA || [],
    rows = summaryData?.tableRows || []

  const formateToUsdCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const accColumns = targetUMA?.map(obj => ({
    field: obj?.accountId,
    headerName: obj?.accountName,
    flex: 1,
    cellClassName: 'sell-transfer-block',
    renderCell: (params) => {
      return (
        <>
          {
            params?.row?.[params?.field]?.sellMv ? (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: '#FFF6E3', px: '10px', width: '100%', flex: 1 }}>
                <Typography sx={{ color: '#002a59', fontWeight: 600, fontSize: '14px' }}>{(params?.row?.[params?.field]?.sellPercentage * 100)?.toFixed(2)}%</Typography>
                <Typography sx={{ color: '#002a59', fontWeight: 600, fontSize: '14px' }}>{formateToUsdCurrency.format(params?.row?.[params?.field]?.sellMv)}</Typography>
              </Box>
            ) : ''
          }
          {
            params?.row?.[params?.field]?.transferMv ? (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: '#E7EEFF', px: '10px', width: '100%', flex: 1 }}>
                <Typography sx={{ color: '#002a59', fontWeight: 600, fontSize: '14px' }}>{(params?.row?.[params?.field]?.transferPercentage * 100)?.toFixed(2)}%</Typography>
                <Typography sx={{ color: '#002a59', fontWeight: 600, fontSize: '14px' }}>{formateToUsdCurrency.format(params?.row?.[params?.field]?.transferMv)}</Typography>
              </Box>
            ) : ''
          }
        </>
      )
    }
  }))
  accColumns.push({
    field: 'total-column',
    headerName: 'Total',
    flex: 1,
    cellClassName: 'sell-transfer-block',
    renderCell: (params) => {
      return (
        <>
          {
            params?.row?.[params?.field]?.sellMv ? (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: '#FFF6E3', px: '10px', width: '100%', flex: 1 }}>
                <Typography sx={{ color: '#002a59', fontWeight: 600, fontSize: '14px' }}>{(params?.row?.[params?.field]?.sellPercentage * 100)?.toFixed(2)}%</Typography>
                <Typography sx={{ color: '#002a59', fontWeight: 600, fontSize: '14px' }}>{formateToUsdCurrency.format(params?.row?.[params?.field]?.sellMv)}</Typography>
              </Box>
            ) : ''
          }
          {
            params?.row?.[params?.field]?.transferMv ? (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: '#E7EEFF', px: '10px', width: '100%', flex: 1 }}>
                <Typography sx={{ color: '#002a59', fontWeight: 600, fontSize: '14px' }}>{(params?.row?.[params?.field]?.transferPercentage * 100)?.toFixed(2)}%</Typography>
                <Typography sx={{ color: '#002a59', fontWeight: 600, fontSize: '14px' }}>{formateToUsdCurrency.format(params?.row?.[params?.field]?.transferMv)}</Typography>
              </Box>
            ) : ''
          }
        </>
      )
    }
  })

  const columns = [
    {
      field: 'field',
      headerName: '',
      flex: 1,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params) => (
        <>
          <Typography sx={{ position: 'absolute', bottom: 0, left: 0, color: '#384F5B' }}>Source Acc</Typography>
          <Typography sx={{ position: 'absolute', top: 0, right: 0, color: '#384F5B' }}>Target Acc</Typography>
          <Divider sx={{ position: 'absolute', transform: 'rotate(10deg)', width: '100%' }} />
        </>
      ),
      renderCell: (params) => (
        params?.row?.accountName
      )
    },
    ...accColumns
  ]

  const legends = [
    {
      label: 'Transfer',
      color: '#E7EEFF',
      borderColor: '#A1BDFF'
    },
    {
      label: 'Sell',
      color: '#FFF6E3',
      borderColor: '#FDD07B'
    }
  ]

  return (
    <Card style={{ mt: '16px' }}>
      <Box sx={{
        display: 'flex',
        alignItems: { xs: 'start', md: 'center' },
        justifyContent: { xs: 'start', md: 'space-between' },
        flexDirection: { xs: 'column', md: 'row' },
        gap: '10px'
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 600, fontSize: '16px', color: '#2f2f2f' }}>Account Transaction</Typography>
          <HtmlTooltip
            title='Account Transaction'
            tooltipStyle={{ backgroundColor: '#000', color: '#fff', fontSize: '11px', align: 'center' }}
          >
            <IconButton size='small'>
              <TbInfoSquare style={{ height: '20px', width: '20px', color: '#2F2F2F' }} />
            </IconButton>
          </HtmlTooltip>
        </Box>
        <Box sx={{
          display: 'flex',
          width: { xs: '100%', md: 'inherit' },
          alignItems: 'center', justifyContent: 'center', gap: '12px', flexWrap: 'wrap'
        }}>
          {
            legends.map((obj, index) => (
              <Box key={index} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Box sx={{ width: '16px', height: '16px', borderRadius: '3px', border: `1px solid ${obj.borderColor}`, background: obj.color }}></Box>
                <Typography sx={{ ml: '8px', fontWeight: 600, fontSize: '12px' }}>{obj?.label}</Typography>
              </Box>
            ))
          }
        </Box>
      </Box>
      <Box sx={{ my: 3 }}>
        {
          isLoading ? (
            <RenderTableSkeleton />
          ) : (
            <DataGridPro
              autoHeight
              rows={rows || []}
              columns={columns}
              getRowId={(row) => row?.accountId}
              hideFooter
              slots={{
                noRowsOverlay: CustomNoRowsOverlay
              }}
              slotProps={{
                noRowsOverlay: { msg: 'No Transactions Found.' }
              }}
              sx={{
                '.sell-transfer-block': {
                  p: 0,
                  display: 'flex',
                  flexDirection: 'column',
                }
              }}
            />
          )
        }
      </Box>
    </Card>
  )
}

const CustomNoRowsOverlay = ({ msg }) => (
  <Typography sx={{ textAlign: 'center', margin: '16px auto' }}>
    {msg}
  </Typography>
)

export default AccountTransactionAndTransfer