import { randomId } from '@mui/x-data-grid-generator'

const centerNodes = {
  cash: {
    label: 'Cash',
    key: 'cashMv',
  },
  sell: {
    label: 'Sell',
    key: 'sellMv',
  },
  transfer: {
    label: 'Transfer',
    key: 'transferMv',
  }
}

const createEdge = (transfer, source, target, type) => {
  return {
    id: randomId(),
    data: {
      value: centerNodes[type].key
    },
    source,
    target,
    sourceAccount: transfer?.srcAccount,
    targetAccount: transfer?.dstAccount,
    type: 'custom',
    style: {
      strokeWidth: 2,
      stroke: '#aaaaaa',
      strokeDasharray: [5]
    }
  }
}

const getOriginalAndTargetUMANodes = (originalUMA, targetUMA, nodeObj, data) => {
  const sourceNodes = [], targetNodes = []
  originalUMA?.forEach((obj, index) => {
    data.push({
      ...obj,
      name: obj?.accountName,
      type: 'TRANSITION',
      id: obj?.accountId
    })
    sourceNodes.push({
      ...nodeObj,
      id: obj?.accountId,
      data: {
        ...obj,
        type: 'TRANSITION',
        strategyType: index % 2 === 0 ? 'PASSIVE' : 'ACTIVE'
      }
    })
  })

  targetUMA?.forEach((obj, index) => {
    data.push({
      ...obj,
      name: obj?.accountName,
      type: 'SCENARIO_TRANSITION',
      id: obj?.accountId
    })
    targetNodes.push({
      ...nodeObj,
      id: obj?.accountId,
      data: {
        ...obj,
        type: 'SCENARIO_TRANSITION',
        strategyType: index % 2 === 0 ? 'PASSIVE' : 'ACTIVE'
      }
    })
  })
  return { sourceNodes, targetNodes }
}

const getStrategyTypeNode = (type, nodeObj, moreData) => {
  // object for active/passive node
  return {
    ...nodeObj,
    type: 'midNode',
    id: randomId(),
    height: 140,
    style: {
      borderRadius: '12px',
      border: '1px solid #E6ECF3',
    },
    data: {
      type: 'SELL_TRANSFER_NODE',
      nodeType: type,
      total: 0,
      activeFund: 0,
      passiveFund: 0,
      isVirtualAccount: true,
      ...moreData
    }
  }
}

const addToTableRows = (transfer, tableRows, sourceAcc, totalMarketValue) => {
  // trying to create a structure like below
  // [{accountName: 'ABC', accountId: '123-456, targetId: {sellMv, sellPercentage, transferMv, transferPercentage}]
  const indexInTableRows = tableRows.findIndex(obj => obj?.accountId === transfer.srcAccount)
  if (indexInTableRows > -1) {
    tableRows[indexInTableRows][transfer.dstAccount] = {
      ...(transfer?.sellMv ? {
        sellMv: transfer?.sellMv ?? 0,
        sellPercentage: transfer?.sellMv / totalMarketValue
      } : {}),
      ...(transfer?.transferMv ? {
        transferMv: transfer?.transferMv ?? 0,
        transferPercentage: transfer?.transferMv / totalMarketValue
      } : {})
    }
  } else {
    tableRows.push({
      accountId: transfer?.srcAccount,
      accountName: sourceAcc?.accountName,
      [transfer.dstAccount]: {
        ...(transfer?.sellMv ? {
          sellMv: transfer?.sellMv ?? 0,
          sellPercentage: transfer?.sellMv / totalMarketValue
        } : {}),
        ...(transfer?.transferMv !== undefined && transfer?.transferMv !== null && transfer?.transferMv !== 0 ? {
          transferMv: transfer?.transferMv ?? 0,
          transferPercentage: transfer?.transferMv / totalMarketValue
        } : {})
      }
    })
  }
}

export const summaryTabDataProcess = (response) => {
  const transferDetails = response?.transferDetails || [],
    originalUMA = response?.originalUMA?.accountDetails || [],
    targetUMA = response?.scenarioUMA?.accountDetails || [], data = [], links = []
  let flowChartNodes = [], flowChartEdges = []
  const nodeObj = {
    type: 'account',
    position: {
      x: 0,
      y: 0
    },
    width: 250,
    height: 120
  }

  const { sourceNodes = [], targetNodes = [] } = getOriginalAndTargetUMANodes(originalUMA, targetUMA, nodeObj, data)

  const totalMarketValue = transferDetails?.reduce((curr, obj) => {
    Object.keys(centerNodes).map(key => {
      if (key !== 'cash' && obj?.[centerNodes[key]?.key]) {
        curr += obj?.[centerNodes[key].key]
      }
    })
    return curr
  }, 0)

  const tableRows = [], midNodeData = {}
  if (transferDetails?.length) {
    const sourceToCenterEdges = [], centerToTargetEdges = []
    transferDetails.forEach((transfer) => {
      const sourceAcc = originalUMA.find(acc => acc.accountId === transfer.srcAccount)

      if (!sourceAcc) return

      addToTableRows(transfer, tableRows, sourceAcc, totalMarketValue)

      // create sell and transfer and cash nodes if not already created
      Object.keys(centerNodes).map(key => {
        if (!midNodeData?.[key]) {
          midNodeData[key] = getStrategyTypeNode(centerNodes[key].label, nodeObj, response?.midCardData)
        }
        const id = transfer?.[centerNodes[key]?.key] ? midNodeData[key].id : null
        if (id) {
          const sourceIndexInEdge = sourceToCenterEdges.findIndex(edge => edge.source === transfer?.srcAccount && edge.target === id)
          if (sourceIndexInEdge > -1) {
            // add the value if there is already an edge from source to transfer
            sourceToCenterEdges[sourceIndexInEdge] = {
              ...sourceToCenterEdges[sourceIndexInEdge],
              data: {
                ...sourceToCenterEdges[sourceIndexInEdge]?.data,
                value: sourceToCenterEdges[sourceIndexInEdge].data.value + transfer?.[centerNodes[key].key]
              }
            }
          } else {
            // create an edge from source to transfer
            sourceToCenterEdges.push(createEdge(transfer, transfer?.srcAccount, id, key))
          }
          const targetIndexInEdge = centerToTargetEdges.findIndex(edge => edge.source === id && edge.target === transfer?.dstAccount)
          if (targetIndexInEdge > -1) {
            // add the value if there is already an edge from transfer to target
            centerToTargetEdges[targetIndexInEdge] = {
              ...centerToTargetEdges[targetIndexInEdge],
              data: {
                ...centerToTargetEdges[targetIndexInEdge]?.data,
                value: centerToTargetEdges[targetIndexInEdge]?.data?.value + transfer?.[centerNodes[key].key]
              }
            }
          } else {
            // create an edge from transfer to target
            centerToTargetEdges.push(createEdge(transfer, id, transfer?.dstAccount, key))
          }
        }
      })

      if (transfer?.transferMv) {
        links.push({
          source: transfer?.srcAccount,
          target: transfer?.dstAccount,
          value: transfer?.transferMv
        })
      }
    })

    Object.keys(midNodeData).forEach(obj => {
      flowChartNodes.push(midNodeData[obj])
    })

    flowChartEdges = [...sourceToCenterEdges, ...centerToTargetEdges]
    flowChartNodes = [...flowChartNodes, ...sourceNodes, ...targetNodes]
  }
  // total row transaction table
  if (tableRows?.length) {
    tableRows.forEach(row => {
      const totalColumn = { sellMv: 0, sellPercentage: 0, transferMv: 0, transferPercentage: 0 }
      Object.keys(row).filter(key => key !== 'accountId' && key !== 'accountName').forEach(key => {
        totalColumn.sellMv += row[key]?.sellMv ?? 0
        totalColumn.sellPercentage += row[key]?.sellPercentage ?? 0
        totalColumn.transferMv += row[key]?.transferMv ?? 0
        totalColumn.transferPercentage += row[key]?.transferPercentage ?? 0
      })
      row['total-column'] = totalColumn
    })
    targetUMA.push({ accountId: 'total-column', accountName: 'Total' })
    const totalRow = { accountId: 'total-row', accountName: 'Total' }
    tableRows.forEach(row => {
      targetUMA.forEach(target => {
        if (row[target.accountId]) {
          if (totalRow[target.accountId]) {
            totalRow[target.accountId] = {
              sellPercentage: totalRow[target.accountId].sellPercentage + (row[target.accountId]?.sellPercentage ?? 0),
              transferPercentage: totalRow[target.accountId].transferPercentage + (row[target.accountId]?.transferPercentage ?? 0),
              sellMv: totalRow[target.accountId].sellMv + (row[target.accountId]?.sellMv ?? 0),
              transferMv: totalRow[target.accountId].transferMv + (row[target.accountId]?.transferMv ?? 0)
            }
          } else {
            totalRow[target.accountId] = {
              sellPercentage: row[target.accountId]?.sellPercentage ?? 0,
              transferPercentage: row[target.accountId]?.transferPercentage ?? 0,
              sellMv: row[target.accountId]?.sellMv ?? 0,
              transferMv: row[target.accountId]?.transferMv ?? 0
            }
          }
        }
      })
    })
    targetUMA.pop()
    tableRows.push(totalRow)
  }

  return { sankeyTransitionData: { data, links }, flowChartEdges, flowChartNodes, tableRows, targetUMA, transferDetails, sourceNodes, midNodeData, midCardData: response?.midCardData }
}
