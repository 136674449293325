import { memo } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Box, Divider, Typography } from '@mui/material'
import { Handle, Position } from '@xyflow/react'
import { formatCurrencyWithSymbol } from '../../../../utils/FormateCurrenyInMilion'

const nodeTypeToKeyMapping = {
  'Transfer': 'transferMv',
  'Sell': 'sellWithoutCash',
  'Cash': 'cashFlowMv'
}

const nodeTypeToActiveFundKeyMapping = {
  'Transfer': 'activeTransferMv',
  'Sell': 'activeSellMv',
  'Cash': 'activeCashFlowMv'
}

const nodeTypeToPassiveFundKeyMapping = {
  'Transfer': 'passiveTransferMv',
  'Sell': 'passiveSellMv',
  'Cash': 'passiveCashFlowMv'
}

export default memo(({ data, isConnectable }) => {
  const chartOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '100%',
        borderRadius: 2, // Add border radius
        borderRadiusApplication: 'end' // Apply to bar ends
      }
    },
    stroke: {
      width: 0
    },
    grid: {
      show: false
    },
    colors: ['#CFD53A', '#1454A6'],
    fill: {
      type: ['pattern', 'solid'], // Pattern for first series, solid for second
      pattern: {
        style: 'verticalLines',
        strokeWidth: 5,
        width: 8,
        height: 8,
        color: '#CFD53A' // Explicit pattern color
      }
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      enabled: false,
      fixed: {
        enabled: true, // Makes tooltip render outside container
        position: 'right',
        offsetX: 20, // Adjust horizontal position
        offsetY: -35 // Position above chart area
      },
      y: {
        formatter: (value) => formatCurrencyWithSymbol(value, 2, '$')
      }
    },
    xaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      labels: {
        show: false
      }
    },
    legend: {
      show: false
    }
  }

  const series = [
    {
      name: 'Active Fund',
      data: [data?.[nodeTypeToActiveFundKeyMapping[data?.nodeType]] ?? 0]
    },
    {
      name: 'Passive Fund',
      data: [data?.[nodeTypeToPassiveFundKeyMapping[data?.nodeType]] ?? 0]
    }
  ]

  return (
    <>
      <Handle
        type='source'
        position={Position.Right}
        isConnectable={isConnectable}
      />
      <Handle
        type='target'
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <Box sx={{ color: '#002a59' }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: '#EEF2F7',
          p: '8px 16px',
          borderRadius: '12px 12px 0px 0px'
        }}>
          <Typography>{data?.nodeType}</Typography>
          <Typography sx={{ fontWeight: 600 }}>{formatCurrencyWithSymbol(data?.[nodeTypeToKeyMapping[data?.nodeType]] ?? 0, 2, '$')}</Typography>
        </Box>
        <Divider />
        <Box sx={{ p: '16px 12px', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Typography sx={{ fontSize: '12px', color: '#6A86A6' }}>Active Fund</Typography>
              <Typography sx={{ color: '#032347', fontWeight: 600 }}>{formatCurrencyWithSymbol(data?.[nodeTypeToActiveFundKeyMapping[data?.nodeType]] ?? 0, 2, '$')}</Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: '12px', color: '#6A86A6' }}>Passive Fund</Typography>
              <Typography sx={{ color: '#032347', fontWeight: 600 }}>{formatCurrencyWithSymbol(data?.[nodeTypeToPassiveFundKeyMapping[data?.nodeType]] ?? 0, 2, '$')}</Typography>
            </Box>
          </Box>
          <Box sx={{
            height: 20,
            borderRadius: '8px',
            overflow: 'hidden',
            marginTop: '10px',
            '.apexcharts-bar-area': {
              strokeLinecap: 'round'
            }
          }}
          >
            <ReactApexChart options={chartOptions} series={series} type='bar' height='35' />
          </Box>
        </Box>
      </Box>
    </>
  )
})