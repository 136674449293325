import { useContext } from 'react'
import Chart from 'react-apexcharts'
import { Box, Divider, Skeleton, Typography } from '@mui/material'
import { formatCurrencyWithSymbol } from '../../../../utils/FormateCurrenyInMilion'
import { SummaryContext } from '..'

const RiskTolerance = () => {
  const originalContext = useContext(SummaryContext)
  const isLoading = originalContext?.isTopUrglSecurityLoading
  const data = originalContext?.topUrglSecurities

  const topUrgl = (data?.topUrgl || [])?.map(security => ({
    name: security?.symbol,
    data: [security?.percentValue?.toFixed(2) ?? 0]
  }))

  const topUrglLegends = (data?.topUrgl || [])?.map(security => ({
    factor: security?.symbol,
    amount: security?.urgl ?? 0,
    percent: security?.percentValue ?? 0
  }))

  return (
    <>
      <RiskGraph
        loading={isLoading}
        data={topUrgl}
        legends={topUrglLegends}
        title='Top Gains'
        totalAmount={data?.totalGain}
      />
    </>
  )
}

const RiskGraph = ({ data, legends = [], title = '', totalAmount = 0, loading }) => {
  const colorArray = ['#002A59', '#33557A', '#667F9B', '#99AABD', '#B8CADF', '#CCD4DE']
  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    colors: colorArray,
    plotOptions: {
      bar: {
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'all',
        borderRadius: 7,
        border: '1px solid white',
        horizontal: true,
        rangeBarOverlap: true
      }
    },
    stroke: {
      width: 1,
      curve: 'monotoneCubic',
      lineCap: 'round',
      colors: ['#fff']
    },
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      }
    },
    legend: {
      show: false
    },
    xaxis: {
      show: false,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false
      }
    },
    yaxis: {
      show: false,
      axisBorder: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      x: {
        show: false
      },
      y: {
        show: true
      }
    },
    grid: {
      show: false
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ fontWeight: 600, fontSize: '16px', color: '#2f2f2f' }}>{title}</Typography>
        {
          loading ? (
            <Skeleton variant='text' sx={{ fontSize: '16px' }} />
          ) : (
            <Typography sx={{ fontSize: '16px', color: '#032347', fontWeight: 600 }}>{formatCurrencyWithSymbol(totalAmount ?? 0, 2, '$')}</Typography>
          )
        }
      </Box>
      <Divider sx={{ my: '10px' }} />
      {
        loading ? (
          <Skeleton variant='rectangular' height='170px' />
        ) : (
          <>
            <Chart options={options} series={data} type='bar' height={50} />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', mt: '10px' }}>
              {
                legends?.map((legend, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ width: '14px', height: '14px', borderRadius: '50%', background: colorArray[index % colorArray.length] }} />
                      <Typography sx={{ color: '#6A86A6', ml: '10px', fontSize: '12px', fontWeight: 700 }}>{legend.factor}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ color: '#6A86A6', fontSize: '12px', fontWeight: 700 }}>{formatCurrencyWithSymbol(legend?.amount ?? 0, 2, '$')}</Typography>
                      <Typography sx={{ color: '#FFFFFF', ml: '10px', fontSize: '10px', fontWeight: 700, background: colorArray[index % colorArray.length], borderRadius: '4px', padding: '0px 4px' }}>{Number(legend?.percent)?.toFixed(2) ?? 0}%</Typography>
                    </Box>
                  </Box>
                ))
              }
            </Box>
          </>
        )
      }
    </>
  )
}

export default RiskTolerance
