import { useEffect, useRef, useState } from 'react'
import { Box, Button, Typography, IconButton, Modal, Checkbox } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 2,
  py: 2,
  width: '100%',
  borderRadius: '4px'
}

const ColumnSelectionPopup = ({ open, onClose, columns, setColumns }) => {
  const [fileColumns, setFileColumns] = useState(columns)
  const dragIndex = useRef(0)
  const draggedOverIndex = useRef(0)

  useEffect(() => {
    setFileColumns(columns)
  }, [columns])

  const changeColumnVisiblity = (e, index) => {
    setFileColumns(prev => prev.map((obj, i) => i === index ? ({
      ...obj,
      isDefault: e.target.checked
    })
      : obj
    ))
  }

  const handleDrop = () => {
    const newColumnOrder = fileColumns?.slice()
    const [draggedItem] = newColumnOrder?.splice(dragIndex.current, 1)
    newColumnOrder?.splice(draggedOverIndex.current, 0, draggedItem)
    setFileColumns(newColumnOrder)
  }

  const applyChanges = () => {
    setColumns(fileColumns)
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={{ ...modalStyle, outline: 'none', height: '500px', width: '500px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
          <Typography variant='h6' sx={{ fontWeight: 400, color: '#34475A' }}>Manage Report Columns</Typography>
          <Box sx={{ display: 'flex' }}>
            <IconButton onClick={onClose}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ overflow: 'auto', height: '390px' }}>
          {
            fileColumns.map((obj, index) => (
              <Box
                key={index}
                draggable
                onDragStart={() => (dragIndex.current = index)}
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={() => (draggedOverIndex.current = index)}
                onDragEnd={handleDrop}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottom: '1px solid #e0e0e0',
                  pr: '9px',
                  cursor: 'grab'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={obj?.isDefault}
                    onChange={(e) => changeColumnVisiblity(e, index)}
                  />
                  <Typography sx={{ color: '#34475A' }}>{obj?.field || ''}</Typography>
                </Box>
                <DragIndicatorIcon fontSize='small' />
              </Box>
            ))
          }
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end', mt: '10px' }}>
          <Button
            size='small'
            variant='contained'
            onClick={applyChanges}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ColumnSelectionPopup