import ReactECharts from 'echarts-for-react'
import ReactApexChart from 'react-apexcharts'
import { Box, Divider, IconButton, Skeleton, Typography } from '@mui/material'
import { TbInfoSquare } from 'react-icons/tb'
import { formatCurrency } from '../../../../utils/FormateCurrenyInMilion'
import { HtmlTooltip } from '../../../../components/CustomTooltip'
import { useContext } from 'react'
import { SummaryContext } from '..'

const Distribution = () => {
  // data for distribution (donut chart)
  const summaryData = useContext(SummaryContext)
  const distributionData = (summaryData?.originalSummaryAllocation?.originalUMA?.allocationData || [])

  const getInitialsForNames = (accountNames = []) => {
    const initialsMap = new Map()
    return accountNames.map(name => {
      const words = name.split(' ')
      let initials = words[0][0].toUpperCase()

      for (let i = 1; i < words.length; i++) {
        const initial = words[i][0].toUpperCase()
        initials += initial

        if (!initialsMap.has(initials)) {
          initialsMap.set(initials, true)
          break
        }
      }

      return initials
    })
  }

  // Calculate total market value for the donut chart
  const totalMarketValue = distributionData.reduce((sum, item) => sum + item.totalMarketValue, 0)
  const formattedTotalMarketValue = formatCurrency(totalMarketValue)

  // Calculate total unrealized P/L for the stacked bar chart
  const totalUnrealizedPL = distributionData.reduce((sum, item) => sum + item.initSecInLoss + item.initSecInProfit, 0)
  const formattedTotalUnrealizedPL = formatCurrency(totalUnrealizedPL)

  const colors = ['#9D84E8', '#E684AE', '#F4B183', '#67E8F9', '#93C5FD']

  const donutOptions = {
    tooltip: {
      trigger: 'item',
      position: function (point, params, dom, rect, size) {
        // Calculate position to be to the right of the cursor
        return [point[0] + 10, point[1] - 50]
      },
      confine: false,
      formatter: (params) => {
        const percentage = ((params.value / totalMarketValue) * 100).toFixed(1)

        return `
          <div style="
            background: #232323;
            padding: 12px;
            border-radius: 6px;
            width: 240px;
            font-family: 'Open Sans', sans-serif;
          ">
            <div style="
              display: flex;
              align-items: center;
              gap: 4px;
              margin-bottom: 4px;
            ">
              <span style="
                width: 6px;
                height: 6px;
                background: ${params.color};
                border-radius: 50%;
                display: inline-block;
              "></span>
              <span style="
                color: white;
                font-size: 13px;
                font-weight: 600;
              ">${params.name}</span>
            </div>
            <div style="
              color: #B4B4B4;
              font-size: 13px;
              margin: 3px 0;
            ">
              Market Value - <span style="color: white; font-weight: 600;">$${params.value.toLocaleString()}</span>
            </div>
            <div style="
              color: #B4B4B4;
              font-size: 13px;
              margin: 3px 0;
            ">
              Allocation - <span style="color: white; font-weight: 600;">${percentage}%</span>
            </div>
            <hr style="
              border: none;
              height: 1px;
              background: #E6ECF3;
              margin: 4px 0;
            "/>
            <div style="
              color: white;
              font-size: 13px;
              font-weight: 600;
            ">
              Total Value <span style="color: #33A68D;">$${totalMarketValue.toLocaleString()}</span>
            </div>
          </div>
        `
      },
      backgroundColor: 'transparent',
      borderWidth: 0,
      shadowBlur: 0,
      extraCssText: 'box-shadow: none;'
    },
    series: [
      {
        name: 'Distribution',
        type: 'pie',
        radius: ['70%', '90%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 4,
          borderWidth: 2,
          borderColor: '#fff'
        },
        label: {
          show: false
        },
        emphasis: {
          label: {
            show: false
          }
        },
        labelLine: {
          show: false
        },
        data: distributionData.map((item, index) => ({
          value: item.totalMarketValue,
          name: item.accountName,
          itemStyle: {
            color: colors[index % colors.length]
          }
        }))
      }
    ]
  }

  const logTransform = (value) => {
    if (value == null || value === 0) return 0
    return value > 0 ? Math.log10(value) : Math.log10(Math.abs(value))
  }
  
  const transformedProfitData = distributionData?.map((item) => logTransform(item.initSecInProfit)) || []
  const transformedLossData = distributionData?.map((item) => 
    item?.initSecInLoss === 0 ? 0 : -logTransform(item.initSecInLoss)
  ) || []
  
  const urglSeries = [
    {
      name: 'Profit',
      data: transformedProfitData
    },
    {
      name: 'Loss',
      data: transformedLossData
    }
  ]
  
  const barOptions = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: { show: false },
      background: "transparent"
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "48px",
        borderRadius: 4,
        borderRadiusApplication: "around",
        borderRadiusWhenStacked: "all"
      }
    },
    legend: { show: false },
    colors: ["#A6E1BD", "#FF8080"],
    grid: {
      show: false,
      xaxis: {
        lines: { show: true }
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    xaxis: {
      categories: distributionData?.length
        ? getInitialsForNames(distributionData.map((item) => item.accountName))
        : [],
      labels: {
        show: distributionData?.length ? true : false,
        style: {
          colors: "#6A86A6",
          fontSize: "12px",
          fontFamily: "Open Sans"
        }
      },
      axisBorder: { show: false },
      axisTicks: { show: false },
      crosshairs: { show: false }
    },
    yaxis: {
      tickAmount: 4,
      labels: {
        style: {
          colors: "#6A86A6",
          fontSize: "12px",
          fontFamily: "Open Sans"
        },
        rotate: -90
      }
    },
    dataLabels: { enabled: false },
    stroke: { width: 1, colors: ["#fff"] },
    annotations: {
      yaxis: [
        {
          y: 0
        }
      ],
      xaxis: [
        {
          x: 0, // First category (can be adjusted dynamically)
          label: {
            text: "Profit",
            style: {
              color: "#6A86A6",
              fontSize: "12px",
              fontFamily: "Open Sans",
              fontWeight: 400
            },
            position: "top", // Places it above the x-axis
            borderWidth: 0,
            offsetY: 15
          }
        },
        {
          x: 0, // First category (can be adjusted dynamically)
          label: {
            text: "Loss",
            style: {
              color: "#6A86A6",
              fontSize: "12px",
              fontFamily: "Open Sans",
              fontWeight: 400
            },
            position: "bottom", // Places it below the x-axis,
            offsetY: 0,
            borderWidth: 0 
          }
        }
      ]
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        if (!distributionData[dataPointIndex]) return ""
        if (!series || !series[0] || !series[1]) return ""
  
        const item = distributionData[dataPointIndex];
        const profit = item.initSecInProfit ?? 0;
        const loss = Math.abs(item.initSecInLoss ?? 0);
        const overAllPL = profit - loss;
  
        return `
          <div style="
            background: #232323;
            padding: 12px;
            border-radius: 8px;
            min-width: 250px;
            font-family: 'Open Sans', sans-serif;
          ">
            <div style="
              display: flex;
              align-items: center;
              gap: 5px;
              margin-bottom: 8px;
            ">
              <span style="
                width: 7px;
                height: 7px;
                background: ${colors[dataPointIndex % colors.length]};
                border-radius: 50%;
                display: inline-block;
              "></span>
              <span style="
                color: white;
                font-size: 13px;
                font-weight: 700;
              ">${item.accountName}</span>
            </div>
            <div style="
              color: #B4B4B4;
              font-size: 13px;
              margin: 5px 0;
            ">
              Securities in Profit - <span style="color: white; font-weight: 700;">$${profit.toLocaleString()}</span>
            </div>
            <div style="
              color: #B4B4B4;
              font-size: 13px;
              margin: 5px 0;
            ">
              Securities in Loss - <span style="color: white; font-weight: 700;">$${loss.toLocaleString()}</span>
            </div>
            <hr style="
              border: none;
              height: 1px;
              background: #E6ECF3;
              margin: 8px 0;
            "/>
            <div style="
              color: white;
              font-size: 13px;
              font-weight: 700;
            ">
              Overall P&L <span style="color: #33A68D;">${overAllPL > 0 ? '+' : ''}${overAllPL.toLocaleString()}</span>
            </div>
          </div>
        `;
      }
    }
  };
  

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 4, mb: 4 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Typography sx={{ fontSize: '16px', fontFamily: 'Open Sans', color: '#2F2F2F', fontWeight: 600 }}>
              Allocation
            </Typography>
            <HtmlTooltip
              title='Allocation Information'
              tooltipStyle={{ backgroundColor: '#000', color: '#fff', fontSize: '11px', align: 'center' }}
            >
              <IconButton size='small'>
                <TbInfoSquare style={{ height: '20px', width: '20px', color: '#2F2F2F' }} />
              </IconButton>
            </HtmlTooltip>
            <Typography sx={{ ml: 'auto', color: '#032347', fontSize: '20px', fontFamily: 'Open Sans', fontWeight: 600 }}>
              ${formattedTotalMarketValue}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {
              summaryData?.isSummaryAllocationLoading ? (
                <Skeleton variant='rectangular' width='100%' height={200} />
              ) : (
                <>
                  {/* left legend */}
                  <Box sx={{ width: '50%' }}>
                    {distributionData.map((item, index) => (
                      <Typography key={item.accountId} sx={{ mb: 1, fontSize: '14px', fontWeight: 600 }}>
                        <Box
                          component='span'
                          sx={{
                            display: 'inline-block',
                            width: 12,
                            height: 12,
                            borderRadius: '50%',
                            bgcolor: colors[index % colors.length],
                            mr: 1
                          }}
                        />
                        ${Math.round(item?.totalMarketValue).toLocaleString()}
                      </Typography>
                    ))}
                  </Box>
                  <ReactECharts option={donutOptions} style={{ height: '200px', width: '50%' }} opts={{ renderer: 'svg' }} />
                </>
              )
            }

          </Box>
        </Box>
        <Divider
          sx={{
            width: '1px',
            orientation: 'vertical',
            background: 'linear-gradient(to bottom, rgba(230, 236, 243, 0), rgba(230, 236, 243, 1), rgba(230, 236, 243, 0))'
          }}
        />
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Typography sx={{ fontSize: '16px', fontFamily: 'Open Sans', color: '#2F2F2F', fontWeight: 600 }}>
              Unrealized Gain/Loss
            </Typography>
            <HtmlTooltip
              title='Unrealized Gain/Loss Information'
              tooltipStyle={{ backgroundColor: '#000', color: '#fff', fontSize: '11px', align: 'center' }}
            >
              <IconButton size='small'>
                <TbInfoSquare style={{ height: '20px', width: '20px', color: '#2F2F2F' }} />
              </IconButton>
            </HtmlTooltip>
            <Typography sx={{ ml: 'auto', color: '#032347', fontSize: '20px', fontFamily: 'Open Sans', fontWeight: 600 }}>
              {formattedTotalUnrealizedPL > 0 ? '+' : ''}${formattedTotalUnrealizedPL}
            </Typography>
          </Box>
          {/* <Box sx={{ display: 'flex' , alignItems: 'center' , justifyContent: 'center' , margin: '10px' , gap: 1}}>
            <Box sx={{ bgcolor: '#A6E1BD', p: 1, borderRadius: '3px', width: 16, height: 16 }} />
            <Box sx={{ fontSize: '12px', fontFamily: 'Open Sans', fontWeight: '600', color: '#000000B2' }}>Gain</Box>
            <Box sx={{ bgcolor: '#FF8080', p: 1, borderRadius: '3px', width: 16, height: 16 }} />
            <Box sx={{ fontSize: '12px', fontFamily: 'Open Sans', fontWeight: '600', color: '#000000B2' }}>Loss</Box>
          </Box> */}
          {
            summaryData?.isSummaryAllocationLoading
              ? (
                <Skeleton variant='rectangular' width='100%' height={200} />
              ) : (
              <ReactApexChart
                options={barOptions}
                series={urglSeries}
                type='bar'
                height={200}
              />)
          }
        </Box>
      </Box>
      {/* bottom legend */}
      <Box sx={{ display: 'flex', alignItems: 'center', height: 24, p: 1, m: 1, gap: 3, justifyContent: 'center' }}>
        {
          distributionData.map((item, index) => (
            <Box key={item.accountName} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', columnGap: 1 }}>
              <Box sx={{ bgcolor: `${colors[index % colors.length]}`, p: 1, borderRadius: '3px', width: 16, height: 16 }} />
              <Box sx={{ fontSize: '12px', fontFamily: 'Open Sans', fontWeight: '600', color: '#000000B2' }}>{item.accountName}</Box>
            </Box>
          ))
        }
      </Box>
    </Box>
  )
}

export default Distribution
