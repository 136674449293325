import { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useAuth } from '../../../contexts/AuthContext'
import GroupedList from './GroupListing'

const policyStatusList = [{ name: 'New change', color: '#34C38F' }, { name: 'Under Approval', color: '#EFAE3A' }, { name: 'Deactivating', color: '#F05F5F' }, { name: 'Approved', color: '#7BB1EF' }]

const StatementCard = ({ selectedData, selectedOpt, selectedSpr, value }) => {
  const [optimizerList, setOptimizerList] = useState(selectedOpt)
  const [selectedDataList, setSelectedDataList] = useState(selectedData)
  const [sponsorList, setSponsorList] = useState(selectedSpr)
  const { user } = useAuth()

  useEffect(() => {
    setOptimizerList(selectedOpt)
    setSelectedDataList(selectedData)
    setSponsorList(selectedSpr)
  }, [selectedOpt, selectedData, selectedSpr])

  const marketAndRiskData = selectedData?.reduce((acc, curr) => {
    const groupIndex = acc.findIndex((el) => el.policyGroup === curr.policyGroup)
    const subDataObj = {
      desc: curr.policyTitle,
      soft: curr.soft,
      value: curr.value,
      time: curr.time,
      statement: curr.policyText,
      checked: curr.checked,
      parameters: curr.parameters
    }

    if (groupIndex === -1) {
      acc.push({
        policyGroup: curr.policyGroup,
        policies: [subDataObj]
      })
    } else {
      acc[groupIndex].policies.push(subDataObj)
    }

    return acc
  }, [])

  const selectedSprData = selectedSpr?.reduce((acc, curr) => {
    const groupIndex = acc?.findIndex((el) => el.policyGroup === curr.policyGroup)
    const subDataObj = {
      desc: curr.policyTitle,
      soft: curr.soft,
      value: curr.value,
      time: curr.time,
      statement: curr.policyText,
      checked: curr.checked,
      parameters: curr.parameters
    }

    if (groupIndex === -1) {
      acc.push({
        policyGroup: curr.policyGroup,
        policies: [subDataObj]
      })
    } else {
      acc[groupIndex].policies.push(subDataObj)
    }

    return acc
  }, [])

  const OptData = selectedOpt?.reduce((acc, curr) => {
    const groupIndex = acc.findIndex((el) => el.policyGroup === curr.policyGroup)
    const subDataObj = {
      desc: curr.policyTitle,
      soft: curr.soft,
      value: curr.value,
      time: curr.time,
      statement: curr.policyText,
      checked: curr.checked,
      adornment: curr.adornment,
      status: curr.status,
      parameters: curr.parameters
    }

    if (groupIndex === -1) {
      acc.push({
        policyGroup: curr.policyGroup,
        policies: [subDataObj]
      })
    } else {
      const subDataIndex = acc[groupIndex].policies.findIndex((el) => el.policyTitle === curr.policyTitle)
      if (subDataIndex === -1) {
        acc[groupIndex].policies.push(subDataObj)
      } else {
        // Update the value if GroupIndex and subData exist
        acc[groupIndex].policies[subDataIndex].value = curr.value
      }
    }

    return acc
  }, [])

  return (
    <>
      <Box
        display='flex' alignItems='center' sx={{
          marginTop: '20px',
          marginLeft: '16px'
        }}
      >
        {
          (user?.userGroup === 'pm' ? policyStatusList.slice(1) : policyStatusList).map((status, index) => (
            <Box display='flex' alignItems='center' key={index} mr={1}>
              <Box sx={{
                width: '15px',
                height: '15px',
                background: status.color,
                borderRadius: '5px'
              }}
              />
              <Typography sx={{ fontSize: '14px', fontWeight: 500, fontFamily: 'Open Sans', ml: 1 }}>{status.name}</Typography>
            </Box>))
        }
      </Box>
      <Box
        style={{
          backgroundColor: '#FFFFFFF',
          marginTop: '10px',
          marginLeft: '16px',
          width: 'auto',
          fontFamily: 'Open Sans',
          height: 'fit-content',
          boxShadow: ' 0px 7px 29px rgba(100, 100, 111, 0.2)',
          borderRadius: '10px'
        }}
      >
          {value === 0
            ? (
                marketAndRiskData?.map((groupData, index) => (
                  <GroupedList
                    key={groupData.policyGroup}
                    title={groupData.policyGroup}
                    items={groupData.policies}
                    isFirst={index === 0}
                  />
                ))
              )
            : value === 1
              ? (
                  selectedSprData?.length
                    ? <GroupedList
                        key='Account Customization'
                        title='Account Customization'
                        items={selectedSprData?.flatMap((groupData) => groupData.policies)}
                      />
                    : ''
                )
              : value === 2
                ? (
                    OptData?.length
                      ? <GroupedList
                          key='optimization'
                          title='Optimization'
                          items={OptData?.flatMap((groupData) => groupData.policies)}
                        />
                      : ''
                  )
                : (
                  <Box style={{ padding: '20px' }}>No policy is active.</Box>
                  )}
      </Box>
    </>
  )
}
export default StatementCard
