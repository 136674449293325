import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isSidebarOpen: true
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  // action as functions
  reducers: {
    setSidebarVisibility (state, action) {
      state.isSidebarOpen = action.payload.isSidebarOpen
    }
  }
})

export const { setSidebarVisibility } = layoutSlice.actions
export default layoutSlice.reducer
