import { styled, Tab, Tabs } from '@mui/material'

const SecondaryTabs = ({ selectedTab, tabsList, handleTabChange }) => {  
  return (
    <>
      <CustomTabs
        value={selectedTab}
        onChange={handleTabChange}
      >
        {
          tabsList.map((item, index) => (
            <CustomTab
              key={index}
              value={item.id}
              disableRipple
              label={item.label}
            />
          ))
        }
      </CustomTabs>
    </>
  )
}

const CustomTabs = styled(Tabs)({
  minHeight: 43,
  '& .MuiTabs-flexContainer': {
    position: 'relative',
    zIndex: 1
  },
  '& .MuiTabs-indicator': {
    border: '1px solid transparent',
    backgroundImage: 'linear-gradient(360deg, #FBFBFD 0%, #E6ECF3 100%),linear-gradient(to bottom, #5A7FB5, #8CAAD9 50%, #E6ECF3)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    borderBottom: '0px',
    borderRadius: '6px 6px 0px 0px',
    bottom: 3,
    height: 'auto',
    right: 3,
    top: 3
  }
})

const CustomTab = styled(Tab)({
  padding: '12px 16px 12px 16px',
  gap: '8px',
  fontFamily: 'Open Sans',
  textTransform: 'capitalize',
  color: '#2F2F2F',
  fontWeight: 400,
  '&.Mui-selected': {
    color: '#1A4B83',
    fontWeight: 600
  }
})

export default SecondaryTabs
