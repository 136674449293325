import { useContext } from 'react'
import { Grid } from '@mui/material'
import StrategyDistribution from '../OriginalSummaryTab/StrategyDistribution'
import TaxFocusedProgress from '../SolutionSummaryTab/TaxFocusedProgress'
import PerformanceLineChart from '../SolutionSummaryTab/PerformanceLineChart'
import { Card } from '../components'
import { ScenarioContext } from '..'

const PerformanceTab = () => {
  const scenarioData = useContext(ScenarioContext)
  const isLoading = scenarioData?.scenarioPerformanceData[scenarioData?.aggId]?.loading
  const scenario = scenarioData?.scenarioPerformanceData[scenarioData?.aggId]?.data?.taxCostScenarioData

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Card>
            <PerformanceLineChart isScenarioScreen />
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card>
                <TaxFocusedProgress scenario={scenario} loading={isLoading} isScenarioScreen />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <StrategyDistribution isScenarioScreen />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default PerformanceTab
