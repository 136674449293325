import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Tab, Tabs } from '@mui/material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import * as Yup from 'yup'
import AccountTitle from '../../../components/AccountTitle'
import { useAccountService } from '../../../hooks/useAccountService'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import { storeAccountData } from '../../../store/dashboard-reducer/dashboard-reducer'
import Loader from '../../Loader'
import PersonalDetails from './PersonalDetails'

dayjs.extend(utc)

const tabs = ['Account Details']

const defaultFields = [
  'name', 'email', 'accountType', 'startDate', 'endDate',
  'fundingSource', 'isTaxManaged', 'statusId', 'portSpecId'
]

// key: value => formikKey => backendPayloadKey
const objectFields = {
  advData: 'advId',
  secAdvData: 'secAdvId',
  sellLogicData: 'sellLogicId'
}

const tradingFields = ['custodianName', 'custodianAccountNumber', 'custodianMasterAccId', 'billStartDate']
const taxManagedFields = ['stateId', 'taxSensitivity', 'longTermTaxRate', 'shortTermTaxRate']

const ManageAccountProfile = () => {
  const { user } = useAuth()
  const params = useParams()
  const [value, setValue] = useState('Account Details')
  const { showSuccess } = useSuccessToast()
  const [defaultAccountDetails, setDefaultAccountDetails] = useState({
    fieldData: {},
    fieldEditAllowed: {},
    fieldLabels: {}
  })
  const [isAccountDetailsApiLoading, setIsAccountDetailsApiLoading] = useState(false)
  const { getBoBAccountListUtil } = useAccountService()
  const [submitApiLoading, setSubmitApiLoading] = useState(false)
  const { showError } = useErrorToast()
  const dispatch = useDispatch()
  const [editFormDataFlag, setEditFormDataFlag] = useState(false)
  const [enableReinitialize, setEnableReinitialize] = useState(true)

  useEffect(() => {
    getAccountDetails()
  }, [user])

  const createFromValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .min(1, 'Name must contain at least one character')
      .max(100, 'Name is Too Long!'),
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email')
      .max(255, 'Email can not be more than 255 characters')
      .min(1, 'Email can not be less than 1 character'),
    custodianAccountNumber: Yup.string()
      .when('accountType', {
        is: (val) => val === 'TRADING',
        then: (schema) => schema.required('Account number is required'),
        otherwise: (schema) => schema.notRequired()
      }),
    custodianMasterAccId: Yup.string()
      .when(['accountType', 'custodianName'], {
        is: (accountType, custodianName) =>
          accountType === 'TRADING' && custodianName === 'Charles Schwab',
        then: (schema) => schema.required('Custodian master account is required'),
        otherwise: (schema) => schema.notRequired()
      }),
    advData: Yup.object()
      .required('Financial advisor is required'),
    stateId: Yup.string()
      .when('isTaxManaged', {
        is: true,
        then: (schema) => schema.required('Tax State is required'),
        otherwise: (schema) => schema.notRequired()
      }),
    isTaxManaged: Yup.boolean().required('Tax Managed is required'),
    taxSensitivity: Yup.string()
      .when('isTaxManaged', {
        is: true,
        then: (schema) => schema.required('Tax sensitivity is required'),
        otherwise: (schema) => schema.notRequired()
      }),
    shortTermTaxRate: Yup.string().when('isTaxManaged', {
      is: true,
      then: (schema) => schema.required('Short term taxRate is required')
        .test('valid-range', 'Long term tax rate must be between 0 and 100', value => {
          const numericValue = parseFloat(value)
          return numericValue >= 0 && numericValue <= 100
        }),
      otherwise: (schema) => schema.notRequired()
    }),
    longTermTaxRate: Yup.string().when('isTaxManaged', {
      is: true,
      then: (schema) => schema.required('Long term taxRate is required')
        .test('valid-range', 'Long term tax rate must be between 0 and 100', value => {
          const numericValue = parseFloat(value)
          return numericValue >= 0 && numericValue <= 100
        }),
      otherwise: (schema) => schema.notRequired()
    }),
    sellLogicData: Yup.object().required('Sell logic code is required'),
    minCash: Yup.number().nullable()
      .typeError('Min Cash Level must be a number'),
    maxCash: Yup.number().nullable()
      .typeError('Max Cash Level must be a number')
  })

  const validateMinMaxCash = (values) => {
    const errors = {}

    const hasMinCash = values.minCash !== undefined && values.minCash !== null && values.minCash !== ''
    const hasMaxCash = values.maxCash !== undefined && values.maxCash !== null && values.maxCash !== ''

    if (hasMinCash && !hasMaxCash) {
      errors.maxCash = 'Max Cash Level is required'
    }

    if (!hasMinCash && hasMaxCash) {
      errors.minCash = 'Min Cash Level is required'
    }

    if (hasMinCash && hasMaxCash) {
      const minCash = parseFloat(values.minCash)
      const maxCash = parseFloat(values.maxCash)

      if (!isNaN(minCash) && !isNaN(maxCash) && maxCash < minCash) {
        errors.minCash = 'Minimum cash level should be less than max cash level'
        errors.maxCash = 'Maximum cash level should be greater than min cash level'
      } else if (!isNaN(minCash) && !isNaN(maxCash) && (maxCash - minCash) < 0.5) {
        errors.maxCash = 'Difference between min and max cash level should be minimum of 0.5%'
        errors.minCash = 'Difference between min and max cash level should be minimum of 0.5%'
      }
    }

    return errors
  }

  const initialFormValues = {
    name: '',
    email: '',
    accountType: '',
    custodianName: '',
    custodianAccountNumber: '',
    custodianMasterAccId: '',
    statusId: '',
    statusDesc: '',
    portSpecId: '',
    portSpecName: '',
    advData: null,
    secAdvData: null,
    startDate: null,
    endDate: null,
    billStartDate: null,
    isTaxManaged: false,
    stateId: '',
    taxSensitivity: '',
    longTermTaxRate: null,
    shortTermTaxRate: null,
    sellLogicData: null,
    fundingSource: '',
    minCash: null,
    maxCash: null
  }

  const formik = useFormik({
    initialValues: defaultAccountDetails?.fieldData
      ? {
          ...defaultAccountDetails.fieldData,
          advData: {
            advId: defaultAccountDetails?.fieldData?.advId || '',
            advName: defaultAccountDetails?.fieldData?.advName || ''
          },
          secAdvData: {
            secAdvId: defaultAccountDetails?.fieldData?.secAdvId || '',
            secAdvName: defaultAccountDetails?.fieldData?.secAdvName || ''
          },
          sellLogicData: {
            sellLogicId: defaultAccountDetails?.fieldData?.sellLogicId || '',
            sellLogicCode: defaultAccountDetails?.fieldData?.sellLogicCode || ''
          },
          startDate: defaultAccountDetails?.fieldData?.startDate
            ? dayjs.utc(defaultAccountDetails.fieldData.startDate).format('YYYY-MM-DD')
            : null,
          endDate: defaultAccountDetails?.fieldData?.endDate
            ? dayjs.utc(defaultAccountDetails.fieldData.endDate).format('YYYY-MM-DD')
            : null,
          billStartDate: defaultAccountDetails?.fieldData?.billStartDate
            ? dayjs.utc(defaultAccountDetails.fieldData.billStartDate).format('YYYY-MM-DD')
            : null,
          isTaxManaged: defaultAccountDetails?.fieldData?.isTaxManaged
            ? Boolean(defaultAccountDetails?.fieldData?.isTaxManaged)
            : false
        }
      : initialFormValues,
    validationSchema: createFromValidationSchema,
    enableReinitialize,
    validate: validateMinMaxCash,
    onSubmit: (values) => {
      setEnableReinitialize(false)

      const filteredValues = {}

      const validateEntry = (key, value) => {
        if (value !== undefined && value !== null && value !== '' && value !== 'Invalid Date' && defaultAccountDetails.fieldEditAllowed[key]) {
          filteredValues[key] = value
        }
      }

      filteredValues.minCash = values.minCash ?? null
      filteredValues.maxCash = values.maxCash ?? null

      let fieldsToValidate = [...defaultFields]
      // only add tradingFields fields if account type is TRADING
      if (values.accountType === 'TRADING') {
        fieldsToValidate = [...fieldsToValidate, ...tradingFields]
      }
      // only add taxManagedFields fields if tax managed is true
      if (values.isTaxManaged) {
        fieldsToValidate = [...fieldsToValidate, ...taxManagedFields]
      }

      // validate all default fields which are not converted to object in initial formik values
      fieldsToValidate?.forEach((key) => validateEntry(key, values[key]))

      // validate all default fields which are converted to object in initial formik values
      Object.entries(objectFields).forEach(([key, nestedKey]) => {
        validateEntry(nestedKey, values[key]?.[nestedKey])
      })

      handleFormSubmit(filteredValues)
    }
  })

  const handleFormSubmit = (values) => {
    setSubmitApiLoading(true)
    API.patch('baseUriAccountOptimization', `account-customization/v1/${user.userGroup}/account-profile-details/${params?.accountId}`, {
      body: {
        ...values
      }
    }).then((res) => {
      if (res && res.success && res?.data && res?.data?.length) {
        setDefaultAccountDetails(prev =>
          ({
            ...prev,
            fieldData: {
              ...prev.fieldData,
              maskedCustodianAccountNumber: res?.data[0]?.maskedCustodianAccountNumber,
              name: res?.data[0]?.name
            },
            fieldEditAllowed: { ...prev.fieldEditAllowed, billStartDate: !formik.values.billStartDate }
          })
        )
        setEditFormDataFlag(true)
        dispatch(storeAccountData([]))
        formik.resetForm({ values: formik.values })
        localStorage.removeItem('object')
        getAccountsList()
        showSuccess(res?.message)
      }
    }).catch((error) => {
      showError(error, false, {}, 'Failed to update profile data.')
    }).finally(() => setSubmitApiLoading(false))
  }

  const getAccountsList = async () => {
    getBoBAccountListUtil({ serviceId: 'book-of-business', resourceId: 'book-of-business' }, { 'cache-override': true })
      .then((response) => {
        if (response?.data?.accountsSummaryDetails) {
          dispatch(storeAccountData(response.data.accountsSummaryDetails))
          const storeArray = []
          response?.data?.accountsSummaryDetails?.forEach((element) => {
            storeArray.push({
              accountName: element.accountName,
              accountType: element.accountType,
              accountCode: element.accountCode,
              strategyId: element.strategyId,
              custodianAccountNumber: element?.custodianAccountNumber,
              portSpecName: element.portSpecName,
              accountId: element.accountId,
              totalMarketValue: element.totalMarketValue,
              isTaxable: element.isTaxable,
              strTypeCode: element.strTypeCode
            })
          })
          localStorage.setItem('object', JSON.stringify(storeArray))
        }
      })
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }

  const getAccountDetails = () => {
    setIsAccountDetailsApiLoading(true)
    API.get('baseUriAccountOptimization', `account-customization/v1/${user.userGroup}/account-profile-details/${params?.accountId}`)
      .then((res) => {
        if (res && res?.data && res?.data?.data && res?.data?.data?.length) {
          setDefaultAccountDetails({
            fieldData: res?.data?.data[0],
            fieldEditAllowed: res?.data?.fieldEditAllowed,
            fieldLabels: res?.data?.fieldLabels
          })
        }
      }).catch((error) => {
        showError(error, false, {}, 'Failed to load account profile details.')
      }).finally(() => setIsAccountDetailsApiLoading(false))
  }

  const renderTabPanel = (item) => {
    switch (item) {
      case 'Account Details':
        return (
          <PersonalDetails formik={formik} defaultAccountDetails={defaultAccountDetails} submitApiLoading={submitApiLoading} getAccountsList={getAccountsList} />
        )
      default:
    }
  }

  const accountTitleData = {
    name: defaultAccountDetails?.fieldData?.name,
    maskedCustodianAccountNumber: defaultAccountDetails?.fieldData?.maskedCustodianAccountNumber
  }

  return (
    <Box>
      {isAccountDetailsApiLoading ? <Loader /> : ''}
      <Box
        sx={{
          display: 'flex',
          alignItems: { xs: 'start', md: 'center' },
          justifyContent: 'space-between'
        }}
      >
        <AccountTitle title='Edit Profile' updatedAccountTitleData={editFormDataFlag ? accountTitleData : {}} />
      </Box>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            variant='standard'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#34475A'
              }
            }}
            indicatorColor='#34475A'
          >
            {
              tabs?.map((item) => (
                <Tab
                  key={item}
                  value={item}
                  label={item}
                />))
            }
          </Tabs>
        </Box>
        {tabs?.map((item) => (
          <TabPanel
            key={item}
            value={item}
            sx={{ padding: 0, position: 'relative' }}
          >
            {item === value && renderTabPanel(item)}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  )
}

export default ManageAccountProfile
