import { useState } from 'react'
import { Box, IconButton, Modal, Tooltip } from '@mui/material'
import eyefill from '../../../../assets/images/eye-fill.svg'
import { tradeModalStyle } from './tradeModalStyle'
import TradeDetailsCompactPopup from './TradeDetails/TradeDetailsCompactPopup'

const CustomTradeDetailsCell = (params) => {
  const [editIndex, setEditIndex] = useState()
  const [tradeAccountId, setTradeAccountId] = useState()
  const [isShown, setIsShown] = useState(false)

  const viewHandleShow = (index, opt, accId) => {
    setEditIndex(index)
    setTradeAccountId(accId)
    setIsShown(true)
  }

  const handleClose = () => {
    setIsShown(false)
  }

  return (
    <>
      <IconButton
        size='small'
        className={params.row?.failedScenario ? 'disabled-action-btn action-clock-btn' : 'action-clock-btn'}
        onClick={() => {
          !params.row?.failedScenario && viewHandleShow(
            params.row?.accOptMapId,
            params.row?.account?.optId,
            params?.row?.account?.accountId
          )
        }}
      >
        <Tooltip title='Trade Details'>
          <img
            src={eyefill}
            alt='eyefill'
          />
        </Tooltip>
      </IconButton>
      {/* <Modal
        open={isShown}
        onClose={() => setIsShown(false)}
      >
        <Box sx={{ ...tradeModalStyle, height: '100vh', width: '100vw', maxWidth: 'unset', top: 0, left: 0, transform: 'none', paddingTop: 1, paddingBottom: '8px !important' }}>
          <ViewTradeDetails
            index={editIndex}
            accID={tradeAccountId}
            setIsShown={setIsShown}
            optId={params.row?.account?.optId}
            accountCode={params?.row?.accountCd}
            isDILink={params?.row?.isDILink && params?.canAccessDIAssist}
            DILinkType={params?.row?.DILinkType}
          />
        </Box>
      </Modal> */}
      <Modal
        open={isShown}
        onClose={() => setIsShown(false)}
      >
        <Box sx={{ ...tradeModalStyle, height: '100vh', width: '100vw', overflow:'auto', maxWidth: 'unset', top: 0, left: 0, transform: 'none', paddingTop: 1, paddingBottom: '8px !important' }}>
          <TradeDetailsCompactPopup
            {...params}
            index={editIndex}
            accID={tradeAccountId}
            onClose={handleClose}
            optId={params.row?.account?.optId}
            accountCode={params?.row?.accountCd}
            isDILink={params?.row?.isDILink}
            DILinkType={params?.row?.DILinkType}
          />
        </Box>
      </Modal>
    </>
  )
}

export default CustomTradeDetailsCell
