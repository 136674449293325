import React, { useContext } from 'react'
import { Box, Grid, Paper, Skeleton, Tooltip, Typography } from '@mui/material'
import { formatCurrencyWithSymbol } from '../../../../utils/FormateCurrenyInMilion'
import { UmaContext } from '..'

const getColorBasedOnValue = number => number > 0 ? '#33A68D' : '#FF6A6A'

const KPICards = () => {
  const umaContextData = useContext(UmaContext)
  const isLoading = umaContextData.topCard?.loading
  const topCardData = umaContextData.topCard?.data

  return (
    <Grid container spacing={2} mt={2}>
      {
        isLoading ? (
          Array.from({ length: 4 }).map((_, i) => (
            <Grid item sm={6} md={3} key={i}>
              <KPICard>
                <Skeleton variant='text' sx={{ fontSize: '22px', width: 40 }} />
                <Skeleton variant='text' sx={{ fontSize: '16px', width: 100 }} />
              </KPICard>
            </Grid>
          ))
        ) : (
          <>
            <Grid item sm={6} md={3}>
              <KPICard>
                {
                  topCardData?.totalMarketValue !== undefined ? (
                    <Tooltip title={(topCardData?.totalMarketValue >= 0 ? '' : '-') + '$' + topCardData?.totalMarketValue?.toFixed(2)}>
                      <Typography className='kpi-value'>
                        {formatCurrencyWithSymbol(topCardData?.totalMarketValue, 2, '$')}
                      </Typography>
                    </Tooltip>
                  ) : '-'
                }
                <Typography>Total Market Value</Typography>
              </KPICard>
            </Grid>
            <Grid item sm={6} md={3}>
              <KPICard>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {
                    topCardData?.trackingError !== undefined ? (
                      <Tooltip title={(topCardData?.trackingError * 100).toFixed(2) + '%'}>
                        <Typography className='kpi-value'>
                          {Number((topCardData?.trackingError) * 100).toFixed(2) + '%'}
                        </Typography>
                      </Tooltip>
                    ) : '-'
                  }
                  {
                    topCardData?.changeInTrackingError !== undefined ? (
                      <Typography className='kpi-percentage' sx={{ color: getColorBasedOnValue(topCardData?.changeInTrackingError) }}>
                        ({Number(topCardData?.changeInTrackingError).toFixed(2)}%)
                      </Typography>
                    ) : ''
                  }
                </Box>
                <Typography>Tracking Error</Typography>
              </KPICard>
            </Grid>
            {
              topCardData?.aggType === 'TRANSITION' ? (
                <>
                  <Grid item sm={6} md={3}>
                    <KPICard>
                      {
                        topCardData?.cashValue !== undefined ? (
                          <Tooltip title={(topCardData?.cashValue >= 0 ? '' : '-') + '$' + topCardData?.cashValue?.toFixed(2)}>
                            <Typography className='kpi-value'>
                              {formatCurrencyWithSymbol(topCardData?.cashValue, 2, '$')}
                            </Typography>
                          </Tooltip>
                        ) : '-'
                      }
                      <Typography>Cash</Typography>
                    </KPICard>
                  </Grid>
                  <Grid item sm={6} md={3}>
                    <KPICard>
                      {
                        topCardData?.ugl !== undefined ? (
                          <Tooltip title={(topCardData?.ugl >= 0 ? '' : '-') + '$' + topCardData?.ugl?.toFixed(2)}>
                            <Typography className='kpi-value'>
                              {formatCurrencyWithSymbol(topCardData?.ugl, 2, '$')}
                            </Typography>
                          </Tooltip>
                        ) : '-'
                      }
                      <Typography>Unrealized Gain/Loss</Typography>
                    </KPICard>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item sm={6} md={3}>
                    <KPICard>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {
                          topCardData?.rgl !== undefined ? (
                            <Tooltip title={(topCardData?.rgl >= 0 ? '' : '-') + '$' + topCardData?.rgl?.toFixed(2)}>
                              <Typography className='kpi-value'>
                                {formatCurrencyWithSymbol(topCardData?.rgl, 2, '$')}
                              </Typography>
                            </Tooltip>
                          ) : '-'
                        }
                        <Typography className='kpi-percentage' sx={{ color: getColorBasedOnValue(topCardData?.changeInRgl) }}>
                          {
                            topCardData?.changeInRgl !== undefined ? (
                              '(' + (topCardData?.changeInRgl > 0 ? '+' : '') + topCardData?.changeInRgl?.toFixed(2) + '%)'
                            ) : ''
                          }
                        </Typography>
                      </Box>
                      <Typography>Realized Gain/Loss</Typography>
                    </KPICard>
                  </Grid>
                  <Grid item sm={6} md={3}>
                    <KPICard>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {
                          topCardData?.taxCost !== undefined ? (
                            <Tooltip title={(topCardData?.taxCost >= 0 ? '' : '-') + '$' + topCardData?.taxCost?.toFixed(2)}>
                              <Typography className='kpi-value'>
                                {formatCurrencyWithSymbol(topCardData?.taxCost, 2, '$')}
                              </Typography>
                            </Tooltip>
                          ) : '-'
                        }
                        <Typography className='kpi-percentage' sx={{ color: getColorBasedOnValue(topCardData?.changeInTaxCost) }}>
                          {
                            topCardData?.changeInTaxCost !== undefined ? (
                              '(' + (topCardData?.changeInTaxCost > 0 ? '+' : '') + topCardData?.changeInTaxCost?.toFixed(2) + '%)'
                            ) : ''
                          }
                        </Typography>
                      </Box>
                      <Typography>Tax Cost</Typography>
                    </KPICard>
                  </Grid>
                </>
              )
            }
          </>
        )
      }
    </Grid>
  )
}

const KPICard = ({ children }) => {
  return (
    <Paper
      sx={{
        background: 'rgba(23, 92, 194, 0.08)',
        boxShadow: '0px 4px 4px rgba(116, 120, 141, 0.5)',
        borderRadius: '10px',
        padding: '18px 30px',
        color: '#032347',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '& .kpi-value': {
          fontWeight: 600,
          fontSize: '22px'
        },
        '& .kpi-percentage': {
          fontWeight: 600,
          fontSize: '14px',
          marginLeft: '5px'
        }
      }}
    >
      <Box className='kpi-value' sx={{ fontSize: '22px', fontWeight: 600 }}>
        {children}
      </Box>
    </Paper>
  )
}

export default KPICards
