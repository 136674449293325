import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton, Tooltip } from '@mui/material'
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'
import { ViewSummary } from '../ViewSummary'


const SummaryColumnCell = (params) => {
  const [isSummaryShown, setIsSummaryShown] = useState(false)

  const handleShowModal = () => {
    setIsSummaryShown(true)
  }

  const handleCloseModal = () => {
    setIsSummaryShown(false)
  }

  return (
    <>
      <Tooltip title='View Summary'>
        <IconButton onClick={() => handleShowModal()}>
          <SummarizeOutlinedIcon sx={{ fontSize: 18, color: '#74788D' }} />
        </IconButton>
      </Tooltip>
      {
        isSummaryShown ? (
          <Dialog
            open={isSummaryShown}
            onClose={handleCloseModal}
            PaperProps={{
              sx: {
                width: '1100px',
                maxWidth: '100%',
              }
            }}>
            <DialogContent
              sx={{ px: 4, py: 2 }}>
              <ViewSummary params={params} onClose={handleCloseModal} />
            </DialogContent>
          </Dialog>
        ) : ''
      }
    </>
  )
}

export default SummaryColumnCell