import { TextField } from '@mui/material'
import dayjs from 'dayjs'
import { isNumberWithSign, isWholeNumber, numberWithSignPasteHandler, wholeNumberPasteHandler } from '../../../utils/NumberUtils'
import { AccountCodeSelect, PassiveScenarioSelect } from './InputComponent'

const AdhocOptPropertyValueField = ({ dataType, propertyValue, formik, propertyCode, accountList, allScenarioList }) => {
  const handleChange = (e) => {
    formik.setFieldValue('propertyValue', e.target.value)
  }
  if (propertyCode === 'DI_ASSIST_ACC_LINK') {
    const handleChange = (_, value) => {
      formik.setFieldValue('propertyValue', value?.accountCode ?? '')
    }
    return (
      <AccountCodeSelect
        accountList={accountList}
        onChange={handleChange}
        value={propertyValue}
        error={Boolean(formik.errors.propertyValue && formik.touched.propertyValue)}
        helperText={
          formik.errors.propertyValue &&
          formik.touched.propertyValue &&
          String(formik.errors.propertyValue)
        }
      />
    )
  }
  if (propertyCode === 'DI_PASSIVE_SCENARIO') {
    const handleChange = (_, value) => {
      formik.setFieldValue('propertyValue', value?.scenarioId ?? '')
    }
    return (
      <PassiveScenarioSelect
        allScenarioList={allScenarioList}
        onChange={handleChange}
        value={propertyValue}
        error={Boolean(formik.errors.propertyValue && formik.touched.propertyValue)}
        helperText={
          formik.errors.propertyValue &&
          formik.touched.propertyValue &&
          String(formik.errors.propertyValue)
        }
      />
    )
  }

  switch (dataType) {
    case 'INT':
    case 'DECIMAL':
    case 'STRING':
    case 'BOOLEAN':
      return (
        <TextField
          fullWidth
          label='Property Value'
          size='small'
          value={propertyValue}
          onChange={handleChange}
          onKeyDown={dataType === 'INT' ? isWholeNumber : dataType === 'DECIMAL' ? isNumberWithSign : undefined}
          onPaste={dataType === 'INT' ? wholeNumberPasteHandler : dataType === 'DECIMAL' ? numberWithSignPasteHandler : undefined}
          error={Boolean(formik.errors.propertyValue && formik.touched.propertyValue)}
          helperText={
            formik.errors.propertyValue &&
            formik.touched.propertyValue &&
            String(formik.errors.propertyValue)
          }
          FormHelperTextProps={{
            style: { marginLeft: '5px' }
          }}
        />
      )
    case 'DATE':
      return (
        <TextField
          label='Property Value'
          type='date'
          fullWidth
          variant='outlined'
          size='small'
          value={dayjs.utc(propertyValue).format('YYYY-MM-DD')}
          onChange={(event) => {
            formik.setFieldValue('propertyValue', event.target.value)
          }}
          error={Boolean(formik.errors.propertyValue && formik.touched.propertyValue)}
          helperText={
            formik.errors.propertyValue &&
            formik.touched.propertyValue &&
            String(formik.errors.propertyValue)
          }
          InputLabelProps={{ shrink: true }}
          FormHelperTextProps={{
            style: { marginLeft: '5px' }
          }}
        />
      )

    default:
      return null
  }
}

export default AdhocOptPropertyValueField
