import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import { CSVLink } from 'react-csv'
import { ErrorBoundary } from 'react-error-boundary'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, Chip, Divider, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Switch, Tab, Tooltip } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { BiLockOpen } from 'react-icons/bi'
import AccountTitle from '../../../components/AccountTitle'
import { useAuth } from '../../../contexts/AuthContext'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL, MODEL_WEIGHT_DISPLAY_MODULES } from '../../../contstants/constants'
import useDownloadLogs from '../../../hooks/useDownloadLogs'
import { useErrorToast } from '../../../hooks/useErrorToast'
import ErrorFallback from '../../ErrorFallback'
import Loader from '../../Loader'
import '../import-portfolio.scss'
import CurrentImport from './CurrentImport'
import UploadForm from './UploadForm'

const taxlotCsvHeaders = [
  { label: 'Name', key: 'instrName' },
  { label: 'Symbol', key: 'localSymbol' },
  { label: 'Account Weight', key: 'accountLotWeight' },
  { label: 'Purchase Date', key: 'purchaseDate' },
  { label: 'Total Purchase Cost', key: 'purchaseCost' },
  { label: 'Original Purchase Price', key: 'orgPurchasePrice' },
  { label: 'Market Value', key: 'marketValue' },
  { label: 'Unrealized Gain/Loss', key: 'urgl' },
  { label: 'Quantity / Shares', key: 'shares' },
  { label: 'ISO Country Code', key: 'isoCountryCode' }
]

const companyCsvHeaders = [
  { label: 'Level', key: 'level' },
  { label: 'Name', key: 'name' },
  { label: 'Symbol', key: 'localSymbol' },
  { label: 'Account Weight', key: 'accountLotWeight' },
  { label: 'Model Weight', key: 'modelWeight' },
  { label: 'Total Purchase Cost', key: 'purchaseCost' },
  { label: 'Unrealized Gain/Loss', key: 'urgl' },
  { label: 'MCTE', key: 'mcte' },
  { label: 'Market Value', key: 'marketValue' },
  { label: 'Model Drift', key: 'modelDrift' },
  { label: 'Quantity / Shares', key: 'shares' },
  { label: 'Purchase Date', key: 'purchaseDate' },
  { label: 'Original Purchase Price', key: 'orgPurchasePrice' }
]

// headers for Download Portfolio In Import Format
const importHeaders = [
  { label: 'Symbol', key: 'localSymbol' },
  { label: 'Purchase Date', key: 'purchaseDate' },
  { label: 'Total Purchase Cost', key: 'purchaseCost' },
  { label: 'Quantity / Shares', key: 'shares' },
  { label: 'ISO Country Code', key: 'isoCountryCode' }
]

const AccountPortfolioImport = () => {
  const { showError } = useErrorToast()
  const { user, checkAccess } = useAuth()
  const params = useParams()
  const [originalTaxlot, setOriginalTaxlot] = useState([])
  const [companyWiseTaxlot, setCompanyWiseTaxlot] = useState([])
  const [taxLotDataDownloadFormat, setTaxLotDataDownloadFormat] = useState([])
  const [companyDataDownloadFormat, setCompanyDataDownloadFormat] = useState([])
  const [noDataMsg, setNoDataMsg] = useState('')
  const [isTaxlotLoading, setIsTaxlotLoading] = useState(true)
  const [currentTab, setCurrentTab] = useState('Current Portfolio')
  const [portfolioLevel, setPortfolioLevel] = useState('Taxlot')
  const [ignoreLotSwitch, setIgnoreLotSwitch] = useState(false)
  const { createCSVDownloadLog } = useDownloadLogs()

  const accountListInString = localStorage.getItem('object')
  const accountList = accountListInString ? JSON.parse(accountListInString) : []
  const [selectedAccount, setSelectedAccount] = useState(accountList?.find((item) => item?.accountId === params?.accountId))
  const [accountOptStatusData, setAccountOptStatusData] = useState({})
  const [isAccountOptStatusLoading, setIsAccountOptStatusLoading] = useState(false)

  // Function to show the colors for the status chip
  const getColorProperties = (data) => {
    if (data === 'OPT_ELIGIBLE') {
      return {
        color: '#3BBFA3',
        border: '1px solid #3BBFA3 !important'
      }
    } else if (data === 'OPT_HOLD') {
      return {
        color: '#D29922',
        border: '1px solid #D29922 !important'
      }
    } else if (data === 'OPT_HOLD_PORTFOLIO_BREAK') {
      return {
        color: '#FF6161',
        border: '1px solid #FF6161 !important'
      }
    }
  }

  // CSV config for Download Portfolio In Displayed taxlot Format
  const displayedTaxlotCsvConfig = {
    filename: 'Export-Current-Portfolio.csv',
    headers: taxlotCsvHeaders,
    data: taxLotDataDownloadFormat || originalTaxlot || []
  }

  // CSV config for Download Portfolio at company level
  const displayedCompanyCsvConfig = {
    filename: 'Export-Current-Portfolio.csv',
    headers: companyCsvHeaders,
    data: companyDataDownloadFormat || companyWiseTaxlot || []
  }

  // CSV config for Download Portfolio In Import Format
  const importFormatCsvConfig = {
    filename: 'Import-Current-Portfolio-format.csv',
    headers: importHeaders,
    data: (taxLotDataDownloadFormat && taxLotDataDownloadFormat?.length ? taxLotDataDownloadFormat?.filter((data) => data.taxlotId != null) : []) ||
      (originalTaxlot && originalTaxlot?.length ? originalTaxlot?.filter((data) => data.taxlotId != null) : []) || []
  }

  const currentPortfolioAccess = checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.IMPORT_PORTFOLIO, component_name: moduleConfig.CURRENT_PORTFOLIO }) || false

  const uploadPortfolioAccess = selectedAccount && selectedAccount?.accountType === 'TRADING'
    ? checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.IMPORT_PORTFOLIO, component_name: moduleConfig.IMPORT_TRADING_PORTFOLIO })
    : selectedAccount && selectedAccount?.accountType === 'TRANSITION'
      ? checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.IMPORT_PORTFOLIO, component_name: moduleConfig.IMPORT_TRANSITION_PORTFOLIO })
      : false

  const portfolioTabList = [
    ...(currentPortfolioAccess ? ['Current Portfolio'] : []),
    ...(uploadPortfolioAccess ? ['Upload Portfolio'] : [])
  ]

  const fetchAccountDetails = () => {
    API.get(
      'baseUriTransactionalMaster',
      `transactional-master/v1/${user?.userGroup}/account-detail/${params?.accountId}`
    )
      .then(response => {
        if (response?.data) {
          setSelectedAccount(response?.data)
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to load account details.')
      })
  }

  const fetchOptStatus = () => {
    setIsAccountOptStatusLoading(true)
    API.get(
      'baseURL',
      `account-master/v1/${user?.userGroup}/account/opt-status/${params?.accountId}`
    )
      .then(response => {
        if (response?.data) {
          setAccountOptStatusData(response?.data)
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to load opt status.')
      })
      .finally(() => {
        setIsAccountOptStatusLoading(false)
      })
  }

  const updateOptStatus = () => {
    setIsAccountOptStatusLoading(true)
    API.patch(
      'baseURL',
      `account-master/v1/${user?.userGroup}/account/opt-status/${params?.accountId}`
    )
      .then((response) => {
        if (response?.data) {
          setAccountOptStatusData(response.data)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to update opt status.')
      })
      .finally(() => {
        setIsAccountOptStatusLoading(false)
      })
  }

  useEffect(() => {
    if (user) {
      if (currentPortfolioAccess) {
        getTaxlot()
      } else {
        setIsTaxlotLoading(false)
      }
      if (checkAccess(
        moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.IMPORT_PORTFOLIO, component_name: moduleConfig.SHOW_ACCOUNT_OPT_STATUS }
      )) {
        fetchOptStatus()
      }
    }
  }, [user, params?.accountId])

  useEffect(() => {
    if (!selectedAccount) {
      fetchAccountDetails()
    }
  }, [selectedAccount])

  const getCompanyWiseRows = (originalData) => {
    const parentRows = {}

    originalData?.forEach(item => {
      const currItem = item.company
      const currentSymbol = item.localSymbol === '__USD' ? '__CASH' : item.localSymbol

      if (parentRows.hasOwnProperty(currItem)) {
        if (parentRows[currItem].shares != null) {
          parentRows[currItem].shares += item.shares
        }

        if (parentRows[currItem].purchaseCost != null) {
          parentRows[currItem].purchaseCost += item.purchaseCost
        }

        parentRows[currItem].accountLotWeight += item.accountLotWeight

        if (!parentRows[currItem].uniqueInstrIds.has(item.instrId)) {
          parentRows[currItem].modelWeight += item.modelWeight
          parentRows[currItem].uniqueInstrIds.add(item.instrId)
          parentRows[currItem].mcte += item.mcte
        }

        if (!parentRows[currItem].uniqueSymbols.has(currentSymbol)) { // Track unique symbols
          parentRows[currItem].uniqueSymbols.add(currentSymbol)
        }

        parentRows[currItem].purchaseDate = null

        if (parentRows[currItem].urgl != null) {
          parentRows[currItem].urgl += item.urgl
        }

        if (parentRows[currItem].marketValue != null) {
          parentRows[currItem].marketValue += item.marketValue || 0
        }

        parentRows[currItem].isoCountryName = item.isoCountryName
        parentRows[currItem].isActive = item.isActive
      } else {
        const companyObj = {}
        companyObj.id = randomId()
        companyObj.hierarchy = [item.company]
        companyObj.shares = item.shares
        companyObj.purchaseCost = item.purchaseCost
        companyObj.purchaseDate = null
        companyObj.uniqueSymbols = new Set([currentSymbol]) // Initialize uniqueSymbols Set
        companyObj.symbol = currentSymbol // Initial symbol is just the first one
        companyObj.accountLotWeight = item.accountLotWeight
        companyObj.modelWeight = item.modelWeight
        companyObj.urgl = item.urgl
        companyObj.isoCountryName = item.isoCountryName
        companyObj.name = item.company
        companyObj.isActive = item.isActive
        companyObj.taxlotId = item.taxlotId
        companyObj.uniqueInstrIds = new Set([item.instrId])
        companyObj.mcte = item.mcte
        companyObj.marketValue = item.marketValue
        companyObj.modelDrift = item.modelDrift
        parentRows[currItem] = companyObj
      }
    })

    const childRows = {}
    originalData?.forEach(item => {
      const currentSymbol = item.localSymbol === '__USD' ? '__CASH' : item.localSymbol

      if (childRows.hasOwnProperty(item.company)) {
        childRows[item.company].push({
          id: randomId(),
          instrId: item.instrId,
          hierarchy: [item.company, item.instrId + randomId()],
          name: item.instrName,
          shares: item.shares,
          purchaseCost: item.purchaseCost,
          purchaseDate: item.purchaseDate,
          accountLotWeight: item.accountLotWeight,
          modelWeight: item.modelWeight,
          urgl: item.urgl,
          symbol: currentSymbol,
          isoCountryName: item.isoCountryName,
          isActive: item.isActive,
          taxlotId: item.taxlotId,
          mcte: item.mcte,
          marketValue: item.marketValue,
          modelDrift: item.modelDrift,
          orgPurchasePrice: item.orgPurchasePrice
        })
      } else {
        childRows[item.company] = [{
          id: randomId(),
          instrId: item.instrId,
          hierarchy: [item.company, item.instrId + randomId()],
          name: item.instrName,
          shares: item.shares,
          purchaseCost: item.purchaseCost,
          purchaseDate: item.purchaseDate,
          accountLotWeight: item.accountLotWeight,
          modelWeight: item.modelWeight,
          urgl: item.urgl,
          symbol: currentSymbol,
          isoCountryName: item.isoCountryName,
          isActive: item.isActive,
          taxlotId: item.taxlotId,
          mcte: item.mcte,
          marketValue: item.marketValue,
          modelDrift: item.modelDrift,
          orgPurchasePrice: item.orgPurchasePrice
        }]
      }
    })

    for (const key in childRows) {
      const allTaxlotIdsNull = childRows[key].every(child => child.taxlotId === null)
      if (allTaxlotIdsNull) {
        parentRows[key].taxlotId = null
      } else {
        parentRows[key].taxlotId = 'companyLot'
      }
    }

    let finalArray = []
    for (const key in parentRows) {
      parentRows[key].modelWeight = parentRows[key].modelWeight || 0
      parentRows[key].modelDrift = (parentRows[key].accountLotWeight) - parentRows[key].modelWeight
      delete parentRows[key].uniqueInstrIds
      parentRows[key].symbol = Array.from(parentRows[key].uniqueSymbols).join(', ') // Update symbol with joined string of unique symbols
      delete parentRows[key].uniqueSymbols // Clean up the uniqueSymbols Set before finalizing
      finalArray.push(parentRows[key])
      finalArray = finalArray.concat(childRows[key])
    }

    return finalArray
  }

  const getTaxlot = async (ignoreLot = false, isFromSwitch = false, prevIgnoreLotSwitch) => {
    setIsTaxlotLoading(true)

    try {
      const queryParams = { ignoreLot: true }
      const url = `account-master/v1/${user?.userGroup}/accounts/taxlots/${params.accountId}`
      const response = await API.get('baseURL', url, {
        queryStringParameters: ignoreLot ? queryParams : {}
      })
      if (!response?.data?.length) {
        setOriginalTaxlot([])
        setNoDataMsg(response?.message)
      } else if (response?.data?.length) {
        const downloadFileData = response?.data?.map((item, index) => {
          const dateObject = new Date(item.purchaseDate)
          const formattedDate = `${dateObject.getUTCFullYear()}${(dateObject.getUTCMonth() + 1).toString().padStart(2, '0')}${dateObject.getUTCDate().toString().padStart(2, '0')}`
          return { ...item, purchaseDate: formattedDate }
        })
        setOriginalTaxlot(response?.data?.map(obj => ({ ...obj, id: randomId() })))
        setTaxLotDataDownloadFormat(downloadFileData)
        const companyWiseTaxlotData = getCompanyWiseRows(response?.data)
        setCompanyWiseTaxlot(companyWiseTaxlotData)
        setCompanyDataDownloadFormat(
          companyWiseTaxlotData.map(row => {
            const csvRow = {}
            const isParentRow = row.hierarchy && row.hierarchy.length === 1
            csvRow.level = isParentRow ? 'Company' : 'Lot'

            if (isParentRow) {
              csvRow.name = row.name
            } else {
              csvRow.name = '' // No name for child rows
            }

            csvRow.localSymbol = row.symbol
            csvRow.marketValue = row.marketValue
            csvRow.shares = row.shares
            csvRow.orgPurchasePrice = !isParentRow ? row.orgPurchasePrice : '' // Only include original purchase price for child rows

            if (isParentRow) {
              csvRow.accountLotWeight = row.accountLotWeight
              csvRow.modelWeight = row.modelWeight
              csvRow.purchaseCost = row.purchaseCost
              csvRow.urgl = row.urgl
              csvRow.mcte = row.mcte !== null && row.mcte !== undefined
                ? parseFloat((parseFloat(row.mcte) * 100).toFixed(6))
                : null
              csvRow.modelDrift = row.modelDrift
              csvRow.purchaseDate = ''
            } else {
              csvRow.accountLotWeight = ''
              csvRow.modelWeight = ''
              csvRow.purchaseCost = ''
              csvRow.urgl = ''
              csvRow.mcte = ''
              csvRow.modelDrift = ''
              if (row.purchaseDate) {
                const dateObject = new Date(row.purchaseDate)
                const formattedDate = `${dateObject.getUTCFullYear()}${(dateObject.getUTCMonth() + 1).toString().padStart(2, '0')}${dateObject.getUTCDate().toString().padStart(2, '0')}`
                csvRow.purchaseDate = formattedDate
              } else {
                csvRow.purchaseDate = ''
              }
            }

            return csvRow
          })
        )
      }
    } catch (error) {
      if (isFromSwitch && prevIgnoreLotSwitch !== ignoreLot) {
        // Only reset ignoreLotSwitch to prevIgnoreLotSwitch if API fails and switch changed
        setIgnoreLotSwitch(prevIgnoreLotSwitch)
      }
      showError(error, false, {}, 'Failed to load taxlot.')
    } finally {
      setIsTaxlotLoading(false)
    }
  }

  // function to call getTaxlot with the appropriate parameter on switch
  const handleIgnoreLotSwitchChange = () => {
    const prevIgnoreLotSwitch = ignoreLotSwitch
    const newIgnoreLotSwitch = !ignoreLotSwitch
    setIgnoreLotSwitch(newIgnoreLotSwitch)
    getTaxlot(newIgnoreLotSwitch, true, prevIgnoreLotSwitch)
  }

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  const renderTabPanel = (tabName) => {
    switch (tabName) {
      case 'Current Portfolio': {
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen='not main' />)} onReset={() => window.location.reload()}>
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <Box width='100%' display='flex' justifyContent='space-between' flexWrap='wrap' my={1}>
                    <RadioGroup
                      row
                      name='portfolioLevel'
                      aria-labelledby='demo-row-radio-buttons-group-label'
                      value={portfolioLevel}
                      onChange={(e) => {
                        setPortfolioLevel(e.target.value)
                      }}
                    >
                      <FormControlLabel
                        value='Taxlot'
                        control={<Radio size='small' />}
                        label='Taxlot'
                      />
                      <FormControlLabel
                        value='Company'
                        control={<Radio size='small' />}
                        label='Company'
                      />
                    </RadioGroup>
                    <Box display='flex' alignItems='center' visibility={isTaxlotLoading || ignoreLotSwitch || !originalTaxlot?.length ? 'hidden' : 'visible'} sx={{ pointerEvents: isTaxlotLoading || ignoreLotSwitch || !originalTaxlot?.length ? 'none' : 'all' }}>
                      <span className='import-portfolio-download-button'>
                        {
                          portfolioLevel === 'Company'
                            ? <CSVLink {...displayedCompanyCsvConfig}>
                              <Button
                                sx={{ textTransform: 'none' }} startIcon={<FileDownloadOutlinedIcon sx={{ color: '#3A76D4', fontSize: '16px', opacity: 1 }} />}
                                onClick={() => createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.PORTFOLIO_CURRENT_COMPANY_DETAILS, params?.accountId, displayedCompanyCsvConfig.filename)}
                              >
                                Current Company Portfolio
                              </Button>
                              </CSVLink>
                            : <CSVLink {...displayedTaxlotCsvConfig}>
                              <Button
                                sx={{ textTransform: 'none' }} startIcon={<FileDownloadOutlinedIcon sx={{ color: '#3A76D4', fontSize: '16px', opacity: 1 }} />}
                                onClick={() => createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.PORTFOLIO_CURRENT_TAXLOT_DETAILS, params?.accountId, displayedTaxlotCsvConfig.filename)}
                              >
                                Current Taxlot Portfolio
                              </Button>
                              </CSVLink>
                        }
                      </span>
                      <span className='import-portfolio-download-button'>
                        <CSVLink {...importFormatCsvConfig}>
                          <Button
                            sx={{ textTransform: 'none' }}
                            startIcon={<FileDownloadOutlinedIcon sx={{ color: '#3A76D4', fontSize: '16px', opacity: 1 }} />}
                          >
                            Portfolio In Import Format
                          </Button>
                        </CSVLink>
                      </span>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <CurrentImport taxlotData={originalTaxlot || []} portfolioLevel={portfolioLevel} companyWisePortfolio={companyWiseTaxlot} noDataMsg={noDataMsg} isTaxlotLoading={isTaxlotLoading} />
                </Grid>
              </Grid>
            </Box>
          </ErrorBoundary>
        )
      }
      case 'Upload Portfolio': {
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen='not main' />)} onReset={() => window.location.reload()}>
            <Grid container>
              <Grid item xs={12}>
                <UploadForm getTaxlot={getTaxlot} selectedAccount={selectedAccount} />
              </Grid>
            </Grid>
          </ErrorBoundary>
        )
      }
      default:
        return 'Something went wrong'
    }
  }

  return (
    <>
      {isAccountOptStatusLoading ? <Loader /> : ''}
      <Box className='import-portfolio'>
        <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen='not main' />)} onReset={getTaxlot}>
          <AccountTitle title='Portfolio' />
        </ErrorBoundary>
        <TabContext value={currentTab}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
            <TabList
              onChange={handleTabChange}
              variant='scrollable'
              scrollButtons={false}
              id='portfolio-tabs'
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#34475A'
                }
              }}
              indicatorColor='#34475A'
            >
              {
                portfolioTabList?.map((tabName) => (<Tab className='tab-header' value={tabName} label={tabName} key={tabName} />))
              }
            </TabList>
            {currentTab === 'Current Portfolio'
              ? <Box sx={{ display: 'flex', alignItems: 'center', mr: '12px', columnGap: 1 }}>
                {checkAccess(
                  moduleConfig.ACCOUNT_REVIEW,
                  ACCESS_LEVEL.COMPONENT_ACCESS,
                  {
                    subModuleName: moduleConfig.IMPORT_PORTFOLIO,
                    component_name: moduleConfig.SHOW_ACCOUNT_OPT_STATUS
                  }
                ) && accountOptStatusData?.optStatusCode && (
                  <Chip
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <span>{accountOptStatusData?.optStatusCode}</span>
                        {accountOptStatusData?.optStatusCode === 'OPT_HOLD_PORTFOLIO_BREAK' && (
                          <Tooltip title='Release Account' arrow>
                            <IconButton
                              aria-label='refresh'
                              onClick={updateOptStatus}
                              sx={{
                                ...getColorProperties(accountOptStatusData?.optStatusCode),
                                fontSize: '16px',
                                padding: '6px',
                                border: 'none'
                              }}
                            >
                              <BiLockOpen fontSize='16px' />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    }
                    variant='outlined'
                    size='small'
                    sx={{
                      ...getColorProperties(accountOptStatusData?.optStatusCode),
                      fontWeight: '500'
                    }}
                  />
                )}
                <FormControlLabel
                  labelPlacement='start'
                  control={
                    <Switch
                      checked={ignoreLotSwitch}
                      onChange={handleIgnoreLotSwitchChange}
                      name='switchName'
                      disabled={isTaxlotLoading}
                      sx={{
                        '& .MuiSwitch-switchBase:hover': {
                          backgroundColor: 'transparent'
                        }
                      }}
                    />
                  }
                  label='Show Ignored Lots'
                  componentsProps={{
                    typography: {
                      sx: {
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#34475A'
                      }
                    }
                  }}
                />
                </Box>
              : ''}
          </Box>
          <Divider sx={{
            bgcolor: 'rgba(116, 120, 141, 0.15)'
          }}
          />
          {
            portfolioTabList?.map((tabName) => (
              <TabPanel
                value={tabName}
                key={tabName}
                sx={{
                  p: 0
                }}
              >
                {renderTabPanel(tabName)}
              </TabPanel>
            ))

          }
        </TabContext>
      </Box>
    </>
  )
}
export default AccountPortfolioImport
