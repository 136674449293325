import { DeleteAPI } from '../components/Actions'
import { getUserRole } from '../../../utils/getUserRole'

export const DeleteAggregateData = async (deleteRow, tabIndex) => {
  switch (tabIndex) {
    case 'properties':
      return await DeleteAggregateProperties(deleteRow)
    case 'external-gl':
      return await DeleteAggregateExternalGL(deleteRow)
    case 'parameters':
      return await DeleteAggregateParameters(deleteRow)
    case 'tax-rate':
      return await DeleteAggregateTaxRate(deleteRow)
    case 'account-map':
      return await DeleteAggregateAccountMap(deleteRow)
    default:
      return {}
  }
}

const DeleteAggregateProperties = async (deleteRow) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseUriAggregateMaintenance',
    `data-maintenance/v1/${userRole}/aggregate/properties/${deleteRow?.aggPropertiesId}`)
}

const DeleteAggregateExternalGL = async (deleteRow) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseUriAggregateMaintenance',
    `data-maintenance/v1/${userRole}/aggregate/external-gain-loss/${deleteRow?.aggExtGlId}`)
}

const DeleteAggregateParameters = async (deleteRow) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseUriAggregateMaintenance',
    `data-maintenance/v1/${userRole}/aggregate/${deleteRow?.aggId}/parameter`)
}

const DeleteAggregateTaxRate = async (deleteRow) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseUriAggregateMaintenance',
    `data-maintenance/v1/${userRole}/aggregate/${deleteRow?.aggId}/tax-rates/${deleteRow?.currentTaxYear}`)
}

const DeleteAggregateAccountMap = async (deleteRow) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseUriAggregateMaintenance',
    `data-maintenance/v1/${userRole}/aggregate/agg-account-map/${deleteRow?.aggAccountMapId}`)
}