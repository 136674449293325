import { FormatDateAPI, FormatPayload } from '../components/FormatAPIRequest'
import { CreateAPI } from '../components/Actions'
import { getUserRole } from '../../../utils/getUserRole'

export const CreateAggregateData = async (newRow, tabIndex) => {
  switch (tabIndex) {
    case 'properties':
      return await CreateAggregateProperties(newRow)
    case 'external-gl':
      return await CreateAggregateExternalGL(newRow)
    case 'parameters':
      return await CreateAggregateParameters(newRow)
    case 'tax-rate':
      return await CreateAggregateTaxRate(newRow)
    case 'account-map':
      return await CreateAggregateAccountMap(newRow)
    default:
      return {}
  }
}

const CreateAggregateProperties = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow?.startDate ? FormatDateAPI(newRow.startDate) : null
  const endDateValue = newRow?.endDate ? FormatDateAPI(newRow.endDate) : null
  let payload = {
    aggId: newRow?.aggId,
    propertyCode: newRow?.propertyCode.toUpperCase(),
    propertyValue: newRow?.propertyValue,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)

  return CreateAPI(
    'baseUriAggregateMaintenance',
    `data-maintenance/v1/${userRole}/aggregate/properties`,
    payload)
}

const CreateAggregateTaxRate = async (newRow) => {
  const userRole = getUserRole()
  
  let payload = {
    currentTaxYear: parseInt(newRow?.currentTaxYear),
    taxSensitivity: newRow?.taxSensitivity,
    stateId: newRow?.stateId,
    stateLongTax: newRow?.stateLongTax,
    stateMedTax: newRow?.stateMedTax,
    stateShortTax: newRow?.stateShortTax,
    fedLongTax: newRow?.fedLongTax,
    fedMedTax: newRow?.fedMedTax,
    fedShortTax: newRow?.fedShortTax,
  }

  payload = FormatPayload(payload)
  return CreateAPI('baseUriAggregateMaintenance', `data-maintenance/v1/${userRole}/aggregate/${newRow?.aggId}/tax-rates`, payload)
}

const CreateAggregateParameters = async (newRow) => {
  const userRole = getUserRole()

  let payload = {
    aggType: newRow?.aggType?.toUpperCase(),
    sellLogicId: newRow?.sellLogicId,
    custodianId: newRow?.custodianId,
    maxShortTermGain: newRow?.maxShortTermGain,
    maxLongTermGain: newRow?.maxLongTermGain,
    brokerId: newRow?.brokerId,
    cashInstrId: newRow?.cashInstrId,
    pctCashCushion: newRow?.pctCashCushion
  }

  payload = FormatPayload(payload)
  return CreateAPI('baseUriAggregateMaintenance', `data-maintenance/v1/${userRole}/aggregate/${newRow?.aggId}/parameter`, payload)
}

const CreateAggregateExternalGL = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow?.startDate ? FormatDateAPI(newRow.startDate) : null
  const aggId = newRow?.aggId

  let payload = {
    taxYear: parseInt(newRow?.taxYear),
    startDate: startDateValue,
    ytdRealLongGains: newRow?.ytdRealLongGains,
    ytdRealShortGains: newRow?.ytdRealShortGains,
  }

  payload = FormatPayload(payload)
  return CreateAPI('baseUriAggregateMaintenance', `data-maintenance/v1/${userRole}/aggregate/${aggId}/external-gain-loss`, payload)
}

const CreateAggregateAccountMap = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow?.startDate ? FormatDateAPI(newRow.startDate) : null
  const endDateValue = newRow?.endDate ? FormatDateAPI(newRow.endDate) : null

  let payload = {
    accountId: newRow?.accountId,
    isDefault: newRow?.isDefault,
    cashAllocWeight: newRow?.cashAllocWeight,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return CreateAPI('baseUriAggregateMaintenance', `data-maintenance/v1/${userRole}/aggregate/${newRow?.aggId}/agg-account-map`, payload)
}