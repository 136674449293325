import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Box, List, Skeleton } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import 'rc-slider/assets/index.css'
import arisLogoA from '../assets/images/ArisLogoA.svg'
import NewArisLogoWhite from '../assets/images/arisNewLogoWhite.svg'
import { useAuth } from '../contexts/AuthContext'
import { useLogo } from '../contexts/SponsorLogoContext'
import { useTourContext } from '../contexts/TourContext'
import { useMenuConfig } from '../hooks/useMenuConfig'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { setSidebarVisibility } from '../store/layout-reducer/layout-reducer'
import { ACCESS_LEVEL } from '../contstants/constants'
import { ExpandCollapseIcon } from './components/ArrowButtons'
import NonSubmenuItems from './NonSubmenuItems'
import SubMenuItems from './SubMenuItems'

const LeftSidebar = ({
  activeMenuFromParent,
  topMenuToggleRef
}) => {
  const menuNodeRef = useRef(null)
  const [activePath, setActivePath] = useState('')
  const location = useLocation()
  const { user, checkAccess } = useAuth()
  const { width } = useWindowDimensions()
  const { sponsorMainLogo, sponsorMobileLogo, backgroundColor } = useLogo()
  const [isMainLogoLoaded, setIsMainLogoLoaded] = useState(false)
  const [isMobileLogoLoaded, setIsMobileLogoLoaded] = useState(false)
  const [openSubmenuId, setOpenSubmenuId] = useState(null)
  const sidebarMenuList = useMenuConfig()
  const { userGroup } = useSelector((state) => state.user)
  const [userGrp, setUserGrp] = useState(user?.userGroup || userGroup)
  const { showLegacyView } = useSelector((state) => state.trade)
  const { isSidebarOpen } = useSelector((state) => state.layout)
  const { startTour } = useTourContext()
  const dispatch = useDispatch()

  const handleSubmenuToggle = (event, id) => {
    if (!checkSubmenuToggle(event, id)) {
      if (openSubmenuId === id) {
        // user click on active submenu
        setOpenSubmenuId(null)
      } else {
        // user selects any icon other than active submenu
        setOpenSubmenuId(id)
      }
    }
  }

  // const handleOtherClick = useMemo(() => (e) => {
  //   // open sidebar on click of any part of sidemenu
  //   if (menuNodeRef && menuNodeRef.current && menuNodeRef.current.contains(e.target)) {
  //     sidebarMenuList.forEach(menuItem => {
  //       if (menuItem.hasSubMenu && menuItem.paths.some(path => window.location?.pathname?.includes(path.path))) {
  //         if (!document.getElementById('sidebar')?.classList.contains('open')) {
  //           if (e.target?.id === 'sidebar') {
  //             setOpenSubmenuId(menuItem.id)
  //           } else {
  //             if (e.target?.parentNode?.id?.endsWith('-icon')) {
  //               handleSubmenuToggle(e, e.target?.parentNode?.id)
  //             } else if (e.target?.id !== '') {
  //               handleSubmenuToggle(e, e.target?.id)
  //             } else {
  //               handleSubmenuToggle(e, menuItem.id)
  //             }
  //           }
  //         }
  //       }
  //     })
  //     document.getElementById('sidebar')?.classList?.add('open')
  //     setIsSidebarOpen(true)
  //   }
  //   // else hide the menubar
  //   else if (document.getElementById('sidebar')?.classList?.contains('open') && (!activeMenuFromParent || ((activeMenuFromParent === 'Personalization' || activeMenuFromParent === 'Report') && e.target.title === 'Next'))) {
  //     if (window.innerWidth < 1400 || showLegacyView) {
  //       setOpenSubmenuId(null)
  //       document.getElementById('sidebar')?.classList?.remove('open')
  //       setIsSidebarOpen(false)
  //     }
  //   }
  // }, [showLegacyView, activeMenuFromParent])

  // useEffect(() => {
  //   // on page refresh it will open sidebar
  //   if (!startTour) {
  //     if (!document.getElementById('sidebar')?.classList?.contains('open') && !showLegacyView) {
  //       document.getElementById('sidebar')?.classList?.add('open')
  //       setIsSidebarOpen(true)
  //     }
  //   }

  //   // bind mouse click events
  //   document.body.addEventListener('mousedown', handleOtherClick, false)

  //   return () => {
  //     document.body.removeEventListener('mousedown', handleOtherClick, false)
  //   }
  // }, [handleOtherClick])

  // useEffect(() => {
  //   // collapse left sidebar when showLegacyView is true
  //   if (showLegacyView && window.innerWidth > 1719) {
  //     if (document.getElementById('sidebar')?.classList?.contains('open')) {
  //       document.getElementById('sidebar')?.classList?.remove('open')
  //       setIsSidebarOpen(false)
  //       setOpenSubmenuId(null)
  //     }
  //   } else {
  //     // set active menu in left sidebar when showLegacyView is set to false and screen has normal view
  //     if (document.getElementById('sidebar')?.classList?.contains('open') && !showLegacyView) {
  //       sidebarMenuList.forEach(menuItem => {
  //         if (menuItem.hasSubMenu && menuItem.paths.some(path => window.location?.pathname?.includes(path.path))) {
  //           if (document.getElementById('sidebar')?.classList?.contains('open')) {
  //             setOpenSubmenuId(menuItem.id)
  //           }
  //         }
  //       })
  //     }
  //   }
  // }, [showLegacyView])

  const handleBodyClick = useCallback((e) => {
    if (width > 600) {
      return
    }

    if (menuNodeRef.current &&
      !menuNodeRef.current.contains(e.target) &&
      topMenuToggleRef.current &&
      !topMenuToggleRef.current.contains(e.target)) {
      dispatch(setSidebarVisibility({ isSidebarOpen: false }))
    }
  }, [width])

  useEffect(() => {
    // open the submenu of selected item if sidebar is open
    if (isSidebarOpen) {
      sidebarMenuList.forEach(menuItem => {
        if (menuItem.hasSubMenu && menuItem.paths.some(path => window.location?.pathname?.includes(path.path))) {
          setOpenSubmenuId(menuItem.id)
        }
      })
    } else {
      setOpenSubmenuId(null)
    }
  }, [isSidebarOpen, activeMenuFromParent])

  useEffect(() => {
    document.body.addEventListener('mousedown', handleBodyClick)
    return () => {
      document.body.removeEventListener('mousedown', handleBodyClick)
    }
  }, [handleBodyClick])

  // useEffect(() => {
  //   if (activeMenuFromParent) {
  //     document.getElementById('sidebar')?.classList?.add('open')
  //     setIsSidebarOpen(true)
  //   }
  // }, [activeMenuFromParent])

  // useEffect(() => {
  //   const autoMenuCloseHandler = () => {
  //     if (window.innerWidth < 768) {
  //       setOpenSubmenuId(null)
  //     } else if (window.innerWidth > 1400 && !showLegacyView) {
  //       // if resized when legacy view is on then we don't want to show sidebar opened every time
  //       sidebarMenuList.forEach(menuItem => {
  //         if (menuItem.hasSubMenu && menuItem.paths.some(path => window.location?.pathname?.includes(path.path))) {
  //           if (!document.getElementById('sidebar')?.classList?.contains('open')) {
  //             setOpenSubmenuId(menuItem.id)
  //             document.getElementById('sidebar')?.classList?.add('open')
  //             setIsSidebarOpen(true)
  //           }
  //         }
  //       })
  //     }
  //   }
  //   window.addEventListener('resize', autoMenuCloseHandler)
  //   return () => window.removeEventListener('resize', autoMenuCloseHandler)
  // }, [showLegacyView])

  useEffect(() => {
    setActivePath(location.pathname)
    if(isSidebarOpen) {
      sidebarMenuList.forEach(menuItem => {
        if (menuItem.hasSubMenu && menuItem.paths.some(path => window.location?.pathname?.includes(path.path))) {
          setOpenSubmenuId(menuItem.id)
        }
      })
    }
  }, [location])

  // useEffect(() => {
  //   sidebarMenuList.forEach(menuItem => {
  //     if (
  //       menuItem.hasSubMenu &&
  //       menuItem.paths.some(path => activePath?.includes(path.path))
  //     ) {
  //       setTimeout(() => {
  //         if (document.getElementById('sidebar')?.classList?.contains('open')) {
  //           setOpenSubmenuId(menuItem.id)
  //         } else {
  //           setOpenSubmenuId(null)
  //         }
  //       }, 100)
  //     }
  //   })
  // }, [activePath])

  const checkSubmenuToggle = (event, id) => {
    return isSidebarOpen ? ((event.target?.id === id) || (event.target?.id === event.target?.id + '-icon')) : true
  }

  const toggleSidebar = () => {
    const newSidebarState = !isSidebarOpen
    dispatch(setSidebarVisibility({ isSidebarOpen: newSidebarState }))
    if (newSidebarState) {
      sidebarMenuList.forEach(menuItem => {
        if (menuItem.hasSubMenu && menuItem.paths.some(path => activePath?.includes(path.path))) {
          setOpenSubmenuId(menuItem.id)
        }
      })
    } else {
      setOpenSubmenuId(null)
    }
  }

  return (
    <>
      <Box
        className={`sidebar ${isSidebarOpen ? 'open' : ''}`}
        sx={{
          backgroundColor: backgroundColor || '#002A59'
        }}
        id='sidebar'
        ref={menuNodeRef}
      >
        <ExpandCollapseIcon
          className='sidebar-toggle-btn'
          sx={{
            backgroundColor: backgroundColor || '#002A59',
            '&:hover': {
              backgroundColor: backgroundColor || '#002A59'
            }
          }}
          onClick={toggleSidebar}
        >
          <KeyboardArrowLeftIcon
            sx={{ width: '16px', height: '16px', transform: isSidebarOpen ? 'rotate(0deg)' : 'rotate(180deg)' }}
          />
        </ExpandCollapseIcon>
        <Box sx={{
          overflow: 'hidden',
          transition: 'width 0.5s ease',
          width: isSidebarOpen ? '100%' : '50px', // Adjust width based on sidebar state
          display: 'flex',
          justifyContent: 'center',
          height: '60px'
        }}>
          <Box
            className='logo' sx={{
              backgroundColor: backgroundColor || '#002A59',
              position: 'sticky',
              top: 0,
              zIndex: 1
            }}
          >
            {userGrp
              ? (
                <>
                  {sponsorMainLogo
                    ? <img
                      src={sponsorMainLogo} alt='sponsor-logo' height={40} width={180} style={{ display: isMainLogoLoaded ? '' : 'none' }}
                      loading='eager'
                      onLoad={() => { setIsMainLogoLoaded(true) }}
                      ref={(img) => {
                        if (img && img?.complete) {
                          setIsMainLogoLoaded(true)
                        }
                      }}
                    />
                    : null}
                  {isMainLogoLoaded ? null : <Skeleton variant='rounded' sx={{ bgcolor: 'rgb(99,119,136)', margin: '16px 0px 0px' }} height={40} width={180} />}
                </>
              )
              : (
                <img src={NewArisLogoWhite} alt='aris logo' height={40} width={180} style={{ margin: '16px 0 8px 0' }} loading='eager' />
              )}
          </Box>
          <Box
            className='logo-tablet'
            sx={{ backgroundColor: backgroundColor || '#002A59' }}
          >
            {userGrp
              ? (
                <>
                  {sponsorMobileLogo
                    ? <img
                      src={sponsorMobileLogo} alt='sponsor-logo' style={{ display: isMobileLogoLoaded ? '' : 'none' }} loading='eager'
                      onLoad={() => { setIsMobileLogoLoaded(true) }}
                      ref={(img) => {
                        if (img && img?.complete) {
                          setIsMobileLogoLoaded(true)
                        }
                      }}
                    />
                    : null}
                  {isMobileLogoLoaded ? null : <Skeleton variant='rounded' sx={{ bgcolor: 'rgb(99,119,136)', margin: '16px 0px 0px' }} height={40} width={40} />}
                </>
              )
              : (
                <>
                  <img src={arisLogoA} alt='aris-logo' loading='eager' />
                </>
              )}
          </Box>
        </Box>
        <List
          className='menu-list'
          sx={{
            '& .MuiListItemIcon-root': {
              minWidth: 'unset',
              marginTop: '4px',
              marginBottom: '4px',
              lineHeight: '21px'
            }
          }}
          disablePadding
        >
          <>
            {sidebarMenuList?.map((menuItem, index) => (
              (user?.userGroup !== 'admin')
                ? (
                  checkAccess(menuItem.moduleCd, ACCESS_LEVEL.MODULE_ACCESS) && (
                    menuItem.hasSubMenu
                      ? (
                        <SubMenuItems
                          key={index}
                          id={menuItem.id}
                          menuId={menuItem.menuId}
                          paths={menuItem.paths}
                          activePath={activePath}
                          icon={menuItem.icon}
                          name={menuItem.name}
                          isOpen={openSubmenuId === menuItem.id}
                          handleSubmenuToggle={(event) => handleSubmenuToggle(event, menuItem.id)}
                          isSidebarOpen={isSidebarOpen}
                          parentModule={menuItem.moduleCd}
                        />
                      )
                      : (
                        <NonSubmenuItems
                          key={index}
                          activePath={activePath}
                          icon={menuItem.icon}
                          linkTo={menuItem.path}
                          label={menuItem.name}
                          id={menuItem.id}
                          handleSubmenuToggle={(event) => handleSubmenuToggle(event, menuItem.id)}
                          isSidebarOpen={isSidebarOpen}
                        />
                      )
                  )
                )
                : (
                  menuItem?.roles?.includes(user?.userGroup) && (
                    menuItem?.hasSubMenu
                      ? (
                        <SubMenuItems
                          key={index}
                          id={menuItem.id}
                          menuId={menuItem.menuId}
                          paths={menuItem.paths}
                          activePath={activePath}
                          icon={menuItem.icon}
                          name={menuItem.name}
                          isOpen={openSubmenuId === menuItem.id}
                          handleSubmenuToggle={(event) => handleSubmenuToggle(event, menuItem.id)}
                          isSidebarOpen={isSidebarOpen}
                          parentModule={menuItem.moduleCd}
                        />
                      )
                      : (
                        <NonSubmenuItems
                          key={index}
                          activePath={activePath}
                          icon={menuItem.icon}
                          linkTo={menuItem.path}
                          label={menuItem.name}
                          id={menuItem.id}
                          isOpen={openSubmenuId === menuItem.id}
                          handleSubmenuToggle={(event) => handleSubmenuToggle(event, menuItem.id)}
                          isSidebarOpen={isSidebarOpen}
                        />
                      )
                  )
                )
            ))}
          </>
        </List>
      </Box>
    </>
  )
}



export default LeftSidebar
