import { Box, styled } from '@mui/material'

const Card = ({ children, style }) => {
  return (
    <CustomCard sx={style}>
      {children}
    </CustomCard>
  )
}

const CustomCard = styled(Box)({
  border: '1px solid #E6ECF3',
  borderRadius: '12px',
  height: '100%',
  padding: '16px'
})

export default Card