import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogTitle, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import CustomAutocompleteField from './components/CustomAutoCompleteField'
import CustomDateField from './components/CustomDateField'
import CustomNumberField from './components/CustomNumberField'
import CustomSelectField from './components/CustomSelectField'
import CustomTextField from './components/CustomTextField'
import Loader from '../../Loader'

dayjs.extend(utc)

const PersonalDetails = ({ formik, defaultAccountDetails, submitApiLoading, getAccountsList }) => {
  const { user } = useAuth()
  const params = useParams()
  const navigate = useNavigate()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [sellLogicCodes, setSellLogicCodes] = useState([])
  const [taxStates, setTaxStates] = useState([])
  const [advIdsList, setAdvIdsList] = useState([])
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const getFieldErrorProps = (fieldName) => ({
    error: Boolean(formik.errors[fieldName] && formik.touched[fieldName]),
    helperText: formik.errors[fieldName] && formik.touched[fieldName] ? String(formik.errors[fieldName]) : ''
  })

  useEffect(() => {
    if (user) {
      getSellLogicCodes()
      getTaxStates()
      getAdvisorIds()
    }
  }, [user])

  const getSellLogicCodes = () => {
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/sell-logics`, {
      queryStringParameters: {}
    })
      .then((response) => {
        if (response.data) {
          setSellLogicCodes([...response.data])
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load sell logic code.')
      })
  }

  const getTaxStates = async () => {
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/tax-states`, {
      queryStringParameters: {}
    })
      .then((response) => {
        if (response.data) {
          setTaxStates([...response.data])
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load tax states.')
      })
  }

  const getAdvisorIds = () => {
    API.get('baseUriAccountOptimization', `account-customization/v1/${user.userGroup}/advisor-ids/${params?.accountId}`)
      .then((res) => {
        if (res && res?.data) {
          setAdvIdsList(res.data)
        }
      }).catch((error) => {
        showError(error, false, {}, 'Failed to load adv ids.')
      })
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const updateFormData = (data) => {
    formik.setFieldValue('statusId', data?.statusId)
    formik.setFieldValue('statusDesc', data?.statusDesc)
    formik.setFieldValue('endDate', dayjs(data?.endDate).format('YYYY-MM-DD'))
  }

  const handleCloseAccountClick = (setIsConfirmApiLoading) => {
    setIsConfirmApiLoading(true)
    API.del('baseUriAccountOptimization', `account-customization/v1/${user.userGroup}/account-profile-details/${params?.accountId}`)
      .then((res) => {
        if (res && res?.success) {
          if (res.data && res?.data?.length) {
            updateFormData(res?.data[0])
          }
          localStorage.removeItem('object')
          getAccountsList()
          showSuccess(res?.message)
          setAnchorEl(null)
          navigate('/account-review/manage-profile/')
        }
      }).catch((error) => {
        showError(error, false, {}, 'Failed to close account.')
      }).finally(() => setIsConfirmApiLoading(false))
  }

  const ConfirmCloseAccountDialog = () => {
    const [isConfirmApiLoading, setIsConfirmApiLoading] = useState(false)
    return (
      <>
        <Dialog
          fullWidth
          maxWidth='xs'
          open={openConfirmationDialog}
          onClose={() => setOpenConfirmationDialog(false)}
        >
          <DialogTitle>
            <Typography variant='body1'>
              Are you sure you want to close <strong>{defaultAccountDetails?.fieldData?.name}</strong> account?
            </Typography>
          </DialogTitle>
          <DialogActions sx={{ marginBottom: '5px' }}>
            {isConfirmApiLoading
              ? (
                <LoadingButton
                  loading
                  loadingPosition='start'
                  variant='outlined'
                  sx={{ width: '130px' }}
                >
                  Confirm
                </LoadingButton>
                )
              : (
                <Button variant='contained' onClick={() => handleCloseAccountClick(setIsConfirmApiLoading)}>
                  Confirm
                </Button>
                )}
            <Button
              autoFocus onClick={() => {
                setOpenConfirmationDialog(false)
                setAnchorEl(null)
              }}
            >Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  return (
    <Box sx={{ position: 'relative' }}>
      {submitApiLoading && <Loader />}
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ textAlign: 'center', position: 'absolute', right: '10px', top: '-65px' }}>
          <>
            <IconButton
              onClick={handleMenuOpen} sx={{
                p: '4px',
                borderRadius: '10px',
                border: '2px solid #dee2e6',
                marginLeft: '8px',
                marginRight: '8px'
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              elevation={4}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}
            >
              <MenuItem onClick={() => setOpenConfirmationDialog(true)}>
                Close Account
              </MenuItem>
            </Menu>
          </>
          {
            submitApiLoading
              ? (
                <LoadingButton
                  loading
                  loadingPosition='start'
                  variant='contained'
                  sx={{ width: '125px' }}
                  type='submit'
                >
                  Submit
                </LoadingButton>
                )
              : (
                <Button
                  type='submit' variant='contained'
                  disabled={!formik?.dirty || formik?.values?.statusDesc === 'Closed account'}
                >
                  Submit
                </Button>
                )
          }
        </Box>
        <Card className='card-layout' sx={{ marginTop: '22px' }}>
          <CardContent>
            <Typography className='account-label-header' mb={2}>
              Basic Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} mb={1}>
                <CustomTextField
                  id='name'
                  name='name'
                  label='Name *'
                  placeholder='Name'
                  disabled={!defaultAccountDetails?.fieldEditAllowed?.name}
                  value={formik?.values?.name}
                  onChange={formik?.handleChange}
                  InputLabelProps={{ shrink: formik?.values?.name }}
                  {...getFieldErrorProps('name')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={1}>
                <CustomTextField
                  id='email'
                  name='email'
                  type='email'
                  label='Email *'
                  placeholder='Email'
                  disabled={!defaultAccountDetails?.fieldEditAllowed?.email}
                  value={formik?.values?.email}
                  onChange={formik?.handleChange}
                  InputLabelProps={{ shrink: formik?.values?.email }}
                  {...getFieldErrorProps('email')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={1}>
                <CustomTextField
                  id='accountType'
                  name='accountType'
                  type='accountType'
                  label='Account Type'
                  placeholder='Account Type'
                  disabled={!defaultAccountDetails?.fieldEditAllowed?.accountType}
                  value={formik?.values?.accountType}
                  onChange={formik?.handleChange}
                  InputLabelProps={{ shrink: formik?.values?.accountType }}
                  {...getFieldErrorProps('accountType')}
                />
              </Grid>
              {
                   formik?.values?.accountType === 'TRADING'
                     ? (
                       <>
                         <Grid item xs={12} sm={6} md={4} mb={2}>
                           <CustomTextField
                             id='custodianName'
                             name='custodianName'
                             label='Custodian Name'
                             placeholder='Custodian Name'
                             disabled={!defaultAccountDetails?.fieldEditAllowed?.custodianName}
                             value={formik?.values?.custodianName}
                             onChange={formik?.handleChange}
                             InputLabelProps={{ shrink: formik?.values?.custodianName }}
                             {...getFieldErrorProps('custodianName')}
                           />
                         </Grid>
                         <Grid item xs={12} sm={6} md={4} mb={2}>
                           <CustomTextField
                             id='custodianAccountNumber'
                             name='custodianAccountNumber'
                             label='Custodian Account Number *'
                             placeholder='Custodian'
                             disabled={!defaultAccountDetails?.fieldEditAllowed?.custodianAccountNumber}
                             value={formik?.values?.custodianAccountNumber}
                             onChange={formik?.handleChange}
                             InputLabelProps={{ shrink: formik?.values?.custodianAccountNumber }}
                             {...getFieldErrorProps('custodianAccountNumber')}
                           />
                         </Grid>
                         {
                          formik?.values?.custodianName === 'Charles Schwab'
                            ? (
                              <Grid item xs={12} sm={6} md={4} mb={2}>
                                <CustomTextField
                                  id='custodianMasterAccId'
                                  name='custodianMasterAccId'
                                  label='Master Account Id *'
                                  placeholder='Master Account Id'
                                  disabled={!defaultAccountDetails?.fieldEditAllowed?.custodianMasterAccId}
                                  value={formik?.values?.custodianMasterAccId}
                                  onChange={formik?.handleChange}
                                  InputLabelProps={{ shrink: formik?.values?.custodianMasterAccId }}
                                  {...getFieldErrorProps('custodianMasterAccId')}
                                />
                              </Grid>
                              )
                            : ''
                         }
                       </>
                       )
                     : ''
              }
              <Grid item xs={12} sm={6} md={4} mb={1}>
                <CustomTextField
                  label='Account Status'
                  placeholder='Account Status'
                  InputLabelProps={{ shrink: formik?.values?.statusDesc }}
                  value={formik?.values?.statusDesc || null}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={2}>
                <CustomAutocompleteField
                  label='Financial Advisor *'
                  optionValue='advName'
                  options={advIdsList}
                  value={formik?.values?.advData || null}
                  disabled={!defaultAccountDetails?.fieldEditAllowed?.advName}
                  onChange={(e, value) => {
                    formik.setFieldValue('advData', value)
                  }}
                  onBlur={formik.handleBlur}
                  {...getFieldErrorProps('advData')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={2}>
                <CustomAutocompleteField
                  label='Secondary Financial Advisor'
                  optionValue='advName'
                  options={advIdsList}
                  value={
                      formik.values.secAdvData
                        ? { ...formik.values.secAdvData, advName: formik.values.secAdvData.secAdvName }
                        : null
                    }
                  disabled={!defaultAccountDetails?.fieldEditAllowed?.secAdvName}
                  onChange={(e, value) => {
                    formik.setFieldValue('secAdvData', {
                      secAdvId: value?.advId,
                      secAdvName: value?.advName
                    })
                  }}
                  onBlur={formik.handleBlur}
                  {...getFieldErrorProps('secAdvData')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={1}>
                <CustomDateField
                  id='startDate'
                  name='startDate'
                  label='Start Date'
                  placeholder='Start Date'
                  disabled={!defaultAccountDetails?.fieldEditAllowed?.startDate}
                  value={formik?.values?.startDate ? dayjs(formik?.values?.startDate).format('YYYY-MM-DD') : ''}
                  onChange={(event) => {
                    const formattedDate = event?.target?.value ? dayjs(event?.target?.value).format('YYYY-MM-DD') : ''
                    formik.setFieldValue('startDate', formattedDate)
                  }}
                  {...getFieldErrorProps('startDate')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={1}>
                <CustomDateField
                  id='endDate'
                  name='endDate'
                  label='End Date'
                  placeholder='End Date'
                  disabled={!defaultAccountDetails?.fieldEditAllowed?.endDate}
                  value={formik?.values?.endDate ? dayjs(formik?.values?.endDate).format('YYYY-MM-DD') : ''}
                  onChange={(event) => {
                    const formattedDate = event?.target?.value ? dayjs(event?.target?.value).format('YYYY-MM-DD') : ''
                    formik.setFieldValue('endDate', formattedDate)
                  }}
                  {...getFieldErrorProps('endDate')}
                />
              </Grid>
              {
                formik?.values?.accountType !== 'TRANSITION'
                  ? (
                    <Grid item xs={12} sm={6} md={4} mb={1}>
                      <CustomDateField
                        id='billStartDate'
                        name='billStartDate'
                        label='Bill Start Date'
                        placeholder='Bill Start Date'
                        disabled={!defaultAccountDetails?.fieldEditAllowed?.billStartDate}
                        value={formik?.values?.billStartDate ? dayjs(formik?.values?.billStartDate).format('YYYY-MM-DD') : ''}
                        onChange={(event) => {
                          const formattedDate = event?.target?.value ? dayjs(event?.target?.value).format('YYYY-MM-DD') : ''
                          formik.setFieldValue('billStartDate', formattedDate)
                        }}
                        {...getFieldErrorProps('billStartDate')}
                      />
                    </Grid>
                    )
                  : ''
              }
            </Grid>
          </CardContent>
        </Card>
        <Card className='card-layout' sx={{ marginTop: '22px' }}>
          <CardContent>
            <Typography className='account-label-header' mb={2}>
              Tax Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} mb={2}>
                <CustomSelectField
                  id='isTaxManaged'
                  name='isTaxManaged'
                  label='Tax Managed '
                  placeholder='Tax Managed'
                  options={['YES', 'NO']}
                  value={formik?.values?.isTaxManaged ? 'YES' : 'NO'}
                  disabled={!defaultAccountDetails?.fieldEditAllowed?.isTaxManaged}
                  onChange={(e) => {
                    formik.setFieldValue('isTaxManaged', e.target.value === 'YES')
                  }}
                  {...getFieldErrorProps('isTaxManaged')}
                />
              </Grid>
              {
                formik?.values?.isTaxManaged
                  ? (
                    <>
                      <Grid item xs={12} sm={6} md={4} mb={2}>
                        <CustomSelectField
                          id='stateId'
                          name='stateId'
                          label='Tax State *'
                          placeholder='Tax State'
                          options={taxStates}
                          getOptionLabel={(option) => option.stateName}
                          getOptionValue={(option) => option.stateId}
                          value={formik?.values?.stateId}
                          disabled={!defaultAccountDetails?.fieldEditAllowed?.stateId}
                          onChange={formik?.handleChange}
                          {...getFieldErrorProps('stateId')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} mb={2}>
                        <CustomSelectField
                          id='taxSensitivity'
                          name='taxSensitivity'
                          label='Tax Sensitivity *'
                          placeholder='Tax Sensitivity'
                          options={['HIGH', 'LOW', 'MEDIUM']}
                          value={formik?.values?.taxSensitivity}
                          disabled={!defaultAccountDetails?.fieldEditAllowed?.taxSensitivity}
                          onChange={formik?.handleChange}
                          {...getFieldErrorProps('taxSensitivity')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} mb={2}>
                        <CustomNumberField
                          id='longTermTaxRate'
                          name='longTermTaxRate'
                          label='Long Term Tax Rate (%) *'
                          placeholder='Long Term Tax Rate'
                          disabled={!defaultAccountDetails?.fieldEditAllowed?.longTermTaxRate}
                          value={formik?.values?.longTermTaxRate}
                          onChange={(event) => {
                            let newValue = event?.target?.value
                            if (newValue !== '') {
                              const numValue = parseFloat(newValue)
                              if (!isNaN(numValue)) {
                                newValue = numValue.toString()
                              }
                            }
                            if (newValue === '' || (parseFloat(newValue) >= 0 && parseFloat(newValue) <= 100)) {
                              formik.setFieldValue('longTermTaxRate', newValue)
                            }
                          }}
                          InputLabelProps={{
                            shrink: formik?.values?.longTermTaxRate !== undefined &&
                            formik?.values?.longTermTaxRate !== '' && formik?.values?.longTermTaxRate !== null
                          }}
                          {...getFieldErrorProps('longTermTaxRate')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} mb={2}>
                        <CustomNumberField
                          id='shortTermTaxRate'
                          name='shortTermTaxRate'
                          label='Short Term Tax Rate (%) *'
                          placeholder='Short Term Tax Rate'
                          disabled={!defaultAccountDetails?.fieldEditAllowed?.shortTermTaxRate}
                          value={formik?.values?.shortTermTaxRate}
                          onChange={(event) => {
                            let newValue = event?.target?.value
                            if (newValue !== '') {
                              const numValue = parseFloat(newValue)
                              if (!isNaN(numValue)) {
                                newValue = numValue.toString()
                              }
                            }
                            if (newValue === '' || (parseFloat(newValue) >= 0 && parseFloat(newValue) <= 100)) {
                              formik.setFieldValue('shortTermTaxRate', newValue)
                            }
                          }}
                          InputLabelProps={{
                            shrink: formik?.values?.shortTermTaxRate !== undefined &&
                            formik?.values?.shortTermTaxRate !== '' && formik?.values?.shortTermTaxRate !== null
                          }}
                          {...getFieldErrorProps('shortTermTaxRate')}
                        />
                      </Grid>
                    </>
                    )
                  : ''
              }
              <Grid item xs={12} sm={6} md={4} mb={2}>
                <CustomAutocompleteField
                  label='Sell Logic *'
                  optionValue='sellLogicCode'
                  options={sellLogicCodes}
                  value={formik?.values?.sellLogicData || null}
                  disabled={!defaultAccountDetails?.fieldEditAllowed?.sellLogicId}
                  onChange={(e, value) => {
                    formik.setFieldValue('sellLogicData', value)
                  }}
                  onBlur={formik.handleBlur}
                  {...getFieldErrorProps('sellLogicData')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={2}>
                <CustomTextField
                  id='fundingSource'
                  name='fundingSource'
                  label='Funding Source'
                  placeholder='Funding Source'
                  disabled={!defaultAccountDetails?.fieldEditAllowed?.fundingSource}
                  value={formik?.values?.fundingSource}
                  onChange={formik?.handleChange}
                  InputLabelProps={{ shrink: formik?.values?.fundingSource }}
                  {...getFieldErrorProps('fundingSource')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={2}>
                <CustomNumberField
                  id='minCash'
                  name='minCash'
                  label='Min Cash Level *'
                  placeholder='Min Cash Level'
                  disabled={!defaultAccountDetails?.fieldEditAllowed?.minCash}
                  value={formik?.values?.minCash || null}
                  onChange={(event) => {
                    let newValue = event?.target?.value
                    if (newValue !== '') {
                      const numValue = parseFloat(newValue)
                      if (!isNaN(numValue)) {
                        newValue = numValue.toString()
                      }
                    }
                    if (newValue === '' || (parseFloat(newValue) >= 0 && parseFloat(newValue) <= 100)) {
                      formik.setFieldValue('minCash', newValue || null)
                    }
                  }}
                  InputLabelProps={{
                    shrink: formik?.values?.minCash !== undefined &&
                    formik?.values?.minCash !== '' && formik?.values?.minCash !== null
                  }}
                  {...getFieldErrorProps('minCash')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={2}>
                <CustomNumberField
                  id='maxCash'
                  name='maxCash'
                  label='Max Cash Level *'
                  placeholder='Max Cash Level'
                  disabled={!defaultAccountDetails?.fieldEditAllowed?.maxCash}
                  value={formik?.values?.maxCash || null}
                  onChange={(event) => {
                    let newValue = event?.target?.value
                    if (newValue !== '') {
                      const numValue = parseFloat(newValue)
                      if (!isNaN(numValue)) {
                        newValue = numValue.toString()
                      }
                    }
                    if (newValue === '' || (parseFloat(newValue) >= 0 && parseFloat(newValue) <= 100)) {
                      formik.setFieldValue('maxCash', newValue || null)
                    }
                  }}
                  InputLabelProps={{
                    shrink: formik?.values?.maxCash !== undefined &&
                    formik?.values?.maxCash !== '' && formik?.values?.maxCash !== null
                  }}
                  {...getFieldErrorProps('maxCash')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={2}>
                <CustomTextField
                  label='Port Spec'
                  placeholder='Port Spec'
                  disabled
                  InputLabelProps={{ shrink: formik?.values?.portSpecName }}
                  value={formik?.values?.portSpecName || null}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
      {
        openConfirmationDialog
          ? (
            <ConfirmCloseAccountDialog />
            )
          : ''
      }
    </Box>
  )
}

export default PersonalDetails
