import { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { Avatar, Fade, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material'
import Logout from '@mui/icons-material/Logout'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { clearStore } from '../store/dashboard-reducer/dashboard-reducer'
import { clearUserStore } from '../store/user-reducer/user-reducer'
import { logoutChannel } from '../utils/getLogoutChannel'
import Loader from '../pages/Loader'

const ProfileMenu = () => {
  const { user, storeUser, setUserAclData } = useAuth()
  const [anchorElUser, setAnchorElUser] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const handleSignOut = () => {
    setLoading(true)
    Auth.signOut().then(() => {
      storeUser(null)
      localStorage.clear()
      dispatch(clearStore('RESET'))
      dispatch(clearUserStore('RESET'))
      navigate('/login')
      setLoading(false)
      setUserAclData({})
      const title = document.getElementById('title')
      const favicon = document.getElementById('favicon')
      title.innerText = 'Aris Investing'
      favicon.href = '/ArisFavicon.svg'
    }).catch(() => setLoading(false))
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
    document.body.classList.add('model-open')
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
    document.body.classList.remove('model-open')
  }

  useEffect(() => {
    // listen to logout channel to redirect user to login screen
    // if user is accessing different screen
    // and in another tab user is accessing login screen
    logoutChannel.onmessage = (e) => {
      if (window.location.pathname !== '/login') {
        handleSignOut()
      }
    }
  }, [])

  return (
    <>
      {loading ? <Loader /> : ''}
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
        <Avatar sx={{ bgcolor: '#E6ECF3', color: 'black', height: '35px', width: '35px', fontSize: '16px' }}>{user?.name ? user?.name?.slice(0, 1).toUpperCase() : ''}</Avatar>
      </IconButton>
      <Menu
        sx={{ mt: '30px' }}
        id='menu-appbar'
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        TransitionComponent={Fade}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          <Typography textAlign='center'>Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export default ProfileMenu
