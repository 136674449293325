export const moduleConfig = {
  // dashboard and account review
  ACCOUNT_REVIEW: 'account-review',
  ACCOUNT_DASHBOARD: 'account-dashboard',
  MANAGE_PROFILE: 'manage-profile',
  ACCOUNT_ATTRIBUTES: 'acc-attributes',
  TOTAL_MARKET_VALUE: 'acc-total-market-value',
  TAX_SENSITIVITY: 'acc-tax-sensitivity',
  AVAILABLE_CASH: 'acc-available-cash',
  URGL: 'acc-unrealized-gain-loss',
  INVESTMENT_ADVISOR: 'acc-investment-advisor',
  YTD_RGL: 'acc-realized-gain-loss',
  ACCOUNT_INCOME_DIV_YIELD: 'acc-div-yield',
  ACTIVE_WEIGHT: 'acc-active-weight',
  STRATEGY_ATTRIBUTES: 'str-attributes',
  CASH_FLOOR: 'str-cash-floor',
  STRATEGY_INCOME_DIV_YIELD: 'str-div-yield',
  BENCHMARK: 'str-benchmark',
  ALLOCATION_CHART: 'chart-allocation',
  MAJOR_ASSET_ALLOCATION: 'major-asset-allocation',
  PORTFOLIO_RISK: 'acc-portfolio-risk',
  ACCOUNT_REQUESTS: 'acc-requests',
  TOP_MODEL_POSITIONS: 'instr-positions',
  TOP_OVER_WEIGHTED_SECURITIES: 'instr-overweighted',
  TOP_UNDER_WEIGHTED_SECURITIES: 'instr-underweighted',
  COMMENTS: 'req-comments',
  REQUEST: 'acc-request',
  AUM_REPORT: 'aum-report',

  All_REQUESTS: 'all-requests',
  VIEW_REQUEST: 'view-request',
  UPDATE_REQUEST: 'update-request',

  // account-review actions
  VIEW_ACCOUNT_POLICY_CHECK: 'view-account-policy-check',
  DOWNLOAD_ACCOUNT_POLICY_CHECK: 'download-account-policy-check',
  PERSONALIZATION: 'personalization',
  VIEW_PERSONALIZATION: 'view-personalization',
  EDIT_TRADING_PERSONALIZATION: 'edit-trading-personalization',
  EDIT_TRANSITION_PERSONALIZATION: 'edit-transition-personalization',

  IMPORT_PORTFOLIO: 'import-portfolio',
  CURRENT_PORTFOLIO: 'current-portfolio',
  IMPORT_TRADING_PORTFOLIO: 'import-trading-portfolio',
  IMPORT_TRANSITION_PORTFOLIO: 'import-transition-portfolio',
  SHOW_ACCOUNT_OPT_STATUS: 'show-account-opt-status',
  // model actions

  STRATEGY_PORTFOLIO: 'strategy-portfolio',
  STRATEGY_ONBOARDING: 'strategy-onboarding',
  MODEL: 'model',
  CURRENT_STRATEGY_PORTFOLIO: 'current-strategy-portfolio',
  UPLOAD_STRATEGY_PORTFOLIO: 'upload-strategy-portfolio',
  MANAGE_RESTRICTION: 'manage-restriction',
  STRATEGY_MODEL_CHANGES: 'strategy-model-changes',
  HOLDING_DATE: 'holding-date',
  DOWNLOAD_SAMPLE_PORTFOLIO: 'download-sample-portfolio',
  DOWNLOAD_LATEST_PORTFOLIO: 'download-latest-portfolio',
  DOWNLOAD_CURRENT_STRATEGY_PORTFOLIO: 'download-current-strategy-portfolio',
  DOWNLOAD_PORTFOLIO_IMPORT_FORMAT: 'download-portfolio-in-import-format',
  DOWNLOAD_RAW_STRATEGY_PORTFOLIO: 'download-raw-strategy-portfolio',
  EXPORT_DOWNLOAD_LOG_REPORT: 'export-download-log-report',
  CREATE_STRATEGY: 'create-strategy',
  DELIMITER_CHANGE: 'delimiter-change',
  DYNAMIC_PORTFOLIO: 'dynamic-portfolio',
  VIEW_MODEL_POLICY_CHECK: 'view-model-policy-check',
  DOWNLOAD_MODEL_POLICY_CHECK: 'download-model-policy-check',

  // trade
  TRADE: 'trade',
  TRADE_APPROVAL: 'trade-approvals',
  VIEW_SUMMARY: 'view-summary',
  TRADE_BLOTTER: 'trade-blotter',
  TRADE_HISTORY: 'trade-history',
  APPROVAL1: 'app1',
  APPROVAL2: 'app2',
  DOWNLOAD_TRANSACTION: 'download-transactions',
  MIN_CASH_POLICY_CHECK: 'min-cash-policy-check',
  POLICY_CHECK: 'policy-check',
  RUN_ADHOC: 'run-adhoc',
  BULK_RERUN_POLICY: 'bulk-re-run-policy',
  BULK_RERUN_OPTIMIZATION: 'bulk-re-run-optimization',
  TRADE_OPT_TRADE_DETAILS: 'view-opt-trade-details',
  TRADE_SUMMARY: 'trade-summary',
  ACCOUNT_COMMENTS: 'account-comments',
  TRADE_STATUS_REFRESH: 'trade-status-refresh',
  VIEW_OPT_TRADE_PROPERTIES: 'view-opt-trade-properties',
  VIEW_TRADE_DETAILS: 'view-trade-details',
  VIEW_TRADE_PROPERTIES: 'view-trade-properties',
  DELIST_INSTRUMENTS: 'delist-instruments',
  FEASIBILITY_REPORT: 'feasibility-report',
  VIEW_TRADE_BLOTTER: 'view-trade-blotter',
  MODEL_VARIANCE: 'model-variance',
  VIEW_POLICY_STATUS: 'view-policy-status',
  DOWNLOAD_POLICY_STATUS: 'download-policy-status',
  VIEW_MARKET_VALUE_POLICY_CHECK: 'view-market-value-policy-check',
  DOWNLOAD_MARKET_VALUE_POLICY_CHECK: 'download-market-value-policy-check',
  VIEW_TRADE_EXECUTION_LOGS: 'view-trade-execution-logs',
  SHOW_TRADE_WORKFLOW: 'show-trade-workflow',
  SHOW_RESOURCE_ID: 'show-resource-id',
  ALLOW_RESUME_TRADE: 'allow-resume-trade',
  VIEW_LIVE_PRICE: 'view-live-price',
  SHOW_MODEL_CHANGE_WITH_TRADE: 'show-model-change-with-trade',
  DI_ASSIST_TRADING: 'di-assist-trading',
  MANAGE_ADHOC_OPT_PROPERTIES: 'manage-adhoc-opt-properties',

  ARIS_INTERNAL: 'aris-internal',
  VIEW_TRADE_ADHOC: 'view-trade-adhoc',
  EDIT_FIX_CONNECTIVITY_STATUS: 'edit-fix-connectivity-status',

  // aggregate
  AGGREGATE: 'aggregate',
  ENTITY_MAPPING: 'entity-mapping',
  AGGREGATE_DASHBOARD: 'aggregate-dashboard',
  GROUP_ONBOARDING: 'group-onboarding',
  CREATE_GROUP: 'create-group',
  EDIT_GROUP: 'edit-group',
  DELETE_GROUP: 'delete-group',
  UMA_ONBOARDING: 'uma-onboarding',
  CREATE_TRADING_UMA: 'create-trading-uma',
  CREATE_TRANSITION_UMA: 'create-transition-uma',
  RE_RUN_UMA_TRANSITION_OPTIMIZATION: 're-run-uma-transition-optimization',
  VIEW_UMA_TRANSITION_OPTIMIZATION_JSON: 'view-uma-transition-optimization-json',
  DOWNLOAD_SELL_TRANSFER_TRADES: 'download-sell-transfer-trades',
  DOWNLOAD_VALIDATE_TRANSFER: 'download-validate-transfer',
  VIEW_UMA_DASHBOARD: 'view-uma-dashboard',
  VIEW_GROUP_DASHBOARD: 'view-group-dashboard',
  ADJUST_MULTI_ACCOUNT_TO_TRADING_UMA: 'adjust-multi-account-to-trading-uma',
  VIEW_UMA_POLICY_CHECK: 'view-uma-policy-check',
  DOWNLOAD_UMA_POLICY_CHECK: 'download-uma-policy-check',
  UMA_DASHBOARD: 'uma-dashboard',

  // policy
  IPS: 'ips',
  POLICY: 'policy',
  POLICY_EDIT: 'policy-edit-access',
  POLICY_VIEW: 'policy-view-access',
  STATEMENT: 'statement',
  STATEMENT_EDIT: 'statement-edit-access',
  STATEMENT_VIEW: 'statement-view-access',
  MONITORING: 'monitoring',
  MONITORING_EDIT: 'monitoring-edit-access',
  MONITORING_VIEW: 'monitoring-view-access',
  EVIDENCE: 'evidence',
  EVIDENCE_EDIT: 'evidence-edit-access',
  EVIDENCE_VIEW: 'evidence-view-access',
  // expanded-chart
  EXPANDED_CHARTS: 'expanded-charts',
  // Account Migration
  ACCOUNT_MIGRATION: 'account-migration',
  EXPORT_ACCOUNT: 'export-account',
  IMPORT_ACCOUNT: 'import-account',

  // Pdf-customizer
  PDF_CUSTOMIZER: 'pdf-customizer',
  PDF_REPORT: 'report',

  // Account Onboarding
  ACCOUNT_ONBOARDING: 'account-onboarding',
  CREATE_TRADING_ACCOUNT: 'create-trading-account',
  CREATE_TRANSITION_ACCOUNT: 'create-transition-account'
}
