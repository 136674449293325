import React, { Fragment, useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { KeyboardArrowLeft } from '@mui/icons-material'
import SaveIcon from '@mui/icons-material/Save'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Button, Card, CardContent, Chip, Dialog, Divider, FormControl, FormControlLabel, Grid, IconButton, Input, InputAdornment, List, ListItem, ListItemText, Radio, RadioGroup, Skeleton, Switch, TextField, ToggleButton, Tooltip, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import ListItemIcon from '@mui/material/ListItemIcon'
import { capitalizeName } from '.'
import CreateInputForm from './CreateInputForm'
import Loader from '../Loader'
import RoleAndAccountManagePopUp from './RoleAndAccountManagePopUp'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import { useSuccessToast } from '../../hooks/useSuccessToast'

export const tradingUmaResourceList = ['group-onboarding', 'taxlot-swapping']
export const closeOnSaveExcludeResourceList = ['group-onboarding', 'aggregate-dashboard', 'trade-approvals', 'app1', 'app2']
const servicePopupTabs = {
  'strategy-portfolio': ['Strategy'],
  'port-spec': ['Strategy'],
  'strategy-onboarding': ['Strategy'],
  'trade-approvals': ['Account', 'Trade-Groups'],
  'aggregate-dashboard': ['UMA', 'Groups'],
  'group-onboarding': ['Account', 'UMA', 'Groups'],
  'entity-mapping': ['Groups'],
  'taxlot-swapping': ['UMA']
}

const PermissionTable = (params) => {
  const { roleParams, userProps, setActiveTab, setModifyUser, setModifyRole, type, selectedRow, roleRows, setUpdatedRole, updatedRole } = params
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [data, setData] = useState([])
  const [selectedService, setSelectedService] = useState({})
  const [isAccessApiLoading, setIsAccessApiLoading] = useState(false)
  const [isPMAccessApiLoading, setIsPMAccessApiLoading] = useState(false)
  const [isCommonServiceLoading, setIsCommonServiceLoading] = useState(false)
  // const [open, setOpen] = useState(false)
  const [currentServiceId, setCurrentServiceId] = useState('')
  const [currentClickedServiceID, setCurrentClickedServiceID] = useState('')
  const [existingRoleSelect, setExistingRoleSelect] = useState(false)
  const [selectedRoleValue, setSelectedRoleValue] = useState(null)
  const [customRoleSelect, setCustomRoleSelect] = useState(false)
  const [idSelectionModalData, setIdSelectionModalData] = useState({})
  const [showCopyContentPanel, setShowCopyContentPanel] = useState({})
  const [sponsorData, setSponsorData] = useState([])
  const [PMUserData, setPMUserData] = useState([])
  const [PMAccessManagementData, setPMAccessManagementData] = useState([])
  const [userData, setUserData] = useState([])
  const [roleData, setRoleData] = useState([])
  const [currentCheckedServiceResourceList, setCurrentCheckedServiceResourceList] = useState([])
  const [parentDataResourceList, setParentDataResourceList] = useState([])
  const [checkedParentDataResourceList, setCheckedParentDataResourceList] = useState([])
  const [currentParentServiceID, setCurrentParentServiceID] = useState('')
  const [currentChildServiceID, setCurrentChildServiceID] = useState('')
  const [initialSpnStructureData, setInitialSpnStructureData] = useState([])
  const [sponsorDataResultList, setSponsorDataResultList] = useState([])
  const [checkedCopyParentDataResourceList, setCheckedCopyParentDataResourceList] = useState([])
  const [selectedServiceCount, setSelectedServiceCount] = useState('')
  const [copySelectedServiceCount, setCopySelectedServiceCount] = useState('')
  const [createRoleOpen, setCreateRoleOpen] = useState(false)
  const [updateApiLoading, setUpdateApiLoading] = useState(false)
  const [updateExistingRoleLoading, setUpdateExistingRoleLoading] = useState(false)
  const [roleListOptions, setRoleListOptions] = useState([])
  const [createApiLoading, setCreateApiLoading] = useState(false)
  const [customRoleResData, setCustomRoleResData] = useState([])
  const [allSpnAccounts, setAllSpnAccounts] = useState([])
  const [allStrategiesList, setAllStrategiesList] = useState([])
  const [umaAggregateLoading, setUMAAggregateLoading] = useState(true)
  const [allUmaAggregateList, setAllUMAAggregateList] = useState([])
  const [allUmaTradingAccounts, setAllUmaTradingAccounts] = useState([])
  const [aggregateGroupsLoading, setAggregateGroupsLoading] = useState(true)
  const [allAggregateGroupsList, setAllAggregateGroupsList] = useState([])
  const [allEntityMapGroupsList, setAllEntityMapGroupsList] = useState([])
  const [allSpnTradingAccounts, setAllSpnTradingAccounts] = useState([])
  const [spnAccountLoading, setSpnAccountsLoading] = useState(true)
  const [strategyListLoading, setStrategyListLoading] = useState(true)
  const [serviceAccSwitchCheckedList, setServiceAccSwitchCheckedList] = useState([])
  const [allServiceAccountSwitches, setAllServiceAccountSwitches] = useState([])
  const [serviceAccSwitchCheckedCount, setServiceAccSwitchCheckedCount] = useState('')
  const [serviceCopyAccSwitchCheckedCount, setServiceCopyAccSwitchCheckedCount] = useState('')
  const [initialCopyCheckedAccount, setInitialCopyCheckedAccount] = useState([])
  const [formEditMode, setFormEditMode] = useState('add')

  // update service payload(body)
  const saveServiceData = (data) => {
    const serviceJSON = []

    // iterate over each service in the data array
    data?.forEach(service => {
      const parentObject = {
        serviceId: service?.serviceId,
        allResources: false, // default to false; will be updated if needed
        resources: [], // to store processed resource data
        accountMgmtAccess: [] // to store access-related resource IDs
      }

      // iterate over each resource within the service
      service?.resources?.forEach(resource => {
        if (!parentObject.allResources) {
          // check if the resource has selected sub-resources
          if (selectedRow?.type === 'MANAGE_PM_PERMISSION' || checkedParentDataResourceList[resource?.resourceId + '-' + resource?.serviceId]?.length > 0) {
            const resourceObject = {
              resourceId: resource.resourceId,
              all: false, // default to false; will be updated if needed
              children: [] // To store processed sub-resources
            }

            if (!resourceObject.all) {
              // iterate over sub-resources
              resource?.subResources?.forEach(subResource => {
                if (selectedRow?.type === 'MANAGE_PM_PERMISSION' || ((checkedParentDataResourceList[resource?.resourceId + '-' + resource?.serviceId]?.length > 0) &&
                  checkedParentDataResourceList[resource?.resourceId + '-' + resource?.serviceId]?.includes(subResource?.resourceId))) {
                  const subResourceObject = {
                    resourceId: subResource.resourceId,
                    all: checkedParentDataResourceList[subResource?.resourceId + '-' + subResource?.serviceId]?.length === sponsorDataResultList[subResource?.resourceId + '-' + subResource?.serviceId]?.length ||
                    (checkedParentDataResourceList[resource?.resourceId + '-' + resource?.serviceId].includes(subResource?.resourceId) && checkedParentDataResourceList[resource?.resourceId + '-' + resource?.serviceId].length > 0),
                    children: [] // to store processed child resources
                  }

                  if (!subResourceObject.all) {
                    // iterate over child resources
                    subResource?.subResources?.forEach(child => {
                      const childObject = {
                        resourceId: child.resourceId,
                        all: checkedParentDataResourceList[child?.resourceId + '-' + child?.serviceId]?.length === sponsorDataResultList[child?.resourceId + '-' + child?.serviceId]?.length,
                        children: [] // to store processed grandchild resources
                      }

                      // check if the child resource is selected
                      if (checkedParentDataResourceList[subResource?.resourceId + '-' + subResource?.serviceId]?.includes(child?.resourceId)) {
                        subResourceObject.children.push(childObject)
                      }

                      // iterate over grandchild resources
                      child?.subResources?.forEach(grandchild => {
                        if (checkedParentDataResourceList[child?.resourceId + '-' + child?.serviceId]?.includes(grandchild?.resourceId)) {
                          childObject.children.push({
                            resourceId: grandchild?.resourceId,
                            all: true // Assume all grandchild resources are selected
                          })
                        }
                      })
                    })
                  }

                  // if the sub-resource is selected for account management access, add it
                  if (serviceAccSwitchCheckedList[subResource?.resourceId + '-' + subResource?.serviceId]) {
                    parentObject?.accountMgmtAccess.push(subResource?.resourceId)
                  }

                  resourceObject?.children.push(subResourceObject)
                }
              })
            }

            parentObject?.resources.push(resourceObject)
          }
        }

        // if the resource is selected for account management access, add it
        if (serviceAccSwitchCheckedList[resource?.resourceId + '-' + resource?.serviceId]) {
          parentObject?.accountMgmtAccess.push(resource?.resourceId)
        }
      })

      // add the processed service object to the final JSON only if it has resources
      if (parentObject?.resources?.length > 0) {
        serviceJSON.push(parentObject)
      }
    })

    // example
    //   {
    //     "serviceId": "account-onboarding",
    //     "allResources": false,
    //     "resources": [
    //         {
    //             "resourceId": "account-onboarding",
    //             "all": false,
    //             "children": [
    //                 {
    //                     "resourceId": "create-transition-account",
    //                     "all": true,
    //                     "children": []
    //                 }
    //             ]
    //         }
    //     ],
    //     "accountMgmtAccess": []
    // }

    return serviceJSON
  }

  useEffect(() => {
    if (initialCopyCheckedAccount && user) {
      setServiceCopyAccSwitchCheckedCount(JSON.stringify(initialCopyCheckedAccount)?.length)
    }
    if (user && serviceAccSwitchCheckedList) {
      setServiceAccSwitchCheckedCount(JSON.stringify(serviceAccSwitchCheckedList)?.length)
    }
  }, [user, initialCopyCheckedAccount, serviceAccSwitchCheckedList])

  // services key-value pair for spn,role and user
  // store permited resource data for specific type
  useEffect(() => {
    if (user) {
      if (sponsorData && sponsorData?.length > 0) {
        setCheckedParentDataResourceList(createServiceChildArray(sponsorData))
        setCheckedCopyParentDataResourceList(createServiceChildArray(sponsorData))
        setServiceAccSwitchCheckedList(filterIsAccManagedChecked(sponsorData))
        setInitialCopyCheckedAccount(filterIsAccManagedChecked(sponsorData))
      } else if (PMUserData && PMUserData?.length > 0) {
        setCheckedParentDataResourceList(createServiceChildArray(PMUserData))
        setCheckedCopyParentDataResourceList(createServiceChildArray(PMUserData))
        setServiceAccSwitchCheckedList(filterIsAccManagedChecked(PMAccessManagementData))
        setInitialCopyCheckedAccount(filterIsAccManagedChecked(PMAccessManagementData))
      } else if (userData && userData?.length > 0) {
        setCheckedParentDataResourceList(createServiceChildArray(userData))
        setCheckedCopyParentDataResourceList(createServiceChildArray(userData))
      } else if (PMAccessManagementData && PMAccessManagementData?.length > 0) {
        setServiceAccSwitchCheckedList(filterIsAccManagedChecked(PMAccessManagementData))
        setInitialCopyCheckedAccount(filterIsAccManagedChecked(PMAccessManagementData))
      } else {
        setCheckedParentDataResourceList(createServiceChildArray(roleData))
        setCheckedCopyParentDataResourceList(createServiceChildArray(roleData))
      }
    }
  }, [user, sponsorData, PMUserData, userData, roleData, PMAccessManagementData])

  // store initial sponsor data
  useEffect(() => {
    if (initialSpnStructureData) {
      setSponsorDataResultList(createServiceChildArray(initialSpnStructureData))
    }
  }, [initialSpnStructureData])

  useEffect(() => {
    if (data) {
      setParentDataResourceList(createServiceChildArray(data))
      setAllServiceAccountSwitches(createPmServiceArray(data))
    }
  }, [data])

  // serviceId - resourceId key-value
  useEffect(() => {
    if (currentParentServiceID && data) {
      setCurrentCheckedServiceResourceList((prevState) => ({
        ...prevState,
        [currentParentServiceID]: extractChildResourceIds(data, currentParentServiceID)?.length === 0 ? [currentParentServiceID] : extractChildResourceIds(data, currentParentServiceID)
      }))
    }
  }, [currentParentServiceID, data])

  useEffect(() => {
    if (user && userProps?.roleCode && roleRows) {
      const filteredRoles = roleRows?.filter(data => {
        if (updatedRole) {
          return data?.roleCode !== updatedRole && data?.roleCode !== 'IND'
        } else {
          return data?.roleCode !== userProps?.roleCode && data?.roleCode !== 'IND'
        }
      })
      setRoleListOptions(filteredRoles)
    }
  }, [user, userProps, roleRows, updatedRole])

  useEffect(() => {
    if (idSelectionModalData && idSelectionModalData?.props) {
      if (idSelectionModalData?.popupTabs?.length && idSelectionModalData?.popupTabs?.includes('UMA')) {
        if (idSelectionModalData?.props?.resourceId) {
          getAllUMAApi(idSelectionModalData?.props?.resourceId)
        } else {
          getAllUMAApi()
        }
      } if (idSelectionModalData?.popupTabs?.length && (idSelectionModalData?.popupTabs?.includes('Groups') || idSelectionModalData?.popupTabs?.includes('Trade-Groups'))) {
        getAllAggregateGroupsApi(idSelectionModalData?.props?.resourceId)
      } if (idSelectionModalData?.popupTabs?.length && idSelectionModalData?.popupTabs?.includes('Account')) {
        if (idSelectionModalData?.props?.serviceId) {
          getAllSpnAccountsApi(idSelectionModalData?.props?.serviceId)
        } else {
          getAllSpnAccountsApi()
        }
      } if (idSelectionModalData?.popupTabs?.length && idSelectionModalData?.popupTabs?.includes('Strategy')) {
        getAllStrategiesApi()
      }
    }
  }, [user, selectedRow, idSelectionModalData])

  const getAllSpnAccountsApi = (serviceId) => {
    setSpnAccountsLoading(true)
    API.get(
      'baseAclURL',
      `user-access-control/v1/${user?.userGroup}/sponsor/accounts${userProps?.sponsor?.sponsorId ? `?sponsorId=${userProps.sponsor.sponsorId}` : ''}`,
      !userProps?.sponsor ? { queryStringParameters: { source: 'pm' } } : {}
    )
      .then((res) => {
        if (res && res.success && res?.data) {
          if (serviceId === 'trade' || serviceId === 'aggregate') {
            const tradeAccounts = res?.data?.filter((acc) => acc?.accountType === 'TRADING')
            setAllSpnTradingAccounts(tradeAccounts)
            setAllSpnAccounts(res?.data)
          } else {
            setAllSpnAccounts(res?.data)
          }
        }
      })
      .catch((error) => {
        setSpnAccountsLoading(false)
        showError(error, false, {}, 'Failed to load sponsor ACL.')
      }).finally(() => setSpnAccountsLoading(false))
  }

  const getAllStrategiesApi = () => {
    setStrategyListLoading(true)
    API.get('baseAclURL2', `user-access-control/v1/${user?.userGroup}/strategies`)
      .then((res) => {
        if (res && res.success && res?.data) {
          setAllStrategiesList(res?.data)
        }
      })
      .catch((error) => {
        setStrategyListLoading(false)
        showError(error, false, {}, 'Failed to load strategies')
      }).finally(() => setStrategyListLoading(false))
  }

  const getAllUMAApi = (resourceId) => {
    setUMAAggregateLoading(true)
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/uma${userProps?.sponsor?.sponsorId ? `?sponsorId=${userProps?.sponsor?.sponsorId || ''}` : ''}`,
      !userProps?.sponsor ? { queryStringParameters: { source: 'pm' } } : ''
    )
      .then((res) => {
        if (res && res.success && res?.data) {
          if (tradingUmaResourceList?.includes(resourceId)) {
            const tradeAccounts = res?.data?.filter((acc) => acc?.aggType === 'TRADING')
            setAllUmaTradingAccounts(tradeAccounts)
          } else {
            setAllUMAAggregateList(res?.data)
          }
        }
      })
      .catch((error) => {
        setUMAAggregateLoading(false)
        showError(error, false, {}, 'Failed to load UMA')
      }).finally(() => setUMAAggregateLoading(false))
  }

  const getAllAggregateGroupsApi = (resourceId) => {
    setAggregateGroupsLoading(true)
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/agg-group${userProps?.sponsor?.sponsorId ? `?sponsorId=${userProps?.sponsor?.sponsorId || ''}` : ''}`)
      .then((res) => {
        if (res && res.success && res?.data) {
          if (resourceId === 'trade-approvals' || resourceId === 'app1' || resourceId === 'app2') {
            setAllAggregateGroupsList(res?.data?.filter((groupObj) => groupObj.groupType === 'TRADING'))
          } else if (resourceId === 'entity-mapping') {
            setAllEntityMapGroupsList(res?.data?.filter((groupObj) => groupObj.groupType === 'ENTITYMAP'))
          } else {
            setAllAggregateGroupsList(res?.data)
          }
        }
      })
      .catch((error) => {
        setAggregateGroupsLoading(false)
        showError(error, false, {}, 'Failed to load aggregate')
      }).finally(() => setAggregateGroupsLoading(false))
  }

  // disable-enable apply button for update services(to compare length of counts)
  useEffect(() => {
    if (checkedParentDataResourceList && user) {
      setSelectedServiceCount(JSON.stringify(checkedParentDataResourceList))
    }
    if (user && checkedCopyParentDataResourceList) {
      setCopySelectedServiceCount(JSON.stringify(checkedCopyParentDataResourceList))
    }
  }, [user, checkedParentDataResourceList, checkedCopyParentDataResourceList])

  useEffect(() => {
    if (user && selectedRow) {
      getCommonServices()
    } else if (roleParams && roleParams?.roleName) {
      getCommonSponsorRoleServices(roleParams)
    } else if (userProps && Object?.keys(userProps)?.length > 0) {
      getCommonSponsorUserServices(userProps)
    }
  }, [user, roleParams, selectedRow, userProps])

  useEffect(() => {
    if (user) {
      if (selectedRow && selectedRow?.sponsorName) {
        getSponsorAccessServices(selectedRow)
      } else if (selectedRow && selectedRow?.userName) {
        getPMUserAccessServices(selectedRow)
        getPMAccountManagementAccess()
      } else if ((roleParams && roleParams?.roleName)) {
        getRoleAccessServices(roleParams)
      } else if (customRoleResData && customRoleResData.length > 0) {
        getRoleAccessServices(customRoleResData[0])
      } else if (userProps && Object?.keys(userProps)?.length > 0) {
        getUserAccessServices(userProps)
      } else if (selectedRow && selectedRow?.type === 'MANAGE_PM_PERMISSION') {
        getPMAccountManagementAccess()
      }
    }
  }, [user, roleParams, selectedRow, userProps, customRoleResData])

  const filterIsAccManagedChecked = (value) => {
    const result = {}
    value?.forEach(service => {
      service?.resources?.forEach(resource => {
        if (service?.accountMgmtAccess?.length && service?.accountMgmtAccess?.includes(resource?.resourceId)) {
          result[resource?.resourceId + '-' + resource?.serviceId] = service?.accountMgmtAccess?.length && service?.accountMgmtAccess?.includes(resource?.resourceId)
        }
        resource?.subResources?.forEach(subResource => {
          if (service?.accountMgmtAccess?.length && service?.accountMgmtAccess?.includes(subResource?.resourceId)) {
            result[subResource?.resourceId + '-' + subResource?.serviceId] = service?.accountMgmtAccess?.length && service?.accountMgmtAccess?.includes(subResource?.resourceId)
          }
        })
      })
    })
    return result
  }

  const createPmServiceArray = (value) => {
    const result = {}
    value?.forEach(service => {
      service?.resources?.forEach(resource => {
        result[resource?.resourceId + '-' + resource?.serviceId] = true
        resource?.subResources?.forEach(subResource => {
          if (subResource?.resourceId === 'app1' || subResource?.resourceId === 'app2') {
            result[subResource?.resourceId + '-' + subResource?.serviceId] = true
          }
        })
      })
    })
    return result
  }

  // store key value pairs
  const createServiceChildArray = (value) => {
    const result = {}

    // Function to extract resource IDs and populate the result object with child sub-resources
    // const extractResourceIds = (resources, serviceId) => {
    //   resources?.forEach(resource => {
    // const resourceId = resource?.resourceId
    // result[resourceId + '-' + resource?.serviceId] = []

    // if the resource has sub-resources, populate the result with their IDs
    // if (resource?.subResources && resource?.subResources?.length > 0) {
    //   resource?.subResources?.forEach(subResource => {
    //     result[resourceId + '-' + resource?.serviceId]?.push(subResource?.resourceId)
    //   })
    // }
    // // if no sub-resources but the value array is non-empty, add the resourceId itself
    // else if (resource?.subResources && resource.subResources?.length <= 0 && value && value?.length > 0) {
    //   result[resourceId + '-' + resource?.serviceId]?.push(resourceId)
    // }
    //   })
    // }

    value?.forEach(service => {
      service?.resources?.forEach(resource => {
        const parentResId = resource?.resourceId
        // populate result with child resource IDs for the current resource
        // ex: [parentResId + '-' + resource?.serviceId] = 'account-dashboard-account-review'
        result[parentResId + '-' + resource?.serviceId] = extractChildResourceIds(value, resource?.resourceId + '-' + resource?.serviceId)

        // if the resource has sub-resources, extract their IDs
        // if (resource?.subResources && resource.subResources?.length > 0) {
        //   extractResourceIds(resource.subResources, serviceId)
        // }

        // if no sub-resources but value array is non-empty, add the resourceId itself
        if (resource?.subResources && resource?.subResources?.length <= 0 && value && value?.length > 0) {
          result[resource?.resourceId + '-' + resource?.serviceId]?.push(resource?.resourceId)
        }
      })
    })

    return result
  }

  const extractChildResourceIds = (data, currentServiceId) => {
    const allResourceIds = []
    data?.forEach((service) => {
      service?.resources?.forEach((resource) => {
        resource?.subResources?.forEach((subResource) => {
          if (subResource?.parentResourceId + '-' + subResource?.serviceId === currentServiceId) {
            allResourceIds.push(subResource.resourceId)
          }
          subResource?.subResources?.forEach((child) => {
            if (child?.parentResourceId + '-' + child?.serviceId === currentServiceId) {
              allResourceIds.push(child.resourceId)
            }
          })
        })
      })
    })
    return allResourceIds
  }

  const handleRoleSelect = (e) => {
    if (e.target.value === 'Assign Existing Role') {
      setExistingRoleSelect(true)
      setCustomRoleSelect(false)
    } else if (e.target.value === 'Give The Custom') {
      setCreateRoleOpen(true)
      setCustomRoleSelect(true)
      setExistingRoleSelect(false)
    } else {
      setCustomRoleSelect(false)
      setExistingRoleSelect(false)
    }
  }

  const handleCloseModal = () => {
    setIdSelectionModalData({})
    setShowCopyContentPanel({})
    setStrategyListLoading(true)
    setSpnAccountsLoading(true)
    setAggregateGroupsLoading(true)
    setUMAAggregateLoading(true)
  }

  const onChangeHandler = (e, value) => {
    setSelectedRoleValue(value?.roleCode)
  }

  useEffect(() => {
    if (user && customRoleResData?.roleCode) {
      updateUserRole()
    }
  }, [user, customRoleResData])

  const updateUserRole = () => {
    setUpdateExistingRoleLoading(true)
    API.patch('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor/${userProps?.sponsor?.sponsorId}/user/${userProps?.userId}/role/${updatedRole || userProps?.roleCode}`,
      { body: { roleCode: customRoleResData?.roleCode || selectedRoleValue } })
      .then((res) => {
        if (res && res?.success && res?.data) {
          setExistingRoleSelect(false)
          setCustomRoleSelect(false)
          setUpdatedRole(res?.data[0]?.roleCode)
          setCheckedParentDataResourceList(createServiceChildArray(res?.data[0]?.servicesAndResources))
          setCheckedCopyParentDataResourceList(createServiceChildArray(res?.data[0]?.servicesAndResources))
          showSuccess(res?.message)
          setCustomRoleResData([])
        }
      }).catch((error) => {
        setUpdateExistingRoleLoading(false)
        setUpdatedRole('')
        showError(error, false, {}, 'Failed to update user role')
      }).finally(() => {
        setSelectedRoleValue(null)
        setUpdateExistingRoleLoading(false)
      })
  }

  const handleCustomRoleSaveClose = (values) => {
    API.post('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor/${userProps?.sponsor?.sponsorId}/roles`, {
      body: {
        roleCode: values?.roleCode,
        roleName: values?.roleName,
        roleDesc: values?.roleDesc
      }
    })
      .then((res) => {
        if (res && res.success && res?.data) {
          setCreateRoleOpen(false)
          setExistingRoleSelect(false)
          setCustomRoleSelect(false)
          setCustomRoleResData(res?.data[0])
          setCreateApiLoading(false)
        }
      })
      .catch((error) => {
        setCreateApiLoading(false)
        showError(error, false, {}, 'Failed to update sponsor role')
      }).finally(() => setCreateApiLoading(false))
  }

  const handleCheckboxChange = (event, resourceId, resourceServiceId) => {
    setCurrentParentServiceID(resourceId + '-' + resourceServiceId)
    if (event.target.checked) {
      // store current service resource id and all related sub-resource in current resource id key
      setCheckedParentDataResourceList((prevState) => ({
        ...prevState,
        // ex: [resourceId + '-' + resourceServiceId] : 'account-dashboard-account-review'
        [resourceId + '-' + resourceServiceId]: extractChildResourceIds(data, resourceId + '-' + resourceServiceId)?.length === 0 ? [resourceId] : extractChildResourceIds(data, resourceId + '-' + resourceServiceId)
      }))
    } else {
      // remove all resources related to current service and remove current service id form the service id list
      setCheckedParentDataResourceList((prevState) => {
        const tempCheckboxState = {
          ...prevState,
          [resourceId + '-' + resourceServiceId]: []
        }

        // Filter out any keys where the list is empty
        const filteredCheckboxState = {}
        Object.keys(tempCheckboxState).filter(key => tempCheckboxState[key] && tempCheckboxState[key].length > 0).map(key => {
          filteredCheckboxState[key] = tempCheckboxState[key]
        })
        return filteredCheckboxState
      })
    }
  }

  const handleCheckAllCheckboxClick = (event, data) => {
    if (event.target.checked) {
      setCheckedParentDataResourceList(createServiceChildArray(data))
    } else {
      setCheckedParentDataResourceList([])
    }
  }

  const handleToggleAllSwitches = (event, data) => {
    if (event.target.checked) {
      setServiceAccSwitchCheckedList(createPmServiceArray(data))
    } else {
      setServiceAccSwitchCheckedList([])
    }
  }

  const handleChild = (event, resourceId, parentResourceId, parentServiceID, resourceServiceId) => {
    // Set current child service ID by combining resourceId and resourceServiceId
    setCurrentChildServiceID(resourceId + '-' + resourceServiceId)

    // Find index of the resourceId in the checked list for the given parent resource
    // ex: [parentResourceId + '-' + parentServiceID] : 'account-requests-account-review'
    const index = checkedParentDataResourceList[parentResourceId + '-' + parentServiceID]?.indexOf(resourceId)

    // if the checkbox is checked (ex. adding the resource)
    if (event.target.checked) {
      const key = parentResourceId + '-' + parentServiceID
      let updatedParentList = checkedParentDataResourceList[key] || []

      // if only one item exists and parentResourceId is not in parentDataResourceList, remove parentResourceId from the list
      if (updatedParentList.length === 1 && !parentDataResourceList[key]?.includes(parentResourceId)) {
        updatedParentList = updatedParentList.filter(id => id !== parentResourceId)
      }

      // if resourceId is in parentDataResourceList for the given key add it to the updatedParentList
      if (parentDataResourceList && parentDataResourceList[key]?.includes(resourceId)) {
        updatedParentList = [...updatedParentList, resourceId]
      }

      // Update the state by adding resourceId to both the parent and the service lists
      setCheckedParentDataResourceList((prevState) => ({
        ...prevState,
        [key]: updatedParentList
      }))
    }
    // if the checkbox is unchecked (ex. removing the resource)
    else if (!event.target.checked && index > -1) {
      setCheckedParentDataResourceList((prevState) => {
        // Copy the current checked list for the parent resource and service
        const updatedParentResource = [...(prevState[parentResourceId + '-' + parentServiceID] || [])]

        // Remove the unchecked resource from the parent resource list if it exists
        if (index > -1) {
          updatedParentResource.splice(index, 1)
        }

        // Create a temporary state to hold updated checked lists
        const tempCheckboxState = {
          ...prevState,
          [parentResourceId + '-' + parentServiceID]: updatedParentResource
          // [resourceServiceId]: updatedParentServiceId
        }

        // Filter out any keys where the list is empty
        const filteredCheckboxState = {}
        Object.keys(tempCheckboxState)
          .filter(key => tempCheckboxState[key] && tempCheckboxState[key].length > 0)
          .map(key => {
            filteredCheckboxState[key] = tempCheckboxState[key]
          })

        return filteredCheckboxState
      })
    }
  }

  const getCommonServices = () => {
    setIsCommonServiceLoading(true)
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/services-and-resources`)
      .then(response => {
        setData(response?.data)
        setInitialSpnStructureData(response?.data)
      })
      .catch((error) => {
        setIsCommonServiceLoading(false)
        showError(error, false, {}, 'Failed to load common services')
      }).finally(() => {
        setIsCommonServiceLoading(false)
      })
  }

  const checkResourceManageAccess = (service, res) => {
    // return false for PM management, role management and sponsor management screen
    if (selectedRow?.type === 'MANAGE_PM_PERMISSION' || selectedRow?.sponsorName || roleParams?.roleName) { return false }
    // check latest acc switch status for PM account list
    else if (selectedRow?.userName && !selectedRow?.sponsorName && !roleParams?.roleName && selectedRow?.type !== 'MANAGE_PM_PERMISSION') {
      return serviceAccSwitchCheckedList[res?.resourceId + '-' + res?.serviceId]
    }
    // check service acc management array for advisor
    return !roleParams?.roleName && service?.accountMgmtAccess?.includes(res?.resourceId)
  }

  const getCommonSponsorRoleServices = (roleParams) => {
    setIsCommonServiceLoading(true)
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor/${roleParams?.sponsor?.sponsorId}/access`)
      .then((res) => {
        if (res && res?.data) {
          setData(res?.data)
        }
      }).catch((error) => {
        setIsCommonServiceLoading(false)
        showError(error, false, {}, 'Failed to load common sponsor role')
      }).finally(() => {
        setIsCommonServiceLoading(false)
      })
  }

  const getCommonSponsorUserServices = (userProps) => {
    setIsCommonServiceLoading(true)
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor/${userProps?.sponsor?.sponsorId}/access`)
      .then((res) => {
        if (res && res?.data) {
          setData(res?.data)
        }
      }).catch((error) => {
        setIsCommonServiceLoading(false)
        showError(error, false, {}, 'Failed to load common sponsor user')
      }).finally(() => {
        setIsCommonServiceLoading(false)
      })
  }

  const getSponsorAccessServices = (selectedRow) => {
    setIsAccessApiLoading(true)
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor/${selectedRow?.sponsorId}/access`)
      .then((res) => {
        if (res && res?.data) {
          setSponsorData(res.data)
        }
      }).catch((error) => {
        setIsAccessApiLoading(false)
        showError(error, false, {}, 'Failed to load sponsor access')
      }).finally(() => {
        setIsAccessApiLoading(false)
      })
  }

  const getPMAccountManagementAccess = () => {
    setIsPMAccessApiLoading(true)
    API.get('baseAclURL2', `user-access-control/v1/${user?.userGroup}/access`)
      .then((res) => {
        if (res && res?.data) {
          setPMAccessManagementData(res.data)
        }
      }).catch((error) => {
        setIsPMAccessApiLoading(false)
        showError(error, false, {}, 'Failed to load PM user access')
      }).finally(() => {
        setIsPMAccessApiLoading(false)
      })
  }

  // selected row will be passed when fetching ACL for specific PM
  const getPMUserAccessServices = (selectedRow) => {
    setIsAccessApiLoading(true)
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/user/pm/${selectedRow?.userId}/access`)
      .then((res) => {
        if (res && res?.data) {
          setPMUserData(res.data)
        }
      }).catch((error) => {
        setIsAccessApiLoading(false)
        showError(error, false, {}, 'Failed to load PM user access')
      }).finally(() => {
        setIsAccessApiLoading(false)
      })
  }

  const getUserAccessServices = (userProps) => {
    setIsAccessApiLoading(true)
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/user/${userProps?.userId}/access`)
      .then((res) => {
        if (res && res?.data) {
          setUserData(res?.data)
        }
      }).catch((error) => {
        setIsAccessApiLoading(false)
        showError(error, false, {}, 'Failed to load user access')
      }).finally(() => {
        setIsAccessApiLoading(false)
      })
  }

  const getRoleAccessServices = (val) => {
    setIsAccessApiLoading(true)
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor/${val?.sponsor?.sponsorId || val?.sponsorId}/roles/${val?.roleCode}/access`)
      .then((res) => {
        if (res && res?.data) {
          setRoleData(res.data)
        }
      }).catch((error) => {
        setIsAccessApiLoading(false)
        showError(error, false, {}, 'Failed to load role access')
      }).finally(() => {
        setIsAccessApiLoading(false)
      })
  }

  const getPmUpdateAccessRoute = () => {
    if (selectedRow?.type === 'MANAGE_PM_PERMISSION') {
      return API.patch('baseAclURL2', `user-access-control/v1/${user?.userGroup}/access`, { body: { services: saveServiceData(data) || [] } })
    }
    return API.patch('baseAclURL', `user-access-control/v1/${user?.userGroup}/user/pm/${selectedRow?.userId}/access`, { body: { services: saveServiceData(data) || [] } })
  }

  const handlePMUserAccessSave = () => {
    setUpdateApiLoading(true)
    getPmUpdateAccessRoute()
      .then((res) => {
        if (res && res?.data && res?.success) {
          if (selectedRow?.type === 'MANAGE_PM_PERMISSION') {
            setPMAccessManagementData(res.data)
          } else {
            setPMUserData(res?.data)
          }
          showSuccess(res.message)
        }
      }).catch((error) => {
        setUpdateApiLoading(false)
        showError(error, false, {}, 'Failed to save PM user access')
      }).finally(() => {
        setUpdateApiLoading(false)
      })
  }

  const handleSponsorAccessSave = (selectedRow) => {
    setUpdateApiLoading(true)
    API.patch('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor/${selectedRow?.sponsorId}/access`
      , { body: { services: saveServiceData(data) || [] } })
      .then((res) => {
        if (res && res?.data && res?.success) {
          setSponsorData(res?.data)
          showSuccess(res.message)
        }
      }).catch((error) => {
        setUpdateApiLoading(false)
        showError(error, false, {}, 'Failed to save sponsor access')
      }).finally(() => {
        setUpdateApiLoading(false)
      })
  }

  const handleUserAccessSave = (userProps) => {
    setUpdateApiLoading(true)
    API.patch('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor/${userProps?.sponsor?.sponsorId}/user/${userProps?.userId}/access`
      , { body: { services: saveServiceData(data) || [] } })
      .then((res) => {
        if (res && res?.data && res?.success) {
          setUserData(res?.data)
          setUpdatedRole('IND')
          showSuccess(res.message)
        }
      }).catch((error) => {
        setUpdateApiLoading(false)
        showError(error, false, {}, 'Failed to save user access')
      }).finally(() => {
        setUpdateApiLoading(false)
      })
  }

  const handleRoleAccessSave = (event, roleParams) => {
    setUpdateApiLoading(true)
    API.patch('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor/${roleParams?.sponsor?.sponsorId}/roles/${roleParams?.roleCode}/access`
      , { body: { services: saveServiceData(data) || [] } })
      .then((res) => {
        if (res && res?.data && res?.success) {
          setRoleData(res?.data)
          showSuccess(res?.message)
        }
      }).catch((error) => {
        setUpdateApiLoading(false)
        showError(error, false, {}, 'Failed to save role access')
      }).finally(() => {
        setUpdateApiLoading(false)
      })
  }

  const handleServiceClick = (e, resourceId, parentServiceID) => {
    setCurrentServiceId(resourceId)
    setCurrentClickedServiceID(parentServiceID)
    setSelectedService(prevState => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false
        return acc
      }, {}),
      [resourceId]: true
    }))
  }

  const handleAccountCheckboxChange = (event, resourceId, resourceServiceId) => {
    if (event?.target?.checked) {
      setServiceAccSwitchCheckedList((prevState) => ({
        ...prevState,
        [resourceId + '-' + resourceServiceId]: true
      }))
    } else {
      setServiceAccSwitchCheckedList((prevState) => {
        const tempCheckedAccountsObj = {
          ...prevState,
          [resourceId + '-' + resourceServiceId]: false
        }
        const checkedAccountsResult = {}
        Object.keys(tempCheckedAccountsObj).filter(key => tempCheckedAccountsObj[key] === true).map((res) => {
          checkedAccountsResult[res] = tempCheckedAccountsObj[res]
        })
        return checkedAccountsResult
      }
      )
    }
  }

  const handleCancelClose = () => {
    setExistingRoleSelect(false)
    setCustomRoleSelect(false)
    setCreateRoleOpen(false)
    // setOpen(false)
  }

  const ACLLoading = () => {
    return (
      <Grid item xs={6} lg={5} marginTop={3}>
        <Card>
          <CardContent>
            <List>
              {[...Array(8)]?.map((e, i) => (
                <Fragment key={i}>
                  <ListItem>
                    <Skeleton
                      variant='text'
                      sx={{ fontSize: '1rem' }}
                      height={50}
                      width='100%'
                    />
                  </ListItem>
                </Fragment>
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  const isAccountSwitchModified = () => {
    return serviceAccSwitchCheckedCount === serviceCopyAccSwitchCheckedCount && Object.keys(serviceAccSwitchCheckedList).every(key => initialCopyCheckedAccount[key] === serviceAccSwitchCheckedList[key])
  }

  const applyButtonDisabled = () => {
    if (selectedRow?.type === 'MANAGE_PM_PERMISSION') {
      return isAccountSwitchModified()
    } else {
      if (selectedServiceCount?.length === copySelectedServiceCount?.length && isAccountSwitchModified()) {
        return true
      } else {
        return false
      }
    }
  }

  const checkIndeterminate = (resourceList, parentResourceList) => {
    const resourceDataList = resourceList || []
    const parentDataResourceList = parentResourceList || []
    // if service has any resources then check if every resources resourceId present in parent resource list.
    // Note: in indeterminate if all resources are present in parent resource list we will return false (because returning true will show Indeterminate)
    return !parentDataResourceList?.every(element => resourceDataList?.includes(element)) && resourceDataList.length
  }

  const checkAllServicesChecked = () => {
    return (Object.keys(parentDataResourceList).every(key => {
      const parentDataResource = parentDataResourceList[key] || []
      const checkedParentDataResource = checkedParentDataResourceList[key] || []
      return parentDataResource?.length > 0 && checkedParentDataResource?.length > 0 && parentDataResource?.every(value => checkedParentDataResource?.includes(value))
    }))
  }

  const checkAllServicesIndeterminate = () => {
    return (
      Object.keys(checkedParentDataResourceList).length > 0 &&
      Object.keys(parentDataResourceList).some(key => {
        const parentDataResource = parentDataResourceList[key] || []
        const checkedParentDataResource = checkedParentDataResourceList[key] || []

        // Show indeterminate if key is missing in checkedParentDataResourceList
        if (!checkedParentDataResourceList.hasOwnProperty(key)) {
          return true
        }

        return parentDataResource.some(value => !checkedParentDataResource.includes(value))
      })
    )
  }

  const isSwitchDisabled = (resource) => {
    if (selectedRow?.type === 'MANAGE_PM_PERMISSION') {
      return false
    }

    const resourceKey = `${resource?.resourceId}-${resource?.serviceId}`

    return checkedParentDataResourceList[resourceKey]?.length !== parentDataResourceList[resourceKey]?.length &&
    !(Object.keys(checkedParentDataResourceList)?.includes(resourceKey) && checkedParentDataResourceList[resourceKey]?.length > 0)
  }

  const allPmSwitchesIndeterminate = () => {
    return (
      Object.keys(serviceAccSwitchCheckedList).length &&
      !Object.keys(allServiceAccountSwitches).every(
        key => allServiceAccountSwitches[key] === true && serviceAccSwitchCheckedList[key] === true
      )
    )
  }

  const isResourceChecked = (resource) => {
    // if resource id for current service is present in parent data resource list then check current service
    // otherwise check for other services if parent data resource list contains all resources for that service
    // else check for services which does not have resources

    const resourceKey = `${resource?.resourceId}-${resource?.serviceId}`
    const isCurrentService = currentParentServiceID && currentParentServiceID === resourceKey

    if (isCurrentService) {
      return (
        currentCheckedServiceResourceList[resourceKey]?.length ===
        checkedParentDataResourceList[resourceKey]?.length
      )
    }

    if (parentDataResourceList[resourceKey]?.length > 0) {
      return (
        checkedParentDataResourceList[resourceKey]?.length ===
          parentDataResourceList[resourceKey]?.length &&
        checkedParentDataResourceList[resourceKey]?.length > 0
      )
    }

    return (
      checkedParentDataResourceList[resourceKey]?.length > 0 &&
      checkedParentDataResourceList[resourceKey].includes(resource?.resourceId)
    )
  }

  const getIdSelectionModalData = (resource) => {
    return {
      show: true,
      popupTabs: servicePopupTabs[resource?.resourceId] || ['Account'],
      props: {
        ...(userProps || selectedRow),
        resId: `${resource?.resourceId}-${resource?.serviceId}`,
        serviceId: resource?.serviceId,
        resourceId: resource?.resourceId,
        resourceName: resource?.resourceName
      }
    }
  }

  const getSubResourcesIdSelectionModalData = (resource, resources) => {
    return {
      show: true,
      popupTabs: servicePopupTabs[resource?.resourceId] || ['Account'],
      props: {
        ...(userProps || selectedRow),
        resId: `${resources?.resourceId}-${resources?.serviceId}`,
        serviceId: resources?.serviceId,
        resourceId: resources?.resourceId,
        resourceName: resources?.resourceName
      }
    }
  }

  const isParentServiceIndeterminate = () => {
    const key = `${currentServiceId}-${currentClickedServiceID}`
    return (
      !!(
        checkedParentDataResourceList[key]?.length &&
        parentDataResourceList[key]?.length !== checkedParentDataResourceList[key]?.length &&
        parentDataResourceList[key]?.some(item => checkedParentDataResourceList[key]?.includes(item))
      )
    )
  }

  const isParentServiceChecked = () => {
    const key = `${currentServiceId}-${currentClickedServiceID}`
    return (
      checkedParentDataResourceList[key]?.length > 0 &&
      checkedParentDataResourceList[key]?.length === parentDataResourceList[key]?.length &&
      parentDataResourceList[key]?.every(item => checkedParentDataResourceList[key]?.includes(item))
    )
  }

  const isSubResourceSwitchDisabled = (resources) => {
    if (selectedRow?.type === 'MANAGE_PM_PERMISSION') {
      return false
    }

    const resourceKey = `${resources?.resourceId}-${resources?.serviceId}`
    const checkedList = checkedParentDataResourceList[resourceKey] || []
    const parentList = parentDataResourceList[resourceKey] || []

    return (
      checkedList.length !== parentList.length &&
        (!Object.keys(checkedParentDataResourceList).includes(resourceKey) || checkedList.length === 0)
    )
  }

  return (
    <>
      <Box>
        {roleParams?.roleName
          ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  position: 'relative'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    className='rounded-circle' onClick={() => {
                      setModifyRole(false)
                      setActiveTab('role-tab')
                    }} sx={{ width: '22px', height: '22px', marginRight: '15px', backgroundColor: 'transparent' }}
                  >
                    <KeyboardArrowLeft />
                  </IconButton>
                  <Typography
                    sx={{
                      color: '#34475A',
                      fontFamily: 'Lora',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '26px',
                      marginRight: '10px'
                    }}
                  >
                    {capitalizeName(roleParams?.roleName)}
                  </Typography>
                  {/* <Tooltip title='Edit Role' placement='bottom'>
                  <Button
                    onClick={() => {
                      setOpen(true)
                      setFormEditMode('edit')
                      setType('role')
                    }} sx={{ backgroundColor: '#f4f5f7', minWidth: '40px', height: '30px' }}
                  >
                    <EditIcon sx={{ color: 'gray' }} />
                  </Button>
                </Tooltip> */}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {/* <ContentCopyIcon
                    sx={{ cursor: 'pointer' }} onClick={() => {
                      setShowCopyContentPanel({ show: true, props: roleParams })
                    }}
                  /> */}
                  {updateApiLoading
                    ? (
                      <LoadingButton
                        loading
                        loadingPosition='start'
                        variant='contained'
                        type='submit'
                        sx={{
                          marginLeft: '10px',
                          width: '120px',
                          backgroundColor: (selectedServiceCount?.length === copySelectedServiceCount?.length) &&
                          (serviceAccSwitchCheckedCount === serviceCopyAccSwitchCheckedCount)
                            ? '#f4f5f7'
                            : '#3369A6',
                          color: 'white',
                          '&:hover': { backgroundColor: '#3369A6' }
                        }}
                      >
                        Apply
                      </LoadingButton>
                      )
                    : (
                      <Button
                        onClick={(e) => handleRoleAccessSave(e, roleParams)}
                        disabled={applyButtonDisabled()}
                        sx={{
                          marginLeft: '10px',
                          width: '100px',
                          backgroundColor: applyButtonDisabled()
                            ? '#f4f5f7'
                            : '#3369A6',
                          color: 'white',
                          '&:hover': { backgroundColor: '#3369A6' }
                        }}
                      >
                        Apply
                      </Button>
                      )}
                </Box>
              </Box>
            </>
            )
          : (
              userProps && Object?.keys(userProps)?.length > 0
                ? (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        // marginBottom: '10px',
                        alignItems: 'center',
                        position: 'relative'
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          className='rounded-circle'
                          onClick={() => {
                            setModifyUser(false)
                            setActiveTab('user-tab')
                          }}
                          sx={{
                            width: '22px',
                            height: '22px',
                            marginRight: '15px',
                            backgroundColor: 'transparent'
                          }}
                        >
                          <KeyboardArrowLeft />
                        </IconButton>
                        <Typography
                          sx={{
                            color: '#34475A',
                            fontFamily: 'Lora',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '26px',
                            marginRight: '10px'
                          }}
                        >
                          {capitalizeName(userProps?.userName)}
                          <Chip label={updatedRole || userProps?.roleCode} size='small' sx={{ background: '#50CAF1', color: 'white', fontSize: '10px', fontWeight: '500', ml: 1, height: '18px', marginBottom: '1px' }} />
                        </Typography>
                        {/* <Tooltip title='Edit Username' placement='bottom'>
                          <Button
                            onClick={() => {
                              setOpen(true)
                              setFormEditMode('edit')
                              setType('user')
                            }}
                            sx={{ backgroundColor: '#f4f5f7', minWidth: '40px', height: '30px' }}
                          >
                            <EditIcon sx={{ color: 'gray' }} />
                          </Button>
                        </Tooltip> */}
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* <ContentCopyIcon
                          sx={{ marginRight: '15px', cursor: 'pointer' }} onClick={() => {
                            setShowCopyContentPanel({ show: true, props: userProps })
                          }}
                        /> */}
                        {updateApiLoading
                          ? (
                            <LoadingButton
                              loading
                              loadingPosition='start'
                              variant='contained'
                              type='submit'
                              sx={{
                                width: '120px',
                                backgroundColor: (selectedServiceCount?.length === copySelectedServiceCount?.length) &&
                                (serviceAccSwitchCheckedCount === serviceCopyAccSwitchCheckedCount)
                                  ? '#f4f5f7'
                                  : '#3369A6',
                                color: 'white',
                                '&:hover': { backgroundColor: '#3369A6' }
                              }}
                            >
                              Apply
                            </LoadingButton>
                            )
                          : (
                            <Button
                              onClick={() => handleUserAccessSave(userProps)}
                              disabled={applyButtonDisabled()}
                              sx={{
                                width: '100px',
                                backgroundColor: applyButtonDisabled()
                                  ? '#f4f5f7'
                                  : '#3369A6',
                                color: 'white',
                                '&:hover': { backgroundColor: '#3369A6' }
                              }}
                            >
                              Apply
                            </Button>
                            )}
                      </Box>
                    </Box>
                    <Grid sx={{ marginTop: '17px' }}>
                      <FormControl>
                        <RadioGroup row>
                          <ToggleButton sx={{ height: '44px', backgroundColor: existingRoleSelect ? '#8585da17' : '', marginRight: '20px' }}>
                            <FormControlLabel value='Assign Existing Role' onChange={handleRoleSelect} control={<Radio />} label='Assign Existing Role' checked={existingRoleSelect} />
                          </ToggleButton>
                          <ToggleButton sx={{ height: '44px', backgroundColor: customRoleSelect ? '#8585da17' : '', marginRight: '20px' }}>
                            <FormControlLabel value='Give The Custom' onChange={handleRoleSelect} control={<Radio />} label='Give The Custom' checked={customRoleSelect} />
                          </ToggleButton>
                          {existingRoleSelect
                            ? (
                              <Autocomplete
                                disablePortal
                                disableClearable
                                noOptionsText='Roles Not Available'
                                id='roles'
                                sx={{
                                  width: 300,
                                  '& .MuiInputBase-input': {
                                    height: '10px'
                                  }
                                }}
                                options={roleListOptions}
                                value={selectedRoleValue}
                                onChange={(event, value) => {
                                  onChangeHandler(event, value)
                                }}
                                isOptionEqualToValue={(option, value) => {
                                  return option.roleCode === value
                                }}
                                getOptionLabel={(option) => typeof option === 'string' || option instanceof String ? option : option?.roleCode}
                                renderInput={(params) => <TextField sx={{ height: '40px', '& .MuiInputLabel-root': { textAlign: 'center', marginTop: '-5px' } }} {...params} label='Roles' />}
                              />
                              )
                            : (
                                customRoleSelect
                                  ? (
                                    <Dialog open={createRoleOpen} onClose={handleCancelClose} fullWidth maxWidth='sm'>
                                      <CreateInputForm
                                        savedData={handleCustomRoleSaveClose} handleCancelClose={handleCancelClose} type='Role' createApiLoading={createApiLoading} setCreateApiLoading={setCreateApiLoading} formEditMode={formEditMode}
                                      />
                                    </Dialog>
                                    )
                                  : (
                                    <></>
                                    )
                              )}
                        </RadioGroup>
                      </FormControl>
                      {existingRoleSelect && (
                        <SaveIcon
                          onClick={() => updateUserRole()}
                          fontSize='large'
                          sx={{ color: 'gray', cursor: 'pointer', marginTop: '3px', marginLeft: '5px' }}
                        />
                      )}
                    </Grid>
                  </>
                  )
                : (
                    selectedRow?.sponsorName
                      ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            position: 'relative'
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                              className='rounded-circle' onClick={() => {
                                setActiveTab('spn-tab')
                              }} sx={{ width: '22px', height: '22px', marginRight: '15px', backgroundColor: 'transparent' }}
                            >
                              <KeyboardArrowLeft />
                            </IconButton>
                            <Typography
                              sx={{
                                color: '#34475A',
                                fontStyle: 'normal',
                                fontFamily: 'Lora',
                                fontWeight: 400,
                                fontSize: '26px',
                                marginRight: '10px'
                              }}
                            >
                              {capitalizeName(selectedRow?.sponsorName)}
                            </Typography>
                            {/* <Tooltip title='Edit Sponsor' placement='bottom'>
                              <Button
                                onClick={() => {
                                  setOpen(true)
                                  setFormEditMode('edit')
                                  setType('role')
                                }} sx={{ backgroundColor: '#f4f5f7', minWidth: '40px', height: '30px' }}
                              >
                                <EditIcon sx={{ color: 'gray' }} />
                              </Button>
                            </Tooltip> */}
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {/* <ContentCopyIcon
                              sx={{ cursor: 'pointer' }} onClick={() => {
                                setShowCopyContentPanel({ show: true, props: roleRows })
                              }}
                            /> */}
                            {updateApiLoading
                              ? (
                                <LoadingButton
                                  loading
                                  loadingPosition='start'
                                  variant='contained'
                                  type='submit'
                                  sx={{
                                    marginLeft: '10px',
                                    width: '120px',
                                    backgroundColor: selectedServiceCount?.length === copySelectedServiceCount?.length &&
                                    serviceAccSwitchCheckedCount === serviceCopyAccSwitchCheckedCount
                                      ? '#f4f5f7'
                                      : '#3369A6',
                                    color: 'white',
                                    '&:hover': { backgroundColor: '#3369A6' }
                                  }}
                                >
                                  Apply
                                </LoadingButton>
                                )
                              : (
                                <Button
                                  onClick={() => handleSponsorAccessSave(selectedRow)}
                                  disabled={applyButtonDisabled()}
                                  sx={{
                                    marginLeft: '10px',
                                    width: '100px',
                                    backgroundColor: applyButtonDisabled()
                                      ? '#f4f5f7'
                                      : '#3369A6',
                                    color: 'white',
                                    '&:hover': { backgroundColor: '#3369A6' }
                                  }}
                                >
                                  Apply
                                </Button>
                                )}
                          </Box>
                        </Box>
                        )
                      : (
                          selectedRow?.userName || selectedRow?.type === 'MANAGE_PM_PERMISSION'
                            ? (
                              <>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    position: 'relative'
                                  }}
                                >
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton
                                      className='rounded-circle' onClick={() => {
                                        setActiveTab('aris-user-tab')
                                      }} sx={{ width: '22px', height: '22px', marginRight: '15px', backgroundColor: 'transparent' }}
                                    >
                                      <KeyboardArrowLeft />
                                    </IconButton>
                                    {
                                      selectedRow?.userName || selectedRow?.type === 'MANAGE_PM_PERMISSION'
                                        ? <Typography
                                            sx={{
                                              color: '#34475A',
                                              fontFamily: 'Lora',
                                              fontStyle: 'normal',
                                              fontWeight: 400,
                                              fontSize: '26px',
                                              marginRight: '10px'
                                            }}
                                          >
                                          {capitalizeName(selectedRow?.userName) || 'PM Access'}
                                          </Typography>
                                        : ''
                                    }
                                  </Box>
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {updateApiLoading
                                      ? (
                                        <LoadingButton
                                          loading
                                          loadingPosition='start'
                                          variant='contained'
                                          type='submit'
                                          sx={{
                                            marginLeft: '10px',
                                            width: '120px',
                                            backgroundColor: (selectedServiceCount?.length === copySelectedServiceCount?.length) &&
                                        (serviceAccSwitchCheckedCount === serviceCopyAccSwitchCheckedCount)
                                              ? '#f4f5f7'
                                              : '#3369A6',
                                            color: 'white',
                                            '&:hover': { backgroundColor: '#3369A6' }
                                          }}
                                        >
                                          Apply
                                        </LoadingButton>
                                        )
                                      : (
                                        <Button
                                          onClick={(e) => handlePMUserAccessSave(selectedRow)}
                                          disabled={applyButtonDisabled()}
                                          sx={{
                                            marginLeft: '10px',
                                            width: '100px',
                                            backgroundColor: applyButtonDisabled()
                                              ? '#f4f5f7'
                                              : '#3369A6',
                                            color: 'white',
                                            '&:hover': { backgroundColor: '#3369A6' }
                                          }}
                                        >
                                          Apply
                                        </Button>
                                        )}
                                  </Box>
                                </Box>
                              </>
                              )
                            : (
                              <></>
                              )
                        )
                  )
            )}
      </Box>
      <Grid container spacing={2} sx={{ marginTop: '-30px' }}>
        {updateApiLoading ? <Loader /> : ''}
        {isCommonServiceLoading || isAccessApiLoading || updateExistingRoleLoading || isPMAccessApiLoading
          ? (
            <ACLLoading />
            )
          : (
            <>
              <Grid item xs={6} lg={5} marginTop={3}>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <Box>
                    <Typography sx={{
                      color: '#34475A',
                      fontFamily: 'open-sans',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '20px'
                    }}
                    >
                      Resources
                    </Typography>
                  </Box>
                  {
                    selectedRow?.type !== 'MANAGE_PM_PERMISSION'
                      ? <Box display='flex' alignItems='center'>
                        <Typography sx={{ marginLeft: '10px', marginRight: '10px', fontSize: '14px' }}>Select All</Typography>
                        <Checkbox
                          edge='start'
                          indeterminate={checkAllServicesIndeterminate()}
                          checked={checkAllServicesChecked()}
                          onChange={(e) => handleCheckAllCheckboxClick(e, data)}
                        />
                      </Box>
                      : <Box display='flex' alignItems='center'>
                        <Typography sx={{ marginRight: '10px', fontSize: '14px' }}>Enable All</Typography>
                        <Checkbox
                          edge='start'
                          indeterminate={allPmSwitchesIndeterminate()}
                          checked={Object.keys(allServiceAccountSwitches).every(
                            key => allServiceAccountSwitches[key] === true && serviceAccSwitchCheckedList[key] === true
                          )}
                          onChange={(e) => handleToggleAllSwitches(e, data)}
                        />
                        </Box>
                  }
                </Box>
                <Card>
                  <CardContent sx={{
                    overflowY: 'auto',
                    height: userProps && Object.keys(userProps).length
                      ? 'calc(100vh - 100px - 42px - 48px - 47px)'
                      : 'calc(100vh - 39px - 42px - 48px - 48px)'
                  }}
                  >
                    <List sx={{ width: '100%', cursor: 'pointer' }}>
                      {data?.map((service) => (
                        <Box key={service.serviceId}>
                          {service.resources.map((resource) => (
                            <Box
                              key={resource.resourceId}
                              sx={{
                                border: '1px solid #c6c8d0',
                                borderRadius: 1,
                                marginBottom: '5px',
                                paddingLeft: '10px',
                                paddingRight: 0,
                                borderColor: selectedService[resource?.resourceId] && resource?.serviceId === currentClickedServiceID ? '#002A59' : ''
                              }}
                            >
                              <ListItem
                                id={service.serviceId}
                                disablePadding
                                secondaryAction={
                                  <>
                                    {((!roleParams?.roleName && !userProps && !selectedRow?.userName) || selectedRow?.type === 'MANAGE_PM_PERMISSION') && (
                                      <Switch
                                        sx={{ marginRight: selectedRow?.type === 'MANAGE_PM_PERMISSION' ? '0' : '55px' }}
                                        onChange={(e) => handleAccountCheckboxChange(e, resource?.resourceId, resource?.serviceId)}
                                        disabled={isSwitchDisabled(resource)}
                                        checked={serviceAccSwitchCheckedList[resource?.resourceId + '-' + resource?.serviceId] || false}
                                      />
                                    )}
                                    {
                                      selectedRow?.type !== 'MANAGE_PM_PERMISSION'
                                        ? <Checkbox
                                            edge='start'
                                            id={resource.resourceId}
                                            indeterminate={
                                              checkIndeterminate(
                                                checkedParentDataResourceList[resource?.resourceId + '-' + resource?.serviceId],
                                                parentDataResourceList[resource?.resourceId + '-' + resource?.serviceId]
                                              )
                                            }
                                            checked={isResourceChecked(resource)}
                                            onChange={(e) => handleCheckboxChange(e, resource?.resourceId, resource?.serviceId)}
                                          />
                                        : ''
                                    }
                                  </>
                                  }
                              >
                                <ListItemText
                                  sx={{
                                    marginRight: '50px',
                                    marginTop: '5px'
                                  }}
                                  onClick={(e) => handleServiceClick(e, resource?.resourceId, service?.serviceId)}
                                  primary={
                                    <Typography>
                                      {resource?.resourceName}
                                    </Typography>
                                  }
                                />
                                <ListItemIcon>
                                  {checkResourceManageAccess(service, resource) &&
                                    <SettingsOutlinedIcon
                                      sx={{ cursor: 'pointer', minWidth: '45px', marginRight: '50px', color: 'rgba(0, 0, 0, 0.54)' }} onClick={() => {
                                        setIdSelectionModalData(getIdSelectionModalData(resource))
                                      }}

                                    />}
                                </ListItemIcon>
                              </ListItem>
                            </Box>
                          ))}
                        </Box>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} lg={7} marginTop={3}>
                <>
                  <Box display='flex' justifyContent='flex-end' alignItems='center'>
                    {selectedRow?.type !== 'MANAGE_PM_PERMISSION' &&
                      currentServiceId && currentClickedServiceID &&
                       parentDataResourceList[currentServiceId + '-' + currentClickedServiceID]?.length > 0 &&
                          parentDataResourceList[currentServiceId + '-' + currentClickedServiceID][0] !== currentServiceId
                      ? (
                        <>
                          <Typography sx={{ marginLeft: '10px', marginRight: '10px', fontSize: '14px' }}>Select All</Typography>
                          <Checkbox
                            edge='start'
                            indeterminate={isParentServiceIndeterminate()}
                            checked={isParentServiceChecked()}
                            onChange={(e) => handleCheckboxChange(e, currentServiceId, currentClickedServiceID)}
                          />
                        </>
                        )
                      : (
                        <Box sx={{ marginTop: '30px' }} />
                        )}
                  </Box>
                  {data?.map(service => (
                    <Box key={service.serviceId}>
                      {service.resources.map(resource => (
                        <Box key={resource.resourceId}>
                          {currentServiceId === resource.resourceId && resource.serviceId === currentClickedServiceID && parentDataResourceList[resource?.resourceId + '-' + resource?.serviceId]?.length > 0 &&
                          parentDataResourceList[resource?.resourceId + '-' + resource?.serviceId][0] !== resource?.resourceId &&
                          (
                            <Card>
                              <CardContent
                                sx={{
                                  overflowY: 'auto',
                                  height: userProps && Object.keys(userProps).length
                                    ? 'calc(100vh - 100px - 42px - 48px - 47px)'
                                    : 'calc(100vh - 39px - 42px - 48px - 48px)'
                                }}
                              >
                                <Box>
                                  {resource?.subResources.map((resources, index) => (
                                    <Box key={resources.resourceId}>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          position: 'relative',
                                          justifyContent: 'space-between'
                                        }}
                                      >
                                        <Typography sx={{ fontWeight: 'bold' }}>
                                          {resources.resourceName}
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                          {((!roleParams?.roleName && !userProps && !selectedRow?.userName) || selectedRow?.type === 'MANAGE_PM_PERMISSION') &&
                                          (resources.resourceId === 'app1' || resources.resourceId === 'app2') && (
                                            <Switch
                                              sx={{ marginRight: selectedRow?.type === 'MANAGE_PM_PERMISSION' ? '0' : '45px' }}
                                              onChange={(e) => handleAccountCheckboxChange(e, resources?.resourceId, resources?.serviceId)}
                                              disabled={isSubResourceSwitchDisabled(resources)}
                                              checked={serviceAccSwitchCheckedList[resources?.resourceId + '-' + resources?.serviceId] || false}
                                            />
                                          )}
                                          {checkResourceManageAccess(service, resources) && (
                                            <SettingsOutlinedIcon
                                              sx={{ cursor: 'pointer', minWidth: '45px', marginRight: '3px', color: 'rgba(0, 0, 0, 0.54)' }}
                                              onClick={() => {
                                                setIdSelectionModalData(getSubResourcesIdSelectionModalData(resource, resources))
                                              }}
                                            />
                                          )}
                                          {selectedRow?.type !== 'MANAGE_PM_PERMISSION'
                                            ? <Checkbox
                                                key={resources.resourceId}
                                                id={resources.resourceId}
                                                checked={
                                                  checkedParentDataResourceList[resources?.parentResourceId + '-' + resources?.serviceId]?.length > 0 &&
                                                  checkedParentDataResourceList[resources?.parentResourceId + '-' + resources?.serviceId]?.includes(resources.resourceId)
                                                }
                                                disabled={resources.resourceName === 'View Personalization'}
                                                onChange={(event) => handleChild(event, resources.resourceId, resources.parentResourceId, service.serviceId, resources.serviceId)}
                                              />
                                            : ''}
                                        </Box>
                                      </Box>
                                      {index < resource.subResources.length - 1 && <Divider sx={{ borderBottom: '1px solid #c6c8d0' }} />}
                                    </Box>
                                  ))}
                                </Box>
                              </CardContent>
                            </Card>
                          )}
                        </Box>
                      ))}
                    </Box>
                  ))}
                </>
              </Grid>
            </>
            )}
      </Grid>
      {idSelectionModalData?.show && (
        <RoleAndAccountManagePopUp
          idSelectionModalData={idSelectionModalData}
          showRoleModel={showCopyContentPanel}
          closeModal={handleCloseModal}
          allSpnAccounts={allSpnAccounts}
          spnAccountLoading={spnAccountLoading}
          data={data}
          allSpnTradingAccounts={allSpnTradingAccounts}
          allStrategiesList={allStrategiesList}
          strategyListLoading={strategyListLoading}
          allUmaAggregateList={allUmaAggregateList}
          allUmaTradingAccounts={allUmaTradingAccounts}
          umaAggregateLoading={umaAggregateLoading}
          allAggregateGroupsList={allAggregateGroupsList}
          allEntityMapGroupsList={allEntityMapGroupsList}
          aggregateGroupsLoading={aggregateGroupsLoading}
        />
      )}
      {/* <Dialog open={open} onClose={handleCancelClose} fullWidth maxWidth='sm'>
        <CreateInputForm
          type={type} handleCancelClose={handleCancelClose} userProps={userProps} roleParams={roleParams} rows={roleRows} formEditMode={formEditMode}
        />
      </Dialog> */}
    </>
  )
}

export default PermissionTable
