import { Grid } from "@mui/material"
import { Card } from "../components"
import TaxFocusedProgress from "./TaxFocusedProgress"
import { useContext } from "react"
import { SolutionContext } from ".."

const TaxFocusedProgressCards = () => {
  const solutionData = useContext(SolutionContext)
  const loading = solutionData?.isSolutionTaxSavingDataLoading || false
  return (
    <Grid container spacing={1} direction='column'>
      {
        loading
          ? (
            <>
              {
                [1, 2, 3].map((item, index) => (
                  <Grid item key={index} xs={12} md={4}>
                    <Card>
                      <TaxFocusedProgress
                        loading
                      />
                    </Card>
                  </Grid>
                ))
              }
            </>
            )
          : (
            <>
              {
                (solutionData?.solutionTaxSavingsData?.taxCostScenarioData || []).map((scenario, index) => (
                  <Grid item key={index} xs={12} md={4}>
                    <Card>
                      <TaxFocusedProgress
                        scenario={scenario}
                      />
                    </Card>
                  </Grid>
                ))
              }
            </>
            )
      }
    </Grid>
  )
}

export default TaxFocusedProgressCards