import React from 'react'
import { Autocomplete, TextField } from '@mui/material'

const CustomAutocompleteField = (props) => {
  const { label, options = [], optionValue, onChange, onBlur, error, helperText, value, disabled, freeSolo, ...otherProps } = props
  return (
    <Autocomplete
      disablePortal
      options={options}
      freeSolo={freeSolo}
      value={value}
      disabled={disabled}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => {
        return option[optionValue] === (value ? value[optionValue] : null)
      }}
      getOptionLabel={(option) =>
        typeof option === 'string' || option instanceof String
          ? option
          : option?.[optionValue] || ''}
      size='small'
      onBlur={onBlur}
      sx={{ width: '100%', marginTop: '0px' }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
      {...otherProps}
    />
  )
}

export default CustomAutocompleteField
