import { API } from 'aws-amplify'
import { ErrorMessage, FieldArray, Formik } from 'formik'
import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography, CircularProgress } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { FormikAutocomplete } from '../../../components/Formik/FormikAutocomplete'
import { FormikDynamicInput } from '../../../components/Formik/FormikDynamicInput'
import { useAuth } from '../../../contexts/AuthContext'

export const formatModelAllocData = (data) => {
  return data?.map(modelAllocData => ({
    ...modelAllocData,
    id: modelAllocData?.id,
    batchId: modelAllocData?.batchId,
    aggId: modelAllocData?.aggId,
    accountId: modelAllocData?.accountId,
    modelAllocWeight: (parseFloat((modelAllocData?.modelAllocWeight * 100).toFixed(4)))
  }))
}


export const ModelAllocationForm = ({ 
  open, 
  handleCancelClose, 
  handleModelAllocInsert, 
  aggId, 
  accountRows = [], 
  selectedBatchId = null,
  mode = 'create' // 'create' or 'edit'
}) => {
  const isEditMode = mode === 'edit'
  
  // Filter rows by batchId if in edit mode and batchId is provided
  const filteredRows = isEditMode && selectedBatchId 
    ? accountRows.filter(row => row.batchId === selectedBatchId) 
    : []
  
  // Initial values setup based on mode
  const initialValues = {
    rows: isEditMode && filteredRows.length
      ? filteredRows.map((row) => ({
          id: randomId(),
          accountId: row.accountId,
          modelAllocWeight: row?.modelAllocWeight,
          aggId: row.aggId,
          existing: true,
          batchId: row?.batchId,
        }))
      : [{ id: randomId(), accountId: '', modelAllocWeight: '', aggId }]
  }

  const { showError } = useErrorToast()
  const { user } = useAuth()

  return (
    <Dialog open={open} onClose={handleCancelClose} fullWidth maxWidth='md'>
      <DialogTitle sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pb: 0
      }}
      >
        {isEditMode ? 'Manage Accounts' : 'Add Data'}
        <IconButton onClick={handleCancelClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const errors = {}
            const usedIds = new Set()
            values.rows.forEach((row, index) => {
              if (!row.accountId) {
                errors.rows = errors.rows || []
                errors.rows[index] = { ...errors.rows[index], accountId: 'Account ID is required' }
              } else if (usedIds.has(row.accountId)) {
                // Check for unique account validation
                errors.rows = errors.rows || []
                errors.rows[index] = { ...errors.rows[index], accountId: 'Account already used. Cannot add it again' }
              } else {
                usedIds.add(row.accountId)
              }

              // Check for weight validation (now between 1-100)
              if (!row.modelAllocWeight) {
                errors.rows = errors.rows || []
                errors.rows[index] = { ...errors.rows[index], modelAllocWeight: 'Weight is required' }
              } else if (!/^\d+(\.\d{1,4})?$/.test(row.modelAllocWeight)) {
                // Check if modelAllocWeight has more than 2 digits after the decimal point
                errors.rows = errors.rows || []
                errors.rows[index] = { ...errors.rows[index], modelAllocWeight: 'Weight can have up to 4 decimal places' }
              } else if (parseFloat(row.modelAllocWeight) < 1 || parseFloat(row.modelAllocWeight) > 100) {
                errors.rows = errors.rows || []
                errors.rows[index] = { ...errors.rows[index], modelAllocWeight: 'Weight must be between 1 and 100' }
              }
            })
            
            // Check total modelAllocWeight (should sum to 100)
            if (!errors.rows) {
              const totalWeight = values.rows.reduce((sum, row) => sum + parseFloat(row.modelAllocWeight || 0), 0)
              if (Math.abs(totalWeight - 100) > 0.01) { // Allow small rounding errors
                errors.rows = 'Total Weight must be 100'
              }
            }
            return errors
          }}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            // Check total weight (should sum to 100)
            const totalWeight = values.rows.reduce((sum, row) => sum + parseFloat(row.modelAllocWeight || 0), 0)
            if (Math.abs(totalWeight - 100) > 0.01) {
              setFieldError('rows', 'Total Model Allocation Weight must be 100')
            } else {
              // Convert weights from 1-100 range back to 0-1 range for API
              const payload = values.rows?.map((row) => ({
                accountId: row?.accountId,
                modelAllocWeight: row?.modelAllocWeight ? (row.modelAllocWeight / 100).toFixed(6) : 0.000000
              }))
              
              try {
                // Use the aggId from the current row or from props
                const targetAggId = values.rows[0]?.aggId || aggId
                
                await API.patch(
                  'baseUriAggregateMaintenance', 
                  `data-maintenance/v1/${user?.userGroup}/aggregate/${targetAggId}/model-allocation`,
                  { body: [...payload] }
                ).then((res) => {
                  // Format the response data and add IDs
                  const formattedData = formatModelAllocData((res.data).map((data) => ({ ...data, id: randomId() })))
                  handleModelAllocInsert(formattedData)
                  handleCancelClose() // Close dialog after successful submission
                })
              } catch (error) {
                showError(error, false, {}, `Failed to ${isEditMode ? 'update' : 'create'} model allocation.`)
              }
            }
            setSubmitting(false)
          }}
        >
          {props => (
            <form onSubmit={props.handleSubmit} autoComplete='off'>
              <FieldArray name='rows'>
                {({ push, remove }) => (
                  <>
                    {
                      props.values.rows.map((row, index) => {
                        return (
                          <Grid container key={row?.id} spacing={2} sx={{ alignItems: 'flex-start' }}>
                            <Grid item xs={9}>
                              <FormikAutocomplete
                                name={`rows[${index}].accountId`}
                                label='Account'
                                fieldValues={row.accountId}
                                disabled={row.existing || false}
                                onHandleChange={(event) => {
                                  // Update accountId in formik values when account is selected
                                  props.setFieldValue(`rows[${index}].accountId`, event.target.value)
                                }}
                                optionValueKey='accountName'
                                filterFunction={(data) => {
                                  return data?.filter(item => item.strTypeId !== 3 && item.strTypeId !== null)
                                }}
                                optionKey='accountId'
                              />
                              <ErrorMessage name={`rows[${index}].accountId`} component={Typography} sx={{ color: '#f05f5f' }} />
                            </Grid>
                            <Grid item xs={2}>
                              <FormikDynamicInput
                                control='number'
                                name={`rows[${index}].modelAllocWeight`}
                                label='Model Alloc Weight'
                                onHandleChange={props.handleChange}
                                fieldValues={row}
                                fieldKey='modelAllocWeight'
                              />
                              <ErrorMessage name={`rows[${index}].modelAllocWeight`} component={Typography} sx={{ color: '#f05f5f' }} />
                            </Grid>
                            <Grid item xs={1}>
                              {(Object.keys(row).length > 3 || !isEditMode) && (
                                <IconButton onClick={() => remove(index)} sx={{ mt: '20px' }}>
                                  <DeleteOutlineIcon sx={{ color: '#f05f5f' }} />
                                </IconButton>
                              )}
                            </Grid>
                          </Grid>
                        )
                      })
                    }
                    <Button 
                      color='primary' 
                      startIcon={<AddIcon />} 
                      onClick={() => push({ 
                        id: randomId(), 
                        accountId: '', 
                        modelAllocWeight: '', 
                        aggId: props.values.rows[0]?.aggId || aggId 
                      })}
                    >
                      Add record
                    </Button>
                    {
                      typeof props.errors?.rows === 'string' && (
                        <ErrorMessage name='rows' component={Typography} sx={{ color: '#f05f5f' }} />
                      )
                    }
                  </>
                )}
              </FieldArray>
              <Button 
                color='primary' 
                variant='contained' 
                fullWidth 
                type='submit' 
                sx={{ mt: 2 }}
                disabled={isEditMode && !props.dirty || props.isSubmitting}
              >
                {props.isSubmitting ? (
                  <>
                    Submitting
                    <CircularProgress 
                      size={20} 
                      sx={{ ml: 1 }} 
                    />
                  </>
                ) : (
                  'Submit'
                )}
              </Button>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

// For backward compatibility, export these components that use the unified component
export const CreateModelAllocation = (props) => (
  <ModelAllocationForm {...props} mode='create' />
)

export const UpdateModelAllocation = (props) => (
  <ModelAllocationForm {...props} mode='edit' />
)
