import { useContext } from 'react'
import Chart from 'react-apexcharts'
import { Box, Divider, Skeleton, Typography } from '@mui/material'
import { formatCurrencyWithSymbol } from '../../../../utils/FormateCurrenyInMilion'
import { ScenarioContext, SummaryContext } from '..'

const StrategyDistribution = ({ isScenarioScreen = false }) => {
  const summaryData = useContext(SummaryContext)
  const scenarioData = useContext(ScenarioContext)
  const colorArray = ['#4575AC', '#BBD0EA']
  const isLoading = isScenarioScreen ?
    scenarioData?.scenarioPerformanceData[scenarioData?.aggId]?.loading
    : summaryData?.returnOverTimeData?.loading

  const returnOverTimeData = !isLoading && summaryData?.returnOverTimeData?.data && summaryData?.returnOverTimeData?.data?.length ?
    summaryData?.returnOverTimeData?.data?.find((data) => data?.aggId === summaryData?.selectedReturnOverTimeScenario) : []

  const performanceData = scenarioData?.scenarioPerformanceData[scenarioData?.aggId]?.data?.distribution

  const activeDistribution = isScenarioScreen ? performanceData?.activeFund : returnOverTimeData?.activeFund,
    passiveDistribution = isScenarioScreen ? performanceData?.passiveFund : returnOverTimeData?.passiveFund,
    totalDistribution = activeDistribution + passiveDistribution,
    activeDistributionInPercent = (activeDistribution / totalDistribution) * 100,
    passiveDistributionInPercent = (passiveDistribution / totalDistribution) * 100

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    colors: colorArray,
    plotOptions: {
      bar: {
        borderRadiusApplication: 'around',
        borderRadiusWhenStacked: 'all',
        borderRadius: 7,
        border: '1px solid white',
        horizontal: true,
        rangeBarOverlap: true
      }
    },
    stroke: {
      width: 1,
      curve: 'monotoneCubic',
      lineCap: 'round',
      colors: ['#fff']
    },
    legend: {
      show: false
    },
    xaxis: {
      show: false,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false
      }
    },
    yaxis: {
      show: false,
      axisBorder: {
        show: false
      }
    },
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      x: {
        show: false
      },
      y: {
        show: true,
        formatter: (data) => data.toFixed(2) + '%'
      }
    },
    grid: {
      show: false
    }
  }

  const data = [
    {
      name: 'Active',
      data: [activeDistributionInPercent]
    },
    {
      name: 'Passive',
      data: [passiveDistributionInPercent]
    }
  ]

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
        <Typography sx={{ fontWeight: 600, fontSize: '16px', color: '#2f2f2f' }}>Distribution</Typography>
      </Box>
      <Divider sx={{ my: '10px' }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '12px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ fontSize: '12px', color: '#6A86A6' }}>Active</Typography>
          {
            isLoading ? (
              <Skeleton variant='text' sx={{ fontSize: '16px' }} />
            ) : (
              <Typography sx={{ fontSize: '16px', color: '#032347', fontWeight: 600 }}>{formatCurrencyWithSymbol(activeDistribution ?? 0, 2, '$')}</Typography>
            )
          }
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ fontSize: '12px', color: '#6A86A6' }}>Passive</Typography>
          {
            isLoading ? (
              <Skeleton variant='text' sx={{ fontSize: '16px' }} />
            ) : (
              <Typography sx={{ fontSize: '16px', color: '#032347', fontWeight: 600 }}>{formatCurrencyWithSymbol(passiveDistribution ?? 0, 2, '$')}</Typography>
            )
          }
        </Box>
      </Box>
      {
        isLoading ? (
          <Skeleton variant='rectangular' height='60px' />
        ) : (
          <Chart options={options} series={data} type='bar' height={60} />
        )
      }
    </>
  )
}

export default StrategyDistribution
