import React, { useContext } from 'react'
import { Box, Typography, Card, Tooltip, Skeleton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate, useLocation } from 'react-router-dom'
import { formatCurrencyWithSymbol } from '../../../../utils/FormateCurrenyInMilion'
import { SolutionContext } from '..'

// Responsive card design that shrinks proportionally on smaller screens
const ScenarioBox = styled(Box)(({ theme }) => ({
  // Base styles for all screen sizes
  flex: '1 1 0',
  minWidth: '180px', // Minimum width to prevent cards from becoming too small
  maxWidth: '260px', // Maximum width as per Figma on larger screens

  // Responsive adjustments
  [theme.breakpoints.between('sm', 'md')]: {
    maxWidth: '190px', // Smaller on medium screens
  },
  [theme.breakpoints.between('md', 'lg')]: {
    maxWidth: '230px', // Slightly larger on medium-large screens
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '260px', // Full size on large screens
  }
}))

const ContentBox = styled(Box)(({ theme }) => ({
  flex: '1 1 auto',
  minWidth: '100px',

  // Responsive adjustments
  [theme.breakpoints.between('sm', 'md')]: {
    maxWidth: '210px',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    maxWidth: '230px',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '280px',
  },

  '@media (min-width: 1446px)': {
    maxWidth: '320px', // Larger size for wide screens
  },

  '@media (min-width: 1620px)': {
    maxWidth: '350px', // Larger size for wide screens
  }
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: 11,
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: 11,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: 12,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 14,
    textAlign: 'center'
  }
}))

const TruncatedHeader = ({ text, maxLength = 20 }) => {
  const needsTruncation = text.length > maxLength
  const displayText = needsTruncation ? `${text.slice(0, maxLength)}...` : text

  return (
    <Tooltip
      title={needsTruncation ? text : ''}
      arrow
      placement='top'
      enterDelay={300}
      disableHoverListener={!needsTruncation}
    >
      <StyledTypography
        sx={{
          fontWeight: 700,
          maxWidth: '100%',
          padding: '0 4px',
          mb: 2,
          color: '#2F2F2F',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '&:hover': {
            cursor: 'pointer'
          }
        }}
      >
        {displayText}
      </StyledTypography>
    </Tooltip>
  )
}

// Skeleton component for loading state
const ScenarioCardSkeleton = () => {
  // Define metrics to display (same as in main component)
  const metricCount = 7; // Number of metrics

  const commonCardStyles = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
    borderRadius: '8px'
  }

  const metricBoxStyles = {
    py: 1.5,
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }

  return (
    <ContentBox>
      <Card
        elevation={0}
        sx={{
          ...commonCardStyles,
          bgcolor: '#E6ECF3',
          border: '1px solid #80A8D6',
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '40px',
            bgcolor: '#fff',
            borderBottomLeftRadius: '7px',
            borderBottomRightRadius: '7px',
            borderTop: '1px solid #80A8D6'
          }
        }}
      >
        <Box sx={{ p: { sm: 1, md: 1, lg: 1.5 } }}>
          <Box sx={{ mb: 2, textAlign: 'center' }}>
            <Skeleton variant="text" width="80%" height={28} sx={{ mx: 'auto' }} />
          </Box>

          {Array(metricCount).fill(0).map((_, index) => (
            <Box key={index} sx={metricBoxStyles}>
              <Skeleton variant="text" width="60%" height={24} sx={{ mx: 'auto' }} />
            </Box>
          ))}

          <Box
            sx={{
              mt: { sm: 1, md: 1, lg: 2 },
              pt: { sm: 1.5, md: 1.5, lg: 3 },
              position: 'relative',
              zIndex: 1,
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Skeleton variant="text" width="40%" height={24} sx={{ mx: 'auto' }} />
          </Box>
        </Box>
      </Card>
    </ContentBox>
  );
};

const ScenarioCards = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const solutionData = useContext(SolutionContext)
  const loading = solutionData?.isScenarioCardLoading || false
  const scenarioData = solutionData?.scenarioCardData || []

  // Maximum number of allocation cards to display (excluding the metrics card)
  const MAX_ALLOCATIONS = 3

  // Define metrics to display with corresponding values from API
  const metricMappings = [
    { name: 'Tracking Error', key: 'propTe', format: value => `${(value * 100).toFixed(2)}%` },
    { name: 'Short Term URGL', key: 'propStUrgl', format: value => `${formatCurrencyWithSymbol(value, 2, '$')}` },
    { name: 'Long Term URGL', key: 'propLtUrgl', format: value => `${formatCurrencyWithSymbol(value, 2, '$')}` },
    { name: 'Short Term RGL', key: 'propShortRgl', format: value => `${formatCurrencyWithSymbol(value, 2, '$')}` },
    { name: 'Long Term RGL', key: 'propLongRgl', format: value => `${formatCurrencyWithSymbol(value, 2, '$')}` },
    { name: 'No. of Accounts', key: 'accounts', format: value => value.toString() },
    { name: 'Tax Cost', key: 'taxCost', format: value => `${formatCurrencyWithSymbol(value, 2, '$')}` }
  ]

  const commonCardStyles = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
    borderRadius: '8px'
  }

  const metricBoxStyles = {
    py: 1.5,
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }

  const handleViewDetails = (scenario) => {
    navigate(`/aggregate/uma-dashboard/${scenario.aggId}`, {
      state: {
        previousRoute: location.pathname
      }
    })
    window.scrollTo(0, 0)
  }

  // Create skeleton placeholders when loading
  const renderSkeletons = () => {
    return Array(MAX_ALLOCATIONS).fill(0).map((_, index) => (
      <ScenarioCardSkeleton key={`skeleton-${index}`} />
    ));
  };

  return (
    <Box sx={{
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      gap: { xs: 1, sm: 1.5, md: 2, lg: 2 },
      width: '100%',
      mt: 2,
      pl: { xs: 0.5, sm: 1, md: 1, lg: 1 },
      overflow: 'hidden'
    }}
    >
      {/* Metric names column - always shown */}
      <ScenarioBox>
        <Card elevation={0} sx={{ ...commonCardStyles, bgcolor: '#F8F9FE' }}>
          <Box sx={{ p: { xs: 0.5, sm: 1, md: 1, lg: 1.5 } }}>
            <TruncatedHeader text='Scenario Name' />
            {metricMappings.map((metric, index) => (
              <Box
                key={metric.name}
                sx={{
                  ...metricBoxStyles,
                  width: '100%',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <StyledTypography sx={{ color: '#6B7280', position: 'relative', display: 'inline-block' }}>
                  <span style={{ borderBottom: index < metricMappings.length - 1 ? '1px solid #E6ECF3' : 'none', paddingBottom: '10px' }}>
                    {metric.name}
                  </span>
                </StyledTypography>
              </Box>
            ))}
          </Box>
        </Card>
      </ScenarioBox>

      {/* Show skeletons while loading */}
      {loading && renderSkeletons()}

      {/* Show actual data when loaded */}
      {!loading && scenarioData.map((scenario) => (
        <ContentBox key={scenario.aggId}>
          <Card
            elevation={0}
            sx={{
              ...commonCardStyles,
              bgcolor: '#E6ECF3',
              border: '1px solid #80A8D6',
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '40px',
                bgcolor: '#fff',
                borderBottomLeftRadius: '7px',
                borderBottomRightRadius: '7px',
                borderTop: '1px solid #80A8D6'
              }
            }}
          >
            <Box sx={{ p: { xs: 0.5, sm: 1, md: 1, lg: 1.5 } }}>
              <TruncatedHeader text={scenario.aggName} />
              {metricMappings.map((metric) => (
                <Box key={metric.name} sx={metricBoxStyles}>
                  <StyledTypography>
                    {metric.format(scenario[metric.key])}
                  </StyledTypography>
                </Box>
              ))}
              <Box
                sx={{
                  mt: { xs: 0.5, sm: 1, md: 1, lg: 2 },
                  pt: { xs: 1, sm: 1.5, md: 1.5, lg: 3 },
                  position: 'relative',
                  zIndex: 1,
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                onClick={() => handleViewDetails(scenario)}
              >
                <StyledTypography
                  sx={{
                    color: '#002A59',
                    cursor: 'pointer',
                    fontWeight: 700,
                    '&:hover': { textDecoration: 'underline' }
                  }}
                >
                  View Details
                </StyledTypography>
              </Box>
            </Box>
          </Card>
        </ContentBox>
      ))}
    </Box>
  )
}

export default ScenarioCards
