import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material'
import { AiOutlineTrademarkCircle } from 'react-icons/ai'
import redirect from '../../../assets/images/pop-out-line.svg'
import { formatCurrency } from '../../Report/components/dataProcess/DataProcess'

const CustomHeader = ({ title, text }) => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Tooltip title={title} placement='bottom'>
          <span style={{ lineHeight: '30px' }}>{title}</span>
        </Tooltip>
        <span style={{ lineHeight: '16px', minHeight: '20px', fontSize: '12px' }}>{text}</span>
      </Box>
    </>
  )
}

const detailColumns = [
  { field: '', headerName: '', width: 50, sortable: false, disableColumnMenu: true, flex: 1 },
  { field: 'instrId', headerName: 'Instrument ID', flex: 1 },
  {
    field: 'trdCode',
    headerName: 'Trade Code',
    flex: 1,
    renderCell: (params) => (<>
      <span style={{ color: params.value === 'SELL' ? '#FF6161' : params.value === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.value}</span>
                             </>)
  },
  { field: 'trdQty', headerName: 'Trade Quantity', type: 'number', align: 'right', headerAlign: 'right', flex: 1 },
  { field: 'trdDate', headerName: 'Trade Date', flex: 1, valueFormatter: (params) => params?.value ? params?.value?.split('T')[0] : '' },
  {
    field: 'initMarketValue',
    flex: 1,
    type: 'number',
    headerName: 'Initial Market Value',
    align: 'right',
    headerAlign: 'right',
    renderCell: (params) => !isNaN(params?.row?.initMarketValue)
      ? params?.row?.initMarketValue < 0
        ? `-$${formatCurrency(params?.row?.initMarketValue, 2)}`
        : `$${formatCurrency(params?.row?.initMarketValue, 2)}`
      : 'N/A'
  },
  { field: 'initShares', headerName: 'Initial Shares', type: 'number', align: 'right', headerAlign: 'right', flex: 1 },
  {
    field: 'propMarketValue',
    headerName: 'Proposed Market Value',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    renderCell: (params) => !isNaN(params?.row?.propMarketValue)
      ? params?.row?.propMarketValue < 0
        ? `-$${formatCurrency(params?.row?.propMarketValue, 2)}`
        : `$${formatCurrency(params?.row?.propMarketValue, 2)}`
      : 'N/A'
  },
  {
    field: 'lastClosePrice',
    headerName: 'Last Close Price',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    renderCell: (params) => !isNaN(params?.row?.lastClosePrice)
      ? params?.row?.lastClosePrice < 0
        ? `-$${formatCurrency(params?.row?.lastClosePrice, 2)}`
        : `$${formatCurrency(params?.row?.lastClosePrice, 2)}`
      : 'N/A'
  },
  {
    field: 'totalMarketValue',
    headerName: 'Total Market Value',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    renderCell: (params) => !isNaN(params?.row?.totalMarketValue)
      ? params?.row?.totalMarketValue < 0
        ? `-$${formatCurrency(params?.row?.totalMarketValue, 2)}`
        : `$${formatCurrency(params?.row?.totalMarketValue, 2)}`
      : 'N/A'
  },
  {
    field: 'orgPurchasePrice',
    headerName: 'Org Purchase Price',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    renderCell: (params) => !isNaN(params?.row?.orgPurchasePrice)
      ? params?.row?.orgPurchasePrice < 0
        ? `-$${formatCurrency(params?.row?.orgPurchasePrice, 2)}`
        : `$${formatCurrency(params?.row?.orgPurchasePrice, 2)}`
      : 'N/A'
  },
  {
    field: 'rgl',
    headerName: 'Realized Gain/Loss',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    renderCell: (params) => !isNaN(params?.row?.rgl)
      ? params?.row?.rgl < 0
        ? `-$${formatCurrency(params?.row?.rgl, 2)}`
        : `$${formatCurrency(params?.row?.rgl, 2)}`
      : 'N/A'
  },
  {
    field: 'initUrgl',
    headerName: 'Initial Unrealized Gain/Loss',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    renderCell: (params) => !isNaN(params?.row?.initUrgl)
      ? params?.row?.initUrgl < 0
        ? `-$${formatCurrency(params?.row?.initUrgl, 2)}`
        : `$${formatCurrency(params?.row?.initUrgl, 2)}`
      : 'N/A'
  },
  { field: 'costBasis', headerName: 'Cost Basis', align: 'right', headerAlign: 'right', flex: 1 },
  { field: 'localSymbol', headerName: 'Local Symbol', flex: 1 }
]

const modelChangesHeaders = [
  { field: 'instrId', headerName: 'Instrument Id', flex: 1 },
  {
    field: 'tradeCode',
    headerName: 'Trade Code',
    flex: 1,
    renderCell: (params) => (<>
      <span style={{ color: params.value === 'SELL' ? '#FF6161' : params.value === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.value}</span>
                             </>
    )
  },
  {
    field: 'previousSecurityWeight',
    headerName: 'Previous Weight (%)',
    headerAlign: 'right',
    align: 'right',
    type: 'number',
    renderCell: (props) => props?.row?.previousSecurityWeight !== undefined && props?.row?.previousSecurityWeight !== null
      ? ((props?.row?.previousSecurityWeight) * 100).toFixed(2)
      : '0.00',
    valueGetter: (props) => props?.row?.previousSecurityWeight !== undefined && props?.row?.previousSecurityWeight !== null
      ? Number(((props?.row?.previousSecurityWeight) * 100).toFixed(2))
      : 0.00,
    flex: 1
  },
  {
    field: 'currentSecurityWeight',
    headerName: 'Current Weight (%)',
    headerAlign: 'right',
    align: 'right',
    type: 'number',
    renderCell: (props) => props?.row?.currentSecurityWeight !== undefined && props?.row?.currentSecurityWeight !== null
      ? ((props?.row?.currentSecurityWeight) * 100).toFixed(2)
      : '0.00',
    valueGetter: (props) => props?.row?.currentSecurityWeight !== undefined && props?.row?.currentSecurityWeight !== null
      ? Number(((props?.row?.currentSecurityWeight) * 100).toFixed(2))
      : 0.00,
    flex: 1
  },
  {
    field: 'drift',
    headerName: 'Weight Change(%)',
    align: 'right',
    headerAlign: 'right',
    type: 'number',
    sortComparator: (a, b) => Math.abs(a) - Math.abs(b),
    flex: 1,
    // renderHeader: () => <CustomHeader title='Weight Change(%)' text='WC = UPW - CPW' />,
    renderCell: (params) => {
      const driftVal = params?.row?.drift
      const invalid = Math.abs(parseFloat(driftVal * 100).toFixed(2)) === 0
      return (
        <>
          {driftVal < 0 && !invalid
            ? <ArrowCircleDownIcon fontSize='small' sx={{ color: '#FF6161', marginRight: '2px' }} />
            : driftVal > 0 && !invalid
              ? <ArrowCircleUpIcon fontSize='small' sx={{ color: '#3BBFA3', marginRight: '2px' }} />
              : ''}
          <Typography
            fontSize='14px'
            fontWeight='600'
            color={parseFloat(driftVal * 100).toFixed(2) < 0 && !invalid
              ? '#FF6161'
              : (parseFloat(driftVal * 100).toFixed(2) > 0 && !invalid)
                  ? '#3BBFA3'
                  : ''}
            sx={{ width: '35px', textAlign: 'right', display: 'inline-block' }}
          >
            {Math.abs(parseFloat(driftVal * 100).toFixed(2)) === 0 ? '0.00' : parseFloat(driftVal * 100).toFixed(2)}
          </Typography>
        </>
      )
    },
    valueGetter: (props) => props?.row?.drift !== undefined && props?.row?.drift !== null
      ? Math.abs(parseFloat(props?.row?.drift * 100).toFixed(2)) === 0 ? 0.00 : parseFloat(parseFloat(props?.row?.drift * 100).toFixed(2))
      : 0.00
  }
]

const restrictionColumns = [
  {
    field: 'restrictionDesc',
    headerName: 'Restriction Type',
    flex: 1
  },
  {
    field: 'source',
    headerName: 'Source',
    flex: 1
  },
  {
    field: 'sourceName',
    headerName: 'Source Name',
    flex: 1
  }
]

const getTradeBlotterHeaders = (isDILink) => ([
  {
    field: 'instrId',
    headerName: 'Instrument Id',
    renderHeader: (params) => <CustomHeader title='Instrument Id' />,
    flex: 1
  },
  {
    field: 'localSymbol',
    headerName: 'Local Symbol',
    renderHeader: (params) => <CustomHeader title='Local Symbol' />,
    flex: 0.7
  },
  {
    field: 'side',
    headerName: 'Trade Code',
    renderHeader: (params) => <CustomHeader title='Trade Code' text='TC' />,
    renderCell: (params) => (<>
      <span style={{ color: params.formattedValue === 'SELL' ? '#FF6161' : params.formattedValue === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.formattedValue}</span>
                             </>),
    flex: 0.7
  },
  {
    field: 'lastClosePrice',
    headerName: 'Last Close Price',
    type: 'number',
    valueGetter: (params) => params?.value?.toFixed(2),
    renderCell: (params) => !isNaN(params?.row?.lastClosePrice)
      ? params?.row?.lastClosePrice < 0
        ? `-$${formatCurrency(Math.abs(params?.row?.lastClosePrice, 2))}`
        : `$${formatCurrency(params?.row?.lastClosePrice, 2)}`
      : 'N/A',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    renderHeader: (params) => <CustomHeader title='Last Close Price' text='LCP' />
  },
  {
    field: 'livePrice',
    headerName: 'Live Price',
    type: 'number',
    renderHeader: (params) => <CustomHeader title='Live Price' text='LP' />,
    renderCell: (params) => {
      const isLivePrice = params?.row?.isLivePrice === 1

      const cellStyle = {
        color: !isLivePrice ? 'rgba(0, 0, 0, 0.4)' : 'inherit',
        textAlign: 'right'
      }

      return (
        <div style={cellStyle}>
          {params?.row?.livePrice !== null
            ? params?.row?.livePrice < 0
              ? `-$${formatCurrency(Math.abs(params?.row?.livePrice, 2))}`
              : `$${formatCurrency(params?.row?.livePrice, 2)}`
            : 'N/A'}
        </div>
      )
    },
    flex: 0.6,
    align: 'right',
    headerAlign: 'right'
  },
  {
    field: 'marketValue',
    headerName: 'Trade Value',
    type: 'number',
    renderHeader: (params) => <CustomHeader title='Trade Value' text='TV' />,
    renderCell: (params) => !isNaN(params?.row?.marketValue)
      ? params?.row?.marketValue < 0
        ? `-$${formatCurrency(Math.abs(params?.row?.marketValue, 2))}`
        : `$${formatCurrency(params?.row?.marketValue, 2)}`
      : 'N/A',
    flex: 0.8,
    align: 'right',
    headerAlign: 'right'
  },
  {
    field: 'liveMarketValue',
    headerName: 'Live Market Value',
    type: 'number',
    renderHeader: (params) => <CustomHeader title='Live Market Value' text='LMV' />,
    renderCell: (params) => {
      const isLivePrice = params?.row?.isLivePrice === 1

      const cellStyle = {
        color: !isLivePrice ? 'rgba(0, 0, 0, 0.4)' : 'inherit',
        textAlign: 'right'
      }

      return (
        <div style={cellStyle}>
          {params?.row?.liveMarketValue !== null
            ? params?.row?.liveMarketValue < 0
              ? `-$${formatCurrency(Math.abs(params?.row?.liveMarketValue, 2))}`
              : `$${formatCurrency(params?.row?.liveMarketValue, 2)}`
            : 'N/A'}
        </div>
      )
    },
    flex: 1,
    align: 'right',
    headerAlign: 'right'
  },
  {
    field: 'propMarketValue',
    header: 'Proposed MV',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    renderHeader: (params) => <CustomHeader title='Proposed MV' text='PMV' />,
    valueGetter: (params) => params?.row?.propMarketValue !== undefined && params?.row?.propMarketValue !== null ? params?.row?.propMarketValue?.toFixed(2) : null,
    renderCell: (params) => params?.row?.propMarketValue !== undefined && params?.row?.propMarketValue !== null
      ? params?.row?.propMarketValue < 0
        ? `-$${formatCurrency(Math.abs(params?.row?.propMarketValue, 2))}`
        : `$${formatCurrency(params?.row?.propMarketValue, 2)}`
      : ''
  },
  {
    field: 'priceChange',
    type: 'number',
    renderCell: (params) => {
      const isPriceChangeNull = params?.row?.priceChange === null
      const isLivePrice = params?.row?.isLivePrice === 1

      const cellStyle = {
        color: !isLivePrice ? 'rgba(0, 0, 0, 0.4)' : 'inherit',
        textAlign: 'right'
      }
      return (
        <div style={cellStyle}>
          {!isPriceChangeNull
            ? `${(params?.row?.priceChange * 100).toFixed(2)}`
            : 'N/A'}
        </div>
      )
    },
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    headerName: 'Price Change (%)',
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Price Change (%)' text='PC = (LP - LCP)/LP' />
  },
  {
    field: 'marketValueChange',
    type: 'number',
    renderCell: (params) => {
      const isMarketValueChangeNull = params?.row?.marketValueChange === null
      const isLivePrice = params?.row?.isLivePrice === 1

      const cellStyle = {
        color: !isLivePrice ? 'rgba(0, 0, 0, 0.4)' : 'inherit',
        textAlign: 'right'
      }
      return (
        <div style={cellStyle}>
          {!isMarketValueChangeNull
            ? `${Number(params?.row?.marketValueChange).toFixed(2)}`
            : 'N/A'}
        </div>
      )
    },
    flex: 1.4,
    align: 'right',
    headerAlign: 'right',
    headerName: 'Trade Value Change',
    renderHeader: (params) => <CustomHeader title='Trade Value Change' text='TVC = TC *(LMV - TV)' />
  },
  {
    field: 'quantity',
    headerName: 'Quantities',
    type: 'number',
    renderHeader: (params) => <CustomHeader title='Quantities' />,
    flex: 0.8,
    align: 'right',
    headerAlign: 'right'
  },
  ...(isDILink
    ? [
        {
          field: 'transferInShares',
          headerName: 'Transfer In Quantity',
          type: 'number',
          align: 'right',
          headerAlign: 'right',
          flex: 1,
          valueGetter: (params) => params.value,
          renderCell: (params) => params?.row?.transferInShares !== null || params?.row?.transferInShares !== undefined ? params?.row?.transferInShares : '',
          renderHeader: (params) => <CustomHeader title='Transfer In Quantity' text='T.I.Q' />
        },
        {
          field: 'transferOutShares',
          headerName: 'Transfer Out Quantity',
          type: 'number',
          align: 'right',
          headerAlign: 'right',
          flex: 1,
          valueGetter: (params) => params.value,
          renderCell: (params) => params?.row?.transferOutShares !== null || params?.row?.transferOutShares !== undefined ? params?.row?.transferOutShares : '',
          renderHeader: (params) => <CustomHeader title='Transfer Out Quantity' text='T.O.Q' />
        }
      ]
    : []),
  {
    field: 'splitEffectiveDate',
    headerName: 'Split Effective Date',
    renderHeader: (params) => <CustomHeader title='Split Effective Date' />,
    flex: 1
  },
  {
    field: 'splitRatio',
    headerName: 'Split Ratio',
    renderHeader: (params) => <CustomHeader title='Split Ratio' />,
    flex: 0.6
  },
  {
    field: 'delistEffectiveDate',
    headerName: 'Delist Effective Date',
    renderHeader: (params) => <CustomHeader title='Delist Effective Date' />,
    flex: 1.1
  }
])

const getPositionSecurityTableHeader = (redirectToPersonalization, isDILink) => ([
  {
    field: 'instr_id',
    headerName: 'Instrument Id',
    flex: 1,
    renderHeader: (params) => <CustomHeader title='Instrument Id' />,
    renderCell: (params) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {params?.row?.instr_id}
        {
          params?.row?.instrRestr
            ? (
              <>
                <Chip label={params?.row?.instrRestr} size='small' sx={{ background: '#50CAF1', color: 'white', fontSize: '10px', fontWeight: '500', ml: 1, height: '16px' }} />
                <IconButton
                  onClick={redirectToPersonalization}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <img src={redirect} height={18} width={18} alt='redirect' />
                </IconButton>
              </>
              )
            : ''
        }
      </Box>
    )
  },
  {
    field: 'trdCode',
    headerName: 'Trade Code',
    flex: 1,
    renderHeader: (params) => <CustomHeader title='Trade Code' />,
    renderCell: (params) => (<>
      <span style={{ color: params.value === 'SELL' ? '#FF6161' : params.value === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.value}</span>
                             </>)
  },
  {
    field: 'modelWeight',
    headerName: 'Model Weight (%)',
    type: 'number',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderCell: (params) => (params?.row?.modelWeight * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Model Weight (%)' text='M.W' />
  },
  {
    field: 'priceByMktVal',
    headerName: 'Price By Market Value (%)',
    type: 'number',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Price By Market Value (%)' />,
    renderCell: (params) => (params?.row?.priceByMktVal * 100).toFixed(2)
  },
  {
    field: 'initWeight',
    headerName: 'Initial Weight (%)',
    type: 'number',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderCell: (params) => (params?.row?.initWeight * 100).toFixed(2),
    renderHeader: (params) => {
      return <CustomHeader title='Initial Weight (%)' text='I.W' />
    }
  },
  {
    field: 'propWeight',
    headerName: 'Proposed Weight (%)',
    type: 'number',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderCell: (params) => parseFloat(params?.row?.propWeight * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Proposed Weight (%)' text='P.W' />
  },
  {
    field: 'trdWeight',
    type: 'number',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueGetter: (params) => params?.row?.trdWeight !== undefined && params?.row?.trdWeight !== null ? parseFloat((params.row?.trdWeight * 100).toFixed(2)) : null,
    renderHeader: (params) => <CustomHeader title='Trade Weight (%)' text='T.W = P.W - I.W' />,
    renderCell: (params) => (
      <>
        {
          params?.value < 0
            ? <ArrowCircleDownIcon fontSize='small' sx={{ color: '#FF6161' }} />
            : params?.value > 0
              ? <ArrowCircleUpIcon fontSize='small' sx={{ color: '#3BBFA3' }} />
              : ''
        }
        {
          params?.value !== null
            ? <Typography fontSize='14px' fontWeight='600' color={params?.value < 0 ? '#FF6161' : (params?.value > 0) ? '#3BBFA3' : '#34475A'}>{params?.value?.toFixed(2)}</Typography>
            : ''
        }
      </>
    )
  },
  ...(isDILink
    ? [
        {
          field: 'transferInWeight',
          headerName: 'Transfer In Weight (%)',
          type: 'number',
          align: 'right',
          headerAlign: 'right',
          flex: 1,
          valueGetter: (params) => params.value !== null || params.value !== undefined ? (params.value * 100).toFixed(2) : null,
          renderCell: (params) => params?.row?.transferInWeight !== null || params?.row?.transferInWeight !== undefined ? (params?.row?.transferInWeight * 100).toFixed(2) : '',
          renderHeader: (params) => <CustomHeader title='Transfer In Weight (%)' text='T.I.W' />
        },
        {
          field: 'transferOutWeight',
          headerName: 'Transfer Out Weight (%)',
          type: 'number',
          align: 'right',
          headerAlign: 'right',
          flex: 1,
          valueGetter: (params) => params.value !== null || params.value !== undefined ? (params.value * 100).toFixed(2) : null,
          renderCell: (params) => params?.row?.transferOutWeight !== null || params?.row?.transferOutWeight !== undefined ? (params?.row?.transferOutWeight * 100).toFixed(2) : '',
          renderHeader: (params) => <CustomHeader title='Transfer Out Weight (%)' text='T.O.W' />
        }
      ]
    : []),
  {
    field: 'propDrift',
    headerName: 'Proposed Drift (%)',
    type: 'number',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Proposed Drift (%)' text='P.D = M.W - P.W' />,
    renderCell: (params) => parseFloat(params.row.propDrift * 100).toFixed(2)
  },
  {
    field: 'initDrift',
    headerName: 'Initial Drift (%)',
    type: 'number',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderCell: (params) => (params?.row?.initDrift * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Initial Drift (%)' text='I.D = M.W - I.W' />
  },
  {
    field: 'tradeDrift',
    headerName: 'Trade Drift (%)',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    valueGetter: (params) => parseFloat((Math.abs(params?.row?.propDrift) - Math.abs(params?.row?.initDrift)) * 100)?.toFixed(2),
    renderHeader: (params) => <CustomHeader title='Trade Drift (%)' text='T.D = abs(P.D) - abs(I.D)' />,
    renderCell: (params) => {
      const absDiff = parseFloat(((Math.abs(params?.row?.propDrift) - Math.abs(params?.row?.initDrift)) * 100)?.toFixed(2))
      return (<>{absDiff < 0 ? <ArrowCircleDownIcon fontSize='small' sx={{ color: '#3BBFA3' }} /> : (absDiff > 0) ? <ArrowCircleUpIcon fontSize='small' sx={{ color: '#FF6161' }} /> : ''}<Typography fontSize='14px' fontWeight='600' color={absDiff < 0 ? '#3BBFA3' : (absDiff > 0) ? '#FF6161' : '#34475A'}>{absDiff?.toFixed(2)}</Typography></>)
    }
  }
])

const getCompanyColumns = (redirectToPersonalization, isDILink) => ([
  {
    field: 'companyName',
    headerName: 'Company',
    flex: 1,
    valueGetter: (params) => {
      const hierarchy = params?.row?.hierarchy
      return hierarchy ? hierarchy[hierarchy.length - 1] : null
    },
    renderCell: (params) => {
      return (
        <>
          {params?.value}
          {
          params?.row?.doesInstrRestrExists
            ? (
              <Box sx={{ height: '100%', paddingTop: '9px' }}>
                <AiOutlineTrademarkCircle color='#1976d2' style={{ marginLeft: '4px', height: '12px', width: '12px' }} />
              </Box>
              )
            : ''
        }
          {
          params?.row?.instrRestr
            ? (
              <>
                <Chip label={params?.row?.instrRestr} size='small' sx={{ background: '#50CAF1', color: 'white', fontSize: '10px', fontWeight: '500', ml: 1, height: '16px' }} />
                <IconButton
                  onClick={redirectToPersonalization}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <img src={redirect} height={18} width={18} alt='redirect' />
                </IconButton>
              </>
              )
            : ''
        }
        </>
      )
    }
  },
  {
    field: 'modelWeight',
    headerName: 'Model Weight (%)',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Model Weight (%)' text='' />,
    renderCell: (params) => parseFloat(params?.row?.modelWeight * 100).toFixed(2)
  },
  {
    field: 'initWeight',
    headerName: 'Initial Weight (%)',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderCell: (params) => parseFloat(params?.row?.initWeight * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Initial Weight (%)' text='I.W' />
  },
  {
    field: 'propWeight',
    headerName: 'Proposed Weight (%)',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderCell: (params) => (params?.row?.propWeight * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Proposed Weight (%)' text='P.W' />
  },
  {
    field: 'tradeWeight',
    headerName: 'Trade Weight (%)',
    type: 'number',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueGetter: (params) => {
      const propWeight = params?.row?.propWeight || null
      const initWeight = params?.row?.initWeight || null
      return parseFloat(((propWeight - initWeight) * 100)?.toFixed(2)) ?? null
    },
    renderHeader: (params) => <CustomHeader title='Trade Weight (%)' text='T.W = P.W - I.W' />,
    renderCell: (params) => (
      <>
        {
          params?.value < 0
            ? <ArrowCircleDownIcon fontSize='small' sx={{ color: '#FF6161' }} />
            : params?.value > 0
              ? <ArrowCircleUpIcon fontSize='small' sx={{ color: '#3BBFA3' }} />
              : ''
        }
        {
          params?.value !== null
            ? <Typography fontSize='14px' fontWeight='600' color={params?.value < 0 ? '#FF6161' : (params?.value > 0) ? '#3BBFA3' : '#34475A'}>{params?.value?.toFixed(2)}</Typography>
            : ''
        }
      </>
    )
  },
  ...(isDILink
    ? [
        {
          field: 'transferInWeight',
          headerName: 'Transfer In Weight (%)',
          type: 'number',
          align: 'right',
          headerAlign: 'right',
          flex: 1,
          valueGetter: (params) => params.value !== null || params.value !== undefined ? (params.value * 100).toFixed(2) : null,
          renderCell: (params) => params?.row?.transferInWeight !== null || params?.row?.transferInWeight !== undefined ? (params?.row?.transferInWeight * 100).toFixed(2) : '',
          renderHeader: (params) => <CustomHeader title='Transfer In Weight (%)' text='T.I.W' />
        },
        {
          field: 'transferOutWeight',
          headerName: 'Transfer Out Weight (%)',
          type: 'number',
          align: 'right',
          headerAlign: 'right',
          flex: 1,
          valueGetter: (params) => params.value !== null || params.value !== undefined ? (params.value * 100).toFixed(2) : null,
          renderCell: (params) => params?.row?.transferOutWeight !== null || params?.row?.transferOutWeight !== undefined ? (params?.row?.transferOutWeight * 100).toFixed(2) : '',
          renderHeader: (params) => <CustomHeader title='Transfer Out Weight (%)' text='T.O.W' />
        }
      ]
    : []),
  {
    field: 'propDrift',
    headerName: 'Proposed Drift (%)',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderCell: (params) => (params?.row?.propDrift * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Proposed Drift (%)' text='P.D = M.W - P.W' />
  },
  {
    field: 'initDrift',
    headerName: 'Initial Drift (%)',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderCell: (params) => parseFloat(params?.row?.initDrift * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Initial Drift (%)' text='I.D = M.W - I.W' />
  },
  {
    field: 'tradeDrift',
    headerName: 'Trade Drift (%)',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    valueGetter: (params) => parseFloat((Math.abs(params?.row?.propDrift) - Math.abs(params?.row?.initDrift)) * 100)?.toFixed(2),
    renderHeader: (params) => <CustomHeader title='Trade Drift (%)' text='T.D = abs(P.D) - abs(I.D)' />,
    renderCell: (params) => {
      const absDiff = parseFloat(((Math.abs(params?.row?.propDrift) - Math.abs(params?.row?.initDrift)) * 100)?.toFixed(2))
      return (<>{absDiff < 0 ? <ArrowCircleDownIcon fontSize='small' sx={{ color: '#3BBFA3' }} /> : (absDiff > 0) ? <ArrowCircleUpIcon fontSize='small' sx={{ color: '#FF6161' }} /> : ''}<Typography fontSize='14px' fontWeight='600' color={absDiff < 0 ? '#3BBFA3' : (absDiff > 0) ? '#FF6161' : '#34475A'}>{absDiff?.toFixed(2)}</Typography></>)
    }
  }
])

// security level trade table
const getTradeTableHeaders = (redirectToPersonalization, isDILink, canAccessModelChangeWithTrade) => ([
  {
    field: 'instr_id',
    headerName: 'Instrument Id',
    flex: 1,
    renderCell: (params) => (
      <>
        {params?.row?.instr_id}
        {
          params?.row?.instrRestr
            ? (
              <>
                <Chip label={params?.row?.instrRestr} size='small' sx={{ background: '#50CAF1', color: 'white', fontSize: '10px', fontWeight: '500', ml: 1, height: '16px' }} />
                <IconButton
                  onClick={redirectToPersonalization}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <img src={redirect} height={18} width={18} alt='redirect' />
                </IconButton>
              </>
              )
            : ''
        }
      </>
    )
  },
  ...(canAccessModelChangeWithTrade
    ? [{
        field: 'localSymbol',
        headerName: 'Local Symbol',
        flex: 1
      }]
    : []),
  {
    field: 'trdCode',
    headerName: 'Trade Code',
    renderCell: (params) => (<>
      <span style={{ color: params.value === 'SELL' ? '#FF6161' : params.value === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.value}</span>
                             </>),
    flex: 1
  },
  ...(canAccessModelChangeWithTrade
    ? [{
        field: 'modelDrift',
        headerName: 'Model Trade',
        type: 'number',
        flex: 1,
        valueGetter: (params) => params?.row?.modelDrift !== undefined && params?.row?.modelDrift !== null
          ? parseFloat((params?.row?.modelDrift * 100).toFixed(2))
          : null,
        renderCell: (params) => params?.value !== null
          ? <>
            {
            params?.value < 0
              ? <ArrowCircleDownIcon sx={{ color: '#FF6161', height: '16px', width: '16px' }} />
              : params?.value > 0
                ? <ArrowCircleUpIcon sx={{ color: '#3BBFA3', height: '16px', width: '16px' }} />
                : ''
          }
            {
            params?.value !== null
              ? <>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: params?.value < 0 ? '#FF6161' : (params?.value > 0) ? '#3BBFA3' : '#34475A',
                    ml: '3px'
                  }}
                >

                  {params?.value?.toFixed(2)}
                </Typography>
                {
                  params?.row?.modelWeight !== undefined && params?.row?.modelWeight !== null
                    ? <span style={{ marginLeft: '3px' }}>({parseFloat((params?.row?.modelWeight * 100)).toFixed(2)})</span>
                    : ''
                }
                </>
              : ''
          }
          </>
          : ''
      },
      {
        field: 'tradeWeight',
        headerName: 'Account Trade',
        type: 'number',
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params) => {
          const propWeight = params?.row?.propWeight || null
          const initWeight = params?.row?.initWeight || null
          return parseFloat(((propWeight - initWeight) * 100)?.toFixed(2)) ?? null
        },
        renderCell: (params) => (
          <>
            {
            params?.value < 0
              ? <ArrowCircleDownIcon sx={{ color: '#FF6161', height: '16px', width: '16px' }} />
              : params?.value > 0
                ? <ArrowCircleUpIcon sx={{ color: '#3BBFA3', height: '16px', width: '16px' }} />
                : ''
          }
            {
            params?.value !== null
              ? <>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: params?.value < 0 ? '#FF6161' : (params?.value > 0) ? '#3BBFA3' : '#34475A',
                    ml: '3px'
                  }}
                >
                  {params?.value?.toFixed(2)}
                </Typography>
                {
                  params?.row?.propWeight !== undefined && params?.row?.propWeight !== null
                    ? <span style={{ marginLeft: '3px' }}>({parseFloat((params?.row?.propWeight * 100)).toFixed(2)})</span>
                    : ''
                }
                </>
              : ''
          }
          </>
        )
      }]
    : []),
  ...(isDILink
    ? [
        {
          field: 'transferInWeight',
          headerName: 'Transfer In Weight (%)',
          type: 'number',
          align: 'right',
          headerAlign: 'right',
          flex: 1,
          valueGetter: (params) => params.value !== null || params.value !== undefined ? (params.value * 100).toFixed(2) : null,
          renderCell: (params) => params?.row?.transferInWeight !== null || params?.row?.transferInWeight !== undefined ? (params?.row?.transferInWeight * 100).toFixed(2) : ''
        },
        {
          field: 'transferOutWeight',
          headerName: 'Transfer Out Weight (%)',
          type: 'number',
          align: 'right',
          headerAlign: 'right',
          flex: 1,
          valueGetter: (params) => params.value !== null || params.value !== undefined ? (params.value * 100).toFixed(2) : null,
          renderCell: (params) => params?.row?.transferOutWeight !== null || params?.row?.transferOutWeight !== undefined ? (params?.row?.transferOutWeight * 100).toFixed(2) : ''
        }
      ]
    : []),
  { field: 'initShares', headerName: 'Initial Shares', type: 'number', valueFormatter: (params) => params?.value?.toFixed(2), align: 'right', headerAlign: 'right', flex: 1 },
  { field: 'propShares', headerName: 'Proposed Shares', type: 'number', valueFormatter: (params) => params?.value?.toFixed(2), align: 'right', headerAlign: 'right', flex: 1 },
  ...(canAccessModelChangeWithTrade
    ? [{
        field: 'priceByMktVal',
        headerName: 'Price By Market Value',
        headerAlign: 'right',
        type: 'number',
        flex: 1,
        valueGetter: (params) => (params.value * 100).toFixed(2),
        renderCell: (params) => (params?.row?.priceByMktVal * 100).toFixed(2)
      },
      {
        field: 'livePriceRgl',
        headerName: 'Live Price RGL',
        headerAlign: 'right',
        type: 'number',
        flex: 1,
        valueGetter: (params) => params?.row?.livePriceRgl !== undefined && params?.row?.livePriceRgl !== null
          ? params?.row?.trdCode !== 'BUY' ? Number((params?.row?.livePriceRgl).toFixed(2)) : null
          : null,
        renderCell: (params) => params?.value !== null
          ? params?.row?.livePriceRgl < 0
            ? params?.row?.livePriceRgl?.toFixed(2) === '-0.00'
              ? '$0.00'
              : '-$' + formatCurrency(Math.abs(params?.row?.livePriceRgl), 2)
            : '$' + formatCurrency(params?.row?.livePriceRgl, 2)
          : ''
      },
      {
        field: 'tradeValue',
        headerName: 'Trade Value',
        headerAlign: 'right',
        type: 'number',
        flex: 1,
        valueGetter: (params) => params?.row?.tradeValue !== undefined && params?.row?.tradeValue !== null
          ? Number((params?.row?.tradeValue).toFixed(2))
          : null,
        renderCell: (params) => params?.value !== null
          ? params?.row?.tradeValue < 0
            ? params?.row?.tradeValue?.toFixed(2) === '-0.00'
              ? '$0.00'
              : '-$' + formatCurrency(Math.abs(params?.row?.tradeValue), 2)
            : '$' + formatCurrency(params?.row?.tradeValue, 2)
          : ''
      }]
    : []),
  ...(isDILink
    ? [
        {
          field: 'transferInShares',
          headerName: 'Transfer In Quantity',
          type: 'number',
          align: 'right',
          headerAlign: 'right',
          flex: 1,
          valueGetter: (params) => params.value,
          renderCell: (params) => params?.row?.transferInShares !== null || params?.row?.transferInShares !== undefined ? params?.row?.transferInShares : ''
        },
        {
          field: 'transferOutShares',
          headerName: 'Transfer Out Quantity',
          type: 'number',
          align: 'right',
          headerAlign: 'right',
          flex: 1,
          valueGetter: (params) => params.value,
          renderCell: (params) => params?.row?.transferOutShares !== null || params?.row?.transferOutShares !== undefined ? params?.row?.transferOutShares : ''
        }
      ]
    : []),
  {
    field: 'trdQty',
    headerAlign: 'right',
    headerName: 'Trade Quantity',
    type: 'number',
    valueGetter: (params) => params?.value !== undefined && params?.value !== null
      ? Number(params?.value?.toFixed(2))
      : null,
    renderCell: (params) => params?.value !== null ? params?.row?.trdQty?.toFixed(2) : '',
    align: 'right',
    flex: 1
  }
])

const getTaxlotTableHeaders = (isDILink) => ([
  { field: 'instrId', headerName: 'Instrument Id' },
  { field: 'localSymbol', headerName: 'Local Symbol' },
  {
    field: 'tradeTags',
    headerName: 'Trade Reason',
    width: 300,
    valueGetter: (params) => {
      const tradeTags = params?.value
      if (Array.isArray(tradeTags) && tradeTags.length > 0) {
        return tradeTags?.map(tag => tag.reasonCd).join(', ')
      }
      return 'N/A'
    },
    renderCell: (params) => {
      const tradeTags = params?.row?.tradeTags
      if (tradeTags && tradeTags?.length > 0) {
        const reasonCodes = tradeTags?.map(tag => tag.reasonCd)?.join(', ')
        const reasonLabels = tradeTags?.map(tag => tag.reasonLabel)?.join(', ')

        return (
          <Tooltip title={reasonLabels}>
            <span>{reasonCodes}</span>
          </Tooltip>
        )
      }
      return <span>N/A</span>
    }
  },
  {
    field: 'trdCode',
    headerName: 'Trade Code',
    renderCell: (params) => (<>
      <span style={{ color: params.value === 'SELL' ? '#FF6161' : params.value === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.value}</span>
                             </>)
  },
  { field: 'trdQty', headerName: 'Trade Quantity', type: 'number', valueFormatter: (params) => params?.value?.toFixed(2), align: 'right' },
  ...(isDILink
    ? [
        {
          field: 'transferCode',
          headerName: 'Transfer Code',
          renderCell: (params) => (<>
            {params?.row?.transferCode === 'RECEIVED'
              ? <ArrowCircleUpIcon fontSize='small' sx={{ color: '#3BBFA3' }} />
              : params?.row?.transferCode === 'SENT'
                ? <ArrowCircleDownIcon fontSize='small' sx={{ color: '#FF6161' }} />
                : ''}
            <span style={{ color: params.value === 'SENT' ? '#FF6161' : params.value === 'RECEIVED' ? '#3BBFA3' : '#34475A' }}>{params.value}</span>
          </>)
        },
        {
          field: 'transferInWeight',
          headerName: 'Transfer In Weight (%)',
          type: 'number',
          align: 'right',
          headerAlign: 'right',
          flex: 1,
          valueGetter: (params) => params.value !== null || params.value !== undefined ? (params.value * 100).toFixed(2) : null,
          renderCell: (params) => params?.row?.transferInWeight !== null || params?.row?.transferInWeight !== undefined ? (params?.row?.transferInWeight * 100).toFixed(2) : ''
        },
        {
          field: 'transferOutWeight',
          headerName: 'Transfer Out Weight (%)',
          type: 'number',
          align: 'right',
          headerAlign: 'right',
          flex: 1,
          valueGetter: (params) => params.value !== null || params.value !== undefined ? (params.value * 100).toFixed(2) : null,
          renderCell: (params) => params?.row?.transferOutWeight !== null || params?.row?.transferOutWeight !== undefined ? (params?.row?.transferOutWeight * 100).toFixed(2) : ''
        },
        { field: 'srcAccount', headerName: 'Source Account' },
        { field: 'dstAccount', headerName: 'Destination Account' }
      ]
    : []),
  {
    field: 'trdValue',
    headerName: 'Trade Value',
    type: 'number',
    align: 'right',
    valueGetter: (params) => params?.row?.trdValue !== undefined && params?.row?.trdValue !== null ? parseFloat(params?.row?.trdValue?.toFixed(2)) : null,
    renderCell: (params) => params?.row?.trdValue !== null && params?.row?.trdValue !== undefined
      ? parseFloat(params?.row?.trdValue?.toFixed(2)) < 0
        ? `-$${formatCurrency(Math.abs(params?.row?.trdValue, 2))}`
        : `$${formatCurrency(params?.row?.trdValue, 2)}`
      : ''
  },
  { field: 'initShares', headerName: 'Initial Shares', type: 'number', align: 'right', headerAlign: 'right' },
  { field: 'initWeight', headerName: 'Initial Weight', type: 'number', align: 'right', headerAlign: 'right' },
  {
    field: 'initMarketValue',
    headerName: 'Initial Market Value',
    type: 'number',
    valueGetter: (params) => params?.value?.toFixed(2),
    renderCell: (params) => !isNaN(params?.row?.initMarketValue)
      ? params?.row?.initMarketValue < 0
        ? `-$${formatCurrency(Math.abs(params?.row?.initMarketValue), 2)}`
        : `$${formatCurrency(params?.row?.initMarketValue, 2)}`
      : 'N/A',
    align: 'right'
  },
  { field: 'propShares', headerName: 'Proposed Shares', type: 'number', align: 'right', headerAlign: 'right' },
  { field: 'propWeight', headerName: 'Proposed Weight', type: 'number', align: 'right', headerAlign: 'right' },
  {
    field: 'propMarketValue',
    headerName: 'Proposed Market Value',
    type: 'number',
    valueGetter: (params) => params?.value?.toFixed(2),
    renderCell: (params) => !isNaN(params?.row?.propMarketValue)
      ? params?.row?.propMarketValue < 0
        ? `-$${formatCurrency(Math.abs(params?.row?.propMarketValue), 2)}`
        : `$${formatCurrency(params?.row?.propMarketValue, 2)}`
      : 'N/A',
    align: 'right',
    headerAlign: 'right'
  },
  { field: 'age', headerName: 'Age', align: 'right', headerAlign: 'right', type: 'number' },
  { field: 'costBasis', headerName: 'Cost Basis', type: 'number', align: 'right', headerAlign: 'right' },
  {
    field: 'purchaseDate',
    headerName: 'Purchase Date',
    type: 'date',
    valueGetter: (params) => {
      if (!params?.value) return params?.value
      const date = new Date(params?.value)
      return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
    },
    renderCell: (params) => params?.row?.purchaseDate ? params?.row?.purchaseDate?.split('T')[0] : ''
  },
  { field: 'orgPurchasePrice', headerName: 'Original Purchase Price', type: 'number', valueFormatter: (params) => params?.value?.toFixed(2), align: 'right', headerAlign: 'right' },
  {
    field: 'lastClosePrice',
    headerName: 'Last Closed Price',
    type: 'number',
    valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
    renderCell: (params) => {
      const cellStyle = {
        color: params?.row?.priceSource === 'last close price' ? 'rgba(0, 0, 0, 0.4)' : 'inherit',
        textAlign: 'right'
      }

      return (
        <div style={cellStyle}>
          {!isNaN(params?.row?.lastClosePrice) && params?.row?.lastClosePrice !== undefined && params?.row?.lastClosePrice !== null
            ? params?.row?.lastClosePrice < 0
              ? `-$${formatCurrency(Math.abs(params?.row?.lastClosePrice, 2))}`
              : `$${formatCurrency(params?.row?.lastClosePrice, 2)}`
            : 'N/A'}
        </div>
      )
    },
    align: 'right',
    headerAlign: 'right'
  },
  {
    field: 'initUrgl',
    headerName: 'Initial Unrealized gain-loss',
    type: 'number',
    valueGetter: (params) => params?.value?.toFixed(2),
    renderCell: (params) =>
      !isNaN(params?.row?.initUrgl)
        ? params?.row?.initUrgl < 0
          ? `-$${formatCurrency(Math.abs(params?.row?.initUrgl), 2)}`
          : `$${formatCurrency(params?.row?.initUrgl, 2)}`
        : 'N/A',
    align: 'right',
    headerAlign: 'right'
  },
  {
    field: 'liveMarketPrice',
    headerName: 'Live Price',
    type: 'number',
    valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
    renderCell: (params) =>
      !isNaN(params?.row?.liveMarketPrice) && params?.row?.liveMarketPrice !== undefined && params?.row?.liveMarketPrice !== null
        ? params?.row?.liveMarketPrice < 0
          ? `-$${formatCurrency(Math.abs(params?.row?.liveMarketPrice, 2))}`
          : `$${formatCurrency(params?.row?.liveMarketPrice, 2)}`
        : 'N/A',
    align: 'right',
    headerAlign: 'right'
  },
  {
    field: 'rgl',
    headerName: 'Realized gain-loss',
    type: 'number',
    valueGetter: (params) => params?.value?.toFixed(2),
    renderCell: (params) => !isNaN(params?.row?.rgl)
      ? params?.row?.rgl < 0
        ? `-$${formatCurrency(Math.abs(params?.row?.rgl), 2)}`
        : `$${formatCurrency(params?.row?.rgl, 2)}`
      : 'N/A',
    align: 'right',
    headerAlign: 'right'
  },
  {
    field: 'rglLive',
    headerName: 'RGL (Live)',
    type: 'number',
    valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
    renderCell: (params) => {
      const cellStyle = {
        color: params?.row?.priceSource === 'last close price' ? 'rgba(0, 0, 0, 0.4)' : 'inherit',
        textAlign: 'right'
      }

      return (
        <div style={cellStyle}>
          {
            !isNaN(params?.row?.rglLive) && params?.row?.rglLive !== undefined && params?.row?.rglLive !== null
              ? params?.row?.rglLive < 0
                ? `-$${formatCurrency(Math.abs(params?.row?.rglLive), 2)}`
                : `$${formatCurrency(params?.row?.rglLive, 2)}`
              : 'N/A'
          }
        </div>
      )
    },
    align: 'right',
    headerAlign: 'right'
  },
  { field: 'trdDate', headerName: 'Trade Date', valueFormatter: (params) => params?.value ? params?.value?.split('T')[0] : '' }
])

const getPositionGroupTableHeader = (isDILink) => ([
  {
    field: 'modelWeight',
    headerName: 'Model Weight (%)',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Model Weight (%)' text='' />,
    renderCell: (params) => parseFloat(params?.row?.modelWeight * 100).toFixed(2)
  },
  {
    field: 'initWeight',
    headerName: 'Initial Weight (%)',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderCell: (params) => parseFloat(params?.row?.initWeight * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Initial Weight (%)' text='I.W' />
  },
  {
    field: 'propWeight',
    headerName: 'Proposed Weight (%)',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderCell: (params) => (params?.row?.propWeight * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Proposed Weight (%)' text='P.W' />
  },
  ...(isDILink
    ? [
        {
          field: 'transferInWeight',
          headerName: 'Transfer In Weight (%)',
          type: 'number',
          align: 'right',
          headerAlign: 'right',
          flex: 1,
          valueGetter: (params) => params.value !== null || params.value !== undefined ? (params.value * 100).toFixed(2) : null,
          renderCell: (params) => params?.row?.transferInWeight !== null || params?.row?.transferInWeight !== undefined ? (params?.row?.transferInWeight * 100).toFixed(2) : '',
          renderHeader: (params) => <CustomHeader title='Transfer In Weight (%)' text='T.I.W' />
        },
        {
          field: 'transferOutWeight',
          headerName: 'Transfer Out Weight (%)',
          type: 'number',
          align: 'right',
          headerAlign: 'right',
          flex: 1,
          valueGetter: (params) => params.value !== null || params.value !== undefined ? (params.value * 100).toFixed(2) : null,
          renderCell: (params) => params?.row?.transferOutWeight !== null || params?.row?.transferOutWeight !== undefined ? (params?.row?.transferOutWeight * 100).toFixed(2) : '',
          renderHeader: (params) => <CustomHeader title='Transfer Out Weight (%)' text='T.O.W' />
        }
      ]
    : []),
  {
    field: 'propDrift',
    headerName: 'Proposed Drift (%)',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderCell: (params) => (params?.row?.propDrift * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Proposed Drift (%)' text='P.D = M.W - P.W' />
  },
  {
    field: 'initDrift',
    headerName: 'Initial Drift (%)',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderCell: (params) => parseFloat(params?.row?.initDrift * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Initial Drift (%)' text='I.D = M.W - I.W' />
  },
  {
    field: 'tradeDrift',
    headerName: 'Trade Drift (%)',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    valueGetter: (params) => parseFloat((Math.abs(params?.row?.propDrift) - Math.abs(params?.row?.initDrift)) * 100)?.toFixed(2),
    renderHeader: (params) => <CustomHeader title='Trade Drift (%)' text='T.D = abs(P.D) - abs(I.D)' />,
    renderCell: (params) => {
      const absDiff = parseFloat(((Math.abs(params?.row?.propDrift) - Math.abs(params?.row?.initDrift)) * 100)?.toFixed(2))
      return (<>{absDiff < 0 ? <ArrowCircleDownIcon fontSize='small' sx={{ color: '#3BBFA3' }} /> : (absDiff > 0) ? <ArrowCircleUpIcon fontSize='small' sx={{ color: '#FF6161' }} /> : ''}<Typography fontSize='14px' fontWeight='600' color={absDiff < 0 ? '#3BBFA3' : (absDiff > 0) ? '#FF6161' : '#34475A'}>{absDiff?.toFixed(2)}</Typography></>)
    }
  }
])

const getTransferValidationHeaders = () => {
  const activeColumns = [
    {
      field: 'activeAccountTransferCode', headerName: 'Transfer Code', flex: 1,
      renderCell: (params) => (
        <>
          {params?.value === 'SENT' && (
            <ArrowCircleDownIcon fontSize="small" sx={{ color: '#FF6161', mr: 0.5 }} />
          )}
          {params?.value === 'RECEIVED' && (
            <ArrowCircleUpIcon fontSize="small" sx={{ color: '#3BBFA3', mr: 0.5 }} />
          )}
          {params?.value && (
            <Typography fontSize="14px" fontWeight="600">{params?.value}</Typography>
          )}
        </>
      )
    },
    { field: 'activeAccountSymbol', headerName: 'Symbol', flex: 1 },
    {
      field: 'activeAccountPurchaseDate',
      headerName: 'Purchase Date',
      flex: 1,
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.activeAccountPurchaseDate ? params?.row?.activeAccountPurchaseDate?.split('T')[0] : ''
    },
    {
      field: 'activeAccountPurchasePrice',
      headerName: 'Purchase Price',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        const value = params?.row?.activeAccountPurchasePrice
        return value == null || isNaN(value) ? null : parseFloat(value)
      },
      renderCell: (params) => !isNaN(params?.row?.activeAccountPurchasePrice) && params?.row?.activeAccountPurchasePrice !== null
        ? params?.row?.activeAccountPurchasePrice < 0
          ? `-$${Math.abs(params?.row?.activeAccountPurchasePrice)}`
          : `$${params?.row?.activeAccountPurchasePrice}`
        : ''
    },
    {
      field: 'activeAccountTransferOutQty',
      headerName: 'Transfer Out Qty',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        return params?.row?.activeAccountTransferOutQty !== null && params?.row?.activeAccountTransferOutQty !== undefined ? params?.row?.activeAccountTransferOutQty : null
      },
      valueFormatter: (params) => {
        return params?.value != null ? params?.value?.toString() : ''
      }
    }
  ]

  const passiveColumns = [
    {
      field: 'passiveAccountTransferCode',
      headerName: 'Transfer Code',
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.value === 'SENT' && (
            <ArrowCircleDownIcon fontSize="small" sx={{ color: '#FF6161', mr: 0.5 }} />
          )}
          {params?.value === 'RECEIVED' && (
            <ArrowCircleUpIcon fontSize="small" sx={{ color: '#3BBFA3', mr: 0.5 }} />
          )}
          {params?.value && (
            <Typography fontSize="14px" fontWeight="600">{params?.value}</Typography>
          )}
        </>
      )
    },
    { field: 'passiveAccountSymbol', headerName: 'Symbol', flex: 1 },
    {
      field: 'passiveAccountPurchaseDate',
      headerName: 'Purchase Date',
      flex: 1,
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.passiveAccountPurchaseDate ? params?.row?.passiveAccountPurchaseDate?.split('T')[0] : ''
    },
    {
      field: 'passiveAccountPurchasePrice',
      headerName: 'Purchase Price',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        const value = params?.row?.passiveAccountPurchasePrice
        return value == null || isNaN(value) ? null : parseFloat(value)
      },
      renderCell: (params) => !isNaN(params?.row?.passiveAccountPurchasePrice) && params?.row?.passiveAccountPurchasePrice !== null
        ? params?.row?.passiveAccountPurchasePrice < 0
          ? `-$${Math.abs(params?.row?.passiveAccountPurchasePrice)}`
          : `$${params?.row?.passiveAccountPurchasePrice}`
        : ''
    },
    {
      field: 'passiveAccountTransferOutQty',
      headerName: 'Transfer Out Qty',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        return params?.row?.passiveAccountTransferOutQty !== null && params?.row?.passiveAccountTransferOutQty !== undefined ? params?.row?.passiveAccountTransferOutQty : null
      },
      valueFormatter: (params) => {
        return params?.value != null ? params?.value?.toString() : ''
      }
    }
  ]

  const commonColumns = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <Box style={{ color: params.value === 'MATCHED' ? '#3BBFA3' : '#FF6161' }}>{params.value}</Box>
      )
    }
  ]

  return { activeColumns, passiveColumns, commonColumns }
}

export {
  CustomHeader,
  detailColumns, getCompanyColumns, getPositionGroupTableHeader, getPositionSecurityTableHeader, getTaxlotTableHeaders, getTradeBlotterHeaders, getTradeTableHeaders, modelChangesHeaders,
  restrictionColumns, getTransferValidationHeaders
}
