import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { TabContext } from '@mui/lab'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Divider } from '@mui/material'
import Tab from '@mui/material/Tab'
import { randomId } from '@mui/x-data-grid-generator'
import { useAuth } from '../../../contexts/AuthContext'
import { useAccountService } from '../../../hooks/useAccountService'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { instrumentDataFormatter, sectorDataFormatter, industryDataFormatter, factoryDataFormatter, substitutionDataFormatter } from '../components/TradeDataFormatting'
import { tableData } from '../components/PersonalizationHeaders'
import TradePersonalizationTab from '../components/TradePersonalizationTab'
import TradeOptProperties from '../components/TradeOptProperties'

const ViewOptProperties = ({ index, optId, selectedScenarioId, allScenarioList, showCompact = false }) => {
  const [approvalPropertiesLoading, setApprovalPropertiesLoading] = useState(true)
  const [otherPropertiesLoading, setOtherPropertiesLoading] = useState(true)
  const [isPropertyCodesLoading, setIsPropertyCodesLoading] = useState(true)
  const [approvalProperties, setApprovalProperties] = useState()
  const [propertiesList, setPropertiesList] = useState([])
  const { user } = useAuth()
  const [optData, setOptData] = useState([])
  const { showError } = useErrorToast()
  const [value, setValue] = useState('0')
  const screen = 'not main'
  const { getGeneralAccountListUtil } = useAccountService()
  const [accountList, setAccountList] = useState({ loading: false, data: [] })
  const viewOptPropertiesTabs =
    ['Properties', 'Sector', 'Industry', 'Factor Tilts', 'Substitutions', 'Instrument']

  const handleTabPanelChange = (event, newValue) => {
    setValue(newValue)
  }

  const fetchAccountList = () => {
    setAccountList({ loading: true, data: [] })
    getGeneralAccountListUtil({ serviceId: 'trade', resourceId: 'trade-approvals' })
      .then((res) => {
        const passiveTradingAccs = res?.data?.accountsSummary?.filter(acc => acc?.accountType === 'TRADING' && acc?.strTypeCode !== 'MANAGED') || []
        setAccountList({ loading: false, data: passiveTradingAccs })
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load account list.')
      })
      .finally(() => {
        setAccountList(prev => ({ ...prev, loading: false }))
      })
  }

  const getOptimizationPersonalization = async () => {
    try {
      const response = await API.get('baseOptimizationURL', `optimization/v1/${user?.userGroup}/personalization/${optId}`)
      setOptData(response?.data)
      setOtherPropertiesLoading(false)
    } catch (error) {
      setOtherPropertiesLoading(false)
      showError(error, false, {}, 'Failed to load personalization data.')
    }
  }

  const renderTabPanel = (item) => {
    switch (item) {
      case 'Properties': {
        return (
          <TradeOptProperties
            accountList={accountList}
            approvalProperties={approvalProperties}
            approvalPropertiesLoading={approvalPropertiesLoading}
            isPropertyCodesLoading={isPropertyCodesLoading}
            propertyCodes={propertiesList}
            setApprovalProperties={setApprovalProperties}
            selectedScenarioId={selectedScenarioId}
            allScenarioList={allScenarioList}
            optMapId={index}
            showCompact={showCompact}
          />
        )
      }
      // for all other tabs call TradePersonalizationTab to get data or loading screen(skeleton)
      case 'Sector': {
        const sector = sectorDataFormatter(optData, 'SECTOR_RESTRICTIONS')
        return (
          <TradePersonalizationTab
            loading={otherPropertiesLoading}
            header='Sector'
            tableData={tableData}
            item={item}
            type={sector}
            screen={screen}
            rowData={sector?.sectorData}
            showCompact={showCompact}
          />
        )
      }
      case 'Industry': {
        const industry = industryDataFormatter(optData, 'INDUSTRY_RESTRICTIONS')
        return (
          <TradePersonalizationTab
            loading={otherPropertiesLoading}
            header='Industry'
            tableData={tableData}
            item={item}
            type={industry}
            screen={screen}
            rowData={industry?.industryData}
            showCompact={showCompact}
          />
        )
      }
      case 'Factor Tilts': {
        const factorTilts = factoryDataFormatter(optData, 'FACTOR_TILTS')
        return (
          <TradePersonalizationTab
            loading={otherPropertiesLoading}
            header='Factor Tilts'
            tableData={tableData}
            item={item}
            type={factorTilts}
            screen={screen}
            rowData={factorTilts?.factoryData}
            showCompact={showCompact}
          />
        )
      }
      case 'Substitutions': {
        const substitutions = substitutionDataFormatter(optData, 'INSTRUMENT_SUBSTITUTIONS')
        return (
          <TradePersonalizationTab
            loading={otherPropertiesLoading}
            header='Substitutions'
            tableData={tableData}
            item={item}
            type={substitutions}
            screen={screen}
            rowData={substitutions?.subData}
            showCompact={showCompact}
          />
        )
      }
      case 'Instrument': {
        const instrument = instrumentDataFormatter(optData, 'INSTRUMENT_RESTRICTIONS')
        return (
          <TradePersonalizationTab
            loading={otherPropertiesLoading}
            header='Instrument'
            tableData={tableData}
            item={item}
            type={instrument}
            screen={screen}
            rowData={instrument?.instrumentData}
            showCompact={showCompact}
          />
        )
      }
      default:
        return 'Something went wrong'
    }
  }

  const getApprovalProperties = async (optMapId) => {
    API.post(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/opt-properties`,
      {
        body: {
          accOptMapId: [optMapId]
        }
      }
    )
      .then((response) => {
        if (response?.data?.data && response?.data?.data?.length > 0) {
          setApprovalProperties(response?.data?.data?.map((row) => ({
            ...row,
            propertyValueCopy: row?.propertyValue,
            propertySourceCopy: row?.propertySource,
            id: randomId()
          })))
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load approval properties.')
      })
      .finally(() => {
        setApprovalPropertiesLoading(false)
      })
  }

  const getAdhocPropertiesList = () => {
    setIsPropertyCodesLoading(true)
    API.get(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/adhoc-property-list`
    )
      .then((response) => {
        if (response?.data?.length) {
          setPropertiesList(response?.data)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load adhoc properties list.')
      })
      .finally(() => {
        setIsPropertyCodesLoading(false)
      })
  }

  useEffect(() => {
    if (user) {
      getOptimizationPersonalization()
      getApprovalProperties(index)
      getAdhocPropertiesList()
      fetchAccountList()
    }
  }, [user, index])

  if (approvalProperties === null) {
    return null
  }

  return (
    <TabContext value={value}>
      <TabList
        onChange={handleTabPanelChange}
        variant='scrollable'
        scrollButtons={false}
        TabIndicatorProps={{
          style: {
            backgroundColor: '#34475A'
          }
        }}
        indicatorColor='#34475A'
      >
        {viewOptPropertiesTabs?.map((item, index) => (
          <Tab
            className='reportCardNav' key={index} value={index.toString()} label={item} disabled={item === 'Geography'} sx={{
              opacity: item === 'Geography' ? 0.5 : 1
            }}
          />
        ))}
      </TabList>
      <Divider sx={{
        bgcolor: 'rgba(116, 120, 141, 0.15)'
      }}
      />
      {viewOptPropertiesTabs?.map((item, index) => (
        <TabPanel
          key={index}
          value={index.toString()}
          sx={{ p: 0 }}
        >
          {renderTabPanel(item)}
        </TabPanel>
      ))}
    </TabContext>
  )
}
export default ViewOptProperties
