import { Box, Typography, Skeleton, Divider } from '@mui/material';
import ReactApexChart from 'react-apexcharts';

const TaxFocusedProgress = ({ scenario, loading, isScenarioScreen }) => {

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value)
  }

  const isErrorScenario = scenario?.optTaxCost > scenario?.basedTaxCost
  const chartOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: '100%',
      toolbar: { show: false },
      sparkline: { enabled: true }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '100%',
        borderRadius: 4,
        borderRadiusApplication: 'end'
      }
    },
    stroke: { width: 0 },
    grid: { show: false },
    colors: ['#4575AC', isErrorScenario ? '#E06464' : '#4575AC'],
    fill: {
      type: ['pattern', 'solid'],
      pattern: {
        style: 'verticalLines',
        strokeWidth: 5,
        width: 8,
        height: 8,
        color: isErrorScenario ? '#E06464' : '#4575AC'
      }
    },
    dataLabels: { enabled: false },
    tooltip: { enabled: false },
    xaxis: { labels: { show: false }, axisBorder: { show: false }, axisTicks: { show: false } },
    yaxis: { labels: { show: false } },
    legend: { show: false }
  }

  const series = [
    { name: 'Base Taxcost', data: [(scenario?.data?.dottedBlue / scenario?.data?.baseValue) * 100] },
    { name: 'Optimization Taxcost', data: [(((isErrorScenario ? scenario?.data?.redValue : scenario?.data?.blueValue) || 0) / scenario?.data?.baseValue) * 100] }
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: isScenarioScreen ? 0 : 2, gap: '5px' }}>
          {loading ? (
            <Skeleton variant="text" width={100} height={20} />
          ) : (
            <Typography sx={{ fontSize: '15px', fontFamily: 'Open Sans', color: '#2F2F2F', fontWeight: 600 }}>
              {isScenarioScreen ? 'Tax Efficiency' : scenario?.name}
            </Typography>
          )}
        </Box>

        {
          isScenarioScreen ? (
            <Divider sx={{ my: '10px' }} />
          ) : ''
        }

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box>
            <Typography sx={{ color: '#6A86A6', fontSize: '12px' }}>Base Tax Cost</Typography>
            {loading ? <Skeleton variant="text" width={80} height={20} /> : (
              <Typography sx={{ color: '#032347', fontWeight: 600, fontSize: '16px' }}>
                {formatCurrency(scenario?.basedTaxCost)}
              </Typography>
            )}
          </Box>
          <Box sx={{ textAlign: 'right' }}>
            <Typography sx={{ color: '#6A86A6', fontSize: '12px' }}>Optimization Tax Cost</Typography>
            {loading ? <Skeleton variant="text" width={80} height={20} /> : (
              <Typography sx={{ color: '#032347', fontWeight: 600, fontSize: '16px' }}>
                {formatCurrency(scenario?.optTaxCost)}
              </Typography>
            )}
          </Box>
        </Box>

        <Box sx={{ height: 35, borderRadius: '8px', overflow: 'hidden' }}>
          {loading ? (
            <Skeleton variant="rectangular" width='100%' height={35} />
          ) : (
            <ReactApexChart options={chartOptions} series={series} type='bar' height='35' />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TaxFocusedProgress;