import { TextField } from '@mui/material'

const CustomDateField = (props) => {
  const { id, name, label, formik, select = false, placeholder, disabled, value, onChange, error, helperText, defaultAccountDetails, ...otherProps } = props
  return (
    <TextField
      id={id}
      name={name}
      type='date'
      size='small'
      label={label}
      disabled={disabled}
      select={select}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      sx={{ width: '100%', marginTop: '0px' }}
      InputLabelProps={{ shrink: true }}
      {...otherProps}
    />
  )
}

export default CustomDateField
