import { styled, Tab, Tabs } from '@mui/material'

const MainTabs = ({ selectedTab, tabsList, handleTabChange, size }) => {
  return (
    <>
      <CustomTabs
        value={selectedTab}
        onChange={handleTabChange}
        size={size}
        {
        ...(size !== 'small'
          ? {
              variant: 'fullWidth'
            }
          : {})
        }
      >
        {
          tabsList.map((item, index) => (
            <CustomTab
              size={size}
              key={index}
              value={item.id}
              disableRipple
              label={item.label}
            />
          ))
        }
      </CustomTabs>
    </>
  )
}

const CustomTabs = styled(Tabs)(({ size }) => ({
  color: '#2F2F2F',
  backgroundColor: '#E6ECF3',
  borderRadius: '10px',
  ...(
    size === 'small'
      ? {
          display: 'inline-flex',
          minHeight: '36px'
        }
      : {}),
  '& .MuiTabs-flexContainer': {
    padding: '0 3px',
    position: 'relative',
    zIndex: 1
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    bottom: 3,
    boxShadow: '0 4px 12px 0 rgba(0,0,0,0.16)',
    height: 'auto',
    right: 3,
    top: 3
  }
}))

const CustomTab = styled(Tab)(({ size }) => ({
  fontFamily: 'Open Sans',
  textTransform: 'capitalize',
  color: '#2F2F2F',
  fontWeight: 600,
  ...(size === 'small'
    ? {
        minHeight: '36px',
        fontSize: '12px'
      }
    : {}
  ),
  '&.Mui-selected': {
    color: '#4575AC',
    fontWeight: 600
  }
}))

export default MainTabs
