import React, { useContext } from 'react'
import { Box, Grid, Skeleton, Tooltip, Typography } from '@mui/material'
import { formatCurrencyWithSymbol } from '../../../../utils/FormateCurrenyInMilion'
import { ScenarioContext } from '..'

const StatItem = ({ title, value, align = 'left', loading }) => (
  <Grid item textAlign={align}>
    <Typography sx={{ fontSize: '16px', color: '#002A59', fontWeight: 400, mb: '4px' }}>{title}</Typography>
    <Typography sx={{ fontSize: '12px', color: '#8F9190', fontWeight: 600, mb: '4px' }}>Total</Typography>
    {
      loading ? (
        <Skeleton variant='text' width='100px' sx={{ fontSize: '20px' }} />
      ) : (
        <Typography sx={{ fontSize: '20px', color: '#002A59', fontWeight: 600 }}>{value}</Typography>
      )
    }
  </Grid>
)

const BoxItem = ({ value, bgColor, height = '50px', label }) => {
  const width = Number(value)
  return (
    <Grid
      item
      sx={{
        cursor: 'pointer',
        backgroundColor: bgColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: height,
        fontSize: '16px',
        fontWeight: 700,
        textAlign: 'center',
        borderRadius: '12px',
        flexBasis: '' + parseFloat(value?.toFixed(2)) + '%',
        width: '100%'
      }}
    >
      {
        width >= 5 ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography sx={{ color: '#232323', fontSize: '14px', fontWeight: 600 }}>{parseFloat(value?.toFixed(2))}%</Typography>
            <Typography sx={{ color: '#232323', fontSize: '14px' }}>{label || ''}</Typography>
          </Box>
        ) : ''
      }
    </Grid>
  )
}

const TransfersSellChart = ({ aggId }) => {
  const scenarioData = useContext(ScenarioContext)
  const isLoading = scenarioData.summaryGraphData[aggId]?.loading
  const transferSellData = scenarioData.summaryGraphData[aggId]?.data?.midCardData

  const formateToUsdCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const legends = [
    {
      label: 'Transfer',
      color: '#E7EEFF',
      borderColor: '#A1BDFF'
    },
    {
      label: 'Sell',
      color: '#FFF6E3',
      borderColor: '#FDD07B'
    },
    // {
    //   label: 'Tax',
    //   color: '#91AFCE',
    //   borderColor: '#9AD3EB'
    // },
    {
      label: 'Buy',
      color: '#B5B2E6',
      borderColor: '#7A6BFE'
    },
    {
      label: 'Cash',
      color: '#6CB2C3',
      borderColor: '#AAE8C7'
    }
  ]

  const labeltoKeyMap = {
    'Transfer': 'transferMv',
    'Sell': 'sellMv',
    'Cash': 'cashMv',
    'Buy': 'buyMv'
  }

  return (
    <Box>
      <Grid container justifyContent='space-between' alignItems='center' sx={{ mb: '16px' }}>
        <StatItem loading={isLoading} title='Transfers' value={formateToUsdCurrency.format(transferSellData?.transferMv ?? 0)} />
        <StatItem loading={isLoading} title='Sell' value={formateToUsdCurrency.format(transferSellData?.sellMv ?? 0)} align='right' />
      </Grid>
      <Grid container>
        {
          isLoading ? (
            <Skeleton variant='rectangular' height='100px' width='100%' />
          ) : (
            transferSellData ? (
              <>
                <Tooltip title={`Transfers: ${formatCurrencyWithSymbol(transferSellData?.transferMv ?? 0, 2, '$')}`} >
                  <Grid item sx={{ width: `${transferSellData?.transfer}%` }}>
                    <BoxItem
                      value={transferSellData?.transfer}
                      bgColor='#E7EEFF'
                      height='100px'
                      label={'Transfer'}
                    />
                  </Grid>
                </Tooltip>
                <Grid item sx={{ width: `${transferSellData?.sell ?? 0}%`, display: 'flex', flexDirection: 'column' }}>
                  <Tooltip title={`Sell + Cash: ${formatCurrencyWithSymbol(transferSellData?.sellMv ?? 0, 2, '$')}`}>
                    <Grid item xs={12}>
                      <BoxItem
                        value={transferSellData?.sell}
                        bgColor='#FFF6E3'
                        height='50px'
                        label={'Sell + Cash'}
                      />
                    </Grid>
                  </Tooltip>
                  <Grid container wrap='nowrap' sx={{ width: '100%', display: 'flex' }}>
                    <Tooltip title={`Buy: ${formatCurrencyWithSymbol(transferSellData?.buyMv ?? 0, 2, '$')}`}>
                      <Grid item sx={{ width: `${(transferSellData?.buy / transferSellData?.sell) * 100 ?? 0}%` }}>
                        <BoxItem
                          value={transferSellData?.buy}
                          bgColor='#B5B2E6'
                          height='50px'
                          label={'Buy'}
                        />
                      </Grid>
                    </Tooltip>
                    <Tooltip title={`Cash: ${formatCurrencyWithSymbol(transferSellData?.cashMv ?? 0, 2, '$')}`}>
                      <Grid item sx={{ width: `${(transferSellData?.cash / transferSellData?.sell) * 100 ?? 0}%` }}>
                        <BoxItem
                          value={transferSellData?.cash}
                          bgColor='#C6D4E3'
                          height='50px'
                          label={'Cash'}
                        />
                      </Grid>
                    </Tooltip>
                  </Grid>
                </Grid>
              </>
            ) : ''
          )
        }
      </Grid>
      <Box sx={{
        mt: '10px',
        display: 'flex',
        width: { xs: '100%', md: 'inherit' },
        alignItems: 'center', justifyContent: 'center', gap: '12px', flexWrap: 'wrap'
      }}>
        {/* {
          legends.map((obj, index) => (
            <Box key={index} sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Box sx={{ width: '16px', height: '16px', borderRadius: '3px', border: `1px solid ${obj.borderColor}`, background: obj.color }}></Box>
              <Typography sx={{ ml: '8px', fontWeight: 600, fontSize: '12px' }}>{obj?.label}</Typography>
              <Typography sx={{ ml: '8px', fontWeight: 600, fontSize: '12px' }}>: {formateToUsdCurrency.format(transferSellData?.[labeltoKeyMap[obj?.label]] ?? 0)}</Typography>
            </Box>
          ))
        } */}
      </Box>
    </Box>
  )
}

export default TransfersSellChart
