import { Autocomplete, Button, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Close'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import AdhocOptPropertyValueField from './AdhocOptPropertyValueField'

const AddPropertyRecordPopup = (props) => {
  const {
    handleCancelClick,
    allScenarioList,
    savedData,
    propertiesList,
    selectedScenarios,
    defaultScenario = null,
    accountList
  } = props

  const createFormValidationSchema = Yup.object().shape({
    scenarioDesc: Yup.string()
      .required('Scenario description is required'),
    propertyCode: Yup.string()
      .required('Property Code is required'),
    propertyValue: Yup.string()
      .required('Property value is required')
  })

  const initialFormValues = {
    scenarioId: defaultScenario?.scenarioId || '',
    scenarioDesc: defaultScenario?.scenarioDescription || '',
    propertyCode: '',
    propertyValue: '',
    propertySource: 'ADHOC'
  }

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: createFormValidationSchema,
    onSubmit: (values) => {
      savedData(values)
    }
  })

  const renderAdhocPropertyValueField = () => {
    const propertyIndex = propertiesList.findIndex(
      (obj) => obj?.propertyCode === formik?.values?.propertyCode
    )
    if (propertyIndex === -1) return null
    return (
      <Grid item>
        <AdhocOptPropertyValueField
          allScenarioList={allScenarioList}
          accountList={accountList}
          propertyCode={propertiesList[propertyIndex]?.propertyCode}
          dataType={propertiesList[propertyIndex]?.dataType}
          propertyValue={formik.values.propertyValue}
          formik={formik} />
      </Grid>
    )
  }

  return (
    <>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', paddingBottom: 0 }}>
        <Typography
          variant='h6'
          textAlign='center'
          color='#34475A'
          fontWeight={400}
        >
          Add Adhoc Property
        </Typography>
        <IconButton onClick={() => { handleCancelClick() }}>
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} direction='column' sx={{ mt: '1px' }}>
            <Grid item>
              <FormControl fullWidth size='small' error={Boolean(formik.errors.scenarioDesc && formik.touched.scenarioDesc)}>
                <InputLabel
                  id='label-id' sx={{
                    color: Boolean(formik.errors.scenarioDesc && formik.touched.scenarioDesc) && formik.values.scenarioDesc === '' ? '#d32f2f' : ''
                  }}
                >Scenario Description
                </InputLabel>
                <Select
                  labelId='label-id'
                  size='small'
                  id='scenarioDesc'
                  label='Scenario Description'
                  value={formik.values.scenarioDesc}
                  onChange={(e) => {
                    const selectedScenario = allScenarioList.find(s => s.scenarioDescription === e.target.value)
                    formik.setFieldValue('scenarioDesc', e.target.value || '')
                    formik.setFieldValue('scenarioId', selectedScenario?.scenarioId || '')
                    formik.setFieldError('scenarioDesc', '')
                    formik.setFieldTouched('scenarioDesc', false, false)
                  }}
                  name='scenarioDesc'
                >
                  {allScenarioList
                    ?.filter((scenario) => selectedScenarios?.includes(scenario?.scenarioId))
                    .map((scenario, index) => (
                      <MenuItem key={index} value={scenario?.scenarioDescription}>
                        {scenario?.scenarioDescription}
                      </MenuItem>
                    ))}
                </Select>
                {Boolean(formik.errors.scenarioDesc && formik.touched.scenarioDesc) && (
                  <FormHelperText sx={{ color: '#d32f2f', marginLeft: '5px' }}>Scenario description is required</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item>
              <Autocomplete
                size='small'
                value={formik?.values?.propertyCode || ''}
                options={propertiesList?.map(propertyObj => propertyObj?.propertyCode)}
                onChange={(e, newValue) => {
                  formik.setFieldValue('propertyValue', '')
                  formik.setFieldValue('propertyCode', newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Property Code'
                    error={Boolean(formik.errors.propertyCode && formik.touched.propertyCode)}
                    helperText={
                        formik.errors.propertyCode &&
                        formik.touched.propertyCode &&
                        String(formik.errors.propertyCode)
                      }
                    variant='outlined'
                    InputProps={{ ...params.InputProps }}
                    FormHelperTextProps={{
                      style: { marginLeft: '5px' }
                    }}
                  />
                )}
              />
            </Grid>
            {renderAdhocPropertyValueField()}
            <Grid item>
              <TextField
                id='source'
                name='source'
                label='Property Source'
                variant='outlined'
                value={formik.values.propertySource}
                size='small'
                disabled
                fullWidth
                FormHelperTextProps={{
                  style: { marginLeft: '5px' }
                }}
              />
            </Grid>
            <Grid item>
              <Button color='primary' variant='contained' fullWidth type='submit'>
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </>
  )
}

export default AddPropertyRecordPopup
