import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconButton, styled } from '@mui/material'

const StyledExpandMoreIcon = styled(({ className, ...props }) => (
  <ExpandMoreIcon {...props} className='arrow-icon' />
))``

const StyledChevronRightIcon = styled(({ className, ...props }) => (
  <ChevronRightIcon {...props} className='arrow-icon' />
))``


const ExpandCollapseIcon = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'inline-flex',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  },
  // display: 'inline-flex',
  position: 'absolute',
  right: '-9px',
  bottom: '55px',
  color: 'white',
  border: '2px solid white',
  padding: '4px 2px',
  zIndex: 2,
  borderRadius: '10px',
}))

export { StyledChevronRightIcon, StyledExpandMoreIcon, ExpandCollapseIcon }
