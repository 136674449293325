import { Grid } from '@mui/material'
import { Card as CustomCard } from '../components'
import Consideration from '../components/Consideration'
import ReturnsOverTime from './ReturnsOverTime'
import Distribution from './Distribution'
import Insights from './Insights'
import TopUrglSecurityChart from './TopUrglSecurityChart'
import RiskTolerance from './RiskTolerance'
import StrategyDistribution from './StrategyDistribution'

const OriginalUMA = () => {
  return (
    <>
      <Grid container spacing={1} sx={{ height: '100%' }} mt={3}>
        <Grid item md={8} xs={12}>
          <Grid container spacing={1} sx={{ height: '100%' }}>
            <Grid item xs={12}>
              <CustomCard>
                <ReturnsOverTime />
              </CustomCard>
            </Grid>
            <Grid item xs={12}>
              <CustomCard>
                <Distribution />
              </CustomCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CustomCard>
                <Insights />
              </CustomCard>
            </Grid>
            <Grid item xs={12}>
              <CustomCard>
                <RiskTolerance />
              </CustomCard>
            </Grid>
            <Grid item xs={12}>
              <CustomCard>
                <StrategyDistribution />
              </CustomCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Consideration umaType='TRANSITION' />
    </>
  )
}

export default OriginalUMA
