import { getUserRole } from '../../../utils/getUserRole'
import { GetAPI } from '../components/Actions'
import { formatModelAllocData } from './ModelAllocationForm'

export const aggregateAPI = (id, parameter) => {
  const userRole = getUserRole()
  return GetAPI('baseUriAggregateMaintenance', `data-maintenance/v1/${userRole}/aggregate/${id}/${parameter}`)
}

export const getAggregateMasterAPI = () => { // formik autocomplete
  const userRole = getUserRole()
  return GetAPI('baseUriAggregateMaintenance', `data-maintenance/v1/${userRole}/aggregate`)
}

export const getPropertyCodeAPI = () => { // formik autocomplete
  const userRole = getUserRole()
  return GetAPI('baseUriMasterData', `data-maintenance/v1/${userRole}/property-codes`)
}

export const GetAggregateData = async (id) => {
  const apiCalls = [
    aggregateAPI(id, 'master'),
    aggregateAPI(id, 'properties'),
    aggregateAPI(id, 'external-gain-loss'),
    aggregateAPI(id, 'parameter'),
    aggregateAPI(id, 'tax-rates'),
    aggregateAPI(id, 'model-allocation'),
    aggregateAPI(id, 'agg-account-map'),
  ]

  const responses = await Promise.allSettled(apiCalls)

  const [
    aggregateMasterAPIData,
    aggregatePropertyAPIData,
    aggregateExternalGlAPIData,
    aggregateParameterAPIData,
    aggregateTaxRatesAPIData,
    aggregateModelAllocAPIData,
    aggregateAccountMapAPIData,
  ] = responses

  return [
    aggregateMasterAPIData?.value,
    aggregatePropertyAPIData?.value,
    aggregateExternalGlAPIData?.value,
    aggregateParameterAPIData?.value,
    aggregateTaxRatesAPIData?.value,
    aggregateModelAllocAPIData?.value
    ? {
      ...aggregateModelAllocAPIData?.value,
      data: {
        ...aggregateModelAllocAPIData?.value?.data,
        data: formatModelAllocData(aggregateModelAllocAPIData?.value?.data?.data)
      }
    }
    : [],
    aggregateAccountMapAPIData?.value,
  ]
}
