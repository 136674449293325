import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { Amplify } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css'
import { ThemeProvider, createTheme } from '@mui/material'
import { LicenseInfo } from '@mui/x-license-pro'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { awsconfig } from './aws-exports'
import { AuthProvider } from './contexts/AuthContext'
import { TourContextProvider } from './contexts/TourContext'
import AppRoutes from './routes/AppRoutes'
import { logoutChannel } from './utils/getLogoutChannel'
import { customTheme } from './theme'
import './assets/styles/app.scss'

Amplify.configure(awsconfig)

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY)
function App () {
  const themeData = useSelector((state) => state.theme)

  const customSponsorTheme =
    createTheme({
      ...customTheme,
      // set dynamic logos and colors for left sidebar
      palette: {
        primaryPalette: {
          main: themeData?.primaryColor,
          contrastText: themeData?.brandTextColor
        },
        paletteTextColor: {
          main: themeData?.brandTextColor,
          contrastText: themeData?.brandTextColor
        },
        secondaryPalette: {
          main: themeData?.secondaryColor,
          contrastText: themeData?.brandTextColor
        }
      }
    })

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        // gc time: how long the cache is persisted in memory AFTER components referencing it unmount.
        // Stale time: how long before cache data is considered stale and refreshed.
        gcTime: Infinity,
        staleTime: Infinity
      }
    }
  })

  useEffect(() => {
    // close logout channel on unmounting app
    return () => {
      logoutChannel.close()
    }
  }, [])

  return (
    <>
      <ThemeProvider theme={customSponsorTheme}>
        <TourContextProvider>
          <AuthProvider>
            <QueryClientProvider client={queryClient}>
              <AppRoutes />
            </QueryClientProvider>
            <ToastContainer />
          </AuthProvider>
        </TourContextProvider>
      </ThemeProvider>
    </>
  )
}

export default App
