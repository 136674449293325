import { Box, Button, IconButton, styled, TextField, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import FixConnectivityStatusChip from './FixConnectivityStatusChip'
import { GridRowModes } from '@mui/x-data-grid'
import { isNumberOnly, numberOnlyPasteHandler } from '../../../../utils/NumberUtils'
import StatusFilter from './StatusFilter'

const HeaderLabel = styled(Typography)({
  fontSize: '14px',
  color: '#74788d'
})

const HeaderValue = styled(Typography)({
  fontSize: '14px'
})

const AdhocPopupHeader = (props) => {
  const { tradesLoading, tradeRow, tradeSummaryLoading, cashValue = 0, setCashValue,
    sellValue = 0, buyValue = 0, selectedTrades, isBlockTrade, fixFlyerStatusList,
    refreshTradeAdhoc, updateFixConnectivityStatus, rowModesModel,
    sendAllocation, sendTrades, closeTradeAdhocPopup, applyStatusFilters } = props

  const disableResumeTrade = !selectedTrades?.length || (tradeRow?.optDetails?.length && tradeRow?.optDetails[0]?.summaryDetails?.fixTradeStatus === 'TRADE_COMPLETED') || (Object.keys(rowModesModel).find(key => rowModesModel[key].mode === GridRowModes.Edit))
  const disableSendAllocation = !selectedTrades?.length || (Object.keys(rowModesModel).find(key => rowModesModel[key].mode === GridRowModes.Edit))
 
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
      <Typography variant='h6' sx={{ ml: '5px', mr: '5px', fontWeight: 400, color: '#002A59' }}>Trade Details</Typography>
      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
        {
          tradesLoading
            ? ''
            : (
              <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '10px' }}>
                {tradeRow?.optDetails?.length && !tradeSummaryLoading
                  ? <FixConnectivityStatusChip fixTradeStatus={tradeRow?.optDetails[0]?.summaryDetails?.fixTradeStatus} fixFlyerStatusList={fixFlyerStatusList} updateFixConnectivityStatus={updateFixConnectivityStatus} />
                  : ''}
                <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '10px' }}>
                  <HeaderLabel>Cash Available To Buy:</HeaderLabel>
                  <TextField
                    type='text'
                    size='small'
                    sx={{
                      width: '150px',
                      '& .MuiInputBase-input': {
                        padding: '3px 10px'
                      }
                    }}
                    value={cashValue}
                    onChange={(e) => setCashValue(e.target.value)}
                    onKeyDown={isNumberOnly}
                    onPaste={numberOnlyPasteHandler}
                  />
                </Box>
                <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '10px' }}>
                  <HeaderLabel>Total Sell Value:</HeaderLabel>
                  <HeaderValue>${sellValue?.toFixed(2)}</HeaderValue>
                </Box>
                <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '10px' }}>
                  <HeaderLabel>Total Buy Value:</HeaderLabel>
                  <HeaderValue>${buyValue?.toFixed(2)}</HeaderValue>
                </Box>
                <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '10px' }}>
                  <HeaderLabel>Net Value:</HeaderLabel>
                  <HeaderValue>${((parseFloat(cashValue)) + (sellValue) - (buyValue))?.toFixed(2)}</HeaderValue>
                </Box>
                <Button variant='contained' size='small' disabled={disableResumeTrade} onClick={sendTrades}>Resume Trade</Button>
                {isBlockTrade ? <Button variant='contained' size='small' disabled={disableSendAllocation} onClick={sendAllocation}>Send Allocation</Button> : ''}
                <StatusFilter applyStatusFilters={applyStatusFilters} isBlockTrade={isBlockTrade}/>
                <IconButton
                  onClick={refreshTradeAdhoc}
                  sx={{
                    padding: 1,
                    borderRadius: '10px',
                    border: '2px solid #dee2e6'
                  }}
                >
                  <RefreshIcon sx={{ fontSize: '16px' }} />
                </IconButton>
              </Box>
            )
        }
        <IconButton onClick={() => closeTradeAdhocPopup(false)} size='small'>
          <CancelIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default AdhocPopupHeader