import { useState } from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import PolicyStatusPopup from '../PolicyStatusPopup'

const PolicyStatusCell = (props) => {
  const { params, getOptRuns, diTrading } = props
  const [showPopup, setShowPopup] = useState('')
  const { items } = params.api.state?.filter?.filterModel
  const filterItem = items?.find(filter => filter.field === 'policyStatus')

  function renderPolicy(policy, color) {
    return (
      <Tooltip title={`${policy} Policies`}>
        <span>
          <IconButton onClick={() => setShowPopup(policy)} sx={{ p: '4px' }}>
            <CircleIcon sx={{ height: '12px', width: '12px', color: getStatusColor(color) }} />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  function getFilteredPolicies(filterItem) {
    const { account, strategy, trade } = params?.row?.policyStatus || {}
    const policies = [
      { type: 'Account', value: account },
      { type: 'Strategy', value: strategy },
      { type: 'Trade', value: trade },
    ]
    if ((filterItem?.operator === 'has any of' && !filterItem?.value?.length) || (filterItem.operator !== 'has any of' && !filterItem?.value)) {
      return policies.map(policy => renderPolicy(policy.type, policy.value))
    }
    const filteredPolicies = policies.filter(policy => {
      switch (filterItem.operator) {
        case 'has':
          return policy.value === filterItem.value
        case 'has not':
          return policy.value !== filterItem.value
        case 'has any of':
          return filterItem.value.includes(policy.value)
        default:
          return false
      }
    })

    return filteredPolicies.map(policy => renderPolicy(policy.type, policy.value))
  }

  function getStatusColor(status) {
    if (status === 'green')
      return '#3BBFA3'
    else if (status === 'red')
      return '#FF6161'
    else if (status === 'yellow')
      return '#D29922'
    else if (status === 'gray')
      return '#0000004d'
    return '#c9c9c9'
  }

  return (
    <>
      <Box
        sx={{ borderRadius: '20px', py: '1px', px: '2px', backgroundColor: '#00000011' }}
      >
        {
          filterItem && filterItem.value ? (
            getFilteredPolicies(filterItem).map(policy => (policy))
          ) : (
            <>
              <Tooltip title={'Account Policies'}>
                <IconButton onClick={() => setShowPopup('Account')} sx={{ p: '4px' }}>
                  <CircleIcon sx={{ height: '12px', width: '12px', color: getStatusColor(params?.row?.policyStatus?.account) }} />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Strategy Policies'}>
                <IconButton onClick={() => setShowPopup('Strategy')} sx={{ p: '4px' }}>
                  <CircleIcon sx={{ height: '12px', width: '12px', color: getStatusColor(params?.row?.policyStatus?.strategy) }} />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Trade Policies'}>
                <IconButton onClick={() => setShowPopup('Trade')} sx={{ p: '4px' }}>
                  <CircleIcon sx={{ height: '12px', width: '12px', color: getStatusColor(params?.row?.policyStatus?.trade) }} />
                </IconButton>
              </Tooltip>
            </>
          )
        }
      </Box>
      {showPopup &&
        <PolicyStatusPopup
          showPolicyPopup={showPopup}
          getOptRuns={getOptRuns}
          onClose={() => setShowPopup('')}
          params={params}
          title={showPopup}
          policySource={
            showPopup === 'Account'
              ? 'ACCOUNT_POLICY'
              : showPopup === 'Strategy'
                ? 'STRATEGY_POLICY'
                : showPopup === 'Trade'
                  ? 'TRADE_POLICY'
                  : null
          }
        />}
    </>
  )
}

export default PolicyStatusCell