import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Card, CardContent, FormControl, FormLabel, Grid, Input, Modal, Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import eyefill from '../../../assets/images/eye-fill.svg'
import gridIcon from '../../../assets/images/grid-Icon.svg'
import sendIcon from '../../../assets/images/sendIcon.svg'
import '../../../assets/styles/AccountRequest.scss'
import AccountTitle from '../../../components/AccountTitle'
import AllComments from '../../../components/AllComments'
import CustomKeyFieldSearch from '../../../components/CustomKeyFieldSearch'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { formatCurrency } from '../../../utils/FormateCurrenyInMilion'
import RequestForm from './RequestForm'
import CustomNoRowsOverlay from '../../PMScreen/RequestsPage/CustomNoRowsOverlay'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  maxHeight: 625,
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '4px',
  overflow: 'auto'
}
const AccountRequest = () => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [accountRequests, setAccountRequests] = useState([])
  const [accountRequestsCopy, setAccountRequestsCopy] = useState([])
  const params = useParams()
  const [noData, setNodata] = useState()
  const [open, setOpen] = React.useState(false)
  const handleNewRequestOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [loading, setLoading] = useState(false)

  const { height } = useWindowDimensions()

  useEffect(() => {
    if (user) {
      getAccountRequests()
    }
  }, [user, params?.accountId])

  const datasecure = localStorage.getItem('object')

  const getStoreData = JSON.parse(datasecure)

  const filterSecuredata = getStoreData?.filter((item) => item?.accountId === params?.accountId)

  const getAccountRequests = async (page = 1) => {
    API
      .get('baseRequestURL', `request/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/requests/${params.accountId}`, { queryStringParameters: { page, perpage: 100 } })
      .then(response => {
        if (response?.data?.length === 0) {
          setNodata(response.message || 'No Request Found')
          setLoading(true)
        } else
          if (response?.data) {
            setNodata() // Clear out the 'no data' message in case request is created after rendering
            setAccountRequests(response?.data?.reqData)
            setAccountRequestsCopy(response?.data?.reqData)
            setLoading(true)
          }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to load account requests.')
        setNodata(error.response?.data?.message)
      })
  }

  return (
    <>
      <Box className='Account-request'>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <AccountTitle title='Request' />
          <CustomKeyFieldSearch
            filterData={(data) => setAccountRequests(data)}
            fieldName='userRequestId'
            tableData={accountRequestsCopy.map((item, index) => ({ ...item, idx: index }))}
            fieldLabel='Request ID'
            sx={{ width: 'max-content' }}
          />
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={modalStyle}>
            <RequestForm
              handleClose={handleClose}
              accountName={filterSecuredata && filterSecuredata.length && filterSecuredata[0]?.accountName}
              getAccountRequests={getAccountRequests}
              name={filterSecuredata && filterSecuredata.length && filterSecuredata[0]?.accountName || 'N/A'}
            />
          </Box>
        </Modal>
        <Box>
          <>
            {loading
              ? (
                <Card className='card-layout'>
                  <CardContent>
                    <RequestList data={accountRequests || []} name={filterSecuredata && filterSecuredata.length && filterSecuredata[0]?.accountName || 'N/A'} pageSize={height > 800 ? 10 : 5} handleNewRequestOpen={handleNewRequestOpen} noDataMessage={noData} />
                  </CardContent>
                </Card>
                )
              : (
                <Stack spacing={2}>
                  <Skeleton variant='rounded' animation='wave' height={40} />
                  <Skeleton variant='rounded' animation='wave' height={40} />
                  <Skeleton variant='rounded' animation='wave' height={40} />
                  <Skeleton variant='rounded' animation='wave' height={40} />
                  <Skeleton variant='rounded' animation='wave' height={40} />
                </Stack>
                )}
          </>
        </Box>
      </Box>
    </>
  )
}
export default AccountRequest

export const RequestList = ({ data, name, pageSize, handleNewRequestOpen, noDataMessage = 'No request found' }) => {
  const roles = localStorage.getItem('userGroup')
  const [isShown, setIsShown] = useState(false) // to show the new request Form
  const [editIndex, setEditIndex] = useState()
  const params = useParams()
  const accountId = params.accountId
  const [requests, setRequests] = useState(data?.map((item, index) => ({ ...item, idx: index })) || [])
  const [currentPage, setCurrentPage] = useState(0)

  useEffect(() => {
    setRequests(data?.map((item, index) => ({ ...item, idx: index })) || [])
  }, [data])

  const viewHandleShow = (index) => {
    setEditIndex(index)
    setIsShown(true)
  }

  const updateList = (item, index) => {
    data[index] = item?.data?.result
  }

  const columns = [
    { field: 'requestStatus', headerName: 'Status', flex: 1 },
    { field: 'userRequestId', headerName: 'Request ID', flex: 1 },
    { field: 'requestType', headerName: 'Request Type', flex: 1 },
    { field: 'title', headerName: 'Request Title', flex: 1 },
    {
      field: 'requestCreationDate',
      headerName: 'Created On',
      flex: 1,
      valueGetter: (params) => new Date(params.row.requestCreationDate).toDateString()
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 1,
      valueGetter: (params) => params.row.createdBy
    },
    { field: 'etaHours', headerName: 'Estimated Time', flex: 1, valueGetter: (params) => `${params.row.etaHours} hours` },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Tooltip title='View Request' placement='bottom'>
            <Button
              sx={{ minWidth: '10px' }}
              onClick={() => viewHandleShow(params.row.idx)}
            >
              <img src={eyefill} alt='eyefill' />
            </Button>
          </Tooltip>
          {roles === 'pm' && params.row.requestType === 'Generate Proposal' && (
            (params.row.requestStatus === 'Fulfilled' || params.row.requestStatus === 'Review')
              ? (
                <Tooltip title='Transition Report'>
                  <Button className='action-clock-btn' sx={{ minWidth: '10px', marginBottom: '-6px' }}>
                    <NavLink to={`/account-review/reports/${accountId}/${params.row.userRequestId}`}>
                      <img src={gridIcon} alt='icon' />
                    </NavLink>
                  </Button>
                </Tooltip>
                )
              : null
          )}

          {roles === 'adv-classic' &&
            params.row.requestType === 'Generate Proposal' &&
            params.row.requestStatus === 'Fulfilled' && (
              <Tooltip title='Transition Report'>
                <Button
                  className='action-clock-btn tooltip-trade'
                  sx={{ minWidth: '10px', marginBottom: '-6px' }}
                >
                  <NavLink to={`/account-review/reports/${accountId}/${params.row.userRequestId}`}>
                    <img src={gridIcon} alt='icon' />
                  </NavLink>
                </Button>
              </Tooltip>
          )}
        </Box>
      )
    }
  ]

  return (
    <>
      <Modal
        open={isShown}
        onClose={() => setIsShown(false)}
      >
        <Box sx={modalStyle}>
          <ViewRequest
            selectedItem={data?.[editIndex]}
            index={editIndex}
            name={name}
            updateList={updateList}
          />
        </Box>
      </Modal>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          startIcon={<AddIcon />}
          color='primary'
          id='request-create'
          onClick={handleNewRequestOpen}
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1
          }}
        >
          New Request
        </Button>
      </Box>
      <DataGridPro
        columns={columns}
        rows={requests}
        autoHeight
        pageSizeOptions={[pageSize]}
        onPaginationModelChange={(event) => setCurrentPage(event.page)}
        paginationModel={{ pageSize, page: currentPage }}
        initialState={{
          pagination: { paginationModel: { pageSize, page: currentPage } }
        }}
        pagination
        getRowId={(row) => row.userRequestId}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay
        }}
        slotProps={{
          noRowsOverlay: { message: noDataMessage }
        }}
        sx={{
          border: 'none',
          fontFamily: 'Open Sans',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600,
            color: '#34475A'
          },
          '& .MuiDataGrid-columnHeaderTitleContainerContent': {
            fontWeight: 600,
            color: '#34475A'
          },
          '.MuiDataGrid-cellContent': {
            fontWeight: 600
          },
          ...(requests?.length === 0 && {
            '& .MuiDataGrid-virtualScrollerContent': {
              height: '45px !important'
            }
          })
        }}
      />
    </>
  )
}

const LoadingSkeletonViewReq = () => {
  return (
    <Box>
      <Skeleton height={80} animation='wave' sx={{ py: 4 }} />
      <Skeleton height={60} animation='wave' sx={{ py: 3 }} />
      <Skeleton height={60} animation='wave' sx={{ py: 3 }} />
      <Skeleton height={60} animation='wave' sx={{ py: 3 }} />
      <Skeleton height={60} animation='wave' sx={{ py: 3 }} />
    </Box>
  )
}

export const ViewRequest = ({ selectedItem, name }) => {
  const { requestType, title, comment, amount, requestId, requestStatus, ytdRealShortGains, ytdRealLongGains } = selectedItem
  const [data] = useState({ requestType, title, comment, amount, requestId, requestStatus, ytdRealShortGains, ytdRealLongGains })
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [commentRequests, setCommentRequests] = useState([])
  const [commentValue, setCommentValue] = useState('')

  const getCommentRequests = () => {
    API
      .get('baseRequestURL', `request/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/comments/${data.requestId}`, { queryStringParameters: { page: 1, perPage: 10 } })
      .then(response => {
        if (response.data) {
          setCommentRequests(response.data.commentData)
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to load comments.')
      })
  }
  useEffect(() => {
    if (user) {
      getCommentRequests()
    }
  }, [])

  const handleInputChange = (event) => {
    const sanitizedComment = event.target.value.replace(/(<([^>]+)>)/gi, '')
    setCommentValue(sanitizedComment)
  }

  const handlePostComment = (event) => {
    event.preventDefault()
    const trimmedCommentValue = commentValue.trim()
    if (trimmedCommentValue?.length > 1) {
      API.post(
        'baseRequestURL',
        `request/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/comments/${data.requestId}`,
        { body: { comment: trimmedCommentValue } }
      )
        .then((response) => {
          setCommentRequests((commentRequests) => [response?.data?.commentData, ...commentRequests])
        })
        .catch((error) => {
          showError(error, false, {}, 'Failed to add comment.')
        })
      setCommentValue('')
    }
  }

  const [loading, setLoading] = useState(true)
  if (loading) {
    setTimeout(() => {
      setLoading(false)
    }, 1500)
  }

  if (loading) {
    return <LoadingSkeletonViewReq />
  }
  return (
    <>
      <Grid container>
        <Grid container spacing={2} className='modal_area' sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <Grid container justifyContent='flex-start' alignItems='center' mb={2}>
              <Grid item>
                <Typography variant='h6' style={{ width: '100%', fontWeight: 400, fontFamily: 'Lora', color: '#34475A' }}>
                  View Request
                </Typography>
              </Grid>
              <Grid item>
                <Box className='divider' mx={2} />
              </Grid>
              <Grid item>
                <Typography sx={{ fontSize: '20px' }} fontFamily='Open Sans'>{name}</Typography>
                <Typography fontSize='12px' fontWeight='600' fontFamily='Open Sans'>{selectedItem.userRequestId}  {'->'}  {selectedItem.requestType}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className='form-req'>
          {data.requestType === 'Cash Raise' && (
            <Grid item sm={6} sx={{ mb: 2 }}>
              <FormLabel className='viewReq account-request-label'>Amount</FormLabel>
              <FormControl fullWidth>
                <Typography>$ {formatCurrency(data?.amount)}</Typography>
              </FormControl>
            </Grid>
          )}
          {data.requestType === 'YTD External Gain/Loss' && (
            <>
              <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                <FormLabel className='viewReq account-request-label'>Long Gain/Loss</FormLabel>
                <Typography>{data.ytdRealLongGains < 0 ? 'Loss' : 'Gain'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                <FormLabel className='viewReq account-request-label'>Long Gain/Loss Amount</FormLabel>
                <FormControl fullWidth>
                  <Typography>$ {formatCurrency(data.ytdRealLongGains)}</Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                <FormLabel className='viewReq account-request-label'>Short Gain/Loss</FormLabel>
                <Typography>{data.ytdRealShortGains < 0 ? 'Loss' : 'Gain'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                <FormLabel className='viewReq account-request-label'>Short Gain/Loss Amount</FormLabel>
                <FormControl fullWidth>
                  <Typography>$ {formatCurrency(data.ytdRealShortGains)}</Typography>
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item xs={12} sx={{ mb: 1 }}>
            <FormLabel className='viewReq account-request-label'>Comments</FormLabel>
            {commentRequests?.length ? <AllComments data={commentRequests || []} /> : <p>N/A</p>}
            <Box className='reqEditCommnet'>
              <FormControl fullWidth>
                <Input
                  className='form-control'
                  placeholder='Write a Comment here'
                  required
                  sx={{ paddingX: '10px' }}
                  value={commentValue}
                  onChange={handleInputChange}
                />
              </FormControl>
              <img
                className='sendIcon' src={sendIcon} alt='' onClick={handlePostComment}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
