import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, List } from '@mui/material'
import { KeyboardArrowLeft } from '@mui/icons-material'
import 'rc-slider/assets/index.css'
import arisLogoA from '../assets/images/ArisLogoA.svg'
import NewArisLogoWhite from '../assets/images/arisNewLogoWhite.svg'
import { useAuth } from '../contexts/AuthContext'
import { useMenuConfig } from '../hooks/useMenuConfig'
import { ExpandCollapseIcon } from './components/ArrowButtons'
import NonSubmenuItems from './NonSubmenuItems'
import SubMenuItems from './SubMenuItems'

const PreviewLeftSidebar = ({ leftSidebarProps }) => {
  const { user } = useAuth()
  const sidebarMenuList = useMenuConfig()
  const { userGroup } = useSelector((state) => state.user)
  const [userGrp, setUserGrp] = useState(user?.userGroup || userGroup)
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  const toggleSidebar = () => {
    setIsSidebarOpen(prev => !prev)
  }

  return (
    <Box
      className={`sidebar ${isSidebarOpen ? 'open' : ''}`}
      sx={{
        backgroundColor: userGrp === 'admin' && leftSidebarProps ? leftSidebarProps?.primaryColor : '#002A59',
      }}
      id='sidebar'
    >
      <ExpandCollapseIcon
        className='sidebar-toggle-btn'
        sx={{
          backgroundColor: userGrp === 'admin' && leftSidebarProps ? leftSidebarProps?.primaryColor : '#002A59',
          '&:hover': {
            backgroundColor: userGrp === 'admin' && leftSidebarProps ? leftSidebarProps?.primaryColor : '#002A59'
          }
        }}
        onClick={toggleSidebar}
      >
        <KeyboardArrowLeft
          sx={{ width: '16px', height: '16px', transform: isSidebarOpen ? 'rotate(0deg)' : 'rotate(180deg)' }}
        />
      </ExpandCollapseIcon>
      <Box sx={{
        overflow: 'hidden',
        transition: 'width 0.5s ease',
        width: isSidebarOpen ? '100%' : '50px', // Adjust width based on sidebar state
        display: 'flex',
        justifyContent: 'center',
        height: '60px'
      }}>
        <Box
          className='logo' sx={{
            width: '100%',
            backgroundColor: userGrp === 'admin' && leftSidebarProps ? leftSidebarProps?.primaryColor : '#002A59',
            position: 'sticky',
            top: 0,
            zIndex: 1
          }}
        >
          {(
            userGrp === 'admin' && leftSidebarProps
              ? (
                <img
                  src={leftSidebarProps?.mainLightLogoUrl || NewArisLogoWhite} alt='sponsor-logo' height={40} width={180}
                  loading='eager'
                />
              )
              : (
                <img src={NewArisLogoWhite} alt='aris logo' height={40} width={180} loading='eager' />
              )
          )}
        </Box>
        <Box
          className='logo-tablet'
          sx={{ backgroundColor: userGrp === 'admin' && leftSidebarProps ? leftSidebarProps?.primaryColor : '#002A59' }}
        >
          {(
            userGrp === 'admin' && leftSidebarProps
              ? (
                <img
                  src={leftSidebarProps?.mobLightLogoUrl || arisLogoA} alt='sponsor-logo' loading='eager'
                />
              )
              : (
                <img src={arisLogoA} alt='aris-logo' loading='eager' />
              )
          )}
        </Box>
      </Box>
      <List
        className='menu-list'
        sx={{
          '& .MuiListItemIcon-root': {
            minWidth: 'unset',
            marginTop: '4px',
            marginBottom: '4px',
            lineHeight: '21px'
          },
          // pointerEvents: 'none'
        }}
        disablePadding
      >
        <>
          {sidebarMenuList?.slice(0, 5)?.map((menuItem, index) => (
            menuItem.hasSubMenu
              ? (
                <SubMenuItems
                  key={index}
                  id={menuItem.id}
                  menuId={menuItem.menuId}
                  paths={menuItem.paths}
                  activePath='/account-review/account-dashboard/'
                  icon={menuItem.icon}
                  name={menuItem.name}
                  leftSidebarProps={leftSidebarProps}
                  isOpen={menuItem.moduleCd === 'account-review' && isSidebarOpen}
                  parentModule={menuItem.moduleCd}
                  isSidebarOpen={isSidebarOpen}
                  handleSubmenuToggle={()=>{}}
                  isPreview
                />
              )
              : (
                <NonSubmenuItems
                  key={index}
                  icon={menuItem.icon}
                  linkTo={menuItem.path}
                  leftSidebarProps={leftSidebarProps}
                  label={menuItem.name}
                  id={menuItem.id}
                  isSidebarOpen={isSidebarOpen}
                  handleSubmenuToggle={() => { }}
                  isPreview
                />
              )
          )
          )}
        </>
      </List>
    </Box>
  )
}

export default PreviewLeftSidebar
