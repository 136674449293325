import { forwardRef, useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Box, Card, CardContent, Chip, Divider, IconButton, MenuItem, Select, Skeleton, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { TabContext, TabPanel } from '@mui/lab'
import { useAuth } from '../../../../../contexts/AuthContext'
import { useErrorToast } from '../../../../../hooks/useErrorToast'
import ViewOptProperties from '../../ViewOptproperties'
import TradePolicyStatus from './TradePolicyStatus'
import { assetAllocationDataProcess } from '../../../../Report/DataFormatting'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '../../../../ErrorFallback'
import TradeSummaryChart from '../../../components/TradeSummaryPopup/TradeSummaryChart'
import TradeMarketCapChart from '../../../components/TradeSummaryPopup/TradeMarketCapChart'
import TradeFactorChart from '../../../components/TradeSummaryPopup/TradeFactorChart'
import TradeSectorTable from '../../../components/TradeSummaryPopup/TradeSectorTable'
import ViewTradeDetails from '../../ViewTradeDetails'
import RGL, { WidthProvider } from 'react-grid-layout'
import { RiDragMove2Line } from 'react-icons/ri'
import SouthEastIcon from '@mui/icons-material/SouthEast'
import 'react-resizable/css/styles.css'
import 'react-grid-layout/css/styles.css'

const ReactGridLayout = WidthProvider(RGL)

// const CustomResizeHandler = forwardRef((props, ref) => {
//   const { handleAxis } = props;
//   return <div ref={ref}
//     className={`react-resizable-handle react-resizable-handle-${handleAxis}`}
//   >
//   </div>;
// });

const TradeDetailsCompactPopup = (props) => {
  const {
    onClose,
    row: currentSelectedRow
  } = props
  const { user, checkAccess } = useAuth()
  const { showError } = useErrorToast()
  const [selectedScenario, setSelectedScenario] = useState(currentSelectedRow?.scenarioDesc || '')
  const [isPolicyLoading, setIsPolicyLoading] = useState(Array.from({ length: 4 }).fill(true))
  const [policyData, setPolicyData] = useState(Array.from({ length: 4 }).fill({}))
  const [value, setValue] = useState('Trade Summary')
  const [tradeSummaryLoading, setTradeSummaryLoading] = useState(true)
  const [tradeSummaryData, setTradeSummaryData] = useState()
  const viewDetailsTabs = ['Trade Summary', 'Trade Details']
  const accountSummaryData = {
    initTe: currentSelectedRow?.initTe,
    propTe: currentSelectedRow?.propTe,
    taxCost: currentSelectedRow?.taxCost,
    totalTurnover: currentSelectedRow?.totalTurnover,
    tradeAge: currentSelectedRow?.tradeAge,
    ModelChanged: currentSelectedRow?.isModelChanged ? 'YES' : 'NO',
    initNumHoldings: currentSelectedRow?.initNumHoldings,
    propNumHoldings: currentSelectedRow?.propNumHoldings,
    Buy: currentSelectedRow?.buy,
    Sell: currentSelectedRow?.sell,
    initRisk: currentSelectedRow?.initRisk,
    preOptAlpha: currentSelectedRow?.preOptAlpha,
    initUtil: currentSelectedRow?.initUtil,
    portMv: currentSelectedRow?.portMv,
    propLongRgl: currentSelectedRow?.propLongRgl,
    propShortRgl: currentSelectedRow?.propShortRgl,
    initLtUrgl: currentSelectedRow?.initLtUrgl,
    initStUrgl: currentSelectedRow?.initStUrgl,
    propLtUrgl: currentSelectedRow?.propLtUrgl,
    propStUrgl: currentSelectedRow?.propStUrgl
  }

  const scenarioChangeHandler = (e) => {
    setSelectedScenario(e.target.value)
  }

  const getTradeSummaryData = async () => {
    try {
      const response = await API.get('baseOptimizationURL', `optimization/v1/${user?.userGroup}/asset-allocations/${currentSelectedRow?.accountId}`)
      if (response && response.data) {
        setTradeSummaryData({ ...assetAllocationDataProcess(response), optFeasibilityReport: response?.data?.optFeasibilityReport })
      }
      setTradeSummaryLoading(false)
    } catch (error) {
      setTradeSummaryLoading(false)
      showError(error, false, {}, 'Failed to load summary data.')
    }
  }

  const fetchPolicy = (accOptMapId, policySource, index) => {
    setIsPolicyLoading(prev => (
      prev.map((_, i) => (i === index ? true : _))
    ))
    API.post('baseDataValidation', `data-validation/v1/${user.userGroup}/policies/trades`, {
      body: {
        accountOptMapIds: [accOptMapId],
        policySource
      }
    })
      .then(res => {
        if (res?.data?.length) {
          setPolicyData(prev => (
            prev.map((_, i) => (i === index ? res?.data[0] : _))
          ))
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load policy data.')
      })
      .finally(() => {
        setIsPolicyLoading(prev => (
          prev.map((_, i) => (i === index ? false : _))
        ))
      })
  }

  const getPolicyBreackCheck = async (accMapId, scenarioDesc, index) => {
    setIsPolicyLoading(prev => (
      prev.map((_, i) => (i === index ? true : _))
    ))
    API.get(
      'baseDataValidation',
      `data-validation/v1/${user?.userGroup
      }/policies/checks/${accMapId}/scenario/${scenarioDesc
        .split(' ')
        .join('')}`
    )
      .then((response) => {
        if (response?.data) {
          setPolicyData(prev => (
            prev.map((_, i) => (i === index ? { policies: response?.data } : _))
          ))
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load policy break check data.')
      })
      .finally(() => {
        setIsPolicyLoading(prev => (
          prev.map((_, i) => (i === index ? false : _))
        ))
      })
  }

  useEffect(() => {
    if (user) {
      ['ACCOUNT_POLICY', 'TRADE_POLICY', 'STRATEGY_POLICY']
        .map((policy, index) => fetchPolicy(currentSelectedRow?.accOptMapId, policy, index + 1))
      getPolicyBreackCheck(currentSelectedRow?.accOptMapId, currentSelectedRow?.scenarioDesc, 0)
    }
  }, [user])

  const renderTabPanel = (item) => {
    switch (item) {
      case 'Trade Summary':
        return (
          <Box sx={{ position: 'relative' }}>
            <ReactGridLayout
              compactType={null}
              containerPadding={[0, 0]}
              margin={[16, 16]}
              cols={12}
              rowHeight={50}
              draggableHandle='.drag-handle'
            >
              <Card
                className='react-grid-layout-card' data-grid={{
                  isBounded: true,
                  x: 0,
                  y: 0,
                  w: 3,
                  h: 7,
                  i: '1'
                }} key='1'
              >
                <CardContent sx={{ p: '8px !important', height: '100%', overflow: 'auto' }}>
                  <RiDragMove2Line className='drag-handle' color='#00000066' size={16} />
                  <Typography variant='h8' sx={{ color: '#74788D', fontWeight: '600' }}>Account Summary</Typography>
                  <Table className='compact-table'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Field</TableCell>
                        <TableCell>Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        Object.keys(accountSummaryData).map(key => (
                          <TableRow key={key}>
                            <TableCell>{key}</TableCell>
                            <TableCell>{accountSummaryData[key] || 'N/A'}</TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
              <Card
                className='react-grid-layout-card' data-grid={{
                  isBounded: true,
                  w: 6,
                  h: 8,
                  x: 0,
                  y: 13,
                  i: '2'
                }} key='2'
              >
                <CardContent sx={{ p: '8px !important', height: '100%', overflow: 'auto' }}>
                  <RiDragMove2Line className='drag-handle' color='#00000066' size={16} />
                  <Typography sx={{ color: '#74788D', fontWeight: '600' }}>Personalization</Typography>
                  <ViewOptProperties
                    index={currentSelectedRow?.accOptMapId}
                    optId={currentSelectedRow?.optId}
                    showCompact
                  />
                </CardContent>
              </Card>
              <Card
                className='react-grid-layout-card' data-grid={{
                  isBounded: true,
                  w: 6,
                  h: 8,
                  x: 6,
                  y: 13,
                  i: '3'
                }} key='3'
              >
                <CardContent sx={{ p: '8px !important', height: '100%', overflow: 'auto' }}>
                  <RiDragMove2Line className='drag-handle' color='#00000066' size={16} />
                  <TradePolicyStatus loading={isPolicyLoading} policyData={policyData} />
                </CardContent>
              </Card>
              <Card
                className='react-grid-layout-card' data-grid={{
                  isBounded: true,
                  w: 4,
                  h: 7,
                  x: 3,
                  y: 0,
                  i: '4'
                }} key='4'
              >
                <CardContent sx={{ p: '8px !important', height: '100%' }}>
                  <RiDragMove2Line className='drag-handle' color='#00000066' size={16} />
                  <Typography sx={{ color: '#74788D', fontWeight: '600' }}>Trade Summary</Typography>
                  <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen='not main' />)}>
                    <TradeSummaryChart
                      fArray={props.data}
                      accountData={[currentSelectedRow?.accOptMapId,
                        currentSelectedRow?.scenarioDesc,
                        currentSelectedRow?.dIndex,
                        currentSelectedRow?.accountId]}
                      hideCheckbox
                      chartHeight='350px'
                    />
                  </ErrorBoundary>
                </CardContent>
              </Card>
              <Card
                className='react-grid-layout-card' data-grid={{
                  isBounded: true,
                  w: 6,
                  h: 6,
                  x: 6,
                  y: 7,
                  i: '5'
                }} key='5'
              >
                <CardContent sx={{ p: '8px !important', height: '100%' }}>
                  <RiDragMove2Line className='drag-handle' color='#00000066' size={16} />
                  <Typography sx={{ color: '#74788D', fontWeight: '600' }}>Marketcap Allocation</Typography>
                  {tradeSummaryLoading
                    ? <Skeleton variant='rectangular' height={350} width='100%' />
                    : <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen='not main' />)}>
                      <TradeMarketCapChart marketCapObj={{ marketCapLabels: tradeSummaryData?.marketCapLabels, marketCapData: tradeSummaryData?.marketCapData || [] }} selectedScenario={currentSelectedRow?.scenarioDesc} chartHeight='350px' />
                      </ErrorBoundary>}
                </CardContent>
              </Card>
              <Card
                className='react-grid-layout-card' data-grid={{
                  isBounded: true,
                  w: 6,
                  h: 6,
                  x: 0,
                  y: 7,
                  i: '6'
                }} key='6'
              >
                <CardContent sx={{ p: '8px !important', height: '100%' }}>
                  <RiDragMove2Line className='drag-handle' color='#00000066' size={16} />
                  <Typography sx={{ color: '#74788D', fontWeight: '600' }}>Factor Allocation</Typography>
                  {tradeSummaryLoading
                    ? <Skeleton variant='rectangular' height={350} width='100%' />
                    : <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen='not main' />)}>
                      <TradeFactorChart factorChartObj={{ factorLabels: tradeSummaryData?.factorLabels, factorData: tradeSummaryData?.factorData || [] }} selectedScenario={currentSelectedRow?.scenarioDesc} chartHeight='350px' />
                      </ErrorBoundary>}
                </CardContent>
              </Card>
              <Card
                className='react-grid-layout-card' data-grid={{
                  isBounded: true,
                  w: 5,
                  h: 7,
                  x: 7,
                  y: 0,
                  i: '7'
                }} key='7'
              >
                <CardContent sx={{ p: '8px !important', height: '100%', overflow: 'auto' }}>
                  <RiDragMove2Line className='drag-handle' color='#00000066' size={16} />
                  <Typography sx={{ color: '#74788D', fontWeight: '600' }}>Sector Allocation</Typography>
                  {tradeSummaryLoading
                    ? <Skeleton variant='rectangular' height='100%' width='100%' />
                    : <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen='not main' />)}>
                      <TradeSectorTable sectorDataObj={{ sectorLabels: tradeSummaryData?.sectorLabels, sectorData: tradeSummaryData?.sectorData || [], sectorDataTableColumnMapping: tradeSummaryData?.sectorDataTableColumnMapping || {} }} showCompact />
                      </ErrorBoundary>}
                </CardContent>
              </Card>
            </ReactGridLayout>
          </Box>
        )
      case 'Trade Details':
        return (
          <ViewTradeDetails
            index={props?.index}
            accID={props?.accID}
            setIsShown={props?.setIsShown}
            optId={props?.optId}
            accountCode={props?.accountCode}
            showTitle={false}
            isDILink={props?.isDILink}
            DILinkType={props?.DILinkType}
          />
        )
      default:
        return <></>
    }
  }

  useEffect(() => {
    if (user) {
      getTradeSummaryData()
    }
  }, [user])

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '10px' }}>
          <Typography variant='h6' style={{ fontWeight: 400, color: '#34475A' }}>Proposed Trade</Typography>
          <Box className='client' mt={{ xs: 3, md: 0 }}>
            <Box sx={{
              borderRadius: '4px',
              width: '3.05px',
              height: '53.8px',
              mx: 2,
              background: '#F05F5F'
            }}
            />
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h6'>{currentSelectedRow?.accountName}</Typography>
                <Chip label={currentSelectedRow?.scenarioDesc} size='small' sx={{ background: '#50CAF1', color: 'white', fontSize: '10px', fontWeight: '500', ml: 1, height: '16px' }} />
              </Box>
              <Typography variant='small' lineHeight='1.25rem' fontSize='0.875rem' mr={1}> {currentSelectedRow?.accountCd}({currentSelectedRow?.custodianAccountNumber || ''})</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
          {
            currentSelectedRow?.optDetails?.length > 1
              ? (
                <Select
                  size='small'
                  value={selectedScenario}
                  onChange={scenarioChangeHandler}
                  IconComponent={ExpandMoreIcon}
                  sx={{
                    height: '32px',
                    width: '150px',
                    fontSize: '14px',
                    backgroundColor: '#ededed',
                    borderRadius: '8px',
                    '.MuiSelect-select': {
                      color: '#2f2f2f'
                    }
                  }}
                >
                  {
                    currentSelectedRow?.optDetails?.map(scenario => (
                      <MenuItem value={scenario?.scenarioDesc} sx={{ fontSize: '14px' }}>{scenario?.scenarioDesc}</MenuItem>
                    ))
                  }
                </Select>
                )
              : (
                <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>
                  {currentSelectedRow?.scenarioDesc || ''}
                </Typography>
                )
          }
          {/* {
          checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.APPROVAL1 }) || checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.APPROVAL2 })
            ? isLoading
              ? (
                <LoadingButton
                  loading
                  loadingPosition='start'
                  startIcon={<SaveIcon />}
                  variant='outlined'
                  sx={{
                    fontSize: '16px',
                    width: '145px'
                  }}
                >
                  Approve
                </LoadingButton>
              )
              : (
                <Button
                  className='tardeApprove'
                  variant='contained'
                  // onClick={handleApprove}
                  startIcon={<img src={approveIcon} alt='approve' />}
                >
                  Approve
                </Button>)
            : ''
        } */}
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <TabContext value={value}>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
          <Tabs
            value={value}
            onChange={(_, newValue) => setValue(newValue)}
            variant='standard'
            scrollButtons
            className=''
            sx={{ minHeight: '32px' }}
            indicatorColor='#00000066'
          >
            {
              viewDetailsTabs?.map((item, index) => (
                <Tab
                  key={index}
                  value={item}
                  label={item}
                  sx={{
                    fontSize: '14px !important',
                    padding: '6px 8px',
                    minHeight: '32px',
                    marginRight: '10px',
                    background: value === item ? '#74788D' : 'white',
                    color: value === item ? 'white !important' : '#74788D',
                    border: '1px solid #74788D',
                    borderRadius: '5px',
                    textTransform: 'none'
                  }}
                />
              ))
            }
          </Tabs>
          {/* <Divider sx={{
            bgcolor: 'rgba(116, 120, 141, 0.15)',
            width: '100%'
          }}
          /> */}
        </Box>
        <Box>
          {viewDetailsTabs?.map((item, index) => (
            <TabPanel
              key={index}
              index={index}
              value={item}
              sx={{ py: 2, px: 0 }}
            >
              {renderTabPanel(item)}
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    </>
  )
}

export default TradeDetailsCompactPopup