import { TextField } from '@mui/material'

const CustomNumberField = (props) => {
  const { id, name, label, formik, placeholder, disabled, value, onChange, error, helperText, defaultAccountDetails, ...otherProps } = props
  return (
    <TextField
      id={id}
      name={name}
      type='number'
      size='small'
      label={label}
      disabled={disabled}
      value={value}
      onChange={onChange}
      onKeyDown={(event) => {
        if (event.key === '-') {
          event.preventDefault()
        }
      }}
      error={error}
      helperText={helperText}
      InputProps={{
        inputProps: {
          step: '0.1',
          min: 0
        }
      }}
      sx={{ width: '100%', marginTop: '0px' }}
      {...otherProps}
    />
  )
}

export default CustomNumberField
