import { MenuItem, Select } from '@mui/material'

const StatusUpdateCell = (params) => {
  const { id, field, value, selectedTrades, isBlockTrade } = params
  const childRowIds = params?.api?.getRowGroupChildren({
    groupId: id
  })
  const oldRow = params.api.getRow(id)
  const updatedRow = params.api.getRowWithUpdatedValues(id)
  const isCellDisable = () => {
     if (isBlockTrade && !childRowIds?.length) {
      return true
     }
    // if overall trade is completed, then disable fix connectivity status edit
     else if (field === 'fixFlyerStatus' && oldRow.fixFlyerStatus === 'TRADE_COMPLETED') {
      return true
     }
    // if overall trade is not completed or allocation is completed, then disable block allocation status edit
     else if (field === 'blockAllocStatus' && (updatedRow.fixFlyerStatus !== 'TRADE_COMPLETED' || oldRow.blockAllocStatus === 'ALLOCATION_COMPLETED')) {
      return true
    }
    return false
  }

  const handleStatusChange = (event) => {
    const newValue = event.target.value
    // update only child row if block trade status is edited
    if (field === 'blockAllocStatus') {
      params?.api?.setEditCellValue({ id, field, value: newValue })
      if (childRowIds?.length) {
        childRowIds?.forEach((id) => {
          params?.api?.setEditCellValue({ id, field, value: newValue })
        })
      }
    }
    // if fixFlyerStatus is changed then updated for all selected rows
    else {
      if (selectedTrades.includes(id)) {
        params?.api?.getAllRowIds().forEach(rowId => {
          if (selectedTrades.includes(rowId)) {
            params?.api?.setEditCellValue({ id: rowId, field, value: newValue }, event)
          }
        })
      } else {
        params?.api?.setEditCellValue({ id, field, value: newValue })
        if (childRowIds?.length) {
          childRowIds?.forEach((id) => {
            params?.api?.setEditCellValue({ id, field, value: newValue })
          })
        }
      }
    }
  }

  return (
    <Select
      margin='dense'
      size='small'
      fullWidth
      id={`${field}-${params?.row?.id}`}
      name={`${field}-${params?.row?.id}`}
      value={value === 'NA' ? '' : (value || '')}
      onChange={handleStatusChange}
      disabled={isCellDisable()}
    >
      <MenuItem value=''>Select</MenuItem>
      {
        (params?.statusList || [])?.map((option, index) => (<MenuItem value={option} key={index}>{option}</MenuItem>))
      }
    </Select>
  )
}

export default StatusUpdateCell
