import { TextField, MenuItem } from '@mui/material'

const CustomSelectField = (props) => {
  const { id, name, label, options = [], placeholder, value, disabled, error, onChange, helperText, getOptionLabel, getOptionValue } = props
  return (
    <TextField
      id={id}
      name={name}
      type='text'
      size='small'
      select
      label={label}
      value={value}
      disabled={disabled}
      onChange={onChange}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      sx={{ width: '100%', marginTop: '0px' }}
    >
      {options.length > 0
        ? (
            options.map((option, index) => (
              <MenuItem key={index} value={getOptionValue ? getOptionValue(option) : option}>
                {getOptionLabel ? getOptionLabel(option) : option}
              </MenuItem>
            ))
          )
        : (
          <MenuItem disabled>No options available</MenuItem>
          )}
    </TextField>
  )
}

export default CustomSelectField
