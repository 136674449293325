import { useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import { API } from 'aws-amplify'
import dayjs from 'dayjs'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import CloseIcon from '@mui/icons-material/Close'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import SettingsIcon from '@mui/icons-material/Settings'
import {
  Box, Button, Card, CardContent,
  Divider, Fade,
  IconButton, Popover,
  Skeleton, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow,
  Typography
} from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { DateCalendar, DateRangeCalendar, PickersDay } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import CustomKeyFieldSearch from '../../components/CustomKeyFieldSearch'
import OutlinedButton from '../../components/OutlinedButton'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import Loader from '../Loader'
import ColumnSelectionPopup from './ColumnSelectionPopup'

const DATE_FORMAT = 'YYYY-MM-DD'
const MAX_DATE = dayjs.utc().subtract(1, 'day')

const renderTableSkeleton = (header, hiddenColumns) => {
  if (hiddenColumns) { header = header.filter(col => !hiddenColumns.includes(col.field)) }
  return (
    <TableContainer mt={5}>
      <Table className='risk-page-table'>
        <TableHead>
          <TableRow>
            {header.map((item, index) => {
              return (
                <TableCell key={index}>{item.headerName}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, index) => (
            <TableRow key={index}>
              {Array.from({ length: header.length }).map((_, index) => (
                <TableCell key={index}>
                  <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                </TableCell>))}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const AumReport = () => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [isAccountsLoading, setIsAccountsLoading] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [copyAccountData, setCopyAccountData] = useState([])
  const [selectedAccounts, setSelectedAccounts] = useState([])
  const [isDownloading, setIsDownloading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedDates, setSelectedDates] = useState([])
  const [showDateRange, setShowDateRange] = useState(false)
  const [showColumnSelectionPopup, setShowColumSelectionPopup] = useState(false)
  const [latestDefaultDate, setLatestDefaultDate] = useState(null)
  const [isColumnLoading, setIsColumnLoading] = useState([])
  const [fileColumns, setFileColumns] = useState([])

  const getAccountsList = () => {
    setIsAccountsLoading(true)
    API.post('baseUriReport', `reporting/v1/${user?.userGroup}/aum-summary/accounts`)
      .then(response => {
        const latestDate = dayjs(response?.data?.accountLatestDataDate ?? undefined)
        setLatestDefaultDate(latestDate)
        setSelectedDates([latestDate])
        if (response?.data?.accountsSummary) {
          setAccounts([...response?.data?.accountsSummary])
          setCopyAccountData([...response?.data?.accountsSummary])
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to load account data.')
      })
      .finally(() => {
        setIsAccountsLoading(false)
      })
  }

  const getAllReportColumn = () => {
    setIsColumnLoading(true)
    API.post('baseUriReport', `reporting/v1/${user?.userGroup}/aum-summary/field`)
      .then(res => {
        if (res?.data) {
          setFileColumns(res?.data)
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to load report columns.')
      })
      .finally(() => {
        setIsColumnLoading(false)
      })
  }

  useEffect(() => {
    if (user) {
      getAccountsList()
      getAllReportColumn()
    }
  }, [user])

  const handleSelectionChange = (value) => {
    setSelectedAccounts(value)
  }

  const openCalendarPopup = (e) => {
    setAnchorEl(e.currentTarget)
    if (!selectedDates.length) {
      if (showDateRange) {
        setSelectedDates([latestDefaultDate, latestDefaultDate])
      } else {
        setSelectedDates([latestDefaultDate])
      }
    }
  }

  const closeCalendarPopup = (e) => {
    setAnchorEl(null)
  }

  const handleDateSelect = (date) => {
    if (showDateRange) {
      setSelectedDates(date)
    } else {
      // select date if not selected else deselect
      const doesDateExists = selectedDates.some((selectedDate) => selectedDate.isSame(date))
      if (!doesDateExists) {
        setSelectedDates(prev => [...prev, date])
      } else {
        setSelectedDates(prev => prev.filter(selectedDate => !selectedDate.isSame(date)))
      }
    }
  }

  const columns = [
    {
      field: 'accountName',
      headerName: 'Name',
      flex: 1
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      flex: 1,
      valueGetter: (params) => params.value?.toLowerCase()?.charAt(0)?.toUpperCase() + params.value?.slice(1)?.toLowerCase()
    },
    ...(user?.userGroup === 'pm'
      ? [{
        field: 'sponsorName',
        headerName: 'Sponsor Name',
        flex: 1
      }]
      : []),
    {
      field: 'accountStartDate',
      headerName: 'Start Date',
      flex: 1,
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.accountStartDate ? params?.row?.accountStartDate?.split('T')[0] : ''
    },
    {
      field: 'accountEndDate',
      headerName: 'End Date',
      flex: 1,
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.accountEndDate ? params?.row?.accountEndDate?.split('T')[0] : ''
    },
    {
      field: 'billStartDate',
      headerName: 'Bill Start Date',
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.billStartDate ? params?.row?.billStartDate?.split('T')[0] : ''
    },
    {
      field: 'isTaxable',
      headerName: 'Taxable',
      flex: 1,
      valueGetter: (params) => (params.value === 0 ? 'No' : 'Yes')
    },
    {
      field: 'accountCode',
      headerName: 'Account Code',
      flex: 1
    },
    {
      field: 'portSpecName',
      headerName: 'Model',
      flex: 1
    },
    {
      field: 'totalMarketValue',
      headerName: 'Total Market Value',
      flex: 1,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
      renderCell: (params) => (
        params?.row?.totalMarketValue !== undefined
          ? params?.row?.totalMarketValue !== null
            ? params?.row?.totalMarketValue < 0
              ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
              : '$' + Number(params?.value)?.toLocaleString()
            : 'NA'
          : ''
      )
    }
  ]

  const downloadReport = () => {
    if (showDateRange && selectedDates.length && (!dayjs(selectedDates[0]).isValid() || !dayjs(selectedDates[1]).isValid())) {
      showError('Start date or end date is not valid.')
      return
    }
    setIsDownloading(true)
    API.post('baseUriReport', `reporting/v1/${user?.userGroup}/aum-summary/download`, {
      body: {
        accountId: selectedAccounts,
        ...(showDateRange && selectedDates.length >= 2 ? {
          startDate: selectedDates[0]?.format(DATE_FORMAT),
          endDate: selectedDates[1]?.format(DATE_FORMAT)
        } : {
          dates: selectedDates.length ? selectedDates.map(date => date?.format(DATE_FORMAT)) : [latestDefaultDate.format(DATE_FORMAT)]
        }),
        columns: fileColumns.filter(col => col?.isDefault).map(col => col?.field)
      }
    })
      .then((res) => {
        if (res?.data?.length) {
          // remove account_id key from the data
          const data = res?.data?.map(obj => {
            const { account_id, ...newObj } = obj
            return newObj
          })

          // create a worksheet from json
          // object keys become column header
          const worksheet = XLSX.utils.json_to_sheet(data)

          // create a new workbook
          const workbook = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Report')

          // converts the workbook to binary data in the XLSX format
          const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })

          // creates a Blob from the array buffer with MIME type of excel xlsx files
          const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

          const url = URL.createObjectURL(blob)

          const a = document.createElement('a')
          a.href = url
          a.download = 'AUM Report.xlsx'
          a.click()

          URL.revokeObjectURL(a)
          showSuccess('Report downloaded successfully.')
        } else {
          showError(res?.message)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to download AUM report.')
      })
      .then(() => {
        setIsDownloading(false)
      })
  }

  const removeSelectedDate = (index) => {
    setSelectedDates(prev => prev.filter((_, i) => i !== index))
  }

  const isDownloadBtnDisabled = !selectedAccounts.length || isColumnLoading

  const toggleRange = () => {
    setShowDateRange(prev => !prev)
    if (showDateRange) {
      setSelectedDates([latestDefaultDate])
    } else {
      setSelectedDates([latestDefaultDate, latestDefaultDate])
    }
  }

  const resetSelectedDates = () => {
    if (showDateRange) {
      setSelectedDates([latestDefaultDate, latestDefaultDate])
    } else {
      setSelectedDates([latestDefaultDate])
    }
  }

  const openColumnSelectionPopup = () => {
    setShowColumSelectionPopup(true)
  }

  const closeColumnSelectionPopup = () => {
    setShowColumSelectionPopup(false)
  }

  return (
    <>
      {isDownloading ? <Loader /> : ''}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
        <Typography component='h3' className='page-title' sx={{ marginRight: '20px', whiteSpace: 'nowrap' }}>AUM Report</Typography>
      </Box>
      <Box>
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <CustomKeyFieldSearch
                filterData={(data) => {
                  setAccounts(data)
                }}
                fieldName='accountName'
                tableData={copyAccountData}
                fieldLabel='Name'
              />
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <OutlinedButton
                  onClick={openColumnSelectionPopup}
                  tooltipText='Manage Columns'
                  disabled={isColumnLoading}
                >
                  <SettingsIcon fontSize='small' sx={{ color: '#74788D' }} />
                </OutlinedButton>
                <OutlinedButton
                  onClick={openCalendarPopup}
                  disabled={isAccountsLoading}
                  tooltipText='Select Date'
                >
                  <CalendarMonthIcon sx={{ color: '#74788D' }} fontSize='small' />
                </OutlinedButton>
                <Button
                  sx={{ textTransform: 'none' }}
                  onClick={downloadReport}
                  startIcon={<FileDownloadOutlinedIcon sx={{ color: isDownloadBtnDisabled ? '#00000042' : '#fff', fontSize: '16px' }} />}
                  disabled={isDownloadBtnDisabled}
                  variant='contained'
                >
                  Export
                </Button>
              </Box>
            </Box>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={closeCalendarPopup}
              TransitionComponent={Fade}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {
                      showDateRange ? (
                        <DateRangeCalendar
                          value={selectedDates}
                          onChange={handleDateSelect}
                          calendars={1}
                          maxDate={MAX_DATE}
                          sx={{
                            width: '320px',
                            maxWidth: '320px',
                            height: '334px',
                            '& .MuiDateRangeCalendar-monthContainer': {
                              width: '100%'
                            },
                            '& .MuiDateRangePickerDay-day': {
                              transform: 'scale(1)'
                            }
                          }}
                        />
                      ) : (
                        <DateCalendar
                          value={selectedDates[0] || null}
                          onChange={handleDateSelect}
                          maxDate={MAX_DATE}
                          slots={{
                            day: CustomDayPicker
                          }}
                          slotProps={{
                            day: {
                              selectedDates
                            }
                          }}
                        />
                      )
                    }
                  </LocalizationProvider>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px', gap: '10px' }}>
                    <Button
                      variant='text'
                      onClick={toggleRange}
                    >
                      Select {showDateRange ? 'individual' : 'Range'}
                    </Button>
                    <Button
                      variant='text'
                      onClick={resetSelectedDates}
                    >
                      Reset
                    </Button>
                  </Box>
                </Box>
                <Divider orientation='vertical' sx={{ height: 'auto' }} />
                <Box sx={{ width: '150px', p: '16px' }}>
                  {
                    showDateRange ? (
                      <Box sx={{ color: '#34475A' }}>
                        <Box>
                          <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Start Date</Typography>
                          <Typography>{(selectedDates.length && selectedDates[0]) ? selectedDates[0]?.format(DATE_FORMAT) : '-'}</Typography>
                        </Box>
                        <Box sx={{ mt: '10px' }}>
                          <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>End Date</Typography>
                          <Typography>{(selectedDates.length > 1 && selectedDates[1]) ? selectedDates[1]?.format(DATE_FORMAT) : '-'}</Typography>
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <Typography sx={{ color: '#34475A', fontSize: '14px', fontWeight: 600, pb: '5px' }}>Selected Dates</Typography>
                        <Box sx={{ overflow: 'auto', height: '320px' }}>
                          {
                            selectedDates.map((selectedDate, index) => (
                              <Box
                                key={index}
                                sx={{
                                  display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                                  mt: '5px'
                                }}>
                                <Typography sx={{ fontSize: '14px', color: '#34475A' }}>
                                  {selectedDate?.format(DATE_FORMAT)}
                                </Typography>
                                <IconButton onClick={() => removeSelectedDate(index)} sx={{ p: '4px' }}>
                                  <CloseIcon fontSize='small' sx={{ color: '#74788D' }} />
                                </IconButton>
                              </Box>
                            ))
                          }
                        </Box>
                      </>
                    )
                  }
                </Box>
              </Box>
            </Popover>
            <Box sx={{
              height: 'calc(100vh - 48px - 15px - 30px - 40px - 16px - 16px - 16px - 40px)',
              overflow: 'auto',
              scrollbarWidth: 'none'
            }}
            >
              {
                isAccountsLoading
                  ? renderTableSkeleton(columns)
                  : <DataGridPro
                    density='compact'
                    rows={accounts}
                    columns={columns}
                    disableRowSelectionOnClick
                    checkboxSelection
                    keepNonExistentRowsSelected
                    rowSelectionModel={selectedAccounts}
                    onRowSelectionModelChange={handleSelectionChange}
                    getRowId={(row) => row?.accountId}
                    autoHeight
                    pageSizeOptions={[15, 25, 50, 100]}
                    initialState={{
                      ...accounts?.initialState,
                      pagination: { paginationModel: { pageSize: 25 } }
                    }}
                    pagination
                    sx={(theme) => ({
                      [`.${gridClasses.main}`]: {
                        overflow: 'unset'
                      },
                      [`.${gridClasses.columnHeaders}`]: {
                        position: 'sticky',
                        backgroundColor: theme.palette.background.paper,
                        top: '0px',
                        zIndex: 1
                      },
                      [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                        color: '#74788d',
                        fontWeight: 600
                      },
                      [`.${gridClasses.footerContainer}`]: {
                        position: 'sticky',
                        bottom: '-1px',
                        backgroundColor: theme.palette.background.paper,
                        zIndex: 1
                      },
                      border: 'none',
                      fontFamily: 'Open Sans'
                    })}
                  />
              }
            </Box>
          </CardContent>
        </Card>
      </Box>
      {
        showColumnSelectionPopup ? (
          <ColumnSelectionPopup
            open={showColumnSelectionPopup}
            onClose={closeColumnSelectionPopup}
            columns={fileColumns}
            setColumns={setFileColumns}
          />
        ) : ''
      }
    </>
  )
}

const CustomDayPicker = (props) => {
  const { day, selectedDates, ...otherProps } = props
  const isSelected = selectedDates.some((date) => date.isSame(day))
  return (
    <Box sx={{
      border: '2px solid transparent',
      '.MuiPickersDay-root': {
        margin: '0'
      }
    }}>
      <PickersDay
        {...otherProps}
        selected={isSelected}
        day={day}
      />
    </Box>
  )
}

export default AumReport
