import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Box, Chip, Grid, IconButton, Skeleton, Tooltip, Typography } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import PolicyStatusPopup from '../../../components/PolicyStatusPopup'
import { useAuth } from '../../../contexts/AuthContext'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import { useErrorToast } from '../../../hooks/useErrorToast'

const getStatusColor = (status) => {
  if (status === 'green')
    return '#3BBFA3'
  else if (status === 'red')
    return '#FF6161'
  else if (status === 'yellow')
    return '#D29922'
  else if (status === 'gray')
    return '#0000004d'
  return '#c9c9c9'
}

const AggregateTitle = ({ title, name, number, accountCodeShow, loading, slug, chipText }) => {
  const params = useParams()
  const location = useLocation()
  const { user, checkAccess } = useAuth()
  const { showError } = useErrorToast()
  const [showPolicyPopup, setShowPolicyPopup] = useState(false)
  const [policyData, setPolicyData] = useState({})
  const [isPolicyLoading, setIsPolicyLoading] = useState(false)

  const canAccessPolicyCheck = checkAccess(moduleConfig.AGGREGATE, ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.AGGREGATE_DASHBOARD, component_name: moduleConfig.VIEW_UMA_POLICY_CHECK }
  )

  const canDownloadJson = checkAccess(
    moduleConfig.AGGREGATE, ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.AGGREGATE_DASHBOARD, component_name: moduleConfig.DOWNLOAD_UMA_POLICY_CHECK }
  )

  const fetchUMAPolicyCheck = (id) => {
    setIsPolicyLoading(true)
    API.post('baseDataValidation', `data-validation/v1/${user.userGroup}/policies/aggregate`, {
      body: {
        aggIds: [id]
      }
    })
      .then(res => {
        if (res?.data?.length) {
          setPolicyData(res?.data[0])
        }
      })
      .catch(error => showError(error, false, {}, 'Failed to load UMA policy.'))
      .finally(() => setIsPolicyLoading(false))
  }

  useEffect(() => {
    if (user) {
      if (chipText === 'TRADING' && canAccessPolicyCheck) {
        fetchUMAPolicyCheck(params?.aggGroupId)
      }
    }
  }, [user, chipText])

  return (
    <Grid container justifyContent='space-between' alignItems='center' mb={2}>
      <Grid item display='flex' flexDirection={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'flex-start', md: 'center' }}>
        {
          loading
            ? <Skeleton variant='text' sx={{ width: '100px', fontSize: '40px' }} />
            : <Typography component='div' className='page-title' sx={{ paddingTop: '0 !important' }}>{title === 'IPS' ? 'Aggregate' : title}</Typography>
        }
        <Box className='client' mt={{ xs: 3, md: 0 }}>
          <Box sx={{
            borderRadius: '4px',
            width: '3.05px',
            height: '53.8px',
            mx: 2,
            background: '#EFAE3A'
          }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {
              loading
                ? <>
                  <Skeleton variant='text' sx={{ width: '150px', fontSize: '20px' }} />
                </>
                : <>
                  <Typography variant='h6' display='flex' alignItems='center'>
                    {name}
                    {
                      chipText
                        ? <Chip label={chipText} size='small' sx={{ background: '#50CAF1', color: 'white', fontSize: '10px', fontWeight: '500', ml: 1, height: '16px' }} />
                        : ''
                    }
                    {
                      chipText === 'TRADING' && canAccessPolicyCheck && policyData?.aggPolicyColorStatus
                        ? (
                          <Tooltip title='UMA Policies'>
                            <IconButton onClick={() => setShowPolicyPopup(true)} sx={{ p: '6px' }}>
                              <CircleIcon sx={{ color: getStatusColor(policyData?.aggPolicyColorStatus), height: '12px', width: '12px' }} />
                            </IconButton>
                          </Tooltip>
                        )
                        : ''
                    }
                  </Typography>
                </>
            }
            {
              loading
                ? <Skeleton variant='text' sx={{ width: '50px', fontSize: '14px' }} />
                : accountCodeShow
                  ? <>
                    <Box>
                      <Typography variant='small' lineHeight='1.25rem' fontSize='0.875rem' mr={1}> {number || ''}</Typography>
                      <Link to={location.pathname.replace(slug, '')} style={{ color: '#3A76D4' }}>
                        Switch Aggregate
                      </Link>
                    </Box>
                  </>
                  : ''
            }
          </Box>
        </Box>
        {
          chipText === 'TRADING' &&
          <PolicyStatusPopup
            showPolicyPopup={showPolicyPopup}
            onClose={() => setShowPolicyPopup(false)}
            data={policyData}
            canDownloadJson={canDownloadJson}
            fetchPolicy={fetchUMAPolicyCheck}
            fetchPolicyLoading={isPolicyLoading}
            id={params?.aggGroupId}
            title='UMA'
          />
        }
      </Grid>
    </Grid>
  )
}

export default AggregateTitle