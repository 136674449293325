import { useState } from 'react'
import { FilterAlt, FilterAltOutlined } from '@mui/icons-material'
import { Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, IconButton, Popover, Tooltip, Typography } from '@mui/material'

const tradeStatusMapping = {
  'Stopped': 'TRADE_STOPPED',
  'Pending': 'TRADE_PENDING',
  'Sent': 'TRADE_SENT',
  'Inprogress': 'TRADE_INPROGRESS',
  'Partially filled': 'TRADE_PARTIALLY_FILLED',
  'Completed': 'TRADE_COMPLETED'
}

const allocationStatusMapping = {
  'Stopped': 'ALLOCATION_STOPPED',
  'Pending': 'ALLOCATION_PENDING',
  'Sent': 'ALLOCATION_SENT',
  'Inprogress': 'ALLOCATION_INPROGRESS',
  'Partially filled': 'ALLOCATION_PARTIALLY_FILLED',
  'Completed': 'ALLOCATION_COMPLETED'
}

const StatusFilter = ({ applyStatusFilters, isBlockTrade }) => {
  const [selectedFilters, setSelectedFilters] = useState({
    trade: [],
    allocation: []
  })
  const [selectedFiltersCopy, setSelectedFiltersCopy] = useState({
    trade: [],
    allocation: []
  })
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOnStatusChange = (status, type, checked) => {
    if (type === 'trades') {
      if (checked) {
        setSelectedFilters(prevState => ({ ...prevState, trade: [...prevState.trade, status] }))
      } else {
        setSelectedFilters(prevState => ({ ...prevState, trade: prevState.trade.filter((filter) => filter !== status) }))
      }
    } else if (type === 'allocation') {
      if (checked) {
        setSelectedFilters(prevState => ({ ...prevState, allocation: [...prevState.allocation, status] }))
      } else {
        setSelectedFilters(prevState => ({ ...prevState, allocation: prevState.allocation.filter((filter) => filter !== status) }))
      }
    }
  }

  const applyFilters = () => {
    setAnchorEl(null)
    applyStatusFilters(selectedFilters)
    setSelectedFiltersCopy(selectedFilters)
  }

  const closeFilters = () => {
    setAnchorEl(null)
    setSelectedFilters(selectedFiltersCopy)
  }

  return (
    <>
      <Tooltip title='Filter accounts'>
        <IconButton
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{
            padding: 1,
            borderRadius: '10px',
            border: '2px solid #dee2e6'
          }}
        >
          {
            (selectedFiltersCopy?.trade?.length || selectedFiltersCopy?.allocation?.length)
              ? <FilterAlt sx={{ fontSize: '16px' }} />
              : <FilterAltOutlined sx={{ fontSize: '16px' }} />
          }
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box sx={{ width: '230px', p: '16px', pt: '10px' }}>
          <Box sx={{ my: '10px' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Trade Status</Typography>
            <FormGroup>
              {
                Object.keys(tradeStatusMapping).map((tradeStatusKey) => (
                  <FormControlLabel
                    key={tradeStatusKey}
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontSize: '14px',
                        color: '#74788D'
                      },
                      '.MuiCheckbox-sizeSmall': {
                        padding: '5px 9px'
                      }
                    }}
                    control={<Checkbox
                      size='small'
                      checked={selectedFilters?.trade?.includes(tradeStatusMapping[tradeStatusKey])}
                      onChange={(event) => handleOnStatusChange(tradeStatusMapping[tradeStatusKey], 'trades', event.target.checked)}
                    />}
                    label={tradeStatusKey}
                  />
                ))
              }
            </FormGroup>
          </Box>
          {
            isBlockTrade
              ? <>
                <Divider />
                <Box>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600', mt: '10px' }}>Allocation Status</Typography>
                  <FormGroup>
                    {
                      Object.keys(allocationStatusMapping).map((allocationStatusKey) => (
                        <FormControlLabel
                          key={allocationStatusKey}
                          sx={{
                            '.MuiFormControlLabel-label': {
                              fontSize: '14px',
                              color: '#74788D'
                            },
                            '.MuiCheckbox-sizeSmall': {
                              padding: '5px 9px'
                            }
                          }}
                          control={<Checkbox
                            size='small'
                            checked={selectedFilters?.allocation?.includes(allocationStatusMapping[allocationStatusKey])}
                            onChange={(event) => handleOnStatusChange(allocationStatusMapping[allocationStatusKey], 'allocation', event.target.checked)}
                          />}
                          label={allocationStatusKey}
                        />
                      ))
                    }
                  </FormGroup>
                </Box>
              </>
              : ''
          }
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button size='small' onClick={() => closeFilters()} sx={{ fontSize: '14px', textTransform: 'capitalize' }}>
              Cancel
            </Button>
            <Button size='small' onClick={() => applyFilters()} sx={{ fontSize: '14px', textTransform: 'capitalize' }} variant='contained'>
              Apply
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  )
}

export default StatusFilter