import { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import { Autocomplete, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, Menu, MenuItem, Modal, Popover, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { DataGridPro, GridActionsCellItem, gridClasses, GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid-pro'
import { Close, FilterAltOutlined, FilterAlt, FileDownloadOutlined, Edit, Save, Cancel } from '@mui/icons-material'
import { useAuth } from '../../../../contexts/AuthContext'
import { moduleConfig } from '../../../../contexts/data'
import { useAccountService } from '../../../../hooks/useAccountService'
import { useErrorToast } from '../../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../../hooks/useSuccessToast'
import { formatCurrencyWithSymbol } from '../../../../utils/FormateCurrenyInMilion'
import { ACCESS_LEVEL } from '../../../../contstants/constants'
import Loader from '../../../Loader'
import { AggregateContext } from '../../AggregateDashboard'
import { UmaContext } from '..'

const getTradeQuantityColor = (tradeCode) => {
  if (tradeCode === 'SELL') {
    return '#FF6A6A'
  } else if (tradeCode === 'BUY') {
    return '#33A68D'
  } else return 'inherit'
}

const tradeListColumns = [
  {
    headerName: 'Security Name',
    field: 'securityName',
    flex: 1
  },
  {
    headerName: 'Local Symbol',
    field: 'localSymbol',
    flex: 1
  },
  {
    headerName: 'Trade Code',
    field: 'trdCode',
    flex: 1,
    renderCell: (params) => <Box sx={{ color: getTradeQuantityColor(params?.row?.trdCode) }}>{params.value}</Box>
  },
  {
    headerName: 'Source Account',
    field: 'sourceAccountName',
    flex: 1
  },
  {
    headerName: 'Destination Account',
    field: 'destinationAccountName',
    flex: 1
  },
  {
    headerName: 'Trade Quantity',
    field: 'tradeQuantity',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    renderCell: (params) => (
      <Box>
        {params?.row?.tradeQuantity !== null && params?.row?.tradeQuantity !== undefined
          ? params?.row?.tradeQuantity
          : ''}
      </Box>
    )
  },
  {
    headerName: 'Trade Value',
    field: 'tradeValue',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    renderCell: (params) => (
      <Box>
        {params?.row?.tradeValue !== null && params?.row?.tradeValue !== undefined
          ? formatCurrencyWithSymbol(params?.row?.tradeValue, 2, '$')
          : ''}
      </Box>
    )
  }
]

const EnhancedTradeList = ({ data = [], loading }) => {
  const { user, checkAccess } = useAuth()
  const { showError } = useErrorToast()
  const { getGeneralAccountListUtil } = useAccountService()
  const { showSuccess } = useSuccessToast()
  const [downloadCSVAnchorEl, setDownloadCSVAnchorEl] = useState(null)
  const [tradeListData, setTradeListData] = useState(data)
  const [anchorEl, setAnchorEl] = useState(null)
  const [filterModel, setFilterModel] = useState({ srcAccount: null, destAccount: null })
  const [srcAccNames, setSrcAccNames] = useState([])
  const [destAccNames, setDestAccNames] = useState([])
  const [isDownloading, setIsDownloading] = useState(false)
  const [selectedTradeCategory, setSelectedTradeCategory] = useState({ buy: true, sell: true, transfer: true, nop: false })
  const [showValidatePopup, setShowValidatePopup] = useState(false)
  const [isAccountLoading, setIsAccountLoading] = useState(false)
  const [tradingAccounts, setTradingAccounts] = useState([])
  const [tradingUMAs, setTradingUMAs] = useState([])
  const params = useParams()

  const getUniqueDestAccounts = (data) => {
    const lookup = {}; const uniqueDestAccs = []
    data.forEach(obj => {
      if (obj.destinationAccountId !== null) {
        if (lookup[obj.destinationAccountId]) {

        } else {
          lookup[obj.destinationAccountId] = obj.destinationAccountName
          uniqueDestAccs.push({
            destinationAccountId: obj?.destinationAccountId,
            destinationAccountName: obj?.destinationAccountName,
            tradingAccountId: '',
            tradingAccountName: ''
          })
        }
      }
    })
    return uniqueDestAccs
  }

  const uniqueDestAccounts = useRef(getUniqueDestAccounts(data))

  useEffect(() => {
    setSrcAccNames([...new Set(data.map(obj => obj?.sourceAccountName).filter(names => names !== null))])
    setDestAccNames([...new Set(data.map(obj => obj?.destinationAccountName).filter(names => names !== null))])
  }, [data])

  useEffect(() => {
    setTradeListData(data.filter(obj => (
      (filterModel?.srcAccount?.length > 0
        ? filterModel?.srcAccount?.includes(obj?.sourceAccountName)
        : true) &&
      (filterModel?.destAccount?.length > 0
        ? filterModel?.destAccount?.includes(obj?.destinationAccountName)
        : true) &&
      selectedTradeCategory[obj?.trdCode?.toLowerCase()]
    )))
    uniqueDestAccounts.current = getUniqueDestAccounts(data)
  }, [data, selectedTradeCategory, filterModel])

  const handleValidatePopupVisibility = (state) => {
    setShowValidatePopup(state)
  }

  const fetchAccounts = () => {
    setIsAccountLoading(true)
    getGeneralAccountListUtil({
      serviceId: 'book-of-business',
      resourceId: 'book-of-business'
    })
      .then((res) => {
        if (res.data?.accountsSummary) {
          setTradingAccounts(res?.data?.accountsSummary?.filter(acc => acc.accountType === 'TRADING'))
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load accounts list.')
      })
      .finally(() => {
        setIsAccountLoading(false)
      })
  }

  const fetchTradingUMA = () => {
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user?.userGroup}/agg-group`, {
        queryStringParameters: {
          resources: encodeURIComponent(JSON.stringify({
            serviceId: 'aggregate',
            resourceId: 'aggregate-dashboard'
          }))
        }
      }
    )
      .then((response) => {
        if (response?.data?.length > 0) {
          setTradingUMAs(response.data?.filter(obj => obj?.type === 'UMA' && obj?.groupType === 'TRADING'))
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load trading UMA.')
      })
  }

  const canAccessTransferTradingUma = checkAccess(moduleConfig.AGGREGATE, ACCESS_LEVEL.COMPONENT_ACCESS, {
    subModuleName: moduleConfig.AGGREGATE_DASHBOARD, component_name: moduleConfig.ADJUST_MULTI_ACCOUNT_TO_TRADING_UMA
  })

  useEffect(() => {
    fetchAccounts()
    if (canAccessTransferTradingUma) {
      fetchTradingUMA()
    }
  }, [])

  const resetFilter = () => {
    setFilterModel({ srcAccount: null, destAccount: null })
  }

  const handleCategoryCheckboxChange = (event) => {
    const updatedSelectedTradeCategory = {
      ...selectedTradeCategory,
      [event.target.name]: event.target.checked
    }
    setSelectedTradeCategory(updatedSelectedTradeCategory)
  }

  const downloadTradeListCsv = () => {
    const dataCsv = [['Security Name', 'Local Symbol', 'Trade Code', 'Source Account ID', 'Source Account Name', 'Destination Account ID', 'Destination Account Name', 'Trade Quantity', 'Trade Value', 'Purchase Date', 'Original Purchase Price']?.join('|')]

    for (let i = 0; i < data?.length; i++) {
      const { securityName, localSymbol, trdCode, sourceAccountId, sourceAccountName, destinationAccountId, destinationAccountName, tradeQuantity, tradeValue, purchaseDate, orgPurchasePrice } = data[i]
      dataCsv.push([securityName || '', localSymbol || '', trdCode || '', sourceAccountId || '', sourceAccountName || '', destinationAccountId || '', destinationAccountName || '', tradeQuantity, tradeValue, purchaseDate ? purchaseDate?.split('T')[0] : '', orgPurchasePrice || ''].join('|').replaceAll(',', ';'))
    }
    const csvBlob = new Blob([dataCsv.join('\n')], { type: 'text/csv' })
    const csvUrl = URL.createObjectURL(csvBlob)

    // Create a temporary link and trigger the download
    const tempLink = document.createElement('a')
    tempLink.href = csvUrl
    tempLink.setAttribute('download', 'trade-list.csv')
    tempLink.click()

    // Clean up the URL object
    URL.revokeObjectURL(csvUrl)
    setDownloadCSVAnchorEl(null)
  }

  const downloadSellTransferCSV = (data) => {
    const dataCsv = [['Instrument Id', 'Security Name', 'Local Symbol', 'Trade Code', 'Source Account ID', 'Source Account Name', 'Destination Account ID', 'Destination Account Name', 'Trade Quantity', 'Trade Value', 'Purchase Date', 'Original Purchase Price', 'Unrealized Gain/Loss']?.join('|')]

    for (let i = 0; i < data?.length; i++) {
      const { instrId, securityName, localSymbol, trdCode, sourceAccountId, sourceAccountName, destinationAccountId, destinationAccountName, tradeQuantity, tradeValue, purchaseDate, orgPurchasePrice, initUrgl } = data[i]
      dataCsv.push([instrId, securityName || '', localSymbol || '', trdCode || '', sourceAccountId || '', sourceAccountName || '', destinationAccountId || '', destinationAccountName || '', tradeQuantity, tradeValue, purchaseDate ? purchaseDate?.split('T')[0] : '', orgPurchasePrice || '', initUrgl].join('|').replaceAll(',', ';'))
    }
    const csvBlob = new Blob([dataCsv.join('\n')], { type: 'text/csv' })
    const csvUrl = URL.createObjectURL(csvBlob)

    // Create a temporary link and trigger the download
    const tempLink = document.createElement('a')
    tempLink.href = csvUrl
    tempLink.setAttribute('download', 'sell-transfer-trades.csv')
    tempLink.click()

    // Clean up the URL object
    URL.revokeObjectURL(csvUrl)
    setDownloadCSVAnchorEl(null)
  }

  const fetchSellTransferData = () => {
    setIsDownloading(true)
    API.post(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/uma/sell-transfer-list/${params?.aggGroupId}`
    )
      .then(res => {
        if (res?.data) {
          downloadSellTransferCSV(res?.data)
          showSuccess(res?.message)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to fetch sell transfer data.')
      })
      .finally(() => {
        setIsDownloading(false)
      })
  }

  const handleCheckboxChange = (selectedOption, isChecked, accountType) => {
    setFilterModel(prevModel => {
      const currentAccounts = prevModel[accountType] || []
      const updatedAccountList = isChecked
        ? [...currentAccounts, selectedOption]
        : currentAccounts.filter(account => account !== selectedOption)
      return {
        ...prevModel,
        [accountType]: updatedAccountList
      }
    })
  }

  const downloadValidatedTransfers = (data = []) => {
    const headers = []; const dataCsv = []
    if (data.length) {
      Object.keys(data[0]).map((header) => headers.push(header))
    }
    dataCsv.push(headers)
    data.forEach(obj => {
      const rowArray = []
      Object.keys(data[0]).forEach(header => {
        rowArray.push(obj[header])
      })
      dataCsv.push(rowArray)
    })
    const csvBlob = new Blob([dataCsv.join('\n')], { type: 'text/csv' })
    const csvUrl = URL.createObjectURL(csvBlob)

    // Create a temporary link and trigger the download
    const tempLink = document.createElement('a')
    tempLink.href = csvUrl
    tempLink.setAttribute('download', 'validate-transfer-trades.csv')
    tempLink.click()

    // Clean up the URL object
    URL.revokeObjectURL(csvUrl)
    setDownloadCSVAnchorEl(null)
  }

  const validateTransfer = (queryParams, data) => {
    setIsDownloading(true)
    API.post(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/uma/sell-transfer-list/${params?.aggGroupId}`,
      {
        queryStringParameters: queryParams,
        body: data
      }
    )
      .then(res => {
        if (res?.data) {
          downloadValidatedTransfers(res?.data)
          handleValidatePopupVisibility(false)
          showSuccess(res?.message)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to validate transfer.')
      })
      .finally(() => {
        setIsDownloading(false)
      })
  }

  return (
    <>
      {isDownloading ? <Loader /> : ''}
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Box sx={{ p: 2, borderRadius: 2, border: 1, borderColor: '#E6ECF3' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', height: '36px' }}>
              <Box sx={{
                display: 'inline-flex',
                alignItems: 'center',
                '& .MuiCheckbox-root': { padding: '6px' },
                '& .MuiFormControlLabel-label': { fontSize: '14px', fontWeight: '600', color: '#2F2F2F' }
              }}
              >
                <FormControl>
                  <FormLabel id='trade-controlled-checkbox-buttons-group' />
                  <FormGroup row name='trade-checkbox-group'>
                    <FormControlLabel
                      value='SELL'
                      sx={{ width: 'max-content', background: '#FBFBFD', pr: 1, borderRadius: '8px' }}
                      control={<Checkbox
                        size='small'
                        checked={selectedTradeCategory.sell}
                        onChange={handleCategoryCheckboxChange}
                               />} label='Sell' name='sell'
                    />
                    <FormControlLabel
                      sx={{ width: 'max-content', background: '#FBFBFD', pr: 1, borderRadius: '8px' }}
                      value='BUY' control={<Checkbox
                        size='small'
                        checked={selectedTradeCategory.buy}
                        onChange={handleCategoryCheckboxChange}
                                           />} label='Buy' name='buy'
                    />
                    <FormControlLabel
                      sx={{ width: 'max-content', background: '#FBFBFD', pr: 1, borderRadius: '8px' }}
                      value='TRANSFER'
                      control={<Checkbox
                        size='small'
                        checked={selectedTradeCategory.transfer}
                        onChange={handleCategoryCheckboxChange}
                               />} label='Transfer' name='transfer'
                    />
                    <FormControlLabel
                      sx={{ width: 'max-content', background: '#FBFBFD', pr: 1, borderRadius: '8px' }}
                      value='NOP'
                      control={<Checkbox
                        size='small'
                        checked={selectedTradeCategory.nop}
                        onChange={handleCategoryCheckboxChange}
                               />} label='NOP' name='nop'
                    />
                  </FormGroup>
                </FormControl>
                {
                  checkAccess(moduleConfig.AGGREGATE, ACCESS_LEVEL.COMPONENT_ACCESS, {
                    subModuleName: moduleConfig.AGGREGATE_DASHBOARD, component_name: moduleConfig.DOWNLOAD_SELL_TRANSFER_TRADES
                  })
                    ? (
                      <>
                        <Tooltip title='Download CSV'>
                          <IconButton
                            onClick={(e) => setDownloadCSVAnchorEl(e.currentTarget)}
                            disabled={!data?.length}
                          >
                            <FileDownloadOutlined fontSize='small' />
                          </IconButton>
                        </Tooltip>
                        <Menu
                          open={Boolean(downloadCSVAnchorEl)}
                          onClose={() => setDownloadCSVAnchorEl(null)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                          MenuListProps={{
                            sx: { py: '4px' }
                          }}
                          anchorEl={downloadCSVAnchorEl}
                        >
                          <MenuItem
                            sx={{ fontSize: '14px' }}
                            onClick={downloadTradeListCsv}
                          >
                            Export Trade List
                          </MenuItem>
                          <MenuItem
                            sx={{ fontSize: '14px' }}
                            onClick={fetchSellTransferData}
                          >
                            Export Sell Transfer
                          </MenuItem>
                          {
                          checkAccess(moduleConfig.AGGREGATE, ACCESS_LEVEL.COMPONENT_ACCESS, {
                            subModuleName: moduleConfig.AGGREGATE_DASHBOARD, component_name: moduleConfig.DOWNLOAD_VALIDATE_TRANSFER
                          })
                            ? (
                              <MenuItem
                                sx={{ fontSize: '14px' }}
                                onClick={() => handleValidatePopupVisibility(true)}
                              >
                                Validate Transfer
                              </MenuItem>
                              )
                            : ''
                        }
                        </Menu>
                      </>
                      )
                    : (
                      <Tooltip title='Download CSV'>
                        <IconButton onClick={downloadTradeListCsv} disabled={!data?.length}>
                          <FileDownloadOutlined fontSize='small' />
                        </IconButton>
                      </Tooltip>
                      )
                }
                <Tooltip title='Filter accounts'>
                  <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                    {
                      filterModel?.srcAccount?.length || filterModel?.destAccount?.length
                        ? <FilterAlt fontSize='small' />
                        : <FilterAltOutlined fontSize='small' />
                    }
                  </IconButton>
                </Tooltip>
              </Box>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                <Box sx={{ width: '520px', p: '8px' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '14px', color: '#34475A' }}>Add Filter</Typography>
                    <IconButton onClick={() => setAnchorEl(null)}>
                      <Close sx={{ height: '18px', width: '18px' }} />
                    </IconButton>
                  </Box>
                  <Box sx={{ display: 'flex', gap: '10px', mt: '5px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        disableClearable
                        value={filterModel.srcAccount || []}
                        options={srcAccNames || []}
                        onChange={(e, value) => setFilterModel({ ...filterModel, srcAccount: value })}
                        size='small'
                        fullWidth
                        ListboxProps={{ sx: { fontSize: 14, py: 1 } }}
                        renderInput={(params) => <TextField {...params} label='Source Account' variant='outlined' />}
                        renderTags={() => null}
                      />
                      {filterModel?.srcAccount?.map((selectedOption) => {
                        return (
                          <Box key={selectedOption} sx={{ mt: '4px', display: 'flex', alignItems: 'baseline' }}>
                            <FormControlLabel
                              sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                '& .MuiCheckbox-root': {
                                  padding: '2px 9px'
                                },
                                '.MuiFormControlLabel-label': {
                                  fontSize: '14px'
                                }
                              }}
                              control={
                                <Checkbox
                                  size='small'
                                  checked
                                  onChange={(e) => handleCheckboxChange(selectedOption, e.target.checked, 'srcAccount')}
                                  sx={{
                                    borderRadius: '10px'
                                  }}
                                />
                              }
                              label={selectedOption}
                            />
                          </Box>
                        )
                      })}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        disableClearable
                        value={filterModel.destAccount || []}
                        options={destAccNames || []}
                        onChange={(e, value) => setFilterModel({ ...filterModel, destAccount: value })}
                        size='small'
                        fullWidth
                        ListboxProps={{ sx: { fontSize: 14, py: 1 } }}
                        renderInput={(params) => <TextField {...params} label='Destination Account' variant='outlined' />}
                        renderTags={() => null}
                      />
                      {filterModel?.destAccount?.map((selectedOption) => {
                        return (
                          <Box key={selectedOption} sx={{ mt: '4px', display: 'flex', alignItems: 'baseline' }}>
                            <FormControlLabel
                              sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                '& .MuiCheckbox-root': {
                                  padding: '2px 9px'
                                },
                                '.MuiFormControlLabel-label': {
                                  fontSize: '14px'
                                }
                              }}
                              control={
                                <Checkbox
                                  size='small'
                                  checked
                                  onChange={(e) => handleCheckboxChange(selectedOption, e.target.checked, 'destAccount')}
                                  sx={{
                                    borderRadius: '10px'
                                  }}
                                />
                              }
                              label={selectedOption}
                            />
                          </Box>
                        )
                      })}
                    </Box>
                  </Box>
                  <Divider sx={{ mt: '10px' }} />
                  <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button onClick={resetFilter} sx={{ fontSize: '14px', textTransform: 'capitalize', color: '#74788D' }}>
                      Clear Filters
                    </Button>
                  </Box>
                </Box>
              </Popover>
            </Box>
            <Box sx={{ position: 'relative', mt: '10px' }}>
              {
                loading
                  ? (
                    <TableContainer mt={10}>
                      <Table className='risk-page-table'>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            {tradeListColumns.map((item, index) => {
                              return (
                                <TableCell key={index}>{item.headerName}</TableCell>
                              )
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Array.from({ length: 5 }).map((_, i) => (
                            <TableRow key={i}>
                              {
                                Array.from({ length: tradeListColumns.length + 1 }).map((_, j) => (
                                  <TableCell key={j}>
                                    <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                                  </TableCell>
                                ))
                              }
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    )
                  : (
                    <DataGridPro
                      autoHeight
                      density='compact'
                      rows={tradeListData}
                      columns={tradeListColumns}
                      getRowId={(row) => row?.id}
                      pagination
                      pageSizeOptions={[10]}
                      initialState={{
                        ...tradeListData?.initialState,
                        pagination: { paginationModel: { pageSize: 10 } }
                      }}
                      sx={(theme) => ({
                        '& .MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        [`.${gridClasses.main}`]: {
                          overflow: 'unset'
                        },
                        [`.${gridClasses.columnHeaders}`]: {
                          position: 'sticky',
                          backgroundColor: theme.palette.background.paper,
                          top: 0,
                          zIndex: 1
                        },
                        [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                          color: '#74788d',
                          fontWeight: 600
                        },
                        [`.${gridClasses.virtualScroller}`]: {
                          overflowY: 'auto !important',
                          scrollbarGutter: 'stable',
                          scrollbarWidth: 'none'
                        }
                      })}
                    />
                    )
              }
            </Box>
          </Box>
        </Grid>
      </Grid>
      {
        showValidatePopup
          ? (
            <CreateDestinationAndTradingAccMapping
              destAccs={uniqueDestAccounts.current}
              open={showValidatePopup}
              onClose={handleValidatePopupVisibility}
              accounts={tradingAccounts}
              isAccountLoading={isAccountLoading}
              validateTransfer={validateTransfer}
              tradingUMA={tradingUMAs}
            />
            )
          : ''
      }
    </>
  )
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 2,
  py: 2,
  width: '100%',
  borderRadius: '4px'
}

const CreateDestinationAndTradingAccMapping = ({ destAccs = [], open = false, onClose, accounts, isAccountLoading, validateTransfer, tradingUMA = [] }) => {
  const aggregateData = useContext(UmaContext)
  const { checkAccess } = useAuth()
  const [data, setData] = useState(destAccs)
  const [isTradingUMASelected, setIsTradingUMASelected] = useState(false)
  const [rowModesModel, setRowModesModel] = useState({})
  const [umaRowModesModel, setUmaRowModesModel] = useState({})
  const [umaData, setUmaData] = useState([{
    transitionUmaId: aggregateData?.topCard?.data?.aggId,
    transitionUmaName: aggregateData?.topCard?.data?.aggName,
    tradingUmaId: '',
    tradingUmaName: ''
  }])
  const canAccessTransferTradingUma = checkAccess(moduleConfig.AGGREGATE, ACCESS_LEVEL.COMPONENT_ACCESS, {
    subModuleName: moduleConfig.AGGREGATE_DASHBOARD, component_name: moduleConfig.ADJUST_MULTI_ACCOUNT_TO_TRADING_UMA
  })
  const columns = [
    {
      field: 'destinationAccountId',
      headerName: 'UMA Transition Id',
      flex: 1
    },
    {
      field: 'destinationAccountName',
      headerName: 'UMA Transition Account',
      flex: 1
    },
    {
      field: 'tradingAccountId',
      headerName: 'Trading Account Id',
      flex: 1,
      editable: true
    },
    {
      field: 'tradingAccountName',
      headerName: 'Trading Account',
      flex: 1,
      editable: true,
      renderEditCell: (params) => <SourceAccountEditField params={params} accounts={accounts} isAccountLoading={isAccountLoading} />,
      renderCell: (params) => params?.row?.tradingAccountName ?? ''
    },
    {
      field: 'action',
      type: 'actions',
      headerName: '',
      width: 100,
      getActions: (params) => {
        const isInEditMode = rowModesModel[params?.id]?.mode === GridRowModes.Edit
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save />}
              label='Save'
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(params?.id)}
            />,
            <GridActionsCellItem
              icon={<Cancel />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(params?.id)}
              color='inherit'
            />
          ]
        }

        return [
          <GridActionsCellItem
            icon={<Edit />}
            label='Edit'
            className='textPrimary'
            onClick={handleEditClick(params?.id)}
            color='inherit'
          />
        ]
      }
    }
  ]

  const umaColumns = [
    {
      field: 'transitionUmaId',
      headerName: 'UMA Id',
      flex: 1
    },
    {
      field: 'transitionUmaName',
      headerName: 'Transition UMA',
      flex: 1
    },
    {
      field: 'tradingUmaId',
      headerName: 'Trading UMA Id',
      flex: 1,
      editable: true
    },
    {
      field: 'tradingUmaName',
      headerName: 'Trading UMA',
      flex: 1,
      editable: true,
      renderEditCell: (params) => <TradingUMAEditField params={params} tradingUMA={tradingUMA} />,
      renderCell: (params) => params?.row?.tradingUmaName ?? ''
    },
    {
      field: 'action',
      type: 'actions',
      headerName: '',
      width: 100,
      getActions: (params) => {
        const isInEditMode = umaRowModesModel[params?.id]?.mode === GridRowModes.Edit
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save />}
              label='Save'
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(params?.id, true)}
            />,
            <GridActionsCellItem
              icon={<Cancel />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(params?.id, true)}
              color='inherit'
            />
          ]
        }

        return [
          <GridActionsCellItem
            icon={<Edit />}
            label='Edit'
            className='textPrimary'
            onClick={handleEditClick(params?.id, true)}
            color='inherit'
          />
        ]
      }
    }
  ]

  const handleSubmit = () => {
    if (isTradingUMASelected && isApplyUmaEnabled) {
      const destinationAndTradeAccMapping = data?.map(obj => ({
        tradingAccountId: obj?.tradingAccountId,
        tradingAccountName: obj?.tradingAccountName,
        destinationAccountId: obj?.destinationAccountId,
        destinationAccountName: obj?.destinationAccountName
      }))

      validateTransfer(
        { validateSellTransfer: true, isTransferTradingUMA: true },
        { validateSellTransferList: destinationAndTradeAccMapping, transferTradingUMAId: umaData[0]?.tradingUmaId }
      )
    } else {
      const destinationAndTradeAccMapping = data?.map(obj => ({
        tradingAccountId: obj?.tradingAccountId,
        tradingAccountName: obj?.tradingAccountName,
        destinationAccountId: obj?.destinationAccountId,
        destinationAccountName: obj?.destinationAccountName
      })).filter(obj => ![null, undefined, ''].includes(obj?.tradingAccountId))
      if (destinationAndTradeAccMapping.length) {
        validateTransfer(
          { validateSellTransfer: true, isTransferTradingUMA: false },
          { validateSellTransferList: destinationAndTradeAccMapping }
        )
      }
    }
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut || params.reason === GridRowEditStopReasons.escapeKeyDown || params.reason === GridRowEditStopReasons.enterKeyDown || params.reason === GridRowEditStopReasons.tabKeyDown) {
      event.defaultMuiPrevented = true
    }
  }

  const handleCellDoubleClick = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCancelClick = (id, umaFlag) => () => {
    if (umaFlag) {
      setUmaRowModesModel({
        ...umaRowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true }
      })
      return
    }
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })
  }

  const handleEditClick = (id, umaFlag) => () => {
    if (umaFlag) {
      setUmaRowModesModel({ ...umaRowModesModel, [id]: { mode: GridRowModes.Edit } })
      return
    }
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id, umaFlag) => () => {
    if (umaFlag) {
      setUmaRowModesModel({ ...umaRowModesModel, [id]: { mode: GridRowModes.View } })
      return
    }
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleRowModesModelChange = (newRowModesModel, umaFlag) => {
    // used for changing view/edit mode of row
    if (umaFlag) {
      setUmaRowModesModel(newRowModesModel)
      return
    }
    setRowModesModel(newRowModesModel)
  }

  const processRowUpdate = (newRow, oldRow, umaFlag) => {
    if (umaFlag) {
      setUmaData(prev =>
        prev.map(obj => obj?.transitionUmaId === newRow?.transitionUmaId
          ? ({
              ...obj,
              ...newRow
            })
          : obj))
      return newRow
    }
    const updatedData = data.map((obj) => obj?.destinationAccountId === newRow?.destinationAccountId
      ? ({
          ...obj,
          ...newRow
        })
      : obj)
    if (isTradingUMASelected) {
      setIsTradingUMASelected(updatedData.every(obj => obj?.tradingAccountId !== '' && obj?.tradingAccountName !== ''))
    }
    setData(updatedData)
    return newRow
  }

  const isApplyUmaEnabled = data.every(obj => obj?.tradingAccountId !== '' && obj?.tradingAccountName !== '')
  const isSubmitDisabled = data?.every(obj => obj?.tradingAccountId === '') ||
    (!!(isTradingUMASelected && isApplyUmaEnabled && !umaData[0]?.tradingUmaId))

  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
    >
      <Box sx={{ ...modalStyle, outline: 'none', minWidth: '800px', maxWidth: '1000px', maxHeight: '100vh' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
          <Typography variant='h6' sx={{ ml: '5px', fontWeight: 400, color: '#34475A' }}>Validate Transfer</Typography>
          <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
            <Button
              variant='contained'
              size='small'
              onClick={handleSubmit}
              disabled={isSubmitDisabled}
              sx={{ height: '32px' }}
            >
              Apply
            </Button>
            <IconButton onClick={() => onClose(false)}>
              <Close />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ height: 'calc(100vh - 90px)' }}>
          <DataGridPro
            density='compact'
            autoHeight
            rows={data}
            columns={columns}
            editMode='row'
            onCellDoubleClick={handleCellDoubleClick}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newRowModesModel) => handleRowModesModelChange(newRowModesModel, false)}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            getRowId={(row) => row?.destinationAccountId}
            hideFooter
            initialState={{
              columns: {
                columnVisibilityModel: {
                  tradingAccountId: false,
                  destinationAccountId: false
                }
              }
            }}
            sx={(theme) => ({
              '& .MuiDataGrid-cell:focus-within': {
                outline: 'none !important'
              },
              [`.${gridClasses.columnHeaders}`]: {
                position: 'sticky',
                backgroundColor: theme.palette.background.paper,
                top: 0,
                zIndex: 1
              },
              [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                color: '#74788d',
                fontWeight: 600
              },
              [`.${gridClasses.virtualScroller}`]: {
                overflowY: 'auto !important',
                scrollbarGutter: 'stable',
                scrollbarWidth: 'none'
              }
            })}
          />
          {
            canAccessTransferTradingUma
              ? (
                <>
                  <FormGroup sx={{ display: 'inline-flex', mt: '10px' }}>
                    <FormControlLabel
                      disabled={!isApplyUmaEnabled}
                      control={
                        <Checkbox
                          checked={isTradingUMASelected}
                          size='small'
                          onChange={(e) => {
                            setIsTradingUMASelected(e.target.checked)
                          }}
                        />
                    }
                      label='Apply Trading UMA'
                      sx={{
                        marginLeft: '0px',
                        '.MuiFormControlLabel-label': {
                          fontSize: '14px',
                          color: '#34475A'
                        }
                      }}
                    />
                  </FormGroup>
                  {
                  isTradingUMASelected && isApplyUmaEnabled
                    ? (
                      <DataGridPro
                        density='compact'
                        autoHeight
                        rows={umaData}
                        columns={umaColumns}
                        editMode='row'
                        rowModesModel={umaRowModesModel}
                        onCellDoubleClick={handleCellDoubleClick}
                        onRowModesModelChange={(newRowModesModel) => handleRowModesModelChange(newRowModesModel, true)}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={(newRow, oldRow) => processRowUpdate(newRow, oldRow, true)}
                        getRowId={(row) => row?.transitionUmaId}
                        hideFooter
                        initialState={{
                          columns: {
                            columnVisibilityModel: {
                              transitionUmaId: false,
                              tradingUmaId: false
                            }
                          }
                        }}
                        sx={(theme) => ({
                          '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none !important'
                          },
                          [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                            color: '#74788d',
                            fontWeight: 600
                          },
                          [`.${gridClasses.virtualScroller}`]: {
                            overflowY: 'auto !important',
                            scrollbarGutter: 'stable',
                            scrollbarWidth: 'none'
                          }
                        })}
                      />
                      )
                    : ''
                }
                </>
                )
              : ''
          }
        </Box>
      </Box>
    </Modal>
  )
}

const SourceAccountEditField = ({ params, accounts = [], isAccountLoading = false }) => {
  const handleAccountChange = (_, newValue) => {
    params?.api?.setEditCellValue({ id: params?.id, field: 'tradingAccountId', value: newValue ? newValue?.accountId : '' })
    params?.api?.setEditCellValue({ id: params?.id, field: 'tradingAccountName', value: newValue ? newValue?.accountName : '' })
  }

  return (
    <Autocomplete
      freeSolo
      value={params?.row}
      loading={isAccountLoading}
      size='small'
      onChange={handleAccountChange}
      loadingText='Loading...'
      noOptionsText='No accounts found'
      options={accounts}
      getOptionLabel={(option) => `${option.accountName || option?.tradingAccountName || ''}`}
      renderOption={(props, option) => (
        <MenuItem {...props} sx={{ whiteSpace: 'normal' }} key={option?.accountId}>
          {option?.accountName}
        </MenuItem>
      )}
      isOptionEqualToValue={(option, value) => option?.accountId === value?.tradingAccountId}
      renderInput={(params) => <TextField {...params} />}
      style={params.style || {}}
      sx={{
        width: '100%',
        '.MuiOutlinedInput-input': {
          height: '14px !important',
          fontSize: '14px',
          color: '#34475A',
          fontWeight: 600
        }
      }}
    />
  )
}

const TradingUMAEditField = ({ params, tradingUMA = [] }) => {
  const handleUmaChange = (_, newValue) => {
    params?.api?.setEditCellValue({ id: params?.id, field: 'tradingUmaId', value: newValue ? newValue?.aggGroupId : '' })
    params?.api?.setEditCellValue({ id: params?.id, field: 'tradingUmaName', value: newValue ? newValue?.aggGroupName : '' })
  }

  return (
    <Autocomplete
      freeSolo
      value={params?.row}
      size='small'
      onChange={handleUmaChange}
      noOptionsText='No trading UMA found'
      options={tradingUMA}
      getOptionLabel={(option) => `${option?.tradingUmaName || option?.aggGroupName || ''}`}
      renderOption={(props, option) => (
        <MenuItem {...props} sx={{ whiteSpace: 'normal' }} key={option?.aggGroupId}>
          {option?.aggGroupName}
        </MenuItem>
      )}
      isOptionEqualToValue={(option, value) => option?.aggGroupId === value?.tradingUmaId}
      renderInput={(params) => <TextField {...params} />}
      style={params.style || {}}
      sx={{
        width: '100%',
        '.MuiOutlinedInput-input': {
          height: '14px !important',
          fontSize: '14px',
          color: '#34475A',
          fontWeight: 600
        }
      }}
    />
  )
}

export default EnhancedTradeList
