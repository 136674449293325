import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // Import the UTC plugin
import { TryOutlined } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, Card, CardContent, Grid, IconButton, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { DataGridPro, gridClasses, useGridApiRef } from '@mui/x-data-grid-pro'
import '../../../assets/styles/AccountDetailspage.scss'
import { HtmlTooltip } from '../../../components/CustomTooltip'

dayjs.extend(utc)

const TaxlotHistory = ({ taxlotHistoryData, taxlotHistoryLoading, taxlotHistoryGridState, setTaxlotHistoryGridState }) => {
  const apiRef = useGridApiRef()

  const wholeNumberCurrencyFormatter = (amount) => {
    return Math.abs(Number(amount)) >= 1.0e9
      ? (Math.abs(Number(amount)) / 1.0e9).toFixed(0) + 'B'
      : Math.abs(Number(amount)) >= 1.0e6
        ? (Math.abs(Number(amount)) / 1.0e6).toFixed(0) + 'M'
        : Math.abs(Number(amount)) >= 1.0e3
          ? (Math.abs(Number(amount)) / 1.0e3).toFixed(0) + 'K'
          : Math.abs(Number(amount)).toFixed(0)
  }

  // Function to show the colors for the swap status
  const getColorProperties = (data) => {
    if (data === 'COMPLETED') {
      return {
        color: '#446b60',
        backgroundColor: '#3BBFA399',
        border: '1px solid #446b6022'
      }
    } else if (data === 'INPROGRESS') {
      return {
        color: '#aaab29',
        backgroundColor: '#fbfa6c99',
        border: '1px solid #aaab2922'
      }
    } else if (data === 'FAILED') {
      return {
        color: '#79454f',
        backgroundColor: '#ff616199',
        border: '1px solid #79454f22'
      }
    } else if (data === 'TRADE_STOPPED') {
      return {
        color: '#969696',
        backgroundColor: '#C0C0C099',
        border: '1px solid #96969622'
      }
    } else if (data === 'TRADE_PARTIAL_FILLED') {
      return {
        color: '#816f36',
        backgroundColor: '#d2992299',
        border: '1px solid #816f3622'
      }
    }
  }

  const tableHeaders = [
    {
      field: 'aggName',
      headerName: 'Aggregate Name',
      valueGetter: (params) => params.row?.aggName || ''
    },
    {
      field: 'instrId',
      headerName: 'Instrument Id',
      renderCell: (params) => params.row?.instrId || 'N/A'
    },
    {
      field: 'sharesSwapped',
      headerName: 'Shares Swapped',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => {
        return params?.row?.sharesSwapped ?? ''
      },
      valueFormatter: (params) => {
        return params?.value != null ? params?.value?.toString() : 'N/A' // Convert the value to string to ensure all decimal points are shown
      }
    },
    {
      field: 'totalSharesInLot',
      headerName: 'Total Shares',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => {
        return params?.row?.totalSharesInLot ?? ''
      },
      valueFormatter: (params) => {
        return params?.value != null ? params?.value?.toString() : 'N/A' // Convert the value to string to ensure all decimal points are shown
      }
    },
    {
      field: 'purchaseDate',
      headerName: 'Purchase Date',
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.purchaseDate ? params?.row?.purchaseDate?.split('T')[0] : ''
    },
    {
      field: 'swappedOutAccountName',
      headerName: 'Swap Out',
      flex: 1.5,
      valueGetter: (params) => params?.row?.swappedOutAccountName || '',
      renderCell: (params) => (
        <Box sx={{ color: '#F46A6A' }}>{params?.value}</Box>
      ),
      renderHeader: () => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ color: '#74788d', fontWeight: 600, fontSize: '14px' }}>Swap Out</span>
          <IconButton sx={{ padding: 0 }}>
            <ArrowForwardIcon sx={{ fontSize: '20px', marginLeft: '4px', color: '#F46A6A' }} />
          </IconButton>
        </Box>
      )
    },
    {
      field: 'swappedInAccountName',
      headerName: 'Swap In',
      flex: 1.5,
      valueGetter: (params) => params?.row?.swappedInAccountName || '',
      renderCell: (params) => (
        <Box sx={{ color: '#34C38F' }}>{params?.value}</Box>
      ),
      renderHeader: () => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ color: '#74788d', fontWeight: 600, fontSize: '14px' }}>Swap In</span>
          <IconButton sx={{ padding: 0 }}>
            <ArrowBackIcon sx={{ fontSize: '20px', marginLeft: '4px', color: '#34C38F' }} />
          </IconButton>
        </Box>
      )
    },
    {
      field: 'swappedInAccountId',
      headerName: 'Swapped In Account ID',
      valueGetter: (params) => params.row?.swappedInAccountId
    },
    {
      field: 'swappedOutAccountId',
      headerName: 'Swapped Out Account ID',
      valueGetter: (params) => params.row?.swappedOutAccountId
    },
    {
      field: 'swapStatus',
      headerName: 'Swap Status',
      renderCell: (params) => {
        const data = params?.row?.swapStatus || 'N/A'
        return data !== 'N/A'
          ? <><Box sx={{ borderRadius: '20px', fontSize: '10px', px: '10px', py: '2px', ...getColorProperties(data), fontWeight: 600 }}>{data}</Box></>
          : ''
      }
    },
    {
      field: 'swappedInSwapStatus',
      headerName: 'Swapped Out Status',
      renderCell: (params) => {
        const data = params?.row?.swappedOutSwapCode || 'N/A'
        return data !== 'N/A'
          ? <><Box sx={{ borderRadius: '20px', fontSize: '10px', px: '10px', py: '2px', ...getColorProperties(data), fontWeight: 600 }}>{data}</Box></>
          : ''
      }
    },
    {
      field: 'swappedInSwapStatus',
      headerName: 'Swapped In Status',
      renderCell: (params) => {
        const data = params?.row?.swappedInSwapStatus || 'N/A'
        return data !== 'N/A'
          ? <><Box sx={{ borderRadius: '20px', fontSize: '10px', px: '10px', py: '2px', ...getColorProperties(data), fontWeight: 600 }}>{data}</Box></>
          : ''
      }
    },
    {
      field: 'isContribution',
      headerName: 'Contribution',
      renderCell: (params) => params.row?.isContribution ? 'Yes' : 'No'
    },

    {
      field: 'swapDate',
      headerName: 'Swap Date',
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.swapDate ? params?.row?.swapDate?.split('T')[0] : 'N/A'
    },
    {
      field: 'instrName',
      headerName: 'Instrument Name',
      renderCell: (params) => params.row?.instrName || 'N/A'
    },
    {
      field: 'localSymbol',
      headerName: 'Local Symbol',
      renderCell: (params) => params.row?.instrName || 'N/A'
    },
    {
      field: 'orgPurchasePrice',
      headerName: 'Purchase Price',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.row?.orgPurchasePrice !== null && params.row?.orgPurchasePrice !== undefined
        ? params.row?.orgPurchasePrice < 0
          ? `-$${Math.abs(params.row?.orgPurchasePrice)}`
          : `$${params.row?.orgPurchasePrice}`
        : '',
        valueGetter: (params) => {
          const value = params?.row?.orgPurchasePrice
          return value == null || isNaN(value) ? null : parseFloat(value)
        }
    },
    {
      field: 'lastClosePrice',
      headerName: 'Last Close Price',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.row?.lastClosePrice !== null && params.row?.lastClosePrice !== undefined
        ? params.row?.lastClosePrice < 0
          ? `-$${wholeNumberCurrencyFormatter(params.row?.lastClosePrice)}`
          : `$${wholeNumberCurrencyFormatter(params.row?.lastClosePrice)}`
        : 'N/A',
      valueGetter: (params) => params.row?.lastClosePrice || 'N/A'
    },
    {
      field: 'comment',
      headerName: 'Comments',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <HtmlTooltip tooltipStyle={{ marginRight: '10px' }}
            title={
              <Typography sx={{ fontSize: '14px' }} my={1}>{params?.row?.comment || 'N/A'}</Typography>
            }
          >
            <TryOutlined />
          </HtmlTooltip>
        )
      }
    }
  ]

  const getRowId = (row) => row.id

  const visibleHeaders = tableHeaders.filter(header => {
    return !['instrName', 'localSymbol', 'swappedInAccountId', 'swappedOutAccountId', 'swappedInSwapStatus', 'swappedOutSwapStatus'].includes(header.field)
  })

  const tableHeaderHTML = visibleHeaders.map(({ headerName }, index) => (
    <TableCell key={index} sx={{ whiteSpace: 'nowrap' }}>{headerName}</TableCell>
  ))

  const rowSkeleton = visibleHeaders.map(({ headerName }, index) => (
    <TableCell key={index}>
      <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
    </TableCell>
  ))

  // Function to save the current state of the DataGridPro
  const handleTaxlotHistoryTableStateChange = () => {
    if (apiRef?.current?.exportState) {
      const state = apiRef?.current?.exportState()
      setTaxlotHistoryGridState(state)
    }
  }

  // Effect to restore the state whenever taxlotHistoryGridState changes
  useEffect(() => {
    if (apiRef?.current?.restoreState && taxlotHistoryGridState) {
      apiRef?.current?.restoreState({
        pinnedColumns: taxlotHistoryGridState.pinnedColumns,
        columns: taxlotHistoryGridState.columns
      })
    }
  }, [taxlotHistoryGridState, taxlotHistoryData, taxlotHistoryLoading])

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  width: '100%',
                  position: 'relative',
                  overflowY: 'auto',
                  scrollbarWidth: 'none'
                }}
              >
                {taxlotHistoryLoading
                  ? (
                    <Table>
                      <TableHead>
                        <TableRow>
                          {tableHeaderHTML}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>{rowSkeleton}</TableRow>
                        <TableRow>{rowSkeleton}</TableRow>
                      </TableBody>
                    </Table>
                    )
                  : (
                      taxlotHistoryData === undefined || taxlotHistoryData?.length === 0
                        ? (
                          <>No Taxlot History found for given date</>
                          )
                        : (
                          <DataGridPro
                            apiRef={apiRef}
                            density='compact'
                            columns={tableHeaders}
                            rows={taxlotHistoryData}
                            autoHeight
                            pageSizeOptions={[15, 25, 50, 75, 100]}
                            disableRowSelectionOnClick
                            onColumnWidthChange={() => handleTaxlotHistoryTableStateChange()}
                            onColumnOrderChange={() => handleTaxlotHistoryTableStateChange()}
                            initialState={{
                              columns: {
                                columnVisibilityModel: {
                                  instrName: false,
                                  localSymbol: false,
                                  swappedInAccountId: false,
                                  swappedOutAccountId: false,
                                  swappedInSwapStatus: false,
                                  swappedOutSwapStatus: false
                                }
                              },
                              pagination: { paginationModel: { pageSize: 15 } }
                            }}
                            pagination
                            getRowId={getRowId}
                            sx={(theme) => ({
                              '& .MuiDataGrid-cell:focus': {
                                outline: 'none'
                              },
                              [`.${gridClasses.main}`]: {
                                overflow: 'unset'
                              },
                              [`.${gridClasses.columnHeaders}`]: {
                                position: 'sticky',
                                backgroundColor: theme.palette.background.paper,
                                top: 0,
                                zIndex: 1
                              },
                              [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                                color: '#74788d',
                                fontWeight: 600
                              },
                              [`.${gridClasses.footerContainer}`]: {
                                position: 'sticky',
                                bottom: '-1px',
                                backgroundColor: theme.palette.background.paper,
                                zIndex: 1
                              },
                              [`.${gridClasses.virtualScroller}`]: {
                                '::-webkit-scrollbar': {
                                  width: '0px !important'
                                },
                                overflowY: 'auto !important',
                                height: 'calc(100vh - 48px - 15px - 20px - 70px - 10px - 48px - 48px - 16px - 24px - 39px - 60px)'
                              }
                            })}
                          />
                          )
                    )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
export default TaxlotHistory
