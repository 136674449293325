import { useContext, useState } from 'react'
import { useParams } from 'react-router'
import { API } from 'aws-amplify'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import DownloadDoneIcon from '@mui/icons-material/DownloadDone'
import DownloadingIcon from '@mui/icons-material/Downloading'
import { Card, CardContent, Chip, Grid, Typography } from '@mui/material'
import TemplateSelectionModal from '../../../components/TemplateSelectionModal'
import { useAuth } from '../../../contexts/AuthContext'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL, MODEL_WEIGHT_DISPLAY_MODULES } from '../../../contstants/constants'
import useDownloadLogs from '../../../hooks/useDownloadLogs'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { accountDataContext, taxSavingDataContext, positionDataContext } from '../Index'
import DownloadReportButton from './DownloadReportButton'

export const DownloadReport = ({ isRetrying, isTaxSavingAPIRetrying, isDoneRetrying, optId }) => {
  const { user, checkAccess } = useAuth()
  const params = useParams()
  const { createCSVDownloadLog } = useDownloadLogs()
  const { showError } = useErrorToast()
  const [showModal, setShowModal] = useState(null) // used for open/close and to hold type of report
  const [isReportDownload, setIsReportDownload] = useState(true)
  const accountData = useContext(accountDataContext)
  const [downloadInProgress, setDownloadInProgress] = useState(false)
  const [downloadTaxSavingInProgress, setDownloadTaxSavingInProgress] = useState(false)
  const [downloadDone, setDownloadDone] = useState(false)
  const [downloadPositionReportDone, setDownloadPositionReportDone] = useState(false)
  const [downloadPositionReportInProgress, setDownloadPositionReportInProgress] = useState(false)
  const [downloadTaxSavingReportDone, setDownloadTaxSavingReportDone] = useState(false)

  const taxSavingData = useContext(taxSavingDataContext)
  const positionData = useContext(positionDataContext)
  // check pdf customizer access for pm. if no access available, then show old report generation process
  const hasPdfCustomizerAccess = user?.userGroup === 'pm'
    ? checkAccess(moduleConfig.PDF_CUSTOMIZER, ACCESS_LEVEL.COMPONENT_ACCESS,
      { subModuleName: moduleConfig.PDF_CUSTOMIZER, component_name: moduleConfig.PDF_REPORT })
    : false

  const toggleArrow = () => {
    setIsReportDownload(!isReportDownload)
  }

  const downloadFileFromBuffer = (response, filename, reportType) => {
    const url = window.URL.createObjectURL(new Blob([response]))
    const fileLink = document.createElement('a')
    fileLink.href = url
    fileLink.download = filename
    document.body.appendChild(fileLink)
    fileLink.click()
    URL.revokeObjectURL(url)
    document.body.removeChild(fileLink)
    setDownloadInProgress(false)
    setDownloadTaxSavingInProgress(false)
    setDownloadPositionReportInProgress(false)
    setDownloadDone(true)
    if (reportType === 'taxSaving') {
      setDownloadTaxSavingReportDone(true)
      setTimeout(() => {
        setDownloadTaxSavingReportDone(false)
      }, 3000)
    } else if (reportType === 'positional') {
      setDownloadPositionReportDone(true)
      setTimeout(() => {
        setDownloadPositionReportDone(false)
      }, 3000)
    } else {
      setTimeout(() => {
        setDownloadDone(false)
      }, 3000)
    }
  }

  const downloadTaxSavingReport = async () => {
    try {
      setDownloadTaxSavingInProgress(true)
      if (taxSavingData) {
        await API.post('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/tax-savings-pdf-reports`, {
          body: taxSavingData,
          responseType: 'blob'
        })
          .then(response => {
            downloadFileFromBuffer(response, 'Tax Saving Report.pdf', 'taxSaving')
          })
      }
    } catch (error) {
      setDownloadTaxSavingInProgress(false)
      showError(error, false, {}, 'Failed to download tax saving report.')
    }
  }

  const downloadPositionalReport = async () => {
    try {
      if (positionData) {
        setDownloadPositionReportInProgress(true)
        await API.post('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/position-pdf-reports`, {
          body: positionData,
          responseType: 'blob'
        })
          .then(response => {
            downloadFileFromBuffer(response, 'Positional Report.pdf', 'positional')
            createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.REPORT_POSITION_PDF, params?.accountId, 'Positional Report.pdf')
          })
      }
    } catch (error) {
      setDownloadPositionReportInProgress(false)
      showError(error, false, {}, 'Failed to download position report.')
    }
  }

  const downloadTransitionReport = async () => {
    try {
      setDownloadInProgress(true)
      API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/transition-reports/pdf-response/${params.accountId}`, {
        queryStringParameters: {
          'validate-only': true,
          ...{ ...params.RequestId ? { requestId: params.RequestId } : {} }
        }
      })
        .then(async (response) => {
          const isPDFStored = response.data?.fileAlreadyExists || false
          API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/transition-reports/pdf-response/${params.accountId}`, isPDFStored
            ? {
                responseType: 'blob',
                ...{ ...params.RequestId ? { queryStringParameters: { requestId: params.RequestId } } : {} }
              }
            : { ...{ ...params.RequestId ? { queryStringParameters: { requestId: params.RequestId } } : {} } })
            .then(async (response) => {
              if (response.data && response.data.ejsTemplates && response.data.accountDetails) {
                await API.post('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/transition-reports/ejs-to-pdf`, {
                  body: response.data,
                  responseType: 'blob'
                })
                  .then(response => {
                    downloadFileFromBuffer(response, 'Transition Report.pdf')
                    createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.REPORT_TRANSITION_PDF, params?.accountId, 'Transition Report.pdf')
                  })
              } else {
                downloadFileFromBuffer(response, 'Transition Report.pdf')
                createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.REPORT_TRANSITION_PDF, params?.accountId, 'Transition Report.pdf')
              }
            })
        })
        .catch(error => {
          setDownloadInProgress(false)
          showError(error, false, {}, 'Failed to download transition report.')
        })
    } catch (error) {
      setDownloadInProgress(false)
      showError(error, false, {}, 'Failed to download transition report.')
    }
  }
  return (
    <div>
      <DownloadReportButton isReportDownload={!isReportDownload} toggleArrow={toggleArrow} />
      {isReportDownload && (
        <Grid xs={12} sm={12}>
          <Card sx={{ minWidth: '100%', borderRadius: '4px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px', border: 'none' }}>
            <CardContent>
              <Grid xs={12} sm={12} container m={0}>
                {accountData?.accountType === 'TRANSITION'
                  ? <Grid>
                    <button type='button' disabled={hasPdfCustomizerAccess ? false : downloadInProgress} onClick={() => hasPdfCustomizerAccess ? setShowModal('Transition Report') : downloadTransitionReport()} style={{ border: 'none', background: 'none', padding: 0, '&:focus': { outline: 'none' } }}>
                      <Typography mt={1} sx={{ color: '#34475A', fontSize: 16, display: 'flex', alignItems: 'center', fontFamily: 'Open Sans' }}>
                        Transition Report {
                          !hasPdfCustomizerAccess
                            ? downloadInProgress
                              ? <DownloadingIcon sx={{ fontSize: 18, marginLeft: '3px' }} />
                              : downloadDone
                                ? <DownloadDoneIcon sx={{ fontSize: 18, marginLeft: '3px' }} />
                                : <CloudDownloadIcon sx={{ fontSize: 18, marginLeft: '3px' }} />
                            : ''
                        }
                      </Typography>
                    </button>
                    <Typography mt={1} sx={{ color: '#34475A', fontSize: 16, fontFamily: 'Open Sans' }}>
                      Strategy Factsheet
                    </Typography>
                    <Typography mt={1} sx={{ color: '#34475A', fontSize: 16, fontFamily: 'Open Sans' }}>
                      Reference Manual
                    </Typography>
                  </Grid>
                  : <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                    <button
                      type='button'
                      disabled={hasPdfCustomizerAccess ? false : (!taxSavingData || downloadTaxSavingInProgress)}
                      onClick={() => hasPdfCustomizerAccess ? setShowModal('Tax Saving Report') : downloadTaxSavingReport()}
                      style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer', '&:focus': { outline: 'none' } }}
                    >
                      <Typography mt={1} sx={{ color: '#34475A', fontSize: 16, display: 'flex', alignItems: 'center', fontFamily: 'Open Sans' }}>
                        Tax saving report {
                          !hasPdfCustomizerAccess
                            ? isTaxSavingAPIRetrying
                              ? <Chip label='Processing' variant='filled' sx={{ marginLeft: '5px' }} size='small' />
                              : !taxSavingData
                                  ? <Chip label='Processing' variant='filled' sx={{ marginLeft: '5px' }} size='small' />
                                  : downloadTaxSavingInProgress
                                    ? <DownloadingIcon sx={{ fontSize: 18, marginLeft: '3px' }} />
                                    : downloadTaxSavingReportDone
                                      ? <DownloadDoneIcon sx={{ fontSize: 18, marginLeft: '3px' }} />
                                      : <CloudDownloadIcon sx={{ fontSize: 18, marginLeft: '3px' }} />
                            : ''
                        }
                      </Typography>
                    </button>
                    <button
                      type='button'
                      disabled={hasPdfCustomizerAccess ? false : (!positionData || downloadPositionReportInProgress)}
                      onClick={() => hasPdfCustomizerAccess ? setShowModal('Position Report') : downloadPositionalReport()}
                      style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer', '&:focus': { outline: 'none' } }}
                    >
                      <Typography mt={1} sx={{ color: '#34475A', fontSize: 16, display: 'flex', alignItems: 'center', fontFamily: 'Open Sans' }}>
                        Positional report {
                          !hasPdfCustomizerAccess
                            ? isRetrying
                              ? <Chip label='Processing' variant='filled' sx={{ marginLeft: '5px' }} size='small' />
                              : (!positionData && !isDoneRetrying)
                                  ? <Chip label='Processing' variant='filled' sx={{ marginLeft: '5px' }} size='small' />
                                  : downloadPositionReportInProgress
                                    ? <DownloadingIcon sx={{ fontSize: 18, marginLeft: '3px' }} />
                                    : downloadPositionReportDone
                                      ? <DownloadDoneIcon sx={{ fontSize: 18, marginLeft: '3px' }} />
                                      : isDoneRetrying
                                        ? <CloudDownloadIcon sx={{ fontSize: 18, marginLeft: '3px' }} />
                                        : <CloudDownloadIcon sx={{ fontSize: 18, marginLeft: '3px' }} />
                            : ''
                        }
                      </Typography>
                    </button>

                    <Typography mt={1} sx={{ color: '#34475A', fontSize: 16, fontFamily: 'Open Sans' }}>
                      Reference Manual
                    </Typography>
                  </Grid>}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
      {showModal && hasPdfCustomizerAccess
        ? <TemplateSelectionModal open handleClose={() => setShowModal(null)} reportType={showModal} optId={optId} />
        : ''}
    </div>
  )
}
