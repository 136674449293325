import { Autocomplete, MenuItem, TextField } from "@mui/material"

const AccountCodeSelect = ({ accountList, onChange, value, error, helperText }) => {
  const accountObject = accountList?.data?.find(acc => acc?.accountCode === value)

  return (
    <Autocomplete
      loading={accountObject?.loading}
      size='small'
      fullWidth
      value={accountObject || null}
      options={accountList?.data || []}
      getOptionLabel={(option) => `${option?.accountName || ''} (${option?.accountCode})`}
      onChange={onChange}
      loadingText='Loading...'
      noOptionsText='No accounts found'
      renderOption={(props, option) => (
        <MenuItem {...props} sx={{ whiteSpace: 'normal' }} key={option?.accountId}>
          {`${option?.accountName} (${option?.accountCode})`}
        </MenuItem>
      )}
      isOptionEqualToValue={(option, value) => option?.accountCode === value?.accountCode}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          FormHelperTextProps={{
            style: { marginLeft: '5px' }
          }}
        />
      )}
    />
  )
}

const PassiveScenarioSelect = ({ allScenarioList, onChange, value, error, helperText }) => {
  const scenarioObject = allScenarioList?.find(acc => acc?.scenarioId === Number(value))

  return (
    <Autocomplete
      loading={false}
      size='small'
      fullWidth
      value={scenarioObject || null}
      options={allScenarioList || []}
      getOptionLabel={(option) => `${option.scenarioCode || ''}`}
      onChange={onChange}
      loadingText='Loading...'
      noOptionsText='No scenario found'
      renderOption={(props, option) => (
        <MenuItem {...props} sx={{ whiteSpace: 'normal' }} key={option?.scenarioId}>
          {option?.scenarioCode}
        </MenuItem>
      )}
      isOptionEqualToValue={(option, value) => option?.scenarioId === value?.scenarioId}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          FormHelperTextProps={{
            style: { marginLeft: '5px' }
          }}
        />
      )}
    />
  )
}

export { AccountCodeSelect, PassiveScenarioSelect }