import { useState } from 'react'
import { API } from 'aws-amplify'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'

const RerunPolicyAndOptimization = (props) => {
  const { data, getOptRuns, accessibleOptions, setSelectedAccounts, selectedAccounts, setBulkRerunLoader } = props
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuItemClick = (option) => {
    if (option === 'Re-run Policies') {
      setBulkRerunLoader(true)
      const accIdsSet = new Set()
      data.forEach(account => {
        account.optDetails.forEach(opt => {
          if (selectedAccounts.includes(opt?.runId)) {
            accIdsSet.add(account?.accountId)
          }
        })
      })
      API.post('baseDataValidation', `data-validation/v1/${user?.userGroup}/trigger/policyCheck/accOptMap`, {
        body: {
          accountId: [...accIdsSet]
        }
      }).then((res) => {
        if (res && res?.success) {
          getOptRuns()
          showSuccess(res?.message)
          setSelectedAccounts([])
        }
      }).catch((error) => {
        setBulkRerunLoader(false)
        showError(error, false, {}, 'Failed to re-run policy', true)
      })
    }
    if (option === 'Re-run Optimization') {
      setBulkRerunLoader(true)
      const accIdsSet = new Set()
      data.forEach(account => {
        account.optDetails.forEach(opt => {
          if (selectedAccounts.includes(opt?.runId)) {
            accIdsSet.add(account?.accountId)
          }
        })
      })
      API.post(
        'baseOptimizationURL',
        `optimization/v1/${user?.userGroup}/rerun-optimization`,
        {
          body: {
            accountIds: [...accIdsSet]
          }
        }
      )
        .then((response) => {
          if (response?.success) {
            showSuccess(response?.message)
            setSelectedAccounts([])
          }
        })
        .catch(error => {
          showError(error, false, {}, 'Failed to run optimization.', true)
        }).finally(() => setBulkRerunLoader(false))
    }
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        sx={{
          padding: 1,
          borderRadius: '10px',
          border: '2px solid #dee2e6',
          marginLeft: '8px'
        }}
        aria-label='more'
        aria-haspopup='true'
        onClick={(event) => {
          setAnchorEl(event.currentTarget)
        }}
      >
        <Tooltip title='Re-run Policies/Optimization'>
          <MoreVertIcon fontSize='small' sx={{ color: '#74788D' }} />
        </Tooltip>
      </IconButton>
      <Menu
        id='long-menu'
        elevation={4}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {accessibleOptions?.map((option) => (
          <MenuItem key={option?.label} onClick={() => handleMenuItemClick(option?.label)} disabled={!(selectedAccounts && selectedAccounts.length)}>
            {option?.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default RerunPolicyAndOptimization
