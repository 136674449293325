import React, { useCallback, useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Circle, CloseOutlined, RefreshOutlined, TableViewOutlined, TryOutlined, ExpandMore, ExpandLess } from '@mui/icons-material'
import { Box, Button, Card, CardContent, Divider, Grid, IconButton, Modal, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, gridClasses, useGridApiRef } from '@mui/x-data-grid-pro'
import { DateRangePicker } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // Import the UTC plugin
import eyefill from '../../../assets/images/eye-fill.svg'
import '../../../assets/styles/AccountDetailspage.scss'
import { HtmlTooltip } from '../../../components/CustomTooltip'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import ViewOptPropertiesHistory from './ViewOptproperties'
import ViewTradeApprovedModal from './ViewTradeApprovedModal'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import { moduleConfig } from '../../../contexts/data.js'
import FixTradeStatusColumnCell from './FixTradeStatusColumnCell.js'
import TradeTimeline from './TradeTimeline/TradeTimeline.js'

dayjs.extend(utc)

const tradeModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 4,
  py: 2,
  width: '100%',
  minWidth: '600px',
  maxWidth: '1140px',
  borderRadius: '4px',
  '.MuiDataGrid-root': {
    border: 'none'
  },
  '.MuiDataGrid-main': {
    // remove overflow hidden overwise sticky does not work
    overflow: 'unset'
  },
  '.MuiDataGrid-columnHeaders': {
    position: 'sticky',
    top: 0,
    zIndex: 99,
    background: 'white'
  },
  '.MuiDataGrid-virtualScroller': {
    overflowY: 'auto !important',
    scrollbarGutter: 'stable',
    scrollbarWidth: 'none',
    marginTop: 'auto !important'
  }
}

const TradeApprovedMain = () => {
  const apiRef = useGridApiRef()
  const [pageLoad, setPageLoad] = useState(true)
  const [optmRun, setOptmRun] = useState([])
  const [isShown, setIsShown] = useState(false)
  const [date, setDate] = useState([dayjs.utc(), dayjs.utc()])
  const [editIndex, setEditIndex] = useState()
  const [noData, setNoData] = useState()
  // const [optmRunSum, setOptmRunSum] = useState([])
  const [isProShow, setProShow] = useState(false)
  const { showError } = useErrorToast()
  const { user, checkAccess } = useAuth()
  const [loading, setLoading] = useState(true)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [datagridState, setDatagridState] = useState({})
  // const [syncIconClick, setSyncIconClick] = useState({})

  const inProgressRefreshIconAccess = checkAccess(
    moduleConfig.TRADE,
    ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.TRADE_HISTORY, component_name: moduleConfig.TRADE_STATUS_REFRESH }
  )

  const resumeTradeAccess = checkAccess(
    moduleConfig.ARIS_INTERNAL,
    ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.ARIS_INTERNAL, component_name: moduleConfig.ALLOW_RESUME_TRADE }
  )

  const viewTradeAdhocAccess = checkAccess(
    moduleConfig.ARIS_INTERNAL,
    ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.ARIS_INTERNAL, component_name: moduleConfig.VIEW_TRADE_ADHOC }
  )

  const showTradeWorkflowAccess = checkAccess(
    moduleConfig.TRADE,
    ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.TRADE_HISTORY, component_name: moduleConfig.SHOW_TRADE_WORKFLOW }
  )

  useEffect(() => {
    if (user) {
      dateFilterHandler()
    }
  }, [user])

  const getoptimrun = (fromDate, toDate) => {
    if (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: moduleConfig.TRADE_HISTORY })) {
      let currentDataDate = new Date().toISOString().slice(0, 10)
      if (!pageLoad) {
        currentDataDate = new Date().toISOString().slice(0, 10)
      }
      fromDate = fromDate || currentDataDate
      toDate = toDate || currentDataDate
      API.get(
        'baseOptimizationURL',
        `optimization/v1/${user?.userGroup}/trade-history?from_date=${fromDate}&to_date=${toDate}`,
        {
          queryStringParameters: { resources: encodeURIComponent(JSON.stringify({ serviceId: 'trade', resourceId: 'trade-history' })) }
        }
      )
        .then((response) => {
          if (response.data.length === 0) {
            setNoData(response.message)
            setPageLoad(false)
            setOptmRun([])
          } else {
            setOptmRun(response?.data?.map((obj, index) => ({ ...obj, id: randomId(), idx: index })))
          }
          setPageLoad(false)
          setLoading(false)
        })
        .catch((error) => {
          showError(error, false, {}, 'Failed to load trade history data.', true)
          setLoading(false)
          setNoData(error?.response?.data?.errorInfo?.userMessage || error.message)
        })
    }
  }

  const updateOptmRun = (data, optMapId) => {
    const index = optmRun.findIndex((obj) => obj?.optDetails[0]?.accOptMapId === optMapId)
    if (index > -1) {
      setOptmRun(prevOptmRun => {
        const newArray = [...prevOptmRun]
        newArray[index].optDetails[0].summaryDetails = {
          ...newArray[index]?.optDetails[0]?.summaryDetails,
          numBuyFilled: data.num_buy_filled,
          numBuyPartialFilled: data.num_buy_partial_filled,
          numBuyFailed: data.num_buy_failed,
          numSellFilled: data.num_sell_filled,
          numSellPartialFilled: data.num_sell_partial_filled,
          numSellFailed: data.num_sell_failed,
          fixTradeStatus: data.fix_trade_status
        }
        return newArray
      })
    }
  }

  const updateOptmRunStatus = (data, optMapId) => {
    const index = optmRun.findIndex((obj) => obj?.optDetails?.length && obj?.optDetails[0]?.accOptMapId === optMapId)
    if (index > -1) {
      setOptmRun(prevOptmRun => {
        const newArray = [...prevOptmRun]
        newArray[index].optDetails[0].summaryDetails = {
          ...newArray[index]?.optDetails[0]?.summaryDetails,
          ...data
        }
        return newArray
      })
    }
  }

  const viewHandleShow = (index) => {
    setEditIndex(index)
    setIsShown(true)
  }

  const ViewOptmProperties = (index) => {
    setEditIndex(index)
    setProShow(true)
  }

  const getIconWithColor = (tradeStatus, proposedField, completedField, partialField, failedField) => {
    let color = '#c9c9c9'
    if (!tradeStatus || tradeStatus === 'TRADE_IN_PROGRESS') {
      return color
    }
    if (tradeStatus === 'TRADE_STOPPED') {
      return '#d29922'
    }
    if ((failedField > 0) || (proposedField > (completedField + partialField + failedField))) {
      color = '#ff6161'
    } else if (partialField > 0) {
      color = '#d29922'
    } else if (completedField === proposedField && completedField !== 0) {
      color = '#3BBFA3'
    }
    return color
  }

  const wholeNumberCurrencyFormatter = (amount) => {
    return Math.abs(Number(amount)) >= 1.0e9
      ? (Math.abs(Number(amount)) / 1.0e9).toFixed(0) + 'B'
      : Math.abs(Number(amount)) >= 1.0e6
        ? (Math.abs(Number(amount)) / 1.0e6).toFixed(0) + 'M'
        : Math.abs(Number(amount)) >= 1.0e3
          ? (Math.abs(Number(amount)) / 1.0e3).toFixed(0) + 'K'
          : Math.abs(Number(amount)).toFixed(0)
  }

  const tableHeaders = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      minWidth: 40,
      maxWidth: 40,
      align: 'center'
    },
    ...(
      (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_HISTORY, id: null, component_name: moduleConfig.VIEW_TRADE_DETAILS }) || checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_HISTORY, id: null, component_name: moduleConfig.VIEW_TRADE_PROPERTIES }))
        ? [{
            field: 'action',
            headerName: '',
            width: 80,
            cellClassName: 'history-action',
            renderCell: (params) => (
              <>
                {checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_HISTORY, id: null, component_name: moduleConfig.VIEW_TRADE_DETAILS })
                  ? <IconButton
                      className='action-clock-btn'
                      onClick={() => {
                        viewHandleShow(params.row?.idx)
                      }}
                    >
                    <Tooltip title='Trade History Details'>
                      <img src={eyefill} alt='eyefill' />
                    </Tooltip>
                  </IconButton>
                  : ''}
                {checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_HISTORY, id: null, component_name: moduleConfig.VIEW_TRADE_PROPERTIES })
                  ? <IconButton
                      className='action-clock-btn'
                      onClick={() => {
                        ViewOptmProperties(params.row?.idx)
                      }}
                    >
                    <Tooltip title='View Personalization'>
                      <TableViewOutlined style={{ color: '#74788D' }} />
                    </Tooltip>
                  </IconButton>
                  : ''}
              </>
            )
          }]
        : []
    ),
    {
      field: 'accountCd',
      headerName: 'Account Code',
      width: 105,
      renderCell: (params) => params.row?.accountCd || 'N/A'
    },
    {
      field: 'accountName',
      headerName: 'Account Name',
      width: 120,
      renderCell: (params) => params.row?.accountName || 'N/A'
    },
    {
      field: 'tradeDate',
      headerName: 'Trade Date',
      type: 'date',
      valueGetter: (params) => {
        const tradeDate = params?.row?.optDetails?.length ? params?.row?.optDetails[0]?.summaryDetails?.tradeDate || '' : null
        if (!tradeDate) return null
        const date = new Date(tradeDate)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.optDetails?.length ? params?.row?.optDetails[0]?.summaryDetails?.tradeDate?.split('T')[0] || '' : ''
    },
    {
      field: 'fixTradeStatus',
      headerName: 'FIX Trade Status',
      width: 160,
      renderCell: (params) => (<FixTradeStatusColumnCell {...params} updateOptmRun={updateOptmRun} inProgressRefreshIconAccess={inProgressRefreshIconAccess} />),
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.fixTradeStatus
    },
    {
      field: 'numSell',
      headerName: 'Sell Trades Proposals',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => {
        const proposedSell = params.row?.optDetails[0]?.summaryDetails?.numSell || 0
        const completedSell = params.row?.optDetails[0]?.summaryDetails?.numSellFilled || 0
        const partialSell = params.row?.optDetails[0]?.summaryDetails?.numSellPartialFilled || 0
        const failedSell = params.row?.optDetails[0]?.summaryDetails?.numSellFailed || 0
        const tradeStatus = params.row?.optDetails[0]?.summaryDetails?.fixTradeStatus

        return <><TableCell sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>{proposedSell}<Circle fontSize='small' sx={{ width: '10px', height: '10px', color: getIconWithColor(tradeStatus, proposedSell, completedSell, partialSell, failedSell) }} /></TableCell></>
      },
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.numSell
    },
    {
      field: 'numSellFilled',
      headerName: 'Sell Trades Filled',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.row?.optDetails[0]?.summaryDetails?.numSellFilled || 0,
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.numSellFilled
    },
    {
      field: 'numSellPartialFilled',
      headerName: 'Sell Trades Partial Filled',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.row?.optDetails[0]?.summaryDetails?.numSellPartialFilled || 0,
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.numSellPartialFilled
    },
    {
      field: 'numSellFailed',
      headerName: 'Sell Trades Failed',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.row?.optDetails[0]?.summaryDetails?.numSellFailed || 0,
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.numSellFailed
    },
    {
      field: 'numBuy',
      headerName: 'Buy Trades Proposals',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => {
        const proposedBuy = params.row?.optDetails[0]?.summaryDetails?.numBuy || 0
        const completedBuy = params.row?.optDetails[0]?.summaryDetails?.numBuyFilled || 0
        const partialBuy = params.row?.optDetails[0]?.summaryDetails?.numBuyPartialFilled || 0
        const failedBuy = params.row?.optDetails[0]?.summaryDetails?.numBuyFailed || 0
        const tradeStatus = params.row?.optDetails[0]?.summaryDetails?.fixTradeStatus

        return <><TableCell sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>{proposedBuy || 0}<Circle fontSize='small' sx={{ width: '10px', height: '10px', color: getIconWithColor(tradeStatus, proposedBuy, completedBuy, partialBuy, failedBuy) }} /></TableCell></>
      },
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.numBuy
    },
    {
      field: 'numBuyFilled',
      headerName: 'Buy Trades Filled',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.row?.optDetails[0]?.summaryDetails?.numBuyFilled || 0,
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.numBuyFilled
    },
    {
      field: 'numBuyPartialFilled',
      headerName: 'Buy Trades Partial Filled',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.row?.optDetails[0]?.summaryDetails?.numBuyPartialFilled || 0,
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.numBuyPartialFilled
    },
    {
      field: 'numBuyFailed',
      headerName: 'Buy Trades Failed',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.row?.optDetails[0]?.summaryDetails?.numBuyFailed || 0,
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.numBuyFailed
    },
    {
      field: 'scenarioDesc',
      headerName: 'Scenario',
      renderCell: (params) => params.row?.optDetails[0]?.scenarioDesc,
      valueGetter: (params) => params.row?.optDetails[0]?.scenarioDesc
    },
    {
      field: 'isDefault',
      headerName: 'Default',
      renderCell: (params) => params.row?.optDetails[0].isDefault === 0 ? 'No' : 'Yes',
      valueGetter: (params) => params.row?.optDetails[0]?.isDefault
    },
    {
      field: 'initTe',
      headerName: 'Init TE',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.row?.optDetails[0]?.summaryDetails?.initTe?.toFixed(2) || 0,
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.initTe
    },
    {
      field: 'propTe',
      headerName: 'Prop TE',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.row?.optDetails[0]?.summaryDetails?.propTe?.toFixed(2) || 0,
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.propTe
    },
    {
      field: 'taxCost',
      headerName: 'Total Tax',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => !isNaN(params.row?.optDetails[0]?.summaryDetails?.taxCost)
        ? params.row?.optDetails[0]?.summaryDetails?.taxCost < 0
          ? `-$${wholeNumberCurrencyFormatter(params.row?.optDetails[0]?.summaryDetails?.taxCost)}`
          : `$${wholeNumberCurrencyFormatter(params.row?.optDetails[0]?.summaryDetails?.taxCost)}`
        : 'N/A',
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.taxCost
    },
    {
      field: 'totalTurnover',
      headerName: 'Total Turnover',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.row?.optDetails[0]?.summaryDetails?.totalTurnover?.toFixed(0) || 0,
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.totalTurnover
    },
    {
      field: 'initNumHoldings',
      headerName: 'Init Holdings',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.row?.optDetails[0]?.summaryDetails?.initNumHoldings || 0,
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.initNumHoldings
    },
    {
      field: 'numSec',
      headerName: 'Prop Holdings',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.row?.optDetails[0]?.summaryDetails?.numSec || 0,
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.numSec
    },

    {
      field: 'initRisk',
      headerName: 'Init Risk',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.row?.optDetails[0]?.summaryDetails?.initRisk?.toFixed(2) || 0,
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.initRisk
    },
    {
      field: 'preOptAlpha',
      headerName: 'Pre-opt Alpha',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.row?.optDetails[0]?.summaryDetails?.preOptAlpha === null ? 0 : params.row?.optDetails[0]?.summaryDetails?.preOptAlpha,
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.preOptAlpha
    },
    {
      field: 'tradeAge',
      headerName: 'Trade Age',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      renderCell: (params) => params.row?.optDetails[0]?.summaryDetails?.tradeAge !== null && params.row?.optDetails[0]?.summaryDetails?.tradeAge !== undefined ? params.row?.optDetails[0]?.summaryDetails?.tradeAge : 'N/A',
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.tradeAge
    },
    {
      field: 'isModelChanged',
      headerName: 'Model Changed',
      headerAlign: 'right',
      align: 'right',
      type: 'boolean',
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.isModelChanged,
      renderCell: (params) => params.row?.optDetails[0]?.summaryDetails?.isModelChanged !== undefined && params.row?.optDetails[0]?.summaryDetails?.isModelChanged !== null ? params.row?.optDetails[0]?.summaryDetails?.isModelChanged ? 'YES' : 'NO' : 'N/A'
    },
    {
      field: 'initUtil',
      headerName: 'Init Utility',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.row?.optDetails[0]?.summaryDetails?.initUtil?.toFixed(2) || 0,
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.initUtil
    },
    {
      field: 'portMv',
      headerName: 'Portfolio MV',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => !isNaN(params.row?.optDetails[0]?.summaryDetails?.portMv)
        ? params.row?.optDetails[0]?.summaryDetails?.portMv < 0
          ? `-$${wholeNumberCurrencyFormatter(params.row?.optDetails[0]?.summaryDetails?.portMv)}`
          : `$${wholeNumberCurrencyFormatter(params.row?.optDetails[0]?.summaryDetails?.portMv)}`
        : 'N/A',
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.portMv
    },
    {
      field: 'propLongRgl',
      headerName: 'Prop LT RGL',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => !isNaN(params.row?.optDetails[0]?.summaryDetails?.propLongRgl)
        ? params.row?.optDetails[0]?.summaryDetails?.propLongRgl < 0
          ? `-$${wholeNumberCurrencyFormatter(params.row?.optDetails[0]?.summaryDetails?.propLongRgl)}`
          : `$${wholeNumberCurrencyFormatter(params.row?.optDetails[0]?.summaryDetails?.propLongRgl)}`
        : 'N/A',
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.propLongRgl
    },
    {
      field: 'propShortRgl',
      headerName: 'Prop ST RGL',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => !isNaN(params.row?.optDetails[0]?.summaryDetails?.propShortRgl)
        ? params.row?.optDetails[0]?.summaryDetails?.propShortRgl < 0
          ? `-$${wholeNumberCurrencyFormatter(params.row?.optDetails[0]?.summaryDetails?.propShortRgl)}`
          : `$${wholeNumberCurrencyFormatter(params.row?.optDetails[0]?.summaryDetails?.propShortRgl)}`
        : 'N/A',
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.propShortRgl
    },
    {
      field: 'initLtUrgl',
      headerName: 'Init LT URGL',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => !isNaN(params.row?.optDetails[0]?.summaryDetails?.initLtUrgl)
        ? params.row?.optDetails[0]?.summaryDetails?.initLtUrgl < 0
          ? `-$${wholeNumberCurrencyFormatter(params.row?.optDetails[0]?.summaryDetails?.initLtUrgl)}`
          : `$${wholeNumberCurrencyFormatter(params.row?.optDetails[0]?.summaryDetails?.initLtUrgl)}`
        : 'N/A',
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.initLtUrgl
    },
    {
      field: 'initStUrgl',
      headerName: 'Init ST URGL',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => !isNaN(params.row?.optDetails[0]?.summaryDetails?.initStUrgl)
        ? params.row?.optDetails[0]?.summaryDetails?.initStUrgl < 0
          ? `-$${wholeNumberCurrencyFormatter(params.row?.optDetails[0]?.summaryDetails?.initStUrgl)}`
          : `$${wholeNumberCurrencyFormatter(params.row?.optDetails[0]?.summaryDetails?.initStUrgl)}`
        : 'N/A',
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.initStUrgl
    },
    {
      field: 'propLtUrgl',
      headerName: 'Prop LT URGL',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => !isNaN(params.row?.optDetails[0]?.summaryDetails?.propLtUrgl)
        ? params.row?.optDetails[0]?.summaryDetails?.propLtUrgl < 0
          ? `-$${wholeNumberCurrencyFormatter(params.row?.optDetails[0]?.summaryDetails?.propLtUrgl)}`
          : `$${wholeNumberCurrencyFormatter(params.row?.optDetails[0]?.summaryDetails?.propLtUrgl)}`
        : 'N/A',
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.propLtUrgl
    },
    {
      field: 'propStUrgl',
      headerName: 'Prop ST URGL',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => !isNaN(params.row?.optDetails[0]?.summaryDetails?.propStUrgl)
        ? params.row?.optDetails[0]?.summaryDetails?.propStUrgl < 0
          ? `-$${wholeNumberCurrencyFormatter(params.row?.optDetails[0]?.summaryDetails?.propStUrgl)}`
          : `$${wholeNumberCurrencyFormatter(params.row?.optDetails[0]?.summaryDetails?.propStUrgl)}`
        : 'N/A',
      valueGetter: (params) => params.row?.optDetails[0]?.summaryDetails?.propStUrgl
    },
    {
      field: 'optRemarks',
      headerName: 'Trade Remarks',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row?.optDetails[0]?.optRemarks) {
          return (
            <HtmlTooltip tooltipStyle={{ marginRight: '10px' }}
              title={
                <>
                  <Typography color='inherit' fontFamily='Open Sans' fontWeight={600}>Trade Remarks</Typography>
                  <Divider sx={{ backgroundColor: '#000000' }} />
                  <Typography sx={{ fontSize: '14px' }} my={1}>{params.row?.optDetails[0]?.optRemarks}</Typography>
                </>
              }
            >
              <TryOutlined />
            </HtmlTooltip>
          )
        } else {
          return null
        }
      }
    }

  ]

  const getRowId = (row) => row.id
  const tableHeaderHTML = tableHeaders.map(({ headerName }, index) => <TableCell key={index}>{headerName}</TableCell>)
  const rowSkeleton = tableHeaders.map(({ headerName }, index) => (
    <TableCell key={index}>
      {headerName === 'Action'
        ? <Skeleton variant='circular' width={20} height={20} sx={{ ml: '10px' }} />
        : <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
      }
    </TableCell>
  ))

  const dateFilterHandler = () => {
    let fromDateData, toDateData
    setLoading(true)
    fromDateData = date[0].format('YYYY-MM-DD')
    toDateData = date[1].format('YYYY-MM-DD')
    getoptimrun(fromDateData, toDateData)
  }

  const handleDateChange = (date) => {
    if (!date[0] || !date[1]) {
      setDisableSubmit(true)
      return
    }
    setDate(date)
    setDisableSubmit(false)
  }

  useEffect(() => {
    if (apiRef?.current?.restoreState && datagridState) {
      apiRef?.current?.restoreState({
        pinnedColumns: datagridState.pinnedColumns,
        columns: datagridState.columns
      })
    }
  }, [date, loading, optmRun, disableSubmit, pageLoad, isShown, isProShow, datagridState])

  const storeDatagridState = () => {
    if (apiRef?.current?.exportState) {
      setDatagridState(apiRef?.current?.exportState())
    }
  }

  const getDetailPanelContent = useCallback(
    ({ row }) => <TradeTimeline row={row} refreshAccess={inProgressRefreshIconAccess} resumeTradeAccess={resumeTradeAccess} updateOptmRunStatus={updateOptmRunStatus} viewTradeAdhocAccess={viewTradeAdhocAccess} updateCurrentRow={updateOptmRun} />,
    [optmRun]
  )

  const getDetailPanelHeight = useCallback(() => 'auto', [])

  return (
    <Box className='Account-trade'>
      <Box
        sx={{
          display: 'flex',
          alignItems: { xs: 'start', md: 'center' },
          justifyContent: 'space-between'
        }}
        className='tradeMainhead'
      >
        <Typography component='h3' className='page-title' sx={{ marginRight: '20px', whiteSpace: 'nowrap' }}>Trade History</Typography>

        <Grid
          container
          sx={{ alignItems: 'flex-end', gap: 2, marginLeft: 'auto', justifyContent: 'flex-end' }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              value={date}
              onChange={handleDateChange}
              disableFuture
              format='YYYY-MM-DD'
              slotProps={{ textField: { size: 'small' } }}
              sx={{
                '.MuiMultiInputDateRangeField-separator': {
                  marginLeft: '8px'
                },
                '.MuiTextField-root:last-child': {
                  marginLeft: '8px'
                },
                '.MuiInputBase-input': {
                  padding: '6px 14px'
                }
              }}
              localeText={{ start: 'From Date', end: 'To Date' }}
            />
          </LocalizationProvider>
          <Grid item>
            <Button
              variant='contained'
              disabled={disableSubmit}
              onClick={() => dateFilterHandler()}
            >
              Submit
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='outlined'
              onClick={() => dateFilterHandler()}
              disabled={disableSubmit}
              sx={{
                border: '2px solid #dee2e6',
                padding: 1,
                minWidth: 'auto',
                ':hover': {
                  background: 'transparent',
                  border: '2px solid #dee2e6'
                }
              }}
              className='tooltip-trade'
            >
              <RefreshOutlined sx={{ height: 20, width: 20, color: '#74788D' }} />
              <span
                className='tooltiptext'
                style={{ marginLeft: '-15px' }}
              >
                Refresh
              </span>
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Grid container sx={{ my: '20px' }}>
        <Grid item xs={12}>
          {loading
            ? (
              <Card>
                <CardContent>
                  <Box className='table-responsive'>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {tableHeaderHTML}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>{rowSkeleton}</TableRow>
                        <TableRow>{rowSkeleton}</TableRow>{' '}
                      </TableBody>
                    </Table>
                  </Box>
                </CardContent>
              </Card>
              )
            : (
              <Card>
                <CardContent>
                  <Box>
                    {optmRun === undefined || optmRun?.length === 0
                      ? (
                        <>{noData || 'No trade found for given date'}</>
                        )
                      : (
                        <>
                          <DataGridPro
                            apiRef={apiRef}
                            density='compact'
                            columns={tableHeaders}
                            rows={optmRun}
                            autoHeight
                            {...(
                              showTradeWorkflowAccess
                                ? {
                                    getDetailPanelContent,
                                    getDetailPanelHeight,
                                    slots: {
                                      detailPanelExpandIcon: ExpandMore,
                                      detailPanelCollapseIcon: ExpandLess
                                    }
                                  }
                                : {}
                            )}
                            pageSizeOptions={[15]}
                            initialState={{
                              pagination: { paginationModel: { pageSize: 15 } }
                            }}
                            pagination
                            getRowId={getRowId}
                            onColumnWidthChange={storeDatagridState}
                            onColumnOrderChange={storeDatagridState}
                            sx={(theme) => ({
                              [`& .${gridClasses.detailPanel}`]: {
                                overflow: 'visible'
                              },
                              [`.${gridClasses.columnHeaderTitle}`]: {
                                lineHeight: 1.4,
                                whiteSpace: 'pre-line',
                                '&.hover': {
                                  textOverflow: 'ellipsis'
                                }
                              }
                            })}
                          />
                          <Modal
                            open={isShown}
                            onClose={() => setIsShown(false)}
                          >
                            <Box sx={{
                              ...tradeModalStyle,
                              height: '100vh',
                              transform: 'none',
                              top: 0,
                              left: 0,
                              width: '100vw',
                              maxWidth: 'auto'
                            }}>
                              <ViewTradeApprovedModal
                                selectedItem={optmRun[editIndex]}
                                index={editIndex}
                                setIsShown={setIsShown}
                              />
                            </Box>
                          </Modal>
                          <Modal
                            open={isProShow}
                            onClose={() => setProShow(false)}
                          >
                            <Box sx={{
                              ...tradeModalStyle,
                              maxWidth: '1000px',
                              height: '600px',
                              maxHeight: '100vh',
                              '.MuiDataGrid-main': {
                                height: 'calc(min(600px, 100vh) - 16px - 16px - 40px - 48px - 53px)'
                              }
                            }}
                            >
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography style={{ textAlign: 'left', fontSize: '22px', fontWeight: 400 }}>View Personalization</Typography>
                                <IconButton aria-label='close' style={{ marginRight: '-10px' }} onClick={() => setProShow(false)}>
                                  <CloseOutlined />
                                </IconButton>
                              </Box>
                              <ViewOptPropertiesHistory
                                selectedItem={optmRun[editIndex]}
                                index={editIndex}
                              />
                            </Box>

                          </Modal>
                        </>
                        )}
                  </Box>
                </CardContent>
              </Card>
              )}
        </Grid>
      </Grid>
    </Box>
  )
}
export default TradeApprovedMain
