import { ErrorBoundary } from 'react-error-boundary'
import React, { useState } from 'react'
import { API } from 'aws-amplify'
import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { Button, Dialog, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GridActionsCellItem, gridClasses, GridRowModes, useGridApiRef } from '@mui/x-data-grid-pro'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useAuth } from '../../../contexts/AuthContext'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import { isNumberWithSign, isWholeNumber, numberWithSignPasteHandler, wholeNumberPasteHandler } from '../../../utils/NumberUtils'
import ErrorFallback from '../../ErrorFallback'
import Loader from '../../Loader'
import AddPropertyRecordPopup from './AddPropertyRecordPopup'
import { AccountCodeSelect, PassiveScenarioSelect } from './InputComponent'

const getRowId = (row) => row?.id

const TradeOptProperties = (props) => {
  const { approvalPropertiesLoading, approvalProperties, propertyCodes, isPropertyCodesLoading, setApprovalProperties, selectedScenarioId, allScenarioList, optMapId, accountList, showCompact } = props
  const { user, checkAccess } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [rowModesModel, setRowModesModel] = useState({})
  const [addPropertyPopupOpen, setAddPropertyPopupOpen] = useState(false)
  const [isApiLoading, setIsApiLoading] = useState(false)
  const apiRef = useGridApiRef()
  const canAccessCrudOnProperties = checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.MANAGE_ADHOC_OPT_PROPERTIES })

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id) => () => {
    let updatedRow = apiRef.current.getRowWithUpdatedValues(id)

    if (updatedRow.scenarioDescription === null || updatedRow.propertyCode === null || updatedRow.propertyValue === null || updatedRow.propertyValue === '' || updatedRow?.propertyValue === updatedRow?.propertyValueCopy) {
      return
    }
    setIsApiLoading(true)
    // update source and value copy variables on saving new row
    if (updatedRow?.propertyValue !== updatedRow?.propertyValueCopy) {
      updatedRow = { ...updatedRow, propertyValueCopy: updatedRow.propertyValue, propertySource: 'ADHOC', propertySourceCopy: 'ADHOC' }
    } else {
      updatedRow = { ...updatedRow, propertySource: updatedRow?.propertySourceCopy }
    }
    const payload = {
      propertyData: [
        {
          propertyCode: updatedRow.propertyCode,
          propertyValue: updatedRow.propertyValue,
          propertySource: updatedRow.propertySource
        }
      ]
    }
    API.patch(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/opt-properties/${optMapId}`,
      {
        body: payload
      }
    )
      .then((response) => {
        if (response.success) {
          showSuccess(response.message)
          const updatedProperties = approvalProperties.map((row) => (row.id === updatedRow.id ? updatedRow : row))
          setApprovalProperties(updatedProperties)
          setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to update optimization property.', true)
      })
      .finally(() => {
        setIsApiLoading(false)
      })
  }

  const handleDeleteClick = (id, row) => () => {
    setIsApiLoading(true)
    const payload = {
      propertyCodes: [row.propertyCode]
    }
    API.del(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/opt-properties/${optMapId}`,
      {
        body: payload
      }
    )
      .then((response) => {
        if (response.success) {
          showSuccess(response.message)
          const updatedProperties = approvalProperties.filter((row) => row.id !== id)
          const updatedPropertiesWithErrors = updatedProperties.map((row) => ({
            ...row,
            hasError: updatedProperties?.some(
              (otherRow) =>
                otherRow.id !== row.id &&
                otherRow.propertyCode === row.propertyCode &&
                Number(otherRow.scenarioId) === Number(row.scenarioId) && row.propertySource === 'ADHOC'
            )
          }))
          setApprovalProperties(updatedPropertiesWithErrors)
          setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to delete optimization property.', true)
      })
      .finally(() => {
        setIsApiLoading(false)
      })
  }

  const handleCancelClick = (id, row) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const handleAddRecordClick = (values) => {
    const id = randomId()
    const newRow = {
      id,
      isNew: true,
      ...values,
      scenarioDescription: values.scenarioDesc,
      propertyValueCopy: null,
      propertySourceCopy: null,
      hasError: false
    }
    // show error if property already exists in the table
    const hasError = approvalProperties.some(
      (otherProperty) =>
        otherProperty.propertyCode === newRow.propertyCode &&
        Number(otherProperty.scenarioId) === Number(newRow.scenarioId)
    )
    if (hasError) {
      showError('Property already exists for this scenario')
    } else {
      setIsApiLoading(true)
      const payload = {
        propertyData: [
          {
            propertyCode: newRow.propertyCode,
            propertyValue: newRow.propertyValue,
            propertySource: newRow.propertySource
          }
        ]
      }
      API.post(
        'baseOptimizationURL',
        `optimization/v1/${user?.userGroup}/opt-properties/${optMapId}`,
        {
          body: payload
        }
      )
        .then((response) => {
          if (response.success) {
            showSuccess(response.message)
            const updatedNewRow = { ...newRow }
            const updatedApprovalProperties = [updatedNewRow, ...approvalProperties]
            setApprovalProperties(updatedApprovalProperties)
            setAddPropertyPopupOpen(false)
          }
        })
        .catch((error) => {
          showError(error, false, {}, 'Failed to add optimization property.', true)
        })
        .finally(() => {
          setIsApiLoading(false)
        })
    }
  }

  const getEditCellFromType = (params, propertyType) => {
    switch (propertyType) {
      case 'INT':
        return (
          <TextField
            fullWidth
            value={params?.value}
            onChange={(e) => {
              params?.api?.setEditCellValue({ id: params?.row?.id, field: params?.field, value: e.target.value })
            }}
            onKeyDown={isWholeNumber}
            onPaste={wholeNumberPasteHandler}
          />
        )
      case 'DECIMAL':
        return (
          <TextField
            fullWidth
            value={params?.value}
            onChange={(e) => {
              params?.api?.setEditCellValue({ id: params?.row?.id, field: params?.field, value: e.target.value })
            }}
            onKeyDown={isNumberWithSign}
            onPaste={numberWithSignPasteHandler}
          />
        )
      case 'STRING':
      case 'BOOLEAN':
        return (
          <TextField
            fullWidth
            value={params?.value}
            onChange={(e) => {
              params?.api?.setEditCellValue({ id: params?.row?.id, field: params?.field, value: e.target.value })
            }}
          />
        )
      case 'DATE':
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={dayjs(params?.value)}
              onChange={(value) => {
                params?.api?.setEditCellValue({ id: params?.row?.id, field: params?.field, value: dayjs(value).format('YYYY-MM-DD') })
              }}
              format='YYYY-MM-DD'
              fullWidth
            />
          </LocalizationProvider>
        )
      default:
        return ''
    }
  }

  const propertiesColumns = [
    {
      field: 'scenarioDescription',
      headerName: 'Scenario Description',
      flex: 1
    },
    {
      field: 'propertyCode',
      headerName: 'Property Code',
      flex: 1
    },
    {
      field: 'propertyValue',
      headerName: 'Property Value',
      editable: true,
      flex: 1,
      align: 'right',
      renderCell: (params) => {
        if (params?.row?.propertyCode === 'DI_ASSIST_ACC_LINK') {
          if (!accountList?.loading) {
            const account = accountList?.data?.find(acc => acc?.accountCode === params?.row?.propertyValue)
            return `${account?.accountName} (${account?.accountCode})`
          }
          return params?.value
        }
        else if (params?.row?.propertyCode === 'DI_PASSIVE_SCENARIO') {
          const scenarioObj = allScenarioList?.find(scenario => scenario?.scenarioId === Number(params?.row?.propertyValue))
          return scenarioObj?.scenarioCode
        }
      },
      renderEditCell: (params) => {
        const propertyIndex = propertyCodes?.findIndex(obj => obj?.propertyCode === params?.row?.propertyCode)
        if (propertyIndex > -1) {
          if (params?.row?.propertyCode === 'DI_ASSIST_ACC_LINK') {
            const handleChange = (e, value) => params?.api?.setEditCellValue({ id: params?.id, field: params?.field, value: value?.accountCode || '' })
            return <AccountCodeSelect accountList={accountList} onChange={handleChange} value={params?.row?.propertyValue || ''} />
          } else if (params?.row?.propertyCode === 'DI_PASSIVE_SCENARIO') {
            const handleChange = (e, value) => params?.api?.setEditCellValue({ id: params?.row?.id, field: params?.field, value: value?.scenarioId || '' })
            return <PassiveScenarioSelect allScenarioList={allScenarioList} onChange={handleChange} value={params?.value || ''} />
          }
          return getEditCellFromType(params, propertyCodes[propertyIndex]?.dataType)
        }
        return null
      }
    },
    {
      field: 'propertySource',
      headerName: 'Property Source',
      flex: 1,
      align: 'right',
      headerAlign: 'right'
    },
    ...(canAccessCrudOnProperties ?
      [{
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                key='save'
                icon={<SaveIcon />}
                label='Save'
                onClick={handleSaveClick(id, row)}
              />,
              <GridActionsCellItem
                key='cancel'
                icon={<CancelIcon />}
                label='Cancel'
                className='textPrimary'
                onClick={handleCancelClick(id, row)}
                color='inherit'
              />
            ]
          }
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              disabled={isPropertyCodesLoading}
              key='edit'
              label='Edit'
              className='textPrimary'
              onClick={handleEditClick(id)}
              color='inherit'
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              disabled={isPropertyCodesLoading}
              key='delete'
              label='Delete'
              className='textPrimary'
              onClick={handleDeleteClick(id, row)}
              color='inherit'
            />
          ]
        }
      }] : []
    )
  ]
  const tableHeaderHTML = propertiesColumns.map(({ headerName }, index) => <TableCell key={index} sx={{ padding: '8px' }}>{headerName}</TableCell>)
  const rowSkeleton = propertiesColumns.map((_, index) => <TableCell key={index} sx={{ padding: '8px' }}><Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} /></TableCell>)

  const handleCancelClose = () => {
    setAddPropertyPopupOpen(false)
  }

  if (approvalPropertiesLoading) {
    return (
      <>
        {/* dynamically changes skeleton for all diff personalization tabs */}
        <Table className='security-holdings-table'>
          <TableHead>
            <TableRow style={{ position: 'sticky', top: '0', background: 'white' }}>
              {tableHeaderHTML}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              Array.from({ length: 3 }).map((_, index) => (
                <TableRow key={index}>
                  {rowSkeleton}
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </>
    )
  } else {
    return (
      <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen='not main' />)}>
        {
          isApiLoading
            ? <Loader />
            : null
        }
        {canAccessCrudOnProperties ? <Button variant='text' size='small' startIcon={<AddIcon />} onClick={() => setAddPropertyPopupOpen(true)}>Add Property</Button> : ''}
        <DataGridPro
          density='compact'
          autoHeight
          apiRef={apiRef}
          rows={approvalProperties}
          columns={propertiesColumns}
          loading={approvalPropertiesLoading}
          getRowId={getRowId}
          pagination
          pageSizeOptions={[10, 15, 25, 50, 100]}
          editMode='row'
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          onCellEditStart={handleCellEditStart}
          onCellEditStop={handleCellEditStop}
          rowSelection={false}
          getCellClassName={(params) => params?.row?.hasError === true ? 'error-row' : ''}
          initialState={{
            ...approvalProperties?.initialState,
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          {...(showCompact ? {
            rowHeight: 30,
            columnHeaderHeight: 39,
          } : { density: 'compact' })}
          sx={{
            '.error-row': {
              backgroundColor: '#f05f5f1a'
            },
            [`.${gridClasses.cell}:focus`]: {
              outline: 'none'
            },
            [`.${gridClasses['cell--editing']}`]: {
              outline: 'none'
            },
            [`.${gridClasses['cell--editing']}:focus`]: {
              outline: 'none'
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none'
            },
            '.MuiDataGrid-main': {
              height: `calc(100vh - 16px - 16px - 40px - 48px - 53px ${canAccessCrudOnProperties ? '- 32px' : ''})`
            },
            [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
              color: '#74788d',
              fontWeight: 600
            },
            [`.${gridClasses.virtualScroller}`]: {
              overflowY: 'auto !important',
              scrollbarGutter: 'stable',
              scrollbarWidth: 'none'
            },
            ...(showCompact ? {
              [`.${gridClasses.cell}, .${gridClasses.cellContent}, .${gridClasses.columnHeaderTitle}, .${gridClasses.columnHeaders}`]: {
                fontSize: '12px'
              },
            } : {}
            )
          }}
        />
        {
          addPropertyPopupOpen && (
            <Dialog open={addPropertyPopupOpen} onClose={handleCancelClose} fullWidth maxWidth='sm'>
              <AddPropertyRecordPopup
                handleCancelClick={handleCancelClose}
                allScenarioList={allScenarioList}
                propertiesList={propertyCodes}
                savedData={handleAddRecordClick}
                selectedScenarios={[selectedScenarioId]}
                accountList={accountList}
                defaultScenario={allScenarioList?.find(s => s.scenarioId === selectedScenarioId)}
              />
            </Dialog>
          )
        }
      </ErrorBoundary>
    )
  }
}

export default TradeOptProperties
