import { Button, Tooltip } from '@mui/material'

const OutlinedButton = ({ children, tooltipText, ...buttonProps }) => {
  return (
    <Tooltip title={tooltipText}>
      <Button
        variant='outlined'
        {...buttonProps}
        sx={{
          border: '2px solid #dee2e6',
          padding: 1,
          minWidth: 'auto',
          ':hover': {
            background: 'transparent',
            border: '2px solid #dee2e6'
          },
          '&.Mui-disabled': {
            border: '2px solid #dee2e6',
            opacity: 0.4
          }
        }}
      >
        {children}
      </Button>
    </Tooltip>
  )
}

export default OutlinedButton