import { useCallback, useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { ExpandMore } from '@mui/icons-material'
import CancelIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import TryOutlinedIcon from '@mui/icons-material/TryOutlined'
import { Box, Divider, IconButton, Modal, Typography } from '@mui/material'
import { DataGridPro, GridActionsCellItem, gridClasses, gridFilteredDescendantCountLookupSelector, GridRowEditStopReasons, GridRowModes, useGridApiContext, useGridApiRef, useGridSelector } from '@mui/x-data-grid-pro'
import { randomId } from '@mui/x-data-grid-generator'
import { HtmlTooltip } from '../../../../components/CustomTooltip'
import { useAuth } from '../../../../contexts/AuthContext'
import { moduleConfig } from '../../../../contexts/data'
import { ACCESS_LEVEL } from '../../../../contstants/constants'
import { useErrorToast } from '../../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../../hooks/useSuccessToast'
import { formatCurrency } from '../../../../utils/FormateCurrenyInMilion'
import { fixConnectivityStatusColor } from '../../../../utils/getFixConnectivityStatusColor'
import Loader from '../../../Loader'
import DownloadTradeLogsCell from '../DownloadTradeLogsCell'
import StatusUpdateCell from './StatusUpdateCell'
import NumberFieldEditCell from './NumberFieldEditCell'
import TradeAdhocLoader from './TradeAdhocLoader'
import AdhocPopupHeader from './AdhocPopupHeader'

const detailsPopupStyle = {
  position: 'absolute',
  inset: 0,
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: '4px',
  outline: 'none'
}

let expansionLookupManualTrade = {}

const CustomGridTreeDataGroupingCell = (props) => {
  const { id, field, rowNode } = props
  const apiRef = useGridApiContext()
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  )
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

  const handleClick = (event) => {
    if (rowNode.type !== 'group') {
      return
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  return (
    <>
      {filteredDescendantCount > 0 ? (
        <>
          <IconButton
            onClick={handleClick}
            size='small'
            tabIndex={-1}
            aria-label={rowNode.childrenExpanded ? 'Close' : 'Open'}
          >
            <ExpandMore
              sx={{
                transform: `rotateZ(${rowNode.childrenExpanded ? 360 : 270}deg)`,
                transition: (theme) =>
                  theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                  }),
              }}
              fontSize='inherit'
            />
          </IconButton>
          <span>
            {props?.row?.instrId}
          </span>
        </>
      ) : (
        <span style={{ marginLeft: filteredDescendantCount === 0 && rowNode.depth === 0 ? '28px' : (rowNode.depth * 28) + 28 }}>{props?.row?.instrId}</span>
      )}
    </>
  )
}

const TradeAdhocPopup = ({ showTradeAdhocPopup, closeTradeAdhocPopup, tradeRow, updateCurrentRow, updateOptmRunStatus }) => {
  const [cashValue, setCashValue] = useState(0)
  const [sellValue, setSellValue] = useState(0)
  const [buyValue, setBuyValue] = useState(0)
  const { user, checkAccess } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [tradesList, setTradesList] = useState([])
  const [tradesListCopy, setTradesListCopy] = useState([])
  const [tradesLoading, setTradesLoading] = useState(true)
  const [tradeSummaryLoading, setTradeSummaryLoading] = useState(false)
  const [isAPILoading, setIsAPILoading] = useState(false)
  const [selectedTrades, setSelectedTrades] = useState([])
  const [adhocTradeGridState, setAdhocTradeGridState] = useState(null)
  const [fixFlyerStatusList, setFixFlyerStatusList] = useState([])
  const [allocationStatusList, setAllocationStatusList] = useState([])
  const [rowModesModel, setRowModesModel] = useState({})
  const apiRef = useGridApiRef()
  const isBlockTrade = tradeRow?.optDetails.length > 0 && tradeRow?.optDetails[0]?.isBlockTrade

  const updateTradeList = (payload, multipleFlag, id) => {
    const accOptMapId = tradeRow?.optDetails.length > 0 && tradeRow?.optDetails[0]?.accOptMapId
    if (!accOptMapId) {
      return
    }
    setIsAPILoading(true)
    API.patch(
      'baseUriTrade',
      `trade/v1/${user?.userGroup}/trade-prop-details/${accOptMapId}`,
      {
        body: payload
      }
    )
      .then((response) => {
        if (response?.success) {
          // find the matching row in response and if found then replace the field from response
          const updatedTradeList = tradesList.map(trade => {
            const index = response?.data?.findIndex(res => trade.tradeId === res.tradeId)
            if (index > -1) {
              return { ...trade, ...response?.data[index], fixFlyerStatus: response?.data[index]?.fixTradeStatus }
            } else {
              return trade
            }
          })
          setTradesList(updatedTradeList)
          setTradesListCopy(tradesListCopy.map(trade => {
            const index = response?.data?.findIndex(res => trade.tradeId === res.tradeId)
            if (index > -1) {
              return { ...trade, ...response?.data[index], fixFlyerStatus: response?.data[index]?.fixTradeStatus }
            } else {
              return trade
            }
          }))
          calculateSellBuyValue(selectedTrades, updatedTradeList)
          // change row mode to view mode
          if (multipleFlag) {
            const obj = {}
            updatedTradeList.forEach((trade) => {
              if (selectedTrades.includes(trade.tradeId) || trade.tradeId === id) {
                obj[trade.tradeId] = { mode: GridRowModes.View }
              }
            })
            setRowModesModel({ ...rowModesModel, ...obj })
          } else {
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
          }
          setSelectedTrades([])
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to update selected trade details.')
      })
      .finally(() => {
        setIsAPILoading(false)
      })
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut || params.reason === GridRowEditStopReasons.escapeKeyDown || params.reason === GridRowEditStopReasons.enterKeyDown || params.reason === GridRowEditStopReasons.tabKeyDown) {
      event.defaultMuiPrevented = true
    }
  }

  const updateRowModesModel = (id, modeConfig, parentEditing = false) => {
    // if clicked row is selected, find all the selected rows, add new mode config for all selected rows and children rows if account is block trade
    if (selectedTrades.includes(id)) {
      const obj = {}
      const rowUpdate = []
      tradesList.forEach((trade) => {
        if (selectedTrades.includes(trade.tradeId)) {
          obj[trade.tradeId] = modeConfig
          const childRowIds = apiRef?.current?.getRowGroupChildren({
            groupId: trade.tradeId
          })
          if (isBlockTrade && childRowIds?.length) {
            childRowIds?.forEach(rowId => {
              obj[rowId] = modeConfig
              // add parent editing key in child rows via update rows to disable/enable save and cancel click
              rowUpdate.push({ tradeId: rowId, parentEditing })
            })
          }
        }
      })
      apiRef.current.updateRows(rowUpdate)
      setRowModesModel({ ...rowModesModel, ...obj })
    } else {
      const childRowIds = apiRef?.current?.getRowGroupChildren({
        groupId: id
      })
      // if account is block traded, find all the child rows and update mode config for child rows
      if (isBlockTrade && childRowIds?.length) {
        const updatedRowModesModel = {
          ...rowModesModel,
          [id]: modeConfig
        }
        const rowUpdate = []
        childRowIds?.forEach(rowId => {
          updatedRowModesModel[rowId] = modeConfig
          // add parent editing key in child rows via update rows to disable/enable save and cancel click
          rowUpdate.push({ tradeId: rowId, parentEditing })
        })
        apiRef.current.updateRows(rowUpdate)
        setRowModesModel(updatedRowModesModel)
      } else {
        // if row is not selected then change to selected mode for that row only
        setRowModesModel({ ...rowModesModel, [id]: modeConfig })
      }
    }
  }

  const handleEditClick = (id) => () => {
    updateRowModesModel(id, { mode: GridRowModes.Edit }, true)
  }

  // if key is not present in API response, return key value from old row
  const getUpdatedKeyValue = (response, key, oldRow) => {
    if (response[key] !== null && response[key] !== undefined) {
      return response[key]
    }
    return oldRow[key]
  }

  const saveBlockTradeChanges = (tradesList, response) => {
    return tradesList.map(trade => {
      if (trade.parentRow) {
        const index = response?.data?.findIndex(res => trade.blockTradeId === res.blockTradeId)
        if (index > -1) {
          return {
            ...trade,
            blockTradeStatus: getUpdatedKeyValue(response?.data[index], 'blockTradeStatus', trade),
            fixFlyerStatus: getUpdatedKeyValue(response?.data[index], 'blockTradeStatus', trade),
            blockAllocStatus: getUpdatedKeyValue(response?.data[index], 'blockAllocStatus', trade),
            tradedPrice: getUpdatedKeyValue(response?.data[index], 'blockTradedPrice', trade),
            blockTradedPrice: getUpdatedKeyValue(response?.data[index], 'blockTradedPrice', trade),
            tradedShares: getUpdatedKeyValue(response?.data[index], 'blockTradedShares', trade),
            blockTradedShares: getUpdatedKeyValue(response?.data[index], 'blockTradedShares', trade),
            trdQty: getUpdatedKeyValue(response?.data[index], 'blockTradeQty', trade),
            blockTradeQty: getUpdatedKeyValue(response?.data[index], 'blockTradeQty', trade),
            fixComments: getUpdatedKeyValue(response?.data[index], 'fixComments', trade),
          }
        } else {
          return trade
        }
      } else {
        const parentIndex = response?.data?.findIndex(res => trade.blockTradeId === res.blockTradeId)
        if (parentIndex > -1) {
          const childIndex = response?.data[parentIndex].indTrades.findIndex(res => trade.tradeId === res.tradeId)
          if (childIndex > -1) {
            return {
              ...trade,
              ...response?.data[parentIndex].indTrades[childIndex],
              blockAllocStatus: getUpdatedKeyValue(response?.data[parentIndex].indTrades[childIndex], 'tradeAllocStatus', trade)
            }
          }
        }
        return trade
      }
    })
  }

  const handleBlockTradeSaveClick = (id) => () => {
    const payload = []
    const multipleSelectFlag = selectedTrades.includes(id)
    const processPayloadObj = (tradeId) => {
      const childRowIds = apiRef?.current?.getRowGroupChildren({
        groupId: tradeId
      })
      if (isBlockTrade && childRowIds?.length) {
        // check if block row's key is modified
        const updatedRow = apiRef.current.getRowWithUpdatedValues(tradeId)
        const oldParentRow = apiRef.current.getRow(tradeId)
        const blockLevelPayloadObj = {}
        const childRowsData = []
        if (updatedRow?.trdQty !== oldParentRow.trdQty) {
          blockLevelPayloadObj.overallQty = updatedRow?.trdQty
        }
        if (updatedRow?.tradedShares !== oldParentRow.tradedShares) {
          blockLevelPayloadObj.tradedShares = updatedRow?.tradedShares
        }
        if (updatedRow?.tradedPrice !== oldParentRow.tradedPrice) {
          blockLevelPayloadObj.tradedPrice = updatedRow?.tradedPrice
        }
        if (updatedRow?.fixFlyerStatus !== oldParentRow.fixFlyerStatus || updatedRow?.blockAllocStatus !== oldParentRow.blockAllocStatus) {
          blockLevelPayloadObj.fixTradeStatus = updatedRow?.fixFlyerStatus
          blockLevelPayloadObj.allocStatusCode = updatedRow?.blockAllocStatus
        }
        // check if any child row is modified
        childRowIds?.forEach(rowId => {
          const updatedChildRow = apiRef.current.getRowWithUpdatedValues(rowId)
          const oldRow = apiRef.current.getRow(rowId)
          const individualRowObj = {}
          if (updatedChildRow?.trdQty !== oldRow?.trdQty) {
            individualRowObj.trdQty = updatedChildRow?.trdQty
          }
          if (updatedChildRow?.tradedShares !== oldRow?.tradedShares) {
            individualRowObj.tradedShares = updatedChildRow?.tradedShares
          }
          if (updatedChildRow?.tradedPrice !== oldRow?.tradedPrice) {
            individualRowObj.tradedPrice = updatedChildRow?.tradedPrice
          }
          if (Object.keys(individualRowObj)?.length) {
            individualRowObj.tradeId = updatedChildRow?.tradeId
            childRowsData.push(individualRowObj)
          }
        })
        // if child row is modified or block level row is modified, then pass current row object in payload
        if (Object.keys(blockLevelPayloadObj).length || childRowsData?.length) {
          blockLevelPayloadObj.blockTradeId = updatedRow?.blockTradeId
          if (childRowsData?.length) {
            blockLevelPayloadObj.indTrades = childRowsData
          }
          payload.push(blockLevelPayloadObj)
        }
      }
    }
    if (multipleSelectFlag) {
      selectedTrades.forEach(selectedTrade => {
        processPayloadObj(selectedTrade)
      })
    } else {
      processPayloadObj(id)
    }
    if (payload.length) {
      const accOptMapId = tradeRow?.optDetails.length > 0 && tradeRow?.optDetails[0]?.accOptMapId
      if (!accOptMapId) {
        return
      }
      setIsAPILoading(true)
      API.patch(
        'baseUriTrade',
        `trade/v1/${user?.userGroup}/trade-block-prop-details/${accOptMapId}`,
        {
          body: payload
        }
      )
        .then((response) => {
          if (response?.success) {
            // find the matching row in response and if found then replace the field from response
            const updatedTradeList = saveBlockTradeChanges(tradesList, response)
            setTradesList(updatedTradeList)
            setTradesListCopy(saveBlockTradeChanges(tradesListCopy, response))
            calculateSellBuyValue(selectedTrades, updatedTradeList)
            updateRowModesModel(id, { mode: GridRowModes.View }, false)
            setSelectedTrades([])
          }
        })
        .catch((error) => {
          showError(error, false, {}, 'Failed to update selected trade details.')
        })
        .finally(() => {
          setIsAPILoading(false)
        })
    } else {
      updateRowModesModel(id, { mode: GridRowModes.View }, false)
    }
  }

  const handleSaveClick = (id, row) => () => {
    const payload = []
    const multipleSelectFlag = selectedTrades.includes(id)
    if (multipleSelectFlag) {
      tradesList.forEach((trade) => {
        if (selectedTrades.includes(trade.tradeId)) {
          const updatedRow = apiRef.current.getRowWithUpdatedValues(trade.tradeId)
          // create object with changed properties
          const tempObj = {}
          if (updatedRow?.trdQty !== trade.trdQty) {
            tempObj.trdQty = updatedRow?.trdQty
          }
          if (updatedRow?.tradedShares !== trade.tradedShares) {
            tempObj.tradedShares = updatedRow?.tradedShares
          }
          if (updatedRow?.tradedPrice !== trade.tradedPrice) {
            tempObj.tradedPrice = updatedRow?.tradedPrice
          }
          if (updatedRow?.fixFlyerStatus !== trade.fixFlyerStatus) {
            tempObj.fixTradeStatus = updatedRow?.fixFlyerStatus
          }
          // insert object into payload only if anything is changed
          if (Object.keys(tempObj)?.length) {
            tempObj.tradeId = updatedRow?.tradeId
          } else {
            return
          }
          payload.push(tempObj)
        }
      })
    } else {
      const updatedRow = apiRef.current.getRowWithUpdatedValues(id)
      const tempObj = {}
      // create object with changed properties
      if (updatedRow?.trdQty !== row.trdQty) {
        tempObj.trdQty = updatedRow?.trdQty
      }
      if (updatedRow?.tradedShares !== row.tradedShares) {
        tempObj.tradedShares = updatedRow?.tradedShares
      }
      if (updatedRow?.tradedPrice !== row.tradedPrice) {
        tempObj.tradedPrice = updatedRow?.tradedPrice
      }
      if (updatedRow?.fixFlyerStatus !== row.fixFlyerStatus) {
        tempObj.fixTradeStatus = updatedRow?.fixFlyerStatus
      }
      // insert object into payload only if anything is changed
      if (Object.keys(tempObj)?.length) {
        tempObj.tradeId = updatedRow?.tradeId
      } else {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } })
        return
      }
      payload.push(tempObj)
    }
    if (payload.length) {
      updateTradeList(payload, multipleSelectFlag, id)
    } else {
      // for multiple entries with no changes change mode to view
      const obj = {}
      tradesList.forEach((trade) => {
        if (selectedTrades.includes(trade.tradeId) || trade.tradeId === id) {
          obj[trade.tradeId] = { mode: GridRowModes.View }
        }
      })
      setRowModesModel({ ...rowModesModel, ...obj })
    }
  }

  const handleCancelClick = (id) => () => {
    updateRowModesModel(id, { mode: GridRowModes.View, ignoreModifications: true }, false)
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const tradeListHeaders = [
    ...(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, {
      subModuleName: moduleConfig.TRADE_HISTORY,
      component_name: moduleConfig.VIEW_TRADE_EXECUTION_LOGS
    })
      ? [{
        field: 'download-trade',
        headerName: '',
        width: 50,
        renderCell: (params) => <DownloadTradeLogsCell {...params} isBlockTrade={isBlockTrade} />
      }]
      : []
    ),
    ...isBlockTrade ? [] : [{ field: 'instrId', headerName: 'Instrument Id' }],
    { field: 'localSymbol', headerName: 'Local Symbol' },
    { field: 'tradeStatusCode', headerName: 'Trade Status' },
    {
      field: 'fixFlyerStatus',
      headerName: 'FIX Connectivity Status',
      width: 200,
      editable: true,
      renderCell: (params) => {
        const data = params?.value
        return (
          <Box sx={{ color: fixConnectivityStatusColor(data), textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={data}>
            {data || ''}
          </Box>
        )
      },
      renderEditCell: (params) => <StatusUpdateCell {...params} statusList={fixFlyerStatusList} selectedTrades={selectedTrades} isBlockTrade={isBlockTrade} />
    },
    ...(tradeRow?.optDetails?.length && tradeRow?.optDetails[0]?.isBlockTrade)
      ? [
        {
          field: 'blockAllocStatus',
          headerName: 'Allocation Status',
          width: 200,
          editable: true,
          renderCell: (params) => {
            const data = params?.value
            return (
              <Box sx={{ color: fixConnectivityStatusColor(data), textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={data}>
                {data || ''}
              </Box>
            )
          },
          renderEditCell: (params) => <StatusUpdateCell {...params} statusList={allocationStatusList} selectedTrades={selectedTrades} isBlockTrade={isBlockTrade} />
        },
          {
          field: 'blockTradedShares',
          headerName: 'Block Trade Quantity',
          type: 'number',
          align: 'right',
          headerAlign: 'right',
          valueGetter: (params) => {
            return params?.row?.parentRow
              ? params?.row?.blockTradedShares !== null && params?.row?.blockTradedShares !== undefined ? params?.row?.blockTradedShares : 0
              : params?.row?.tradedShares !== null && params?.row?.tradedShares !== undefined ? params?.row?.tradedShares : 0
          },
          renderCell: (params) => {
            return params?.row?.parentRow
              ? (
                <>
                  {params?.row?.blockTradedShares !== null && params?.row?.blockTradedShares !== undefined ? params?.row?.blockTradedShares : ''}
                  {params?.row?.blockTradeQty !== null && params?.row?.blockTradeQty !== undefined ? `/${Math.abs(params?.row?.blockTradeQty)}` : ''}
                </>)
              : (
                <>
                  {params?.row?.tradedShares !== null && params?.row?.tradedShares !== undefined ? params?.row?.tradedShares : ''}
                  {params?.row?.trdQty !== null && params?.row?.trdQty !== undefined ? `/${Math.abs(params?.row?.trdQty)}` : ''}
                </>
              )
          }
          }
        ]
      : [],
    {
      field: 'purchaseDate',
      headerName: 'Purchase Date',
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.purchaseDate ? params?.row?.purchaseDate?.split('T')[0] : ''
    },
    {
      field: 'trdCode',
      headerName: 'Trade Code',
      renderCell: (params) => <span style={{ color: params.value === 'SELL' ? '#FF6161' : params.value === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.value}</span>
    },
    {
      field: 'orgPurchasePrice',
      headerName: 'Original Purchase Price',
      type: 'number',
      valueGetter: (params) => params?.value !== null && params?.value !== undefined ? parseFloat(params?.value) : null,
      renderCell: (params) => !isNaN(params?.row?.orgPurchasePrice) && params?.row?.orgPurchasePrice !== null
        ? params?.row?.orgPurchasePrice < 0
          ? `-$${formatCurrency(params?.row?.orgPurchasePrice)}`
          : `$${formatCurrency(params?.row?.orgPurchasePrice)}`
        : '',
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'initWeight',
      headerName: 'Initial Weight (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => parseFloat((params?.value * 100).toFixed(2)),
      renderCell: (params) => (params?.row?.initWeight * 100).toFixed(2)
    },
    {
      field: 'propWeight',
      headerName: 'Proposed Weight (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => (params?.row?.propWeight * 100).toFixed(2)
    },
    {
      field: 'initShares',
      headerName: 'Initial Shares',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params?.row?.initShares,
    },
    { field: 'propShares', headerName: 'Proposed Shares', type: 'number', align: 'right', headerAlign: 'right', renderCell: (params) => params?.row?.propShares },
    {
      field: 'tradedShares',
      headerName: 'Traded Shares',
      type: 'number',
      align: 'right',
      width: 200,
      valueGetter: (params) => params?.value !== null && params?.value !== undefined ? parseFloat(params?.value) : null,
      renderCell: (params) => {
        const data = params?.row?.fixFlyerStatus
        return params?.value !== null
          ? <Box sx={{ color: data === 'TRADE_COMPLETED' ? '#3BBFA3' : data === 'TRADE_SENT' ? '#d29922' : data === 'NA' ? '#34475A' : '#ff6161', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{params?.value || 0}</Box>
          : ''
      },
      editable: true,
      renderEditCell: (params) => <NumberFieldEditCell {...params} />
    },
    {
      field: 'tradedPrice',
      headerName: 'Traded Price',
      type: 'number',
      align: 'right',
      width: 200,
      valueGetter: (params) => params?.value !== null && params?.value !== undefined ? parseFloat(params?.value) : null,
      renderCell: (params) => !isNaN(params?.row?.tradedPrice) && params?.row?.tradedPrice !== null
        ? params?.row?.tradedPrice < 0
          ? `-$${formatCurrency(params?.row?.tradedPrice)}`
          : `$${formatCurrency(params?.row?.tradedPrice)}`
        : '',
      editable: true,
      renderEditCell: (params) => <NumberFieldEditCell {...params} />
    },
    { field: 'age', headerName: 'Age', type: 'number', align: 'right', headerAlign: 'right', renderCell: (params) => params?.row?.age },
    {
      field: 'costBasis',
      headerName: 'Cost Basis',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => params?.value !== null && params?.value !== undefined ? parseFloat(params?.value?.toFixed(2)) : null,
      renderCell: (params) => params?.row?.costBasis !== null && params?.row?.costBasis !== undefined
        ? params?.row?.costBasis?.toFixed(2)
        : ''
    },
    {
      field: 'lastClosePrice',
      headerName: 'Last Closed Price',
      type: 'number',
      valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
      renderCell: (params) => !isNaN(params?.row?.lastClosePrice)
        ? params?.row?.lastClosePrice < 0
          ? `-$${formatCurrency(params?.row?.lastClosePrice)}`
          : `$${formatCurrency(params?.row?.lastClosePrice)}`
        : '',
      align: 'right'
    },
    {
      field: 'liveMarketPrice',
      headerName: 'Live Market Price',
      type: 'number',
      valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
      renderCell: (params) => {
        const isLivePriceAvailable = params?.row?.isLivePriceAvailable === 1

        const cellStyle = {
          color: !isLivePriceAvailable ? 'rgba(0, 0, 0, 0.4)' : 'inherit',
          textAlign: 'right'
        }
        return (
          <div style={cellStyle}>
            {
              !isNaN(params?.row?.liveMarketPrice)
                ? params?.row?.liveMarketPrice < 0
                  ? `-$${formatCurrency(params?.row?.liveMarketPrice)}`
                  : `$${formatCurrency(params?.row?.liveMarketPrice)}`
                : ''
            }
          </div>
        )
      },
      align: 'right'
    },
    {
      field: 'initMarketValue',
      headerName: 'Initial Market Value',
      type: 'number',
      valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
      renderCell: (params) => !isNaN(params?.row?.initMarketValue)
        ? params?.row?.initMarketValue < 0
          ? `-$${formatCurrency(params?.row?.initMarketValue)}`
          : `$${formatCurrency(params?.row?.initMarketValue)}`
        : '',
      align: 'right'
    },
    {
      field: 'propMarketValue',
      headerName: 'Proposed Market Value',
      type: 'number',
      valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
      renderCell: (params) => !isNaN(params?.row?.propMarketValue)
        ? params?.row?.propMarketValue < 0
          ? `-$${formatCurrency(params?.row?.propMarketValue)}`
          : `$${formatCurrency(params?.row?.propMarketValue)}`
        : '',
      align: 'right'
    },
    {
      field: 'initUrgl',
      headerName: 'Initial Unrealized gain-loss',
      type: 'number',
      valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
      renderCell: (params) => !isNaN(params?.row?.initUrgl)
        ? params?.row?.initUrgl < 0
          ? `-$${formatCurrency(params?.row?.initUrgl)}`
          : `$${formatCurrency(params?.row?.initUrgl)}`
        : '',
      align: 'right'
    },
    {
      field: 'rgl',
      headerName: 'Realized gain-loss',
      type: 'number',
      valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
      renderCell: (params) => !isNaN(params?.row?.rgl)
        ? params?.row?.rgl < 0
          ? `-$${formatCurrency(params?.row?.rgl)}`
          : `$${formatCurrency(params?.row?.rgl)}`
        : '',
      align: 'right'
    },
    {
      field: 'trdDate',
      headerName: 'Trade Date',
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.trdDate ? params?.row?.trdDate?.split('T')[0] : ''
    },
    { field: 'tripNum', headerName: 'Trip Number', valueGetter: (params) => (params?.value) || '' },
    {
      field: 'cashBalance',
      headerName: 'Cash Balance',
      type: 'number',
      align: 'right',
      valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
      renderCell: (params) => !isNaN(params?.row?.cashBalance) && params?.row?.cashBalance !== null
        ? params?.row?.cashBalance < 0
          ? `-$${formatCurrency(params?.row?.cashBalance)}`
          : `$${formatCurrency(params?.row?.cashBalance)}`
        : ''
    },
    {
      field: 'trdQty',
      headerName: 'Trade Quantity',
      type: 'number',
      width: 200,
      valueGetter: (params) => params?.value !== null && params?.value !== undefined ? parseFloat(params?.value) : '',
      align: 'right',
      editable: true,
      renderEditCell: (params) => <NumberFieldEditCell {...params} />
    },
    {
      field: 'fixComments',
      headerName: 'Fix Comments',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <HtmlTooltip
            title={
              <>
                <Typography color='inherit' fontFamily='Open Sans' fontWeight={600}>Fix Comments</Typography>
                <Divider sx={{ backgroundColor: '#000000' }} />
                <Typography sx={{ fontSize: '14px' }} my={1}>{!params?.row?.fixComments ? '-' : params?.row?.fixComments}</Typography>
              </>
            }
          >
            <TryOutlinedIcon />
          </HtmlTooltip>
        )
      }
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
        if (isInEditMode) {
          const actions = [
            <GridActionsCellItem
              key='save'
              disabled={row.error || row.parentEditing}
              icon={<SaveIcon />}
              label='Save'
              onClick={isBlockTrade && row.parentRow ? handleBlockTradeSaveClick(id) : handleSaveClick(id, row)}
              color='inherit'
            />,
            <GridActionsCellItem
              key='cancel'
              disabled={row.parentEditing}
              icon={<CancelIcon />}
              label='Cancel'
              onClick={handleCancelClick(id, row)}
              color='inherit'
            />
          ]
          return actions
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            key='edit'
            label='Edit'
            disabled={isBlockTrade ? row.fixFlyerStatus === 'TRADE_COMPLETED' && row.blockAllocStatus === 'ALLOCATION_COMPLETED' : row.fixFlyerStatus === 'TRADE_COMPLETED'}
            onClick={handleEditClick(id, row)}
            color='inherit'
          />
        ]
      }
    }
  ]

  const getCashValue = (trades) => {
    // filter cash trade
    let initialCashBalance = parseFloat(trades.find(trd => trd.instrId === '__CASH')?.initShares) ?? 0

    // filter completed trades and broken trades
    const completedTrades = (isBlockTrade ? trades.flatMap(trade => trade.indTrades || []) : trades).filter(trd => trd.fixFlyerStatus === 'TRADE_COMPLETED' || trd.fixFlyerStatus === 'TRADE_BREAK')
    // store sell and buy trades with valid traded shares and price
    const sellTrades = completedTrades.filter(trade => trade.trdCode === 'SELL' && trade.tradedShares && trade.tradedPrice)
    const buyTrades = completedTrades.filter(trade => trade.trdCode === 'BUY' && trade.tradedShares && trade.tradedPrice)
    if (isNaN(initialCashBalance)) {
      initialCashBalance = 0
    }
    let currentCashBalance = initialCashBalance
    // add all sell trade amount into cash
    sellTrades.forEach(trade => {
      const shares = trade.tradedShares ?? 0
      const price = trade.tradedPrice ?? 0
      currentCashBalance += shares * price
    })
    // reduce all buy trade amount from cash
    buyTrades.forEach(trade => {
      const shares = trade.tradedShares ?? 0
      const price = trade.tradedPrice ?? 0
      currentCashBalance -= shares * price
    })
    setCashValue(parseFloat(currentCashBalance?.toFixed(2)))
  }

  const processBlockTradeRows = (rows) => {
    const blockTradeRows = []
    rows.forEach((row) => {
      if (row.blockTradeId !== null) {
        blockTradeRows.push({
          ...row,
          id: row.blockTradeId,
          hierarchy: [row.blockTradeId],
          parentRow: true,
          tradeId: row.blockTradeId || randomId(),
          // map block trade fields to column fields
          fixFlyerStatus: row.blockTradeStatus,
          tradedShares: row.blockTradedShares,
          tradedPrice: row.blockTradedPrice,
        })
      }
      (row?.indTrades || [])?.forEach(object => {
        blockTradeRows.push({
          ...object,
          blockTradeId: row.blockTradeId,
          // map child row's allocation status key to allocation column field
          blockAllocStatus: object.tradeAllocStatus,
          hierarchy: row.blockTradeId === null ? [object.instrId + object.tradeId] : [row.blockTradeId, object.instrId + object.tradeId],
        })
      })
    })
    return blockTradeRows
  }

  const getOptimPropertyDetails = () => {
    const accOptMapId = tradeRow?.optDetails.length > 0 && tradeRow?.optDetails[0]?.accOptMapId
    if (!accOptMapId) {
      return
    }
    setTradesLoading(true)
    API.get(
      'baseUriTrade',
      `trade/v1/${user?.userGroup}/block-trade-details/${accOptMapId}`
    )
      .then((response) => {
        if (response?.data?.length) {
          const tradeList = response?.data?.filter(row => row.trdCode !== 'NOP')?.map((row) => ({ ...row, id: row.tradeId }))
          if (isBlockTrade) {
            const updatedTradeList = processBlockTradeRows(tradeList)
            setTradesList(updatedTradeList)
            setTradesListCopy(updatedTradeList)
          } else {
            setTradesList(tradeList)
            setTradesListCopy(tradeList)
          }
          getCashValue(response?.data)
          setSellValue(0)
          setBuyValue(0)
          setSelectedTrades([])
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load trade history.')
      })
      .finally(() => {
        setTradesLoading(false)
      })
  }

  const sendTrades = () => {
    const accOptMapId = tradeRow?.optDetails.length > 0 && tradeRow?.optDetails[0]?.accOptMapId
    setIsAPILoading(true)
    // pass only child rows for resuming trades since child rows are individual trades
    const filteredSelectedTrades = isBlockTrade ? selectedTrades.filter((tradeId) => !apiRef.current.getRow(tradeId).parentRow) : selectedTrades
    API.post(
      'baseUriTrade',
      `trade/v1/${user?.userGroup}/manual-trading-order/${accOptMapId}`,
      { body: { tradeIds: filteredSelectedTrades } }
    )
      .then((response) => {
        if (response?.success) {
          showSuccess(response.message)
          closeTradeAdhocPopup()
          setSelectedTrades([])
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to send allocation.')
      })
      .finally(() => {
        setIsAPILoading(false)
      })
  }

  const sendAllocation = () => {
    const accOptMapId = tradeRow?.optDetails.length > 0 && tradeRow?.optDetails[0]?.accOptMapId
    setIsAPILoading(true)
    // pass only parent rows for sending allocation since parent row indicates allocation data
    const filteredSelectedTrades = selectedTrades.filter((tradeId) => apiRef.current.getRow(tradeId).parentRow)
    API.post(
      'baseUriTrade',
      `trade/v1/${user?.userGroup}/manual-trading-order-allocation/${accOptMapId}`,
      { body: { allocationIds: filteredSelectedTrades } }
    )
      .then((response) => {
        if (response?.success) {
          showSuccess(response.message)
          closeTradeAdhocPopup()
          setSelectedTrades([])
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to send manual trades.')
      })
      .finally(() => {
        setIsAPILoading(false)
      })
  }

  // Effect to restore the grid state whenever gridState changes
  useEffect(() => {
    if (apiRef?.current?.restoreState && adhocTradeGridState) {
      const resetGridStateObj = {
        columns: adhocTradeGridState?.columns || {},
        pinnedColumns: adhocTradeGridState?.pinnedColumns || {}
      }
      apiRef?.current?.restoreState(resetGridStateObj)
    }
  }, [adhocTradeGridState, selectedTrades, isAPILoading, tradesLoading, tradesList, cashValue, fixFlyerStatusList, sellValue, buyValue, rowModesModel, tradesListCopy, allocationStatusList, isBlockTrade])

  const exportTableState = () => {
    if (apiRef?.current?.exportState) {
      const state = apiRef?.current?.exportState()
      setAdhocTradeGridState(state)
    }
  }

  const calculateSellBuyValue = (selectedList, updatedTradeList) => {
    const selectedRows = updatedTradeList?.filter(row => selectedList.includes(row.tradeId) && (isBlockTrade ? row.parentRow : true))
    let sellValue = 0
    let buyValue = 0
    if (selectedRows?.length) {
      // store sell and buy trades with valid traded shares and price
      const sellTrades = selectedRows.filter(trade => trade.trdCode === 'SELL' && trade.trdQty)
      const buyTrades = selectedRows.filter(trade => trade.trdCode === 'BUY' && trade.trdQty)
      // add all sell trade amount into cash
      sellTrades.forEach(trade => {
        const shares = Math.abs(parseFloat(trade.trdQty ?? 0))
        // consider last close price if live price is not available
        const price = parseFloat((trade.isLivePriceAvailable === 1 ? trade.liveMarketPrice : trade.lastClosePrice) || 0)
        sellValue += shares * price
      })
      // reduce all buy trade amount from cash
      buyTrades.forEach(trade => {
        const shares = parseFloat(trade.trdQty ?? 0)
        // consider last close price if live price is not available
        const price = parseFloat((trade.isLivePriceAvailable === 1 ? trade.liveMarketPrice : trade.lastClosePrice) || 0)
        buyValue += shares * price
      })
    }
    setSellValue(sellValue)
    setBuyValue(buyValue)
  }

  const handleRowSelection = (selectedList) => {
    const updatedSelectionList = [...selectedList]
    // find child rows for each selectedRowID and add in updated selection if it is not added previously
    selectedList.forEach(id => {
      const childRowIds = apiRef.current.getRowGroupChildren({
        groupId: id
      })
      if (childRowIds?.length) {
        childRowIds?.forEach(childRow => {
          if (!selectedList.includes(childRow)) {
            updatedSelectionList.push(childRow)
          }
        })
      }
    })
    // find ids in old selection which is not present in updated selection
    const removedSelectedIds = selectedTrades.filter(id => !updatedSelectionList.includes(id))
    // find child row Ids of removed parent row and remove child rows from updated selection
    if (removedSelectedIds?.length) {
      removedSelectedIds.forEach(id => {
        const childRowIds = apiRef.current.getRowGroupChildren({
          groupId: id
        })
        if (childRowIds?.length) {
          childRowIds?.forEach(childRowId => {
            const rowIndex = updatedSelectionList.findIndex(selectionId => childRowId === selectionId)
            if (rowIndex > -1) {
              updatedSelectionList.splice(rowIndex, 1)
            }
          })
        }
      })
    }
    setSelectedTrades(updatedSelectionList)
    calculateSellBuyValue(updatedSelectionList, tradesList)
  }

  const fetchFixFlyerStatus = () => {
    API.get('baseUriTrade', `trade/v1/${user?.userGroup}/trade-status-master`, { queryStringParameters: { 'trade-status-type': 'TRADE' }})
      .then((response) => {
        if (response?.data?.length) {
          setFixFlyerStatusList(response?.data?.map(statusObj => statusObj.tradeStatusCode))
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load flyer status list.')
      })
  }

  const fetchAllocationStatus = () => {
    API.get('baseUriTrade', `trade/v1/${user?.userGroup}/trade-status-master`, { queryStringParameters: { 'trade-status-type': 'ALLOCATION' } })
      .then((response) => {
        if (response?.data?.length) {
          setAllocationStatusList(response?.data?.map(statusObj => statusObj.tradeStatusCode))
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load allocation status list.')
      })
  }

  const updateFixConnectivityStatus = (fixTradeStatus) => {
    const accOptMapId = tradeRow?.optDetails.length > 0 && tradeRow?.optDetails[0]?.accOptMapId
    if (!accOptMapId) {
      return
    }
    setIsAPILoading(true)
    API.patch(
      'baseUriTrade',
      `trade/v1/${user?.userGroup}/trade-prop-summary/${accOptMapId}`,
      { body: { fixTradeStatus } }
    )
      .then((response) => {
        if (response?.data?.length > 0) {
          updateOptmRunStatus({ fixTradeStatus: response.data[0]?.fixTradeStatus }, accOptMapId)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to update fix flyer status.')
      })
      .finally(() => {
        setIsAPILoading(false)
      })
  }

  const getUpdatedTradeSummaryDetails = () => {
    const accOptMapId = tradeRow?.optDetails.length > 0 && tradeRow?.optDetails[0]?.accOptMapId
    if (!accOptMapId) {
      return
    }
    setTradeSummaryLoading(true)
    API.get(
      'baseUriTrade',
      `trade/v1/${user.userGroup}/fix-trade-status/${accOptMapId}`
    )
      .then((response) => {
        if (response?.data?.length > 0) {
          updateCurrentRow(response.data[0], accOptMapId)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load updated flyer status.')
      })
      .finally(() => {
        setTradeSummaryLoading(false)
      })
  }

  useEffect(() => {
    if (user) {
      getOptimPropertyDetails()
      fetchFixFlyerStatus()
      fetchAllocationStatus()
    }
  }, [user])

  const refreshTradeAdhoc = () => {
    getOptimPropertyDetails()
    getUpdatedTradeSummaryDetails()
  }

  useEffect(() => {
    if (apiRef?.current?.subscribeEvent) {
      apiRef?.current?.subscribeEvent('rowExpansionChange', (node) => {
        if (node?.groupingKey) {
          expansionLookupManualTrade[node?.groupingKey] = node?.childrenExpanded
        }
      })
    }
  }, [apiRef, tradesList])

  // clear expansion lookup on popup unmount
  useEffect(() => {
    return () => {
      expansionLookupManualTrade = {}
    }
  }, [])

  const isGroupExpandedByDefault = useCallback(
    (node) => {
      return node?.groupingKey && !!expansionLookupManualTrade?.[node?.groupingKey]
    },
    []
  )

  const applyStatusFilters = (selectedFilters) => {
    const filteredTrades = tradesListCopy.filter(trade => {
      const tradeStatusMatch = selectedFilters?.trade?.length === 0 || selectedFilters?.trade?.includes(trade.fixFlyerStatus)
      const allocationStatusMatch = selectedFilters?.allocation?.length === 0 || selectedFilters?.allocation?.includes(trade.blockAllocStatus)
      return tradeStatusMatch && allocationStatusMatch
    })
    setTradesList(filteredTrades)
  }

  const handleCellDoubleClick = (params, event) => {
    event.defaultMuiPrevented = true
  }

  return (
    <>
      {
        isAPILoading ? <Loader /> : ''
      }
      <Modal
        open={showTradeAdhocPopup}
        onClose={() => closeTradeAdhocPopup(false)}
      >
        <Box sx={detailsPopupStyle}>
          <AdhocPopupHeader
            tradesLoading={tradesLoading}
            tradeRow={tradeRow}
            tradeSummaryLoading={tradeSummaryLoading}
            cashValue={cashValue}
            setCashValue={setCashValue}
            sellValue={sellValue}
            buyValue={buyValue}
            selectedTrades={selectedTrades}
            isBlockTrade={isBlockTrade}
            fixFlyerStatusList={fixFlyerStatusList}
            refreshTradeAdhoc={refreshTradeAdhoc}
            updateFixConnectivityStatus={updateFixConnectivityStatus}
            rowModesModel={rowModesModel}
            sendAllocation={sendAllocation}
            sendTrades={sendTrades}
            closeTradeAdhocPopup={closeTradeAdhocPopup}
            applyStatusFilters={applyStatusFilters}
          />
          {
            tradesLoading
              ? (
                <TradeAdhocLoader tradeListHeaders={tradeListHeaders} />
                )
              : (
                <DataGridPro
                  apiRef={apiRef}
                  autoHeight
                  density='compact'
                  rows={tradesList || []}
                  columns={tradeListHeaders}
                  getRowId={(row) => row?.tradeId}
                  pagination
                  checkboxSelection
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  disableRowSelectionOnClick
                  disableMultipleRowSelection
                  editMode='row'
                  rowSelectionModel={selectedTrades}
                  onRowSelectionModelChange={handleRowSelection}
                  onCellDoubleClick={handleCellDoubleClick}
                  onColumnWidthChange={() => exportTableState()}
                  onColumnOrderChange={() => exportTableState()}
                  pageSizeOptions={[15, 25, 50, 75, 100]}
                  isRowSelectable={(params) => isBlockTrade
                    ? params?.row?.parentRow ? params.row.blockAllocStatus !== 'ALLOCATION_COMPLETED' : false
                    : (params.row.fixFlyerStatus !== 'TRADE_IN_PROGRESS' &&
                      params.row.fixFlyerStatus !== 'TRADE_COMPLETED' &&
                      params.row.fixFlyerStatus !== 'TRADE_SENT' &&
                      params.row.fixFlyerStatus !== 'TRADE_PARTIALLY_COMPLETED')}
                  {
                  ...isBlockTrade
                    ? {
                      treeData: true,
                      getTreeDataPath: (row) => row.hierarchy,
                      isGroupExpandedByDefault,
                      groupingColDef: {
                        headerName: 'Instr Id',
                        filterable: true,
                        sortable: true,
                        disableColumnMenu: false,
                        cellClassName: 'name-column-row',
                        headerClassName: 'name-column-header',
                        valueGetter: (params) => params?.row?.instrId,
                        renderCell: (params) => (
                          <CustomGridTreeDataGroupingCell
                            {...params}
                          />
                        )
                      }
                    }
                    : {}
                  }
                  initialState={{
                    pagination: { paginationModel: { pageSize: 15 } },
                    pinnedColumns: {
                      left: isBlockTrade ? ['__check__', 'download-trade', '__tree_data_group__'] : ['__check__', 'download-trade', 'instrId'],
                      right: ['actions']
                    }
                  }}
                  sx={(theme) => ({
                    [`& .${gridClasses.cell}:focus-within`]: {
                      outline: 'none !important'
                    },
                    [`& .${gridClasses['cell--editing']}:focus-within`]: {
                      outline: 'none !important'
                    },
                    [`& .${gridClasses['cell--editing']}`]: {
                      background: 'rgba(128,128,128,0.2)'
                    },
                    [`& .${gridClasses.cell} .MuiFormControl-root`]: {
                      margin: 0
                    },
                    [`& .${gridClasses.cell} .MuiInputBase-input`]: {
                      height: '1em'
                    },
                    [`.${gridClasses.main}`]: {
                      height: 'calc(100vh - 16px - 16px - 40px - 53px)'
                    },
                    [`.${gridClasses.columnHeaders}`]: {
                      position: 'sticky',
                      backgroundColor: theme.palette.background.paper,
                      top: 0,
                      zIndex: 1
                    },
                    [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                      color: '#74788d',
                      fontWeight: 600
                    },
                    [`.${gridClasses.virtualScroller}`]: {
                      overflowY: 'auto !important'
                    },
                    [`.${gridClasses.virtualScroller}::-webkit-scrollbar`]: {
                      width: '0px'
                    }
                  })}
                />
                )
          }
        </Box>
      </Modal>
    </>
  )
}

export default TradeAdhocPopup
