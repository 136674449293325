import { FormatDateAPI, FormatPayload } from '../components/FormatAPIRequest'
import { UpdateAPI } from '../components/Actions'
import { getUserRole } from '../../../utils/getUserRole'

export const UpdateAccountData = async (updatedRow, oldRow, tabIndex) => {
  switch (tabIndex) {
    case 'account-master':
      return await UpdateAccountMaster(updatedRow)
    case 'external-gl':
      return await UpdateAccountExternalGL(updatedRow)
    case 'internal-gl':
      return await UpdateAccountInternalGL(updatedRow)
    case 'parameters':
      return await UpdateAccountParameters(updatedRow)
    case 'portfolio-specification':
      return await UpdatePortSpec(updatedRow)
    case 'portfolio-specification-properties':
      return await UpdatePortSpecProperties(updatedRow)
    case 'substitution':
      return await UpdateAccountSubstitution(updatedRow, oldRow)
    case 'restriction':
      return await UpdateAccountRestrictions(updatedRow, oldRow)
    case 'tax-rate':
      return await UpdateTaxRates(updatedRow, oldRow)
    case 'properties':
      return await UpdateAccountProperties(updatedRow)
    case 'ignore-tax-lots':
      return await UpdateAccountIgnoreTaxLot(updatedRow)
    default:
      return {}
  }
}

const UpdateAccountMaster = async (updatedRow) => {
  const userRole = getUserRole()
  const startDateValue = updatedRow?.startDate ? FormatDateAPI(updatedRow.startDate) : null
  const billStartDateValue = updatedRow?.billStartDate ? FormatDateAPI(updatedRow.billStartDate) : null
  const endDateValue = ((updatedRow?.accountStatusId === 0 && FormatDateAPI(updatedRow.endDate) > FormatDateAPI(new Date())) || (updatedRow?.accountStatusId === 0 && updatedRow?.endDate === null)) ? FormatDateAPI(new Date()) : updatedRow?.endDate ? FormatDateAPI(updatedRow.endDate) : null
  let payload = {
    accountStatusId: updatedRow.accountStatusId,
    accountName: updatedRow.accountName,
    accountEmail: updatedRow.accountEmail,
    startDate: startDateValue,
    endDate: endDateValue,
    billStartDate: billStartDateValue
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/${updatedRow?.accountId}/master`,
    payload
  )
}

const UpdateAccountInternalGL = async (updatedRow) => {
  const userRole = getUserRole()
  const gainDateValue = updatedRow?.gainDate ? FormatDateAPI(updatedRow.gainDate) : null

  let payload = {
    ytdRealLongGains: updatedRow.ytdRealLongGains,
    ytdRealShortGains: updatedRow.ytdRealShortGains,
    gainDate: gainDateValue
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/${updatedRow.accountId}/int-gl/${updatedRow?.accIntGl}`,
    payload
  )
}

const UpdateAccountExternalGL = async (updatedRow) => {
  const userRole = getUserRole()
  const startDateValue = updatedRow?.startDate ? FormatDateAPI(updatedRow.startDate) : null
  const endDateValue = updatedRow?.endDate ? FormatDateAPI(updatedRow.endDate) : null

  let payload = {
    taxYear: updatedRow.taxYear,
    ytdRealLongGains: updatedRow.ytdRealLongGains,
    ytdRealShortGains: updatedRow.ytdRealShortGains,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/${updatedRow.accountId}/external-gain-loss/${updatedRow.accExtGl}`,
    payload
  )
}

// const UpdateAccountCashManagement = async (updatedRow) => {
//   const userRole = getUserRole()
//   const withdrawalStartDate = updatedRow?.withdrawalStartDate ? FormatDateAPI(updatedRow.withdrawalStartDate) : null
//   const withdrawalEndDate = updatedRow?.withdrawalEndDate ? FormatDateAPI(updatedRow.withdrawalEndDate) : null
//   let payload = {
//     needCashWithdrawal: updatedRow?.needCashWithdrawal === 1,
//     cashWithdrawalAmount: updatedRow?.cashWithdrawalAmount,
//     withdrawalFrequency: updatedRow?.withdrawalFrequency,
//     withdrawalStartDate,
//     withdrawalEndDate
//   }

//   payload = FormatPayload(payload)
//   return UpdateAPI(
//     'baseAccountURL',
//     `data-maintenance/v1/${userRole}/accounts/${updatedRow?.accountId}/cash-management`,
//     payload
//   )
// }

const UpdateAccountParameters = async (updatedRow) => {
  const userRole = getUserRole()
  const startDate = updatedRow?.startDate ? FormatDateAPI(updatedRow.startDate) : null
  const endDate = updatedRow?.endDate ? FormatDateAPI(updatedRow.endDate) : null

  let payload = {
    startDate,
    endDate,
    accountType: updatedRow?.accountType?.toUpperCase(),
    fundingSource: updatedRow?.fundingSource?.toUpperCase(),
    sellLogicId: updatedRow?.sellLogicId,
    isTaxable: updatedRow?.isTaxable === 1,
    custodianId: updatedRow?.custodianId,
    secondaryFaId: updatedRow?.secondaryFaId,
    brokerId: updatedRow?.brokerId,
    cashInstrId: updatedRow?.cashInstrId,
    pctCashCushion: updatedRow?.pctCashCushion,
    portSpecId: updatedRow?.portSpecId,
    maxShortTermGain: updatedRow?.maxShortTermGain,
    maxLongTermGain: updatedRow?.maxLongTermGain
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/${updatedRow?.accountId}/parameter`,
    payload
  )
}

const UpdatePortSpec = async (updatedRow) => {
  const userRole = getUserRole()
  const startDateValue = updatedRow?.startDate ? FormatDateAPI(updatedRow?.startDate) : null
  const endDateValue = updatedRow.endDate ? FormatDateAPI(updatedRow.endDate) : null

  let payload = {
    portSpecName: updatedRow?.portSpecName,
    portSpecCode: updatedRow?.portSpecCode?.toUpperCase(),
    strategyId: updatedRow?.strategyId,
    sponsorId: updatedRow?.sponsorId,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseAccountDataMainURL',
    `data-maintenance/v1/${userRole}/accounts/port-spec/${updatedRow?.portSpecId}`,
    payload
  )
}

const UpdatePortSpecProperties = async (updatedRow) => {
  const userRole = getUserRole()
  const startDateValue = updatedRow?.startDate ? FormatDateAPI(updatedRow?.startDate) : null
  const endDateValue = updatedRow?.endDate ? FormatDateAPI(updatedRow?.endDate) : null

  let payload = {
    portSpecId: updatedRow?.portSpecId,
    propertyCode: updatedRow?.propertyCode?.toUpperCase(),
    propertyValue: updatedRow?.propertyValue,
    startDate: startDateValue,
    endDate: endDateValue
  }
  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseAccountDataMainURL',
    `data-maintenance/v1/${userRole}/portfolio/specifications/properties/${updatedRow?.portSpecPropertiesId}`,
    payload
  )
}

const UpdateAccountSubstitution = async (updatedRow, oldRow) => {
  const userRole = getUserRole()
  const startDateValue = updatedRow?.startDate ? FormatDateAPI(updatedRow.startDate) : null
  const endDateValue = updatedRow?.endDate ? FormatDateAPI(updatedRow.endDate) : null
  const accountInstrSubsId = oldRow?.accountInstrSubsId ? oldRow?.accountInstrSubsId : null

  let payload = {
    accountId: updatedRow?.accountId,
    sourceInstrIdData: updatedRow?.sourceInstrId,
    targetInstrIdData: updatedRow?.targetInstrId,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/instr-substitution/${accountInstrSubsId}`,
    payload
  )
}

const UpdateAccountRestrictions = async (updatedRow, oldRow) => {
  const userRole = getUserRole()
  const startDateValue = updatedRow?.startDate ? FormatDateAPI(updatedRow.startDate) : null
  const endDateValue = updatedRow?.endDate ? FormatDateAPI(updatedRow.endDate) : null

  let payload = {
    instrId: updatedRow?.instrId,
    restrictionId: updatedRow?.restrictionId,
    startDate: startDateValue,
    endDate: endDateValue,
    weight: updatedRow?.weight
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/instrument-restriction/${oldRow?.accountInstrRestrId}`,
    payload
  )
}

const UpdateTaxRates = async (updatedRow, oldRow) => {
  const userRole = getUserRole()
  let payload = {
    currentTaxYear: updatedRow?.currentTaxYear,
    taxSensitivity: updatedRow?.taxSensitivity,
    stateId: updatedRow?.stateId,
    stateLongTax: updatedRow?.stateLongTax,
    stateMedTax: updatedRow?.stateMedTax,
    stateShortTax: updatedRow?.stateShortTax,
    fedLongTax: updatedRow?.fedLongTax,
    fedMedTax: updatedRow?.fedMedTax,
    fedShortTax: updatedRow?.fedShortTax
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/${updatedRow?.accountId}/tax-rates/${oldRow?.currentTaxYear}`,
    payload
  )
}

const UpdateAccountProperties = async (updatedRow) => {
  const userRole = getUserRole()
  const startDateValue = updatedRow?.startDate ? FormatDateAPI(updatedRow?.startDate) : null
  const endDateValue = updatedRow?.endDate ? FormatDateAPI(updatedRow?.endDate) : null

  let payload = {
    accountId: updatedRow?.accountId,
    propertyCode: updatedRow?.propertyCode?.toUpperCase(),
    propertyValue: updatedRow?.propertyValue,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/properties/${updatedRow?.accountPropertiesId}`,
    payload
  )
}

const UpdateAccountIgnoreTaxLot = async (updatedRow) => {
  const userRole = getUserRole()
  const purchaseDateValue = updatedRow?.purchaseDate ? FormatDateAPI(updatedRow?.purchaseDate) : null
  const endDateValue = updatedRow?.endDate ? FormatDateAPI(updatedRow?.endDate) : null

  let payload = {
    orgPurchasePrice: updatedRow?.orgPurchasePrice,
    purchaseDate: purchaseDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return UpdateAPI(
    'baseAccountDataMainURL',
    `data-maintenance/v1/${userRole}/ignore-lot/${updatedRow?.ignoreTaxlotId}`,
    payload
  )
}
