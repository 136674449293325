import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import { Box, Button, Chip, IconButton, Skeleton, styled, Tooltip, Typography } from '@mui/material'
import { CachedOutlined, VisibilityOutlined } from '@mui/icons-material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { ACCESS_LEVEL } from '../../../../contstants/constants'
import { moduleConfig } from '../../../../contexts/data'
import { useAuth } from '../../../../contexts/AuthContext'
import { useSuccessToast } from '../../../../hooks/useSuccessToast'
import { useErrorToast } from '../../../../hooks/useErrorToast'
import Loader from '../../../Loader'
import OptimizationDataModal from '../../components/OptimizationDataModal'
import { UmaContext } from '..'

const UMADetail = () => {
  const { user, checkAccess } = useAuth()
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { showSuccess } = useSuccessToast()
  const { showError } = useErrorToast()
  const [previousRoute, setPreviousRoute] = useState(null)
  const [isOptimizationLoading, setIsOptimizationLoading] = useState(false)
  const [optimizationData, setOptimizationData] = useState([])
  const [isOptDataLoading, setIsOptDataLoading] = useState(false)
  const [showOptimizationPopup, setShowOptimizationPopup] = useState('')
  const umaContextData = useContext(UmaContext)
  const loading = umaContextData?.topCard?.loading
  const currentUmaData = umaContextData?.topCard?.data
  const umaDetailsData = umaContextData?.umaDetailsData?.data
  const umaDetailsLoading = umaContextData?.umaDetailsData?.loading

  useEffect(() => {
    // Only set the previousRoute if it's provided and we don't already have one
    // This ensures we remember the original source even after tab switches
    if (location.state?.previousRoute && !previousRoute) {
      setPreviousRoute(location?.state?.previousRoute)
    }
  }, [location.state, previousRoute])

  const backBtnClickHandler = () => {
    if (currentUmaData?.aggType === 'SCENARIO_TRANSITION') {
      navigate(`/aggregate/uma-dashboard/${umaDetailsData?.aggId}`, { state: { umaType: 'TRANSITION' } })
    } else {
      navigate('/aggregate/aggregate-dashboard')
    }
  }

  const triggerOptimization = (generateFlag) => {
    const requestId = umaContextData?.scenarioCardData[0]?.userRqstId || ''
    if (generateFlag) {
      setIsOptDataLoading(true)
      setShowOptimizationPopup('rerun-btn')
    } else {
      setIsOptimizationLoading(true)
    }
    API.post(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user?.userGroup}/uma/opt/adhoc/${requestId}/${params?.aggGroupId}`, {
      queryStringParameters: {
        'generate-only': !!generateFlag
      },
      ...(!generateFlag
        ? {
          body: optimizationData
        }
        : {})
    }
    )
      .then((res) => {
        if (generateFlag) {
          if (res?.data?.length) {
            setOptimizationData(res?.data)
          } else {
            setOptimizationData([])
          }
        } else if (res?.success) {
          showSuccess(res?.message)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to trigger optimization.')
      })
      .finally(() => {
        if (generateFlag) {
          setIsOptDataLoading(false)
        } else {
          setShowOptimizationPopup('')
          setIsOptimizationLoading(false)
        }
      })
  }

  const fetchOptimizationData = () => {
    const requestId = umaContextData?.scenarioCardData[0]?.userRqstId || ''
    setIsOptDataLoading(true)
    API.get(
      'baseUriAggregateMaster2',
      `aggregate-master/v1/${user?.userGroup}/opt-request/${requestId}/${params?.aggGroupId}`
    )
      .then((res) => {
        if (res?.data?.length) {
          setOptimizationData(res?.data)
        } else {
          setOptimizationData([])
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to fetch optimization data.')
      })
      .finally(() => {
        setIsOptDataLoading(false)
      })
  }

  const handleOptDetailClick = () => {
    setShowOptimizationPopup('view-json-btn')
    fetchOptimizationData()
  }

  return (
    <>
      {isOptimizationLoading ? <Loader /> : ''}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <BackButton onClick={backBtnClickHandler} disabled={umaDetailsLoading || loading}>
            <KeyboardArrowLeftIcon sx={{ color: '#002A59' }} />
          </BackButton>
          <Box sx={{ display: 'flex', ml: '18px', alignItems: 'center' }}>
            <PageTitle>UMA</PageTitle>
            <VerticalDivider />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {
                  umaDetailsLoading ? (
                    <Skeleton variant='text' sx={{ width: '150px', fontSize: '16px' }} />
                  ) : (
                    <Typography sx={{ color: '#002A59' }}>{umaDetailsData?.aggName || ''}</Typography>
                  )
                }
                {
                  !umaDetailsLoading ? (
                    <Chip label={umaDetailsData?.aggType || location.state?.umaType || ''} size='small'
                      sx={{
                        background: '#50CAF1', color: 'white',
                        fontSize: '10px', fontWeight: '500',
                        ml: 1, height: '16px'
                      }} />
                  ) : ''
                }
              </Box>
              {
                umaDetailsLoading ? (
                  <Skeleton variant='text' sx={{ width: '50px', fontSize: '14px' }} />
                ) : (
                  <Typography sx={{ color: '#6A86A6', fontSize: '14px' }}>{umaDetailsData?.aggCd || ''}</Typography>
                )
              }
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          {
            umaContextData?.scenarioCardData?.length && currentUmaData?.aggType === 'TRANSITION'
              ? (
                <>
                  {
                    checkAccess(moduleConfig.AGGREGATE, ACCESS_LEVEL.COMPONENT_ACCESS, {
                      subModuleName: moduleConfig.AGGREGATE_DASHBOARD, component_name: moduleConfig.RE_RUN_UMA_TRANSITION_OPTIMIZATION
                    })
                      ? (
                        <OptimizationTriggerButton triggerOptimization={triggerOptimization} />
                      )
                      : ''
                  }
                  {
                    checkAccess(moduleConfig.AGGREGATE, ACCESS_LEVEL.COMPONENT_ACCESS, {
                      subModuleName: moduleConfig.AGGREGATE_DASHBOARD, component_name: moduleConfig.VIEW_UMA_TRANSITION_OPTIMIZATION_JSON
                    })
                      ? (
                        <ViewOptimizationJson handleOptDetailClick={handleOptDetailClick} />
                      )
                      : ''
                  }
                </>
              )
              : ''
          }
          <SwitchAggregateButton variant='outlined'
            onClick={() => navigate('/aggregate/aggregate-dashboard')}
          >
            Switch Aggregate
          </SwitchAggregateButton>
        </Box>
      </Box>
      {
        showOptimizationPopup
          ? (
            <OptimizationDataModal
              loading={isOptDataLoading}
              jsonData={optimizationData}
              onClose={() => setShowOptimizationPopup('')}
              isOpen={Boolean(showOptimizationPopup)}
              triggerOptimization={triggerOptimization}
              showDownloadButton={showOptimizationPopup === 'rerun-btn'}
            />
          )
          : ''
      }
    </>
  )
}

const OptimizationTriggerButton = ({ triggerOptimization }) => {
  return (
    <Tooltip title='Rerun Optimization'>
      <Button
        variant='outlined'
        onClick={() => triggerOptimization(true)}
        sx={{
          border: '1px solid #002A59',
          padding: '8px',
          minWidth: 'auto',
          ':hover': {
            background: 'transparent',
            border: '1px solid #002A59'
          }
        }}
      >
        <CachedOutlined sx={{ width: 20, height: 20, color: '#002A59' }} />
      </Button>
    </Tooltip>
  )
}

const ViewOptimizationJson = ({ handleOptDetailClick }) => {
  return (
    <Tooltip title='View Optimization JSON'>
      <Button
        variant='outlined'
        onClick={handleOptDetailClick}
        sx={{
          border: '1px solid #002A59',
          minWidth: 'auto',
          ':hover': {
            background: 'transparent',
            border: '1px solid #002A59'
          },
          padding: '8px'
        }}
      >
        <VisibilityOutlined sx={{ width: 20, height: 20, color: '#002A59' }} />
      </Button>
    </Tooltip>
  )
}

const SwitchAggregateButton = styled(Button)({
  color: '#002A59',
  fontWeight: 700,
  border: '1px solid #002A59',
  textTransform: 'capitalize',
  p: '15px 24px',
  '&:hover': {
    border: '1px solid #002A59'
  }
})

const BackButton = styled(IconButton)({
  backgroundColor: '#CCE4FF',
  borderRadius: '12px',
  '&:hover': {
    backgroundColor: '#CCE4FF'
  },
  '&.Mui-disabled': {
    backgroundColor: '#CCE4FF',
    opacity: 0.7
  }
})

const PageTitle = styled(Typography)({
  fontWeight: 600,
  color: '#002A59',
  fontSize: '32px',
  fontFamily: 'Lora'
})

const VerticalDivider = styled(Box)({
  background: 'linear-gradient( #FFFFFF, #80A8D6, #FFFFFF)',
  marginInline: '13px',
  height: '50px',
  width: '2px',
  borderColor: 'transparent'
})

export default UMADetail
