import { useState, useEffect } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { MenuItem } from '@mui/material'

const CustomAccountNameField = (props) => {
  const { accountData, params, error } = props
  const [options, setOptions] = useState(accountData || [])

  const handleAccountChange = (event, newValue) => {
    if (newValue) {
      params.api.setEditCellValue({ id: params.id, field: 'account', value: { accountId: newValue.accountId, accountName: newValue.accountName } })
      params.api.setEditCellValue({
        id: params.id,
        field: 'strategy',
        value: {
          strategyId: newValue?.strategyId,
          strategyName: newValue?.strategyName,
          strTypeCode: newValue?.strTypeCode,
          portSpecName: newValue?.portSpecName,
          portSpecId: newValue?.portSpecId
        }
      })
      params?.api?.updateRows([{ id: params?.id, strategyError: !newValue, accountError: !newValue }])
    }
  }

  const handleInputChange = (event, newInputValue) => {
    if (newInputValue?.trim()?.length >= 3) {
      setOptions(accountData)
    }
  }

  useEffect(() => {
    if (accountData) {
      setOptions(accountData)
    }
  }, [accountData])

  return (
    <Autocomplete
      value={params?.value || null}
      onChange={handleAccountChange}
      onInputChange={handleInputChange}
      size='small'
      noOptionsText='No Accounts found'
      options={options}
      getOptionLabel={(option) => (`${option?.accountName || ''}`)}
      renderOption={(props, option) => (
        <MenuItem {...props} sx={{ whiteSpace: 'normal' }} key={option?.accountId}>
          {option?.accountName}
        </MenuItem>
      )}
      isOptionEqualToValue={(option, value) => (option?.accountId === value?.accountId)}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Account Name'
          InputLabelProps={{ shrink: true }}
          error={error}
        />
      )}
      style={params?.style || {}}
      sx={{ width: '100%', mr: 1 }}
    />
  )
}

export default CustomAccountNameField
