import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import ErrorIcon from '@mui/icons-material/Error'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import SaveIcon from '@mui/icons-material/Save'
import SettingsIcon from '@mui/icons-material/Settings'
import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Box, Button,
  Card, CardContent,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  gridClasses
} from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// import CSVFileValidator from 'csv-file-validator'
import Papa from 'papaparse'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // Import the UTC plugin
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'
import download from '../../assets/images/downloadFiles.svg'
import fileImage from '../../assets/images/selectedFile.svg'
import Success from '../../assets/images/success.svg'
import { HtmlTooltip } from '../../components/CustomTooltip'
import TextAreaEditor from '../../components/TextAreaEditor'
import { checkInstrumentSearchQuery } from '../../utils/searchQueryUtils'
import { useAuth } from '../../contexts/AuthContext'
import { moduleConfig } from '../../contexts/data'
import { ACCESS_LEVEL } from '../../contstants/constants'
import { useErrorToast } from '../../hooks/useErrorToast'
import '../../pages/AccountOnboarding/AccountOnboarding.scss'
import '../../pages/AccountReview/import-portfolio.scss'
import Loader from '../../pages/Loader'
import StrategyPortfolioUpload from './StrategyPortfolioUpload'
import ViewConfirmModelPopup from './ViewConfirmModelPopup'
dayjs.extend(utc)

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 2,
  py: 2,
  width: '100%',
  minWidth: '600px',
  maxWidth: '900px',
  borderRadius: '4px',
  outline: 'none'
}

const portfolioModalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '16px 24px 20px',
  borderRadius: '4px',
  height: '100vh'
}

const successModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
}

const invalidErrorModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 3,
  py: 2,
  width: '100%',
  minWidth: '600px',
  maxWidth: '800px',
  borderRadius: '4px',
  height: '650px',
  maxHeight: '100vh'
}

const StrategyImport = (props) => {
  const { submitRef, getCurrentTaxlotData , fileSize, setFileSize, configModel, loading, switchToCurrentPortfolio, downloadFileFromBuffer, countryOptions, isCountrySearchLoading, samplePortfolioData, isSamplePortfolioFetching, file, setFile, additionalMessage, setAdditionalMessage, hasDynamicPortfolioAccess } = props
  const { user, checkAccess } = useAuth()
  const { showError, sendSentryError } = useErrorToast()
  const params = useParams()

  const validationSchema = Yup.object({
    totalPortfolioMarketValue: Yup.string(),
    securityTaxLotsFile: Yup.mixed().required('A file is required')
  })
  const [showSecondMsg, setShowSecondMsg] = useState(false)
  const [isPortfolioFileUploading, setIsPortfolioFileUploading] = useState(false)
  const [showPortfolioSubmitSuccessModal, setShowPortfolioSubmitSuccessModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState('Validating...')
  const [isSecuritySearchLoading, setIsSecuritySearchLoading] = useState(false)
  const [holdingDate, setHoldingDate] = useState(dayjs.utc().format('YYYY-MM-DD'))
  const [fileId, setFileId] = useState('')
  const [stagedData, setStagedData] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [isoCodeInputValue, setIsoCodeInputValue] = useState(null)
  const [securityInputValue, setSecurityInputValue] = useState(null)
  const [securityOptions, setSecurityOptions] = useState([])
  const [showPortfolioValidationPopup, setShowPortfolioValidationPopup] = useState(false)
  const [isoCodeEditIndex, setIsoCodeEditIndex] = useState([])
  const [isoCodeInputValueList, setIsoCodeInputValueList] = useState([])
  const [showPortfolioConfirmationModal, setShowPortfolioConfirmationModal] = useState(false)
  const [newStagedData, setNewStagedData] = useState([])
  const [totalInputWeight, setTotalInputWeight] = useState(0)
  const [totalAdjustedWeight, setTotalAdjustedWeight] = useState(0)
  const [invalidRowsCount, setInvalidRowsCount] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [isErrorModelOpen, setIsErrorModelOpen] = useState(false)
  const [errorModalContent, setErrorModalContent] = useState([])
  const [delimiter, setDelimiter] = useState(null)
  const [showFileConfigurationPopup, setShowFileConfigurationPopup] = useState(false)
  const [errorPopupTitle, setErrorPopupTitle] = useState('')
  const [confirmModelPopup, setConfirmModelPopup] = useState(false)
  const [isWeightDiffExists, setIsWeightDiffExists] = useState(false)
  const [sectorAndCompanyData, setSectorAndCompanyData] = useState([])
  const [isSectorAndCompanyFetching, setIsSectorAndCompanyFetching] = useState(false)
  const [fileUploadApiLoading, setFileUploadApiLoading] = useState(false)
  const [preSignedApiLoading, setPreSignedApiLoading] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 15))
    setPage(0)
  }

  const displayRows = stagedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  // Calculate total number of rows for error message
  const totalRows = stagedData?.length || 0
  // Filter items with errors to show error message
  const errorItems = stagedData?.filter(item => !item.name || parseFloat(item.weightValue) <= 0 || !item.weightValue || item.hasCodeError || item.matchStatus === 'red') || []
  const errorCount = errorItems?.length || 0

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const sampleColumnData = [
    {
      headerName: 'Vendor_Name',
      field: 'vendorName',
      flex: 1
    },
    {
      headerName: 'Model_Name',
      field: 'strategyName',
      flex: 1
    },
    {
      headerName: 'Model_Code',
      field: 'strategyCd',
      flex: 1
    },
    {
      headerName: 'Effective_Date',
      field: 'holdingDate',
      flex: 1
    },
    {
      headerName: 'Symbol',
      field: 'symbol',
      flex: 1
    },
    {
      headerName: 'Company_Name',
      field: 'companyName',
      flex: 1
    },
    {
      headerName: 'MIC',
      field: 'exchangeCode',
      flex: 1
    },
    {
      headerName: 'ISO_Country_Code',
      field: 'isoCountryCode',
      flex: 1
    },
    {
      headerName: 'ISO_Currency_Code',
      field: 'isoCurrencyCode',
      flex: 1
    },
    {
      headerName: 'CUSIP',
      field: 'CUSIP',
      flex: 1
    },
    {
      headerName: 'ISIN',
      field: 'ISIN',
      flex: 1
    },
    {
      headerName: 'SEDOL',
      field: 'SEDOL',
      flex: 1
    },
    {
      headerName: 'FIGI',
      field: 'FIGI',
      flex: 1
    },
    {
      headerName: 'Weight',
      field: 'weight',
      align: 'left',
      type: 'number',
      headerAlign: 'left',
      flex: 1
    }
  ]

  useEffect(() => {
    if (showPortfolioSubmitSuccessModal) {
      getCurrentTaxlotData()
    }
  }, [showPortfolioSubmitSuccessModal])

  const onSecurityNameChange = (event, query, index) => {
    setIsSecuritySearchLoading(true)
    if (checkInstrumentSearchQuery(query)) {
      API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/instruments`, {
        queryStringParameters: { search: query }
      })
        .then((response) => {
          if (response?.data) {
            const temp = [...securityOptions]
            temp[index] = [...response.data]
            setSecurityOptions(temp)
          }
        })
        .catch((error) => {
          showError(error, false, {}, 'Failed to load security.')
        })
        .finally(() => {
          setIsSecuritySearchLoading(false)
        })
    } else {
      setIsSecuritySearchLoading(false)
    }
  }

  const openModal = () => {
    document.body.classList.add('modal-open', 'pe-0')
    setShowPortfolioValidationPopup(true)
  }

  const showSuccessModel = () => {
    setShowPortfolioSubmitSuccessModal(true)
    setShowSecondMsg(true)

    setTimeout(() => {
      setShowPortfolioSubmitSuccessModal(false)
      closeModal()
      setFile('')
      // Switch to Current Portfolio tab after successful upload
      switchToCurrentPortfolio()
    }, 3000)
  }

  const closeModal = () => {
    // if (document.querySelectorAll('.invalid-entry').length > 0) {
    //   showError('Please correct data')
    // } else {
    calculateMarketValue()
    setShowPortfolioValidationPopup(false)
    setTimeout(() => {
      document.body.classList.remove('modal-open', 'pe-0')
    }, 200)
    // }
    setShowPortfolioConfirmationModal(false)
    setIsWeightDiffExists(false)
  }

  const CloseMsgModel = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setShowPortfolioSubmitSuccessModal(false)
    }
  }
  const calculateMarketValue = () => {
    let sum = 0
    stagedData?.forEach((element) => {
      sum = sum + Number(element.purchaseCost)
    })
    setValue('totalPortfolioMarketValue', sum.toString())
  }

  const onSubmitHandler = () => {
    setApiLoading(true)
    const emptyFields = stagedData?.filter(item =>
      item?.weightValue === null || item?.weightValue === '' || item?.weightValue <= 0 ||
      item?.symbol === null || item?.symbol === '' ||
      item?.name === null || item?.name === ''
    ).length
    if (emptyFields && emptyFields > 0) {
      showError('Please enter the correct values')
    } else {
      const fomattedData = stagedData?.map((e) => {
        return { symbol: e.symbol, weight: e.weight }
      })

      API.post(
        'baseStrategyURL',
        `strategy/v1/${user.userGroup}/strategy-imports/submit/${fileId}`,
        { body: [...fomattedData] }
      )
        .then((response) => {
          alert(response.message)
          setFile('')
        })
        .catch((error) => {
          if (Array.isArray(error.response?.data?.errorInfo?.userMessage)) {
            error?.response?.data?.errorInfo?.userMessage?.forEach((element) => {
              showError(error, false, {}, 'Failed to upload strategy portfolio.')
            })
          } else {
            showError(error, false, {}, 'Failed to submit strategy.')
          }
          setApiLoading(false)
        }).finally(() => {
          setApiLoading(false)
        })
    }
  }

  const csvConfig = {
    headers: configModel && configModel?.length
      ? configModel[0]?.fieldMapping?.map(mapping => ({
        name: mapping.sourceField,
        inputName: mapping.targetField,
        required: mapping.required
      }))
      : [
          {
            name: 'Vendor_Name',
            inputName: 'vendorName',
            required: false
          },
          {
            name: 'Model_Name',
            inputName: 'strategyName',
            required: false
          },
          {
            name: 'Model_Code',
            inputName: 'strategyCd',
            required: false
          },
          {
            name: 'Effective_Date',
            inputName: 'holdingDate',
            required: false
          },
          {
            name: 'Symbol',
            inputName: 'symbol',
            required: true
          },
          {
            name: 'Company_Name',
            inputName: 'companyName',
            required: false
          },
          {
            name: 'MIC',
            inputName: 'exchangeCode',
            required: false
          },
          {
            name: 'ISO_Country_Code',
            inputName: 'isoCountryCode',
            required: false
          },
          {
            name: 'ISO_Currency_Code',
            inputName: 'isoCurrencyCode',
            required: false
          },
          {
            name: 'CUSIP',
            inputName: 'CUSIP',
            required: false
          },
          {
            name: 'ISIN',
            inputName: 'ISIN',
            required: false
          },
          {
            name: 'SEDOL',
            inputName: 'SEDOL',
            required: false
          },
          {
            name: 'FIGI',
            inputName: 'FIGI',
            required: false
          },
          {
            name: 'Weight',
            inputName: 'weight',
            required: true
          }
        ]
  }

  const handleUpload = async () => {
    const fileSize = (file.size / 1024).toFixed(2) // KB
    if ((file.type !== configModel[0]?.contentType && configModel && configModel?.length) || (file.type !== 'text/csv' && file.type !== 'text/plain' && file.type !== 'application/vnd.ms-excel')) {
      showError(`Please upload ${configModel ? configModel[0]?.fileTypeCd : 'csv or text'} file`)
      setFile('')
    } else if (fileSize > 1024) {
      showError('Please upload file size less than 1 MB')
      setFile('')
    } else {
      // Passing file data to parse using Papa.parse
      Papa.parse(file, {
        delimitersToGuess: configModel && configModel[0]?.fileParameters && configModel[0]?.fileParameters[0]?.paramCd === 'delimiter' ? [configModel[0]?.fileParameters[0]?.paramValue] : [','],
        skipEmptyLines: true,
        complete: (results) => {
          const errors = {
            headerErrors: [],
            rowErrors: []
          }
          const portfolioJsonData = []
          if (results?.data?.length) {
            const headersArray = results.data[0]
            const originalFileContent = results.data

            // find mandatory columns are present or not and store error message for missing header
            const mandatoryColumns = csvConfig.headers.filter(column => column.required)
            const validationOfColumns = mandatoryColumns.every(ele => headersArray.includes(ele.name))
            if (!validationOfColumns) {
              const headerErrors = mandatoryColumns.filter(column => !headersArray.includes(column.name)).map(column => `Header name ${column.name} is not correct or missing`)
              errors.headerErrors = [...headerErrors]
            }

            // store index of column position in file data
            const positionOfColumnInFileJson = {}
            for (const column of csvConfig.headers) {
              // ignore column without target field
              if (column?.inputName) {
                positionOfColumnInFileJson[column?.inputName] = headersArray.findIndex(name => name === column?.name)
              }
            }

            for (let rowIndex = 1; rowIndex < originalFileContent.length; rowIndex++) {
              const columns = originalFileContent[rowIndex]
              const rowJson = {}
              csvConfig?.headers?.forEach(header => {
                // ignore column without target field
                if (header?.inputName) {
                  if (header?.inputName !== 'weight' && header?.required) {
                    // check if symbol value in the row is same as cashticker in config, then replace with __USD else keep the symbol as it is from file
                    rowJson[header?.inputName] = (positionOfColumnInFileJson && columns[positionOfColumnInFileJson[header?.inputName]])
                      ? (configModel?.length && columns[positionOfColumnInFileJson[header?.inputName]] === configModel[0]?.cashTicker)
                          ? '__USD'
                          : columns[positionOfColumnInFileJson[header?.inputName]]
                      : null
                  } else {
                    rowJson[header?.inputName] = (positionOfColumnInFileJson && columns[positionOfColumnInFileJson[header?.inputName]]) || null
                  }
                }
              })

              // if all mandatory column are present, store row
              if (mandatoryColumns.every(header => rowJson[header?.inputName])) {
                portfolioJsonData.push({ ...rowJson })
              } else {
                errors.rowErrors = [...errors?.rowErrors, `Mandatory field ${mandatoryColumns.map(column => column?.name || '').join(' or ')} is missing in the row ${rowIndex}`]
              }
            }
            if ((errors?.rowErrors?.length > 0) || (errors?.headerErrors?.length > 0)) {
              setErrorModalContent(errors)
              setIsErrorModelOpen(true)
              setErrorPopupTitle('Missing Information in Uploaded File')
            } else {
              setErrorModalContent([])
              setIsErrorModelOpen(false)
              if (Array.isArray(portfolioJsonData) && portfolioJsonData?.length > 0) {
                setIsPortfolioFileUploading(true)
                const updatedCsvData = portfolioJsonData?.map((item) => (
                  {
                    symbol: item?.symbol ? item?.symbol?.toUpperCase() : null,
                    weight: item?.weight,
                    isoCountryCode: item?.isoCountryCode || 'US',
                    cusip: item?.CUSIP,
                    sedol: item?.SEDOL,
                    isin: item?.ISIN
                  }
                ))
                getSecurityDetails(updatedCsvData || portfolioJsonData)
              } else {
                showError('No rows available in the uploaded file. Please re-upload file with rows')
              }
            }
          }
        }
      })
    }
  }

  const getSecurityDetails = (csvDataProcessed, retry = 2) => {
    API.post(
      'baseStrategyURL',
      `strategy/v1/${user?.userGroup}/strategy-portfolio/preprocessed-data/${params?.strategyId}`,
      {
        queryStringParameters: checkAccess(moduleConfig.MODEL, ACCESS_LEVEL.COMPONENT_ACCESS,
          { subModuleName: moduleConfig.STRATEGY_PORTFOLIO, component_name: moduleConfig.HOLDING_DATE })
          ? { 'holding-date': holdingDate }
          : {},
        body: csvDataProcessed?.map((data) => ({ ...data, weight: data?.weight }))
      }
    )
      .then((response) => {
        if (response?.data) {
          setTotalInputWeight(response?.data?.totalInputWeight)
          setTotalAdjustedWeight(response?.data?.totalAdjustedWeight)
          const tempStagData = (response?.data?.data || [])?.map((data, index) => {
            return {
              ...data, clicked: false, hasCodeError: false, id: uuidv4(), weightValue: data.weight, adjustedWeight: data.adjustedWeight, isWeightEdited: false, isSecurityChanged: false
            }
          }).sort((a, b) => {
            const aCondition = !a.name || !a.weight || a.weight < 0 || a.matchStatus === 'red' || a.weightStatus === 'red'
            const bCondition = !b.name || !b.weight || b.weight < 0 || b.matchStatus === 'red' || b.weightStatus === 'red'

            if (aCondition && !bCondition) return -1
            if (!aCondition && bCondition) return 1

            const statusOrder = ['orange', 'green']
            return statusOrder.indexOf(a.matchStatus) - statusOrder.indexOf(b.matchStatus)
          })

          const fileId = response?.data?.fileId || 'no file ID'
          setFileId(fileId)
          setInvalidRowsCount(0)
          setPage(0)
          setStagedData(tempStagData)
          openModal()
          setIsPortfolioFileUploading(false)
          setIsWeightDiffExists(response?.data?.isWeightDiffExists)
          setIsPortfolioFileUploading(false)
          setApiLoading(false)
        } else {
          setIsPortfolioFileUploading(false)
          setApiLoading(false)
        }
      })
      .catch((error) => {
        if (retry > 0 && error.response?.data?.errorInfo?.data?.timeoutReached) {
          getSecurityDetails(csvDataProcessed, retry - 1)
        } else {
          showError('Issue in file processing. Please re-upload file.')
          setIsPortfolioFileUploading(false)
          setApiLoading(false)
        }
      })
  }

  const getConfirmedSecurityDetails = (formattedData) => {
    setApiLoading(true)
    API.post(
      'baseStrategyURL',
      `strategy/v1/${user?.userGroup}/model-changes/${params?.strategyId}`,
      {
        body: formattedData
      }
    )
      .then((response) => {
        if (response?.data) {
          const tempStagData = response?.data?.data?.map((data, index) => {
            return { ...data, id: uuidv4() }
          })
          setNewStagedData(tempStagData)
          setApiLoading(false)
          setShowPortfolioConfirmationModal(true)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load model changes.')
      })
      .finally(() => {
        setApiLoading(false)
      })
  }

  const getSectorCompanyDetails = (formattedData) => {
    setIsSectorAndCompanyFetching(true)
    API.post(
      'baseStrategyURL',
      `strategy/v1/${user?.userGroup}/model-changes/${params?.strategyId}`,
      {
        queryStringParameters: { sectorAllocation: true },
        body: formattedData
      }
    )
      .then((response) => {
        if (response?.data?.data?.length) {
          const sectorAndCompanyData = response?.data?.data?.map((data, index) => {
            return { ...data, id: uuidv4() }
          })
          setSectorAndCompanyData(sectorAndCompanyData)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load sector and company details.')
      })
      .finally(() => {
        setIsSectorAndCompanyFetching(false)
      })
  }

  const submitStrategyPortfolio = () => {
    setConfirmModelPopup(false)
    setApiLoading(true)
    setIsoCodeInputValueList([])
    let batchId = null
    const fomattedData = stagedData?.map((strategyObj) => ({
      symbol: strategyObj.symbol,
      weight: strategyObj.adjustedWeight,
      instrId: strategyObj.instrId
    })
    )
    const strategySubmitPayload = {
      portfolio: [...fomattedData]
    }
    if (additionalMessage?.trim()?.length) {
      strategySubmitPayload.additionalText = additionalMessage
    }

    API.post(
      'baseStrategyURL',
        `strategy/v1/${user.userGroup}/strategy-imports/submit/${fileId}`,
        { body: strategySubmitPayload }
    )
      .then((response) => {
        if (response && response?.success) {
          setSuccessMessage(response.message)
          const temp = stagedData?.map((data) => {
            return { ...data, clicked: false, hasCodeError: false }
          })
          batchId = response?.data?.batchId
          setStagedData(temp)
          setAdditionalMessage('')
          setIsWeightDiffExists(false)
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to submit strategy portfolio.')
        setApiLoading(false)
      }).finally(() => {
        if (batchId) {
          fetchPreSignedAPI(batchId, 2)
        }
        setApiLoading(false)
      })
  }

  const fetchPreSignedAPI = (batchId, retries = 2) => {
    setPreSignedApiLoading(true)
    let hasError = false
    API.get('baseStrategyURL', `strategy/v1/${user.userGroup}/strategy-imports/batch/${batchId}/strategy/${params.strategyId}`)
      .then((res) => {
        if (res && res?.success) {
          fileUploadApi(res, hasError, retries, fetchPreSignedAPI, batchId)
        }
      }).catch((error) => {
        hasError = true
        sendSentryError(error)
      }).finally(() => {
        if (hasError && retries > 0) {
          retries -= 1
          setTimeout(() => {
            fetchPreSignedAPI(batchId, retries)
          }, 1000)
        } else if (hasError) {
          sendFailedToUploadMail(params.strategyId)
        } else {
          setPreSignedApiLoading(false)
        }
      })
  }

  const sendFailedToUploadMail = (strategyId) => {
    API.get('baseStrategyURL', `strategy/v1/${user.userGroup}/mail-trigger/${strategyId}`)
      .then()
      .catch((error) => {
        sendSentryError(error)
      }).finally(() => {
        setPreSignedApiLoading(false)
        setFileUploadApiLoading(false)
        showSuccessModel()
      })
  }

  const fileUploadApi = (res, hasError, retries, retryCallback, batchId) => {
    setFileUploadApiLoading(true)
    const formData = new FormData()
    formData.append('Content-Type', res?.data?.fields ? res?.data?.fields['Content-Type'] : '')
    formData.append('tagging', res?.data?.fields ? res?.data?.fields.tagging : '')
    formData.append('bucket', res?.data?.fields?.bucket)
    formData.append('X-Amz-Algorithm', res?.data?.fields ? res?.data?.fields['X-Amz-Algorithm'] : '')
    formData.append('X-Amz-Credential', res?.data?.fields ? res?.data?.fields['X-Amz-Credential'] : '')
    formData.append('X-Amz-Date', res?.data?.fields ? res?.data?.fields['X-Amz-Date'] : '')
    formData.append('X-Amz-Security-Token', res?.data?.fields ? res?.data?.fields['X-Amz-Security-Token'] : '')
    formData.append('key', res?.data?.fields.key)
    formData.append('Policy', res?.data?.fields?.Policy)
    formData.append('X-Amz-Signature', res?.data?.fields ? res?.data?.fields['X-Amz-Signature'] : '')
    formData.append('file', file)
    fetch(res?.data?.url, {
      method: 'POST',
      body: formData
    }).then((result) => {
      if (!result?.ok) {
        hasError = true
      } else {
        showSuccessModel()
      }
    })
      .catch(error => {
        hasError = true
        sendSentryError(error)
      }).finally(() => {
        if (hasError && retries > 0) {
          retries -= 1
          setTimeout(() => {
            retryCallback(batchId, retries)
          }, 1000)
        } else if (hasError) {
          sendFailedToUploadMail(params.strategyId)
        } else {
          setFileUploadApiLoading(false)
        }
      })
  }

  const handleCancel = () => {
    setValue('totalPortfolioMarketValue', '')
    setFile(null)
    // if (fileInputRef.current) {
    //   fileInputRef.current.value = ''
    // }
  }

  const onSecurityChangeHandler = (event, value, index) => {
    const temp = [...stagedData]
    if (value) {
      temp[index].isSecurityChanged = true
      temp[index].name = value?.name
      temp[index].symbol = value?.localSymbol
      temp[index].instrId = value?.instrId
      temp[index].isoCountryCode = value?.isoCountryCode
      setStagedData(temp)
    }
    setIsoCodeInputValue(temp[index].isoCountryCode)
    const updatedIsoCountryCodes = [...isoCodeInputValueList]
    updatedIsoCountryCodes[index] = value?.isoCountryCode
    setIsoCodeInputValueList(updatedIsoCountryCodes)

    if (stagedData[index]?.isoCountryCode === value?.isoCountryCode) {
      stagedData[index].hasCodeError = false
    }
  }

  const onISOCodeChangeHandler = (event, value, index) => {
    if (value) {
      const temp = [...stagedData]
      const currentIsoCountryCode = temp[index].isoCountryCode
      if (currentIsoCountryCode !== value?.code) {
        if (
          (temp[index]?.newRow && isoCodeEditIndex[index] !== value?.code && isoCodeInputValueList[index] !== value?.code) ||
          (isoCodeEditIndex[index] !== value?.code && isoCodeInputValueList[index] !== value?.code)
        ) {
          temp[index].isoCountryCode = value?.code
          temp[index].hasCodeError = true
        } else {
          temp[index].isoCountryCode = value?.code
          temp[index].hasCodeError = false
        }
        setStagedData(temp)
      }
    }
  }

  // function to handle the delimiter input value change
  const handleDelimiterInputChange = (event) => {
    setDelimiter(event.target.value)
  }

  const handleHoldingDateChange = (date) => {
    if (date) {
      setHoldingDate(date?.format('YYYY-MM-DD'))
    }
  }

  const openFileConfigPopup = () => {
    setShowFileConfigurationPopup(true)
  }

  const closeFileConfigPopup = () => {
    setShowFileConfigurationPopup(false)
  }

  const downloadSampleStrategyPortfolio = async () => {
    try {
      const response = await API.get('baseStrategyURL', `strategy/v1/${user?.userGroup}/strategies/models/${params?.strategyId}/sample?downloadSample=true`)
      if (response?.data) {
        downloadFileFromBuffer(atob(response.data), `${'Strategy-Portfolio-Sample'}${configModel && configModel?.length ? configModel[0]?.extension : '.csv'}`)
      }
    } catch (error) {
      showError(error, false, {}, 'Failed to download sample strategy.')
    }
  }

  const getIdentifierForError = (strategyObj) => {
    if (strategyObj?.secondaryId) {
      return `${strategyObj?.secondaryId} : ${strategyObj?.[strategyObj?.secondaryId?.toLowerCase()]}`
    }
    return ''
  }

  const isNumberWithLimitedDecimals = (event) => {
    const regex = /^(\d+\.?\d{0,4})$/
    if (event.ctrlKey || event.metaKey) {
      return true
    }
    if ((event.keyCode === 8 || (event.keyCode >= 35 && event.keyCode <= 40) || [46, 8, 9, 27, 13].includes(event.keyCode) || (event.keyCode === 65 && (event.ctrlKey === true || event.metaKey === true)))) {
      return true
    }
    const inputValue = event?.target?.value?.slice(0, event?.target?.selectionStart) + event.key + event?.target?.value?.slice(event?.target?.selectionEnd)
    if (!regex.test(inputValue)) {
      event.preventDefault()
      return false
    }
    return true
  }

  const numberOnlyPasteHandler = (event) => {
    const inputValue = event.target.value.slice(0, event.target.selectionStart) + event.clipboardData.getData('text') + event.target.value.slice(event.target.selectionEnd)
    const regex = /^(\d*)\.?(\d{0,4})$/
    if (!regex.test(inputValue)) {
      event.preventDefault()
      return false
    }
    return true
  }

  const CharacterLengthMessage = () => (
    <Typography sx={{ fontSize: '12px !important', margin: '3px 3px', color: additionalMessage?.replace(/(<([^>]+)>)/gi, '')?.trim()?.length > 1000 ? 'red' : 'inherit' }}>
      {additionalMessage?.replace(/(<([^>]+)>)/gi, '')?.trim()?.length}/{1000}
    </Typography>
  )

  return (
    <>
      <Box sx={{
        position: 'absolute',
        right: 0,
        top: 10,
        zIndex: 90,
        display: 'inline-flex',
        alignItems: 'center'
      }}
      >
        {/* {checkAccess(moduleConfig.MODEL, ACCESS_LEVEL.COMPONENT_ACCESS,
            { subModuleName: moduleConfig.STRATEGY_PORTFOLIO, component_name: moduleConfig.DOWNLOAD_SAMPLE_PORTFOLIO })
            ? (
              <Tooltip title='Manage File Configuration'>
                <IconButton onClick={openFileConfigPopup}>
                  <SettingsIcon sx={{ color: '#787b86', fontSize: '18px' }} />
                </IconButton>
              </Tooltip>
              )
            : null} */}

        <Button
          type='button'
          onClick={handleUpload}
          disabled={!file || additionalMessage?.replace(/(<([^>]+)>)/gi, '')?.trim()?.length > 1000}
          // size='small'
          variant='contained'
        >
          Upload
        </Button>

      </Box>
      <Box>
        <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
          <Button ref={submitRef} type='submit' style={{ display: 'none' }} />
          <Box>
            <Grid container>
              <Grid item xs={12}>
                <Box display='flex' gap={3}>
                  {checkAccess(moduleConfig.MODEL, ACCESS_LEVEL.COMPONENT_ACCESS,
                    { subModuleName: moduleConfig.STRATEGY_PORTFOLIO, component_name: moduleConfig.HOLDING_DATE })
                    ? (
                      <Box>
                        <Typography className='text-title header-text' sx={{ fontSize: '14px !important' }}>Holding Date</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={dayjs(holdingDate)}
                            onChange={handleHoldingDateChange}
                            format='YYYY-MM-DD'
                            maxDate={dayjs()}
                            sx={{ width: '200px', marginBottom: '8px' }}
                            slotProps={{ textField: { size: 'small' } }}
                          />
                        </LocalizationProvider>
                      </Box>
                      )
                    : null}
                </Box>

                <FormControl fullWidth sx={{ marginBottom: '16px', marginTop: '8px' }} error={!!errors.totalPortfolioMarketValue}>
                  <Box display='flex' alignItems='flex-start' gap={3} width='100%'>
                    <Box width='30%'>
                      <Typography className='text-title header-text' sx={{ fontSize: '14px !important', marginBottom: '8px !important', display: 'flex', alignItems: 'center' }}>
                        Upload Portfolio File
                        <HtmlTooltip
                          title={
                            <Box>
                              <Typography sx={{ fontSize: '14px' }} my={1}>
                                Accepted File Format: {configModel ? configModel[0]?.extension : 'CSV, TXT'}
                              </Typography>
                              <Typography sx={{ fontSize: '14px' }} my={1}>
                                Weights will be normalized to 100% by the system if their sum is not already 100%.
                              </Typography>
                            </Box>
                          }
                        >
                          <IconButton sx={{ padding: '0px !important', marginLeft: '5px' }}>
                            <InfoOutlinedIcon sx={{ fontSize: '18px' }} />
                          </IconButton>
                        </HtmlTooltip>
                      </Typography>
                      {
                        file
                          ? <>
                            <Card className='card-layout' sx={{ height: '130px', border: file ? '2px solid #3BBFA399 !important' : '' }}>
                              <CardContent sx={{ pb: '16px !important' }}>
                                <Box className='uploaded-file' sx={{ pt: '4px' }}>
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Box className='btn-soft-blue' sx={{ display: 'flex', justifyContent: 'center' }} align='center' mx={1}>
                                      <img
                                        src={fileImage}
                                        alt=''
                                        height={42}
                                        width={36}
                                      />
                                    </Box>
                                    <Box sx={{ overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                                      <Typography sx={{ fontSize: '12px', color: '#74788D', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>Name: <Tooltip title={file.name}>{file.name}</Tooltip></Typography>
                                      <Typography sx={{ fontSize: '12px', color: '#74788D' }}>Size: {fileSize || file?.size}</Typography>
                                      <Typography sx={{ fontSize: '12px', color: '#74788D' }}>
                                        Last Modified:{' '}
                                        {file
                                          ? file?.lastModifiedDate?.toLocaleDateString()
                                          : ''}
                                      </Typography>
                                    </Box>
                                    {isPortfolioFileUploading ? <Loader /> : null}
                                  </Box>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'end' }} p={1}>
                                  {/* <Button
                                  type='button'
                                  onClick={handleUpload}
                                  size='small'
                                  variant='contained'
                                  sx={{ fontSize: '12px !important' }}
                                >
                                  Upload
                                </Button> */}
                                  <Button
                                    type='button'
                                    onClick={handleCancel}
                                    size='small'
                                    variant='text'
                                    sx={{
                                      fontSize: '12px !important',
                                      marginLeft: '10px',
                                      background: '#F05F5F26',
                                      border: 'none',
                                      color: '#F05F5F',
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </Box>
                              </CardContent>
                            </Card>
                          </>
                          : <>
                            <Box
                              className='file-upload-block'
                              onDragEnter={(e) => e.preventDefault()}
                              onDragOver={(e) => e.preventDefault()}
                              onDrop={(e) => {
                                e.preventDefault()
                                const droppedFile = e.dataTransfer.files[0]
                                setFile(droppedFile)
                                if (droppedFile) {
                                  if (droppedFile.size >= 1024 && droppedFile.size < 1048576) {
                                    setFileSize((droppedFile.size / 1024).toFixed(1) + 'KB')
                                  } else {
                                    setFileSize(droppedFile.size + 'B')
                                  }
                                }
                              }}
                              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                              <Box
                                sx={{ position: 'relative', width: '100%', height: '100%', cursor: 'pointer' }}
                              >
                                <input
                                  type='file'
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    opacity: 0,
                                    cursor: 'pointer'
                                  }}
                                  {...register('securityTaxLotsFile')}
                                  onChange={(e) => {
                                    const selectedFile = e.target.files[0]
                                    setFile(selectedFile)
                                    e.target.value = ''
                                    if (selectedFile) {
                                      if (selectedFile.size >= 1024 && selectedFile.size < 1048576) {
                                        setFileSize((selectedFile.size / 1024).toFixed(1) + 'KB')
                                      } else {
                                        setFileSize(selectedFile.size + 'B')
                                      }
                                    }
                                  }}
                                  accept={configModel ? configModel[0]?.extension : '.csv,.txt'}
                                />
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%'
                                  }}
                                >
                                  <img
                                    style={{ cursor: 'pointer' }}
                                    src={download}
                                    alt='add'
                                    height={40}
                                    width={40}
                                  />
                                  <Typography
                                    pl={1}
                                    sx={{ color: '#34475a', fontWeight: 500, cursor: 'pointer' }}
                                  >
                                    Drag & drop or click to attach file
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </>
                      }
                    </Box>
                    <Box maxWidth='70%' minWidth='30%'>
                      <Typography className='text-title header-text' sx={{ fontSize: '14px !important', marginBottom: '8px !important', display: 'flex', alignItems: 'center' }}>
                        Additional Message
                        <HtmlTooltip
                          title={
                            <Box>
                              <Typography sx={{ fontSize: '14px' }} my={1}>
                                Max Characters Allowed: 1000
                              </Typography>
                            </Box>
                          }
                        >
                          <IconButton sx={{ padding: '0px !important', marginLeft: '5px' }}>
                            <InfoOutlinedIcon sx={{ fontSize: '18px' }} />
                          </IconButton>
                        </HtmlTooltip>
                      </Typography>
                      <div className='strategy-message-area'>
                        <TextAreaEditor
                          message={additionalMessage}
                          setMessage={setAdditionalMessage}
                          placeholder='Enter additional message'
                          containerProps={{ style: { resize: 'both' } }}
                          rightHeaderComponent={<CharacterLengthMessage />}
                        />
                      </div>
                    </Box>
                  </Box>
                  {errors.totalPortfolioMarketValue && (
                    <FormHelperText error>{errors.totalPortfolioMarketValue.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className='text-title header-text' sx={{ fontSize: '14px !important', mb: '12px !important' }}>Sample Strategy Portfolio </Typography>
            <Card sx={{ marginTop: '10px' }} className='card-layout'>
              <CardContent>
                <Box
                  sx={{
                    width: '100%',
                    overflow: 'auto',
                    [`.${gridClasses.columnHeaders}`]: {
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                      pointerEvents: 'none'
                    },
                    [`.${gridClasses.footerContainer}`]: {
                      position: 'sticky',
                      bottom: -20,
                      zIndex: 1
                    },
                    '.MuiDataGrid-footerContainer': {
                      border: 'none'
                    },
                    '.MuiDataGrid-root': {
                      border: 'none'
                    }
                  }}
                >
                  {checkAccess(moduleConfig.MODEL, ACCESS_LEVEL.COMPONENT_ACCESS,
                    { subModuleName: moduleConfig.STRATEGY_PORTFOLIO, component_name: moduleConfig.DOWNLOAD_SAMPLE_PORTFOLIO })
                    ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                        <Box className='strategy-download-button'>
                          <Button
                            sx={{ textTransform: 'none', color: '#3A76D4' }}
                            disabled={loading}
                            startIcon={<FileDownloadOutlinedIcon sx={{ color: '#3A76D4', fontSize: '16px' }} />}
                            onClick={downloadSampleStrategyPortfolio}
                          >
                            Download Sample Portfolio
                          </Button>
                        </Box>
                      </Box>
                      )
                    : null}
                  <DataGridPro
                    autoHeight
                    density='compact'
                    loading={isSamplePortfolioFetching}
                    disableRowSelectionOnClick
                    rows={samplePortfolioData?.data || []}
                    columns={samplePortfolioData?.fields || sampleColumnData}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Modal
          open={showPortfolioValidationPopup}
          onClose={closeModal}
          aria-labelledby='modal-title'
          centered
          fullWidth
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Box sx={portfolioModalstyle}>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='h6' style={{ fontWeight: 400, color: '#34475A' }}>Step 1 of 2: Model Validation</Typography>
              <Box display='flex' justifyContent='flex-end' alignItems='center'>
                {
                  // only show this message if adjusted weight and input weight difference is >= 0.0001
                  isWeightDiffExists
                    ? <Chip
                        variant='outlined'
                        sx={{
                          backgroundColor: '#F9F4D8',
                          color: '#947330',
                          borderRadius: '5px',
                          border: '1px solid #947330',
                          fontSize: '12px',
                          fontWeight: 600
                        }}
                        icon={<ErrorIcon sx={{ color: '#947330 !important' }} />}
                        label={`Total weight of portfolio in upload file was ${totalInputWeight}%. Adjusted weight: ${totalAdjustedWeight}%`}
                      />
                    : ''
                }
                <IconButton
                  onClick={closeModal}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>

            <Box overflow='auto' sx={{ height: 'calc(100vh - 16px - 40px - 52px - 40px)' }} mt={3} className='strategy-validation-popup'>
              <Table
                fullWidth
                stickyHeader
                sx={{
                  '.MuiTableCell-root': {
                    padding: '0px 8px !important'
                  }
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Symbol</TableCell>
                    <TableCell align='right'>
                      Uploaded Weight(%)
                      <IconButton sx={{ visibility: 'hidden' }}>
                        <InfoOutlinedIcon sx={{ fontSize: '18px', color: '#D32F2F' }} />
                      </IconButton>
                    </TableCell>
                    <TableCell align='right'>Adjusted Weight(%)</TableCell>
                    <TableCell align='right'>Country Code</TableCell>
                    <TableCell align='right'>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody mb={2}>
                  {displayRows?.map((data, index) => {
                    const originalIndex = page * rowsPerPage + index
                    return (
                      <TableRow
                        key={originalIndex}
                        className={
                          data.name === null ||
                            data.weightValue <= 0 ||
                            data.matchStatus === 'red' || data.weightStatus === 'red'
                            ? 'invalid-entry'
                            : data.matchStatus === 'orange'
                              ? 'warning-entry'
                              : ''
                        }
                      >
                        <TableCell py={2}>
                          {data.clicked
                            ? (
                              <Autocomplete
                                size='small'
                                loading={isSecuritySearchLoading}
                                isOptionEqualToValue={(option, value) => {
                                  return option?.name === value
                                }}
                                options={securityOptions[originalIndex] || []}
                                getOptionLabel={(option) => typeof option === 'string' || option instanceof String ? option : option?.name}
                                getOptionDisabled={(option) =>
                                  option.instrId === stagedData[originalIndex]?.instrId}
                                value={stagedData[originalIndex].name === securityInputValue ? securityInputValue : stagedData[originalIndex].name}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label='Security Name'
                                    placeholder={data.name}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {params.InputProps.endAdornment}
                                        </>
                                      )
                                    }}
                                  />
                                )}
                                onInputChange={(event, value) => {
                                  onSecurityNameChange(event, value, originalIndex)
                                }}
                                onChange={(event, value) => {
                                  onSecurityChangeHandler(event, value, originalIndex)
                                }}
                              />
                              )
                            : data.name
                              ? (
                                  data.name
                                )
                              : (
                                  ''
                                )}
                        </TableCell>
                        <TableCell py={2}>
                          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            {data?.symbol || ''}
                            {
                              stagedData[originalIndex]?.hasCodeError || !stagedData[originalIndex]?.name || stagedData[originalIndex]?.matchStatus === 'red' || stagedData[originalIndex]?.matchStatus === 'orange'
                                ? <HtmlTooltip title={
                                  <Typography sx={{ fontSize: '14px' }}>
                                    {
                                      !stagedData[originalIndex]?.symbol && !stagedData[originalIndex]?.name && stagedData[originalIndex]?.secondaryId
                                        ? `Symbol does not found with provided ${getIdentifierForError(stagedData[originalIndex])}`
                                        : !stagedData[originalIndex]?.symbol
                                            ? 'Symbol is required'
                                            : !stagedData[originalIndex]?.name
                                                ? 'No matching symbol found in system'
                                                : stagedData[originalIndex]?.hasCodeError
                                                  ? 'Symbol does not exist in selected country code'
                                                  : ((stagedData[originalIndex]?.matchStatus === 'red' || stagedData[originalIndex]?.matchStatus === 'orange') && stagedData[originalIndex]?.secondaryId)
                                                      ? `Symbol does not match with provided ${getIdentifierForError(stagedData[originalIndex])}`
                                                      : ''
                                    }
                                  </Typography>
                                }
                                  >
                                  <IconButton>
                                    <InfoOutlinedIcon sx={{ fontSize: '18px', color: stagedData[originalIndex]?.matchStatus === 'orange' ? '#947330' : '#D32F2F' }} />
                                  </IconButton>
                                  </HtmlTooltip>
                                : ''
                            }
                          </Box>
                        </TableCell>
                        <TableCell py={2} align='right'>
                          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            {data?.clicked
                              ? (
                                <TextField
                                  type='text'
                                  size='small'
                                  value={stagedData[originalIndex]?.weightValue}
                                  onKeyDown={(event) => isNumberWithLimitedDecimals(event)}
                                  onPaste={numberOnlyPasteHandler}
                                  onChange={(e) => {
                                    const temp = [...stagedData]
                                    temp[originalIndex].isWeightEdited = parseFloat(e.target.value) !== temp[originalIndex]?.weight
                                    temp[originalIndex].weightValue = e.target.value
                                    setStagedData(temp)
                                  }}
                                />
                                )
                              : (
                                  !isNaN(Number(data?.weightValue)) ? Number(data?.weightValue).toFixed(4) : ''
                                )}
                            <HtmlTooltip title={data?.weightValue === '' || parseFloat(data?.weightValue) <= 0
                              ? <>
                                <Typography sx={{ fontSize: '14px' }} my={1}>
                                  {
                                    data?.weightValue === '' ? 'Weight is required' : 'Weight cannot be less than or equal to 0'
                                  }
                                </Typography>
                              </>
                              : ''}
                            >
                              <IconButton sx={{ visibility: data?.weightValue === '' || parseFloat(data?.weightValue) <= 0 ? 'visible' : 'hidden' }}>
                                <InfoOutlinedIcon sx={{ fontSize: '18px', color: '#D32F2F' }} />
                              </IconButton>
                            </HtmlTooltip>
                          </Box>
                        </TableCell>

                        <TableCell py={2} align='right'>
                          {data?.adjustedWeight?.toFixed(4)}
                        </TableCell>
                        <TableCell key={originalIndex} py={2} align='right'>
                          {data.clicked
                            ? (
                              <Autocomplete
                                size='small'
                                loading={isCountrySearchLoading}
                                sx={{ padding: '8px 0px 8px 8px' }}
                                isOptionEqualToValue={(option, value) => {
                                  return option?.code === value
                                }}
                                options={countryOptions || []}
                                getOptionDisabled={(option) =>
                                  option.code === stagedData[originalIndex]?.isoCountryCode}
                                getOptionLabel={(option) => typeof option === 'string' || option instanceof String ? option : option?.name}
                                value={stagedData[originalIndex]?.isoCountryCode === isoCodeInputValue ? isoCodeInputValue : stagedData[originalIndex]?.isoCountryCode}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label='Country Code'
                                    placeholder={data.code}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {params.InputProps.endAdornment}
                                        </>
                                      )
                                    }}
                                  />
                                )}
                                onChange={(event, value) => {
                                  onISOCodeChangeHandler(event, value, originalIndex)
                                }}
                              />
                              )
                            : data?.isoCountryCode === null
                              ? 'US'
                              : data?.isoCountryCode
                                ? data?.isoCountryCode
                                : ''}
                        </TableCell>
                        <TableCell align='right'>
                          {data.clicked
                            ? (
                              <IconButton
                                aria-label='save'
                                size='medium'
                                disabled={stagedData[originalIndex].hasCodeError === true || stagedData[originalIndex]?.weightValue <= 0 || !data.name}
                                onClick={() => {
                                  if (
                                    data.name &&
                                    data.symbol &&
                                    data.weightValue
                                  ) {
                                    const temp = [...stagedData]
                                    const actualIndex =
                                      stagedData.findIndex(
                                        (item) =>
                                          item.name === data.name
                                      )
                                    temp[actualIndex].clicked = false
                                    if (data.isWeightEdited) {
                                      temp[actualIndex].weightStatus = ''
                                    }
                                    if (data?.isSecurityChanged) {
                                      temp[actualIndex].matchStatus = ''
                                    }
                                    setStagedData(temp)
                                  } else {
                                    showError('Please enter the correct values')
                                  }
                                }}
                              >
                                <SaveIcon sx={{ width: '20px', height: '20px', color: '#34475A' }} />
                              </IconButton>
                              )
                            : (
                              <IconButton
                                aria-label='edit'
                                size='medium'
                                onClick={() => {
                                  const temp = [...stagedData]
                                  const actualIndex = stagedData?.findIndex(
                                    (item) => item.id === data.id
                                  )
                                  temp[actualIndex].clicked = true
                                  setIsoCodeInputValue(temp[actualIndex]?.isoCountryCode)
                                  const updatedIsoCountryCodes = [...isoCodeEditIndex]
                                  updatedIsoCountryCodes[actualIndex] = temp[actualIndex]?.isoCountryCode
                                  setIsoCodeEditIndex(updatedIsoCountryCodes)
                                  setSecurityInputValue(temp[originalIndex]?.name)
                                  setStagedData(temp)
                                }}
                              >
                                <EditIcon sx={{ width: '20px', height: '20px', color: '#34475A' }} />
                              </IconButton>
                              )}
                          <IconButton
                            aria-label='delete'
                            size='medium'
                            onClick={() => {
                              const temp = [...stagedData]
                              setInvalidRowsCount(1)
                              temp.splice(originalIndex, 1)
                              setStagedData(temp)
                            }}
                          >
                            <DeleteIcon sx={{ width: '20px', height: '20px', color: '#34475A' }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
              <Box sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1 }}>

                <TablePagination
                  rowsPerPageOptions={[]}
                  component='div'
                  count={stagedData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </Box>
            <Box sx={{ padding: '12px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '10px' }}>
              {errorCount > 0 && (
                <Box>
                  <Typography color='error' sx={{ fontSize: '14px' }}>
                    {errorCount} out of {totalRows} rows have errors.
                  </Typography>
                </Box>
              )}
              {apiLoading || fileUploadApiLoading || preSignedApiLoading
                ? (
                  <>
                    <Loader />
                    <LoadingButton
                      position='absolute'
                      loading
                      disabled={
                        stagedData?.length <= 0 ||
                        stagedData.some((data) => {
                          if (
                            data.hasCodeError ||
                            data?.weightValue <= 0 ||
                            data.adjustedWeight <= 0 ||
                            !data ||
                            !data.name ||
                            !data.symbol ||
                            !data?.weightValue
                          ) {
                            return true
                          }
                          return false
                        })
                      }
                      loadingPosition='start'
                      startIcon={<SaveIcon />}
                      variant='contained'
                      color='primary'
                    >
                      {stagedData.some((data) =>
                        data.isWeightEdited ||
                        data?.isSecurityChanged ||
                        data.hasCodeError ||
                        data?.weightValue <= 0 ||
                        !data.name ||
                        !data.symbol ||
                        !data?.weightValue) || invalidRowsCount > 0
                        ? 'Re-Validate'
                        : 'Confirm'}
                    </LoadingButton>
                  </>
                  )
                : (
                  <Button
                    position='absolute'
                    disabled={
                      stagedData.length <= 0 ||
                      stagedData.some((data) => {
                        if (
                          data.hasCodeError ||
                          data?.weightValue <= 0 ||
                          !data ||
                          !data.name ||
                          !data.symbol ||
                          !data?.weightValue ||
                          data.clicked
                        ) {
                          return true
                        }
                        return false
                      })
                    }
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      if (stagedData.some((data) => data.isWeightEdited || data?.isSecurityChanged) || invalidRowsCount > 0) {
                        // csvDataProcessed based updated stagedData
                        const csvDataProcessed = stagedData?.map((data) => ({
                          symbol: data.symbol,
                          weight: typeof data?.weightValue === 'string' ? parseFloat(data?.weightValue) : data?.weightValue,
                          isoCountryCode: data.isoCountryCode,
                          cusip: data.cusip,
                          sedol: data.sedol,
                          isin: data.isin
                        }))
                        setApiLoading(true)
                        getSecurityDetails(csvDataProcessed)
                      } else {
                        const formattedData = stagedData?.map((strategyObj) => {
                          return {
                            instrId: strategyObj?.instrId,
                            weight: strategyObj?.adjustedWeight
                          }
                        })
                        getConfirmedSecurityDetails(formattedData)
                        getSectorCompanyDetails(formattedData)
                      }
                    }}
                  >
                    {stagedData.some((data) =>
                      data.isWeightEdited ||
                      data?.isSecurityChanged ||
                      data.hasCodeError ||
                      data?.weightValue <= 0 ||
                      !data.name ||
                      !data.symbol ||
                      !data?.weightValue) || invalidRowsCount > 0
                      ? 'Re-Validate'
                      : 'Confirm'}
                  </Button>
                  )}
            </Box>
          </Box>
        </Modal>
        <Modal
          open={showPortfolioSubmitSuccessModal}
          onClose={CloseMsgModel}
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Box sx={successModalStyle}>
            <Box className='oboardSuccess'>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                <img src={Success} alt='' height={100} width={100} />
              </Box>
              <Typography variant='h5' align='center' mb={2} sx={{ fontFamily: 'Open Sans' }}>
                {successMessage}
              </Typography>
              {/* {showSecondMsg && (
                <Box className='apimsgbox'>
                  <ul className='apimsg'>
                    <li className='msglist'>
                      <Box sx={{ width: '10%' }} mb={1}>
                        <img
                          src={DoneIcon}
                          alt=''
                          height={25}
                          width={25}
                        />
                      </Box>{' '}
                      <Typography>Update portfolio details</Typography>
                    </li>
                    <li className='msglist'>
                      <Box sx={{ width: '10%' }} mb={1}>
                        <img
                          src={DoneIcon}
                          alt=''
                          height={25}
                          width={25}
                        />
                      </Box>{' '}
                      <Typography>Recalculate Account Summary</Typography>
                    </li>
                    <li className='msglist'>
                      <Box sx={{ width: '10%' }} mb={1}>
                        <img
                          src={DoneIcon}
                          alt=''
                          height={25}
                          width={25}
                        />
                      </Box>{' '}
                      <Typography>Recalculate Risk</Typography>
                    </li>
                  </ul>
                </Box>
              )} */}
            </Box>
          </Box>
        </Modal>
      </Box>
      {showPortfolioConfirmationModal &&
        <StrategyPortfolioUpload
          dialogOpen={showPortfolioConfirmationModal}
          data={newStagedData}
          dialogClose={setShowPortfolioConfirmationModal}
          closeModal={closeModal}
          securityLoading={apiLoading}
          submitStrategyPortfolio={submitStrategyPortfolio}
          setConfirmModelPopup={setConfirmModelPopup}
          sectorAndCompanyData={sectorAndCompanyData}
          isSectorAndCompanyFetching={isSectorAndCompanyFetching}
          showDynamicPortfolioHeader={hasDynamicPortfolioAccess}
        />}
      {
          confirmModelPopup && (
            <Modal
              open={confirmModelPopup}
              onClose={() => setConfirmModelPopup(false)}
            >
              <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography style={{ fontSize: '20px', fontWeight: 400, color: '#34475A', paddingLeft: '10px' }}>Confirm Model Universe Change</Typography>
                  <IconButton aria-label='close' onClick={() => setConfirmModelPopup(false)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <ViewConfirmModelPopup submitStrategyPortfolio={submitStrategyPortfolio} data={newStagedData} />
              </Box>
            </Modal>
          )
        }
      <Modal
        open={isErrorModelOpen}
        onClose={() => setIsErrorModelOpen(false)}
        centered
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={invalidErrorModalStyle} overflow='auto'>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography id='modal-title' variant='h6' component='h2'>
              {errorPopupTitle}
            </Typography>
            <IconButton onClick={() => setIsErrorModelOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ mt: 2 }}>
            {(errorModalContent?.headerErrors?.length > 0 || errorModalContent?.rowErrors?.length > 0) && (
              <TableContainer>
                <Table
                  stickyHeader
                  sx={{
                    width: '100%',
                    '.MuiTableCell-root': {
                      padding: '6px 8px !important'
                    }
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell align='left'>Error Message</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {errorModalContent?.headerErrors?.length
                      ? <TableRow key='header'>
                        <TableCell width='80px' sx={{ verticalAlign: 'top' }}>Header</TableCell>
                        <TableCell sx={{ color: '#34475a', p: '4px', fontWeight: 600 }}>
                          <Box sx={{ maxHeight: errorModalContent?.rowErrors?.length === 0 ? '80vh' : '40vh', overflowY: 'auto' }}>
                            <ul>
                              {errorModalContent?.headerErrors?.map((errorMessage, index) => (
                                <li key={index} style={{ marginBottom: '4px', marginLeft: '20px' }}>
                                  <Typography key={index} sx={{ whiteSpace: 'normal', wordBreak: 'break-word', color: '#34475a', fontWeight: 600 }}>
                                    {errorMessage}
                                  </Typography>
                                </li>
                              ))}
                            </ul>
                          </Box>
                        </TableCell>
                      </TableRow>
                      : ''}
                    {errorModalContent?.rowErrors?.length
                      ? <TableRow key='rows'>
                        <TableCell sx={{ verticalAlign: 'top' }}>Rows</TableCell>
                        <TableCell sx={{ color: '#34475a', p: '4px', fontWeight: 600 }}>
                          <Box sx={{ maxHeight: errorModalContent?.headerErrors?.length === 0 ? '80vh' : '40vh', overflowY: 'auto' }}>
                            <ul>
                              {errorModalContent?.rowErrors?.map((errorMessage, index) => (
                                <li key={index} style={{ marginBottom: '4px', marginLeft: '20px' }}>
                                  <Typography sx={{ whiteSpace: 'normal', wordBreak: 'break-word', color: '#34475a', fontWeight: 600 }}>
                                    {errorMessage}
                                  </Typography>
                                </li>
                              ))}
                            </ul>
                          </Box>
                        </TableCell>
                      </TableRow>
                      : ''}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Box>
      </Modal>
      {/* <Modal
        open={showFileConfigurationPopup}
        onClose={closeFileConfigPopup}
        centered
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{ ...invalidErrorModalStyle, maxWidth: '600px', height: '650px', maxHeight: '100vh', overflow: 'visible' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography id='modal-title' variant='h6' component='h2'>
              File Configuration
            </Typography>
            <IconButton onClick={closeFileConfigPopup}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ mt: 0 }}>
            <ManageFileConfig configModel={configModel} />
          </Box>
        </Box>
      </Modal> */}
    </>
  )
}

export default StrategyImport
